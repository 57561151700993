import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BsFillPencilFill } from "react-icons/bs";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import swal from "sweetalert";
import Swal from "sweetalert2";
import { ViewCustomerOrders } from "./Customers";

import {
  Modal,
  OverlayTrigger,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export default class PosUsers extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loadSpinnerOpen: true,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      reload: true,
    };
  }
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };
  OnDeleted = (indexno) => {
    this.setState({ reload: false });
    const list = [...this.state.users];
    list.splice(indexno, 1);
    this.setState({ users: list }, () => {
      this.setState({ reload: true }, () => {
        //this.ShowMessagBox("Information", "Deleted Successfully!");
      });
    });
  };

  OnUpdated = (indexno) => {
    this.OnRefreshButtonClick();
  };

  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
  };

  OnRefreshButtonClick = () => {
    this.setState({ user: [] }, () => {
      this.GetPosUsers();
      this.setState({ reload: false }, () => {
        this.setState({ reload: true });
      });
    });
  };

  GetPosUsers = () => {
    const config = {
      headers: { Authorization: `Bearer ${this.context.userData.bearertoken}` },
    };
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetPosUsers/" +
        this.context.storeId,
      config
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ users: data });
        }
      })
      .catch((error) => {
        this.ShowMessagBox("Warning", "Session Timeout!");
        this.context.clearUserData();
        this.setState({ sessionTimedout: true });
      });
  };

  fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 500));
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.state.users.length == 0) {
      this.GetPosUsers();
    }
    this.fakeRequest().then(() => {
      this.setState({ loadSpinnerOpen: false }); // showing the app
    });
  }

  componentDidUpdate() {}
  render() {
    return (
      <>
        <div className="Container-fluid mx-0 px-0">
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}

          <Row>
            <Col className="" xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="pagebackground">
              <Navbar className="secondarythemecolor position-sticky fixed-top  my-0">
                <h5> &nbsp; &nbsp;POS Users MANAGEMENT &nbsp;</h5>
                <Navbar.Collapse className="justify-content-end mx-1 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemecolor"
                        disabled={
                          this.state.users &&
                          this.state.users.length >=
                            (this.context.storeSettings.totalPosUsers
                              ? this.context.storeSettings.totalErpAdminUsers
                              : 0)
                            ? true
                            : false
                        }
                        // hidden={
                        //   this.context.UserRoles.PRODUCTADMIN ==
                        //   this.context.userData.roleId
                        //     ? false
                        //     : true
                        // }
                        onClick={() => this.setState({ addModalShow: true })}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh
                          size="26"
                          className="mainthemecolor"
                        ></BiRefresh>
                        Refresh
                      </Button>
                      &nbsp;
                      {/* this logical check is required to deal with object destraction after close or submit the model */}
                      {this.state.addModalShow === true ? (
                        <AddOrEditPosUserModal
                          show={this.state.addModalShow}
                          Type="Add"
                          onHide={this.addModalClose}
                        />
                      ) : null}
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <Row>
                <div className="text-black ">
                  <Table border striped className="px-0 bg-white ">
                    <thead className="">
                      <tr
                        className="text-center text-black bg-white border"
                        style={{ fontSize: "12px" }}
                      >
                        <th width="5%" className="border"></th>
                        <th width="10%" className="border">
                          User Name
                        </th>
                        <th width="10%" className="border">
                          Email-Id
                        </th>
                        <th width="10%" className="border">
                          Contact Number
                        </th>
                        <th width="10%" className="border">
                          Role
                        </th>
                        <th width="15%" className="text-center border">
                          Options
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-primary border">
                      {this.state.users.map((user, i) =>
                        this.state.reload === true ? (
                          <EditUserInline
                            index={i}
                            user={user}
                            OnDeleted={this.OnDeleted}
                            OnUpdated={this.OnUpdated}
                          ></EditUserInline>
                        ) : null
                      )}
                    </tbody>
                  </Table>
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
export class EditUserInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      user: this.props.user,
      editModalShow: false,
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      showOrdersModal: false,
    };
  }
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDelete = () => {
    this.ShowLoadSpinner();

    const requestOptions = {
      method: "DELETE",
      headers: { Authorization: `Bearer ${this.context.userData.bearertoken}` },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeletePosUser/" +
        this.state.user.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          this.ShowMessagBox("Error", "Delete Failed! try after sometime");
          return;
        }
        this.HideLoadSpinner();
        this.props.OnDeleted(this.props.index);
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Error",
          "Delete Failed! This could be due to child reference Error."
        );
      });
  };

  OnEditButtonClick = () => {
    this.setState({ editModalShow: true }, () => {});
  };

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {
      this.props.OnUpdated();
    });
  };

  GetUserPurpose(value) {
    switch (value) {
      case 1:
        return "Refund";
      case 2:
        return "Reward";
      case 3:
        return "Promotional";
    }
  }
  GetUserType(value) {
    switch (value) {
      case 1:
        return "Cash";
      case 2:
        return "Discount";
    }
  }
  GetUserStatus(value) {
    switch (value) {
      case true:
        return "Valid";
      case false:
        return "Expired";
    }
  }
  OnClickViewOrders = () => {
    this.setState({ showOrdersModal: true });
  };
  OnCloseOrdersModal = () => {
    this.setState({ showOrdersModal: false });
  };
  componentDidMount() {}

  render() {
    return (
      <>
        {this.state.showOrdersModal == true ? (
          <>
            <ViewCustomerOrders
              customer={this.props.user}
              Close={this.OnCloseOrdersModal}
            ></ViewCustomerOrders>
          </>
        ) : (
          <></>
        )}

        {this.state.editModalShow === true ? (
          <AddOrEditPosUserModal
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            user={this.state.user}
            Type="Edit"
          />
        ) : (
          ""
        )}
        {this.state.msgboxOpen === true ? (
          <MessageBox
            open={this.state.msgboxOpen}
            title={this.state.msgboxTitle}
            message={this.state.msgboxMessage}
            callback={this.CloseMessagBoxCallback}
          />
        ) : (
          ""
        )}
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <tr
          className="text-center align-middle text-secondary bg-white mx-5"
          style={{ fontSize: "11px" }}
        >
          <td>{this.props.index + 1}</td>
          <td>{this.props.user.firstName}</td>
          <td>{this.props.user.emailId}</td>
          <td>{this.props.user.mobileNo}</td>
          <td>
            {this.context.posRoles.filter(
              (f) => f.id == this.props.user.posRoleId
            ).length > 0
              ? this.context.posRoles.filter(
                  (f) => f.id == this.props.user.posRoleId
                )[0].roleName
              : ""}
          </td>
          <td className="text-cener">
            <div class="btn-group">
              <Button
                className="mainthemecolor"
                onClick={(e) => {
                  this.OnClickViewOrders();
                }}
              >
                Orders
              </Button>{" "}
              &nbsp;&nbsp;
              <OverlayTrigger overlay={<Tooltip>Edit!</Tooltip>}>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    this.OnEditButtonClick(e);
                  }}
                >
                  <BsFillPencilFill size="20" />
                </Button>
              </OverlayTrigger>
              &nbsp;&nbsp;&nbsp;
              <OverlayTrigger overlay={<Tooltip>Delete!</Tooltip>}>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    if (window.confirm("Are you sure you want to Delete ?")) {
                      this.OnDelete(e);
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

export class AddOrEditPosUserModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      user: {
        firstName: "",
        emailId: "",
        mobileNo: "",
        posRoleId: "",
        customerUserTypeId: 3,
      },
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
    };
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  handleInputChange = (e) => {
    const { name, value } = e.target;
    //validate for minus values not to allow
    switch (name.trim()) {
      case "firstName":
        this.state.user.firstName = value;
        break;
      case "emailId":
        this.state.user.emailId = value;
        break;
      case "mobileNo":
        this.state.user.mobileNo = value;
        break;
      case "posRoleId":
        this.state.user.posRoleId = value;
        break;
    }
    this.setState({ user: this.state.user });
  };

  AddOrUpdatePosUserInDB = () => {
    this.ShowLoadSpinner();
    this.state.user.storeId = this.context.storeId;
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.context.userData.bearertoken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state.user),
    };

    console.log(process.env.REACT_APP_API);

    fetch(
      process.env.REACT_APP_API + "StoreManager/AddPosUser/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.HideLoadSpinner();
          Swal.fire({
            title: "Saved Successfully!",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              this.props.onHide();
              window.scrollTo(0, 0);
            }
          });
          return;
        } else if (res.status == 409) {
          this.HideLoadSpinner();
          this.ShowMessagBox("Information", "User Code already Exist!");
        } else {
          this.HideLoadSpinner();
          this.ShowMessagBox("Error", "Saved Failed!");
        }
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed! " + error);
      });
  };
  handleSubmit = (event) => {
    event.preventDefault();

    this.AddOrUpdatePosUserInDB();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };
  componentDidMount() {
    if (this.props.Type == "Edit") {
      this.setState({ user: this.props.user });
    }
  }

  render() {
    return (
      <>
        {this.state.msgboxOpen === true ? (
          <MessageBox
            open={this.state.msgboxOpen}
            title={this.state.msgboxTitle}
            message={this.state.msgboxMessage}
            callback={this.CloseMessagBoxCallback}
          />
        ) : (
          ""
        )}
        <Modal
          {...this.props}
          // size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          show={true}
          dialogClassName="payByCash"
        >
          <Form onSubmit={this.handleSubmit}>
            {/* <Form> */}
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                Add POS User
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Form.Group as={Row} className="mb-1 " controlId="">
                <Form.Label column sm="5">
                  User Name:
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    type="Name"
                    size="lg"
                    name="firstName"
                    value={this.state.user.firstName}
                    required
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => this.handleInputChange(e)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1 " controlId="">
                <Form.Label column sm="5">
                  EmailId / Id:
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    type="text"
                    size="lg"
                    name="emailId"
                    value={this.state.user.emailId}
                    required
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => this.handleInputChange(e)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1 " controlId="">
                <Form.Label column sm="5">
                  Phone Number:
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    type="number"
                    size="lg"
                    name="mobileNo"
                    value={this.state.user.mobileNo}
                    required
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => this.handleInputChange(e)}
                  />
                </Col>
              </Form.Group>
              {this.context.store.businessTypeId == 3 ? (
                <>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="5">
                      Role:
                    </Form.Label>
                    <Col sm="7">
                      <Form.Select
                        type="number"
                        size="lg"
                        name="posRoleId"
                        value={this.state.user.posRoleId}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value=""></option>
                        {this.context.posRoles.map((r) => (
                          <option value={r.id}>{r.roleName}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                </>
              ) : (
                <></>
              )}
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  {/* <Button variant="primary" size="lg" onClick={this.handleSubmit}> */}
                  Save
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}
