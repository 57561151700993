// import React, { Component } from "react";
// import CartContext from "./CartContext";
// import TableOrdersQueue from "./TableOrdersQueue";
// import { Package } from "./Orders";
// import { Row, Col } from "react-bootstrap";

// const WAIT_INTERVAL = 500;

// export default class Kds extends Component {
//   static contextType = CartContext;
//   constructor(props) {
//     super(props);
//     this.state = {};
//   }
//   componentDidMount = () => {};
//   componentDidUpdate = () => {};
//   render() {
//     return (
//       <>
//         <div className="Container-fluid mx-3 px-0 bg-white">
//           <Row>
//             <Col className="pagebackground " width="40%">
//               <TableOrdersQueue KdsView={true}></TableOrdersQueue>
//             </Col>
//             <Col className="pagebackground " width="30%">
//               <Package KdsView={true} deliveryType="Take Away"></Package>
//             </Col>
//             <Col className="pagebackground " width="30%">
//               <Package KdsView={true} deliveryType="Home Delivery"></Package>
//             </Col>
//           </Row>
//         </div>
//       </>
//     );
//   }
// }
