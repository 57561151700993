import React, { Component, useState } from "react";
import CartContext from "./CartContext";
import { MessageBox } from "./MessageBox";
import Switch from "react-switch";
import Select from "react-select";

import {
  Table,
  OverlayTrigger,
  Tooltip,
  Button,
  Form,
  Row,
  Col,
} from "react-bootstrap";
import { InputGroup, Modal } from "react-bootstrap";
import { LoadSpinner } from "./LoadSpinner";

export class CategoryAddOrEditModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    if (this.props.ModalType === "Edit") {
    }

    this.state = {
      name: this.props.ModalType === "Edit" ? this.props.maincategory.name : "",
      description:
        this.props.ModalType === "Edit"
          ? this.props.maincategory.description
          : "",
      hideMe:
        this.props.ModalType === "Edit"
          ? this.props.maincategory.hideMe
          : false,
      imgFileName:
        this.props.ModalType === "Edit"
          ? this.props.maincategory.imgFileName
          : "",
      adImgFileName:
        this.props.ModalType === "Edit"
          ? this.props.maincategory.adImgFileName
          : "",
      subCategoryArray:
        this.props.ModalType === "Edit"
          ? this.props.subCategories
          : [
              {
                name: "",
                storeId: this.props.storeId,
                description: "",
                hideMe: "false",
                imgFileName: "",
              },
            ],
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
    };
  }
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  optionsMainCaregoryImgNames = [
    { value: "vegetables.jpeg", label: "vegetables.jpeg" },
    { value: "fruits.jpeg", label: "fruits.jpeg" },
    { value: "dairyproducts.jpeg", label: "dairyproducts.jpeg" },
    { value: "IceCreamproducts.jpeg", label: "IceCreamproducts.jpeg" },
  ];
  optionsSubCaregoryImgNames = [
    { value: "vegetables.jpeg", label: "vegetables.jpeg" },
    { value: "fruits.jpeg", label: "fruits.jpeg" },
    { value: "dairyproducts.jpeg", label: "dairyproducts.jpeg" },
    { value: "IceCreamproducts.jpeg", label: "IceCreamproducts.jpeg" },
  ];
  //required to set 100% width on react-select
  styles = {
    container: (base) => ({
      ...base,
      flex: 1,
    }),
  };

  OnNameChange = (event) => {
    this.setState({ name: event.target.value });
  };
  OnDescriptionChange = (event) => {
    this.setState({ description: event.target.value });
  };
  OnHideMeOnPortalChange = (event) => {
    this.setState({ hideMe: event.target.value });
  };

  OnMainCategoryImgNameChanges = (e) => {
    this.setState({ imgFileName: e.target.value });
  };
  OnMainCategoryAdImgNameChanges = (e) => {
    this.setState({ adImgFileName: e.target.value });
  };

  handleSubCategoryAdd = (e) => {
    this.setState(
      {
        subCategoryArray: [
          ...this.state.subCategoryArray,
          {
            name: "",
            storeId: this.props.storeId,
            description: "",
            hideMe: "false",
            imgFileName: "",
          },
        ],
      },
      () => this.OnAfterSubCategoryAdded()
    );
  };
  OnAfterSubCategoryAdded = () => {};

  handleSubCategoryControlChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.subCategoryArray];

    list[index][name] = value;
    this.setState({ subCategoryArray: list });
  };

  handleSubCategoryHidemeChange = (e, index) => {
    const list = [...this.state.subCategoryArray];

    list[index]["hideMe"] = !e;

    this.setState({ subCategoryArray: list });
  };

  handleSubCategoryImageFileNameChange = (e, index) => {
    const list = [...this.state.subCategoryArray];
    list[index]["imgFileName"] = e.target.value;
    this.setState({ subCategoryArray: list });
  };

  handleRemoveSubCategoryClick = (i) => {
    const list = [...this.state.subCategoryArray];
    list.splice(i, 1);
    this.setState({ subCategoryArray: list });
  };

  AddCategoryToDB = () => {
    this.ShowLoadSpinner();

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        storeId: this.context.storeId,
        name: this.state.name,
        description: this.state.description,
        imgFileName: this.state.imgFileName,
        adImgFileName: this.state.adImgFileName,
        hideMe: this.state.hideMe,
        subCategories: this.state.subCategoryArray,
      }),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/AddMainCategory/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          console.log("Added Successfully");
          this.context.getMainCategories();
          this.context.getSubCategories();
          this.HideLoadSpinner();
          this.ShowMessagBox(
            "Status",
            "Saved Successfully! Click Refresh button to see the changes."
          );
          return;
        }
        this.HideLoadSpinner();
        alert("not updated");
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("Failed" + error);
      });
  };
  UpdateCategoryToDB = () => {
    this.ShowLoadSpinner();

    var tmpMainCategory = this.props.maincategory;
    tmpMainCategory.name = this.state.name;
    tmpMainCategory.hideMe = this.state.hideMe;
    tmpMainCategory.description = this.state.description;
    tmpMainCategory.imgFileName = this.state.imgFileName;
    tmpMainCategory.adImgFileName = this.state.adImgFileName;
    tmpMainCategory.subCategories = this.state.subCategoryArray;

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmpMainCategory),
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/UpdateMainCategory/" +
        this.props.maincategory.id,
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.context.getMainCategories();
          this.context.getSubCategories();
          console.log("Updated Successfully");
          this.HideLoadSpinner();
          this.ShowMessagBox(
            "Status",
            "Updated Successfully. Click Refresh Button to see the changes!"
          );
          return;
        }
        this.HideLoadSpinner();
        alert("not updated");
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("Failed" + error);
      });
  };

  handleSubmit = (event) => {
    //TODO: Validate Form
    event.preventDefault();

    if (this.props.ModalType === "Edit") {
      this.UpdateCategoryToDB();
    } else this.AddCategoryToDB();
  };

  handleSubCategoryInputChange = (e, index) => {
    const { name, value } = e.target;
  };

  handleSubCategoryRemoveClick = (i) => {
    const list = [...this.state.subCategoryArray];
    list.splice(i, 1);
    this.setState({ subCategoryArray: list });
  };
  handleMainCategoryHidemeChange = (checked) => {
    this.setState({ hideMe: !checked });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div className="container ">
        {this.state.msgboxOpen === true ? (
          <MessageBox
            open={this.state.msgboxOpen}
            title={this.state.msgboxTitle}
            message={this.state.msgboxMessage}
            callback={this.CloseMessagBoxCallback}
          />
        ) : (
          ""
        )}
        <Modal
          {...this.props}
          //
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          dialogClassName="ProductModal"
        >
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.ModalType === "Edit"
                  ? "Edit Category"
                  : "Add Category"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Row>
                <Col className="text-secondary">
                  <h4>Main Category Details</h4>
                </Col>
              </Row>
              <Row className="border bg-light ">
                <Col xs={12} md={6} className="my-3">
                  <Row>
                    <Col md={3}>Main Category Name</Col>
                    <Col md={9}>
                      {" "}
                      <Form.Control
                        type="text"
                        name="MainCategoryName"
                        value={this.state.name}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.OnNameChange(e)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>Description</Col>
                    <Col md={9}>
                      <Form.Control
                        type="text"
                        as="textarea"
                        rows={3}
                        name="MainCategoryDescription"
                        maxlength="499"
                        value={this.state.description}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.OnDescriptionChange(e)}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col md={3}>Show Me</Col>
                    <Col md={9}>
                      <div className="my-2">
                        <Switch
                          height={20}
                          onChange={this.handleMainCategoryHidemeChange}
                          checked={!this.state.hideMe}
                        />
                      </div>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={3}>Image FileName</Col>
                    <Col md={9}>
                      <Form.Select
                        styles={this.styles}
                        value={this.state.imgFileName}
                        onChange={(e) => this.OnMainCategoryImgNameChanges(e)}
                      >
                        {this.context.images.map((r) => (
                          <option value={r.name}>{r.name}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>

                  <Row>
                    <Col md={3}>Ad Image FileName</Col>
                    <Col md={9}>
                      <Form.Select
                        styles={this.styles}
                        value={this.state.adImgFileName}
                        onChange={(e) => this.OnMainCategoryAdImgNameChanges(e)}
                      >
                        <option value=""></option>
                        {this.context.images.map((r) => (
                          <option value={r.name}>{r.name}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </Col>
                <Col xs={6} md={6} className="my-3">
                  <Row>
                    <Col>
                      <Form.Group controlId="Image" className="text-center">
                        Category Image
                        <img
                          width="100%"
                          height="200px"
                          className=""
                          style={{ objectFit: "contain" }}
                          src={
                            this.context.store.storageBlobUrl +
                            this.context.store.storageBlobContainerName +
                            "/images/" +
                            this.state.imgFileName +
                            this.context.store.storageSasToken
                          }
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group controlId="Image" className="text-center">
                        Ad Image
                        <img
                          width="100%"
                          height="200px"
                          className=""
                          style={{ objectFit: "contain" }}
                          src={
                            this.context.store.storageBlobUrl +
                            this.context.store.storageBlobContainerName +
                            "/images/" +
                            this.state.adImgFileName +
                            this.context.store.storageSasToken
                          }
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col>
                  <br></br>
                  <Row>
                    <Col xs={6} md={8} className="text-start text-secondary">
                      <h4> Sub Category Details </h4>
                    </Col>
                    <Col xs={6} md={4} className="text-end align-bottom">
                      <Button
                        variant="primary"
                        classname="px-2"
                        onClick={(e) => this.handleSubCategoryAdd(e)}
                      >
                        + Add
                      </Button>
                      &nbsp;&nbsp;
                    </Col>
                  </Row>
                  <Table
                    bordered
                    striped
                    size="sm"
                    className="my-2 text-center"
                  >
                    <thead className="bg-secondary text-white">
                      <tr size="sm">
                        <td width="20%">Sub Category Name</td>
                        <td width="30%">Description</td>
                        <td width="20%">Image File Name</td>
                        <td width="10%">Image</td>
                        <td width="10%">Show Me</td>
                        <td width="5%"></td>
                      </tr>
                    </thead>
                    <tbody size="sm">
                      {this.state.subCategoryArray.map((row, i) => (
                        <>
                          <tr>
                            <td>
                              <Form.Control
                                type="text"
                                name="name"
                                value={row.name}
                                required
                                placeholder=""
                                autoComplete="off"
                                onChange={(e) =>
                                  this.handleSubCategoryControlChange(e, i)
                                }
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="text"
                                name="description"
                                value={row.description}
                                placeholder=""
                                autoComplete="off"
                                onChange={(e) =>
                                  this.handleSubCategoryControlChange(e, i)
                                }
                              />
                            </td>

                            <td className="text-start">
                              <Form.Select
                                styles={this.styles}
                                options={this.optionsSubCaregoryImgNames}
                                value={row.imgFileName}
                                onChange={(e) =>
                                  this.handleSubCategoryImageFileNameChange(
                                    e,
                                    i
                                  )
                                }
                              >
                                {this.context.images.map((r) => (
                                  <option value={r.name}>{r.name}</option>
                                ))}
                              </Form.Select>
                            </td>
                            <td>
                              <img
                                width="50px"
                                height="30px"
                                src={
                                  this.context.store.storageBlobUrl +
                                  this.context.store.storageBlobContainerName +
                                  "/images/" +
                                  row.imgFileName +
                                  this.context.store.storageSasToken
                                }
                                // className="prd-img mx-auto"
                                alt="..."
                              />
                            </td>
                            <td>
                              {/* <Form.Select
                                type="text"
                                name="hideMe"
                                value={row.hideMe}
                                required
                                placeholder=""
                                onChange={(e) =>
                                  this.handleSubCategoryControlChange(e, i)
                                }
                              >
                                <option value="true">true</option>
                                <option value="false">false</option>
                              </Form.Select> */}
                              <Switch
                                height={20}
                                onChange={(e) =>
                                  this.handleSubCategoryHidemeChange(e, i)
                                }
                                checked={!row.hideMe}
                              />
                            </td>
                            <td>
                              <OverlayTrigger
                                overlay={<Tooltip>Delete!</Tooltip>}
                              >
                                <Button
                                  variant="outline-primary"
                                  onClick={(e) => {
                                    this.handleRemoveSubCategoryClick(i);
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-trash"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                    <path
                                      fill-rule="evenodd"
                                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                    />
                                  </svg>
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                    {this.state.loadSpinnerOpen === true ? (
                      <LoadSpinner open="true"></LoadSpinner>
                    ) : (
                      ""
                    )}
                  </Table>
                  <Form.Group></Form.Group>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" type="submit" size="lg">
                  Save
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
