import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiRefresh } from "react-icons/bi";
import { BsFillPencilFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";

import {
  OverlayTrigger,
  InputGroup,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Modal,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export class AccountReceiveables extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      accountreceiveables: [],
      accountreceiveablesFiltered: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      reload: true,
      selectedStatus: "1",
    };
  }
  GetAccountReceiveables = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetAccountReceiveables/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ accountreceiveables: data }, () => {
            this.setState({
              accountreceiveablesFiltered:
                this.state.accountreceiveables.filter((f) => f.status == 1),
            });
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnSaved = () => {
    this.OnRefreshButtonClick();
  };
  OnDeleted = () => {
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.setState({ selectedStatus: "1" });
    this.setState({ accountreceiveables: [] }, () => {
      this.GetAccountReceiveables();
    });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  OnUpdated = (indexno) => {
    this.OnRefreshButtonClick();
  };

  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
    this.OnRefreshButtonClick();
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    if (this.state.accountreceiveables.length == 0)
      this.GetAccountReceiveables();
  };

  render() {
    return (
      <>
        <div className="Container-fluid p-1 ">
          <Row>
            {window.innerWidth > 1200 ? (
              <>
                <Col className="" xs={2}>
                  <SideMenu></SideMenu>
                </Col>
              </>
            ) : (
              <></>
            )}

            <Col
              xs={window.innerWidth > 1200 ? 10 : 12}
              className="pagebackground p-0"
            >
              {this.state.msgboxOpen === true ? (
                <MessageBox
                  open={this.state.msgboxOpen}
                  title={this.state.msgboxTitle}
                  message={this.state.msgboxMessage}
                  callback={this.CloseMessagBoxCallback}
                />
              ) : (
                ""
              )}
              <Navbar className="secondarythemecolor position-sticky fixed-top px-1 m-0 p-1 text-center border-3 border-dark">
                <h4> &nbsp; &nbsp;Account Receivables &nbsp;</h4>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemecolor"
                        // onClick={() => this.handleAddAccountReceiveable()}
                        onClick={() => this.setState({ addModalShow: true })}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Refresh
                      </Button>
                      &nbsp;
                      {this.state.addModalShow === true ? (
                        <AccountReceiveableAddOrEditModal
                          show={this.state.addModalShow}
                          Type="Add"
                          onHide={this.addModalClose}
                        />
                      ) : null}
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div className="bg-light ">
                <Table className="bg-light m-0 p-0">
                  <tr className="border-0">
                    {/* <td width="20%">
                      <InputGroup className="mb-1">
                        <InputGroup.Text id="basic-addon1">
                          Date Range
                        </InputGroup.Text>
                        <Form.Select
                          value={this.state.dateRange}
                          onChange={(e) => {
                            this.OnDateRangeChanged(e);
                          }}
                        >
                          <option value="Today">Today</option>
                          <option value="Yesterday">Yesterday</option>
                          <option value="Last 3 Days">Last 3 Days</option>
                          <option value="Last 7 Days">Last 7 Days</option>
                          <option value="This Month">This Month</option>
                          <option value="Last Month">Last Month</option>
                          <option value="Last 3 Months">Last 3 Months</option>
                          <option value="All">All</option>
                        </Form.Select>
                      </InputGroup>
                    </td> */}
                    <td width="20%">
                      <InputGroup className="mb-1">
                        <InputGroup.Text id="basic-addon1">
                          Status
                        </InputGroup.Text>
                        <Form.Select
                          value={this.state.selectedStatus}
                          onChange={(e) => {
                            this.setState({ selectedStatus: e.target.value });
                            if (e.target.value == "") {
                              this.setState({
                                accountreceiveablesFiltered:
                                  this.state.accountreceiveables,
                              });
                            } else {
                              this.setState(
                                {
                                  accountreceiveablesFiltered: [],
                                },
                                () => {
                                  this.setState({
                                    accountreceiveablesFiltered:
                                      this.state.accountreceiveables.filter(
                                        (f) => f.status == e.target.value
                                      ),
                                  });
                                }
                              );
                            }
                          }}
                        >
                          <option value=""></option>
                          <option value="1">Yet to Receive</option>
                          <option value="2">Received</option>
                        </Form.Select>
                      </InputGroup>
                    </td>
                  </tr>
                </Table>
              </div>
              <div>
                <Table border striped className="px-0 bg-white ">
                  <thead className="">
                    <tr className="text-start text-black bg-white border">
                      <th width="5%">#</th>
                      <>
                        <th width="10%">Created/Modifed</th>
                        <th width="30%">Details</th>
                        <th width="20%">To ReceiveFrom</th>
                        <th width="10%">Amount</th>
                        <th width="10%">CreatedBy</th>
                        <th width="10%">Status</th>
                        <th width="10%" className="text-end">
                          Options
                        </th>
                      </>
                    </tr>
                  </thead>
                  <tbody className="text-primary border">
                    {this.state.accountreceiveablesFiltered
                      .sort((a, b) => (a.created > b.created ? 1 : -1))
                      .map((accountreceiveable, i) =>
                        this.state.reload === true ? (
                          <EditAccountReceiveableInline
                            index={i}
                            accountreceiveable={accountreceiveable}
                            OnDeleted={this.OnDeleted}
                          ></EditAccountReceiveableInline>
                        ) : null
                      )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export class EditAccountReceiveableInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      accountreceiveable: "",
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      editModalShow: false,
    };
  }

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDelete = () => {
    if (this.state.accountreceiveable.id == undefined) {
      this.ShowMessagBox(
        "Information",
        "This is invalid row which can't be deleted. Press Refresh!"
      );
      return;
    }

    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteAccountReceivable/" +
        this.state.accountreceiveable.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert(
            "Not deleted. This AccountReceiveable may be associated with few Products. First disassociate this accountreceiveable from the Products and then try!"
          );
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Status",
          "Deleted Successfully. Click Refresh Button to see the changes!"
        );
        this.props.OnDeleted();
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("OnDelete Failed" + error);
      });
  };

  componentDidMount = () => {
    this.setState({
      accountreceiveable: this.props.accountreceiveable,
    });
  };
  OnEditButtonClick = () => {
    this.setState({ editModalShow: true }, () => {});
  };

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {});
  };

  render() {
    return (
      <>
        {this.state.editModalShow === true ? (
          <AccountReceiveableAddOrEditModal
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            accountreceiveable={this.state.accountreceiveable}
            Type="Edit"
          />
        ) : (
          ""
        )}
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <tr
          className="text-center align-middle text-secondary bg-white mx-5"
          style={{ fontSize: "12px" }}
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          <td className="text-start ">{this.props.index + 1}</td>
          <td className="text-start ">
            {this.context.GetInDateFormat(
              new Date(this.state.accountreceiveable.created)
            )}
          </td>
          <td className="text-start ">
            {this.state.accountreceiveable.details}
          </td>
          <td className="text-start ">
            {this.state.accountreceiveable.receiveFrom}
          </td>
          <td className="text-start ">
            {this.state.accountreceiveable.amount}
          </td>
          <td className="text-start ">
            {this.state.accountreceiveable.createdBy}
          </td>
          <td className="text-start ">
            {this.state.accountreceiveable.status == 1
              ? "Yet to Receive"
              : "Received"}
          </td>

          <td className="col-md-2 text-end align-middle ">
            <div class="btn-group">
              <OverlayTrigger overlay={<Tooltip>Edit!</Tooltip>}>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    this.OnEditButtonClick(e);
                  }}
                >
                  <BsFillPencilFill size="20" />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                overlay={
                  <Tooltip>
                    Warning! This will delete this Brand Row and associated
                    Products as well.
                  </Tooltip>
                }
              >
                <Button
                  className="mx-1 mainthemecolor"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        "Warning! This action will delete this Brand Row and associated Products as well. Are you sure you want to Delete?"
                      )
                    ) {
                      this.OnDelete(e);
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

export class AccountReceiveableAddOrEditModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      accountreceiveable: {
        accountreceiveableName: "",
        manufacturerName: "",
        imgFileName: "",
        hideMe: false,
      },
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
    };
  }

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  AddOrUpdateAccountReceiveableInDB = () => {
    this.ShowLoadSpinner();

    var tmpAccountReceiveable = this.state.accountreceiveable;
    if (this.props.Type == "Edit") {
      tmpAccountReceiveable = this.props.accountreceiveable;
    } else {
      tmpAccountReceiveable.storeId = this.context.storeId;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmpAccountReceiveable),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/AddOrUpdateAccountReceivable/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.HideLoadSpinner();
          if (tmpAccountReceiveable.status == 2)
            // paid
            alert(
              "Updated Successfully with Information!  Please make sure to add an entry in Revenues/Credit module for accounting purpose!"
            );
          this.props.onHide();
          return;
        }
        this.HideLoadSpinner();
        alert("This AccountReceiveable is already exist. try again!");
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("Exception. Received an Exception. Try after sometime !" + error);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.AddOrUpdateAccountReceiveableInDB();
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "details":
        this.state.accountreceiveable.details = value;
        break;
      case "receiveFrom":
        this.state.accountreceiveable.receiveFrom = value;
        break;
      case "amount":
        this.state.accountreceiveable.amount = value;
        break;
      case "createdBy":
        this.state.accountreceiveable.createdBy = value;
        break;
      case "status":
        this.state.accountreceiveable.status = value;
        break;
    }
    this.setState({
      accountreceiveable: this.state.accountreceiveable,
    });
  };

  componentDidMount() {
    if (this.props.Type == "Edit") {
      this.setState({
        accountreceiveable: this.props.accountreceiveable,
      });
    }
  }

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          dialogClassName="AccountReceiveableModal"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit"
                  ? "Edit Account Receivable"
                  : "Add Account Receivable"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Row
                className="m-2 p-2 bg-light border"
                style={{ fontSize: "9pt" }}
              >
                <Col md={12}>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Receivable Details
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="details"
                        value={this.state.accountreceiveable.details}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Receivable From
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="receiveFrom"
                        value={this.state.accountreceiveable.receiveFrom}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Amount
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        name="amount"
                        value={this.state.accountreceiveable.amount}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Created by
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="createdBy"
                        value={this.state.accountreceiveable.createdBy}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Status
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        name="status"
                        value={this.state.accountreceiveable.status}
                        placeholder=""
                        required
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value=""></option>
                        <option value="1">Yet to Receive</option>
                        <option value="2">Received</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  Save
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
