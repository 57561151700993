import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";

import {
  OverlayTrigger,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export default class Users extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      menuReferences: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
    };
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDeleted = () => {
    this.OnRefreshButtonClick();
  };
  OnSaved = () => {
    // this.setState({ users: this.context.users });
    // this.setState({ reload: false }, () => this.setState({ reload: true }));
    // this.ShowMessagBox("Status", "Saved Successfully!");
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.GetUsers();
    this.setState({ reload: false }, () => {
      this.setState({ users: this.context.users }, () => {
        this.setState({ reload: true });
      });
    });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  handleAddUser = (e) => {
    this.setState({
      users: [
        ...this.state.users,
        {
          storeId: this.context.storeId,
          emailId: "",
          password: "newpassword@123",
          storeRoleId: "",
          // created: new Date().toLocaleString(),
          // modified: new Date().toLocaleString(),
        },
      ],
    });
    alert("An empty row added at the end of list below!");
  };

  handleInputChange = (e, i) => {
    const { name, value } = e.target;

    //validate for minus values not to allow
    switch (name.trim()) {
      case "emailId":
        this.state.users[i].emailId = value;
        break;
      case "password":
        this.state.users[i].password = value;
        break;
      case "storeRoleId":
        this.state.users[i].storeRoleId = value;
        break;
    }
    this.setState({ users: this.state.users });
  };

  GetUsers = () => {
    const config = {
      headers: { Authorization: `Bearer ${this.context.userData.bearertoken}` },
    };
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetStoreUserAccounts/" +
        this.context.storeId,
      config
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ users: data.filter((f) => f.storeRoleId != 1) });
        }
      })
      .catch((error) => {
        alert("Session Timeout! Signout and Signin again!");
      });
  };

  DuplicateValidation = () => {
    this.state.users.map((m, i) => {
      var result = this.state.users.filter(
        (f) => f.emailId.toUpperCase() == m.emailId.toUpperCase()
      );
      if (result.length > 1) {
        alert(
          " UserId should be unique. UserId " + m.emailId + "is already exist!"
        );
        return true;
      }
    });
    return false;
  };

  OnSave = (e) => {
    this.ShowLoadSpinner();

    //Validate
    var validationError = false;
    this.state.users.map((m, i) => {
      if (m.emailId == "" || m.password == "" || m.storeRoleId == "") {
        alert("Error: Value is empty in Row=" + (i + 1));
        validationError = true;
        return;
      }
    });
    if (validationError == true) {
      this.HideLoadSpinner();
      return;
    }

    if (this.DuplicateValidation() == true) {
      this.HideLoadSpinner();
      return;
    }

    const requestOptions = {
      method: "POST",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.context.userData.bearertoken}`,
      },
      body: JSON.stringify(this.state.users),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/AddOrUpdateStoreUsers/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.GetUsers();
          this.HideLoadSpinner();
          this.ShowMessagBox("Information", "Saved Successfully!");
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed!");
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed! " + error);
      });
  };

  OnDelete = (i) => {
    if (this.state.users[i].id == undefined) {
      this.ShowMessagBox(
        "Information",
        "This is invalid row which can't be deleted. Press Refresh!"
      );
      return;
    }

    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.context.userData.bearertoken}`,
      },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteUser/" +
        this.state.users[i].id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert("not deleted");
          return;
        }
        this.GetUsers();
        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Status",
          "Deleted Successfully. Click Refresh Button to see the changes!"
        );
        this.OnDeleted();
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("OnDelete Failed" + error);
      });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    if (this.state.users.length == 0) this.GetUsers();
  };

  render() {
    return (
      <>
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <div className="Container-fluid p-1 ">
          <Row>
            <Col className="" xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="pagebackground p-0">
              {this.state.msgboxOpen === true ? (
                <MessageBox
                  open={this.state.msgboxOpen}
                  title={this.state.msgboxTitle}
                  message={this.state.msgboxMessage}
                  callback={this.CloseMessagBoxCallback}
                />
              ) : (
                ""
              )}
              <Navbar
                expand="lg"
                className="secondarythemecolor position-sticky fixed-top  px-3"
              >
                <h4> &nbsp; &nbsp;users &nbsp;</h4>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemecolor"
                        // hidden={
                        //   this.context.UserRoles.PRODUCTADMIN ==
                        //   this.context.userData.roleId
                        //     ? false
                        //     : true
                        // }
                        disabled={
                          this.state.users &&
                          this.state.users.length >=
                            (this.context.storeSettings.totalErpAdminUsers
                              ? this.context.storeSettings.totalErpAdminUsers
                              : 0) +
                              (this.context.storeSettings
                                .totalErpContributorUsers
                                ? this.context.storeSettings
                                    .totalErpContributorUsers
                                : 0)
                            ? true
                            : false
                        }
                        onClick={() => this.handleAddUser()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={(e) => {
                          this.OnSave(e);
                        }}
                      >
                        <BiSave size="26" />
                        Save
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Refresh
                      </Button>
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div>
                <Table border striped className="px-0 bg-white ">
                  <thead className="">
                    <tr className="text-start text-black bg-white border ">
                      {/* <th width="20%">Menu Name</th> */}
                      <th>#</th>
                      <th width="20%">UserId</th>
                      <th width="30%">Password</th>
                      <th width="20%">Role</th>
                      <th width="30%" className="text-center">
                        Options
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-primary border">
                    {this.state.users != undefined
                      ? this.state.users.map((mainMenu, i) => (
                          <>
                            <tr>
                              <td>{i + 1}</td>
                              <td>
                                <Form.Control
                                  type="text"
                                  name="emailId"
                                  value={this.state.users[i].emailId}
                                  required
                                  className="text-start  "
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e, i)}
                                />
                              </td>
                              <td>
                                <Form.Control
                                  type="text"
                                  name="password"
                                  value={this.state.users[i].password}
                                  required
                                  className="text-start  "
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e, i)}
                                />
                              </td>
                              <td>
                                <Form.Select
                                  size=""
                                  name="storeRoleId"
                                  value={this.state.users[i].storeRoleId}
                                  onChange={(e) => this.handleInputChange(e, i)}
                                >
                                  <option value={0}></option>
                                  {this.context.storeRoles.map((e) => (
                                    <>
                                      {e.name != "ProductAdmin" ? (
                                        <option value={e.id}>{e.name}</option>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  ))}
                                </Form.Select>
                              </td>
                              <td className="text-center align-middle">
                                <Button
                                  className="mx-1 mainthemecolor"
                                  onClick={(e) => {
                                    if (
                                      window.confirm(
                                        "Warning! This action will delete this Carousel Row and associated Products as well. Are you sure you want to Delete?"
                                      )
                                    ) {
                                      this.OnDelete(i);
                                    }
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="25"
                                    height="25"
                                    fill="currentColor"
                                    class="bi bi-trash"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                    <path
                                      fill-rule="evenodd"
                                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                    />
                                  </svg>
                                </Button>
                              </td>
                            </tr>
                          </>
                        ))
                      : ""}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
