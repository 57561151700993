import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";

import {
  OverlayTrigger,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export class HomeSettingsMainMenu extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      mainMenus: [],
      menuReferences: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
    };
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDeleted = () => {
    this.OnRefreshButtonClick();
  };
  OnSaved = () => {
    // this.setState({ mainMenus: this.context.mainMenus });
    // this.setState({ reload: false }, () => this.setState({ reload: true }));
    // this.ShowMessagBox("Status", "Saved Successfully!");
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    // this.context.GetMainMenus();
    this.setState({ mainMenus: [] }, () => {
      this.setState({ reload: false }, () => {
        this.setState({ mainMenus: this.context.mainMenus }, () => {
          this.setState({ reload: true });
        });
      });
    });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  handleAddMainMenu = (e) => {
    this.setState({
      mainMenus: [
        ...this.state.mainMenus,
        {
          storeId: this.context.storeId,
          menutype: "",
          mainCategoryId: "",
          subCategoryId: "",
          productGroupId: "",
          seqNo: this.state.mainMenus.length + 1,
        },
      ],
    });
    alert("An empty row added at the end of list below!");
  };

  handleInputChange = (e, i) => {
    const { name, value } = e.target;

    //validate for minus values not to allow
    switch (name.trim()) {
      // case "name":
      //   this.state.mainMenus[i].name = value;
      //   break;
      case "menutype":
        this.state.mainMenus[i].menutype = value;
        break;
      case "mainCategoryId":
        this.state.mainMenus[i].mainCategoryId = value;
        break;
      case "subCategoryId":
        this.state.mainMenus[i].subCategoryId = value;
        break;
      case "productGroupId":
        this.state.mainMenus[i].productGroupId = value;
        break;
      case "seqNo":
        this.state.mainMenus[i].seqNo = value;
        break;
    }
    this.setState({ mainMenus: this.state.mainMenus });
  };

  OnSave = (e) => {
    this.ShowLoadSpinner();

    //Validate
    var validationError = false;
    this.state.mainMenus.map((m, i) => {
      if (m.menutype == "") {
        alert("Error: Value is empty in Row=" + (i + 1));
        validationError = true;
        return;
      } else {
        if (
          (m.menutype == 1 && m.mainCategoryId == "") ||
          (m.menutype == 2 && m.subCategoryId == "") ||
          (m.menutype == 3 && m.productGroupId == "")
        ) {
          alert("Error: Value is empty in Row=" + (i + 1));
          validationError = true;
        }
      }
    });
    if (validationError == true) {
      this.HideLoadSpinner();
      return;
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.mainMenus),
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/AddOrUpdateMainMenu/" +
        this.context.storeId,
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.context.GetMainMenus();
          this.HideLoadSpinner();
          this.ShowMessagBox("Information", "Saved Successfully!");
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed!");
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed! " + error);
      });
  };

  OnDelete = (i) => {
    if (this.state.mainMenus[i].id == undefined) {
      this.ShowMessagBox(
        "Information",
        "This is invalid row which can't be deleted. Press Refresh!"
      );
      return;
    }

    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteMenu/" +
        this.state.mainMenus[i].id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert("not deleted");
          return;
        }
        this.context.GetMainMenus();
        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Status",
          "Deleted Successfully. Click Refresh Button to see the changes!"
        );
        this.OnDeleted();
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("OnDelete Failed" + error);
      });
  };

  componentDidMount = () => {
    this.setState({ mainMenus: this.context.mainMenus });
    this.setState({ reload: true });
  };

  render() {
    return (
      <>
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <div className="Container-fluid p-1 ">
          <Row>
            <Col className="" xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="pagebackground p-0">
              {this.state.msgboxOpen === true ? (
                <MessageBox
                  open={this.state.msgboxOpen}
                  title={this.state.msgboxTitle}
                  message={this.state.msgboxMessage}
                  callback={this.CloseMessagBoxCallback}
                />
              ) : (
                ""
              )}
              <Navbar
                expand="lg"
                className="secondarythemecolor position-sticky fixed-top  px-3"
              >
                <h4> &nbsp; &nbsp;MainMenus &nbsp;</h4>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemecolor"
                        onClick={() => this.handleAddMainMenu()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={(e) => {
                          this.OnSave(e);
                        }}
                      >
                        <BiSave size="26" />
                        Save
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Refresh
                      </Button>
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div>
                <Table border striped className="px-0 bg-white ">
                  <thead className="">
                    <tr className="text-center text-black bg-white border">
                      {/* <th width="20%">Menu Name</th> */}
                      <th width="20%">Menu Type</th>
                      <th width="30%">Menu Reference Name</th>
                      <th width="10%">Sequence No</th>
                      <th width="20%" className="text-center">
                        Options
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-primary border">
                    {this.state.mainMenus.map((mainMenu, i) => (
                      <>
                        <tr>
                          {/* <td>
                            <Form.Control
                              type="text"
                              name="name"
                              value={this.state.mainMenus[i].name}
                              required
                              className="text-start  "
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e, i)}
                            />
                          </td> */}
                          <td>
                            <Form.Select
                              size=""
                              name="menutype"
                              value={this.state.mainMenus[i].menutype}
                              onChange={(e) => this.handleInputChange(e, i)}
                            >
                              <option value={0}></option>
                              <option value={1}>MainCategory</option>
                              <option value={2}>SubCategory</option>
                              <option value={3}>ProductGroup</option>
                            </Form.Select>
                          </td>
                          <td>
                            {this.state.mainMenus[i].menutype == 1 ? (
                              <Form.Select
                                size=""
                                name="mainCategoryId"
                                value={this.state.mainMenus[i].mainCategoryId}
                                onChange={(e) => this.handleInputChange(e, i)}
                              >
                                <option value=""></option>
                                {this.context.mainCategories.map((m) => (
                                  <>
                                    <option value={m.id}>{m.name}</option>
                                  </>
                                ))}
                              </Form.Select>
                            ) : this.state.mainMenus[i].menutype == 2 ? (
                              <Form.Select
                                size=""
                                name="subCategoryId"
                                value={this.state.mainMenus[i].subCategoryId}
                                onChange={(e) => this.handleInputChange(e, i)}
                              >
                                <option value=""></option>
                                {this.context.subCategories.map((m) => (
                                  <>
                                    <option value={m.id}>{m.name}</option>
                                  </>
                                ))}
                              </Form.Select>
                            ) : this.state.mainMenus[i].menutype == 3 ? (
                              <Form.Select
                                size=""
                                name="productGroupId"
                                value={this.state.mainMenus[i].productGroupId}
                                onChange={(e) => this.handleInputChange(e, i)}
                              >
                                <option value=""></option>
                                {this.context.productGroups.map((m) => (
                                  <>
                                    <option value={m.id}>{m.name}</option>
                                  </>
                                ))}
                              </Form.Select>
                            ) : (
                              ""
                            )}
                          </td>
                          <td>
                            {" "}
                            <Form.Control
                              type="number"
                              name="seqNo"
                              min={0}
                              value={mainMenu.seqNo}
                              required
                              placeholder=""
                              align="center"
                              className="text-center  "
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e, i)}
                            />
                          </td>
                          <td className="text-center align-middle">
                            <Button
                              className="mx-1 mainthemecolor"
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Warning! This action will delete this Carousel Row and associated Products as well. Are you sure you want to Delete?"
                                  )
                                ) {
                                  this.OnDelete(i);
                                }
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                class="bi bi-trash"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path
                                  fill-rule="evenodd"
                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                />
                              </svg>
                            </Button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
