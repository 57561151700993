import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { ProductServiceTasksCategory } from "./ProductServiceTasksCategory";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import { BsFillPencilFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import axios from "axios";
import Fuse from "fuse.js";

import {
  OverlayTrigger,
  Tooltip,
  Stack,
  FormControl,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Modal,
  InputGroup,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export class ProductServiceTaskInventory extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      productservicetasksinventory: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      reload: true,
      productservicetaskscategory: [],
      productservicetasksinventoryFiltered: [],
      categoryFilterName: "",
      loadSpinnerOpen: false,
    };
    this.inputFile = React.createRef();
  }
  SetCategoryFilterName = (cname) => {
    this.setState({ reload: false });
    this.setState({ categoryFilterName: cname }, () => {
      this.setState({ reload: true });
    });
  };
  GetProductServiceTasksCategories = () => {
    fetch(
      process.env.REACT_APP_API +
        "ManufactureAndService/GetProductServiceTasksCategories/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ productservicetaskscategory: data }, () => {
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  GetProductServiceTasksInventories = () => {
    fetch(
      process.env.REACT_APP_API +
        "ManufactureAndService/GetProductServiceTasksInventories/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({
            productservicetasksinventoryFiltered: data,
          });
          this.setState({ productservicetasksinventory: data }, () => {
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnSaved = () => {
    this.OnRefreshButtonClick();
  };
  OnDeleted = () => {
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.setState({ productservicetasksinventory: [] }, () => {
      this.GetProductServiceTasksInventories();
    });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  OnUpdated = (indexno) => {
    this.OnRefreshButtonClick();
  };

  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
    this.OnRefreshButtonClick();
  };

  onSearchChange = (query) => {
    this.setState({ reload: false });
    this.setState({ searchquery: query });
    if (!query) {
      this.setState(
        {
          productservicetasksinventoryFiltered:
            this.state.productservicetasksinventory,
        },
        () => {
          this.setState({ reload: true });
        }
      );
      return;
    }
    const fuse = new Fuse(this.state.productservicetasksinventory, {
      keys: ["name"],
      threshold: 0.6,
      ignoreLocation: true,
      findAllMatches: true,
      includeMatches: true,
      distance: 120,
    });
    const result = fuse.search(query);
    const finalResult = [];
    if (result.length) {
      result.forEach((item) => {
        finalResult.push(item.item);
      });
      this.setState(
        { productservicetasksinventoryFiltered: finalResult.slice(0, 15) },
        () => {
          this.setState({ reload: true });
        }
      );
    } else {
      this.setState({ productservicetasksinventoryFiltered: [] });
    }
  };

  OnCategoryChanged = (e) => {
    this.setState({ reload: false });
    this.setState({ categoryFilterName: e.target.value }, () => {
      this.setState({ reload: true });
    });
  };

  onUploadButtonClick = () => {
    this.inputFile.current.click();
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  handleFileUpload = (e) => {
    this.ShowLoadSpinner();
    const { files } = e.target;

    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      this.UploadProductsFromCSV(text);
    };
    reader.readAsText(files[0]);
  };

  UploadProductsFromCSV = (csvstr) => {
    this.ShowLoadSpinner();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(csvstr),
    };

    fetch(
      process.env.REACT_APP_API +
        "ManufactureAndService/UploadProductServiceTasksFromCsv/" +
        this.context.storeId,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 404) {
          return;
        }
        this.OnRefreshButtonClick();
        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Information",
          "Uploaded Successfully. Please Refresh(f5) the Portal! \r\n" +
            "\r\n" +
            data
        );
      })
      .catch((error) => {
        this.HideLoadSpinner();
        // this.ShowMessagBox("Failed", "Unable to Add! try later");
        alert("Failed" + error);
      });
  };
  DownloadProductsToCSV = () => {
    this.ShowLoadSpinner();
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "ManufactureAndService/DownloadProductServiceTasksToCSV/" +
          this.context.storeId
      )
      .then((response) => {
        if (response.status === 200) {
          this.HideLoadSpinner();
          const element = document.createElement("a");
          const file = new Blob([response.data], {
            type: "text/plain",
          });
          element.href = URL.createObjectURL(file);
          element.download =
            "productservicetask-" +
            new Date().getDate() +
            (new Date().getMonth() + 1) +
            new Date().getFullYear() +
            "-" +
            new Date().getMinutes() +
            new Date().getSeconds() +
            ".csv";
          document.body.appendChild(element); // Required for this to work in FireFox
          element.click();
        } else {
          this.HideLoadSpinner();
          this.ShowMessagBox("Error", "Download Failed. Please try later!");
        }
      })
      .catch((error) => {
        this.HideLoadSpinner();
        console.error("DownloadProductsToCSV - There was an error! ", error);
      });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    if (this.state.productservicetasksinventory.length == 0)
      this.GetProductServiceTasksInventories();
    this.GetProductServiceTasksCategories();
  };

  render() {
    return (
      <>
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <div className="Container-fluid p-1 ">
          <Row>
            <Col className="" xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="pagebackground p-0">
              <input
                style={{ display: "none" }}
                accept=".csv"
                ref={this.inputFile}
                onChange={this.handleFileUpload}
                type="file"
                multiple="single"
              />
              <Row>
                <Col xs={3} className="bg-light border">
                  <div
                    className="fixedTableHightOrder"
                    style={{
                      height: window.innerHeight - 80 + "px",
                    }}
                  >
                    <ProductServiceTasksCategory
                      update={this.GetProductServiceTasksCategories}
                      SetCategoryFilterName={this.SetCategoryFilterName}
                    ></ProductServiceTasksCategory>
                  </div>
                </Col>
                <Col xs={9}>
                  <div
                    className="fixedTableHightOrder"
                    style={{
                      height: window.innerHeight - 80 + "px",
                    }}
                  >
                    {this.state.msgboxOpen === true ? (
                      <MessageBox
                        open={this.state.msgboxOpen}
                        title={this.state.msgboxTitle}
                        message={this.state.msgboxMessage}
                        callback={this.CloseMessagBoxCallback}
                      />
                    ) : (
                      ""
                    )}
                    <Navbar
                      expand="lg"
                      className="secondarythemecolor position-sticky fixed-top  px-3"
                    >
                      <h5>
                        {" "}
                        &nbsp; &nbsp;<b>Service Tasks - Inventory</b> &nbsp;
                      </h5>
                      <Navbar.Collapse className="justify-content-end mx-0 ">
                        <Nav>
                          <ButtonToolbar className="text-end align-middle">
                            <Button
                              className="mainthemecolor"
                              // onClick={() => this.handleAddProductServiceTasksInventory()}
                              onClick={() =>
                                this.setState({ addModalShow: true })
                              }
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-plus"
                                viewBox="0 0 16 16"
                              >
                                <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                              </svg>
                              Add
                            </Button>
                            &nbsp;
                            <Button
                              className="mainthemecolor"
                              size=""
                              onClick={() => this.OnRefreshButtonClick()}
                            >
                              <BiRefresh size="20"></BiRefresh>
                              Refresh
                            </Button>
                            &nbsp;
                            <Button
                              className="bg-warning text-dark buttonRounded"
                              onClick={(e) => this.onUploadButtonClick()}
                            >
                              <BiUpload
                                size="20"
                                className="bg-warning text-dark"
                              ></BiUpload>
                              Upload from CSV
                            </Button>
                            &nbsp;
                            <Button
                              className="bg-warning text-dark buttonRounded"
                              size=""
                              onClick={(e) => this.DownloadProductsToCSV()}
                            >
                              <BiDownload
                                size="20"
                                className="bg-warning text-dark"
                              ></BiDownload>
                              Download as CSV
                            </Button>
                            {this.state.addModalShow === true ? (
                              <ProductServiceTasksInventoryAddOrEditModal
                                show={this.state.addModalShow}
                                Type="Add"
                                onHide={this.addModalClose}
                                productservicetaskscategory={
                                  this.state.productservicetaskscategory
                                }
                              />
                            ) : null}
                          </ButtonToolbar>
                        </Nav>
                      </Navbar.Collapse>
                    </Navbar>
                    <div className="p-3">
                      <Row>
                        <Col>
                          <Stack direction="horizontal" gap={3} className="">
                            <b>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="16"
                                height="16"
                                fill="currentColor"
                                class="bi bi-search"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                              </svg>
                            </b>
                            <FormControl
                              type="search"
                              placeholder="Search  "
                              className="text-primary fw-bold bg-white "
                              aria-label="Search"
                              onChange={(e) =>
                                this.onSearchChange(e.target.value)
                              }
                            />
                          </Stack>
                        </Col>
                        <Col>
                          <InputGroup className="">
                            <InputGroup.Text id="basic-addon1">
                              Category
                            </InputGroup.Text>
                            <Form.Select
                              value={this.state.categoryFilterName}
                              onChange={(e) => {
                                this.OnCategoryChanged(e);
                              }}
                            >
                              <option value=""></option>
                              {this.state.productservicetaskscategory.map(
                                (m) => (
                                  <>
                                    <option value={m.id}>{m.name}</option>
                                  </>
                                )
                              )}
                            </Form.Select>
                          </InputGroup>
                        </Col>
                      </Row>
                    </div>
                    <div>
                      <Table border striped className="px-0 bg-white ">
                        <thead className="">
                          <tr className="text-center text-black bg-white border">
                            <th width="3%"></th>
                            <th width="22%" className="text-start">
                              Task Name
                            </th>
                            <th width="30%" className="text-start">
                              Description
                            </th>
                            <th width="10%" className="text-start">
                              Cost/Estimation
                            </th>
                            <th width="10%" className="text-center">
                              Effort in Hours
                            </th>
                            <th width="20%" className="text-center">
                              Raw Materials required
                            </th>
                            <th width="10%" className="text-end">
                              Options
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-primary border">
                          {this.state.productservicetasksinventoryFiltered
                            .filter((f) => {
                              if (
                                this.state.categoryFilterName != "" &&
                                this.state.categoryFilterName == f.categoryId
                              ) {
                                return true;
                              } else if (this.state.categoryFilterName == "") {
                                return true;
                              } else {
                                return false;
                              }
                            })
                            // .sort((a, b) =>
                            //   a.name.toUpperCase() > b.name.toUpperCase()
                            //     ? 1
                            //     : -1
                            // )
                            .map((productservicetasksinventory, i) =>
                              this.state.reload === true ? (
                                <EditProductServiceTasksInventoryInline
                                  index={i}
                                  productservicetasksinventory={
                                    productservicetasksinventory
                                  }
                                  OnDeleted={this.OnDeleted}
                                  productservicetaskscategory={
                                    this.state.productservicetaskscategory
                                  }
                                ></EditProductServiceTasksInventoryInline>
                              ) : null
                            )}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export class EditProductServiceTasksInventoryInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      productservicetasksinventory: "",
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      editModalShow: false,
    };
  }

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDelete = () => {
    if (this.state.productservicetasksinventory.id == undefined) {
      this.ShowMessagBox(
        "Information",
        "This is invalid row which can't be deleted. Press Refresh!"
      );
      return;
    }

    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "ManufactureAndService/DeleteProductServiceTasksInventory/" +
        this.state.productservicetasksinventory.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert(
            "Not deleted. This ProductServiceTasksInventory may be associated with few Products. First disassociate this productservicetasksinventory from the Products and then try!"
          );
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Status",
          "Deleted Successfully. Click Refresh Button to see the changes!"
        );
        this.props.OnDeleted();
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("OnDelete Failed" + error);
      });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name.trim()) {
      case "taskName":
        this.state.productservicetasksinventory.taskName = value;
        break;
      case "taskDescription":
        this.state.productservicetasksinventory.taskDescription = value;
        break;
      case "costEstimation":
        this.state.productservicetasksinventory.costEstimation = value;
        break;
      case "effortInHours":
        this.state.productservicetasksinventory.effortInHours = value;
        break;
      case "rawMaterialsRequired":
        this.state.productservicetasksinventory.rawMaterialsRequired = value;
        break;
    }
    this.setState({
      productservicetasksinventory: this.state.productservicetasksinventory,
    });
  };

  componentDidMount = () => {
    this.setState({
      productservicetasksinventory: this.props.productservicetasksinventory,
    });
  };
  OnEditButtonClick = () => {
    this.setState({ editModalShow: true }, () => {});
  };

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {});
  };

  GetCategoryName = (categoryId) => {
    var result = this.props.productservicetaskscategory.filter(
      (f) => f.id == categoryId
    );
    if (result.length > 0) {
      return result[0].name;
    }
    return "";
  };
  OnSave = () => {
    this.ShowLoadSpinner();

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.productservicetasksinventory),
    };

    fetch(
      process.env.REACT_APP_API +
        "ManufactureAndService/AddOrUpdateProductServiceTasksInventory/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.HideLoadSpinner();
          return;
        }
        this.HideLoadSpinner();
        alert("This ProductServiceTasksInventory is already exist. try again!");
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("Exception. Received an Exception. Try after sometime !" + error);
      });
  };

  render() {
    return (
      <>
        {this.state.editModalShow === true ? (
          <ProductServiceTasksInventoryAddOrEditModal
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            productservicetasksinventory={
              this.state.productservicetasksinventory
            }
            Type="Edit"
            productservicetaskscategory={this.props.productservicetaskscategory}
          />
        ) : (
          ""
        )}
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <tr
          className="text-center align-middle text-secondary bg-white mx-5"
          style={{ fontSize: "12px" }}
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          <td>{this.props.index + 1}</td>
          <td className="text-start ">
            <Form.Control
              type="text"
              name="taskName"
              value={this.state.productservicetasksinventory.taskName}
              placeholder=""
              min={0}
              align="center"
              className="text-start "
              autoComplete="off"
              onChange={(e) => this.handleInputChange(e)}
            />
          </td>
          <td className="text-start ">
            <Form.Control
              type="text"
              name="taskDescription"
              value={this.state.productservicetasksinventory.taskDescription}
              placeholder=""
              min={0}
              align="center"
              className="text-start "
              autoComplete="off"
              onChange={(e) => this.handleInputChange(e)}
            />
          </td>
          <td>
            <Form.Control
              type="number"
              name="costEstimation"
              value={this.state.productservicetasksinventory.costEstimation}
              placeholder=""
              min={0}
              align="center"
              className="text-center  "
              autoComplete="off"
              onChange={(e) => this.handleInputChange(e)}
            />
          </td>
          <td>
            <Form.Control
              type="number"
              name="effortInHours"
              value={this.state.productservicetasksinventory.effortInHours}
              placeholder=""
              min={0}
              align="center"
              className="text-center  "
              autoComplete="off"
              onChange={(e) => this.handleInputChange(e)}
            />
          </td>
          <td className="text-start ">
            <Form.Control
              type="text"
              name="rawMaterialsRequired"
              value={
                this.state.productservicetasksinventory.rawMaterialsRequired
              }
              placeholder=""
              min={0}
              align="center"
              className="text-start "
              autoComplete="off"
              onChange={(e) => this.handleInputChange(e)}
            />
          </td>
          <td className="col-md-2 text-end align-middle ">
            <div class="btn-group">
              <OverlayTrigger overlay={<Tooltip>Save</Tooltip>}>
                <Button
                  className="mainthemecolor rounded"
                  onClick={(e) => {
                    this.OnSave(e);
                  }}
                >
                  <BiSave size="16" />
                </Button>
              </OverlayTrigger>
              &nbsp;
              <OverlayTrigger overlay={<Tooltip>Edit!</Tooltip>}>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    this.OnEditButtonClick(e);
                  }}
                >
                  <BsFillPencilFill size="16" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                overlay={
                  <Tooltip>Warning! This will delete all dependents.</Tooltip>
                }
              >
                <Button
                  className="mx-1 mainthemecolor"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        "Warning! This action will delete all it dependents. Are you sure you want to Delete?"
                      )
                    ) {
                      this.OnDelete(e);
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

export class ProductServiceTasksInventoryAddOrEditModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      productservicetasksinventory: {},
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
    };
  }

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  AddOrUpdateProductServiceTasksInventoryInDB = () => {
    this.ShowLoadSpinner();

    var tmpProductServiceTasksInventory =
      this.state.productservicetasksinventory;
    if (this.props.Type == "Edit") {
    } else {
      tmpProductServiceTasksInventory.storeId = this.context.storeId;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmpProductServiceTasksInventory),
    };

    fetch(
      process.env.REACT_APP_API +
        "ManufactureAndService/AddOrUpdateProductServiceTasksInventory/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.HideLoadSpinner();
          this.props.onHide();
          return;
        }
        this.HideLoadSpinner();
        alert("This ProductServiceTasksInventory is already exist. try again!");
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("Exception. Received an Exception. Try after sometime !" + error);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.AddOrUpdateProductServiceTasksInventoryInDB();
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name.trim()) {
      case "taskName":
        this.state.productservicetasksinventory.taskName = value;
        break;
      case "categoryId":
        this.state.productservicetasksinventory.categoryId = value;
        break;
      case "taskDescription":
        this.state.productservicetasksinventory.taskDescription = value;
        break;
      case "costEstimation":
        this.state.productservicetasksinventory.costEstimation = value;
        break;
      case "effortInHours":
        this.state.productservicetasksinventory.effortInHours = value;
        break;
      case "rawMaterialsRequired":
        this.state.productservicetasksinventory.rawMaterialsRequired = value;
        break;
    }
    this.setState({
      productservicetasksinventory: this.state.productservicetasksinventory,
    });
  };

  componentDidMount() {
    if (this.props.Type == "Edit") {
      this.setState({
        productservicetasksinventory: this.props.productservicetasksinventory,
      });
    }
    if (document.getElementById("name") != null)
      document.getElementById("name").focus();
  }

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          dialogClassName="ProductServiceTasksInventoryModal"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit"
                  ? "Edit ProductServiceTask"
                  : "Add ProductServiceTask"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Row
                className="m-2 p-2 bg-light border"
                style={{ fontSize: "9pt" }}
              >
                <Col md={12}>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Name
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        id="name"
                        name="taskName"
                        maxlength="99"
                        value={this.state.productservicetasksinventory.taskName}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Category Name
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        name="categoryId"
                        value={
                          this.state.productservicetasksinventory.categoryId
                        }
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value=""></option>
                        {this.props.productservicetaskscategory &&
                          this.props.productservicetaskscategory.map((r) => (
                            <option value={r.id}>{r.name}</option>
                          ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Task Description
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        as="textarea"
                        type="textarea"
                        rows={3}
                        id="taskDescription"
                        name="taskDescription"
                        maxlength="499"
                        value={
                          this.state.productservicetasksinventory
                            .taskDescription
                        }
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Cost Estimation
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        id="costEstimation"
                        name="costEstimation"
                        value={
                          this.state.productservicetasksinventory.costEstimation
                        }
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Efforts required in Hrs
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        id="effortInHours"
                        name="effortInHours"
                        value={
                          this.state.productservicetasksinventory.effortInHours
                        }
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Raw Materials Required
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        as="textarea"
                        type="textarea"
                        rows={5}
                        id="rawMaterialsRequired"
                        name="rawMaterialsRequired"
                        value={
                          this.state.productservicetasksinventory
                            .rawMaterialsRequired
                        }
                        required
                        maxlength="1999"
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  Save
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
