import React, { Component } from "react";
import { useState } from "react";
import { SideMenu } from "./SideMenu";
import CartContext from "./CartContext";
import { BsFillPencilFill } from "react-icons/bs";
import Fuse from "fuse.js";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import axios from "axios";
import Switch from "react-switch";

import {
  Nav,
  Stack,
  InputGroup,
  FormControl,
  Table,
  OverlayTrigger,
  Tooltip,
  Navbar,
  ListGroup,
} from "react-bootstrap";
import { Button, ButtonToolbar, Form, Row, Col } from "react-bootstrap";

import { ProductGroupAddOrEditModal } from "./ProductGroupAddOrEditModal";
import { ProductGroupAndProductsMapping } from "./ProductGroupAndProductsMapping";

export class ProductGroups extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      productGroups: [],
      addModalShow: false,
      editModalShow: false,

      reload: true,
      loading: true,
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
    };
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDeleted = (indexno) => {
    this.GetproductGroups();
    this.setState({ reload: false });
    const list = [...this.state.productGroups];
    list.splice(indexno, 1);
    this.setState({ productGroups: list }, () => {
      this.setState({ reload: true }, () => {
        this.ShowMessagBox("Information", "Deleted Successfully!");
      });
    });
  };

  OnUpdated = (indexno) => {
    this.GetproductGroups();
    this.setState({ reload: true }, () => this.setState({ reload: true }));
  };

  OnRefreshButtonClick = () => {
    this.setState({ productGroups: [] });
    this.GetproductGroups();
    this.setState({ reload: false }, () => {
      this.setState({ reload: true });
    });
  };
  useForceUpdate = () => {
    const [value, setValue] = useState(0); // integer state
    return () =>
      setValue(
        (value) => value + 1,
        () => {}
      ); // update the state to force render
  };
  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
  };

  fakeRequest = () => {
    // var waittime = (this.context.productGroups.length / 100) * 1000;
    return new Promise((resolve) => setTimeout(() => resolve(), 1000));
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fakeRequest().then(() => {
      this.setState({ loading: false }); // showing the app
    });

    if (this.state.productGroups.length == 0) this.GetproductGroups();
  }

  GetproductGroups = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetproductGroups/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ productGroups: data });
        }
      })
      .catch((error) => {
        alert("GetproductGroups:: " + error);
      });
  };

  componentDidUpdate() {}

  render() {
    if (this.state.loading === true) {
      return (
        <>
          <LoadSpinner open="true"></LoadSpinner>
        </>
      );
    }

    const {} = this.state;
    return (
      <div className="Container-fluid p-1 pagebackground">
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        {this.state.msgboxOpen === true ? (
          <MessageBox
            open={this.state.msgboxOpen}
            title={this.state.msgboxTitle}
            message={this.state.msgboxMessage}
            callback={this.CloseMessagBoxCallback}
          />
        ) : (
          ""
        )}

        <Row>
          <Col className="" xs={2}>
            <SideMenu></SideMenu>
          </Col>
          <Col xs={10} className="pagebackground">
            <Navbar className="secondarythemecolor position-sticky fixed-top  my-0">
              <h3> &nbsp; &nbsp;PRODUCT GROUPS &nbsp;</h3>
              <Navbar.Collapse className="justify-content-end mx-1 ">
                <Nav>
                  <ButtonToolbar className="text-end align-middle">
                    <Button
                      className="mainthemecolor"
                      onClick={() => this.setState({ addModalShow: true })}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="30"
                        height="30"
                        fill="currentColor"
                        class="bi bi-plus"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                      Add
                    </Button>
                    &nbsp;
                    <Button
                      className="mainthemecolor"
                      onClick={() => this.OnRefreshButtonClick()}
                    >
                      <BiRefresh
                        size="26"
                        className="mainthemecolor"
                      ></BiRefresh>
                      Refresh
                    </Button>
                    &nbsp;
                    {/* this logical check is required to deal with object destraction after close or submit the model */}
                    {this.state.addModalShow === true ? (
                      <ProductGroupAddOrEditModal
                        show={this.state.addModalShow}
                        Type="Add"
                        onHide={this.addModalClose}
                      />
                    ) : null}
                  </ButtonToolbar>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <Row>
              <Col xs={12} className=" py-1">
                <div className=" ">
                  <Table border striped className="px-0 bg-white ">
                    <thead className="">
                      <tr className="text-center text-black bg-white border">
                        <th width="5%"></th>
                        <th className="text-center" width="15%">
                          Group Name
                        </th>
                        <th width="10%">Select Products</th>
                        <th width="10%">Image FileName</th>
                        <th width="10%">Image </th>
                        <th width="10%">SeqNo</th>
                        <th width="10%">Show Me</th>
                        <th width="10%">Ads Image FileName</th>
                        <th width="10%">Ads Image</th>

                        <th width="10%" className="text-center">
                          Options
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-primary border">
                      {this.state.productGroups
                        .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                        .map((productGroup, i) =>
                          this.state.reload === true ? (
                            <EditproductGroupInline
                              index={i}
                              productGroup={productGroup}
                              OnDeleted={this.OnDeleted}
                              OnUpdated={this.OnUpdated}
                            ></EditproductGroupInline>
                          ) : null
                        )}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export class EditproductGroupInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      productGroup: this.props.productGroup,
      showProducMappingModel: false,
      editModalShow: false,
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
    };
  }
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };
  OnCloseProductMappingModel = () => {
    this.setState({ showProducMappingModel: false });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    //validate for minus values not to allow
    switch (name.trim()) {
      case "name":
        this.state.productGroup.name = value;
        break;
      case "imgFileName":
        this.state.productGroup.imgFileName = value;
        break;
      case "seqNo":
        this.state.productGroup.seqNo = value;
        break;
      case "hideMe":
        this.state.productGroup.hideMe = value;
        break;
      case "adsImgFileName":
        this.state.productGroup.adsImgFileName = value;
        break;
    }
    this.setState({ productGroup: this.state.productGroup });
  };

  OnDelete = () => {
    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteProductGroup/" +
        this.state.productGroup.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          this.ShowMessagBox("Error", "Delete Failed! try after sometime");
          return;
        }
        this.context.GetproductGroups();
        this.HideLoadSpinner();
        this.props.OnDeleted(this.props.index);
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Delete Failed! try after sometime");
      });
  };

  OnSave = () => {
    this.ShowLoadSpinner();
    var tmpproductGroup = this.state.productGroup;
    //tmpproductGroup.hideMe = this.state.productGroup.hideMe;

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify({ tmpproductGroup.JSON }),
      body: JSON.stringify(tmpproductGroup),
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/UpdateProductGroup/" +
        this.state.productGroup.id,
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.context.GetProductGroups();
          this.HideLoadSpinner();
          this.ShowMessagBox("Information", "Saved Successfully!");
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed!");
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed! " + error);
      });
  };

  OnEditButtonClick = () => {
    this.setState({ editModalShow: true }, () => {});
  };

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {
      this.props.OnUpdated();
    });
  };

  handleHidemeChange = (checked) => {
    this.state.productGroup.hideMe = checked == false ? true : false;
    this.setState({ productGroup: this.state.productGroup });
  };

  OnSelectProducts = () => {
    this.setState({ showProducMappingModel: true });
  };
  componentDidMount() {}

  render() {
    return (
      <>
        {this.state.editModalShow === true ? (
          <ProductGroupAddOrEditModal
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            productGroup={this.state.productGroup}
            Type="Edit"
          />
        ) : (
          ""
        )}
        {this.state.msgboxOpen === true ? (
          <MessageBox
            open={this.state.msgboxOpen}
            title={this.state.msgboxTitle}
            message={this.state.msgboxMessage}
            callback={this.CloseMessagBoxCallback}
          />
        ) : (
          ""
        )}
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        {this.state.showProducMappingModel === true ? (
          <ProductGroupAndProductsMapping
            productGroup={this.state.productGroup}
            show={this.state.showProducMappingModel}
            onHide={this.OnCloseProductMappingModel}
          />
        ) : null}
        <tr
          className="text-center align-middle text-secondary bg-white mx-5"
          style={{ fontSize: "12px" }}
        >
          <td>{this.props.index + 1}</td>
          <td className=" text-center ">
            <Form.Control
              type="text"
              name="name"
              value={this.state.productGroup.name}
              required
              placeholder=""
              align="center"
              className="text-start  "
              autoComplete="off"
              onChange={(e) => this.handleInputChange(e)}
            />
          </td>
          <td>
            <Button
              className="mainthemecolor bg-primary"
              onClick={(e) => {
                this.OnSelectProducts(e, this.props.index);
              }}
            >
              Products...
            </Button>
          </td>
          <td className="text-start">{this.state.productGroup.imgFileName}</td>
          <td>
            <img
              width="60px"
              height="60px"
              src={
                this.context.store.storageBlobUrl +
                this.context.store.storageBlobContainerName +
                "/images/" +
                this.state.productGroup.imgFileName +
                this.context.store.storageSasToken
              }
              className="productGroupuctimg-on-productGroupuctlist mx-auto"
              alt="..."
            />
          </td>
          <td>
            <Form.Control
              type="number"
              name="seqNo"
              min={0}
              value={this.state.productGroup.seqNo}
              required
              placeholder=""
              align="center"
              className="text-center  "
              autoComplete="off"
              onChange={(e) => this.handleInputChange(e)}
            />
          </td>
          <td>
            {/* <Form.Select
              type="text"
              name="hideMe"
              value={this.state.productGroup.hideMe}
              required
              className="text-center"
              placeholder=""
              onChange={(e) => this.handleInputChange(e)}
            >
              <option value="true">True</option>
              <option value="false">False</option>
            </Form.Select> */}
            <div className="text-center">
              <Switch
                height={20}
                onChange={this.handleHidemeChange}
                checked={!this.state.productGroup.hideMe}
              />
            </div>
          </td>
          <td className=" text-start ">
            {this.state.productGroup.adsImgFileName}
          </td>
          <td>
            <img
              width="100px"
              height="60px"
              src={
                this.context.store.storageBlobUrl +
                this.context.store.storageBlobContainerName +
                "/images/" +
                this.state.productGroup.adsImgFileName +
                this.context.store.storageSasToken
              }
              className="productGroupuctimg-on-productGroupuctlist mx-auto"
              alt="..."
            />
          </td>

          <td className="text-cener">
            <div class="btn-group">
              <OverlayTrigger overlay={<Tooltip>Save</Tooltip>}>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    this.OnSave(e);
                  }}
                >
                  <BiSave size="20" />
                </Button>
              </OverlayTrigger>
              &nbsp;&nbsp;
              <OverlayTrigger overlay={<Tooltip>Edit!</Tooltip>}>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    this.OnEditButtonClick(e);
                  }}
                >
                  <BsFillPencilFill size="20" />
                </Button>
              </OverlayTrigger>
              &nbsp;&nbsp;&nbsp;
              <OverlayTrigger overlay={<Tooltip>Delete!</Tooltip>}>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    if (window.confirm("Are you sure you want to Delete ?")) {
                      this.OnDelete(e);
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>
            </div>
          </td>
        </tr>
      </>
    );
  }
}
