import React, { Component } from "react";
import CartContext from "./CartContext";
import TableOrdersQueue from "./TableOrdersQueue";
import { Package } from "./Orders";
import { Navigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { SideMenu } from "./SideMenu";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import { useEffect, useRef, useState, useContext } from "react";
import { OrderDetails } from "./OrderDetails";
import { OrderDetailsMobile } from "./OrderDetailsMobile";
import { LoadSpinner } from "./LoadSpinner";

import {
  Modal,
  Card,
  Badge,
  Table,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  ButtonToolbar,
  Form,
  Alert,
} from "react-bootstrap";

const WAIT_INTERVAL = 500;

export default class Kds2 extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      key: 0,
      allOrders: [],
      packageOrders: [],
      reloadPackagedOrders: false,
      reloadall: false,
      selectedProductName: "",
      loadSpinnerOpen: false,
      firstload: false,
      timer: null,
      refreshTime: 120,
    };
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  StoreAllOrders = (allOrders) => {
    this.setState({ allOrders: allOrders }, () => {
      this.setState({ reloadPackagedOrders: true }, () => {
        this.setState({ reloadPackagedOrders: false });
      });
    });
  };
  StorePackageOrders = (orders) => {
    this.setState({ packageOrders: orders });
  };

  Refresh = () => {
    this.setState({ reloadall: true }, () => {
      this.setState({ reloadall: false });
    });
  };
  GetOrders = () => {
    this.ShowLoadSpinner();
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetOrdersByStatusName/" +
        this.context.storeId +
        "/" +
        "Package"
    )
      .then((response) => response.json())
      .then((data) => {
        this.StorePackageOrders(data);
        this.HideLoadSpinner();
      })
      .catch((error) => {
        console.error("GetOrders:There was an error!", error);
        this.HideLoadSpinner();
      });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    if (this.state.firstload == false) {
      this.state.firstload = true;
      this.GetOrders();

      let intervalId = setInterval(() => {
        console.log("KDS Refresh Called");
        this.setState({ packageOrders: [] }, () => {
          this.setState({ allOrders: [] }, () => {
            this.GetOrders();
            this.Refresh();
          });
        });
      }, 1000 * this.state.refreshTime); //every 30 seconds
      this.setState({ timer: intervalId });
      console.log("timer called");
    }
  };
  refreshTimer = () => {};
  componentWillUnmount = () => {
    clearTimeout(this.state.timer);
  };
  componentDidUpdate = () => {};
  render() {
    return (
      <>
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <div className="Container-fluid mx-0 px-0 bg-primary">
          {window.innerWidth > 1200 ? (
            <>
              <table className="w-100 m-0 p-0">
                <tbody>
                  <tr className="pagebackground  w-100">
                    <td className="pagebackground m-0 p-0" width="52%">
                      {this.state.reloadall == false ? (
                        <>
                          <AllOrdersQueue
                            OrderState="Package"
                            allOrders={this.StoreAllOrders}
                            packageOrders={this.state.packageOrders}
                          ></AllOrdersQueue>
                        </>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td
                      className="pagebackground  m-0 p-0 border-0 border-dark"
                      width="48%"
                      style={{ verticalAlign: "top" }}
                    >
                      {this.state.reloadPackagedOrders == false ? (
                        <>
                          <PackagedOrdersQueue
                            OrderState="Package"
                            allOrders={this.state.allOrders}
                            StorePackageOrders={this.StorePackageOrders}
                            Refresh={this.Refresh}
                          ></PackagedOrdersQueue>
                        </>
                      ) : (
                        ""
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          ) : (
            <>
              <table className="w-100 m-0 p-0">
                <tbody>
                  <tr className="pagebackground  w-100">
                    <td className="pagebackground m-0 p-0" width="100%">
                      {this.state.reloadall == false ? (
                        <>
                          <AllOrdersQueue
                            OrderState="Package"
                            allOrders={this.StoreAllOrders}
                            packageOrders={this.state.packageOrders}
                          ></AllOrdersQueue>
                        </>
                      ) : (
                        <></>
                      )}
                    </td>
                    {/* <td
                  className="pagebackground  m-0 p-0 border-0 border-dark"
                  width="48%"
                  style={{ verticalAlign: "top" }}
                >
                  {this.state.reloadPackagedOrders == false ? (
                    <>
                      <PackagedOrdersQueue
                        OrderState="Package"
                        allOrders={this.state.allOrders}
                        StorePackageOrders={this.StorePackageOrders}
                        Refresh={this.Refresh}
                      ></PackagedOrdersQueue>
                    </>
                  ) : (
                    ""
                  )}
                </td> */}
                  </tr>
                </tbody>
              </table>
            </>
          )}
        </div>
      </>
    );
  }
}

class PackagedOrdersQueue extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      showOrderDetailsModal: false,
      selectedOrder: "",
      firstLoad: false,
    };
  }

  setOrders = (data) => {
    this.setState({ orders: data });
    // this.setState({ filteredOrdersDetail: data });
  };
  GetOrders = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetOrdersByStatusName/" +
        this.context.storeId +
        "/" +
        this.props.OrderState
    )
      .then((response) => response.json())
      .then((data) => {
        this.setOrders(data);
        this.props.StorePackageOrders(data);
      })
      .catch((error) => {
        console.error("GetOrders:There was an error!", error);
      });
  };
  componentDidMount = () => {
    if (this.state.firstLoad == false) {
      this.state.firstLoad = true;
      this.GetOrders();
    }
  };

  IsOrderPreparing = (order) => {
    var items = this.props.allOrders.filter(
      (f) => f.orderId == order.orderId && f.orderStatusId > 1
    );
    if (items.length > 1) return true;
    return false;
  };

  IsOrderReady = (order) => {
    var items = this.props.allOrders.filter(
      (f) =>
        f.orderId == order.orderId &&
        (f.orderStatusId == 1 || f.orderStatusId == 2)
    );
    if (items.length > 0) return false;
    return true;
  };
  UpdatePackageOrderedItemsStatus = (order, statusId) => {
    axios
      .post(
        process.env.REACT_APP_API +
          "StoreManager/UpdatePackageOrderedItemsStatus/" +
          order.id +
          "/" +
          statusId
      )
      .then((response) => {
        if (response.status === 200) {
          this.props.Refresh();
        } else {
          alert("UpdatePackageOrderedItemsStatus Failed. Please try again!");
        }
      })
      .catch((error) => {
        console.error(
          "UpdatePackageOrderedItemsStatus:There was an error!",
          error
        );
      });
  };
  UpdateOrderState = (order, changedOrderedState) => {
    var tmporder = order;
    tmporder.orderStatus = changedOrderedState;

    var orderStatus = this.context.orderStatusTypes.filter(
      (f) => f.id == Number(changedOrderedState)
    )[0];
    tmporder.orderWorkflowStatus +=
      "<p>" +
      new Date().toLocaleString("en-GB", {
        timeZone: "Asia/Kolkata",
      }) +
      "[" +
      this.context.userData.emailId +
      "]" +
      "=> <b>" +
      orderStatus.statusName +
      "</b></p>";

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmporder),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/UpdateOrderStatus",
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          alert("not updated");
          return;
        }
        this.props.Refresh();
      })
      .catch((error) => {
        alert("Failed" + error);
      });
  };
  OnOrderRowClick = (e, order) => {
    this.setState({ selectedOrder: order });
    this.setState({ showOrderDetailsModal: true });
  };
  ModalClose = () => {
    window.scrollTo(0, 0);
    this.setState({ showOrderDetailsModal: false }, () => {
      // swal("Updated Successfully!", "", "success");
    });
    this.props.Refresh();
  };

  GetCustomerName = (order) => {
    if (order.customerUserType.userTypeName == "POS")
      return "POS-" + order.customerName;
    return order.customerName;
  };

  render() {
    return (
      <>
        {this.state.showOrderDetailsModal === true ? (
          <>
            {window.innerWidth >= 1200 ? (
              <>
                <OrderDetails
                  show={this.state.showOrderDetailsModal}
                  onHide={this.ModalClose}
                  OrderState="Package"
                  storeId={this.context.storeId}
                  orderDetail={this.state.selectedOrder}
                />
              </>
            ) : (
              <>
                <OrderDetailsMobile
                  show={this.state.showOrderDetailsModal}
                  onHide={this.ModalClose}
                  OrderState={this.props.OrderState}
                  storeId={this.context.storeId}
                  orderDetail={this.state.selectedOrder}
                />
              </>
            )}
          </>
        ) : null}
        <div
          className="text-center bg-primary py-1 my-1"
          style={{ color: "yellow" }}
        >
          <h5>Take Away & Home Delivery Orders</h5>
        </div>
        <div
          className="fixedTableHightOrder"
          style={{
            height: window.innerHeight - 105 + "px",
            // backgroundColor: "#BFDAF7",
            backgroundColor: "black",
          }}
        >
          <Table
            className="m-0 p-0"
            style={{
              width: "100%",
            }}
          >
            <div className="row justify-content-around align-content-start m-0 py-2 text-start ">
              {this.state.orders
                .filter((f) => this.IsOrderReady(f) != true)
                .sort((a, b) => (a.orderCreated > b.orderCreated ? 1 : -1))
                .map((order) => (
                  <>
                    <Card
                      className="mb-3 mx-1 p-0"
                      style={{
                        width: "22rem",
                        height: "22rem",
                        cursor: "pointer",
                      }}
                      // className={
                      //   order.totalOrderedItems == 0
                      //     ? "bg-white text-black mx-3 p-0 mb-5 text-center CornersRounded btn3d-restauranttables"
                      //     : "bg-warning text-black mx-3 p-0 mb-5 text-center CornersRounded btn3d-restauranttables"
                      // }
                    >
                      <Card.Body
                        className="m-0 p-0"
                        // onClick={(e) => this.OnOrderRowClick(e, order)}
                      >
                        <Card.Title
                          className="m-0 p-0 text-center border "
                          style={{
                            fontSize: "14px",
                            backgroundColor: "#F1F1EC",
                            // color: "black",
                          }}
                        >
                          <Button
                            // className="mainthemecolor w-100"
                            className="w-100 bg-dark"
                            size="lg"
                            onClick={(e) => this.OnOrderRowClick(e, order)}
                          >
                            {order.orderId}
                          </Button>
                          <b></b> <br />
                          <span
                            className=""
                            style={{
                              fontSize: "12px",
                              color: "#360BA2",
                            }}
                          >
                            {order.deliveryMethodId == 2
                              ? "Take Away"
                              : order.deliveryMethodId == 3
                              ? "Home Delivery"
                              : order.deliveryMethodId == 4
                              ? "Swiggy"
                              : order.deliveryMethodId == 5
                              ? "Zomto"
                              : "???"}
                            {this.IsOrderReady(order) == true ? (
                              <span
                                className="blink_me mx-2"
                                style={{
                                  fontSize: "14px",
                                  backgroundColor: "#1B5448",
                                  color: "white",
                                }}
                              >
                                &nbsp;READY&nbsp;
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                          -{this.GetCustomerName(order)}
                        </Card.Title>
                        <Card.Subtitle className="my-1">
                          {/* Waiter: <b>{rtable.waiterName}</b> */}
                        </Card.Subtitle>
                        <Card.Text
                          className="text-start"
                          style={{ fontSize: "12px" }}
                        >
                          <div
                            className="fixedTableHightOrder border-0 border-dark"
                            style={{ height: 230 + "px" }}
                          >
                            <table
                              className="ordertablecustom border w-100 text-start"
                              size="sm"
                            >
                              <thead>
                                <tr>
                                  <th width="10%"></th>
                                  <th width="70%">Name</th>
                                  <th width="20%">Qty</th>
                                </tr>
                              </thead>
                              <tbody className="border">
                                {this.props.allOrders
                                  .filter((f) => f.orderId == order.orderId)

                                  .map((m, i) => (
                                    <>
                                      <tr
                                        style={{
                                          backgroundColor:
                                            m.orderStatusId == 1
                                              ? "white"
                                              : m.orderStatusId == 2
                                              ? "yellow"
                                              : m.orderStatusId == 3
                                              ? "lightgreen"
                                              : "red",
                                        }}
                                      >
                                        <td width="10%">{i + 1}</td>
                                        <td width="70%" className="border">
                                          <b>{m.productName}</b>
                                        </td>
                                        <td
                                          width="20%"
                                          className="border text-center"
                                        >
                                          <h5>
                                            <b>{m.qty}</b>
                                          </h5>
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                              </tbody>
                            </table>
                            <div className="justify-content-end text-end my-2">
                              <Button
                                onClick={(e) =>
                                  this.UpdatePackageOrderedItemsStatus(order, 3)
                                }
                                style={{
                                  backgroundColor: "green",
                                }}
                              >
                                Ready
                              </Button>{" "}
                              &nbsp;&nbsp;
                              <Button
                                onClick={(e) =>
                                  this.UpdateOrderState(
                                    order,
                                    this.context.OrderStates.PACKAGED
                                  )
                                }
                                style={{
                                  backgroundColor: "green",
                                }}
                              >
                                Ready & Packed
                              </Button>
                            </div>
                            {/* <div className="justify-content-end text-end my-2">
                              {this.IsOrderPreparing(order) == true ? (
                                <>
                                  
                                </>
                              ) : (
                                <>
                                  <Button
                                    onClick={(e) =>
                                      this.UpdatePackageOrderedItemsStatus(
                                        order,
                                        2
                                      )
                                    }
                                    style={{
                                      color: "black",
                                      backgroundColor: "yellow",
                                    }}
                                  >
                                    Prepare
                                  </Button>
                                </>
                              )}
                            </div> */}
                          </div>
                        </Card.Text>
                      </Card.Body>
                    </Card>
                  </>
                ))}
            </div>
          </Table>
        </div>
      </>
    );
  }
}

class AllOrdersQueue extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      showProductListByCategory: false,
      updateCartView: "true",
      havePermissions: false,
      tableOrders: [],
      selectedTableId: "",
      showGroupView: false,
      orderStatusFilterId: "",
      filteredTableName: "",
      filteredOrderId: "",
      filteredItemName: "",
      filteredProdList: [],

      //package orders
      packagingOrders: [],

      //All Orders
      allOrders: [],
      groupedOrdersByProductName: [],

      showSummary: false,
      firstLoad: false,
      reload: false,
    };
  }
  ShowSummaryModal = () => {
    this.setState({ showSummary: true });
  };
  HideSummaryModal = () => {
    this.setState({ showSummary: false });
  };

  //////////////////////////Package-ORDERS
  setOrders = (data) => {
    if (this.state.allOrders.length > 0) return;
    this.setState({ packagingOrders: data }, () => {
      console.log("push start = " + new Date().toLocaleString());
      this.state.tableOrders
        //.filter((f) => f.orderStatusId == 1 || f.orderStatusId == 2)
        .map((t) => {
          this.state.allOrders.push({
            orderType: "Table",
            id: t.id,
            orderId: "",
            name: t.name,
            productId: t.productId,
            mainCategoryId: this.GetMainCategroyIdOfProductId(t.productId),
            productName: t.productName,
            qty: t.qty,
            orderStatusId: t.orderStatusId,
            created: t.created,
          });
        });
      this.state.packagingOrders
        .filter((f) => f.availability == true)
        .map((t) => {
          this.state.allOrders.push({
            orderType: "Package",
            id: t.id,
            orderId: t.orderId,
            name:
              t.deliveryMethodId == 2
                ? "Take Away"
                : t.deliveryMethodId == 3
                ? "Home Delivery"
                : t.deliveryMethodId == 4
                ? "Swiggy"
                : t.deliveryMethodId == 5
                ? "Zomato"
                : "???",
            productId: t.productId,
            mainCategoryId: this.GetMainCategroyIdOfProductId(t.productId),
            productName: t.productName,
            qty: t.qty,
            orderStatusId: t.orderStatusId,
            created: t.created,
          });
        });
      console.log("push end = " + new Date().toLocaleString());
      this.setState({ allOrders: this.state.allOrders }, () => {
        //this.GetProductNames();
      });
      this.props.allOrders(this.state.allOrders);
      var result = this.groupBy(
        this.state.allOrders.filter(
          (f) => f.orderStatusId == 1 || f.orderStatusId == 2
        ),
        "productName"
      );
      console.log(result);
      this.setState({ groupedOrdersByProductName: result });
      this.GetProductNames1(result);
    });

    console.log("end = " + new Date().toLocaleString());
  };

  GetMainCategroyIdOfProductId = (productId) => {
    var prod = this.context.prods.filter((p) => p.id == productId);
    if (prod.length == 1) return prod[0].subCategory.mainCategoryId;
    else return "Custom";
  };

  groupBy = (array, productName) => {
    var result = [];
    array.reduce(function (res, value) {
      if (!res[value.productName]) {
        res[value.productName] = {
          productName: value.productName,
          // mainCategoryId: this.GetMainCategroyIdOfProductId(value.productId),
          mainCategoryId:
            value.mainCategoryId == undefined ? "Custom" : value.mainCategoryId,
          qty: 0,
        };
        result.push(res[value.productName]);
      }
      res[value.productName].qty += value.qty;
      return res;
    }, {});

    return result;
    // return array.reduce((result, currentValue) => {
    //   (result[currentValue[key]] = result[currentValue[key]] || []).push(
    //     currentValue
    //   );
    //   result[currentValue[key]] += currentValue.qty
    //   return result;
    // }, {});
  };

  GetAllOrdersOrderedItemByStatus = () => {
    axios
      .get(
        process.env.REACT_APP_API +
          "StoreManager/GetAllOrdersOrderedItemByStatus/" +
          this.context.storeId +
          "/" +
          this.props.OrderState
      )
      .then((response) => {
        if (response.status === 200) {
          this.setOrders(response.data);
        } else {
          alert("Please try again!");
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  ///////////////////////////TableOrder
  OnOrderStatusFilterChanged = (e) => {
    this.setState({ orderStatusFilterId: e.target.value });
  };
  OnfilteredTableNameChanged = (e) => {
    this.setState({ filteredTableName: e.target.value });
  };
  OnfilteredOrderIdChanged = (e) => {
    this.setState({ filteredOrderId: e.target.value });
  };
  OnfilteredItemNameChanged = (e) => {
    this.setState({ filteredItemName: e.target.value });
  };
  ShowGroupView = () => {
    this.setState({ showGroupView: true });
  };
  HideGroupView = () => {
    this.setState({ showGroupView: false });
  };

  GetMainCategoryIdOfProduct = (productid) => {
    return "";
  };

  GetRestaurantTableOrderedItemsDashboard = (tableId) => {
    console.log("start = " + new Date().toLocaleString());
    fetch(
      process.env.REACT_APP_API +
        "Consumers/GetRestaurantTableOrderedItemsDashboard/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ tableOrders: data }, () => {
            // this.GetProductNames();
            //this.GetOrders();
            this.GetAllOrdersOrderedItemByStatus();
          });
        }
      });
  };
  OnButtonPrepare = (cartItem) => {
    cartItem.orderStatusId = 2;
    if (cartItem.orderType == "Table") {
      this.UpdateRestaurantTableOrderItem(cartItem);
    } else if (cartItem.orderType == "Package") {
      this.UpdateCustomerOrderedItem(cartItem);
    }
  };
  OnButtonReady = (cartItem) => {
    cartItem.orderStatusId = 3;
    if (cartItem.orderType == "Table") {
      this.UpdateRestaurantTableOrderItem(cartItem);
    } else if (cartItem.orderType == "Package") {
      this.UpdateCustomerOrderedItem(cartItem);
    }
  };

  OnOrderStatusChange = (e, cartItem) => {
    cartItem.orderStatusId = e.target.value;
    if (cartItem.orderType == "Table") {
      this.UpdateRestaurantTableOrderItem(cartItem);
    } else if (cartItem.orderType == "Package") {
      this.UpdateCustomerOrderedItem(cartItem);
    }
  };

  UpdateRestaurantTableOrderItem = (item) => {
    axios
      .post(
        process.env.REACT_APP_API +
          "Consumers/UpdateRestaurantTableOrderedItemStatus/" +
          item.id +
          "/" +
          item.orderStatusId,
        item
      )
      .then((response) => {
        if (response.status === 200) {
          this.Refresh();
        } else {
          alert("UpdateRestaurantTableOrderItem Failed. Please try again!");
        }
      })
      .catch((error) => {
        console.error(
          "UpdateRestaurantTableOrderItem:There was an error!",
          error
        );
      });
  };
  UpdateCustomerOrderedItem = (item) => {
    axios
      .post(
        process.env.REACT_APP_API +
          "StoreManager/UpdateCustomerOrderedItemStatus/" +
          item.id +
          "/" +
          item.orderStatusId,
        item
      )
      .then((response) => {
        if (response.status === 200) {
          this.Refresh();
        } else {
          alert("UpdateCustomerOrderedItem Failed. Please try again!");
        }
      })
      .catch((error) => {
        console.error(
          "UpdateRestaurantTableOrderItem:There was an error!",
          error
        );
      });
  };

  GetProductNames = () => {
    var prodlist = [
      ...new Set(
        this.state.allOrders
          .filter((f) => f.orderStatusId == 1 || f.orderStatusId == 2)
          .map((q) => q.productName)
      ),
    ];
    this.setState({ filteredProdList: prodlist });
  };

  GetProductNames1 = (groupedOrdersByProductName) => {
    var prodlist = [
      ...new Set(groupedOrdersByProductName.map((q) => q.productName)),
    ];
    this.setState({ filteredProdList: prodlist });
  };

  Refresh = () => {
    this.setState({ allOrders: [] }, () => {
      this.GetRestaurantTableOrderedItemsDashboard();
    });
    this.setState({ reload: true }, () => {
      this.setState({ reload: false });
    });
  };

  componentDidMount = () => {
    if (this.state.firstLoad == false) {
      this.state.firstLoad = true;
      this.GetRestaurantTableOrderedItemsDashboard();
    }
  };

  GetDateDifference = (createddate) => {
    if (createddate == null || createddate == undefined) return 0;
    // var diff = Math.abs(new Date().toLocaleString() - createddate);
    //var startTime = new Date();
    //var endTime = new Date(createddate);
    //var endTime = new Date(createddate.toLocaleString());
    var diff = new Date() - new Date(createddate.toLocaleString());
    //var diff = startTime - endTime;
    // var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
    var minutes = Math.floor(diff / 1000 / 60);
    return minutes + 1;
  };

  componentDidUpdate = () => {};

  SetSelectedItemName = (item) => {
    this.setState({ filteredItemName: item });
  };

  PrepareStatus = (order) => {
    var status = order.customerOrderedItems.filter(
      (f) => f.orderStatusId != 3 && f.availability == true
    );
    if (status.length > 0) return "Preparing";
    else return "Ready";
  };

  render() {
    if (this.state.selectedTableId != "") return <Navigate to="/TableOrder" />;
    return (
      <>
        {this.state.showSummary === true ? (
          <>
            <OrderGroupViewMobile
              show={this.state.showSummary}
              onHide={this.HideSummaryModal}
              GroupedOrders={this.state.groupedOrdersByProductName}
              Refresh={this.Refresh}
            ></OrderGroupViewMobile>
          </>
        ) : (
          <></>
        )}
        <div className="m-0">
          <Table>
            <tbody>
              <tr>
                {window.innerWidth > 1200 ? (
                  <>
                    <td
                      width="30%"
                      className="border bg-primary text-white m-0 p-0"
                    >
                      <div
                        className="w-100  text-center py-0 m-0 p-0"
                        style={{
                          backgroundColor: "midnightblue",
                          color: "yellow",
                        }}
                      >
                        <h5>Order Summary</h5>
                        <small style={{ color: "white" }}>
                          [Received/Preparing]
                        </small>
                      </div>
                      {this.state.reload == false ? (
                        <>
                          <OrderGroupView
                            GroupedOrders={
                              this.state.groupedOrdersByProductName
                            }
                            SelectedItem={this.SetSelectedItemName}
                            Refresh={this.Refresh}
                          ></OrderGroupView>
                        </>
                      ) : (
                        <></>
                      )}
                    </td>
                  </>
                ) : (
                  <></>
                )}

                <td
                  width={window.innerWidth > 1200 ? "70%" : "100%"}
                  className="border-0 border-dark mx-0 p-0 bg-light"
                >
                  <div className=" ">
                    <Navbar
                      className="position-sticky bg-primary  fixed-top py-1 p-0 mx-1"
                      style={{
                        // backgroundColor: "lightskyblue",
                        color: "yellow",
                      }}
                    >
                      <Navbar.Collapse className="justify-content-start mx-1 p-0 m-0">
                        {this.context.store.businessTypeId == 3 ? (
                          <>
                            {window.innerWidth > 1200 ? (
                              <>
                                <h5>
                                  2. Preparing / Kitchen Display Sytem(KDS)
                                </h5>
                              </>
                            ) : (
                              "2.Preparing "
                            )}
                          </>
                        ) : (
                          <>
                            {window.innerWidth > 1200 ? (
                              <>
                                <h5>2. Preparing </h5>
                              </>
                            ) : (
                              "2. Preparing"
                            )}
                          </>
                        )}
                      </Navbar.Collapse>
                      <Navbar.Collapse className="justify-content-end text-end mx-1 ">
                        <Nav>
                          <ButtonToolbar className="text-end">
                            &nbsp;
                            <Button
                              className="mainthemecolor"
                              onClick={() => this.ShowSummaryModal()}
                            >
                              Summary
                            </Button>
                            <Button
                              className="mainthemecolor"
                              onClick={() => this.Refresh()}
                            >
                              <BiRefresh size="16"></BiRefresh>
                              Refresh
                            </Button>
                            &nbsp;
                          </ButtonToolbar>
                        </Nav>
                      </Navbar.Collapse>
                    </Navbar>
                  </div>
                  <div className="p-0 m-0 w-100">
                    <Table className="p-0 m-0 w-100">
                      <tbody className="w-100 p-0 m-0">
                        <tr className="w-100 p-0 m-0">
                          {this.context.store.businessTypeId == 3 ? (
                            <>
                              <td width="25%" className="text-start">
                                Filter by Table
                                <Form.Select
                                  value={this.state.filteredTableName}
                                  size="sm"
                                  style={{
                                    fontWeight: "bold",
                                    backgroundColor: "white",
                                  }}
                                  required
                                  onChange={(e) =>
                                    this.OnfilteredTableNameChanged(e)
                                  }
                                >
                                  <option value=""> </option>
                                  {this.context.returantTables.map((sta) => (
                                    <option value={sta.name}>
                                      {" "}
                                      {sta.name}
                                    </option>
                                  ))}
                                </Form.Select>
                              </td>
                            </>
                          ) : (
                            ""
                          )}

                          <td width="25%" className="text-start">
                            Filter by OrderId
                            <Form.Select
                              value={this.state.filteredOrderId}
                              size="sm"
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "white",
                              }}
                              required
                              onChange={(e) => this.OnfilteredOrderIdChanged(e)}
                            >
                              <option value=""> </option>
                              {this.props.packageOrders.map((sta) =>
                                this.PrepareStatus(sta) == "Preparing" ? (
                                  <>
                                    <option value={sta.orderId}>
                                      {" "}
                                      {sta.orderId}
                                    </option>
                                  </>
                                ) : (
                                  <></>
                                )
                              )}
                            </Form.Select>
                          </td>
                          <td width="25%" className="text-start">
                            Filter by Product
                            <Form.Select
                              value={this.state.filteredItemName}
                              size="sm"
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "white",
                              }}
                              required
                              onChange={(e) =>
                                this.OnfilteredItemNameChanged(e)
                              }
                            >
                              <option value=""> </option>
                              {this.state.filteredProdList
                                .sort((a, b) => (a > b ? 1 : -1))
                                .map((sta) => (
                                  <option value={sta}> {sta}</option>
                                ))}
                            </Form.Select>
                          </td>

                          <td width="25%" className="text-start">
                            Filter by Status
                            <Form.Select
                              value={this.state.orderStatusFilterId}
                              size="sm"
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "white",
                              }}
                              required
                              onChange={(e) =>
                                this.OnOrderStatusFilterChanged(e)
                              }
                            >
                              <option value=""> </option>
                              <option value="1">Received</option>
                              <option value="2">Preparing</option>
                            </Form.Select>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                  <div
                    className="fixedTableHightOrder p-0 m-0 w-100"
                    style={{ height: window.innerHeight - 170 + "px" }}
                  >
                    <Table className="p-0 m-0 w-100">
                      <tbody>
                        <tr>
                          <td width="100%">
                            <Table
                              hover
                              className="bg-white ordertablecustom "
                              striped
                              style={{ tableLayout: "fixed" }}
                            >
                              <thead>
                                <tr className="text-center m-0 p-0 border">
                                  {window.innerWidth > 1200 ? (
                                    <>
                                      <th width="5%"></th>
                                      <th width="25%">Order</th>
                                      {/* <th width="10%">To</th> */}
                                      <th width="25%">P.Name</th>
                                      <th width="10%">Qty</th>
                                      <th width="25%">Current/Next Status</th>
                                      <th width="10%">Mins</th>
                                    </>
                                  ) : (
                                    <>
                                      <th width="5%"></th>
                                      <th width="25%">Order</th>
                                      <th width="25%">P.Name</th>
                                      <th width="10%">Qty</th>
                                      <th width="25%"> Status</th>
                                      <th width="10%">Mins</th>
                                    </>
                                  )}
                                </tr>
                              </thead>
                              <tbody className="text-center">
                                {this.state.allOrders
                                  .filter(
                                    (f) =>
                                      f.orderStatusId == 1 ||
                                      f.orderStatusId == 2
                                  )
                                  .sort((a, b) =>
                                    new Date(a.created) > new Date(b.created)
                                      ? 1
                                      : -1
                                  )
                                  .filter(
                                    (f) =>
                                      f.orderStatusId == 1 ||
                                      f.orderStatusId == 2
                                  )
                                  .filter((f) => {
                                    if (
                                      this.state.filteredItemName != "" &&
                                      f.productName ==
                                        this.state.filteredItemName
                                    )
                                      return true;
                                    if (this.state.filteredItemName == "")
                                      return true;
                                  })
                                  .filter((f) => {
                                    if (
                                      this.state.filteredTableName != "" &&
                                      f.name == this.state.filteredTableName
                                    )
                                      return true;
                                    if (this.state.filteredTableName == "")
                                      return true;
                                  })
                                  .filter((f) => {
                                    if (
                                      this.state.filteredOrderId != "" &&
                                      f.orderId == this.state.filteredOrderId
                                    )
                                      return true;
                                    if (this.state.filteredOrderId == "")
                                      return true;
                                  })
                                  .filter((f) => {
                                    if (
                                      this.state.orderStatusFilterId != "" &&
                                      f.orderStatusId ==
                                        this.state.orderStatusFilterId
                                    ) {
                                      return true;
                                    }
                                    if (this.state.orderStatusFilterId == "")
                                      return true;
                                  })
                                  .map((order, i) => (
                                    <>
                                      <tr
                                        className="align-middle p-0"
                                        style={{}}
                                      >
                                        <td>{i + 1}</td>
                                        <td>
                                          {/* {order.orderType}
                                          <br></br> */}
                                          <b>{order.orderId}</b>
                                          <br />[{order.name}]
                                        </td>

                                        <td className="text-start">
                                          <b>{order.productName}</b>
                                        </td>
                                        <td>
                                          <b>
                                            <h5>
                                              <b>{order.qty}</b>
                                            </h5>
                                          </b>
                                        </td>
                                        <td>
                                          {order.orderStatusId == 1 ? (
                                            <>
                                              <span
                                                className="blink_me"
                                                style={{ color: "black" }}
                                              >
                                                Received
                                              </span>
                                              <br />
                                              <Button
                                                onClick={(e) =>
                                                  this.OnButtonPrepare(order)
                                                }
                                                style={{
                                                  backgroundColor: "yellow",
                                                  color: "black",
                                                }}
                                              >
                                                Prepare
                                              </Button>
                                            </>
                                          ) : order.orderStatusId == 2 ? (
                                            <>
                                              <span
                                                className="blink_me"
                                                style={{
                                                  backgroundColor: "yellow",
                                                  color: "black",
                                                }}
                                              >
                                                Preparing
                                              </span>
                                              <br />
                                              <Button
                                                onClick={(e) =>
                                                  this.OnButtonReady(order)
                                                }
                                                style={{
                                                  backgroundColor: "lightgreen",
                                                  color: "red",
                                                }}
                                              >
                                                Ready
                                              </Button>
                                            </>
                                          ) : (
                                            <></>
                                          )}
                                        </td>
                                        <td>
                                          <b>
                                            {this.GetDateDifference(
                                              order.created
                                            ) > 15 ? (
                                              <>
                                                <span className="blink_me text-black ">
                                                  {this.GetDateDifference(
                                                    order.created
                                                  )}
                                                </span>
                                              </>
                                            ) : (
                                              this.GetDateDifference(
                                                order.created
                                              )
                                            )}
                                          </b>
                                        </td>
                                      </tr>
                                    </>
                                  ))}
                              </tbody>
                            </Table>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </div>
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}

class OrderGroupViewMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  SetSelectedItemName = () => {};
  render() {
    return (
      <>
        {/* <div className="container m-0 p-0">
          <Modal
            {...this.props}
            aria-labelledby="contained-modal-title-vcenter "
            keyboard={false}
            dialogClassName="order-modal-mobile"
          >
            <Form>
              <Modal.Header closeButton className="bg-warning">
                <Modal.Title id="contained-modal-title-vcenter">
                  Order Details
                </Modal.Title>
              </Modal.Header>
              <Modal.Body className="m-0 p-0">
                <h1>hi</h1>
              </Modal.Body>
              <Modal.Footer className="bg-light">
              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                  Close
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
        </div> */}
        <Modal
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter "
          keyboard={false}
          dialogClassName="order-modal-mobile"
        >
          <Modal.Header closeButton className="bg-warning">
            {/* <Modal.Title id="contained-modal-title-vcenter"></Modal.Title> */}
            <small>Order Summary - [Received & Preparing]</small>
          </Modal.Header>
          <Modal.Body className="show-grid bg-primary">
            <OrderGroupView
              GroupedOrders={this.props.GroupedOrders}
              SelectedItem={this.SetSelectedItemName}
              Refresh={this.props.Refresh}
            ></OrderGroupView>
          </Modal.Body>
          <Modal.Footer className="bg-light">
            <Button variant="danger" size="lg" onClick={this.props.onHide}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}
class OrderGroupView extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
      firstLoad: false,
    };
  }
  GetRestaurantTableOrderedItemsGrouped = () => {
    this.setState({ orders: [] }, () => {
      fetch(
        process.env.REACT_APP_API +
          "Consumers/GetRestaurantTableOrderedItemsGrouped/" +
          this.context.storeId
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 400) {
          } else {
            this.setState({ orders: data });
          }
        });
    });
  };
  componentDidMount = () => {
    if (this.state.firstLoad == false) {
      this.state.firstLoad = true;
      this.GetRestaurantTableOrderedItemsGrouped();
    }
  };
  Refresh = (e) => {
    this.props.Refresh();
  };
  isMainCategoryHasItems = (maincategory) => {
    var result = false;

    this.props.GroupedOrders.filter(
      (item) => item.mainCategoryId === maincategory.id
    ).length > 0
      ? (result = true)
      : (result = false);

    return result;
  };
  render() {
    return (
      <>
        <div className="text-center mx-3">
          <Button size="sm" variant="warning" onClick={(e) => this.Refresh(e)}>
            Refresh
          </Button>
        </div>
        <div
          className="fixedTableHightOrder my-1"
          style={{ height: window.innerHeight - 120 + "px" }}
        >
          {this.context.mainCategories.map((maincategory, j) => (
            <>
              {this.isMainCategoryHasItems(maincategory) == true ? (
                <>
                  <div
                    className="text-center "
                    style={{ backgroundColor: "white", color: "black" }}
                  >
                    <strong>{maincategory.name}</strong>
                  </div>
                  <Table
                    className="border text-start text-white"
                    size="sm"
                    style={{
                      fontSize: "12px",
                    }}
                  >
                    <tbody>
                      {this.props.GroupedOrders.filter(
                        (item) => item.mainCategoryId === maincategory.id
                      )
                        .sort((a, b) =>
                          a.productName > b.productName ? 1 : -1
                        )
                        .map((order, i) => (
                          <>
                            <tr
                              style={{
                                cursor: "pointer",
                              }}
                              onClick={(e) =>
                                this.props.SelectedItem(order.productName)
                              }
                            >
                              <td width="5%">{i + 1}</td>
                              <td width="85%" className="text-start">
                                <span>{order.productName}</span>
                              </td>
                              <td width="10%">
                                {" "}
                                <Badge
                                  bg="dark"
                                  style={{
                                    fontSize: 14,
                                    borderRadius: "50px 50px 50px 50px",
                                  }}
                                >
                                  {/* {order.totQty} */}
                                  {order.qty}
                                </Badge>
                              </td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </Table>
                </>
              ) : (
                <></>
              )}
            </>
          ))}
          <div>
            {this.props.GroupedOrders.filter(
              (item) => item.mainCategoryId == "Custom"
            ).length > 0 ? (
              <>
                <div
                  className="text-center "
                  style={{ backgroundColor: "yellow", color: "blue" }}
                >
                  <strong>Custom Items</strong>
                </div>
                <Table
                  className="border text-start text-white"
                  size="sm"
                  style={{
                    fontSize: "12px",
                  }}
                >
                  <tbody>
                    {this.props.GroupedOrders.filter(
                      (item) => item.mainCategoryId === "Custom"
                    )
                      .sort((a, b) => (a.productName > b.productName ? 1 : -1))
                      .map((order, i) => (
                        //{this.state.orders.map((order, i) => (
                        <>
                          <tr
                            style={{
                              cursor: "pointer",
                            }}
                            onClick={(e) =>
                              this.props.SelectedItem(order.productName)
                            }
                          >
                            <td width="5%">{i + 1}</td>
                            <td width="85%" className="text-start">
                              {order.productName}
                            </td>
                            <td width="10%">
                              {" "}
                              <Badge
                                bg="dark"
                                style={{
                                  fontSize: 14,
                                  borderRadius: "50px 50px 50px 50px",
                                }}
                              >
                                {/* {order.totQty} */}
                                {order.qty}
                              </Badge>
                            </td>
                          </tr>
                        </>
                      ))}
                  </tbody>
                </Table>
              </>
            ) : (
              ""
            )}
          </div>
        </div>
      </>
    );
  }
}
const RefreshKDS = () => {
  const { GetOrdersSummary, storeSettings } = useContext(CartContext);
  const { orderSummary } = useContext(CartContext);
  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [isActive, setIsActive] = useState(true);
  const [counter, setCounter] = useState(0);
  const [ring, setRing] = useState(true);

  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        const computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        const computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);
        console.log("timer called to refresh order summary");
        GetOrdersSummary();

        setCounter((counter) => counter + 1);
        setRing(false);
      }, 30000); //refresh once in 30seconds
      setRing(true);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isActive, counter]);

  return (
    <div className="container">
      {ring == true ? (
        <>
          {orderSummary.orderReceived > 0 ? (
            <>
              {storeSettings.disableNewOrderBeepSound == false ? (
                <>{/* <BoopButton></BoopButton> */}</>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};
