import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiRefresh } from "react-icons/bi";
import { BsFillPencilFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import DatePicker from "react-date-picker";
import axios from "axios";

import {
  OverlayTrigger,
  InputGroup,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Modal,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export class AccountPayroll extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      month: "",
      year: "",
      payrolls: [],
      employees: [],
      reload: true,
      firstload: false,
      showEditPayroll: false,
    };
  }

  IsPaid = () => {
    var r = this.state.payrolls.filter((p) => p.status == "2");
    if (r.length > 0) {
      return true;
    }
    return false;
  };

  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
  };

  GetEmployees = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetEmployees/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ employees: data }, () => {});
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  GeneratePayroll = () => {
    if (this.state.month == "" || this.state.year == "") {
      alert("Select Month and Year and try!");
      return;
    }

    var payroll = [];
    var temployee = "";
    for (var i = 0; i < this.state.employees.length; i++) {
      if (this.state.employees[i].status == 1) {
        temployee = {
          storeId: this.context.storeId,
          created:
            new Date().getMonth() +
            1 +
            "/" +
            new Date().getDate() +
            "/" +
            new Date().getFullYear(),
          month: this.state.month,
          year: this.state.year,
          empId: this.state.employees[i].id,
          basicSalary: this.state.employees[i].basicSalary,
          bonus: this.state.employees[i].bonus,
          hra: this.state.employees[i].hra,
          da: this.state.employees[i].da,
          conveyanceAllowance: this.state.employees[i].conveyanceAllowance,
          medicalAllowance: this.state.employees[i].medicalAllowance,
          otherAllowances: 0,
          pfdeduction: 0,
          taxDeduction: 0,
          otherDeduction: 0,
          status: 1, //unpaid
          description: "",
        };
        payroll.push(temployee);
      }
    }

    if (payroll.length > 0) {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify(payroll),
      };

      fetch(
        process.env.REACT_APP_API +
          "StoreManager/GeneratePayroll/" +
          this.context.storeId +
          "/" +
          this.state.month +
          "/" +
          this.state.year,
        requestOptions
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 400) {
            alert("Payroll already generated for this period. !");
          } else {
            this.setState({ payrolls: data }, () => {});
            alert("Payroll generated successfully for this period. !");
          }
        })

        .catch((error) => {
          alert(
            "Exception. Received an Exception. Try after sometime !" + error
          );
        });
    } else {
      alert("No Employees Found!");
    }
  };
  UpdatePayrollPaid = () => {
    if (this.state.month == "" || this.state.year == "") {
      return;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/UpdatePayrollPaid/" +
        this.context.storeId +
        "/" +
        this.state.month +
        "/" +
        this.state.year,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ payrolls: [] }, () => {
            this.setState({ payrolls: data }, () => {});
            alert("Updated Successfully !");
          });
        }
      })
      .catch((error) => {
        alert("Exception. Received an Exception. Try after sometime !" + error);
      });
  };

  GetPayroll = () => {
    if (this.state.month != "" && this.state.year != "") {
      fetch(
        process.env.REACT_APP_API +
          "StoreManager/GetPayroll/" +
          this.context.storeId +
          "/" +
          this.state.month +
          "/" +
          this.state.year
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 400) {
          } else {
            this.setState({ payrolls: [] }, () => {
              this.setState({ payrolls: data }, () => {});
            });
          }
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
    }
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "month":
        this.setState({ month: value }, () => {
          this.GetPayroll();
        });
        break;
      case "year":
        this.setState({ year: value }, () => {
          this.GetPayroll();
        });
        break;
    }
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);

    if (this.state.firstload == false) {
      this.state.firstload = true;
      this.GetEmployees();
    }
  };

  render() {
    return (
      <>
        <div className="Container-fluid p-1 ">
          <Row>
            {window.innerWidth > 1200 ? (
              <>
                <Col className="" xs={2}>
                  <SideMenu></SideMenu>
                </Col>
              </>
            ) : (
              <></>
            )}

            <Col
              xs={window.innerWidth > 1200 ? 10 : 12}
              className="pagebackground p-0"
            >
              {this.state.msgboxOpen === true ? (
                <MessageBox
                  open={this.state.msgboxOpen}
                  title={this.state.msgboxTitle}
                  message={this.state.msgboxMessage}
                  callback={this.CloseMessagBoxCallback}
                />
              ) : (
                ""
              )}

              <Navbar className="secondarythemecolor position-sticky fixed-top px-1 m-0 p-1 text-center border-3 border-dark">
                <h4> &nbsp; &nbsp;Employee Payroll &nbsp;</h4>
              </Navbar>
              <div className="bg-light ">
                <Table className="bg-light m-0 p-0">
                  <tr className="border-0">
                    <td width="20%">
                      <InputGroup className="mb-1">
                        <InputGroup.Text id="basic-addon1">
                          Select Month
                        </InputGroup.Text>
                        <Form.Select
                          type="text"
                          name="month"
                          value={this.state.month}
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value=""></option>
                          <option value={1}>January</option>
                          <option value={2}>February</option>
                          <option value={3}>March</option>
                          <option value={4}>April</option>
                          <option value={5}>May</option>
                          <option value={6}>June</option>
                          <option value={7}>July</option>
                          <option value={8}>Auguest</option>
                          <option value={9}>September</option>
                          <option value={10}>October</option>
                          <option value={11}>November</option>
                          <option value={12}>December</option>
                        </Form.Select>
                      </InputGroup>
                    </td>
                    <td width="20%">
                      <InputGroup className="mb-1">
                        <InputGroup.Text id="basic-addon1">
                          Select Year
                        </InputGroup.Text>
                        <Form.Select
                          type="text"
                          name="year"
                          value={this.state.year}
                          required
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        >
                          <option value=""></option>
                          {[2024, 2025, 2026, 2027, 2028, 2029, 2030].map(
                            (m) => (
                              <option value={m}>{m}</option>
                            )
                          )}
                        </Form.Select>
                      </InputGroup>
                    </td>
                    <td width="30%">
                      <Button
                        className="bg-primary text-white"
                        size="lg"
                        disabled={
                          this.state.month == "" ||
                          this.state.year == "" ||
                          this.state.payrolls.length > 0
                        }
                        onClick={() => this.GeneratePayroll()}
                      >
                        Generate New Payroll...
                      </Button>
                    </td>
                    {this.state.month == "" ||
                    this.state.year == "" ||
                    this.state.payrolls.length == 0 ? (
                      <></>
                    ) : (
                      <>
                        <td width="30%">
                          <Button
                            className="bg-danger text-white"
                            size="lg"
                            hidden={this.IsPaid()}
                            onClick={() => this.UpdatePayrollPaid()}
                          >
                            Update as PAID
                          </Button>
                        </td>
                      </>
                    )}
                  </tr>
                </Table>
              </div>
              <Table border striped className="px-0 bg-white" size="sm">
                <thead className="">
                  <tr
                    className="text-center text-black bg-white border "
                    style={{ fontsize: "10px" }}
                  >
                    <th>#</th>
                    <>
                      <th width="10%">Created</th>
                      <th width="10%">Emp</th>
                      <th width="5%">
                        Basic
                        <br />
                        (A){" "}
                      </th>
                      <th width="5%">
                        Bonus
                        <br />
                        (B)
                      </th>
                      <th width="5%">
                        HRA
                        <br />
                        (C)
                      </th>
                      <th width="5%">
                        CA
                        <br />
                        (D)
                      </th>
                      <th width="5%">
                        MA
                        <br />
                        (E)
                      </th>
                      <th width="5%">
                        OA
                        <br />
                        (F)
                      </th>
                      <th width="10%">
                        PF <br />
                        Deduction
                        <br />
                        (G)
                      </th>
                      <th width="10%">
                        Tax <br />
                        Deduction
                        <br />
                        (H)
                      </th>
                      <th width="10%">
                        Other <br />
                        Deduction
                        <br />
                        (I)
                      </th>
                      <th width="10%">
                        Total <br />
                        Earned
                        <br />
                        J=(A+B+C+D+E+F)
                      </th>
                      <th width="10%">
                        Total <br />
                        Detection
                        <br /> K=(G+H+I)
                      </th>
                      <th width="10%">
                        Monthly <br />
                        Salary (J-K)
                      </th>
                      <th width="10%">Status</th>
                      <th width="10%" className="text-end">
                        Options
                      </th>
                    </>
                  </tr>
                </thead>
                <tbody className="text-primary border text-center">
                  {this.state.payrolls.map((payroll, i) =>
                    this.state.reload === true ? (
                      <EditPayrollInline
                        index={i}
                        payroll={payroll}
                        employees={this.state.employees}
                        OnDeleted={this.OnDeleted}
                      ></EditPayrollInline>
                    ) : null
                  )}
                </tbody>
              </Table>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export class EditPayrollInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      payroll: "",
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      editModalShow: false,
    };
  }

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };
  addModalClose = () => {
    this.setState({ editModalShow: false }, () => {});
  };

  OnDelete = () => {
    if (this.state.payroll.id == undefined) {
      this.ShowMessagBox(
        "Information",
        "This is invalid row which can't be deleted. Press Refresh!"
      );
      return;
    }

    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeletePayroll/" +
        this.state.payroll.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert(
            "Not deleted. This Payroll may be associated with few Products. First disassociate this payroll from the Products and then try!"
          );
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Status",
          "Deleted Successfully. Click Refresh Button to see the changes!"
        );
        this.props.OnDeleted();
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("OnDelete Failed" + error);
      });
  };

  componentDidMount = () => {
    this.setState({
      payroll: this.props.payroll,
    });
  };
  OnEditButtonClick = () => {
    this.setState({ editModalShow: true }, () => {});
  };

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {});
  };

  GetCategoryName = (id) => {
    var result = this.props.categories.filter(
      (f) => f.id == this.state.payroll.expenseCategoryId
    );
    if (result.length > 0) return result[0].name;
    return "";
  };
  GetEmployeeName = (id) => {
    var emp = this.props.employees.filter(
      (f) => f.id == this.state.payroll.empId
    );
    if (emp.length > 0)
      return (
        emp[0].firstName + " " + emp[0].lastName + " EmpNo=" + emp[0].empNo
      );
    return "";
  };

  PrintTableOffline = (invoiceHtml) => {
    var printWindow = window.open("", "_blank");
    printWindow.document.write(invoiceHtml);
    printWindow.document.close();
    this.onPrintFinished(printWindow.print(), printWindow);
  };

  render() {
    return (
      <>
        {/* {this.state.editModalShow === true ? (
          <PayrollAddOrEditModal
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            payroll={this.state.payroll}
            categories={this.props.categories}
            Type="Edit"
          />
        ) : (
          ""
        )} */}
        {this.state.editModalShow == true ? (
          <>
            <EditPayrollModal
              onHide={this.addModalClose}
              payroll={this.state.payroll}
              employees={this.props.employees}
            ></EditPayrollModal>
          </>
        ) : (
          <></>
        )}
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <tr
          className="text-center align-middle text-secondary bg-white mx-5"
          style={{ fontSize: "12px" }}
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          <td className="text-center ">{this.props.index + 1}</td>
          <td className="text-center ">
            {this.context.GetInDateFormat(new Date(this.state.payroll.created))}
          </td>
          <td>{this.GetEmployeeName(this.state.payroll.empId)}</td>
          <td className="text-center ">{this.state.payroll.basicSalary}</td>
          <td className="text-center ">{this.state.payroll.bonus}</td>
          <td className="text-center ">{this.state.payroll.hra}</td>
          <td className="text-center ">
            {this.state.payroll.conveyanceAllowance}
          </td>
          <td className="text-center ">
            {this.state.payroll.medicalAllowance}
          </td>
          <td className="text-center ">{this.state.payroll.otherAllowances}</td>
          <td className="text-center ">{this.state.payroll.pfdeduction}</td>
          <td className="text-center ">{this.state.payroll.taxDeduction}</td>
          <td className="text-center ">{this.state.payroll.otherDeduction}</td>
          <td className="text-center ">
            {Number(this.state.payroll.basicSalary) +
              Number(this.state.payroll.bonus) +
              Number(this.state.payroll.hra) +
              Number(this.state.payroll.conveyanceAllowance) +
              Number(this.state.payroll.medicalAllowance) +
              Number(this.state.payroll.otherAllowances)}
          </td>
          <td className="text-center ">
            {Number(this.state.payroll.pfdeduction) -
              Number(this.state.payroll.taxDeduction) -
              Number(this.state.payroll.otherDeduction)}
          </td>
          <td className="text-center ">
            {Number(this.state.payroll.basicSalary) +
              Number(this.state.payroll.bonus) +
              Number(this.state.payroll.hra) +
              Number(this.state.payroll.conveyanceAllowance) +
              Number(this.state.payroll.medicalAllowance) +
              Number(this.state.payroll.otherAllowances) -
              Number(this.state.payroll.pfdeduction) -
              Number(this.state.payroll.taxDeduction) -
              Number(this.state.payroll.otherDeduction)}
          </td>
          <td className="text-center ">
            {this.state.payroll.status == 1 ? "UNPAID" : "PAID"}
          </td>

          <td className="col-md-2 text-end align-middle ">
            <div class="btn-group">
              <OverlayTrigger overlay={<Tooltip>Edit!</Tooltip>}>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    this.OnEditButtonClick(e);
                  }}
                >
                  <BsFillPencilFill size="16" />
                </Button>
              </OverlayTrigger>
              &nbsp;
              <OverlayTrigger overlay={<Tooltip>Edit!</Tooltip>}>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    // this.OnEditButtonClick(e);
                  }}
                >
                  Print
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    Warning! This will delete this Brand Row and associated
                    Products as well.
                  </Tooltip>
                }
              >
                <Button
                  className="mx-1 mainthemecolor"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        "Warning! This action will delete this Brand Row and associated Products as well. Are you sure you want to Delete?"
                      )
                    ) {
                      this.OnDelete(e);
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

export class EditPayrollModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      payroll: "",
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
    };
  }

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "basicSalary":
        this.state.payroll.basicSalary = value;
        break;
      case "bonus":
        this.state.payroll.bonus = value;
        break;
      case "hra":
        this.state.payroll.hra = value;
        break;
      case "conveyanceAllowance":
        this.state.payroll.conveyanceAllowance = value;
        break;
      case "medicalAllowance":
        this.state.payroll.medicalAllowance = value;
        break;
      case "otherAllowances":
        this.state.payroll.otherAllowances = value;
        break;
      case "pfdeduction":
        this.state.payroll.pfdeduction = value;
        break;
      case "taxDeduction":
        this.state.payroll.taxDeduction = value;
        break;
      case "otherDeduction":
        this.state.payroll.otherDeduction = value;
        break;
    }
    this.setState({
      payroll: this.state.payroll,
    });
  };

  GetEmployeeName = (id) => {
    var emp = this.props.employees.filter(
      (f) => f.id == this.state.payroll.empId
    );
    if (emp.length > 0)
      return (
        emp[0].firstName + " " + emp[0].lastName + " EmpNo=" + emp[0].empNo
      );
    return "";
  };

  UpdateEmployeePayroll = () => {
    this.ShowLoadSpinner();

    axios
      .post(
        process.env.REACT_APP_API + "StoreManager/UpdateEmployeePayroll",
        this.state.payroll
      )
      .then((res) => {
        if (res.status == 200) {
          this.setState({ payroll: res.data });
          this.HideLoadSpinner();
          //alert("Updated Successfully!");
          this.props.onHide();
          return;
        }
        this.HideLoadSpinner();
        alert("Error! try again.");
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("Exception. Received an Exception. Try after sometime !" + error);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.UpdateEmployeePayroll();
  };

  componentDidMount() {
    this.setState({
      payroll: this.props.payroll,
    });
  }

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          show={true}
          dialogClassName="PayrollModal"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                Edit payroll
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Row>
                <Col>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      <h5>Name & EmpNo</h5>
                    </Form.Label>
                    <Col sm="9">
                      <h5> {this.GetEmployeeName()}</h5>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row
                className="m-2 p-2 bg-light border"
                style={{ fontSize: "9pt" }}
              >
                <Col md={6}>
                  <h4 className="text-center">Earnings</h4>
                  <div className="border p-2">
                    <Form.Group as={Row} className="mb-3 " controlId="">
                      <Form.Label column sm="3">
                        Basic Salary
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="number"
                          name="basicSalary"
                          value={this.state.payroll.basicSalary}
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3 " controlId="">
                      <Form.Label column sm="3">
                        Bonus
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="number"
                          name="bonus"
                          value={this.state.payroll.bonus}
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>

                    <Form.Group as={Row} className="mb-3 " controlId="">
                      <Form.Label column sm="3">
                        HRA
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="number"
                          name="hra"
                          value={this.state.payroll.hra}
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 " controlId="">
                      <Form.Label column sm="3">
                        Conveyance Allowance
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="number"
                          name="conveyanceAllowance"
                          value={this.state.payroll.conveyanceAllowance}
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 " controlId="">
                      <Form.Label column sm="3">
                        Medical Allowance
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="number"
                          name="medicalAllowance"
                          value={this.state.payroll.medicalAllowance}
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                    <Form.Group as={Row} className="mb-3 " controlId="">
                      <Form.Label column sm="3">
                        Other Allowances
                      </Form.Label>
                      <Col sm="9">
                        <Form.Control
                          type="number"
                          name="otherAllowances"
                          value={this.state.payroll.otherAllowances}
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Form.Group>
                  </div>
                  <h4>
                    Total Earning :{" "}
                    {Number(this.state.payroll.basicSalary) +
                      Number(this.state.payroll.bonus) +
                      Number(this.state.payroll.hra) +
                      Number(this.state.payroll.conveyanceAllowance) +
                      Number(this.state.payroll.medicalAllowance) +
                      Number(this.state.payroll.otherAllowances)}
                  </h4>
                </Col>
                <Col md={6}>
                  <h4 className="text-center">Deduction</h4>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      PF Deduction
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        name="pfdeduction"
                        value={this.state.payroll.pfdeduction}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Tax Deduction
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        name="taxDeduction"
                        value={this.state.payroll.taxDeduction}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Other Deduction
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        name="otherDeduction"
                        value={this.state.payroll.otherDeduction}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <h4>
                    Total Deduction:{" "}
                    {Number(this.state.payroll.pfdeduction) +
                      Number(this.state.payroll.taxDeduction) +
                      Number(this.state.payroll.otherDeduction)}
                  </h4>
                </Col>
              </Row>
              <Row>
                <h2 className="text-center">
                  Monthly Salary:{" "}
                  {Number(this.state.payroll.basicSalary) +
                    Number(this.state.payroll.bonus) +
                    Number(this.state.payroll.hra) +
                    Number(this.state.payroll.conveyanceAllowance) +
                    Number(this.state.payroll.medicalAllowance) +
                    Number(this.state.payroll.otherAllowances) -
                    Number(this.state.payroll.pfdeduction) -
                    Number(this.state.payroll.taxDeduction) -
                    Number(this.state.payroll.otherDeduction)}
                </h2>
              </Row>
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  Save
                </Button>
              </Form.Group>
              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
