import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiRefresh } from "react-icons/bi";
import { BsFillPencilFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import ReactDOM from "react-dom";
import Select from "react-select";

import {
  OverlayTrigger,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Modal,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";
import { TbTower } from "react-icons/tb";

export class RawMaterialPurchaseOrders extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      rawmaterialpurchaseorders: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      reload: true,
      rawmaterialsuppliers: [],
      prods: [],
      loaded: false,
    };
  }

  GetRawMaterialsInventories = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetRawMaterialsInventories/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ prods: data }, () => {
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  GetRawMaterialSuppliers = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetRawMaterialSuppliers/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ rawmaterialsuppliers: data }, () => {
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  GetRawMaterialPurchaseOrders = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetRawMaterialPurchaseOrders/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ rawmaterialpurchaseorders: data }, () => {
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnSaved = () => {
    this.OnRefreshButtonClick();
  };
  OnDeleted = () => {
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.setState({ rawmaterialpurchaseorders: [] }, () => {
      this.GetRawMaterialPurchaseOrders();
    });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  OnUpdated = (indexno) => {
    this.OnRefreshButtonClick();
  };

  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
    this.OnRefreshButtonClick();
  };

  componentDidMount = () => {
    if (this.state.loaded == false) {
      if (this.state.rawmaterialpurchaseorders.length == 0)
        this.GetRawMaterialPurchaseOrders();
      if (this.state.rawmaterialsuppliers.length == 0)
        this.GetRawMaterialSuppliers();
      this.GetRawMaterialsInventories();
      this.state.loaded = true;
    }
    window.scrollTo(0, 0);
    // document.addEventListener("keydown", (event) => {
    //   if (event.key == "F8") {
    //     this.setState({ addModalShow: true });
    //   } else if (event.key == "F9") {
    //     this.OnRefreshButtonClick();
    //   }
    // });
  };

  render() {
    return (
      <>
        <div className="Container-fluid p-1 ">
          <Row>
            <Col className="" xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="pagebackground p-0">
              {this.state.msgboxOpen === true ? (
                <MessageBox
                  open={this.state.msgboxOpen}
                  title={this.state.msgboxTitle}
                  message={this.state.msgboxMessage}
                  callback={this.CloseMessagBoxCallback}
                />
              ) : (
                ""
              )}
              <Navbar
                expand="lg"
                className="secondarythemecolor position-sticky fixed-top  px-3"
              >
                <h4> &nbsp; &nbsp;RawMaterial Purchase Orders (PO) &nbsp;</h4>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemecolor"
                        onClick={() => this.setState({ addModalShow: true })}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Refresh
                      </Button>
                      &nbsp;
                      {this.state.addModalShow === true ? (
                        <RawMaterialPurchaseOrderAddOrEditModal
                          show={this.state.addModalShow}
                          Type="Add"
                          onHide={this.addModalClose}
                          rawmaterialsuppliers={this.state.rawmaterialsuppliers}
                          prods={this.state.prods}
                        />
                      ) : null}
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div>
                <Table border striped className="px-0 bg-white ">
                  <thead className="">
                    <tr className="text-center text-black bg-white border">
                      <th></th>
                      <th width="20%">Created</th>
                      <th width="20%">InvoiceNo</th>
                      <th width="20%">Payment Status</th>
                      <th width="20%">Supplier</th>
                      <th width="20%">Description</th>
                      <th width="20%" className="text-end"></th>
                    </tr>
                  </thead>
                  <tbody className="text-primary border">
                    {this.state.rawmaterialpurchaseorders
                      .sort((a, b) => (a.created > b.created ? 1 : -1))
                      .map((purchaseOrder, i) =>
                        this.state.reload === true ? (
                          <EditRawMaterialPurchaseOrderInline
                            index={i}
                            purchaseOrder={purchaseOrder}
                            OnDeleted={this.OnDeleted}
                            rawmaterialsuppliers={
                              this.state.rawmaterialsuppliers
                            }
                            prods={this.state.prods}
                          ></EditRawMaterialPurchaseOrderInline>
                        ) : null
                      )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export class EditRawMaterialPurchaseOrderInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      purchaseOrder: "",
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      editModalShow: false,
    };
  }

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDelete = () => {
    if (this.state.purchaseOrder.id == undefined) {
      this.ShowMessagBox(
        "Information",
        "This is invalid row which can't be deleted. Press Refresh!"
      );
      return;
    }

    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteRawMaterialsPurchase/" +
        this.state.purchaseOrder.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert(
            "Not deleted. This PurchaseOrder may be associated with few Products. First disassociate this purchaseOrder from the Products and then try!"
          );
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Status",
          "Deleted Successfully. Click Refresh Button to see the changes!"
        );
        this.props.OnDeleted();
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("OnDelete Failed" + error);
      });
  };

  componentDidMount = () => {
    this.setState({ purchaseOrder: this.props.purchaseOrder });
  };
  OnEditButtonClick = () => {
    this.setState({ editModalShow: true }, () => {});
  };

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {});
  };
  getSupplierName = (id) => {
    var sup = this.props.rawmaterialsuppliers.filter((s) => s.id == id);
    if (sup.length > 0) return sup[0].name;
    return "";
  };

  render() {
    return (
      <>
        {this.state.editModalShow === true ? (
          <RawMaterialPurchaseOrderAddOrEditModal
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            purchaseOrder={this.state.purchaseOrder}
            rawmaterialsuppliers={this.props.rawmaterialsuppliers}
            Type="Edit"
            prods={this.props.prods}
          />
        ) : (
          ""
        )}
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <tr
          className="text-center align-middle text-secondary bg-white mx-5"
          style={{ fontSize: "12px" }}
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          <td>{this.props.index + 1}</td>
          <td>
            {
              this.context.GetInDateFormat(
                new Date(this.state.purchaseOrder.created)
              )

              // new Date(this.state.purchaseOrder.created).getDate() +
              //   "-" +
              //   (new Date(this.state.purchaseOrder.created).getMonth() + 1) +
              //   "-" +
              //   new Date(this.state.purchaseOrder.created).getFullYear()
            }
          </td>
          <td className="text-center ">{this.state.purchaseOrder.invoiceNo}</td>
          <td className="text-center ">
            {this.state.purchaseOrder.paymentStatusId == 1 ? "Paid" : "Unpaid"}
          </td>
          <td className="text-center ">
            {this.getSupplierName(this.state.purchaseOrder.supplierId)}
          </td>
          <td className="text-center ">
            {this.state.purchaseOrder.description}
          </td>
          <td className="col-md-2 text-end align-middle ">
            <div class="btn-group">
              <OverlayTrigger overlay={<Tooltip>Edit!</Tooltip>}>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    this.OnEditButtonClick(e);
                  }}
                >
                  <BsFillPencilFill size="20" />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                overlay={
                  <Tooltip>
                    Warning! This will delete associated details as well.
                  </Tooltip>
                }
              >
                <Button
                  className="mx-1 mainthemecolor"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        "Warning! This action will delete associated details as well. Are you sure you want to Delete?"
                      )
                    ) {
                      this.OnDelete(e);
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

export class RawMaterialPurchaseOrderAddOrEditModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      purchaseOrder: {
        id: undefined,
      },
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      rawMaterialsPurchaseOrderItems: [],
      showOrderItemModal: false,
      editPurchaseItemRow: 0,
    };
  }

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  GetPurchaseOrderById = (Id) => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetRawMaterialsPurchaseById/" +
        Id
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          if (data.rawMaterialsPurchaseOrderItems != undefined) {
            data.rawMaterialsPurchaseOrderItems =
              data.rawMaterialsPurchaseOrderItems.sort((a, b) =>
                a.seqNo > b.seqNo ? 1 : -1
              );
          }
          this.setState({ purchaseOrder: data }, () => {
            this.setState({
              rawMaterialsPurchaseOrderItems:
                data.rawMaterialsPurchaseOrderItems,
            });
            // this.setState({ reload: false }, () => {
            //   this.setState({ reload: true });
            // });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  AddOrUpdatePurchaseOrderInDB = () => {
    this.ShowLoadSpinner();

    var tmpPurchaseOrder = this.state.purchaseOrder;
    if (this.props.Type == "Edit") {
      //tmpPurchaseOrder = this.state.purchaseOrder;
    } else {
      tmpPurchaseOrder.storeId = this.context.storeId;
    }
    tmpPurchaseOrder.rawMaterialsPurchaseOrderItems =
      this.state.rawMaterialsPurchaseOrderItems;
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmpPurchaseOrder),
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/AddOrUpdateRawMaterialsPurchase/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.HideLoadSpinner();
          this.props.onHide();
          return;
        }
        this.HideLoadSpinner();
        alert("This PurchaseOrder is already exist. try again!");
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("Exception. Received an Exception. Try after sometime !" + error);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.AddOrUpdatePurchaseOrderInDB();
  };

  handleSelectionChange = (e, index) => {
    //const { name, value } = e.target;
    var name = "fileName";
    const list = [...this.state.rawMaterialsPurchaseOrderItems];
    list[index][name] = e.value;
    this.setState({ rawMaterialsPurchaseOrderItems: list });
  };

  UpdatePurchaseItem = (item, index) => {
    this.state.rawMaterialsPurchaseOrderItems[index].sku = item.sku;
    this.state.rawMaterialsPurchaseOrderItems[index].itemId = item.itemId;
    this.state.rawMaterialsPurchaseOrderItems[index].rate = item.rate;
    this.state.rawMaterialsPurchaseOrderItems[index].qty = item.qty;
    this.state.rawMaterialsPurchaseOrderItems[index].mrp = item.mrp;
    this.state.rawMaterialsPurchaseOrderItems[index].discount = item.discount;
    this.state.rawMaterialsPurchaseOrderItems[index].tax = item.tax;
    this.setState(
      {
        rawMaterialsPurchaseOrderItems:
          this.state.rawMaterialsPurchaseOrderItems,
      },
      () => {
        this.setState({ editModalShow: false }, () => {});
      }
    );
  };

  AddPurchaseItem = (item) => {
    this.setState(
      {
        rawMaterialsPurchaseOrderItems: [
          ...this.state.rawMaterialsPurchaseOrderItems,
          {
            sku: item.sku,
            itemId: item.itemId,
            rate: item.rate,
            qty: item.qty,
            mrp: item.mrp,
            discount: item.discount,
            tax: item.tax,
            seqNo: this.state.rawMaterialsPurchaseOrderItems.length + 1,
          },
        ],
      },
      () => {
        this.onHideOrderItemModal();
      }
    );
  };

  handleRemoveClick = (indexno) => {
    const list = [...this.state.rawMaterialsPurchaseOrderItems];
    list.splice(indexno, 1);
    this.setState({ rawMaterialsPurchaseOrderItems: list }, () => {});
  };
  onHideOrderItemModal = () => {
    this.setState({ showOrderItemModal: false });
    this.setState({ editModalShow: false }, () => {});
  };
  handleAddItems = (e) => {
    this.setState({ showOrderItemModal: true });
  };

  OnEditButtonClick = (e, i) => {
    this.setState({ editPurchaseItemRow: i }, () => {
      this.setState({ editModalShow: true }, () => {});
    });
  };

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {
      // this.props.OnUpdated();
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "invoiceNo":
        this.state.purchaseOrder.invoiceNo = value;
        break;
      case "paymentStatusId":
        this.state.purchaseOrder.paymentStatusId = value;
        break;
      case "description":
        this.state.purchaseOrder.description = value;
        break;
      case "supplierId":
        this.state.purchaseOrder.supplierId = value;
        break;
    }
    this.setState({ purchaseOrder: this.state.purchaseOrder });
  };

  componentDidMount() {
    if (this.props.Type == "Edit") {
      //this.setState({ purchaseOrder: this.props.purchaseOrder });
      this.GetPurchaseOrderById(this.props.purchaseOrder.id);
    }
    if (document.getElementById("invoiceNo") != null)
      document.getElementById("invoiceNo").focus();

    // ReactDOM.findDOMNode(this).addEventListener("keydown", this._handleNVEvent);
    // ReactDOM.findDOMNode(this).addEventListener("keyup", this._handleNVEvent);
    document.addEventListener("keydown", (event) => {
      if (event.key == "F8") {
        this.handleAddItems(event);
      }
      // if (
      //   (event.metaKey && event.key === 13) ||
      //   (!event.ctrlKey && event.key === 13)
      // ) {
      //   alert("hi");
      // }
    });
  }
  GetProductName = (id) => {
    var prods = this.props.prods.filter((f) => f.id == id);
    if (prods.length > 0) {
      return prods[0].name + " " + prods[0].weight + prods[0].unit;
    }
  };

  GetTotalOfPurchaseOrder = (rawMaterialsPurchaseOrderItems) => {
    let sum = 0;
    rawMaterialsPurchaseOrderItems.forEach((v) => {
      sum += this.context.ReturnwithTax(v.rate, v.qty, v.discount, v.tax);
    });

    return sum;
  };

  componentDidUpdate = () => {};

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="xl"
          // className="modal-fullscreen"
          style={{
            fontFamily: "sans-serif",
            fontSize: "14px",
          }}
          backdrop="static"
          keyboard={false}
          dialogClassName="purchaseorder-modal"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit"
                  ? "Edit Purchase Order"
                  : "Add Purchase Order"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              {this.state.showOrderItemModal == true ? (
                <>
                  <AddOrEditRawMaterialPurchaseOrderItem
                    onHide={this.onHideOrderItemModal}
                    AddPurchaseItem={this.AddPurchaseItem}
                    Type="Add"
                    prods={this.props.prods}
                  ></AddOrEditRawMaterialPurchaseOrderItem>
                </>
              ) : (
                <></>
              )}
              {this.state.editModalShow == true ? (
                <>
                  <AddOrEditRawMaterialPurchaseOrderItem
                    onHide={this.onHideOrderItemModal}
                    UpdatePurchaseItem={this.UpdatePurchaseItem}
                    Type="Edit"
                    purchaseOrderItem={
                      this.state.rawMaterialsPurchaseOrderItems[
                        this.state.editPurchaseItemRow
                      ]
                    }
                    Index={this.state.editPurchaseItemRow}
                    prods={this.props.prods}
                  ></AddOrEditRawMaterialPurchaseOrderItem>
                </>
              ) : (
                <></>
              )}
              <Row
                className="m-2 p-2 bg-light border"
                style={{ fontSize: "12pt" }}
              >
                <Col md={6}>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      InvoiceNo
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        autoFocus
                        id="invoiceNo"
                        name="invoiceNo"
                        maxlength="99"
                        value={this.state.purchaseOrder.invoiceNo}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Payment Status
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        name="paymentStatusId"
                        value={this.state.purchaseOrder.paymentStatusId}
                        required
                        className="text-start"
                        placeholder=""
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value=""></option>
                        <option value={1}>Paid</option>
                        <option value={2}>Unpaid</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Supplier
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        name="supplierId"
                        value={this.state.purchaseOrder.supplierId}
                        required
                        className="text-start"
                        placeholder=""
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value=""></option>
                        {this.props.rawmaterialsuppliers != undefined ? (
                          <>
                            {this.props.rawmaterialsuppliers.map((supplier) => (
                              <>
                                <option value={supplier.id}>
                                  {supplier.name}
                                </option>
                              </>
                            ))}
                          </>
                        ) : (
                          <></>
                        )}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Description
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="description"
                        maxlength="99"
                        value={this.state.purchaseOrder.description}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="m-0">
                <Col md={8} className="text-start text-dark my-3">
                  <span>
                    <h4> Purchase Items: </h4>
                  </span>
                </Col>
                <Col md={4} className="text-end align-bottom">
                  <Button
                    variant="primary"
                    classname="px-2"
                    id="addbutton"
                    onClick={(e) => this.handleAddItems(e)}
                  >
                    + Add (F8)
                  </Button>
                  &nbsp;&nbsp;
                </Col>
              </Row>
              <Row className="m-0 p-0 bg-light border">
                <Col>
                  <Table bordered size="sm" className=" text-start">
                    <thead className="bg-secondary text-white">
                      <tr size="lg" className="text-center">
                        <td width="5%"></td>
                        <td width="37%">Product Name</td>
                        {/* <td width="12%">SKU</td> */}
                        <td width="7%">Mrp</td>
                        <td width="7%">Purchase Rate</td>
                        <td width="7%">Qty</td>
                        <td width="7%">Discount (%)</td>
                        <td width="7%">Tax (%)</td>
                        <td width="7%">Sub.Tot</td>
                        <td width="7%">InStock</td>
                        <td width="10%" className="text-center">
                          Options
                        </td>
                      </tr>
                    </thead>
                    <tbody size="lg">
                      {this.state.rawMaterialsPurchaseOrderItems != undefined
                        ? this.state.rawMaterialsPurchaseOrderItems
                            //.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                            .map((row, i) => (
                              <>
                                <tr
                                  height="30pt"
                                  className="align-middle text-center"
                                >
                                  <td className="text-center">{i + 1}</td>
                                  <td className="text-start">
                                    {this.GetProductName(row.itemId)}
                                  </td>
                                  {/* <td>{row.sku}</td> */}
                                  <td>
                                    {Number(row.mrp).toLocaleString(
                                      this.context.storeSettings.defaultLocale,
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 1,
                                        style: "currency",
                                        currency:
                                          this.context.storeSettings
                                            .defaultCurrency,
                                      }
                                    )}
                                  </td>
                                  <td>
                                    {Number(row.rate).toLocaleString(
                                      this.context.storeSettings.defaultLocale,
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 1,
                                        style: "currency",
                                        currency:
                                          this.context.storeSettings
                                            .defaultCurrency,
                                      }
                                    )}
                                  </td>
                                  <td>{row.qty}</td>
                                  <td>{row.discount}</td>
                                  <td>{row.tax}</td>
                                  <td>
                                    {this.context
                                      .ReturnwithTax(
                                        row.rate,
                                        row.qty,
                                        row.discount,
                                        row.tax
                                      )
                                      .toLocaleString(
                                        this.context.storeSettings
                                          .defaultLocale,
                                        {
                                          minimumFractionDigits: 0,
                                          maximumFractionDigits: 1,
                                          style: "currency",
                                          currency:
                                            this.context.storeSettings
                                              .defaultCurrency,
                                        }
                                      )}
                                  </td>
                                  <td>
                                    {row.addedToStock === true
                                      ? "Added"
                                      : "Not Added"}
                                  </td>
                                  <td className="text-center">
                                    <OverlayTrigger
                                      overlay={<Tooltip>Edit!</Tooltip>}
                                    >
                                      <Button
                                        className="mainthemecolor"
                                        onClick={(e) => {
                                          this.OnEditButtonClick(e, i);
                                        }}
                                      >
                                        <BsFillPencilFill size="20" />
                                      </Button>
                                    </OverlayTrigger>
                                    &nbsp;&nbsp;
                                    <OverlayTrigger
                                      overlay={<Tooltip>Delete!</Tooltip>}
                                    >
                                      <Button
                                        variant="outline-danger"
                                        onClick={(e) => {
                                          this.handleRemoveClick(i);
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="16"
                                          height="16"
                                          fill="currentColor"
                                          class="bi bi-trash"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                          <path
                                            fill-rule="evenodd"
                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                          />
                                        </svg>
                                      </Button>
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                              </>
                            ))
                        : ""}
                      <tr>
                        <td colSpan={9} className="text-end">
                          <h3>
                            Total ={" "}
                            {this.state.rawMaterialsPurchaseOrderItems &&
                            this.state.rawMaterialsPurchaseOrderItems.length > 0
                              ? this.GetTotalOfPurchaseOrder(
                                  this.state.rawMaterialsPurchaseOrderItems
                                ).toLocaleString(
                                  this.context.storeSettings.defaultLocale,
                                  {
                                    minimumFractionDigits: 0,
                                    maximumFractionDigits: 1,
                                    style: "currency",
                                    currency:
                                      this.context.storeSettings
                                        .defaultCurrency,
                                  }
                                )
                              : ""}
                          </h3>
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                    </tbody>
                  </Table>
                </Col>
              </Row>
              <Row className="text-end">
                <h6>
                  Information: MRP and Instock will be updated in "RawMaterial
                  Inventory" accordingly while Save!
                </h6>
              </Row>
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  Save
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
export class AddOrEditRawMaterialPurchaseOrderItem extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      sku: "",
      itemId: "",
      mrp: 0,
      rate: 0,
      qty: 1,
      discount: 0,
      tax: 0,
      productNames: [],
    };
  }
  LoadProductName = () => {
    {
      var pd = [];

      this.props.prods.map((r) =>
        pd.push({
          value: r.id,
          // label: (r.sku == null ? "" : r.sku + " ") + r.name,
          label: r.name + " " + r.weight + r.unit,
        })
      );
      this.setState({
        productNames: pd.sort((a, b) =>
          a.label.toUpperCase() > b.label.toUpperCase() ? 1 : -1
        ),
      });
    }
  };
  GetLabelOfProduct = (id) => {
    var prods = this.props.prods.filter((f) => f.id == id);
    if (prods.length > 0)
      return prods[0].name + " " + prods[0].weight + prods[0].unit;
    return "";
  };
  componentDidMount = () => {
    if (this.props.Type == "Edit") {
      this.setState({ sku: this.props.purchaseOrderItem.sku });
      this.setState({ itemId: this.props.purchaseOrderItem.itemId });
      this.setState({ rate: this.props.purchaseOrderItem.rate });
      this.setState({ qty: this.props.purchaseOrderItem.qty });
      this.setState({ mrp: this.props.purchaseOrderItem.mrp });
      this.setState({ discount: this.props.purchaseOrderItem.discount });
      this.setState({ tax: this.props.purchaseOrderItem.tax });
    }
    document.getElementById("productname").focus();

    // document.addEventListener("keydown", (event) => {
    //   if (event.key == "Enter") {
    //     this.handleSubmit(event);
    //   }
    // });
    if (this.state.productNames.length == 0) this.LoadProductName();
  };

  OnProductNameChange = (e) => {
    this.setState({ itemId: e.value });
    // this.setState({
    //   sku: this.props.prods.filter((f) => f.name == e.value)[0].sku,
    // });
  };

  handleItemsInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "sku":
        this.setState({ sku: value });
        break;
      case "itemId":
        this.setState({ itemId: value });
        break;

      case "rate":
        this.setState({ rate: value });
        break;
      case "qty":
        this.setState({ qty: value });
        break;
      case "mrp":
        this.setState({ mrp: value });
        break;
      case "discount":
        this.setState({ discount: value });
        break;
      case "tax":
        this.setState({ tax: value });
        break;
    }
  };
  handleSubmit = (event) => {
    if (
      this.state.itemId == "" ||
      this.state.rate <= 0 ||
      this.state.qty <= 0 ||
      this.state.mrp <= 0 ||
      this.state.discount < 0 ||
      this.state.tax < 0
    ) {
      alert("enter all manadatory fields and try!");
      return;
    }
    if (this.props.Type == "Add") {
      this.props.AddPurchaseItem({
        sku: this.state.sku,
        itemId: this.state.itemId,
        rate: this.state.rate,
        qty: this.state.qty,
        mrp: this.state.mrp,
        discount: this.state.discount,
        tax: this.state.tax,
      });
    } else {
      this.props.UpdatePurchaseItem(
        {
          sku: this.state.sku,
          itemId: this.state.itemId,

          rate: this.state.rate,
          qty: this.state.qty,
          mrp: this.state.mrp,
          discount: this.state.discount,
          tax: this.state.tax,
        },
        this.props.Index
      );
    }
  };

  render() {
    return (
      <>
        <div className="container ">
          <Modal
            {...this.props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={true}
            backdrop="static"
            keyboard={false}
            dialogClassName="CouponModal"
          >
            {/* <Form onSubmit={this.handleSubmit}> */}
            {/* <Form> */}
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit"
                  ? "Edit Purchase Item"
                  : "Add Purchase Item"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Row className="m-2 p-2 bg-light " style={{ fontSize: "12pt" }}>
                <Col md={12}>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Product Name / SKU (*)
                    </Form.Label>
                    <Col sm="9">
                      <span id="productname">
                        <Select
                          name="itemId"
                          autoFocus
                          value={{
                            value: this.state.itemId,
                            label: this.GetLabelOfProduct(this.state.itemId),
                          }}
                          onChange={(e) => this.OnProductNameChange(e)}
                          options={this.state.productNames}
                        ></Select>
                      </span>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="m-2 p-2 bg-light " style={{ fontSize: "12pt" }}>
                <Col md={12}>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Mrp (*)
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        size="lg"
                        className="pd-2 w-100"
                        name="mrp"
                        required
                        value={this.state.mrp}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleItemsInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="m-2 p-2 bg-light " style={{ fontSize: "12pt" }}>
                <Col md={12}>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Purchase Rate (*)
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        size="lg"
                        className="pd-2 w-100"
                        name="rate"
                        required
                        value={this.state.rate}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleItemsInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="m-2 p-2 bg-light " style={{ fontSize: "12pt" }}>
                <Col md={12}>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Qty (*)
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        size="lg"
                        className="pd-2 w-100"
                        name="qty"
                        required
                        value={this.state.qty}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleItemsInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="m-2 p-2 bg-light " style={{ fontSize: "12pt" }}>
                <Col md={12}>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Discount % (*)
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        size="lg"
                        className="pd-2 w-100"
                        name="discount"
                        required
                        value={this.state.discount}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleItemsInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="m-2 p-2 bg-light " style={{ fontSize: "12pt" }}>
                <Col md={12}>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      TAX % (*)
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        className="pd-2 w-100"
                        name="tax"
                        size="lg"
                        value={this.state.tax}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleItemsInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="m-2 p-2 bg-light " style={{ fontSize: "14pt" }}>
                <Col md={12}>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Total
                    </Form.Label>
                    <Col sm="9">
                      {Number(
                        this.context.ReturnwithTax(
                          this.state.rate,
                          this.state.qty,
                          this.state.discount,
                          this.state.tax
                        )
                      ).toLocaleString(
                        this.context.storeSettings.defaultLocale,
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1,
                          style: "currency",
                          currency: this.context.storeSettings.defaultCurrency,
                        }
                      )}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                {/* <Button variant="primary" size="lg" type="submit"> */}
                <Button variant="primary" size="lg" onClick={this.handleSubmit}>
                  Save
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
            {/* </Form> */}
          </Modal>
        </div>
      </>
    );
  }
}
