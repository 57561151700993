import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";

import {
  OverlayTrigger,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export class RestaurantTables extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      restaurantTables: [],
      menuReferences: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
    };
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDeleted = () => {
    this.OnRefreshButtonClick();
  };
  OnSaved = () => {
    // this.setState({ restaurantTables: this.context.restaurantTables });
    // this.setState({ reload: false }, () => this.setState({ reload: true }));
    // this.ShowMessagBox("Status", "Saved Successfully!");
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.setState({ restaurantTables: [] }, () => {
      this.GetRestaurantTables();
    });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  handleAddRestaurantTable = (e) => {
    this.setState({
      restaurantTables: [
        ...this.state.restaurantTables,
        {
          storeId: this.context.storeId,
          name: "",
          noSeats: "",
          details: "",
          waiterName: "",
          seqNo: this.state.restaurantTables.length + 1,
        },
      ],
    });
    alert("An empty row added at the end of list below!");
  };

  handleInputChange = (e, i) => {
    const { name, value } = e.target;

    //validate for minus values not to allow
    switch (name.trim()) {
      // case "name":
      //   this.state.restaurantTables[i].name = value;
      //   break;
      case "name":
        this.state.restaurantTables[i].name = value;
        break;
      case "noSeats":
        this.state.restaurantTables[i].noSeats = value;
        break;
      case "seqNo":
        this.state.restaurantTables[i].seqNo = value;
        break;
      case "waiterName":
        this.state.restaurantTables[i].waiterName = value;
        break;
      case "details":
        this.state.restaurantTables[i].details = value;
        break;
    }
    this.setState({ restaurantTables: this.state.restaurantTables });
  };

  OnSave = (e) => {
    this.ShowLoadSpinner();

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.restaurantTables),
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/AddOrUpdateRestaurantTables/" +
        this.context.storeId,
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.GetRestaurantTables();
          this.HideLoadSpinner();
          this.ShowMessagBox("Information", "Saved Successfully!");
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed!");
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed! " + error);
      });
  };

  OnDelete = (i) => {
    if (this.state.restaurantTables[i].id == undefined) {
      this.ShowMessagBox(
        "Information",
        "This is invalid row which can't be deleted. Press Refresh!"
      );
      return;
    }

    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteRestaurantTable/" +
        this.state.restaurantTables[i].id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert("not deleted");
          return;
        }
        // this.GetRestaurantTables();
        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Status",
          "Deleted Successfully. Click Refresh Button to see the changes!"
        );
        this.OnDeleted();
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("OnDelete Failed" + error);
      });
  };

  GetRestaurantTables = () => {
    this.setState({ restaurantTables: [] }, () => {
      fetch(
        process.env.REACT_APP_API +
          "StoreManager/GetRestaurantTables/" +
          this.context.storeId
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 400) {
          } else {
            this.setState({
              restaurantTables: data.sort((a, b) =>
                a.seqNo > b.seqNo ? 1 : -1
              ),
            });
          }
        })
        .catch((error) => {
          alert("GetRestaurantTables:: " + error);
        });
    });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.GetRestaurantTables();
  };

  render() {
    return (
      <>
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <div className="Container-fluid p-1 ">
          <Row>
            <Col className="" xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="pagebackground p-0">
              {this.state.msgboxOpen === true ? (
                <MessageBox
                  open={this.state.msgboxOpen}
                  title={this.state.msgboxTitle}
                  message={this.state.msgboxMessage}
                  callback={this.CloseMessagBoxCallback}
                />
              ) : (
                ""
              )}
              <Navbar
                expand="lg"
                className="secondarythemecolor position-sticky fixed-top  px-3"
              >
                <h4> &nbsp; &nbsp;Restaurant Tables &nbsp;</h4>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemecolor"
                        onClick={() => this.handleAddRestaurantTable()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={(e) => {
                          this.OnSave(e);
                        }}
                      >
                        <BiSave size="26" />
                        Save
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Refresh
                      </Button>
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div>
                <Table border striped className="px-0 bg-white ">
                  <thead className="">
                    <tr className="text-center text-black bg-white border">
                      {/* <th width="20%">restaurantTable Name</th> */}
                      <th></th>
                      <th width="20%">Table Name</th>
                      <th width="10%">No. of Seats</th>
                      <th width="10%">Seq No</th>
                      <th width="20%">Waiter's Name</th>
                      <th width="20%">Details</th>
                      <th width="20%" className="text-center">
                        Options
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-primary border">
                    {this.state.restaurantTables.map((mainMenu, i) => (
                      <>
                        <tr>
                          <td>{i + 1}</td>
                          <td>
                            <Form.Control
                              type="text"
                              name="name"
                              value={this.state.restaurantTables[i].name}
                              required
                              className="text-start  "
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e, i)}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              name="noSeats"
                              value={this.state.restaurantTables[i].noSeats}
                              required
                              className="text-start  "
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e, i)}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              name="seqNo"
                              value={this.state.restaurantTables[i].seqNo}
                              required
                              className="text-start  "
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e, i)}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              name="waiterName"
                              value={this.state.restaurantTables[i].waiterName}
                              required
                              className="text-start  "
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e, i)}
                            />
                          </td>
                          <td>
                            {" "}
                            <Form.Control
                              type="text"
                              name="details"
                              min={0}
                              value={mainMenu.details}
                              required
                              placeholder=""
                              align="center"
                              className="text-center  "
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e, i)}
                            />
                          </td>
                          <td className="text-center align-middle">
                            <Button
                              className="mx-1 mainthemecolor"
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Warning! This action will delete this Carousel Row and associated Products as well. Are you sure you want to Delete?"
                                  )
                                ) {
                                  this.OnDelete(i);
                                }
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                class="bi bi-trash"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path
                                  fill-rule="evenodd"
                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                />
                              </svg>
                            </Button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
