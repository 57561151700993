import React, { Component } from "react";
import CartContext from "./CartContext";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import Select from "react-select";
import reactSelect from "react-select";

import {
  FloatingLabel,
  OverlayTrigger,
  Tooltip,
  Table,
  InputGroup,
  Modal,
  Button,
  Row,
  Col,
  Form,
  Image,
  Alert,
} from "react-bootstrap";

// https://www.geeksforgeeks.org/how-to-create-an-editable-table-with-add-delete-and-search-filter-using-reactjs/
export class BrandAddOrEditModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      brand: {
        brandName: "",
        manufacturerName: "",
        imgFileName: "",
        hideMe: false,
      },
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
    };
  }

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  AddOrUpdateBrandInDB = () => {
    this.ShowLoadSpinner();

    var tmpBrand = {};
    if (this.props.Type == "Edit") {
      tmpBrand = this.props.brand;
    } else {
      tmpBrand.storeId = this.context.storeId;
    }
    tmpBrand.brandName = this.state.brand.brandName;
    tmpBrand.imgFileName = this.state.brand.imgFileName;
    tmpBrand.manufacturerName = this.state.brand.manufacturerName;
    tmpBrand.hideMe = this.state.brand.hideMe;

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmpBrand),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/AddOrUpdateBrand/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.context.getBrands();
          this.HideLoadSpinner();
          // this.ShowMessagBox(
          //   "Status",
          //   "Saved Successfully. Click Refresh Button to see the changes!"
          // );
          this.props.onHide();
          return;
        }
        this.HideLoadSpinner();
        alert("Not Saved. Brand Name must be unique. try again!");
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("Exception. Received an Exception. Try after sometime !" + error);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.AddOrUpdateBrandInDB();
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    //validate for minus values not to allow
    switch (name.trim()) {
      case "brandName":
        this.state.brand.brandName = value;
        break;
      case "manufacturerName":
        this.state.brand.manufacturerName = value;
        break;
      case "imgFileName":
        this.state.brand.imgFileName = value;
        break;
      case "hideMe":
        this.state.brand.hideMe = value;
        break;
    }
    this.setState({ brand: this.state.brand }, () => {});
  };

  componentDidMount() {
    if (this.props.Type == "Edit") {
      this.setState({ brand: this.props.brand });
    }
  }

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          dialogClassName="BrandModal"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit" ? "Edit brand" : "Add brand"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              {/* <Form.Label column>
                <h4 className="text-dark">brand Details</h4>
              </Form.Label> */}
              <Row
                className="m-2 p-2 bg-light border"
                style={{ fontSize: "9pt" }}
              >
                <Col md={6}>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Brand Name
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="brandName"
                        maxlength="99"
                        value={this.state.brand.brandName}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Manufacturer Name
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="manufacturerName"
                        maxlength="99"
                        value={this.state.brand.manufacturerName}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Image File Name
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        name="imgFileName"
                        value={this.state.brand.imgFileName}
                        required
                        placeholder=""
                        autoComplete="off"
                        className="text-start"
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value=""></option>
                        {this.context.images.map((r) => (
                          <option value={r.name}>{r.name}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Show Me
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        name="hideMe"
                        value={!this.state.brand.hideMe}
                        required
                        disabled
                        className="text-start"
                        placeholder=""
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value="true">True</option>
                        <option value="false">False</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6} className="my-3">
                  <Form.Group controlId="Image" className="text-center">
                    <img
                      width="400px"
                      height="300px"
                      src={
                        this.context.store.storageBlobUrl +
                        this.context.store.storageBlobContainerName +
                        "/images/" +
                        this.state.brand.imgFileName +
                        this.context.store.storageSasToken
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  Save
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
