import React, { Component } from "react";
import CartContext from "./CartContext";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { OrderDetails } from "./OrderDetails";
import { SideMenu } from "./SideMenu";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import { OrderHistory } from "./OrderHistory";
import { Navigate } from "react-router-dom";
import {
  PurchaseByRawMaterialReport,
  RawMaterialsPurchaseOrderSummaryReport,
  SalesReport,
} from "./Reports2";

import {
  InputGroup,
  Stack,
  FormControl,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

import Select from "react-select";
import {} from "react-bootstrap";
import { AccountLedger } from "./AccountLedger";

export class Reports extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      rawMaterialsInventory: [],
      firstloaded: false,
      selectedReport: "",
      dateRange: "Today",
      reportlist: [
        { id: 1, name: "1. Sales by Product" },
        { id: 2, name: "2. Sales by Category" },
        { id: 3, name: "3. Sales by Order" },
        { id: 4, name: "4. Top Selling Products" },
        { id: 5, name: "5. Top Purchasing Customers" },
        { id: 6, name: "6. Products Purchase Summary - by Products" },
        { id: 7, name: "7. Product Purchase Order(PO) Summary" },
        { id: 8, name: "8. RawMaterial Purchase Summary - by Material" },
        { id: 9, name: "9. RawMaterials Purchase Order(PO) Summary" },
        { id: 10, name: "10. Sales & Purchases Report / TAX Register(GST)" },
        { id: 11, name: "11. Account Ledger" },

        // { id: 10, name: "GSTR-3B" },
      ],
    };
  }
  GetRawMaterialsInventories = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetRawMaterialsInventories/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ rawMaterialsInventory: data }, () => {});
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  OnDateRangeChanged = (e) => {
    const { name, value } = e.target;
    this.setState({ dateRange: value }, () => {
      this.setState({ result: [] }, () => {});
    });
  };
  OnReportChange = (e) => {
    const { name, value } = e.target;
    this.setState({ selectedReport: value }, () => {});
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    if (this.state.firstloaded == false) {
      this.state.firstloaded = true;
      this.GetRawMaterialsInventories();
    }
  };

  render() {
    if (this.context.storeId === "") {
      // return <Navigate to="/UserLogin" />;
      return <Navigate to="/Home" />;
    }
    return (
      <>
        <div className="Container-fluid mx-0 ">
          <Row>
            {window.innerWidth > 1200 ? (
              <>
                <Col className="pagebackground " xs={2}>
                  <SideMenu></SideMenu>
                </Col>
              </>
            ) : (
              <></>
            )}

            <Col
              xs={window.innerWidth > 1200 ? 10 : 12}
              className="pagebackground "
            >
              <div>
                <Navbar className="secondarythemecolor position-sticky fixed-top text-white mx-0 my-1">
                  <Navbar.Collapse className="justify-content-start m-0 p-0 ">
                    <Nav>
                      <div className="text-start border-3 border-dark">
                        <h3>Reports</h3>
                      </div>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
              </div>
              <div className="bg-light px-3">
                <Table className="bg-light m-0 p-0">
                  <tr className="border-0">
                    <td width="100%">
                      <InputGroup className="mb-1">
                        <InputGroup.Text id="basic-addon1">
                          Select Report
                        </InputGroup.Text>
                        <Form.Select
                          value={this.state.selectedReport}
                          onChange={(e) => {
                            this.OnReportChange(e);
                          }}
                        >
                          <option value=""></option>
                          {this.state.reportlist.map((m) => (
                            <>
                              <option value={m.id}>{m.name}</option>
                            </>
                          ))}
                        </Form.Select>
                      </InputGroup>
                    </td>
                    {/* <td width="30%">
                      <InputGroup className="mb-1">
                        <InputGroup.Text id="basic-addon1">
                          Date Range
                        </InputGroup.Text>
                        <Form.Select
                          value={this.state.dateRange}
                          onChange={(e) => {
                            this.OnDateRangeChanged(e);
                          }}
                        >
                          <option value="Today">Today</option>
                          <option value="Yesterday">Yesterday</option>
                          <option value="Last 3 Days">Last 3 Days</option>
                          <option value="Last 7 Days">Last 7 Days</option>
                          <option value="This Month">This Month</option>
                          <option value="Last Month">Last Month</option>
                          <option value="Last 3 Months">Last 3 Months</option>
                          <option value="All">All</option>
                        </Form.Select>
                      </InputGroup>
                    </td> */}
                  </tr>
                </Table>
                {this.state.selectedReport == 1 ? (
                  <>
                    <SalesByProductReport
                      dateRange={this.state.dateRange}
                    ></SalesByProductReport>
                  </>
                ) : this.state.selectedReport == 2 ? (
                  <>
                    <SalesByCategoryReport
                      dateRange={this.state.dateRange}
                    ></SalesByCategoryReport>
                  </>
                ) : this.state.selectedReport == 3 ? (
                  <>
                    <OrderHistory report={true}></OrderHistory>
                  </>
                ) : this.state.selectedReport == 4 ? (
                  <>
                    <TopSellingProducts report={true}></TopSellingProducts>
                  </>
                ) : this.state.selectedReport == 5 ? (
                  <>
                    <TopPurchasingCustomers
                      report={true}
                    ></TopPurchasingCustomers>
                  </>
                ) : this.state.selectedReport == 6 ? (
                  <>
                    <PurchaseByProductReport
                    // dateRange={this.state.dateRange}
                    ></PurchaseByProductReport>
                  </>
                ) : this.state.selectedReport == 7 ? (
                  <>
                    <PurchaseSummaryReport
                    // dateRange={this.state.dateRange}
                    ></PurchaseSummaryReport>
                  </>
                ) : this.state.selectedReport == 8 ? (
                  <>
                    <PurchaseByRawMaterialReport
                      // dateRange={this.state.dateRange}
                      rawMaterialsInventory={this.state.rawMaterialsInventory}
                    ></PurchaseByRawMaterialReport>
                  </>
                ) : this.state.selectedReport == 9 ? (
                  <>
                    <RawMaterialsPurchaseOrderSummaryReport
                    // dateRange={this.state.dateRange}
                    ></RawMaterialsPurchaseOrderSummaryReport>
                  </>
                ) : this.state.selectedReport == 10 ? (
                  <>
                    <SalesReport
                    // dateRange={this.state.dateRange}
                    ></SalesReport>
                  </>
                ) : this.state.selectedReport == 11 ? (
                  <>
                    <AccountLedger isReport={true}></AccountLedger>
                  </>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export class SalesByProductReport extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      dateRange: "Today",
      result: [],
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  OnDateRangeChanged = (e) => {
    const { name, value } = e.target;
    this.setState({ dateRange: value }, () => {
      this.setState({ result: [] }, () => {});
    });
  };

  GetSalesByProduct = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetSalesByProduct/" +
        this.context.storeId +
        "/" +
        this.state.dateRange +
        "/" +
        this.context.GetTicks(this.state.startDate) +
        "/" +
        this.context.GetTicks(this.state.endDate)
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ result: data });
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // alert("GetOrders fetch err:" + error);
      });
  };

  DownloadToCSV = () => {
    var headings = "#,ProductName,TotalQty,SalesAmount,Tax,Total";
    var reportDetails = "Sales By Product Report\n";
    reportDetails += this.context.GetDateResult(
      this.state.dateRange,
      this.state.startDate,
      this.state.endDate
    );
    var rows = "";
    this.state.result
      .sort((a, b) => (a.totqty < b.totqty ? 1 : -1))
      .map((m, i) => {
        rows +=
          i +
          1 +
          "," +
          m.result.name +
          "," +
          Number(m.totqty).toFixed(2) +
          "," +
          Number(m.price).toFixed(2) +
          "," +
          Number(
            (m.price * this.context.taxDetails.taxPercentage) / 100
          ).toFixed(2) +
          "," +
          Number(
            m.price + (m.price * this.context.taxDetails.taxPercentage) / 100
          ).toFixed(2);
        rows += "\n";
      });

    var result = reportDetails + "\n\n" + headings + "\n" + rows;
    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download =
      "products-" +
      new Date().getDate() +
      (new Date().getMonth() + 1) +
      new Date().getFullYear() +
      "-" +
      new Date().getMinutes() +
      new Date().getSeconds() +
      ".csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  componentDidMount = () => {
    //this.GetSalesByProduct();
  };

  onStartDateChange = (date) => {
    this.setState({ startDate: date });
  };
  onEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  render() {
    return (
      <>
        <div>
          {window.innerWidth > 1200 ? (
            <>
              <tr>
                <td>
                  <InputGroup className="mb-1">
                    <InputGroup.Text id="basic-addon1">
                      Date Range
                    </InputGroup.Text>
                    <Form.Select
                      value={this.state.dateRange}
                      onChange={(e) => {
                        this.OnDateRangeChanged(e);
                      }}
                    >
                      <option value="Custom">Custom</option>
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Last 3 Days">Last 3 Days</option>
                      <option value="Last 7 Days">Last 7 Days</option>
                      <option value="This Month">This Month</option>
                      <option value="Last Month">Last Month</option>
                      <option value="Last 3 Months">Last 3 Months</option>
                      <option value="All">All</option>
                    </Form.Select>
                  </InputGroup>
                </td>
                {this.state.dateRange == "Custom" ? (
                  <>
                    <td>
                      StartDate:{" "}
                      <DateTimePicker
                        onChange={this.onStartDateChange}
                        value={this.state.startDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                    </td>
                    <td>
                      <Stack direction="horizontal">
                        EndDate:
                        <DateTimePicker
                          onChange={this.onEndDateChange}
                          value={this.state.endDate}
                          format="dd-MMM-yy hh:mm a"
                        />
                      </Stack>
                    </td>
                  </>
                ) : (
                  <></>
                )}
                <td>
                  <Button onClick={(e) => this.GetSalesByProduct()}>
                    Show Report
                  </Button>
                </td>
                <td className="">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {this.state.result.length > 0 ? (
                    <>
                      <Button
                        // className="mainthemecolor"
                        variant="danger"
                        onClick={() => this.DownloadToCSV()}
                      >
                        <BiDownload size="20"></BiDownload>
                        Download
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td>
                  <InputGroup className="mb-1">
                    <InputGroup.Text id="basic-addon1">
                      Date Range
                    </InputGroup.Text>
                    <Form.Select
                      value={this.state.dateRange}
                      onChange={(e) => {
                        this.OnDateRangeChanged(e);
                      }}
                    >
                      <option value="Custom">Custom</option>
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Last 3 Days">Last 3 Days</option>
                      <option value="Last 7 Days">Last 7 Days</option>
                      <option value="This Month">This Month</option>
                      <option value="Last Month">Last Month</option>
                      <option value="Last 3 Months">Last 3 Months</option>
                      <option value="All">All</option>
                    </Form.Select>
                  </InputGroup>
                  {this.state.dateRange == "Custom" ? (
                    <>
                      StartDate:{" "}
                      <DateTimePicker
                        onChange={this.onStartDateChange}
                        value={this.state.startDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                      EndDate:
                      <DateTimePicker
                        onChange={this.onEndDateChange}
                        value={this.state.endDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </td>

                <td>
                  <Button onClick={(e) => this.GetSalesByProduct()}>
                    Show Report
                  </Button>
                </td>
                <td className="">
                  {this.state.result.length > 0 ? (
                    <>
                      <Button
                        // className="mainthemecolor"
                        variant="danger"
                        onClick={() => this.DownloadToCSV()}
                      >
                        <BiDownload size="20"></BiDownload>
                        Download
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </>
          )}

          <Table
            bordered
            hover
            className="bg-white text-end"
            style={{
              tableLayout: "revert",
              verticalAlign: "middle",
              fontFamily: "monospace",
              fontSize: "14px",
            }}
          >
            <thead>
              <th className="text-center">No.</th>
              <th className="text-start">Product Name</th>
              <th className="text-start">Category</th>
              <th>Tot.Qty</th>
              <th>
                Sales in Amount
                <br />[
                {this.state.result.length > 0 &&
                  this.state.result
                    .reduce((a, v) => (a = a + Number(v.price)), 0)
                    .toLocaleString(this.context.storeSettings.defaultLocale, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 1,
                      style: "currency",
                      currency: this.context.storeSettings.defaultCurrency,
                    })}
                ]
              </th>
              <th>
                Tax
                <br />[
                {this.state.result.length > 0 &&
                  this.state.result
                    .reduce(
                      (a, v) =>
                        (a =
                          a +
                          Number(
                            v.price * this.context.taxDetails.taxPercentage
                          ) /
                            100),
                      0
                    )
                    .toLocaleString(this.context.storeSettings.defaultLocale, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 1,
                      style: "currency",
                      currency: this.context.storeSettings.defaultCurrency,
                    })}
                ]
              </th>
              <th>
                Total
                <br />[
                {this.state.result.length > 0 &&
                  this.state.result
                    .reduce(
                      (a, v) =>
                        (a =
                          a +
                          v.price +
                          Number(
                            v.price * this.context.taxDetails.taxPercentage
                          ) /
                            100),
                      0
                    )
                    .toLocaleString(this.context.storeSettings.defaultLocale, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 1,
                      style: "currency",
                      currency: this.context.storeSettings.defaultCurrency,
                    })}
                ]
              </th>
            </thead>
            <tbody>
              {this.state.result.length > 0 &&
                this.state.result
                  .sort((a, b) => (a.totqty < b.totqty ? 1 : -1))
                  .map((m, i) => (
                    <>
                      <tr>
                        <td className="text-center">{i + 1}</td>
                        <td className="text-start">{m.result.name}</td>
                        <td className="text-start">{m.result.categoryName}</td>
                        <td>{Number(m.totqty).toFixed(2)}</td>
                        <td>
                          {m.price.toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>
                          {(
                            (m.price * this.context.taxDetails.taxPercentage) /
                            100
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>
                          {(
                            m.price +
                            (m.price * this.context.taxDetails.taxPercentage) /
                              100
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                      </tr>
                    </>
                  ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}

export class SalesByCategoryReport extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      dateRange: "Today",
      result: [],
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  OnDateRangeChanged = (e) => {
    const { name, value } = e.target;
    this.setState({ dateRange: value }, () => {
      this.setState({ result: [] }, () => {});
    });
  };

  GetSalesByCategory = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetSalesByCategory/" +
        this.context.storeId +
        "/" +
        this.state.dateRange +
        "/" +
        this.context.GetTicks(this.state.startDate) +
        "/" +
        this.context.GetTicks(this.state.endDate)
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ result: data });
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // alert("GetOrders fetch err:" + error);
      });
  };

  DownloadToCSV = () => {
    var headings = "#,ProductName,TotalQty,SalesAmount,Tax,Total";
    var reportDetails = "Sales By Product Report\n";
    reportDetails += this.context.GetDateResult(
      this.state.dateRange,
      this.state.startDate,
      this.state.endDate
    );
    var rows = "";
    this.state.result
      .sort((a, b) => (a.totqty < b.totqty ? 1 : -1))
      .map((m, i) => {
        rows +=
          i +
          1 +
          "," +
          m.name +
          "," +
          Number(m.totqty).toFixed(2) +
          "," +
          Number(m.price).toFixed(2) +
          "," +
          Number(
            (m.price * this.context.taxDetails.taxPercentage) / 100
          ).toFixed(2) +
          "," +
          Number(
            m.price + (m.price * this.context.taxDetails.taxPercentage) / 100
          ).toFixed(2);
        rows += "\n";
      });

    var result = reportDetails + "\n\n" + headings + "\n" + rows;
    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download =
      "products-" +
      new Date().getDate() +
      (new Date().getMonth() + 1) +
      new Date().getFullYear() +
      "-" +
      new Date().getMinutes() +
      new Date().getSeconds() +
      ".csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  componentDidMount = () => {
    //this.GetSalesByProduct();
  };

  onStartDateChange = (date) => {
    this.setState({ startDate: date });
  };
  onEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  render() {
    return (
      <>
        <div>
          {window.innerWidth > 1200 ? (
            <>
              <tr>
                <td>
                  <InputGroup className="mb-1">
                    <InputGroup.Text id="basic-addon1">
                      Date Range
                    </InputGroup.Text>
                    <Form.Select
                      value={this.state.dateRange}
                      onChange={(e) => {
                        this.OnDateRangeChanged(e);
                      }}
                    >
                      <option value="Custom">Custom</option>
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Last 3 Days">Last 3 Days</option>
                      <option value="Last 7 Days">Last 7 Days</option>
                      <option value="This Month">This Month</option>
                      <option value="Last Month">Last Month</option>
                      <option value="Last 3 Months">Last 3 Months</option>
                      <option value="All">All</option>
                    </Form.Select>
                  </InputGroup>
                </td>
                {this.state.dateRange == "Custom" ? (
                  <>
                    <td>
                      StartDate:{" "}
                      <DateTimePicker
                        onChange={this.onStartDateChange}
                        value={this.state.startDate}
                        format="dd-MMM-yy HH:mm a"
                      />
                    </td>
                    <td>
                      <Stack direction="horizontal">
                        EndDate:
                        <DateTimePicker
                          onChange={this.onEndDateChange}
                          value={this.state.endDate}
                          format="dd-MMM-yy hh:mm a"
                        />
                      </Stack>
                    </td>
                  </>
                ) : (
                  <></>
                )}
                <td>
                  <Button onClick={(e) => this.GetSalesByCategory()}>
                    Show Report
                  </Button>
                </td>
                <td className="">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {this.state.result.length > 0 ? (
                    <>
                      <Button
                        // className="mainthemecolor"
                        variant="danger"
                        onClick={() => this.DownloadToCSV()}
                      >
                        <BiDownload size="20"></BiDownload>
                        Download
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td>
                  <InputGroup className="mb-1">
                    <InputGroup.Text id="basic-addon1">
                      Date Range
                    </InputGroup.Text>
                    <Form.Select
                      value={this.state.dateRange}
                      onChange={(e) => {
                        this.OnDateRangeChanged(e);
                      }}
                    >
                      <option value="Custom">Custom</option>
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Last 3 Days">Last 3 Days</option>
                      <option value="Last 7 Days">Last 7 Days</option>
                      <option value="This Month">This Month</option>
                      <option value="Last Month">Last Month</option>
                      <option value="Last 3 Months">Last 3 Months</option>
                      <option value="All">All</option>
                    </Form.Select>
                  </InputGroup>
                  {this.state.dateRange == "Custom" ? (
                    <>
                      StartDate:{" "}
                      <DateTimePicker
                        onChange={this.onStartDateChange}
                        value={this.state.startDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                      EndDate:
                      <DateTimePicker
                        onChange={this.onEndDateChange}
                        value={this.state.endDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </td>

                <td>
                  <Button onClick={(e) => this.GetSalesByCategory()}>
                    Show Report
                  </Button>
                </td>
                <td className="">
                  {this.state.result.length > 0 ? (
                    <>
                      <Button
                        // className="mainthemecolor"
                        variant="danger"
                        onClick={() => this.DownloadToCSV()}
                      >
                        <BiDownload size="20"></BiDownload>
                        Download
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </>
          )}

          <Table
            bordered
            hover
            className="bg-white text-end"
            style={{
              tableLayout: "revert",
              verticalAlign: "middle",
              fontFamily: "monospace",
              fontSize: "14px",
            }}
          >
            <thead>
              <th className="text-center">No.</th>
              <th className="text-start">Product Name</th>
              <th>Tot.Qty</th>
              <th>
                Sales in Amount
                <br />[
                {this.state.result.length > 0 &&
                  this.state.result
                    .reduce((a, v) => (a = a + Number(v.price)), 0)
                    .toLocaleString(this.context.storeSettings.defaultLocale, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 1,
                      style: "currency",
                      currency: this.context.storeSettings.defaultCurrency,
                    })}
                ]
              </th>
              <th>
                Tax
                <br />[
                {this.state.result.length > 0 &&
                  this.state.result
                    .reduce(
                      (a, v) =>
                        (a =
                          a +
                          Number(
                            v.price * this.context.taxDetails.taxPercentage
                          ) /
                            100),
                      0
                    )
                    .toLocaleString(this.context.storeSettings.defaultLocale, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 1,
                      style: "currency",
                      currency: this.context.storeSettings.defaultCurrency,
                    })}
                ]
              </th>
              <th>
                Total
                <br />[
                {this.state.result.length > 0 &&
                  this.state.result
                    .reduce(
                      (a, v) =>
                        (a =
                          a +
                          v.price +
                          Number(
                            v.price * this.context.taxDetails.taxPercentage
                          ) /
                            100),
                      0
                    )
                    .toLocaleString(this.context.storeSettings.defaultLocale, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 1,
                      style: "currency",
                      currency: this.context.storeSettings.defaultCurrency,
                    })}
                ]
              </th>
            </thead>
            <tbody>
              {this.state.result.length > 0 &&
                this.state.result
                  .sort((a, b) => (a.totqty < b.totqty ? 1 : -1))
                  .map((m, i) => (
                    <>
                      <tr>
                        <td className="text-center">{i + 1}</td>
                        <td className="text-start">{m.name}</td>
                        <td>{Number(m.totqty).toFixed(1)}</td>
                        <td>
                          {m.price.toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>
                          {(
                            (m.price * this.context.taxDetails.taxPercentage) /
                            100
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>
                          {(
                            m.price +
                            (m.price * this.context.taxDetails.taxPercentage) /
                              100
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                      </tr>
                    </>
                  ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}

export class TopSellingProducts extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      dateRange: "Today",
      result: [],
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  OnDateRangeChanged = (e) => {
    const { name, value } = e.target;
    this.setState({ dateRange: value }, () => {
      this.setState({ result: [] }, () => {});
    });
  };

  TopSellingProductsReportSummary = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/TopSellingProductsReportSummary/" +
        this.context.storeId +
        "/" +
        this.state.dateRange +
        "/" +
        this.context.GetTicks(this.state.startDate) +
        "/" +
        this.context.GetTicks(this.state.endDate)
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ result: data });
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // alert("GetOrders fetch err:" + error);
      });
  };
  DownloadToCSV = () => {
    var headings =
      "#,Product Name, Total Orders(Count),TotalQty,SalesAmount,Tax,Total";
    var reportDetails = "Top Selling Products Report\n";
    reportDetails += this.context.GetDateResult(
      this.state.dateRange,
      this.state.startDate,
      this.state.endDate
    );
    var rows = "";
    this.state.result
      .sort((a, b) => (a.totqty < b.totqty ? 1 : -1))
      .map((m, i) => {
        rows +=
          i +
          1 +
          "," +
          m.name +
          "," +
          m.count +
          "," +
          Number(m.totqty).toFixed(2) +
          "," +
          Number(m.price).toFixed(2) +
          "," +
          Number(
            (m.price * this.context.taxDetails.taxPercentage) / 100
          ).toFixed(2) +
          "," +
          Number(
            m.price + (m.price * this.context.taxDetails.taxPercentage) / 100
          ).toFixed(2);
        rows += "\n";
      });

    var result = reportDetails + "\n\n" + headings + "\n" + rows;
    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download =
      "products-" +
      new Date().getDate() +
      (new Date().getMonth() + 1) +
      new Date().getFullYear() +
      "-" +
      new Date().getMinutes() +
      new Date().getSeconds() +
      ".csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  componentDidMount = () => {
    //this.TopSellingProductsReportSummary();
  };

  onStartDateChange = (date) => {
    this.setState({ startDate: date });
  };
  onEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  render() {
    return (
      <>
        <div>
          {window.innerWidth > 1200 ? (
            <>
              <tr>
                <td>
                  <InputGroup className="mb-1">
                    <InputGroup.Text id="basic-addon1">
                      Date Range
                    </InputGroup.Text>
                    <Form.Select
                      value={this.state.dateRange}
                      onChange={(e) => {
                        this.OnDateRangeChanged(e);
                      }}
                    >
                      <option value="Custom">Custom</option>
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Last 3 Days">Last 3 Days</option>
                      <option value="Last 7 Days">Last 7 Days</option>
                      <option value="This Month">This Month</option>
                      <option value="Last Month">Last Month</option>
                      <option value="Last 3 Months">Last 3 Months</option>
                      <option value="All">All</option>
                    </Form.Select>
                  </InputGroup>
                </td>
                {this.state.dateRange == "Custom" ? (
                  <>
                    <td>
                      StartDate:{" "}
                      <DateTimePicker
                        onChange={this.onStartDateChange}
                        value={this.state.startDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                    </td>
                    <td>
                      <Stack direction="horizontal">
                        EndDate:
                        <DateTimePicker
                          onChange={this.onEndDateChange}
                          value={this.state.endDate}
                          format="dd-MMM-yy hh:mm a"
                        />
                      </Stack>
                    </td>
                  </>
                ) : (
                  <></>
                )}
                <td>
                  <Button
                    onClick={(e) => this.TopSellingProductsReportSummary()}
                  >
                    Show Report
                  </Button>
                </td>
                <td className="">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {this.state.result.length > 0 ? (
                    <>
                      <Button
                        // className="mainthemecolor"
                        variant="danger"
                        onClick={() => this.DownloadToCSV()}
                      >
                        <BiDownload size="20"></BiDownload>
                        Download
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td>
                  <InputGroup className="mb-1">
                    <InputGroup.Text id="basic-addon1">
                      Date Range
                    </InputGroup.Text>
                    <Form.Select
                      value={this.state.dateRange}
                      onChange={(e) => {
                        this.OnDateRangeChanged(e);
                      }}
                    >
                      <option value="Custom">Custom</option>
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Last 3 Days">Last 3 Days</option>
                      <option value="Last 7 Days">Last 7 Days</option>
                      <option value="This Month">This Month</option>
                      <option value="Last Month">Last Month</option>
                      <option value="Last 3 Months">Last 3 Months</option>
                      <option value="All">All</option>
                    </Form.Select>
                  </InputGroup>
                  {this.state.dateRange == "Custom" ? (
                    <>
                      StartDate:{" "}
                      <DateTimePicker
                        onChange={this.onStartDateChange}
                        value={this.state.startDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                      EndDate:
                      <DateTimePicker
                        onChange={this.onEndDateChange}
                        value={this.state.endDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </td>

                <td>
                  <Button
                    onClick={(e) => this.TopSellingProductsReportSummary()}
                  >
                    Show Report
                  </Button>
                </td>
                <td className="">
                  {this.state.result.length > 0 ? (
                    <>
                      <Button
                        // className="mainthemecolor"
                        variant="danger"
                        onClick={() => this.DownloadToCSV()}
                      >
                        <BiDownload size="20"></BiDownload>
                        Download
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </>
          )}

          <Table
            bordered
            hover
            className="bg-white text-end"
            style={{
              tableLayout: "revert",
              verticalAlign: "middle",
              fontFamily: "monospace",
              fontSize: "14px",
            }}
          >
            <thead>
              <th className="text-center">No.</th>
              <th className="text-start">Product Name</th>
              <th className="text-start">Total Orders(Count)</th>
              <th>Tot.Qty</th>
              <th>
                Sales in Amount
                <br />[
                {this.state.result.length > 0 &&
                  this.state.result
                    .reduce((a, v) => (a = a + Number(v.price)), 0)
                    .toLocaleString(this.context.storeSettings.defaultLocale, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 1,
                      style: "currency",
                      currency: this.context.storeSettings.defaultCurrency,
                    })}
                ]
              </th>
              <th>
                Tax
                <br />[
                {this.state.result.length > 0 &&
                  this.state.result
                    .reduce(
                      (a, v) =>
                        (a =
                          a +
                          Number(
                            v.price * this.context.taxDetails.taxPercentage
                          ) /
                            100),
                      0
                    )
                    .toLocaleString(this.context.storeSettings.defaultLocale, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 1,
                      style: "currency",
                      currency: this.context.storeSettings.defaultCurrency,
                    })}
                ]
              </th>
              <th>
                Total
                <br />[
                {this.state.result.length > 0 &&
                  this.state.result
                    .reduce(
                      (a, v) =>
                        (a =
                          a +
                          v.price +
                          Number(
                            v.price * this.context.taxDetails.taxPercentage
                          ) /
                            100),
                      0
                    )
                    .toLocaleString(this.context.storeSettings.defaultLocale, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 1,
                      style: "currency",
                      currency: this.context.storeSettings.defaultCurrency,
                    })}
                ]
              </th>
            </thead>
            <tbody>
              {this.state.result.length > 0 &&
                this.state.result
                  .sort((a, b) => (a.count < b.count ? 1 : -1))
                  .map((m, i) => (
                    <>
                      <tr>
                        <td className="text-center">{i + 1}</td>
                        <td className="text-start">{m.name}</td>
                        <td className="text-start">{m.count}</td>
                        <td>{Number(m.totqty).toFixed(1)}</td>
                        <td>
                          {m.price.toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>
                          {(
                            (m.price * this.context.taxDetails.taxPercentage) /
                            100
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>
                          {(
                            m.price +
                            (m.price * this.context.taxDetails.taxPercentage) /
                              100
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                      </tr>
                    </>
                  ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}
export class TopPurchasingCustomers extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      dateRange: "Today",
      result: [],
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  OnDateRangeChanged = (e) => {
    const { name, value } = e.target;
    this.setState({ dateRange: value }, () => {
      this.setState({ result: [] }, () => {});
    });
  };

  TopPurchasingCustomersReportSummary = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/TopPurchasingCustomersReportSummary/" +
        this.context.storeId +
        "/" +
        this.state.dateRange +
        "/" +
        this.context.GetTicks(this.state.startDate) +
        "/" +
        this.context.GetTicks(this.state.endDate)
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ result: data });
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // alert("GetOrders fetch err:" + error);
      });
  };
  DownloadToCSV = () => {
    var headings =
      "#,Customer Name, Tot.Qty Purchased,Purchased Amount,Tax,Total";
    var reportDetails = "Top Purchasing Customers - Report\n";
    reportDetails += this.context.GetDateResult(
      this.state.dateRange,
      this.state.startDate,
      this.state.endDate
    );
    var rows = "";
    this.state.result
      .sort((a, b) => (a.price < b.price ? 1 : -1))
      .map((m, i) => {
        rows +=
          i +
          1 +
          "," +
          m.name +
          "," +
          Number(m.totqty).toFixed(2) +
          "," +
          Number(m.price).toFixed(2) +
          "," +
          Number(
            (m.price * this.context.taxDetails.taxPercentage) / 100
          ).toFixed(2) +
          "," +
          Number(
            m.price + (m.price * this.context.taxDetails.taxPercentage) / 100
          ).toFixed(2);
        rows += "\n";
      });

    var result = reportDetails + "\n\n" + headings + "\n" + rows;
    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download =
      "products-" +
      new Date().getDate() +
      (new Date().getMonth() + 1) +
      new Date().getFullYear() +
      "-" +
      new Date().getMinutes() +
      new Date().getSeconds() +
      ".csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  componentDidMount = () => {
    //this.TopSellingProductsReportSummary();
  };

  onStartDateChange = (date) => {
    this.setState({ startDate: date });
  };
  onEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  render() {
    return (
      <>
        <div>
          {window.innerWidth > 1200 ? (
            <>
              <tr>
                <td>
                  <InputGroup className="mb-1">
                    <InputGroup.Text id="basic-addon1">
                      Date Range
                    </InputGroup.Text>
                    <Form.Select
                      value={this.state.dateRange}
                      onChange={(e) => {
                        this.OnDateRangeChanged(e);
                      }}
                    >
                      <option value="Custom">Custom</option>
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Last 3 Days">Last 3 Days</option>
                      <option value="Last 7 Days">Last 7 Days</option>
                      <option value="This Month">This Month</option>
                      <option value="Last Month">Last Month</option>
                      <option value="Last 3 Months">Last 3 Months</option>
                      <option value="All">All</option>
                    </Form.Select>
                  </InputGroup>
                </td>
                {this.state.dateRange == "Custom" ? (
                  <>
                    <td>
                      StartDate:{" "}
                      <DateTimePicker
                        onChange={this.onStartDateChange}
                        value={this.state.startDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                    </td>
                    <td>
                      <Stack direction="horizontal">
                        EndDate:
                        <DateTimePicker
                          onChange={this.onEndDateChange}
                          value={this.state.endDate}
                          format="dd-MMM-yy hh:mm a"
                        />
                      </Stack>
                    </td>
                  </>
                ) : (
                  <></>
                )}
                <td>
                  <Button
                    onClick={(e) => this.TopPurchasingCustomersReportSummary()}
                  >
                    Show Report
                  </Button>
                </td>
                <td className="">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {this.state.result.length > 0 ? (
                    <>
                      <Button
                        // className="mainthemecolor"
                        variant="danger"
                        onClick={() => this.DownloadToCSV()}
                      >
                        <BiDownload size="20"></BiDownload>
                        Download
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td>
                  <InputGroup className="mb-1">
                    <InputGroup.Text id="basic-addon1">
                      Date Range
                    </InputGroup.Text>
                    <Form.Select
                      value={this.state.dateRange}
                      onChange={(e) => {
                        this.OnDateRangeChanged(e);
                      }}
                    >
                      <option value="Custom">Custom</option>
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Last 3 Days">Last 3 Days</option>
                      <option value="Last 7 Days">Last 7 Days</option>
                      <option value="This Month">This Month</option>
                      <option value="Last Month">Last Month</option>
                      <option value="Last 3 Months">Last 3 Months</option>
                      <option value="All">All</option>
                    </Form.Select>
                  </InputGroup>
                  {this.state.dateRange == "Custom" ? (
                    <>
                      StartDate:{" "}
                      <DateTimePicker
                        onChange={this.onStartDateChange}
                        value={this.state.startDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                      EndDate:
                      <DateTimePicker
                        onChange={this.onEndDateChange}
                        value={this.state.endDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </td>

                <td>
                  <Button
                    onClick={(e) => this.TopPurchasingCustomersReportSummary()}
                  >
                    Show Report
                  </Button>
                </td>
                <td className="">
                  {this.state.result.length > 0 ? (
                    <>
                      <Button
                        // className="mainthemecolor"
                        variant="danger"
                        onClick={() => this.DownloadToCSV()}
                      >
                        <BiDownload size="20"></BiDownload>
                        Download
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </>
          )}

          <Table
            bordered
            hover
            className="bg-white text-end"
            style={{
              tableLayout: "revert",
              verticalAlign: "middle",
              fontFamily: "monospace",
              fontSize: "14px",
            }}
          >
            <thead>
              <th className="text-center">No.</th>
              <th className="text-start">Customer Name</th>
              <th>Tot.Qty Purchased</th>
              <th>
                Purchased Amount
                <br />[
                {this.state.result.length > 0 &&
                  this.state.result
                    .reduce((a, v) => (a = a + Number(v.price)), 0)
                    .toLocaleString(this.context.storeSettings.defaultLocale, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 1,
                      style: "currency",
                      currency: this.context.storeSettings.defaultCurrency,
                    })}
                ]
              </th>
              <th>
                Tax
                <br />[
                {this.state.result.length > 0 &&
                  this.state.result
                    .reduce(
                      (a, v) =>
                        (a =
                          a +
                          Number(
                            v.price * this.context.taxDetails.taxPercentage
                          ) /
                            100),
                      0
                    )
                    .toLocaleString(this.context.storeSettings.defaultLocale, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 1,
                      style: "currency",
                      currency: this.context.storeSettings.defaultCurrency,
                    })}
                ]
              </th>
              <th>
                Total
                <br />[
                {this.state.result.length > 0 &&
                  this.state.result
                    .reduce(
                      (a, v) =>
                        (a =
                          a +
                          v.price +
                          Number(
                            v.price * this.context.taxDetails.taxPercentage
                          ) /
                            100),
                      0
                    )
                    .toLocaleString(this.context.storeSettings.defaultLocale, {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 1,
                      style: "currency",
                      currency: this.context.storeSettings.defaultCurrency,
                    })}
                ]
              </th>
            </thead>
            <tbody>
              {this.state.result.length > 0 &&
                this.state.result
                  .sort((a, b) => (a.price < b.price ? 1 : -1))
                  .map((m, i) => (
                    <>
                      <tr>
                        <td className="text-center">{i + 1}</td>
                        <td className="text-start">{m.name}</td>
                        <td>{Number(m.totqty).toFixed(1)}</td>
                        <td>
                          {m.price.toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>
                          {(
                            (m.price * this.context.taxDetails.taxPercentage) /
                            100
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>
                          {(
                            m.price +
                            (m.price * this.context.taxDetails.taxPercentage) /
                              100
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                      </tr>
                    </>
                  ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}
export class PurchaseByProductReport extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      dateRange: "Today",
      result: [],
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  OnDateRangeChanged = (e) => {
    const { name, value } = e.target;
    this.setState({ dateRange: value }, () => {
      this.setState({ result: [] }, () => {});
    });
  };

  GetPurchasesByProduct = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetPurchasesByProduct/" +
        this.context.storeId +
        "/" +
        this.state.dateRange +
        "/" +
        this.context.GetTicks(this.state.startDate) +
        "/" +
        this.context.GetTicks(this.state.endDate)
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ result: data });
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // alert("GetOrders fetch err:" + error);
      });
  };

  DownloadToCSV = () => {
    var headings = "#,Product Name, Tot.Qty, Purchases in Amount,Tax,Total";
    var reportDetails = "Purchase Summary by Products - Report\n";
    reportDetails += this.context.GetDateResult(
      this.state.dateRange,
      this.state.startDate,
      this.state.endDate
    );
    var rows = "";
    this.state.result
      .sort((a, b) => (a.totqty < b.totqty ? 1 : -1))
      .map((m, i) => {
        rows +=
          i +
          1 +
          "," +
          m.name +
          "," +
          Number(m.totqty).toFixed(2) +
          "," +
          Number(m.price).toFixed(2) +
          "," +
          Number((m.price * m.tax) / 100).toFixed(2) +
          "," +
          Number(m.price + (m.price * m.tax) / 100).toFixed(2);
        rows += "\n";
      });

    var result = reportDetails + "\n\n" + headings + "\n" + rows;
    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download =
      "products-" +
      new Date().getDate() +
      (new Date().getMonth() + 1) +
      new Date().getFullYear() +
      "-" +
      new Date().getMinutes() +
      new Date().getSeconds() +
      ".csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  onStartDateChange = (date) => {
    this.setState({ startDate: date });
  };
  onEndDateChange = (date) => {
    this.setState({ endDate: date });
  };
  componentDidMount = () => {
    //this.GetPurchasesByProduct();
  };

  render() {
    return (
      <>
        <div>
          {window.innerWidth > 1200 ? (
            <>
              <tr>
                <td>
                  <InputGroup className="mb-1">
                    <InputGroup.Text id="basic-addon1">
                      Date Range
                    </InputGroup.Text>
                    <Form.Select
                      value={this.state.dateRange}
                      onChange={(e) => {
                        this.OnDateRangeChanged(e);
                      }}
                    >
                      <option value="Custom">Custom</option>
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Last 3 Days">Last 3 Days</option>
                      <option value="Last 7 Days">Last 7 Days</option>
                      <option value="This Month">This Month</option>
                      <option value="Last Month">Last Month</option>
                      <option value="Last 3 Months">Last 3 Months</option>
                      <option value="All">All</option>
                    </Form.Select>
                  </InputGroup>
                </td>
                {this.state.dateRange == "Custom" ? (
                  <>
                    <td>
                      StartDate:{" "}
                      <DateTimePicker
                        onChange={this.onStartDateChange}
                        value={this.state.startDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                    </td>
                    <td>
                      <Stack direction="horizontal">
                        EndDate:
                        <DateTimePicker
                          onChange={this.onEndDateChange}
                          value={this.state.endDate}
                          format="dd-MMM-yy hh:mm a"
                        />
                      </Stack>
                    </td>
                  </>
                ) : (
                  <></>
                )}
                <td>
                  <Button onClick={(e) => this.GetPurchasesByProduct()}>
                    Show Report
                  </Button>
                </td>
                <td className="">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {this.state.result.length > 0 ? (
                    <>
                      <Button
                        // className="mainthemecolor"
                        variant="danger"
                        onClick={() => this.DownloadToCSV()}
                      >
                        <BiDownload size="20"></BiDownload>
                        Download
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td>
                  <InputGroup className="mb-1">
                    <InputGroup.Text id="basic-addon1">
                      Date Range
                    </InputGroup.Text>
                    <Form.Select
                      value={this.state.dateRange}
                      onChange={(e) => {
                        this.OnDateRangeChanged(e);
                      }}
                    >
                      <option value="Custom">Custom</option>
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Last 3 Days">Last 3 Days</option>
                      <option value="Last 7 Days">Last 7 Days</option>
                      <option value="This Month">This Month</option>
                      <option value="Last Month">Last Month</option>
                      <option value="Last 3 Months">Last 3 Months</option>
                      <option value="All">All</option>
                    </Form.Select>
                  </InputGroup>
                  {this.state.dateRange == "Custom" ? (
                    <>
                      StartDate:{" "}
                      <DateTimePicker
                        onChange={this.onStartDateChange}
                        value={this.state.startDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                      EndDate:
                      <DateTimePicker
                        onChange={this.onEndDateChange}
                        value={this.state.endDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </td>

                <td>
                  <Button onClick={(e) => this.GetPurchasesByProduct()}>
                    Show Report
                  </Button>
                </td>
                <td className="">
                  {this.state.result.length > 0 ? (
                    <>
                      <Button
                        // className="mainthemecolor"
                        variant="danger"
                        onClick={() => this.DownloadToCSV()}
                      >
                        <BiDownload size="20"></BiDownload>
                        Download
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </>
          )}
          <Table
            bordered
            hover
            className="bg-white text-end"
            style={{
              tableLayout: "revert",
              verticalAlign: "middle",
              fontFamily: "monospace",
              fontSize: "14px",
            }}
          >
            <thead>
              <th className="text-center">No.</th>
              <th className="text-start">Product Name</th>
              <th>Tot.Qty</th>
              <th>
                Purchases in Amount
                <br />[
                {this.state.result
                  .reduce((a, v) => (a = a + Number(v.price)), 0)
                  .toLocaleString(this.context.storeSettings.defaultLocale, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1,
                    style: "currency",
                    currency: this.context.storeSettings.defaultCurrency,
                  })}
                ]
              </th>
              <th>
                Tax Amount
                <br />[
                {this.state.result
                  .reduce((a, v) => (a = a + Number(v.price * v.tax) / 100), 0)
                  .toLocaleString(this.context.storeSettings.defaultLocale, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1,
                    style: "currency",
                    currency: this.context.storeSettings.defaultCurrency,
                  })}
                ]
              </th>
              <th>
                Total
                <br />[
                {this.state.result
                  .reduce(
                    (a, v) => (a = a + v.price + Number(v.price * v.tax) / 100),
                    0
                  )
                  .toLocaleString(this.context.storeSettings.defaultLocale, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1,
                    style: "currency",
                    currency: this.context.storeSettings.defaultCurrency,
                  })}
                ]
              </th>
            </thead>
            <tbody>
              {this.state.result
                .sort((a, b) => (a.totqty < b.totqty ? 1 : -1))
                .map((m, i) => (
                  <>
                    <tr>
                      <td className="text-center">{i + 1}</td>
                      <td className="text-start">{m.name}</td>
                      <td>{Number(m.totqty).toFixed(1)}</td>
                      <td>
                        {m.price.toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </td>
                      <td>
                        {((m.price * m.tax) / 100).toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </td>
                      <td>
                        {(m.price + (m.price * m.tax) / 100).toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}

export class PurchaseSummaryReport extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      dateRange: "Today",
      result: [],
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  OnDateRangeChanged = (e) => {
    const { name, value } = e.target;
    this.setState({ dateRange: value }, () => {
      this.setState({ result: [] }, () => {});
    });
  };

  GetPurchaseSummaryReport = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetPurchaseSummaryReport/" +
        this.context.storeId +
        "/" +
        this.state.dateRange +
        "/" +
        this.context.GetTicks(this.state.startDate) +
        "/" +
        this.context.GetTicks(this.state.endDate)
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ result: data });
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // alert("GetOrders fetch err:" + error);
      });
  };

  DownloadToCSV = () => {
    var headings =
      "#,CreatedOn, InvoiceNo,Tot.Purchase Amount,Tot.Purchsed Items ";
    var reportDetails = "Products Purchase Orders(PO) - Summary  - Report\n";
    reportDetails += this.context.GetDateResult(
      this.state.dateRange,
      this.state.startDate,
      this.state.endDate
    );
    var rows = "";
    this.state.result
      .sort((a, b) => (a.created < b.created ? 1 : -1))
      .map((m, i) => {
        rows +=
          i +
          1 +
          "," +
          this.context.GetInDateFormat(new Date(m.created)) +
          "," +
          m.invoiceNo +
          "," +
          Number(m.totAmount).toFixed(2) +
          "," +
          m.totQty;
        rows += "\n";
      });

    var result = reportDetails + "\n\n" + headings + "\n" + rows;
    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download =
      "products-" +
      new Date().getDate() +
      (new Date().getMonth() + 1) +
      new Date().getFullYear() +
      "-" +
      new Date().getMinutes() +
      new Date().getSeconds() +
      ".csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  onStartDateChange = (date) => {
    this.setState({ startDate: date });
  };
  onEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  componentDidMount = () => {
    //this.GetPurchasesByProduct();
  };

  render() {
    return (
      <>
        <div>
          {window.innerWidth > 1200 ? (
            <>
              <tr>
                <td>
                  <InputGroup className="mb-1">
                    <InputGroup.Text id="basic-addon1">
                      Date Range
                    </InputGroup.Text>
                    <Form.Select
                      value={this.state.dateRange}
                      onChange={(e) => {
                        this.OnDateRangeChanged(e);
                      }}
                    >
                      <option value="Custom">Custom</option>
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Last 3 Days">Last 3 Days</option>
                      <option value="Last 7 Days">Last 7 Days</option>
                      <option value="This Month">This Month</option>
                      <option value="Last Month">Last Month</option>
                      <option value="Last 3 Months">Last 3 Months</option>
                      <option value="All">All</option>
                    </Form.Select>
                  </InputGroup>
                </td>
                {this.state.dateRange == "Custom" ? (
                  <>
                    <td>
                      StartDate:{" "}
                      <DateTimePicker
                        onChange={this.onStartDateChange}
                        value={this.state.startDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                    </td>
                    <td>
                      <Stack direction="horizontal">
                        EndDate:
                        <DateTimePicker
                          onChange={this.onEndDateChange}
                          value={this.state.endDate}
                          format="dd-MMM-yy hh:mm a"
                        />
                      </Stack>
                    </td>
                  </>
                ) : (
                  <></>
                )}
                <td>
                  <Button onClick={(e) => this.GetPurchaseSummaryReport()}>
                    Show Report
                  </Button>
                </td>
                <td className="">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {this.state.result.length > 0 ? (
                    <>
                      <Button
                        // className="mainthemecolor"
                        variant="danger"
                        onClick={() => this.DownloadToCSV()}
                      >
                        <BiDownload size="20"></BiDownload>
                        Download
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td>
                  <InputGroup className="mb-1">
                    <InputGroup.Text id="basic-addon1">
                      Date Range
                    </InputGroup.Text>
                    <Form.Select
                      value={this.state.dateRange}
                      onChange={(e) => {
                        this.OnDateRangeChanged(e);
                      }}
                    >
                      <option value="Custom">Custom</option>
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Last 3 Days">Last 3 Days</option>
                      <option value="Last 7 Days">Last 7 Days</option>
                      <option value="This Month">This Month</option>
                      <option value="Last Month">Last Month</option>
                      <option value="Last 3 Months">Last 3 Months</option>
                      <option value="All">All</option>
                    </Form.Select>
                  </InputGroup>
                  {this.state.dateRange == "Custom" ? (
                    <>
                      StartDate:{" "}
                      <DateTimePicker
                        onChange={this.onStartDateChange}
                        value={this.state.startDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                      EndDate:
                      <DateTimePicker
                        onChange={this.onEndDateChange}
                        value={this.state.endDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </td>

                <td>
                  <Button onClick={(e) => this.GetPurchaseSummaryReport()}>
                    Show Report
                  </Button>
                </td>
                <td className="">
                  {this.state.result.length > 0 ? (
                    <>
                      <Button
                        // className="mainthemecolor"
                        variant="danger"
                        onClick={() => this.DownloadToCSV()}
                      >
                        <BiDownload size="20"></BiDownload>
                        Download
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </>
          )}
          <Table
            bordered
            hover
            className="bg-white text-end"
            style={{
              tableLayout: "revert",
              verticalAlign: "middle",
              fontFamily: "monospace",
              fontSize: "14px",
            }}
          >
            <thead>
              <th className="text-center">No.</th>
              <th className="text-start">CreatedOn</th>
              <th className="text-start">InvoiceNo</th>
              <th>
                Tot.Purchase Amount
                <br />[
                {this.state.result
                  .reduce((a, v) => (a = a + Number(v.totAmount)), 0)
                  .toLocaleString(this.context.storeSettings.defaultLocale, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1,
                    style: "currency",
                    currency: this.context.storeSettings.defaultCurrency,
                  })}
                ]
              </th>
              <th>
                Tot.Purchsed Items
                <br />[
                {this.state.result.reduce(
                  (a, v) => (a = a + Number(v.totQty)),
                  0
                )}
                ]
              </th>
            </thead>
            <tbody>
              {this.state.result
                //.sort((a, b) => (a.totqty < b.totqty ? 1 : -1))
                .map((m, i) => (
                  <>
                    <tr>
                      <td className="text-center">{i + 1}</td>
                      <td className="text-start">
                        {this.context.GetInDateFormat(new Date(m.created))}
                      </td>
                      <td className="text-start">{m.invoiceNo}</td>
                      <td>
                        {m.totAmount.toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </td>
                      <td>{Number(m.totQty).toFixed(1)}</td>
                      {/* <td>
                        {m.price.toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </td>
                      <td>{(m.price * m.tax) / 100}</td>
                      <td>
                        {(m.price + (m.price * m.tax) / 100).toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </td> */}
                    </tr>
                  </>
                ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}
