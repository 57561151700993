import React, { Component, useRef, useState } from "react";
import Barcode from "react-barcode";
import CartContext from "./CartContext";

import {
  Form,
  Table,
  FloatingLabel,
  Button,
  Row,
  Col,
  Card,
  Stack,
} from "react-bootstrap";
import { SideMenu } from "./SideMenu";
import ReactToPrint from "react-to-print";

// export class BarCodeCustom extends Component {
//   static contextType = CartContext;

//   constructor(props) {
//     super(props);
//     this.state = {
//       barcode: "",
//       totalcount: 10,
//       arr: [],
//     };
//     useRef myref="";
//   }

const pageStyle = `
    @page {size:30mm 20mm};
    @media all {
    .pageBreak{display:none}
  };
  @media print{
  .pageBreak{ page-Break-before:always;}
  }
    }
    `;

export function BarCodeCustom(props) {
  const [barCode, SetBarCode] = useState("");
  const [label, SetLabel] = useState("");
  const [totalcount, SetTotalCount] = useState(10);
  const [arr, SetArr] = useState([]);
  const [labelFontSize, SetLabelFontSize] = useState("12");
  const [barCodeHeight, SetBarCodeHeight] = useState("40");
  const [barCodeWidth, SetBarCodeWidth] = useState("1");
  const myref = useRef();

  const Generate = () => {
    // var barcode = getBarcode();
    if (barCode == "") {
      alert("Barcode is empty!");
      return;
    }
    var tarr = [];
    for (let i = 1; i <= totalcount; i++) {
      tarr.push({ barcode: barCode, label: label });
    }
    SetArr(tarr);
  };
  // const Print = () => {
  //   var printContents = document.getElementById("divcontents").innerHTML;
  //   //var originalContents = document.body.innerHTML;
  //   //document.body.innerHTML = printContents;
  //   // window.print();
  //   //document.body.innerHTML = originalContents;

  //   var printWindow = window.open("", "_blank");
  //   printWindow.document.write(printContents);
  //   printWindow.document.close();
  //   this.onPrintFinished(printWindow.print(), printWindow);
  // };

  const onPrintFinished = (status, printWindow) => {
    var osvar = this.getOS();
    if (osvar == "Windows") printWindow.close();
  };
  const getOS = () => {
    var uA = navigator.userAgent || navigator.vendor || window.opera;
    if (
      (/iPad|iPhone|iPod/.test(uA) && !window.MSStream) ||
      (uA.includes("Mac") && "ontouchend" in document)
    )
      return "iOS";

    var i,
      os = ["Windows", "Android", "Unix", "Mac", "Linux", "BlackBerry"];
    for (i = 0; i < os.length; i++)
      if (new RegExp(os[i], "i").test(uA)) return os[i];
  };

  const getBarcode = () => {
    var currentTime = new Date();
    var currentOffset = currentTime.getTimezoneOffset();
    var ISTOffset = 330; // IST offset UTC +5:30
    var ISTTime = new Date(
      currentTime.getTime() + (ISTOffset + currentOffset) * 60000
    );

    var code =
      ISTTime.getFullYear() +
      "" +
      (ISTTime.getMonth() + 1) +
      "" +
      ISTTime.getDate() +
      "" +
      ISTTime.getMinutes() +
      "" +
      ISTTime.getSeconds() +
      "" +
      ISTTime.getMilliseconds();
    // this.setState({ barcode: code });
    return code;
  };

  return (
    <>
      <Row>
        <Col className=" " xs={2}>
          <SideMenu></SideMenu>
        </Col>
        <Col xs={10} className="my-3 text-center">
          <h2 className="bg-light">Generate or Re-Generate Barcodes </h2>
          <Row className="border-0 w-100">
            <Col md={1} className=" verticalaligncentertd">
              <Form.Control
                type="text"
                name="name1"
                size="lg"
                value={totalcount}
                required
                placeholder="Enter Total Barcodes required"
                autoComplete="off"
                onChange={(e) => SetTotalCount(e.target.value)}
              />
            </Col>
            <Col md={6} className="verticalaligncentertd">
              <Row className=" w-100">
                <Col md={8}>
                  <Form.Control
                    type="text"
                    name="name1"
                    className="w-100 verticalaligncentertd"
                    size="lg"
                    value={barCode}
                    required
                    placeholder="Enter Barcode"
                    autoComplete="off"
                    onChange={(e) => SetBarCode(e.target.value)}
                  />
                  <Form.Control
                    type="text"
                    name="label"
                    className="w-100 verticalaligncentertd"
                    size="lg"
                    value={label}
                    required
                    placeholder="Enter Label"
                    autoComplete="off"
                    onChange={(e) => SetLabel(e.target.value)}
                  />
                </Col>
                <Col md={4}>
                  <Button
                    size="lg"
                    variant="primary"
                    className="w-100 verticalaligncentertd"
                    onClick={() => SetBarCode(getBarcode())}
                  >
                    Get New Barcode
                  </Button>
                  <Button
                    className=""
                    size="lg"
                    variant="warning"
                    onClick={Generate}
                  >
                    GENERATE BARCODES
                  </Button>
                </Col>
              </Row>
            </Col>
            <Col md={3} className="text-center verticalaligncentertd">
              <FloatingLabel
                controlId="floatingInput"
                label="Label FontSize"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="label"
                  className="w-100 verticalaligncentertd"
                  size="lg"
                  value={labelFontSize}
                  required
                  placeholder="Label FontSize"
                  autoComplete="off"
                  onChange={(e) => SetLabelFontSize(e.target.value)}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Barcode Height"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="label"
                  className="w-100 verticalaligncentertd"
                  size="lg"
                  value={barCodeHeight}
                  required
                  placeholder="Barcode Height"
                  autoComplete="off"
                  onChange={(e) => SetBarCodeHeight(e.target.value)}
                />
              </FloatingLabel>
              <FloatingLabel
                controlId="floatingInput"
                label="Barcode Width"
                className="mb-3"
              >
                <Form.Control
                  type="text"
                  name="label"
                  className="w-100 verticalaligncentertd"
                  size="lg"
                  value={barCodeWidth}
                  required
                  placeholder="Barcode Width"
                  autoComplete="off"
                  onChange={(e) => SetBarCodeWidth(e.target.value)}
                />
              </FloatingLabel>
            </Col>
            <Col md={2} className="text-center verticalaligncentertd">
              <ReactToPrint
                trigger={() => (
                  <Button size="lg" variant="danger" className="w-100">
                    PRINT
                  </Button>
                )}
                content={() => myref.current}
                pageStyle={pageStyle}
              />
            </Col>
          </Row>
          <Row>
            <div id="divcontents" ref={myref}>
              <Row>
                {arr.map((m, i) => (
                  <Col className="text-center">
                    <Barcode
                      key={i}
                      value={m.barcode}
                      displayValue={false}
                      width={barCodeWidth}
                      height={barCodeHeight}
                    />
                    <br />
                    <span style={{ fontSize: labelFontSize + "px" }}>
                      {m.label}
                    </span>
                  </Col>
                ))}
              </Row>
            </div>
          </Row>
        </Col>
      </Row>
    </>
  );
}
