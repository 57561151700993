import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";

import {
  OverlayTrigger,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export class ClientTestimonial extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      clientTestimonials: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      firstLoad: false,
    };
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDeleted = () => {
    this.OnRefreshButtonClick();
  };
  OnSaved = () => {
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.setState({ clientTestimonials: [] }, () => {
      this.GetClientTestimonials();
    });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  handleAddClientTestimonial = (e) => {
    this.setState({
      clientTestimonials: [
        ...this.state.clientTestimonials,
        {
          storeId: this.context.storeId,
          imgFileName: "",
          seqNo: this.state.clientTestimonials.length + 1,
        },
      ],
    });
    alert("An empty row added at the end of list below!");
  };

  handleInputChange = (e, i) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "customerName":
        this.state.clientTestimonials[i].customerName = value;
        break;
      case "imgFileName":
        this.state.clientTestimonials[i].imgFileName = value;
        break;
      case "totalStars":
        this.state.clientTestimonials[i].totalStars = value;
        break;
      case "feedback":
        this.state.clientTestimonials[i].feedback = value;
        break;
      case "seqNo":
        this.state.clientTestimonials[i].seqNo = value;
        break;
    }
    this.setState({ clientTestimonials: this.state.clientTestimonials });
  };

  OnSave = (e) => {
    this.ShowLoadSpinner();

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.clientTestimonials),
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/AddOrUpdateClientTestimonial/" +
        this.context.storeId,
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.OnRefreshButtonClick();
          this.HideLoadSpinner();
          this.ShowMessagBox("Information", "Saved Successfully!");
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed!");
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed! " + error);
      });
  };

  OnDelete = (i) => {
    if (this.state.clientTestimonials[i].id == undefined) {
      this.ShowMessagBox(
        "Information",
        "This is invalid row which can't be deleted. Press Refresh!"
      );
      return;
    }

    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteClientTestimonial/" +
        this.state.clientTestimonials[i].id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert("not deleted");
          return;
        }
        this.OnRefreshButtonClick();
        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Status",
          "Deleted Successfully. Click Refresh Button to see the changes!"
        );
        this.OnDeleted();
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("OnDelete Failed" + error);
      });
  };

  GetClientTestimonials = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetClientTestimonials/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({
            clientTestimonials: data.sort((a, b) =>
              a.seqNo > b.seqNo ? 1 : -1
            ),
          });
        }
      })
      .catch((error) => {
        // alert("GetMainMenus:: " + error);
      });
  };

  componentDidMount = () => {
    if (this.state.firstLoad == false) {
      this.state.firstLoad = true;
      this.GetClientTestimonials();
    }
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <>
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <div className="Container-fluid p-1 ">
          <Row>
            <Col className="" xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="pagebackground p-0">
              {this.state.msgboxOpen === true ? (
                <MessageBox
                  open={this.state.msgboxOpen}
                  title={this.state.msgboxTitle}
                  message={this.state.msgboxMessage}
                  callback={this.CloseMessagBoxCallback}
                />
              ) : (
                ""
              )}
              <Navbar
                expand="lg"
                className="secondarythemecolor position-sticky fixed-top  px-3"
              >
                <h4> &nbsp; &nbsp;Manage Testimonials &nbsp;</h4>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemecolor"
                        onClick={() => this.handleAddClientTestimonial()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={(e) => {
                          this.OnSave(e);
                        }}
                      >
                        <BiSave size="26" />
                        Save
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Refresh
                      </Button>
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div>
                <Table border striped className="px-0 bg-white ">
                  <thead className="">
                    <tr className="text-center text-black bg-white border">
                      {/* <th width="20%">Menu Name</th> */}
                      <th width="5%"></th>
                      <th width="20%">Customer Name</th>
                      <th width="15%">Customer Image File</th>
                      <th width="10%">Ratings (Total Stars)</th>
                      <th width="40%">Feedback</th>
                      <th width="5%">Sequence No</th>
                      <th width="5%" className="text-center">
                        Options
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-primary border">
                    {this.state.clientTestimonials.map(
                      (clientTestimonial, i) => (
                        <>
                          <tr className="text-start">
                            <td>{i + 1}</td>
                            <td className="text-start">
                              <Form.Control
                                type="text"
                                name="customerName"
                                min={0}
                                value={clientTestimonial.customerName}
                                required
                                placeholder=""
                                align="center"
                                className="text-start  "
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e, i)}
                              />
                            </td>
                            <td className="text-start">
                              <Form.Select
                                type="text"
                                name="imgFileName"
                                value={clientTestimonial.imgFileName}
                                required
                                placeholder=""
                                autoComplete="off"
                                className="text-start"
                                onChange={(e) => this.handleInputChange(e, i)}
                              >
                                <option value=""></option>
                                {this.context.images.map((r) => (
                                  <option value={r.name}>{r.name}</option>
                                ))}
                              </Form.Select>
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                name="totalStars"
                                min={0}
                                value={clientTestimonial.totalStars}
                                required
                                placeholder=""
                                align="center"
                                className="text-center  "
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e, i)}
                              />
                            </td>
                            <td>
                              <Form.Control
                                as="textarea"
                                type="textarea"
                                rows={5}
                                name="feedback"
                                value={clientTestimonial.feedback}
                                required
                                maxLength={511}
                                placeholder=""
                                align="center"
                                className="text-start  "
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e, i)}
                              />
                            </td>
                            <td>
                              <Form.Control
                                type="number"
                                name="seqNo"
                                min={0}
                                value={clientTestimonial.seqNo}
                                required
                                placeholder=""
                                align="center"
                                className="text-center  "
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e, i)}
                              />
                            </td>
                            <td className="text-center align-middle">
                              <Button
                                className="mx-1 mainthemecolor"
                                onClick={(e) => {
                                  if (
                                    window.confirm(
                                      "Warning! This action will delete this Carousel Row and associated Products as well. Are you sure you want to Delete?"
                                    )
                                  ) {
                                    this.OnDelete(i);
                                  }
                                }}
                              >
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="25"
                                  height="25"
                                  fill="currentColor"
                                  class="bi bi-trash"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                  <path
                                    fill-rule="evenodd"
                                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                  />
                                </svg>
                              </Button>
                            </td>
                          </tr>
                        </>
                      )
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
