import React, {
  Component,
  useEffect,
  useRef,
  useState,
  useContext,
} from "react";
import CartContext from "./CartContext";
import { Navigate } from "react-router-dom";
import axios from "axios";
import Swal from "sweetalert2";
import { SideMenu } from "./SideMenu";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";

import {
  Table,
  Modal,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  ButtonToolbar,
  Form,
} from "react-bootstrap";

const WAIT_INTERVAL = 500;

export default class TableOrdersQueue extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      showProductListByCategory: false,
      updateCartView: "true",
      havePermissions: false,
      ordersDashboard: [],
      selectedTableId: "",
      showGroupView: false,
      orderStatusFilterId: "",
      filteredTableName: "",
      filteredItemName: "",
      filteredProdList: [],
    };
  }

  OnOrderStatusFilterChanged = (e) => {
    this.setState({ orderStatusFilterId: e.target.value });
  };
  OnfilteredTableNameChanged = (e) => {
    this.setState({ filteredTableName: e.target.value });
  };
  OnfilteredItemNameChanged = (e) => {
    this.setState({ filteredItemName: e.target.value });
  };

  ShowGroupView = () => {
    this.setState({ showGroupView: true });
  };
  HideGroupView = () => {
    this.setState({ showGroupView: false });
  };

  GetRestaurantTableOrderedItemsDashboard = (tableId) => {
    this.setState({ ordersDashboard: [] }, () => {
      fetch(
        process.env.REACT_APP_API +
          "Consumers/GetRestaurantTableOrderedItemsDashboard/" +
          this.context.storeId
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 400) {
          } else {
            this.setState({ ordersDashboard: data }, () => {
              this.GetProductNames();
            });
          }
        });
    });
  };
  OnOrderStatusChange = (e, cartItem) => {
    cartItem.orderStatusId = e.target.value;
    this.UpdateRestaurantTableOrderItem(cartItem);
  };

  UpdateRestaurantTableOrderItem = (item) => {
    axios
      .post(
        process.env.REACT_APP_API +
          "Consumers/UpdateRestaurantTableOrderedItemStatus/" +
          item.id +
          "/" +
          item.orderStatusId,
        item
      )
      .then((response) => {
        if (response.status === 200) {
          this.Refresh();
        } else {
          alert("UpdateRestaurantTableOrderItem Failed. Please try again!");
        }
      })
      .catch((error) => {
        console.error(
          "UpdateRestaurantTableOrderItem:There was an error!",
          error
        );
      });
  };

  GetProductNames = () => {
    var prodlist = [
      ...new Set(
        this.state.ordersDashboard
          .filter((f) => f.orderStatusId == 1 || f.orderStatusId == 2)
          .map((q) => q.productName)
      ),
    ];
    this.setState({ filteredProdList: prodlist });
  };

  Refresh = () => {
    this.setState({ ordersDashboard: [] }, () => {
      this.GetRestaurantTableOrderedItemsDashboard();
    });
  };

  componentDidMount = () => {
    this.GetRestaurantTableOrderedItemsDashboard();
  };

  GetDateDifference = (createddate) => {
    // var diff = Math.abs(new Date().toLocaleString() - createddate);
    var startTime = new Date(new Date().toLocaleString());
    var endTime = new Date(createddate.toLocaleString());
    var diff = startTime - endTime;
    // var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
    var minutes = Math.floor(diff / 1000 / 60);
    return minutes + 1;
  };

  componentDidUpdate = () => {};

  render() {
    if (this.state.selectedTableId != "") return <Navigate to="/TableOrder" />;
    return (
      <>
        {this.state.showGroupView == true ? (
          <>
            <TableOrderGroupView
              onHide={this.HideGroupView}
            ></TableOrderGroupView>
          </>
        ) : (
          ""
        )}

        {this.props.KdsView == true ? (
          <>
            <Row>
              <Col xs={12} className="bg-white p-1 ">
                <Navbar className="secondarythemecolor position-sticky fixed-top px-1">
                  <Navbar.Collapse className="justify-content-start mx-1 ">
                    <h5>Table Orders </h5>
                  </Navbar.Collapse>
                  <Navbar.Collapse className="justify-content-end text-end mx-1 ">
                    <Nav>
                      <ButtonToolbar className="text-end">
                        &nbsp;
                        <Button
                          className="mainthemecolor"
                          onClick={() => this.ShowGroupView()}
                        >
                          GroupByProduct
                        </Button>
                        <Button
                          className="mainthemecolor"
                          onClick={() => this.Refresh()}
                        >
                          <BiRefresh size="16"></BiRefresh>
                          Refresh
                        </Button>
                        &nbsp;
                      </ButtonToolbar>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
                <div className="p-2">
                  <Row className="ordertablecustom">
                    <Col className="text-center">
                      Filter by Table
                      <Form.Select
                        value={this.state.filteredTableName}
                        size=""
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "white",
                        }}
                        required
                        onChange={(e) => this.OnfilteredTableNameChanged(e)}
                      >
                        <option value=""> </option>
                        {this.context.returantTables.map((sta) => (
                          <option value={sta.name}> {sta.name}</option>
                        ))}
                      </Form.Select>
                    </Col>
                    <Col className="text-center">
                      Filter by Product
                      <Form.Select
                        value={this.state.filteredItemName}
                        size=""
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "white",
                        }}
                        required
                        onChange={(e) => this.OnfilteredItemNameChanged(e)}
                      >
                        <option value=""> </option>
                        {this.state.filteredProdList.map((sta) => (
                          <option value={sta}> {sta}</option>
                        ))}
                      </Form.Select>
                    </Col>

                    <Col>
                      Filter by Status
                      <Form.Select
                        value={this.state.orderStatusFilterId}
                        size=""
                        style={{
                          fontWeight: "bold",
                          backgroundColor: "white",
                        }}
                        required
                        onChange={(e) => this.OnOrderStatusFilterChanged(e)}
                      >
                        <option value=""> </option>
                        <option value="1">Received</option>
                        <option value="2">Preparing</option>
                        {/* {this.context.restaurantTableOrdereStatus.map(
                            (sta) => (
                              <option value={sta.id}> {sta.orderStatus}</option>
                            )
                          )} */}
                      </Form.Select>
                    </Col>
                  </Row>
                </div>
                <div
                  className="fixedTableHightOrder"
                  style={{ height: window.innerHeight - 100 + "px" }}
                >
                  <Table hover className="bg-white ordertablecustom" size="sm">
                    <thead>
                      <tr className="text-center bg-secondary text-white ordertablecustom ordertable m-0 p-0 border-2 border-primary">
                        <th width="5%"></th>
                        <th width="10%">Table</th>
                        <th width="35%">Prod.Name</th>
                        <th width="5%">Qty</th>
                        <th width="30%">Status</th>
                        <th width="15%">Minutes</th>
                      </tr>
                    </thead>
                    <tbody className="text-center">
                      {this.state.ordersDashboard
                        .sort((a, b) =>
                          new Date(a.created) > new Date(b.created) ? 1 : -1
                        )
                        .filter(
                          (f) => f.orderStatusId == 1 || f.orderStatusId == 2
                        )
                        .filter((f) => {
                          if (
                            this.state.filteredItemName != "" &&
                            f.productName == this.state.filteredItemName
                          )
                            return true;
                          if (this.state.filteredItemName == "") return true;
                        })
                        .filter((f) => {
                          if (
                            this.state.filteredTableName != "" &&
                            f.name == this.state.filteredTableName
                          )
                            return true;
                          if (this.state.filteredTableName == "") return true;
                        })
                        .filter((f) => {
                          if (
                            this.state.orderStatusFilterId != "" &&
                            f.orderStatusId == this.state.orderStatusFilterId
                          ) {
                            return true;
                          }
                          if (this.state.orderStatusFilterId == "") return true;
                        })
                        .map((order, i) => (
                          <>
                            <tr>
                              <td>{i + 1}</td>
                              <td>{order.name}</td>
                              <td className="text-center">
                                {order.productName}
                              </td>
                              <td>
                                <b>{order.qty}</b>
                              </td>
                              <td>
                                {/* {
                              this.context.GetRestaurantTableOrdereStatusById(
                                order.orderStatusId
                              ).orderStatus
                            } */}
                                <Form.Select
                                  value={order.orderStatusId}
                                  size="sm"
                                  style={{
                                    fontWeight: "bold",
                                    backgroundColor: "yellow",
                                  }}
                                  required
                                  onChange={(e) =>
                                    this.OnOrderStatusChange(e, order)
                                  }
                                >
                                  <option value={1}>Received</option>
                                  <option value={2}>Preparing</option>
                                  <option value={3}>Ready</option>
                                  {/* {this.context.restaurantTableOrdereStatus.map(
                                (sta) => (
                                  <option value={sta.id}>
                                    {" "}
                                    {sta.orderStatus}
                                  </option>
                                )
                              )} */}
                                </Form.Select>
                              </td>
                              <td>
                                {this.GetDateDifference(order.created)} mins
                              </td>
                            </tr>
                          </>
                        ))}
                    </tbody>
                  </Table>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          <>
            <Row>
              {window.innerWidth >= 1200 ? (
                <>
                  <Col className="pagebackground " xs={2}>
                    <SideMenu></SideMenu>
                  </Col>
                </>
              ) : (
                ""
              )}
              <Col
                xs={
                  window.innerWidth >= 1200
                    ? this.props.KdsView != true
                      ? 10
                      : 12
                    : 10
                }
                className="bg-white p-1 my-2"
              >
                <Navbar className="secondarythemecolor position-sticky fixed-top px-1">
                  <Navbar.Collapse className="justify-content-start mx-1 ">
                    <h5>Table Orders Queue &nbsp;</h5>
                  </Navbar.Collapse>
                  <Navbar.Collapse className="justify-content-end text-end mx-1 ">
                    <Nav>
                      <ButtonToolbar className="text-end">
                        &nbsp;
                        <Button
                          className="mainthemecolor"
                          onClick={() => this.ShowGroupView()}
                        >
                          {/* <BiRefresh size="26"></BiRefresh> */}
                          GroupByProduct
                        </Button>
                        <Button
                          className="mainthemecolor"
                          onClick={() => this.Refresh()}
                        >
                          {/* <BiRefresh size="26"></BiRefresh> */}
                          Refresh
                        </Button>
                        &nbsp;
                      </ButtonToolbar>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
                <div className="bg-primary text-white m-0 p-0"></div>

                <div>
                  {window.innerWidth >= 1200 ? (
                    <>
                      <div className="p-2">
                        <Row>
                          <Col className="text-end">Filter by Table</Col>
                          <Col className="text-center">
                            <Form.Select
                              value={this.state.filteredTableName}
                              size=""
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "white",
                              }}
                              required
                              onChange={(e) =>
                                this.OnfilteredTableNameChanged(e)
                              }
                            >
                              <option value=""> </option>
                              {this.context.returantTables.map((sta) => (
                                <option value={sta.name}> {sta.name}</option>
                              ))}
                            </Form.Select>
                          </Col>
                          <Col className="text-end">Filter by Product</Col>
                          <Col className="text-center">
                            <Form.Select
                              value={this.state.filteredItemName}
                              size=""
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "white",
                              }}
                              required
                              onChange={(e) =>
                                this.OnfilteredItemNameChanged(e)
                              }
                            >
                              <option value=""> </option>
                              {this.state.filteredProdList.map((sta) => (
                                <option value={sta}> {sta}</option>
                              ))}
                            </Form.Select>
                          </Col>
                          <Col className="text-end">Filter by Status</Col>
                          <Col>
                            <Form.Select
                              value={this.state.orderStatusFilterId}
                              size=""
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "white",
                              }}
                              required
                              onChange={(e) =>
                                this.OnOrderStatusFilterChanged(e)
                              }
                            >
                              <option value=""> </option>
                              <option value="1">Received</option>
                              <option value="2">Preparing</option>
                              {/* {this.context.restaurantTableOrdereStatus.map(
                            (sta) => (
                              <option value={sta.id}> {sta.orderStatus}</option>
                            )
                          )} */}
                            </Form.Select>
                          </Col>
                        </Row>
                      </div>
                      <Table className="border text-center">
                        <thead>
                          <th width="5%"></th>
                          <th width="10%">Table</th>
                          <th width="20%">Prod.Name</th>
                          <th width="10%">Qty</th>
                          <th width="20%">Status</th>
                          <th width="20%">Received ago</th>
                          <th width="25%">Received On</th>
                        </thead>
                        <tbody>
                          {this.state.ordersDashboard
                            .sort((a, b) =>
                              new Date(a.created) > new Date(b.created) ? 1 : -1
                            )
                            .filter(
                              (f) =>
                                f.orderStatusId == 1 || f.orderStatusId == 2
                            )
                            .filter((f) => {
                              if (
                                this.state.filteredItemName != "" &&
                                f.productName == this.state.filteredItemName
                              )
                                return true;
                              if (this.state.filteredItemName == "")
                                return true;
                            })
                            .filter((f) => {
                              if (
                                this.state.filteredTableName != "" &&
                                f.name == this.state.filteredTableName
                              )
                                return true;
                              if (this.state.filteredTableName == "")
                                return true;
                            })
                            .filter((f) => {
                              if (
                                this.state.orderStatusFilterId != "" &&
                                f.orderStatusId ==
                                  this.state.orderStatusFilterId
                              ) {
                                return true;
                              }
                              if (this.state.orderStatusFilterId == "")
                                return true;
                            })
                            .map((order, i) => (
                              <>
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{order.name}</td>
                                  <td>{order.productName}</td>
                                  <td>{order.qty}</td>
                                  <td>
                                    {/* {
                              this.context.GetRestaurantTableOrdereStatusById(
                                order.orderStatusId
                              ).orderStatus
                            } */}
                                    <Form.Select
                                      value={order.orderStatusId}
                                      size=""
                                      style={{
                                        fontWeight: "bold",
                                        backgroundColor: "yellow",
                                      }}
                                      required
                                      onChange={(e) =>
                                        this.OnOrderStatusChange(e, order)
                                      }
                                    >
                                      <option value={1}>Received</option>
                                      <option value={2}>Preparing</option>
                                      <option value={3}>Ready</option>
                                      {/* {this.context.restaurantTableOrdereStatus.map(
                                (sta) => (
                                  <option value={sta.id}>
                                    {" "}
                                    {sta.orderStatus}
                                  </option>
                                )
                              )} */}
                                    </Form.Select>
                                  </td>
                                  <td>
                                    {this.GetDateDifference(order.created)}{" "}
                                    minutes
                                  </td>
                                  <td>
                                    {new Date(order.created).getDate() +
                                      "/" +
                                      (new Date(order.created).getMonth() + 1) +
                                      "/" +
                                      new Date(order.created)
                                        .getFullYear()
                                        .toString()
                                        .substr(-2) +
                                      "  " +
                                      new Date(order.created).toLocaleString(
                                        "en-US",
                                        {
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        }
                                      )}
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </Table>
                    </>
                  ) : (
                    <>
                      <div className="p-2">
                        <Row>
                          <Col className="text-end">
                            Filter by Table
                            <br />
                            <Form.Select
                              value={this.state.filteredTableName}
                              size=""
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "white",
                              }}
                              required
                              onChange={(e) =>
                                this.OnfilteredTableNameChanged(e)
                              }
                            >
                              <option value=""> </option>
                              {this.context.returantTables.map((sta) => (
                                <option value={sta.name}> {sta.name}</option>
                              ))}
                            </Form.Select>
                          </Col>

                          <Col className="text-end">
                            Filter by Product
                            <Form.Select
                              value={this.state.filteredItemName}
                              size=""
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "white",
                              }}
                              required
                              onChange={(e) =>
                                this.OnfilteredItemNameChanged(e)
                              }
                            >
                              <option value=""> </option>
                              {this.state.filteredProdList.map((sta) => (
                                <option value={sta}> {sta}</option>
                              ))}
                            </Form.Select>
                          </Col>
                          <Col className="text-end">
                            Filter by Status
                            <br />
                            <Form.Select
                              value={this.state.orderStatusFilterId}
                              size=""
                              style={{
                                fontWeight: "bold",
                                backgroundColor: "white",
                              }}
                              required
                              onChange={(e) =>
                                this.OnOrderStatusFilterChanged(e)
                              }
                            >
                              <option value=""> </option>
                              <option value="1">Received</option>
                              <option value="2">Preparing</option>
                              {/* <option value=""> </option>
                          {this.context.restaurantTableOrdereStatus.map(
                            (sta) => (
                              <option value={sta.id}> {sta.orderStatus}</option>
                            )
                          )} */}
                            </Form.Select>
                          </Col>
                        </Row>
                      </div>
                      <Table className="border ">
                        <thead>
                          <th width="5%"></th>
                          <th width="15%">Table</th>
                          <th width="35%">Prod.Name</th>
                          <th width="10%">Qty</th>
                          <th width="35%">Status</th>
                        </thead>
                        <tbody>
                          {this.state.ordersDashboard
                            .sort((a, b) =>
                              new Date(a.created) > new Date(b.created) ? 1 : -1
                            )
                            .filter(
                              (f) =>
                                f.orderStatusId == 1 || f.orderStatusId == 2
                            )
                            .filter((f) => {
                              if (
                                this.state.filteredItemName != "" &&
                                f.productName == this.state.filteredItemName
                              )
                                return true;
                              if (this.state.filteredItemName == "")
                                return true;
                            })
                            .filter((f) => {
                              if (
                                this.state.filteredTableName != "" &&
                                f.name == this.state.filteredTableName
                              )
                                return true;
                              if (this.state.filteredTableName == "")
                                return true;
                            })
                            .filter((f) => {
                              if (
                                this.state.orderStatusFilterId != "" &&
                                f.orderStatusId ==
                                  this.state.orderStatusFilterId
                              ) {
                                return true;
                              }
                              if (this.state.orderStatusFilterId == "")
                                return true;
                            })
                            .map((order, i) => (
                              <>
                                <tr>
                                  <td>{i + 1}</td>
                                  <td>{order.name}</td>
                                  <td>{order.productName}</td>
                                  <td>{order.qty}</td>
                                  <td>
                                    {/* {
                              this.context.GetRestaurantTableOrdereStatusById(
                                order.orderStatusId
                              ).orderStatus
                            } */}
                                    <Form.Select
                                      value={order.orderStatusId}
                                      size=""
                                      style={{
                                        fontWeight: "bold",
                                        backgroundColor: "yellow",
                                      }}
                                      required
                                      onChange={(e) =>
                                        this.OnOrderStatusChange(e, order)
                                      }
                                    >
                                      <option value={1}>Received</option>
                                      <option value={2}>Preparing</option>
                                      <option value={3}>Ready</option>
                                    </Form.Select>
                                    <b>
                                      {this.GetDateDifference(order.created)}{" "}
                                      minutes
                                    </b>
                                    <br />
                                    {new Date(order.created).getDate() +
                                      "/" +
                                      (new Date(order.created).getMonth() + 1) +
                                      "/" +
                                      new Date(order.created)
                                        .getFullYear()
                                        .toString()
                                        .substr(-2) +
                                      "  " +
                                      new Date(order.created).toLocaleString(
                                        "en-US",
                                        {
                                          hour: "numeric",
                                          minute: "numeric",
                                          hour12: true,
                                        }
                                      )}
                                  </td>
                                </tr>
                              </>
                            ))}
                        </tbody>
                      </Table>
                    </>
                  )}
                </div>
              </Col>
            </Row>
          </>
        )}
      </>
    );
  }
}

class TableOrderGroupView extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      orders: [],
    };
  }
  GetRestaurantTableOrderedItemsGrouped = () => {
    this.setState({ orders: [] }, () => {
      fetch(
        process.env.REACT_APP_API +
          "Consumers/GetRestaurantTableOrderedItemsGrouped/" +
          this.context.storeId
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 400) {
          } else {
            this.setState({ orders: data });
          }
        });
    });
  };
  componentDidMount = () => {
    this.GetRestaurantTableOrderedItemsGrouped();
  };
  render() {
    return (
      <>
        <div className="container ">
          <Modal
            {...this.props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
            keyboard={false}
            show={true}
            dialogClassName="BrandModal"
          >
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                Table Orders Grouped by Product
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Table className="border text-center">
                <thead>
                  <th width="10%"></th>
                  <th width="50%">Item Name</th>
                  <th width="40%">Qty</th>
                </thead>
                <tbody>
                  {this.state.orders.map((order, i) => (
                    <>
                      <tr>
                        <td>{i + 1}</td>
                        <td>{order.productName}</td>
                        <td>{order.totQty}</td>
                      </tr>
                    </>
                  ))}
                </tbody>
              </Table>
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </>
    );
  }
}
