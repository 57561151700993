import React, { Component, useContext } from "react";
import CartContext from "./CartContext";
import { OrderDetails } from "./OrderDetails";
import { OrderDetailsMobile } from "./OrderDetailsMobile";
import { SideMenu } from "./SideMenu";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import Fuse from "fuse.js";
import swal from "sweetalert";
import axios from "axios";
import Swal from "sweetalert2";
import { LoadSpinner } from "./LoadSpinner";
import ReactToPrint, { PrintContextConsumer } from "react-to-print";
import { useRef } from "react";
import { useState } from "react";
// import { usePDF } from "react-to-pdf";
// import generatePDF, { Resolution, Margin } from "react-to-pdf";

import {
  InputGroup,
  Stack,
  FormControl,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Nav,
  Table,
  Navbar,
  Card,
} from "react-bootstrap";

import { ProductAddOrEditModal } from "./ProductAddOrEditModal";
import { EditProductModal } from "./ProductAddOrEditModal";

import Select from "react-select";
import {} from "react-bootstrap";

export class Orders extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      OrderReceived: 1,
      ordersDetail: [],
      selectedOrder: -1,
      selectedOrderRow: "",
      filteredOrdersDetail: [],
      showOrderDetailsModal: false,
      RefreshPage: false,
      Title: "",
      loadSpinnerOpen: false,
      firstload: false,
      htmltext: "",
    };
  }

  downloadTxtFile = () => {
    const texts = ["line 1", "line 2", "line 3"];

    const file = new Blob(texts, { type: "text/plain" });

    const element = document.createElement("a");
    element.href = URL.createObjectURL(file);
    element.download = "100ideas-" + Date.now() + ".txt";

    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  GetInvoiceHtml = () => {
    if (this.state.selectedOrder == -1) return;
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "StoreManager/GetInvoiceHtml/" +
          this.context.storeId +
          "/" +
          this.state.selectedOrder.id
      )
      .then((response) => {
        if (response.status === 200) {
          if (response.data.length > 0) {
            this.setState({ htmltext: response.data });
          }
        }
      })
      .catch((error) => {
        console.error("GetOrderStatusTypes - There was an error! ", error);
      });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  Reload = () => {
    this.setState({ RefreshPage: true }, () => {});
  };

  setOrders = (data) => {
    if (
      this.props.OrderState == "Delivery" &&
      this.context.UserRoles.DELIVERY == this.context.userData.roleId
    ) {
      var result = data.filter(
        (f) => f.deliveryPersonId == this.context.userData.emailId
      );
      this.setState({ ordersDetail: result });
      this.setState({ filteredOrdersDetail: result });

      if (result.length > 0) {
        this.setState({ selectedOrderRow: data[0] });
      } else {
        this.setState({ selectedOrderRow: "" });
      }
    } else {
      this.setState({ ordersDetail: data });
      this.setState({ filteredOrdersDetail: data });
      if (data.length > 0) {
        this.setState({ selectedOrderRow: data[0] });
      } else {
        this.setState({ selectedOrderRow: "" });
      }
    }
  };

  GetOrders = () => {
    this.ShowLoadSpinner();
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetOrdersByStatusName/" +
        this.context.storeId +
        "/" +
        this.props.OrderState
    )
      .then((response) => response.json())
      .then((data) => {
        this.setOrders(data);
        this.HideLoadSpinner();
        if (data.length > 0)
          this.setState({ selectedOrder: data[0] }, () => {
            this.GetInvoiceHtml();
          });
      })
      .catch((error) => {
        console.error("There was an error!", error);
        this.HideLoadSpinner();
      });
  };

  OnOrderRowClick = (e, order) => {
    this.setState({ selectedOrder: order }, () => {
      this.GetInvoiceHtml();
    });
    this.setState({ showOrderDetailsModal: true });
  };

  enableDeleteButtonMenu = () => {
    if (this.context.UserRoles.PRODUCTADMIN == this.context.userData.roleId) {
      return false;
    } else {
      return true;
    }
  };

  OnDeleteOrder = (e, order) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteAnCustomerOrder/" +
        order.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.Reload();
          alert("not deleted");
          return;
        }
        this.Reload();
        Swal.fire({
          title: "Status",
          text: "Deleted Successfully",
          // confirmButtonText: "yes",

          timer: 1000,
        }).then((result) => {});
      })
      .catch((error) => {
        alert("OnDelete Failed" + error);
      });
  };

  OnOrderRowSelect = (e, order) => {
    this.setState({ selectedOrderRow: "" }, () => {
      this.setState({ selectedOrderRow: order });
      this.setState({ selectedOrder: order }, () => {
        this.GetInvoiceHtml();
      });
    });

    // this.setState({ showOrderDetailsModal: true });
  };
  ModalClose = () => {
    //window.scrollTo(0, 0);
    this.setState({ showOrderDetailsModal: false }, () => {
      // swal("Updated Successfully!", "", "success");
    });
    this.Reload();
  };

  onSearchChange = (query) => {
    this.setState({ reload: false });
    this.setState({ searchquery: query });
    if (!query) {
      this.setState({ filteredOrdersDetail: this.state.ordersDetail }, () => {
        this.setState({ reload: true });
      });
      return;
    }
    const fuse = new Fuse(this.state.ordersDetail, {
      keys: ["orderId", "deliveryAddress", "customerName", "mobileNo"],
      threshold: 0.0,
      ignoreLocation: true,
      findAllMatches: true,
      includeMatches: true,
      distance: 120,
    });
    const result = fuse.search(query);
    const finalResult = [];
    if (result.length) {
      result.forEach((item) => {
        finalResult.push(item.item);
      });
      this.setState({ filteredOrdersDetail: finalResult.slice(0, 15) }, () => {
        this.setState({ reload: true });
      });
    } else {
      this.setState({ filteredOrdersDetail: [] });
    }
  };

  GetDateDifference = (createddate) => {
    // var diff = Math.abs(new Date().toLocaleString() - createddate);
    // var startTime = new Date(new Date().toLocaleString());
    // var endTime = new Date(createddate.toLocaleString());
    var startTime = new Date();
    var endTime = new Date(createddate);
    var diff = startTime - endTime;
    // var difference = endTime.getTime() - startTime.getTime(); // This will give difference in milliseconds
    var minutes = Math.floor(diff / 1000 / 60);
    var hours = Math.floor(minutes / 60, 0);
    var days = Math.floor(hours / 24, 0);
    var daysstring = days > 0 ? days + "d : " : " ";
    var hoursstring = hours > 0 ? (hours % 24) + "h : " : " ";

    return daysstring + hoursstring + (minutes % 60) + " m";
    //return minutes + 1;
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.OrderState === "Received") {
      this.setState({ Title: "1. New Orders Management" });
    } else if (this.props.OrderState === "Package") {
      this.setState({ Title: "3. Packaging Management System(PMS)" });
    } else if (this.props.OrderState === "Dispatch") {
      this.setState({ Title: "4. Shipping/Dispatch Management" });
    } else if (this.props.OrderState === "Delivery") {
      this.setState({ Title: "5. Delivery Management" });
    } else if (this.props.OrderState === "Payment") {
      this.setState({ Title: "6. Payment Review System(PRS)" });
    } else if (this.props.OrderState === "Close") {
      this.setState({ Title: "7. Final Review & Close Orders" });
    } else if (this.props.OrderState === "OrderHisotry") {
      this.setState({ Title: "Orders History / Closed Orders" });
    } else if (this.props.OrderState === "Returns") {
      this.setState({ Title: "Returns" });
    }
    if (this.state.firstload == false) {
      this.state.firstload = true;
      this.GetOrders();
    }
  }

  GetInvoiceHtmlFromFileAndPrint = (orderId) => {
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "StoreManager/GetInvoiceHtmlFromFile/" +
          this.context.storeId +
          "," +
          orderId
      )
      .then((response) => {
        if (response.status === 200) {
          console.log(response.data);
          if (response.data.length > 0) {
            var printWindow = window.open("", "", "height=1000,width=1500");
            //var printWindow = window.open("", "");
            printWindow.document.write(response.data);
            printWindow.document.close();
            this.onPrintFinished(printWindow.print(), printWindow);
          }
        }
      })
      .catch((error) => {
        console.error("GetOrderStatusTypes - There was an error! ", error);
      });
  };
  onPrintFinished = (status, printWindow) => {
    // printWindow.close();
  };

  PrintInvoice = (order) => {
    this.GetInvoiceHtmlFromFileAndPrint(order.orderId);
  };

  PrepareStatus = (order) => {
    var status = order.customerOrderedItems.filter(
      (f) => f.orderStatusId != 3 && f.availability == true
    );
    if (status.length > 0) return "Preparing";
    else return "Ready";
  };

  UpdateOrder = (order, changedOrderedState) => {
    var tmporder = order;

    tmporder.orderStatus = changedOrderedState;

    var orderStatus = this.context.orderStatusTypes.filter(
      (f) => f.id == Number(changedOrderedState)
    );
    tmporder.orderStatusName = orderStatus[0].statusName;
    tmporder.orderWorkflowStatus +=
      "<p>" +
      this.context.GetInDateFormat(new Date()) +
      "[" +
      this.context.userData.emailId +
      "]" +
      "=> <b>" +
      orderStatus[0].statusName +
      "</b></p>";

    //tmporder.customerOrderedItems = this.state.updatedOrderedItems;
    // tmporder.orderModified = new Date().toLocaleString();

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmporder),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/UpdateOrder/" + order.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          return;
        }
        console.log("Updated Successfully");
        this.context.GetOrdersSummary();
        this.Reload();
        Swal.fire({
          title: "Order Status",
          text: "Changed to => " + orderStatus[0].statusName,

          timer: 1000,
        }).then((result) => {});
      })
      .catch((error) => {
        alert("Failed" + error);
      });
  };

  UpdateOrderState = (order, changedOrderedState) => {
    var tmporder = order;
    tmporder.orderStatus = changedOrderedState;

    var orderStatus = this.context.orderStatusTypes.filter(
      (f) => f.id == Number(changedOrderedState)
    )[0];
    tmporder.orderWorkflowStatus +=
      "<p>" +
      new Date().toLocaleString("en-GB", {
        timeZone: "Asia/Kolkata",
      }) +
      "[" +
      this.context.userData.emailId +
      "]" +
      "=> <b>" +
      orderStatus.statusName +
      "</b></p>";

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmporder),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/UpdateOrderStatus",
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          alert("not updated");
          return;
        }
        console.log("Updated Successfully");
        this.context.GetOrdersSummary();
        this.Reload();
        Swal.fire({
          title: "Order Status",
          text: "Changed to => " + orderStatus.statusName,
          // confirmButtonText: "yes",

          timer: 1000,
        }).then((result) => {});
      })
      .catch((error) => {
        alert("Failed" + error);
      });
  };

  render() {
    if (this.state.RefreshPage === true) {
      this.GetOrders();
      this.setState({ RefreshPage: false });
      return <></>;
    }
    return (
      <>
        <>
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <div className="Container-fluid mx-3 px-0 bg-white">
            <Row>
              {window.innerWidth >= 1200 && this.props.KdsView != true ? (
                <>
                  {/* <Col className="pagebackground " xs={2}>
                    <SideMenu></SideMenu>
                  </Col> */}
                </>
              ) : (
                ""
              )}
              <Col
                xs={window.innerWidth >= 1200 ? 12 : 12}
                className="bg-white p-1 my-0"
              >
                <Navbar className="secondarythemecolor position-sticky fixed-top px-1 m-0 p-1 text-center border-3 border-dark">
                  {window.innerWidth >= 1200 ? (
                    <>
                      <Navbar.Collapse className="justify-content-end m-0 p-0 ">
                        <Nav>
                          <h4 className=""> {this.state.Title} &nbsp;</h4>
                        </Nav>
                      </Navbar.Collapse>
                    </>
                  ) : (
                    <>
                      <small style={{ fontWeight: "normal" }}>
                        {this.state.Title}
                      </small>
                      {/* <Navbar.Collapse className="justify-content-start m-0 p-0 ">
                        <Nav>
                          
                        </Nav>
                      </Navbar.Collapse> */}
                    </>
                  )}

                  <Navbar.Collapse className="justify-content-end m-0 p-0 ">
                    <Nav>
                      <ButtonToolbar className="text-start align-middle">
                        &nbsp;
                        <Button
                          className="mainthemecolor"
                          onClick={() => this.Reload()}
                        >
                          <BiRefresh size="20"></BiRefresh>
                          Refresh
                        </Button>
                        &nbsp;
                      </ButtonToolbar>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
                <Stack direction="horizontal" gap={3} className="py-2">
                  <b>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      fill="currentColor"
                      class="bi bi-search"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                    </svg>
                  </b>
                  <FormControl
                    type="search"
                    placeholder="Search by Phoneno,CustomerName,OrderNo... "
                    className="text-primary fw-bold bg-white "
                    aria-label="Search"
                    onChange={(e) => this.onSearchChange(e.target.value)}
                  />
                </Stack>
                {window.innerWidth >= 1200 ? (
                  <>
                    <Table>
                      <tbody>
                        <tr>
                          <td
                            width={this.props.KdsView == true ? "100%" : "70%"}
                            className="border  bg-light"
                          >
                            <div
                              className="fixedTableHightOrder"
                              style={{
                                height: window.innerHeight - 165 + "px",
                              }}
                            >
                              <Table
                                bordered
                                hover
                                className="bg-white ordertablecustom"
                                style={{
                                  // tableLayout: "revert",
                                  verticalAlign: "middle",
                                }}
                                striped
                              >
                                <thead>
                                  <tr
                                    className="text-center  text-dark "
                                    // style={{ whiteSpace: "nowrap" }}
                                  >
                                    <th width="3%"></th>
                                    <th width="30%">
                                      OrderId &nbsp;[
                                      {this.state.filteredOrdersDetail.length}]
                                    </th>
                                    {/* <th width="30%">Delivery Details</th> */}
                                    <th width="67%">Order Details</th>
                                    {/* <th width="37%">Order Status</th> */}
                                    {/* <th width="10%"></th> */}
                                  </tr>
                                </thead>
                                <tbody>
                                  {this.state.filteredOrdersDetail
                                    .sort((a, b) =>
                                      a.orderCreated > b.orderCreated ? 1 : -1
                                    )
                                    .map((order, i) => (
                                      <>
                                        <tr
                                          className="text-center "
                                          style={{ outline: "thin solid" }}
                                          onClick={(e) =>
                                            this.OnOrderRowSelect(e, order)
                                          }
                                          onDoubleClick={(e) =>
                                            this.OnOrderRowClick(e, order)
                                          }
                                          // style={{ whiteSpace: "nowrap" }}
                                        >
                                          <td width="3%">{i + 1}</td>
                                          <td width="10%">
                                            <h4>
                                              <b className="border border-success CornersRounded ">
                                                &nbsp;{order.orderId}&nbsp;
                                              </b>
                                            </h4>
                                            <b>
                                              {this.GetDateDifference(
                                                order.orderCreated
                                              )}{" "}
                                              {/* minutes */}
                                            </b>
                                            [
                                            {this.context.GetInDateFormat(
                                              new Date(order.orderCreated)
                                            ) +
                                              " " +
                                              // new Date(
                                              //   order.orderCreated
                                              // ).getDate() +
                                              //   "/" +
                                              //   (new Date(
                                              //     order.orderCreated
                                              //   ).getMonth() +
                                              //     1) +
                                              //   "/" +
                                              //   new Date(
                                              //     order.orderCreated
                                              //   ).getFullYear() +
                                              //   " "
                                              new Date(
                                                order.orderCreated
                                              ).toLocaleString("en-US", {
                                                hour: "numeric",
                                                minute: "numeric",
                                                hour12: true,
                                              })}{" "}
                                            ]<br />{" "}
                                            <h6>{order.deliveryAddress}</h6>
                                            <a
                                              target="_"
                                              href={
                                                "https://www.google.com/maps/search/" +
                                                order.googleAddress
                                              }
                                            >
                                              <h5>Google Map</h5>
                                            </a>
                                          </td>

                                          <td
                                            width="60%"
                                            style={{ fontSize: "14px" }}
                                          >
                                            <Row className="">
                                              <Col sm={6} className="">
                                                <Table
                                                  className="w-100 text-center border p-0 m-0"
                                                  size="sm"
                                                >
                                                  <tbody className="">
                                                    <tr className="">
                                                      <td className="w-50 text-center border ">
                                                        Tot.Qty:
                                                      </td>
                                                      <td className="w-50 text-center border">
                                                        <strong>
                                                          <large>
                                                            {order.totalQty}
                                                          </large>
                                                        </strong>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td className="w-50 text-center border ">
                                                        Total Amount:
                                                      </td>
                                                      <td className="w-50 text-center border">
                                                        <strong>
                                                          {Math.round(
                                                            Number(
                                                              order.totalAmt
                                                            ),
                                                            0
                                                          ).toLocaleString(
                                                            this.context
                                                              .storeSettings
                                                              .defaultLocale,
                                                            {
                                                              minimumFractionDigits: 0,
                                                              maximumFractionDigits: 1,
                                                              style: "currency",
                                                              currency:
                                                                this.context
                                                                  .storeSettings
                                                                  .defaultCurrency,
                                                            }
                                                          )}
                                                        </strong>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td className="w-50 text-center border ">
                                                        Order Method:{" "}
                                                      </td>
                                                      <td className="w-50 text-center border">
                                                        <strong>
                                                          {order
                                                            .customerUserType
                                                            .userTypeName ==
                                                          "POS"
                                                            ? order.deliveryMethodId ==
                                                              4
                                                              ? "Swiggy"
                                                              : order.deliveryMethodId ==
                                                                5
                                                              ? "Zomto"
                                                              : "POS"
                                                            : "Online"}
                                                        </strong>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td className="w-50 text-center border ">
                                                        Delivery Method:{" "}
                                                      </td>
                                                      <td className="w-50 text-center border">
                                                        <strong>
                                                          <strong>
                                                            {order.deliveryMethodId ==
                                                            1
                                                              ? "Delivered"
                                                              : order.deliveryMethodId ==
                                                                2
                                                              ? "TakeAway"
                                                              : order.deliveryMethodId ==
                                                                3
                                                              ? "Home Delivery"
                                                              : order.deliveryMethodId ==
                                                                4
                                                              ? "Swiggy"
                                                              : order.deliveryMethodId ==
                                                                5
                                                              ? "Zomato"
                                                              : "???"}
                                                          </strong>
                                                        </strong>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </Table>
                                              </Col>
                                              <Col sm={6}>
                                                <div>
                                                  <h5 className="text-dark">
                                                    <i>
                                                      <small>
                                                        Order Status:
                                                      </small>
                                                      <span className="border border-success CornersRounded ">
                                                        <b>
                                                          &nbsp;&nbsp;
                                                          {
                                                            order.orderStatusName
                                                          }
                                                          &nbsp;&nbsp;
                                                        </b>
                                                      </span>
                                                    </i>
                                                  </h5>
                                                </div>
                                                <Table
                                                  className="w-100 text-center border p-0 m-0 "
                                                  size="sm"
                                                >
                                                  <tbody>
                                                    {this.props.OrderState ==
                                                    "Package" ? (
                                                      <>
                                                        <tr>
                                                          <td className="w-50 text-center border ">
                                                            Prepare Status:{" "}
                                                          </td>

                                                          <td className="w-50 text-center border">
                                                            <span className="blink_me ">
                                                              <h5 className="">
                                                                <b>
                                                                  {this.PrepareStatus(
                                                                    order
                                                                  ) ==
                                                                  "Ready" ? (
                                                                    <span
                                                                      style={{
                                                                        backgroundColor:
                                                                          "#1B5448",
                                                                        color:
                                                                          "white",
                                                                      }}
                                                                    >
                                                                      &nbsp;Ready&nbsp;
                                                                    </span>
                                                                  ) : (
                                                                    <span
                                                                      style={{
                                                                        backgroundColor:
                                                                          "yellow",
                                                                      }}
                                                                    >
                                                                      &nbsp;Preparing&nbsp;
                                                                    </span>
                                                                  )}
                                                                </b>
                                                              </h5>
                                                            </span>
                                                          </td>
                                                        </tr>
                                                      </>
                                                    ) : (
                                                      <></>
                                                    )}

                                                    <tr>
                                                      <td className="w-50 text-center border ">
                                                        Payment Status:{" "}
                                                      </td>
                                                      <td className="w-50 text-center border">
                                                        <strong>
                                                          {order.paymentStatusName ==
                                                          "Unpaid" ? (
                                                            <>
                                                              <span
                                                                className="p-1 blink_me"
                                                                style={{
                                                                  // backgroundColor:
                                                                  //   "red",
                                                                  color: "red",
                                                                }}
                                                              >
                                                                <b
                                                                  style={{
                                                                    fontSize:
                                                                      "large",
                                                                  }}
                                                                >
                                                                  {
                                                                    order.paymentStatusName
                                                                  }
                                                                </b>
                                                              </span>
                                                            </>
                                                          ) : (
                                                            <>
                                                              <b
                                                                style={{
                                                                  fontSize:
                                                                    "large",
                                                                }}
                                                              >
                                                                {
                                                                  order.paymentStatusName
                                                                }
                                                              </b>
                                                            </>
                                                          )}
                                                        </strong>
                                                      </td>
                                                    </tr>
                                                    <tr>
                                                      <td className="w-50 text-center border ">
                                                        Payment Mode:{" "}
                                                      </td>
                                                      <td className="w-50 text-center border">
                                                        <strong>
                                                          {
                                                            order.paymentmodeName
                                                          }
                                                        </strong>
                                                      </td>
                                                    </tr>
                                                  </tbody>
                                                </Table>
                                              </Col>
                                            </Row>

                                            <Row
                                              className="w-100 p-0"
                                              style={{}}
                                            >
                                              <Stack
                                                direction="horizontal"
                                                className="w-100"
                                                style={{
                                                  justifyContent: "end",
                                                }}
                                              >
                                                <Button
                                                  // className="mainthemecolor w-100"
                                                  className="mx-2"
                                                  onClick={(e) =>
                                                    this.OnOrderRowClick(
                                                      e,
                                                      order
                                                    )
                                                  }
                                                >
                                                  Open Order
                                                </Button>

                                                <Button
                                                  className="my-2"
                                                  hidden={this.enableDeleteButtonMenu()}
                                                  variant="danger"
                                                  onClick={(e) => {
                                                    if (
                                                      window.confirm(
                                                        " Are you sure that you want to Delete?"
                                                      )
                                                    ) {
                                                      this.OnDeleteOrder(
                                                        e,
                                                        order
                                                      );
                                                    }
                                                  }}
                                                >
                                                  Delete
                                                </Button>
                                                {this.props.OrderState ==
                                                  "Received" &&
                                                order.paymentmodeName !=
                                                  "BankReceipt" &&
                                                order.orderStatus ==
                                                  this.context.OrderStates
                                                    .RECEIVED ? (
                                                  <>
                                                    <Button
                                                      className="my-2 mx-2"
                                                      variant="success"
                                                      onClick={(e) =>
                                                        this.UpdateOrderState(
                                                          order,
                                                          this.context
                                                            .OrderStates
                                                            .STARTPACKAGING
                                                        )
                                                      }
                                                    >
                                                      Start Packaging
                                                    </Button>
                                                    <Button
                                                      className="my-2 mx-2 "
                                                      variant="danger"
                                                      onClick={(e) => {
                                                        Swal.fire({
                                                          title: "Warning!",
                                                          confirmButtonColor:
                                                            "#23B14D",
                                                          cancelButtonColor:
                                                            "#23B14D",
                                                          text: "Are you sure Order Packaged and Ready to Ship?",
                                                          showCancelButton:
                                                            "true",
                                                          confirmButtonText:
                                                            "Yes",
                                                          cancelButtonText:
                                                            "No",
                                                        }).then((result) => {
                                                          if (
                                                            result.isConfirmed
                                                          ) {
                                                            this.UpdateOrder(
                                                              order,
                                                              this.context
                                                                .OrderStates
                                                                .PACKAGED
                                                            );
                                                          }
                                                        });
                                                      }}
                                                    >
                                                      Packaged [Ready to Ship /
                                                      Deliver]
                                                    </Button>
                                                    <Button
                                                      className="my-2 mx-2 "
                                                      variant="danger"
                                                      onClick={(e) => {
                                                        Swal.fire({
                                                          title: "Warning!",
                                                          confirmButtonColor:
                                                            "#23B14D",
                                                          cancelButtonColor:
                                                            "#23B14D",
                                                          text: "Are you sure Order Delivered and Paid?",
                                                          showCancelButton:
                                                            "true",
                                                          confirmButtonText:
                                                            "Yes",
                                                          cancelButtonText:
                                                            "No",
                                                        }).then((result) => {
                                                          if (
                                                            result.isConfirmed
                                                          ) {
                                                            order.paymentStatusId = 1;
                                                            order.paymentStatusName =
                                                              "Paid";
                                                            order.totalAmtPaid =
                                                              order.totalAmt;
                                                            order.amtToRefund = 0;
                                                            this.UpdateOrder(
                                                              order,
                                                              this.context
                                                                .OrderStates
                                                                .DELIVEREDANDPAID
                                                            );
                                                          }
                                                        });
                                                      }}
                                                    >
                                                      Delivered & Paid
                                                    </Button>
                                                  </>
                                                ) : (
                                                  <>
                                                    {this.props.OrderState ==
                                                      "Package" &&
                                                    order.orderStatus ==
                                                      this.context.OrderStates
                                                        .STARTPACKAGING &&
                                                    this.PrepareStatus(order) ==
                                                      "Ready" ? (
                                                      <>
                                                        <Button
                                                          className="my-2"
                                                          style={{
                                                            backgroundColor:
                                                              "#6EC531",
                                                            color: "black",
                                                          }}
                                                          onClick={(e) =>
                                                            this.UpdateOrderState(
                                                              order,
                                                              this.context
                                                                .OrderStates
                                                                .PACKAGED
                                                            )
                                                          }
                                                        >
                                                          Packaged
                                                        </Button>
                                                        <br />
                                                      </>
                                                    ) : (
                                                      <>
                                                        {this.props
                                                          .OrderState ==
                                                          "Delivery" &&
                                                        order.orderStatus ==
                                                          this.context
                                                            .OrderStates
                                                            .PACKAGED &&
                                                        order.paymentStatusId ==
                                                          this.context
                                                            .PaymentStates
                                                            .PAID ? (
                                                          <>
                                                            <Button
                                                              // className="mainthemecolor w-100"
                                                              className="my-2"
                                                              style={{
                                                                backgroundColor:
                                                                  "#6EC531",
                                                                color: "black",
                                                              }}
                                                              onClick={(e) =>
                                                                this.UpdateOrderState(
                                                                  order,
                                                                  this.context
                                                                    .OrderStates
                                                                    .DELIVEREDANDPAID
                                                                )
                                                              }
                                                            >
                                                              <b>
                                                                PaidAndDelivered
                                                              </b>
                                                            </Button>
                                                          </>
                                                        ) : (
                                                          <>
                                                            {this.props
                                                              .OrderState ==
                                                              "Delivery" &&
                                                            order.orderStatus ==
                                                              this.context
                                                                .OrderStates
                                                                .SENTDELIVERY &&
                                                            order.paymentStatusId ==
                                                              this.context
                                                                .PaymentStates
                                                                .PAID ? (
                                                              <>
                                                                <Button
                                                                  // className="mainthemecolor w-100"
                                                                  className="my-2"
                                                                  style={{
                                                                    backgroundColor:
                                                                      "#6EC531",
                                                                    color:
                                                                      "black",
                                                                  }}
                                                                  onClick={(
                                                                    e
                                                                  ) =>
                                                                    this.UpdateOrderState(
                                                                      order,
                                                                      this
                                                                        .context
                                                                        .OrderStates
                                                                        .DELIVEREDANDPAID
                                                                    )
                                                                  }
                                                                >
                                                                  <b>
                                                                    PaidAndDelivered
                                                                  </b>
                                                                </Button>
                                                                <br />
                                                              </>
                                                            ) : (
                                                              <>
                                                                {this.props
                                                                  .OrderState ==
                                                                  "Payment" &&
                                                                order.orderStatus ==
                                                                  this.context
                                                                    .OrderStates
                                                                    .DELIVEREDANDPAID &&
                                                                order.amtToRefund <=
                                                                  1 &&
                                                                order.paymentStatusId ==
                                                                  this.context
                                                                    .PaymentStates
                                                                    .PAID ? (
                                                                  <>
                                                                    <Button
                                                                      // className="mainthemecolor w-100"
                                                                      className="my-2"
                                                                      style={{
                                                                        backgroundColor:
                                                                          "#6EC531",
                                                                        color:
                                                                          "black",
                                                                      }}
                                                                      onClick={(
                                                                        e
                                                                      ) =>
                                                                        this.UpdateOrderState(
                                                                          order,
                                                                          this
                                                                            .context
                                                                            .OrderStates
                                                                            .PAYMENTRECEIVED
                                                                        )
                                                                      }
                                                                    >
                                                                      <b>
                                                                        Payment
                                                                        Received
                                                                      </b>
                                                                    </Button>
                                                                    <br />
                                                                  </>
                                                                ) : (
                                                                  <>
                                                                    {this.props
                                                                      .OrderState ==
                                                                      "Close" &&
                                                                    order.orderStatus ==
                                                                      this
                                                                        .context
                                                                        .OrderStates
                                                                        .PAYMENTRECEIVED &&
                                                                    order.paymentStatusId ==
                                                                      this
                                                                        .context
                                                                        .PaymentStates
                                                                        .PAID ? (
                                                                      <>
                                                                        <Button
                                                                          // className="mainthemecolor w-100"
                                                                          className="my-2"
                                                                          style={{
                                                                            backgroundColor:
                                                                              "#6EC531",
                                                                            color:
                                                                              "black",
                                                                          }}
                                                                          onClick={(
                                                                            e
                                                                          ) =>
                                                                            this.UpdateOrderState(
                                                                              order,
                                                                              this
                                                                                .context
                                                                                .OrderStates
                                                                                .CLOSED
                                                                            )
                                                                          }
                                                                        >
                                                                          <b>
                                                                            Close
                                                                            Order
                                                                          </b>
                                                                        </Button>
                                                                        <br />
                                                                      </>
                                                                    ) : (
                                                                      <></>
                                                                    )}
                                                                  </>
                                                                )}
                                                              </>
                                                            )}
                                                          </>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </Stack>
                                            </Row>
                                          </td>
                                        </tr>
                                      </>
                                    ))}
                                </tbody>
                              </Table>
                            </div>
                          </td>
                          <td
                            width="30% "
                            className=" m-0 p-0"
                            style={{
                              backgroundColor: "black",
                              color: "yellow",
                            }}
                          >
                            <div
                              className="fixedTableHightOrder m-0 p-0 ordertablecustom"
                              style={{
                                height: window.innerHeight - 165 + "px",
                              }}
                            >
                              {this.state.selectedOrderRow != "" ? (
                                <>
                                  <div
                                    style={{
                                      backgroundColor: "black",
                                      color: "yellow",
                                    }}
                                  >
                                    <div className="text-center  m-0 p-0">
                                      <h4>
                                        Order No.&nbsp;{"  "}
                                        {this.state.selectedOrderRow.orderId}
                                      </h4>
                                      {/* <PdfComponent
                                        htmltext={this.state.htmltext}
                                      ></PdfComponent> */}
                                      {/* <div className="btnDiv">
                                        <button
                                          id="downloadBtn"
                                          onClick={this.downloadTxtFile}
                                          value="download"
                                        >
                                          Download
                                        </button>
                                      </div> */}
                                      <PrintMe
                                        orderId={this.state.selectedOrderRow.id}
                                      ></PrintMe>
                                    </div>

                                    {/* <OrderedProductItems
                                      orderDetail={this.state.selectedOrderRow}
                                      OrderState={this.props.OrderState}
                                    ></OrderedProductItems> */}
                                  </div>
                                </>
                              ) : (
                                <></>
                              )}
                            </div>
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                  </>
                ) : (
                  <>
                    <div
                      className="fixedTableHightOrder"
                      style={{ height: window.innerHeight - 100 + "px" }}
                    >
                      <Table
                        bordered
                        hover
                        className="bg-white ordertablecustom"
                        size="sm"
                      >
                        <thead className="m-0 p-0">
                          <tr className="text-center bg-primary text-white ordertablecustom ordertable m-0 p-0 border-2 border-primary">
                            <th></th>
                            <th>Order</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        <tbody>
                          {this.state.filteredOrdersDetail
                            .sort((a, b) =>
                              a.orderCreated > b.orderCreated ? 1 : -1
                            )
                            .map((order, i) => (
                              <tr
                                className="trheight ordertablecustom text-center border-2 border-primary"
                                onDoubleClick={(e) =>
                                  this.OnOrderRowClick(e, order)
                                }
                              >
                                <td>{i + 1}</td>
                                <td
                                  className="text-start m-0 p-0"
                                  style={{
                                    lineHeight: "200%",
                                    fontSize: "11px",
                                    width: "45%",
                                  }}
                                >
                                  <div className="border w-100 m-0 p-0">
                                    <b>OrderNo:</b>{" "}
                                    <span className="bg-primary text-white">
                                      &nbsp;
                                      {order.orderId}
                                      &nbsp;
                                    </span>
                                  </div>

                                  <div className="border w-100 m-0 p-0">
                                    <b>Date:</b>{" "}
                                    {this.context.GetInDateFormat(
                                      new Date(order.orderCreated)
                                    ) +
                                      // new Date(order.orderCreated).getDate() +
                                      //   "/" +
                                      //   (new Date(order.orderCreated).getMonth() +
                                      //     1) +
                                      //   "/" +
                                      //   new Date(
                                      //     order.orderCreated
                                      //   ).getFullYear()
                                      " " +
                                      new Date(
                                        order.orderCreated
                                      ).toLocaleString("en-US", {
                                        hour: "numeric",
                                        minute: "numeric",
                                        hour12: true,
                                      })}
                                  </div>
                                  <div className="border w-100 m-0 p-0">
                                    <b>Del.Method: </b>
                                    <span className="bg-danger text-white">
                                      &nbsp;
                                      {/* {order.customerUserType.userTypeName ==
                                      "POS"
                                        ? order.deliveryMethodId == 4
                                          ? "Swiggy"
                                          : order.deliveryMethodId == 5
                                          ? "Zomto"
                                          : "POS"
                                        : "Online"} */}
                                      {order.deliveryMethodId == 1
                                        ? "Delivered"
                                        : order.deliveryMethodId == 2
                                        ? "TakeAway"
                                        : order.deliveryMethodId == 3
                                        ? "Home Delivery"
                                        : order.deliveryMethodId == 4
                                        ? "Swiggy"
                                        : order.deliveryMethodId == 5
                                        ? "Zomato"
                                        : "???"}
                                      &nbsp;
                                    </span>
                                  </div>
                                  <div className="border w-100 m-0 p-0">
                                    <b>Delivery Address:</b>
                                    <br />
                                    {order.deliveryAddress}
                                    <a
                                      target="_"
                                      href={
                                        "https://www.google.com/maps/search/" +
                                        order.googleAddress
                                      }
                                    >
                                      <h5>Google Map</h5>
                                    </a>
                                  </div>
                                </td>

                                <td
                                  className="text-start m-0 p-0"
                                  style={{
                                    lineHeight: "200%",
                                    fontSize: "11px",
                                    width: "55%",
                                  }}
                                >
                                  <div className="border w-100 m-0 p-0">
                                    <tr>
                                      <td>
                                        <h5>Tot.Qty:{order.totalQty}</h5>
                                      </td>
                                      <td>
                                        <h5>
                                          &nbsp;&nbsp;Tot.Amt:
                                          {Math.round(
                                            Number(order.totalAmt),
                                            0
                                          )}
                                        </h5>
                                      </td>
                                    </tr>
                                  </div>
                                  {this.props.OrderState == "Package" ? (
                                    <div className="border w-100 m-0 p-0">
                                      <b>Prepare Status:</b>{" "}
                                      <span className="blink_me ">
                                        <b>
                                          {this.PrepareStatus(order) ==
                                          "Ready" ? (
                                            <span
                                              style={{
                                                backgroundColor: "#1B5448",
                                                color: "white",
                                              }}
                                            >
                                              &nbsp;Ready&nbsp;
                                            </span>
                                          ) : (
                                            <span
                                              style={{
                                                backgroundColor: "yellow",
                                              }}
                                            >
                                              &nbsp;Preparing&nbsp;
                                            </span>
                                          )}
                                        </b>
                                      </span>
                                    </div>
                                  ) : (
                                    <></>
                                  )}
                                  <div className="border w-100 m-0 p-0">
                                    <b>Order Type:</b>{" "}
                                    <span className="bg-primary text-white">
                                      &nbsp;
                                      <strong>
                                        {order.customerUserType.userTypeName ==
                                        "POS"
                                          ? order.deliveryMethodId == 4
                                            ? "Swiggy"
                                            : order.deliveryMethodId == 5
                                            ? "Zomto"
                                            : "POS"
                                          : "Online"}
                                      </strong>
                                      &nbsp;
                                    </span>
                                  </div>
                                  <b>Payment Mode:</b>
                                  <span className="bg-primary text-white">
                                    &nbsp;{order.paymentmodeName}&nbsp;
                                  </span>
                                  <br />
                                  <div className="border w-100 m-0 p-0">
                                    <b>Payment Status:</b>{" "}
                                    {/* <span className="text-danger">
                                      &nbsp;{order.paymentStatusName}&nbsp;
                                    </span> */}
                                    {order.paymentStatusName == "Unpaid" ? (
                                      <>
                                        <span
                                          className="p-1 blink_me"
                                          style={{
                                            // backgroundColor:
                                            //   "red",
                                            color: "red",
                                          }}
                                        >
                                          <b style={{ fontSize: "large" }}>
                                            {order.paymentStatusName}
                                          </b>
                                        </span>
                                      </>
                                    ) : (
                                      <>
                                        <b style={{ fontSize: "large" }}>
                                          {order.paymentStatusName}
                                        </b>
                                      </>
                                    )}
                                  </div>

                                  <div className="border w-100 m-0 p-0">
                                    <b>Order Status:</b>{" "}
                                    <span className="bg-warning text-dark border CornersRounded ">
                                      &nbsp;{order.orderStatusName}&nbsp;
                                    </span>
                                  </div>
                                  <div className="text-start m-0 p-0">
                                    {this.props.OrderState == "Received" &&
                                    order.paymentmodeName != "BankReceipt" &&
                                    order.orderStatus ==
                                      this.context.OrderStates.RECEIVED ? (
                                      <>
                                        <Button
                                          className="my-1"
                                          variant="success"
                                          onClick={(e) =>
                                            this.UpdateOrderState(
                                              order,
                                              this.context.OrderStates
                                                .STARTPACKAGING
                                            )
                                          }
                                        >
                                          Start Packaging
                                        </Button>
                                        <Button
                                          className="my-0 mx-0 "
                                          variant="danger"
                                          onClick={(e) => {
                                            Swal.fire({
                                              title: "Warning!",
                                              confirmButtonColor: "#23B14D",
                                              cancelButtonColor: "#23B14D",
                                              text: "Are you sure Order Packaged and Ready to Ship?",
                                              showCancelButton: "true",
                                              confirmButtonText: "Yes",
                                              cancelButtonText: "No",
                                            }).then((result) => {
                                              if (result.isConfirmed) {
                                                this.UpdateOrder(
                                                  order,
                                                  this.context.OrderStates
                                                    .PACKAGED
                                                );
                                              }
                                            });
                                          }}
                                        >
                                          Packaged & go Shippment
                                        </Button>
                                        <Button
                                          className="my-1 mx-0 "
                                          variant="danger"
                                          onClick={(e) => {
                                            Swal.fire({
                                              title: "Warning!",
                                              confirmButtonColor: "#23B14D",
                                              cancelButtonColor: "#23B14D",
                                              text: "Are you sure Order Delivered and Paid?",
                                              showCancelButton: "true",
                                              confirmButtonText: "Yes",
                                              cancelButtonText: "No",
                                            }).then((result) => {
                                              if (result.isConfirmed) {
                                                order.paymentStatusId = 1;
                                                order.paymentStatusName =
                                                  "Paid";
                                                order.totalAmtPaid =
                                                  order.totalAmt;
                                                order.amtToRefund = 0;
                                                this.UpdateOrder(
                                                  order,
                                                  this.context.OrderStates
                                                    .DELIVEREDANDPAID
                                                );
                                              }
                                            });
                                          }}
                                        >
                                          Delivered & Paid
                                        </Button>
                                      </>
                                    ) : (
                                      <>
                                        {this.props.OrderState == "Package" &&
                                        order.orderStatus ==
                                          this.context.OrderStates
                                            .STARTPACKAGING &&
                                        this.PrepareStatus(order) == "Ready" ? (
                                          <>
                                            <Button
                                              className="my-2"
                                              style={{
                                                backgroundColor: "#6EC531",
                                                color: "black",
                                              }}
                                              onClick={(e) =>
                                                this.UpdateOrderState(
                                                  order,
                                                  this.context.OrderStates
                                                    .PACKAGED
                                                )
                                              }
                                            >
                                              Packaged
                                            </Button>
                                            <br />
                                          </>
                                        ) : (
                                          <>
                                            {this.props.OrderState ==
                                              "Delivery" &&
                                            order.orderStatus ==
                                              this.context.OrderStates
                                                .PACKAGED &&
                                            order.paymentStatusId ==
                                              this.context.PaymentStates
                                                .PAID ? (
                                              <>
                                                <Button
                                                  // className="mainthemecolor w-100"
                                                  className="my-2"
                                                  style={{
                                                    backgroundColor: "#6EC531",
                                                    color: "black",
                                                  }}
                                                  onClick={(e) =>
                                                    this.UpdateOrderState(
                                                      order,
                                                      this.context.OrderStates
                                                        .DELIVEREDANDPAID
                                                    )
                                                  }
                                                >
                                                  <b>PaidAndDelivered</b>
                                                </Button>
                                                {/* <Buttons                                                
                                                  className="my-2"
                                                  style={{
                                                    backgroundColor: "#6EC531",
                                                    color: "black",
                                                  }}
                                                  onClick={(e) =>
                                                    this.UpdateOrderState(
                                                      order,
                                                      this.context.OrderStates
                                                        .SENTDELIVERY
                                                    )
                                                  }
                                                >
                                                  <b>Sent For Delivery</b>
                                                </Button> */}
                                                <br />
                                              </>
                                            ) : (
                                              <>
                                                {this.props.OrderState ==
                                                  "Delivery" &&
                                                order.orderStatus ==
                                                  this.context.OrderStates
                                                    .SENTDELIVERY &&
                                                order.paymentStatusId ==
                                                  this.context.PaymentStates
                                                    .PAID ? (
                                                  <>
                                                    <Button
                                                      // className="mainthemecolor w-100"
                                                      className="my-2"
                                                      style={{
                                                        backgroundColor:
                                                          "#6EC531",
                                                        color: "black",
                                                      }}
                                                      onClick={(e) =>
                                                        this.UpdateOrderState(
                                                          order,
                                                          this.context
                                                            .OrderStates
                                                            .DELIVEREDANDPAID
                                                        )
                                                      }
                                                    >
                                                      <b>PaidAndDelivered</b>
                                                    </Button>
                                                    <br />
                                                  </>
                                                ) : (
                                                  <>
                                                    {this.props.OrderState ==
                                                      "Payment" &&
                                                    order.orderStatus ==
                                                      this.context.OrderStates
                                                        .DELIVEREDANDPAID &&
                                                    order.amtToRefund <= 1 &&
                                                    order.paymentStatusId ==
                                                      this.context.PaymentStates
                                                        .PAID ? (
                                                      <>
                                                        <Button
                                                          // className="mainthemecolor w-100"
                                                          className="my-2"
                                                          style={{
                                                            backgroundColor:
                                                              "#6EC531",
                                                            color: "black",
                                                          }}
                                                          onClick={(e) =>
                                                            this.UpdateOrderState(
                                                              order,
                                                              this.context
                                                                .OrderStates
                                                                .PAYMENTRECEIVED
                                                            )
                                                          }
                                                        >
                                                          <b>
                                                            Payment Received
                                                          </b>
                                                        </Button>
                                                        <br />
                                                      </>
                                                    ) : (
                                                      <>
                                                        {this.props
                                                          .OrderState ==
                                                          "Close" &&
                                                        order.orderStatus ==
                                                          this.context
                                                            .OrderStates
                                                            .PAYMENTRECEIVED &&
                                                        order.paymentStatusId ==
                                                          this.context
                                                            .PaymentStates
                                                            .PAID ? (
                                                          <>
                                                            <Button
                                                              // className="mainthemecolor w-100"
                                                              className="my-2"
                                                              style={{
                                                                backgroundColor:
                                                                  "#6EC531",
                                                                color: "black",
                                                              }}
                                                              onClick={(e) =>
                                                                this.UpdateOrderState(
                                                                  order,
                                                                  this.context
                                                                    .OrderStates
                                                                    .CLOSED
                                                                )
                                                              }
                                                            >
                                                              <b>Close Order</b>
                                                            </Button>
                                                            <br />
                                                          </>
                                                        ) : (
                                                          <></>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </>
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                    <Button
                                      className="mainthemecolor justify-content w-100"
                                      onClick={(e) =>
                                        this.OnOrderRowClick(e, order)
                                      }
                                    >
                                      Open Order
                                    </Button>
                                  </div>
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </Table>
                    </div>
                  </>
                )}

                {this.state.showOrderDetailsModal === true ? (
                  <>
                    {window.innerWidth >= 1200 ? (
                      <>
                        <OrderDetails
                          show={this.state.showOrderDetailsModal}
                          onHide={this.ModalClose}
                          OrderState={this.props.OrderState}
                          storeId={this.context.storeId}
                          orderDetail={
                            // this.state.ordersDetail[this.state.selectedOrder]
                            this.state.selectedOrder
                          }
                        />
                      </>
                    ) : (
                      <>
                        <OrderDetailsMobile
                          show={this.state.showOrderDetailsModal}
                          onHide={this.ModalClose}
                          OrderState={this.props.OrderState}
                          storeId={this.context.storeId}
                          orderDetail={
                            // this.state.ordersDetail[this.state.selectedOrder]
                            this.state.selectedOrder
                          }
                        />
                      </>
                    )}
                  </>
                ) : null}
              </Col>
            </Row>
          </div>
        </>
      </>
    );
  }
}

export class Package extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Orders
          OrderState="Package"
          KdsView={this.props.KdsView}
          deliveryType={this.props.deliveryType}
        ></Orders>
      </>
    );
  }
}
export class Dispatch extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Orders OrderState="Dispatch"></Orders>
      </>
    );
  }
}
export class Delivery extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Orders OrderState="Delivery"></Orders>
      </>
    );
  }
}
export class Payment extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Orders OrderState="Payment"></Orders>
      </>
    );
  }
}
export class CloseOrder extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Orders OrderState="Close"></Orders>
      </>
    );
  }
}

export class Returns extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Orders OrderState="Returns"></Orders>
      </>
    );
  }
}

class OrderedProductItems extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      orderedItems: [],
    };
  }

  handleInputChange = (e, index) => {
    const { name, value } = e.target;

    if (name === "qty") {
      if (Number(value) < 0) return;
      if (value == "") return;

      if (Number(value) > this.state.orderedItems[index].qty) return;
    }

    const list = [...this.state.orderedItems];
    if (name == "availability") {
      list[index][name] = value == "true" ? true : false;
    } else if (name == "amountToRefundOnReturns") {
      list[index][name] = Number(value);
    } else {
      list[index][name] = value;
    }
    this.setState({ order: list }, () => {
      this.props.StoreUpdatedOrderedItems(this.state.orderedItems);
    });
  };

  setOrders = (data) => {
    this.setState({ orderedItems: data });
  };

  GetOrder = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetOrderedItemByOrderNo/" +
        this.context.storeId +
        "/" +
        this.props.orderDetail.id
    )
      .then((response) => response.json())
      .then((data) => {
        this.setOrders(data);
        //this.props.StoreUpdatedOrderedItems(data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // alert("GetOrder fetch err:" + error);
      });
  };
  PrepareStatus = (order) => {
    if (order.availability != true) return "";
    if (order.orderStatusId != 3) return "Preparing";
    else return "Ready";
  };

  componentDidMount() {
    this.GetOrder();
  }

  render() {
    return (
      <>
        <div
          className="p-0 m-0"
          style={{ backgroundColor: "black", color: "white" }}
        >
          <h5 className="text-center">Ordered Items</h5>
          <Table striped className="p-0 m-0 border text-white" size="sm">
            <thead className="">
              <tr className=" text-secondary ">
                <th className="text-start" width="10%">
                  #
                </th>
                {/* <th className="text-center" width="7%">
                  Image
                </th> */}
                <th className="text-start" width="55%">
                  Product Name
                </th>
                <th className="text-center" width="10%">
                  {" "}
                  Qty
                </th>

                <th className="text-center" width="25%">
                  Sub. Total
                </th>
                {this.props.OrderState == "Package" ? (
                  <>
                    <th className="text-center" width="10%">
                      Prepare Status
                    </th>
                  </>
                ) : (
                  ""
                )}
              </tr>
            </thead>

            <tbody className="text-white">
              {this.state.orderedItems.length > 0
                ? this.state.orderedItems.map((orderdata, i) => (
                    <tr className=" align-middle text-white border border-secondary">
                      <td className="text-white text-start">{i + 1}</td>
                      {/* <td className="text-white text-center">
                        <img
                          src={                            

                            this.context.store.storageBlobUrl +
                            this.context.store.storageBlobContainerName +
                            "/images/" +
                            orderdata.imgFileName +
                            this.context.store.storageSasToken
                          }
                          className="cardprodimg-on-cartview mx-auto"
                          alt="..."
                        />
                      </td> */}
                      <td className="text-white text-start">
                        {orderdata.productName}
                        <br></br>
                        <span className="text-secondary">
                          <strong>
                            {Number(orderdata.mrp).toLocaleString(
                              this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 1,
                                style: "currency",
                                currency:
                                  this.context.storeSettings.defaultCurrency,
                              }
                            )}
                          </strong>
                          &nbsp;&nbsp;&nbsp;{orderdata.discount}% OFF
                        </span>
                      </td>

                      <td className="text-white text-right  " align="center">
                        {orderdata.availability == true ? (
                          orderdata.qty
                        ) : (
                          <>
                            <b className="bg-danger text-warning">
                              Unavailable
                            </b>
                          </>
                        )}
                      </td>

                      <td className=" text-white text-center">
                        <strong>
                          {Number(
                            orderdata.mrp * orderdata.qty -
                              (orderdata.qty *
                                orderdata.mrp *
                                orderdata.discount) /
                                100
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </strong>
                      </td>
                      {this.props.OrderState == "Package" ? (
                        <>
                          <td className="text-center">
                            <h5>
                              {this.PrepareStatus(orderdata) == "Ready" ? (
                                <span
                                  style={{
                                    backgroundColor: "yellow",
                                    color: "black",
                                  }}
                                >
                                  &nbsp;Ready&nbsp;
                                </span>
                              ) : this.PrepareStatus(orderdata) ==
                                "Preparing" ? (
                                <span
                                  className="blink_me "
                                  style={{
                                    backgroundColor: "yellow",
                                    color: "black",
                                  }}
                                >
                                  &nbsp;Preparing&nbsp;
                                </span>
                              ) : (
                                ""
                              )}
                            </h5>
                          </td>
                        </>
                      ) : (
                        <></>
                      )}
                    </tr>
                  ))
                : ""}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}

export function PrintMe(props) {
  const { storeId } = useContext(CartContext);

  const myref = useRef();
  const [htmltext, sethtmltext] = useState("");
  const pageStyle = `
    @page {size:30mm 20mm};
    @media all {
    .pageBreak{display:none}
  };
  @media print{
  .pageBreak{ page-Break-before:always;}
  }
    }
    `;

  const GetInvoiceHtmlFromFileAndPrint = () => {
    if (props.orderId) {
      var res = axios
        .get(
          process.env.REACT_APP_API +
            "StoreManager/GetInvoiceHtml/" +
            storeId +
            "/" +
            props.orderId
        )
        .then((response) => {
          if (response.status === 200) {
            console.log(response.data);
            if (response.data.length > 0) {
              sethtmltext(response.data);
            }
          }
        })
        .catch((error) => {
          console.error("GetOrderStatusTypes - There was an error! ", error);
        });
    }
  };
  return (
    <>
      <div>
        <ReactToPrint
          trigger={() => (
            <Button size="lg" variant="danger" className="w-100">
              PRINT
            </Button>
          )}
          content={() => myref.current}
          // pageStyle={pageStyle}
        ></ReactToPrint>
      </div>
      <div id="divcontents" ref={myref} className="w-100">
        {GetInvoiceHtmlFromFileAndPrint()}
        <div
          className="my-0 bg-white text-dark w-100"
          dangerouslySetInnerHTML={{
            __html: htmltext,
          }}
        />
      </div>
    </>
  );
}

// const options = {
//   // default is `save`
//   method: "open",
//   // default is Resolution.MEDIUM = 3, which should be enough, higher values
//   // increases the image quality but also the size of the PDF, so be careful
//   // using values higher than 10 when having multiple pages generated, it
//   // might cause the page to crash or hang.
//   resolution: Resolution.HIGH,
//   page: {
//     // margin is in MM, default is Margin.NONE = 0
//     // margin: Margin.SMALL,
//     // default is 'A4'
//     // format: "letter",
//     // default is 'portrait'
//     // orientation: "landscape",
//   },
//   // canvas: {
//   //   // default is 'image/jpeg' for better size performance
//   //   mimeType: "image/png",
//   //   qualityRatio: 1,
//   // },
//   // Customize any value passed to the jsPDF instance and html2canvas
//   // function. You probably will not need this and things can break,
//   // so use with caution.
//   overrides: {
//     // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
//     pdf: {
//       compress: true,
//     },
//     // see https://html2canvas.hertzen.com/configuration for more options
//     canvas: {
//       useCORS: true,
//     },
//   },
// };

// // you can use a function to return the target element besides using React refs
// const getTargetElement = () => document.getElementById("content-id");

// export function PdfComponent(props) {
//   const { storeId } = useContext(CartContext);

//   return (
//     <div>
//       <button onClick={() => generatePDF(getTargetElement, options)}>
//         Generate PDF
//       </button>
//       <div id="content-id" className="text-center" style={{ color: "black" }}>
//         <h1>Content to be generated to PDF</h1>
//         <div
//           className="my-0 bg-white text-dark w-100"
//           dangerouslySetInnerHTML={{
//             __html: props.htmltext,
//           }}
//         />
//       </div>
//     </div>
//   );
// }
