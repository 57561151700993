import React, { Component } from "react";
import CartContext from "./CartContext";
import { Navigate } from "react-router-dom";
import { SideMenu } from "./SideMenu";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import { Country, State, City } from "country-state-city";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import Switch from "react-switch";
import TimePicker from "react-time-picker";

import "../CSS/TimePicker.css";
import "../CSS/DateTimePicker.css";
import "../CSS/Calendar.css";
import "../CSS/Clock.css";

// import "react-time-picker/dist/TimePicker.css";
// import "react-datetime-picker/dist/DateTimePicker.css";
// import "react-calendar/dist/Calendar.css";
// import "react-clock/dist/Clock.css";

import {
  OverlayTrigger,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export default class StoreSettings extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      storeSettings: "",
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      sessionTimedout: false,
      key: "home",
    };
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDeleted = () => {
    this.OnRefreshButtonClick();
  };
  OnSaved = () => {
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.GetStoreSettings();
    this.setState({ reload: false }, () => {
      // this.setState({ storeSettings: this.context.storeSettings }, () => {
      //});
      this.setState({ reload: true });
    });
  };

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  handleInputChange = (e, i) => {
    const { name, value } = e.target;
    switch (name.trim()) {
      //Store
      case "storeName":
        this.state.storeSettings.storeName = value;
        break;
      case "offlineNote":
        this.state.storeSettings.offlineNote = value;
        break;
      case "gstNumber":
        this.state.storeSettings.gstNumber = value;
        break;
      case "storeAddress":
        this.state.storeSettings.storeAddress = value;
        break;
      case "storeEmailId":
        this.state.storeSettings.storeEmailId = value;
        break;
      case "header":
        this.state.storeSettings.header = value;
        break;
      case "footer":
        this.state.storeSettings.footer = value;
        break;
      case "storePhoneNo":
        if (value.length < 11) this.state.storeSettings.storePhoneNo = value;
        break;
      case "storeWhatsappNo":
        if (value.length < 11) this.state.storeSettings.storeWhatsappNo = value;
        break;
      case "homeDeliveryDomainAddress":
        this.state.storeSettings.homeDeliveryDomainAddress = value;
        break;
      case "homeDeliveryContactNumber":
        if (value.length < 11)
          this.state.storeSettings.homeDeliveryContactNumber = value;
        break;
      case "loginMethod":
        this.state.storeSettings.loginMethod = value;
        break;
      case "liveMode":
        this.state.storeSettings.liveMode = value;
        break;
      case "posLicenseKey":
        this.state.storeSettings.posLicenseKey = value;
        break;

      case "totalErpAdminUsers":
        this.state.storeSettings.totalErpAdminUsers = value;
        break;
      case "totalErpContributorUsers":
        this.state.storeSettings.totalErpContributorUsers = value;
        break;
      case "totalPosUsers":
        this.state.storeSettings.totalPosUsers = value;
        break;

      case "isManufacturer":
        this.state.storeSettings.isManufacturer = value;
        break;
      case "isQuotationSupported":
        this.state.storeSettings.isQuotationSupported = value;
        break;
      case "isServiceManagementBusiness":
        this.state.storeSettings.isServiceManagementBusiness = value;
        break;
      case "isReseller":
        this.state.storeSettings.isReseller = value;
        break;
      case "showOrdersManagement":
        this.state.storeSettings.showOrdersManagement = value;
        break;
      case "showProductInventoryModule":
        this.state.storeSettings.showProductInventoryModule = value;
        break;
      case "showPurchaseModule":
        this.state.storeSettings.showPurchaseModule = value;
        break;
      case "showHrmModule":
        this.state.storeSettings.showHrmModule = value;
        break;
      case "showFinanceModule":
        this.state.storeSettings.showFinanceModule = value;
        break;

      case "disableNewOrderBeepSound":
        this.state.storeSettings.disableNewOrderBeepSound = value;
        break;
      case "orderNumberDailyReset":
        this.state.storeSettings.orderNumberDailyReset = value;
        break;
      case "language2ndName":
        this.state.storeSettings.language2ndName = value;
        break;
      case "printPosBillIn2NdLanguage":
        this.state.storeSettings.printPosBillIn2NdLanguage = value;
        break;
      case "hideMyOrdersinPos":
        this.state.storeSettings.hideMyOrdersinPos = value;
        break;
      case "hideCancelOrderinPos":
        this.state.storeSettings.hideCancelOrderinPos = value;
        break;
      case "hideTakeAwayOptionOnPos":
        this.state.storeSettings.hideTakeAwayOptionOnPos = value;
        break;
      case "hideHomeDeliveryOptionOnPos":
        this.state.storeSettings.hideHomeDeliveryOptionOnPos = value;
        break;
      case "disableDeleteOrderItemsOnTableOrder":
        this.state.storeSettings.disableDeleteOrderItemsOnTableOrder = value;
        break;
      case "storeLogoFileName":
        this.state.storeSettings.storeLogoFileName = value;
        break;
      case "favicon":
        this.state.storeSettings.favicon = value;
        break;
      case "homePageBizSummary":
        this.state.storeSettings.homePageBizSummary = value;
        break;
      case "googlemapEmbedUrl":
        this.state.storeSettings.googlemapEmbedUrl = value;
        break;
      case "bannerVideoFileName":
        this.state.storeSettings.bannerVideoFileName = value;
        break;

      case "bannerVideoCenterMessage":
        this.state.storeSettings.bannerVideoCenterMessage = value;
        break;
      case "bannerVideoBottomMessage":
        this.state.storeSettings.bannerVideoBottomMessage = value;
        break;
      case "themeName":
        this.state.storeSettings.themeName = value;
        break;
      case "customThemeName":
        this.state.storeSettings.customThemeName = value;
        break;
      case "defaultCountry":
        this.state.storeSettings.defaultCountry = value;
        break;
      case "defaultLocale":
        this.state.storeSettings.defaultLocale = value;
        break;
      case "defaultCountryCode":
        this.state.storeSettings.defaultCountryCode = value;
        break;
      case "defaultState":
        this.state.storeSettings.defaultState = value;
        break;
      case "disableCountrySelection":
        this.state.storeSettings.disableCountrySelection = value;
        break;
      case "disableStateSelection":
        this.state.storeSettings.disableStateSelection = value;
        break;
      case "multiCurrencySupport":
        this.state.storeSettings.multiCurrencySupport = value;
        break;
      case "defaultCurrency":
        this.state.storeSettings.defaultCurrency = value;
        break;

      //Email Settings
      case "fromEmailAddress":
        this.state.storeSettings.fromEmailAddress = value;
        break;
      case "fromEmailAddressFirstName":
        this.state.storeSettings.fromEmailAddressFirstName = value;
        break;
      case "fromEmailAddressLastName":
        this.state.storeSettings.fromEmailAddressLastName = value;
        break;
      case "phoneNumberLength":
        this.state.storeSettings.phoneNumberLength = value;
        break;
      case "pincodeLength":
        this.state.storeSettings.pincodeLength = value;
        break;

      //Flags
      case "hideDisplayCounters":
        if (value == "true")
          this.state.storeSettings.hideDisplayCounters = false;
        else this.state.storeSettings.hideDisplayCounters = true;
        break;
      case "hideCertificates":
        if (value == "true") this.state.storeSettings.hideCertificates = false;
        else this.state.storeSettings.hideCertificates = true;
        break;
      case "hideTestimonials":
        if (value == "true") this.state.storeSettings.hideTestimonials = false;
        else this.state.storeSettings.hideTestimonials = true;
        break;
      case "hideClientLogos":
        if (value == "true") this.state.storeSettings.hideClientLogos = false;
        else this.state.storeSettings.hideClientLogos = true;
        break;
      case "hideVlogs":
        if (value == "true") this.state.storeSettings.hideVlogs = false;
        else this.state.storeSettings.hideVlogs = true;
        break;
      case "hideBlogs":
        if (value == "true") this.state.storeSettings.hideBlogs = false;
        else this.state.storeSettings.hideBlogs = true;
        break;

      //Sicial Media Links
      case "twitterUrl":
        this.state.storeSettings.twitterUrl = value;
        break;
      case "linkedinUrl":
        this.state.storeSettings.linkedinUrl = value;
        break;
      case "facebookUrl":
        this.state.storeSettings.facebookUrl = value;
        break;
      case "instagramUrl":
        this.state.storeSettings.instagramUrl = value;
        break;

      //Page Loading Time
      case "homePageLoadingTimeInSecond":
        this.state.storeSettings.homePageLoadingTimeInSecond = value;
        break;
      case "productListLoadingTimeInSecond":
        this.state.storeSettings.productListLoadingTimeInSecond = value;
        break;
      case "searchFuseThreshold":
        this.state.storeSettings.searchFuseThreshold = value;
        break;

      //PRODUCTS
      case "hideWhenOutOfStock":
        this.state.storeSettings.hideWhenOutOfStock = value;
        break;
      case "productQtyLimitToCart":
        this.state.storeSettings.productQtyLimitToCart = value;
        break;
      case "dontAddInstockInPurchasePo":
        this.state.storeSettings.dontAddInstockInPurchasePo = value;
        break;

      //Checkout
      case "hideCouponCodeField":
        this.state.storeSettings.hideCouponCodeField = value;
        break;
      case "hidePromotionCodeField":
        this.state.storeSettings.hidePromotionCodeField = value;
        break;
      case "showStorePickupOrHomeDelivery":
        this.state.storeSettings.showStorePickupOrHomeDelivery = value;
        break;

      //Orders
      case "whatsAppEnable":
        this.state.storeSettings.whatsAppEnable = value;
        break;
      case "whatsAppSecurityKey":
        this.state.storeSettings.whatsAppSecurityKey = value;
        break;
      case "whatsAppStoreImageFile":
        this.state.storeSettings.whatsAppStoreImageFile = value;
        break;
      case "whatsAppNewOrderConfirmationMsg":
        this.state.storeSettings.whatsAppNewOrderConfirmationMsg = value;
        break;
      case "whatsAppPaymentConfirmationMsg":
        this.state.storeSettings.whatsAppPaymentConfirmationMsg = value;
        break;
      case "whatsAppShipmentMsg":
        this.state.storeSettings.whatsAppShipmentMsg = value;
        break;
      case "whatsAppDeliveryConfirmationMsg":
        this.state.storeSettings.whatsAppDeliveryConfirmationMsg = value;
        break;
      case "whatsAppCancelOrderMsg":
        this.state.storeSettings.whatsAppCancelOrderMsg = value;
        break;

      //Payment Modes
      case "disableRazorPayPaymentMode":
        if (value == "true") {
          this.state.storeSettings.disableRazorPayPaymentMode = false;
        } else this.state.storeSettings.disableRazorPayPaymentMode = true;
        break;
      case "disablePhonePePaymentMode":
        if (value == "true")
          this.state.storeSettings.disablePhonePePaymentMode = false;
        else this.state.storeSettings.disablePhonePePaymentMode = true;
        break;
      case "disableCod":
        if (value == "true") this.state.storeSettings.disableCod = false;
        else this.state.storeSettings.disableCod = true;
        break;
      case "disableBankReceipt":
        if (value == "true")
          this.state.storeSettings.disableBankReceipt = false;
        else this.state.storeSettings.disableBankReceipt = true;
        break;
      case "bankReceiptDescriptionHtml":
        this.state.storeSettings.bankReceiptDescriptionHtml = value;
        break;
      case "upiId":
        this.state.storeSettings.upiId = value;
        break;
      case "upiPhoneNo":
        this.state.storeSettings.upiPhoneNo = value;
        break;

      //PhonePe Settings
      case "merchentId":
        this.state.storeSettings.merchentId = value;
        break;
      case "merchantUserId":
        this.state.storeSettings.merchantUserId = value;
        break;
      case "phonePeEnvUrl":
        this.state.storeSettings.phonePeEnvUrl = value;
        break;
      case "saltKey":
        this.state.storeSettings.saltKey = value;
        break;
      case "saltKeyIndex":
        this.state.storeSettings.saltKeyIndex = value;
        break;

      // Razorpay Settings
      case "razorpayKey":
        this.state.storeSettings.razorpayKey = value;
        break;
      case "razorpayKeySecret":
        this.state.storeSettings.razorpayKeySecret = value;
        break;
      case "razorpayCurrency":
        this.state.storeSettings.razorpayCurrency = value;
        break;
      case "razorpayCompanyName":
        this.state.storeSettings.razorpayCompanyName = value;
        break;
      case "razorpayCompanyDescription":
        this.state.storeSettings.razorpayCompanyDescription = value;
        break;
      case "razorpayCompanylogoFileName":
        this.state.storeSettings.razorpayCompanylogoFileName = value;
        break;
      case "razorpayCompanyAddress":
        this.state.storeSettings.razorpayCompanyAddress = value;
        break;
      case "razorpayThemeColor":
        this.state.storeSettings.razorpayThemeColor = value;
        break;

      //Invoice
      case "invoiceCompleteOrderMessage":
        this.state.storeSettings.invoiceCompleteOrderMessage = value;
        break;

      //Customer Support
      case "customerSupportDescription":
        this.state.storeSettings.customerSupportDescription = value;
        break;

      //Kiosk Settings
      case "kioskInvoiceCompleteOrderMessage":
        this.state.storeSettings.kioskInvoiceCompleteOrderMessage = value;
        break;
      case "kioskInvoicePageWaitTimeInMilliSeconds":
        this.state.storeSettings.kioskInvoicePageWaitTimeInMilliSeconds = value;
        break;

      //SEO
      case "seoHostDomainName":
        this.state.storeSettings.seoHostDomainName = value;
        break;
      case "seoTitle":
        this.state.storeSettings.seoTitle = value;
        break;
      case "seoDescription":
        this.state.storeSettings.seoDescription = value;
        break;
      case "seoKeywords":
        this.state.storeSettings.seoKeywords = value;
        break;
      case "seoGoogleSiteVerification":
        this.state.storeSettings.seoGoogleSiteVerification = value;
        break;
    }
    this.setState({ storeSettings: this.state.storeSettings });
  };

  GetStoreSettings = () => {
    const config = {
      headers: { Authorization: `Bearer ${this.context.userData.bearertoken}` },
    };
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetStoreSettings/" +
        this.context.storeId,
      config
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ storeSettings: data });
          this.context.setStoreSettings(data);
        }
      })
      .catch((error) => {
        this.ShowMessagBox("Warning", "Session Timeout!");
        this.context.clearUserData();
        this.setState({ sessionTimedout: true });
      });
  };

  UpdateStoreDefaults(data) {
    // this.state.storeSettings.storeName = data.storeName;
    //this.state.storeSettings.storeLogoFileName = data.storeLogoFileName;

    this.state.storeSettings.fromEmailAddress = data.fromEmailAddress;
    this.state.storeSettings.fromEmailAddressFirstName =
      data.fromEmailAddressFirstName;
    this.state.storeSettings.fromEmailAddressLastName =
      data.fromEmailAddressLastName;
    this.state.storeSettings.phoneNumberLength = data.phoneNumberLength;
    this.state.storeSettings.pincodeLength = data.pincodeLength;
    this.state.storeSettings.hideWhenOutOfStock = data.hideWhenOutOfStock;
    this.state.storeSettings.productQtyLimit = data.productQtyLimit;
    this.state.storeSettings.productCardWidth = data.productCardWidth;
    this.state.storeSettings.productCardHeight = data.productCardHeight;
    this.state.storeSettings.homePageLoadingTimeInSecond =
      data.homePageLoadingTimeInSecond;
    this.state.storeSettings.productListLoadingTimeInSecond =
      data.productListLoadingTimeInSecond;
    this.state.storeSettings.searchFuseThreshold = data.searchFuseThreshold;
    this.state.storeSettings.invoiceCompleteOrderMessage =
      data.invoiceCompleteOrderMessage;
    this.state.storeSettings.disableRazorPayPaymentMode =
      data.disableRazorPayPaymentMode;
    this.state.storeSettings.disablePhonePePaymentMode =
      data.disablePhonePePaymentMode;

    // this.state.storeSettings.razorpayKey = value;
    // this.state.storeSettings.razorpayKeySecret = value;
    // this.state.storeSettings.razorpayCurrency = value;
    // this.state.storeSettings.razorpayCompanyName = value;
    // this.state.storeSettings.razorpayCompanyDescription = value;
    // this.state.storeSettings.razorpayCompanylogoFileName = value;
    // this.state.storeSettings.razorpayCompanyAddress = value;
    // this.state.storeSettings.razorpayThemeColor = value;

    this.state.storeSettings.customerSupportDescription =
      data.customerSupportDescription;
    this.setState({ storeSettings: this.state.storeSettings });
  }

  GetDefaultStoreSettings = () => {
    const config = {
      headers: { Authorization: `Bearer ${this.context.userData.bearertoken}` },
    };
    fetch(
      process.env.REACT_APP_API + "StoreManager/GetStoreDefaultSettings/",
      config
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.UpdateStoreDefaults(data);
        }
      })
      .catch((error) => {
        this.ShowMessagBox("Warning", "Session Timeout!");
        this.context.clearUserData();
        this.setState({ sessionTimedout: true });
      });
  };

  OnSave = (e) => {
    e.preventDefault();
    this.ShowLoadSpinner();

    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.context.userData.bearertoken}`,
      },
      body: JSON.stringify(this.state.storeSettings),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/UpdateStoreSettings/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.GetStoreSettings();
          this.HideLoadSpinner();
          this.ShowMessagBox("Information", "Saved Successfully!");
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed!");
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed! " + error);
      });
  };

  handleThemeTypeChange = (event) => {
    const { name, value } = event.target;
    switch (event.target.id) {
      case "productTheme":
        if (value == "on") {
          this.state.storeSettings.selectedThemeType = "Product";
        }
        break;
      case "customTheme":
        if (value == "on") {
          this.state.storeSettings.selectedThemeType = "Custom";
        }
        break;
    }
    this.setState({ storeSettings: this.state.storeSettings });
  };
  handleOfflineChange = (checked) => {
    this.state.storeSettings.offline = !checked;
    this.setState({ storeSettings: this.state.storeSettings });
  };

  componentDidMount = () => {
    if (this.state.storeSettings == "") this.GetStoreSettings();
    window.scrollTo(0, 0);
  };

  render() {
    if (this.state.sessionTimedout == true) {
      return <Navigate to="/UserLogin" />;
    }
    return (
      <>
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        {this.state.msgboxOpen === true ? (
          <MessageBox
            open={this.state.msgboxOpen}
            title={this.state.msgboxTitle}
            message={this.state.msgboxMessage}
            callback={this.CloseMessagBoxCallback}
          />
        ) : (
          ""
        )}

        <div className="Container-fluid p-1 ">
          <Row>
            <Col className="" xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="pagebackground p-0">
              <Form onSubmit={this.OnSave}>
                {this.state.msgboxOpen === true ? (
                  <MessageBox
                    open={this.state.msgboxOpen}
                    title={this.state.msgboxTitle}
                    message={this.state.msgboxMessage}
                    callback={this.CloseMessagBoxCallback}
                  />
                ) : (
                  ""
                )}
                <Navbar
                  expand="lg"
                  className="secondarythemecolor position-sticky fixed-top  px-3"
                >
                  <h4> &nbsp; &nbsp;Store Settings &nbsp;</h4>
                  <Navbar.Collapse className="justify-content-end mx-0 ">
                    <Nav>
                      <ButtonToolbar className="text-end align-middle">
                        <Button
                          className="mainthemeColorCode"
                          size=""
                          // onClick={(e) => {
                          //   this.OnSave(e);
                          // }}
                          type="submit"
                        >
                          <BiSave size="26" />
                          Save
                        </Button>
                        &nbsp;
                        <Button
                          className="mainthemeColorCode"
                          size=""
                          onClick={() => this.OnRefreshButtonClick()}
                        >
                          <BiRefresh size="26"></BiRefresh>
                          Undo
                        </Button>
                        &nbsp;
                        <Button
                          className="mainthemeColorCode"
                          size=""
                          disabled
                          onClick={() => this.GetDefaultStoreSettings()}
                        >
                          <BiRefresh size="26"></BiRefresh>
                          Reset to Store Default
                        </Button>
                      </ButtonToolbar>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
                <Tabs
                  id="controlled-tab-example"
                  activeKey={this.state.key}
                  onSelect={(k) => this.setState({ key: k })}
                  className="mb-3"
                >
                  <Tab eventKey="home" title="Store & Email">
                    <div className="border p-3">
                      <h4> Store Basic Details </h4>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Store Name
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="storeName"
                            value={this.state.storeSettings.storeName}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <div className="border p-3">
                        <h4>Store Status</h4>
                        <Form.Group as={Row} className="mb-1 " controlId="">
                          <Form.Label column sm="6">
                            Store Online?
                          </Form.Label>
                          <Col sm="6">
                            <Switch
                              height={20}
                              onChange={this.handleOfflineChange}
                              checked={!this.state.storeSettings.offline}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1 " controlId="">
                          <Form.Label column sm="6">
                            Open Time:
                          </Form.Label>
                          <Col sm="6">
                            <TimePicker
                              amPmAriaLabel="Select AM/PM"
                              format="h:mm a"
                              onChange={(t) => {
                                this.state.storeSettings.openStoreTime = t;
                                this.setState({
                                  storeSettings: this.state.storeSettings,
                                });
                              }}
                              value={
                                this.state.storeSettings.openStoreTime ==
                                undefined
                                  ? "0:0"
                                  : this.state.storeSettings.openStoreTime
                              }
                            />
                          </Col>
                        </Form.Group>

                        <Form.Group as={Row} className="mb-1 " controlId="">
                          <Form.Label column sm="6">
                            Close Time
                          </Form.Label>
                          <Col sm="6">
                            <TimePicker
                              amPmAriaLabel="Select AM/PM"
                              format="h:mm a"
                              onChange={(t) => {
                                this.state.storeSettings.closeStoreTime = t;
                                this.setState({
                                  storeSettings: this.state.storeSettings,
                                });
                              }}
                              value={
                                this.state.storeSettings.closeStoreTime ==
                                undefined
                                  ? "23:59"
                                  : this.state.storeSettings.closeStoreTime
                              }
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1 " controlId="">
                          <Form.Label column sm="6">
                            Offline Notes (in html)
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              as="textarea"
                              type="text"
                              rows={4}
                              name="offlineNote"
                              value={this.state.storeSettings.offlineNote}
                              required
                              placeholder=""
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e)}
                            />
                          </Col>
                        </Form.Group>
                      </div>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          GSTIN
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="gstNumber"
                            value={this.state.storeSettings.gstNumber}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Store Address
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            as="textarea"
                            type="text"
                            rows={4}
                            name="storeAddress"
                            value={this.state.storeSettings.storeAddress}
                            required
                            placeholder=""
                            maxLength={299}
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Store EmailId
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="email"
                            name="storeEmailId"
                            value={this.state.storeSettings.storeEmailId}
                            // required
                            placeholder=""
                            autoComplete="off"
                            maxLength={49}
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Store Phone Number
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="number"
                            name="storePhoneNo"
                            value={this.state.storeSettings.storePhoneNo}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Store WhatsApp Number
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="number"
                            name="storeWhatsappNo"
                            value={this.state.storeSettings.storeWhatsappNo}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Home Delivery DomainAddress
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="homeDeliveryDomainAddress"
                            value={
                              this.state.storeSettings.homeDeliveryDomainAddress
                            }
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Home Delivery ContactNumber
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="homeDeliveryContactNumber"
                            value={
                              this.state.storeSettings.homeDeliveryContactNumber
                            }
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Store Logo FileName
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="storeLogoFileName"
                            value={this.state.storeSettings.storeLogoFileName}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            {this.context.images.map((r) => (
                              <option value={r.name}>{r.name}</option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Disble Beep Sound on New Order
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="disableNewOrderBeepSound"
                            value={
                              this.state.storeSettings.disableNewOrderBeepSound
                            }
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </div>

                    <div className="border p-3">
                      <h4>Customer Portal Home Page</h4>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Biz Summary
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            as="textarea"
                            type="text"
                            rows={3}
                            name="homePageBizSummary"
                            value={this.state.storeSettings.homePageBizSummary}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Store AppIcon FileName
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="favicon"
                            value={this.state.storeSettings.favicon}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            {this.context.images.map((r) => (
                              <option value={r.name}>{r.name}</option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Google map Embed Url
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="googlemapEmbedUrl"
                            value={this.state.storeSettings.googlemapEmbedUrl}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                    <div className="border p-3">
                      <h4>BannerVideo</h4>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Banner Video File name
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="bannerVideoFileName"
                            value={this.state.storeSettings.bannerVideoFileName}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            {this.context.images.map((r) => (
                              <option value={r.name}>{r.name}</option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Banner Video Center Text
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="bannerVideoCenterMessage"
                            value={
                              this.state.storeSettings.bannerVideoCenterMessage
                            }
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Banner Video Bottom Text
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="bannerVideoBottomMessage"
                            value={
                              this.state.storeSettings.bannerVideoBottomMessage
                            }
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                    <div className="border p-3">
                      <h4>Email</h4>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          From Email Address
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="email"
                            name="fromEmailAddress"
                            value={this.state.storeSettings.fromEmailAddress}
                            required
                            // disabled
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          First Name
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="fromEmailAddressFirstName"
                            value={
                              this.state.storeSettings.fromEmailAddressFirstName
                            }
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          LastName
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="fromEmailAddressLastName"
                            value={
                              this.state.storeSettings.fromEmailAddressLastName
                            }
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                    <div className="p-3 border">
                      <h4>Defaults & Limits</h4>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Phone Number Length
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="number"
                            name="phoneNumberLength"
                            value={this.state.storeSettings.phoneNumberLength}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Pincode Length
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="number"
                            name="pincodeLength"
                            value={this.state.storeSettings.pincodeLength}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                    <div className="border p-3">
                      <h4>Customer Portal Flags</h4>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Show Display Counters
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="hideDisplayCounters"
                            value={
                              this.state.storeSettings.hideDisplayCounters ===
                              true
                                ? false
                                : true
                            }
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Show Certificates
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="hideCertificates"
                            value={
                              this.state.storeSettings.hideCertificates === true
                                ? false
                                : true
                            }
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value={true}>true</option>
                            <option value={false}>false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Show Testimonials
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="hideTestimonials"
                            value={
                              this.state.storeSettings.hideTestimonials === true
                                ? false
                                : true
                            }
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Show Client Logos
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="hideClientLogos"
                            value={
                              this.state.storeSettings.hideClientLogos === true
                                ? false
                                : true
                            }
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Show Vlogs
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="hideVlogs"
                            value={
                              this.state.storeSettings.hideVlogs === true
                                ? false
                                : true
                            }
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Show Blogs
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="hideBlogs"
                            value={
                              this.state.storeSettings.hideBlogs === true
                                ? false
                                : true
                            }
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </div>
                    <div className="border p-3">
                      <h4>Social Media Links</h4>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Twitter
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="twitterUrl"
                            value={this.state.storeSettings.twitterUrl}
                            maxLength={299}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          LinkedIn
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="linkedinUrl"
                            value={this.state.storeSettings.linkedinUrl}
                            maxLength={299}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Facebook Url
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="facebookUrl"
                            value={this.state.storeSettings.facebookUrl}
                            maxLength={299}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Instagram Url
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="instagramUrl"
                            value={this.state.storeSettings.instagramUrl}
                            maxLength={299}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                  </Tab>
                  <Tab eventKey="POS" title="POS">
                    <div className="border p-3">
                      <h4>POS Settings</h4>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Reset orderNumber Daily from 1
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="orderNumberDailyReset"
                            value={
                              this.state.storeSettings.orderNumberDailyReset
                            }
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          2nd Language Name
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="language2ndName"
                            value={this.state.storeSettings.language2ndName}
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Header On POS Invoice
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="header"
                            value={this.state.storeSettings.header}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Footer On POS Invoice
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="footer"
                            value={this.state.storeSettings.footer}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Print PosBills In 2nd Language
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="printPosBillIn2NdLanguage"
                            value={
                              this.state.storeSettings.printPosBillIn2NdLanguage
                            }
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Hide MyOrders in POS
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="hideMyOrdersinPos"
                            value={this.state.storeSettings.hideMyOrdersinPos}
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Hide CancelOrders in POS
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="hideCancelOrderinPos"
                            value={
                              this.state.storeSettings.hideCancelOrderinPos
                            }
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Hide TakeAway Order in POS
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="hideTakeAwayOptionOnPos"
                            value={
                              this.state.storeSettings.hideTakeAwayOptionOnPos
                            }
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Hide HomeDelivery Order in POS
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="hideHomeDeliveryOptionOnPos"
                            value={
                              this.state.storeSettings
                                .hideHomeDeliveryOptionOnPos
                            }
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Disable Deleting OrderItems to Waiters
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="disableDeleteOrderItemsOnTableOrder"
                            value={
                              this.state.storeSettings
                                .disableDeleteOrderItemsOnTableOrder
                            }
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </div>
                  </Tab>
                  <Tab eventKey="country" title="Country & Currency">
                    <div className="p-3 border">
                      <h4>Country & Currency</h4>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Default Country
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="defaultCountry"
                            value={this.state.storeSettings.defaultCountry}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            {Country.getAllCountries().map((country) => (
                              <>
                                <option value={country.isoCode}>
                                  {country.name}
                                </option>
                              </>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Default State
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="defaultState"
                            value={this.state.storeSettings.defaultState}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            {State.getStatesOfCountry(
                              this.state.storeSettings.defaultCountry
                            ).map((s1) => (
                              <>
                                <option>{s1.name}</option>
                              </>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Default Locale
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="defaultLocale"
                            value={this.state.storeSettings.defaultLocale}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Default Currency
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="defaultCurrency"
                            value={this.state.storeSettings.defaultCurrency}
                            required
                            // disabled
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="INR">INR</option>
                            <option value="USD">USD</option>
                            <option value="PGK">PGK</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Default Country Code
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="defaultCountryCode"
                            value={this.state.storeSettings.defaultCountryCode}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          DisableCountrySelection
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="disableCountrySelection"
                            value={
                              this.state.storeSettings.disableCountrySelection
                            }
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          DisableStateSelection
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="disableStateSelection"
                            value={
                              this.state.storeSettings.disableStateSelection
                            }
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Multi Country & Currency Support
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="multiCurrencySupport"
                            value={
                              this.state.storeSettings.multiCurrencySupport
                            }
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </div>
                  </Tab>
                  <Tab eventKey="themes" title="Themes & Performance">
                    <div className="border p-3">
                      <h4>Store Theme</h4>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Select Theme Type
                        </Form.Label>
                        <Col sm="6">
                          <Table>
                            <Row>
                              <Col>
                                <Form.Check
                                  className=""
                                  type="radio"
                                  checked={
                                    this.state.storeSettings
                                      .selectedThemeType == "Product"
                                      ? "true"
                                      : ""
                                  }
                                  name="productTheme"
                                  label="Product Theme"
                                  id="productTheme"
                                  onChange={this.handleThemeTypeChange}
                                ></Form.Check>
                              </Col>
                              <Col>
                                <Form.Check
                                  className=""
                                  type="radio"
                                  checked={
                                    this.state.storeSettings
                                      .selectedThemeType == "Custom"
                                      ? "true"
                                      : ""
                                  }
                                  name="customTheme"
                                  label="Custom Theme"
                                  id="customTheme"
                                  onChange={this.handleThemeTypeChange}
                                ></Form.Check>
                              </Col>
                            </Row>
                          </Table>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-1 "
                        controlId=""
                        hidden={
                          this.state.storeSettings.selectedThemeType !=
                          "Product"
                            ? true
                            : false
                        }
                      >
                        <Form.Label column sm="6">
                          Product Theme
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="themeName"
                            value={this.state.storeSettings.themeName}
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value={""}></option>
                            {this.context.productThemes.map((theme) => (
                              <option value={theme.themeName}>
                                {theme.themeName}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group
                        as={Row}
                        className="mb-1 "
                        controlId=""
                        hidden={
                          this.state.storeSettings.selectedThemeType != "Custom"
                            ? true
                            : false
                        }
                      >
                        <Form.Label column sm="6">
                          Custom Theme
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="customThemeName"
                            value={this.state.storeSettings.customThemeName}
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value={""}></option>
                            {this.context.themes.map((theme) => (
                              <option value={theme.themeName}>
                                {theme.themeName}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </div>
                    <div className="border p-3">
                      <h4>Pages Loading Time</h4>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Retail Portal LoadingTime InSecond
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="number"
                            min={0}
                            name="homePageLoadingTimeInSecond"
                            value={
                              this.state.storeSettings
                                .homePageLoadingTimeInSecond
                            }
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          ProductList LoadingTime InSecond
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="number"
                            min={0}
                            name="productListLoadingTimeInSecond"
                            value={
                              this.state.storeSettings
                                .productListLoadingTimeInSecond
                            }
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Search Threshold [Fuse]
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            min={0}
                            name="searchFuseThreshold"
                            value={this.state.storeSettings.searchFuseThreshold}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                  </Tab>
                  <Tab eventKey="products" title="Products & Checkouts">
                    <div className="border p-3">
                      <h4>PRODUCTS</h4>

                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Show Products when Out-of-Stock
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="hideWhenOutOfStock"
                            value={!this.state.storeSettings.hideWhenOutOfStock}
                            required
                            disabled
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Max Product Quantity's Limit in Cart
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="number"
                            min={0}
                            name="productQtyLimitToCart"
                            value={
                              this.state.storeSettings.productQtyLimitToCart
                            }
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                    <div className="border p-3">
                      <h4>PRODUCT Purchases</h4>

                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Dont Add Instock to Inventory on Product PO
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="dontAddInstockInPurchasePo"
                            value={
                              this.state.storeSettings
                                .dontAddInstockInPurchasePo
                            }
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </div>
                    <div className="border p-3">
                      <h4>CheckOut </h4>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Show CouponCode Field
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="hideCouponCodeField"
                            value={
                              !this.state.storeSettings.hideCouponCodeField
                            }
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Show PromotionCode Field
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="hidePromotionCodeField"
                            value={
                              !this.state.storeSettings.hidePromotionCodeField
                            }
                            required
                            disabled
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Show DeliveryMethod
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            disabled
                            name="showStorePickupOrHomeDelivery"
                            value={
                              this.state.storeSettings
                                .showStorePickupOrHomeDelivery
                            }
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="false">false</option>
                            <option value="true">true</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </div>
                    <div className="border p-3">
                      <h4>Invoice </h4>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Invoice Completed Order Message
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            as="textarea"
                            type="text"
                            rows={4}
                            name="invoiceCompleteOrderMessage"
                            value={
                              this.state.storeSettings
                                .invoiceCompleteOrderMessage
                            }
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                  </Tab>
                  <Tab eventKey="payment" title="Payment">
                    <div className="border p-3">
                      <h4>Payment Modes </h4>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Show RazorPay PaymentMode
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="disableRazorPayPaymentMode"
                            value={
                              !this.state.storeSettings
                                .disableRazorPayPaymentMode
                            }
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Show PhonePe PaymentMode
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="disablePhonePePaymentMode"
                            value={
                              !this.state.storeSettings
                                .disablePhonePePaymentMode
                            }
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Show Cash on Delivery(COD)
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="disableCod"
                            value={!this.state.storeSettings.disableCod}
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Show Bank Receipt
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="disableBankReceipt"
                            value={!this.state.storeSettings.disableBankReceipt}
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          UPI-ID
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="upiId"
                            maxLength={99}
                            value={this.state.storeSettings.upiId}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          UPI Phone Number
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="upiPhoneNo"
                            maxLength={99}
                            value={this.state.storeSettings.upiPhoneNo}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                    <div className="border p-3">
                      <h4>PhonePe Payment Gatway Settings </h4>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          MerchentId
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="merchentId"
                            value={this.state.storeSettings.merchentId}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Merchant UserId
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="merchantUserId"
                            value={this.state.storeSettings.merchantUserId}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          PhonePe Environment URL
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="phonePeEnvUrl"
                            value={this.state.storeSettings.phonePeEnvUrl}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Salt Key
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="saltKey"
                            value={this.state.storeSettings.saltKey}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Salt Key Index Number
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="saltKeyIndex"
                            value={this.state.storeSettings.saltKeyIndex}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                    <div className="border p-3">
                      <h4>RazorPay </h4>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Razorpay Key
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="razorpayKey"
                            value={this.state.storeSettings.razorpayKey}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Razorpay KeySecret
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="razorpayKeySecret"
                            value={this.state.storeSettings.razorpayKeySecret}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Currency
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="razorpayCurrency"
                            value={this.state.storeSettings.razorpayCurrency}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Company Name
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="razorpayCompanyName"
                            value={this.state.storeSettings.razorpayCompanyName}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Company Description
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="razorpayCompanyDescription"
                            value={
                              this.state.storeSettings
                                .razorpayCompanyDescription
                            }
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Companylogo FileName
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="razorpayCompanylogoFileName"
                            value={
                              this.state.storeSettings
                                .razorpayCompanylogoFileName
                            }
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            {this.context.images.map((r) => (
                              <option value={r.name}>{r.name}</option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          Company Address
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="razorpayCompanyAddress"
                            value={
                              this.state.storeSettings.razorpayCompanyAddress
                            }
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          themeColorCode
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="razorpayThemeColor"
                            value={this.state.storeSettings.razorpayThemeColor}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                    <div className="border p-3">
                      <h4>Bank Receipt description in HTML </h4>
                      <Form.Control
                        as="textarea"
                        type="text"
                        rows={10}
                        name="bankReceiptDescriptionHtml"
                        maxLength={4999}
                        value={
                          this.state.storeSettings.bankReceiptDescriptionHtml
                        }
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </div>
                  </Tab>
                  <Tab eventKey="Orders" title="Orders">
                    <div className="border p-3">
                      <h4>WhatsApp Settings </h4>
                      <i className="text-secondary">
                        Note: On the below Templates, you can use OrderNo,
                        ShipmentDetails, InvoiceFileName fields within double
                        angle braces.
                      </i>
                      <br />
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Enable WhatsApp for Order Notifications
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="whatsAppEnable"
                            value={this.state.storeSettings.whatsAppEnable}
                            required
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            <option value="true">true</option>
                            <option value="false">false</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          WhatsApp Security Key
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            as="textarea"
                            type="text"
                            rows={1}
                            name="whatsAppSecurityKey"
                            value={this.state.storeSettings.whatsAppSecurityKey}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="6">
                          WhatsApp Attachment ImageFile
                        </Form.Label>
                        <Col sm="6">
                          <Form.Select
                            type="text"
                            name="whatsAppStoreImageFile"
                            value={
                              this.state.storeSettings.whatsAppStoreImageFile
                            }
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            {this.context.images.map((r) => (
                              <option value={r.name}>{r.name}</option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          New Order Received Message Template
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            as="textarea"
                            type="text"
                            rows={4}
                            name="whatsAppNewOrderConfirmationMsg"
                            value={
                              this.state.storeSettings
                                .whatsAppNewOrderConfirmationMsg
                            }
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Payment Confirmation Message Template
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            as="textarea"
                            type="text"
                            rows={4}
                            name="whatsAppPaymentConfirmationMsg"
                            value={
                              this.state.storeSettings
                                .whatsAppPaymentConfirmationMsg
                            }
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Shipment Message Template
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            as="textarea"
                            type="text"
                            rows={4}
                            name="whatsAppShipmentMsg"
                            value={this.state.storeSettings.whatsAppShipmentMsg}
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Delivery Message Template
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            as="textarea"
                            type="text"
                            rows={4}
                            name="whatsAppDeliveryConfirmationMsg"
                            value={
                              this.state.storeSettings
                                .whatsAppDeliveryConfirmationMsg
                            }
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Cancel Order Message Template
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            as="textarea"
                            type="text"
                            rows={4}
                            name="whatsAppCancelOrderMsg"
                            value={
                              this.state.storeSettings.whatsAppCancelOrderMsg
                            }
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                  </Tab>
                  <Tab eventKey="support" title="Customer Support">
                    <div className="border p-3">
                      <h4>Customer Support </h4>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Customer Support Description
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            as="textarea"
                            type="text"
                            rows={8}
                            name="customerSupportDescription"
                            value={
                              this.state.storeSettings
                                .customerSupportDescription
                            }
                            required
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                  </Tab>
                  <Tab eventKey="kiosk" title="Kiosk">
                    <div className="border p-3">
                      <h4>Kiosk </h4>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Invoice Generated Description
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            as="textarea"
                            type="text"
                            rows={5}
                            name="kioskInvoiceCompleteOrderMessage"
                            value={
                              this.state.storeSettings
                                .kioskInvoiceCompleteOrderMessage
                            }
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Invoice Page Waiting Time in Milliseconds
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="number"
                            name="kioskInvoicePageWaitTimeInMilliSeconds"
                            value={
                              this.state.storeSettings
                                .kioskInvoicePageWaitTimeInMilliSeconds
                            }
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                  </Tab>
                  <Tab eventKey="seo" title="SEO">
                    <div className="border p-3">
                      <h4>SEO </h4>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Domain Name
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            name="seoHostDomainName"
                            value={this.state.storeSettings.seoHostDomainName}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Store Title
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            maxLength={99}
                            name="seoTitle"
                            value={this.state.storeSettings.seoTitle}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Store Description
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            as="textarea"
                            type="text"
                            rows={5}
                            maxLength={499}
                            name="seoDescription"
                            value={this.state.storeSettings.seoDescription}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Store Keywords
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            as="textarea"
                            type="text"
                            rows={5}
                            maxLength={499}
                            name="seoKeywords"
                            value={this.state.storeSettings.seoKeywords}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1" controlId="">
                        <Form.Label column sm="6">
                          Google-Site-Verfication Code
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            maxLength={199}
                            name="seoGoogleSiteVerification"
                            value={
                              this.state.storeSettings.seoGoogleSiteVerification
                            }
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </div>
                  </Tab>
                  {this.context.UserRoles.PRODUCTADMIN ==
                  this.context.userData.roleId ? (
                    <Tab eventKey="ProductSupport" title="ProductSupport">
                      <div className="border p-3">
                        <h4>ProductSupport </h4>
                        <Form.Group as={Row} className="mb-1" controlId="">
                          <Form.Label column sm="6">
                            Login Method
                          </Form.Label>
                          <Col sm="6">
                            <Form.Select
                              type="text"
                              name="loginMethod"
                              value={this.state.storeSettings.loginMethod}
                              required
                              placeholder=""
                              onChange={(e) => this.handleInputChange(e)}
                            >
                              <option value="EMAIL">EMAIL</option>
                              <option value="SMS">SMS</option>
                            </Form.Select>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1" controlId="">
                          <Form.Label column sm="6">
                            Live Mode?
                          </Form.Label>
                          <Col sm="6">
                            <Form.Select
                              type="text"
                              name="liveMode"
                              value={this.state.storeSettings.liveMode}
                              required
                              placeholder=""
                              onChange={(e) => this.handleInputChange(e)}
                            >
                              <option value={true}>LIVE</option>
                              <option value={false}>TEST</option>
                            </Form.Select>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1" controlId="">
                          <Form.Label column sm="6">
                            Total Admin users
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              type="number"
                              name="totalErpAdminUsers"
                              value={
                                this.state.storeSettings.totalErpAdminUsers
                              }
                              placeholder=""
                              onChange={(e) => this.handleInputChange(e)}
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1" controlId="">
                          <Form.Label column sm="6">
                            Total Contributor users
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              type="number"
                              name="totalErpContributorUsers"
                              value={
                                this.state.storeSettings
                                  .totalErpContributorUsers
                              }
                              placeholder=""
                              onChange={(e) => this.handleInputChange(e)}
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1" controlId="">
                          <Form.Label column sm="6">
                            Total POS users
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              type="number"
                              name="totalPosUsers"
                              value={this.state.storeSettings.totalPosUsers}
                              placeholder=""
                              onChange={(e) => this.handleInputChange(e)}
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1" controlId="">
                          <Form.Label column sm="6">
                            POS License Key
                          </Form.Label>
                          <Col sm="6">
                            <Form.Control
                              type="text"
                              name="posLicenseKey"
                              value={this.state.storeSettings.posLicenseKey}
                              placeholder=""
                              onChange={(e) => this.handleInputChange(e)}
                            ></Form.Control>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1" controlId="">
                          <Form.Label column sm="6">
                            IS Manufacturer ?
                          </Form.Label>
                          <Col sm="6">
                            <Form.Select
                              type="text"
                              name="isManufacturer"
                              value={this.state.storeSettings.isManufacturer}
                              required
                              placeholder=""
                              onChange={(e) => this.handleInputChange(e)}
                            >
                              <option value=""></option>
                              <option value={true}>true</option>
                              <option value={false}>false</option>
                            </Form.Select>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1" controlId="">
                          <Form.Label column sm="6">
                            IS Quotation Request Supported ?
                          </Form.Label>
                          <Col sm="6">
                            <Form.Select
                              type="text"
                              name="isQuotationSupported"
                              value={
                                this.state.storeSettings.isQuotationSupported
                              }
                              required
                              placeholder=""
                              onChange={(e) => this.handleInputChange(e)}
                            >
                              <option value=""></option>
                              <option value={true}>true</option>
                              <option value={false}>false</option>
                            </Form.Select>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1" controlId="">
                          <Form.Label column sm="6">
                            IS Servicing Biz ?
                          </Form.Label>
                          <Col sm="6">
                            <Form.Select
                              type="text"
                              name="isServiceManagementBusiness"
                              value={
                                this.state.storeSettings
                                  .isServiceManagementBusiness
                              }
                              required
                              placeholder=""
                              onChange={(e) => this.handleInputChange(e)}
                            >
                              <option value=""></option>
                              <option value={true}>true</option>
                              <option value={false}>false</option>
                            </Form.Select>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1" controlId="">
                          <Form.Label column sm="6">
                            IS Reseller ?
                          </Form.Label>
                          <Col sm="6">
                            <Form.Select
                              type="text"
                              name="isReseller"
                              value={this.state.storeSettings.isReseller}
                              required
                              placeholder=""
                              onChange={(e) => this.handleInputChange(e)}
                            >
                              <option value=""></option>
                              <option value={true}>true</option>
                              <option value={false}>false</option>
                            </Form.Select>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1" controlId="">
                          <Form.Label column sm="6">
                            Show OrdersManagement
                          </Form.Label>
                          <Col sm="6">
                            <Form.Select
                              type="text"
                              name="showOrdersManagement"
                              value={
                                this.state.storeSettings.showOrdersManagement
                              }
                              required
                              placeholder=""
                              onChange={(e) => this.handleInputChange(e)}
                            >
                              <option value=""></option>
                              <option value={true}>true</option>
                              <option value={false}>false</option>
                            </Form.Select>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1" controlId="">
                          <Form.Label column sm="6">
                            Show Product Inventory Module
                          </Form.Label>
                          <Col sm="6">
                            <Form.Select
                              type="text"
                              name="showProductInventoryModule"
                              value={
                                this.state.storeSettings
                                  .showProductInventoryModule
                              }
                              required
                              placeholder=""
                              onChange={(e) => this.handleInputChange(e)}
                            >
                              <option value=""></option>
                              <option value={true}>true</option>
                              <option value={false}>false</option>
                            </Form.Select>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1" controlId="">
                          <Form.Label column sm="6">
                            Show Purchase Module
                          </Form.Label>
                          <Col sm="6">
                            <Form.Select
                              type="text"
                              name="showPurchaseModule"
                              value={
                                this.state.storeSettings.showPurchaseModule
                              }
                              required
                              placeholder=""
                              onChange={(e) => this.handleInputChange(e)}
                            >
                              <option value=""></option>
                              <option value={true}>true</option>
                              <option value={false}>false</option>
                            </Form.Select>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1" controlId="">
                          <Form.Label column sm="6">
                            Show HRM Module
                          </Form.Label>
                          <Col sm="6">
                            <Form.Select
                              type="text"
                              name="showHrmModule "
                              value={this.state.storeSettings.showHrmModule}
                              required
                              placeholder=""
                              onChange={(e) => this.handleInputChange(e)}
                            >
                              <option value=""></option>
                              <option value={true}>true</option>
                              <option value={false}>false</option>
                            </Form.Select>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1" controlId="">
                          <Form.Label column sm="6">
                            Show Fiance Module
                          </Form.Label>
                          <Col sm="6">
                            <Form.Select
                              type="text"
                              name="showFinanceModule "
                              value={this.state.storeSettings.showFinanceModule}
                              required
                              placeholder=""
                              onChange={(e) => this.handleInputChange(e)}
                            >
                              <option value=""></option>
                              <option value={true}>true</option>
                              <option value={false}>false</option>
                            </Form.Select>
                          </Col>
                        </Form.Group>
                      </div>
                    </Tab>
                  ) : (
                    <></>
                  )}
                </Tabs>
                <div>
                  <Row
                    className="m-2 p-2 bg-light border"
                    style={{ fontSize: "9pt" }}
                  >
                    <Col md={6} className=""></Col>
                    <Col md={6} className=""></Col>
                  </Row>
                </div>
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
