import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiRefresh } from "react-icons/bi";
import { BsFillPencilFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import DatePicker from "react-date-picker";
import DateTimePicker from "react-datetime-picker";
// import "react-datetime-picker/dist/DateTimePicker.css";
// import "react-calendar/dist/Calendar.css";
// import "react-clock/dist/Clock.css";
import "../CSS/DateTimePicker.css";
import "../CSS/Calendar.css";
import "../CSS/Clock.css";
import Fuse from "fuse.js";
import { ProductServiceCustomerAddOrEditModal } from "./ProductServiceCustomers";

import {
  InputGroup,
  OverlayTrigger,
  FormControl,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Modal,
  Nav,
  Table,
  Navbar,
  Stack,
} from "react-bootstrap";

export class ProductServiceBookings extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      productservicebookings: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      reload: true,
      firstload: false,
      searchData: [],
      searchquery: "",
      BookingDate: new Date(),
      BookingStatus: "",
    };
  }
  onSearchChange = (query) => {
    this.setState({ reload: false });
    this.setState({ searchquery: query });
    if (!query) {
      this.setState({ searchData: this.state.productservicebookings }, () => {
        this.setState({ reload: true });
      });
      return;
    }
    const fuse = new Fuse(this.state.searchData, {
      keys: ["firstName", "lastName", "mobileNo"],
      threshold: 0.6,
    });
    const result = fuse.search(query);
    const finalResult = [];
    if (result.length) {
      result.forEach((item) => {
        finalResult.push(item.item);
      });
      this.setState({ searchData: finalResult.slice(0, 15) }, () => {
        this.setState({ reload: true });
      });
    } else {
      this.setState({ searchData: [] });
    }
  };
  GetProductServiceBookings = () => {
    fetch(
      process.env.REACT_APP_API +
        "ManufactureAndService/GetProductServiceBookings/" +
        this.context.storeId +
        "/" +
        this.context.GetTicks(this.state.BookingDate)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ productservicebookings: data }, () => {
            this.setState({ searchData: data });
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnSaved = () => {
    this.OnRefreshButtonClick();
  };
  OnDeleted = () => {
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.setState({ productservicebookings: [] }, () => {
      this.GetProductServiceBookings();
    });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  OnUpdated = (indexno) => {
    this.OnRefreshButtonClick();
  };

  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
    this.OnRefreshButtonClick();
  };
  onBookingForChange = (date) => {
    if (date == null) {
      this.setState({ BookingDate: new Date() });
      return;
    }

    this.setState({ BookingDate: date }, () => {
      this.GetProductServiceBookings();
    });
  };

  OnBookingStatusChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "bookingstatus":
        this.setState({ BookingStatus: value }, () => {
          this.setState({ reload: false }, () => {
            this.setState({ reload: true });
          });
        });
        break;
    }
  };

  componentDidMount = () => {
    if (this.state.firstload == false) {
      this.state.firstload = true;
      this.GetProductServiceBookings();
    }
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <>
        <div className="Container-fluid p-1 ">
          <Row>
            <Col className="" xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="pagebackground p-0 m-0">
              {this.state.msgboxOpen === true ? (
                <MessageBox
                  open={this.state.msgboxOpen}
                  title={this.state.msgboxTitle}
                  message={this.state.msgboxMessage}
                  callback={this.CloseMessagBoxCallback}
                />
              ) : (
                ""
              )}
              <Navbar
                // expand="lg"
                className="secondarythemecolor position-sticky fixed-top  m-0 px-3 py-1"
              >
                <h5>
                  {" "}
                  &nbsp; &nbsp;<b>Service Booking</b> &nbsp;
                </h5>
                <Navbar.Collapse className="justify-content-end m-0 p-0 ">
                  <Nav className="m-0 p-0">
                    <ButtonToolbar className="text-end align-middle m-0 p-0">
                      <Button
                        className="mainthemecolor"
                        size="sm"
                        // onClick={() => this.handleAddProductServiceBooking()}
                        onClick={() => this.setState({ addModalShow: true })}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size="sm"
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Refresh
                      </Button>
                      &nbsp;
                      {this.state.addModalShow === true ? (
                        <ProductServiceBookingAddOrEditModal
                          show={this.state.addModalShow}
                          Type="Add"
                          onHide={this.addModalClose}
                        />
                      ) : null}
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div>
                <Table className="bg-light m-0 p-0">
                  <tr className="border-0">
                    <td width="45%">
                      <Stack direction="horizontal" gap={3} className="">
                        <b>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            fill="currentColor"
                            class="bi bi-search"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                          </svg>
                        </b>
                        <FormControl
                          type="search"
                          placeholder="Search... "
                          className="text-primary fw-bold bg-white "
                          size="lg"
                          aria-label="Search"
                          onChange={(e) => this.onSearchChange(e.target.value)}
                        />
                      </Stack>
                    </td>
                    <td width="30%">
                      <InputGroup className="mb-1">
                        <InputGroup.Text id="basic-addon1">
                          Booking Date:
                        </InputGroup.Text>
                        <DatePicker
                          onChange={this.onBookingForChange}
                          value={this.state.BookingDate}
                          format="dd-MMM-yy"
                        />
                      </InputGroup>
                    </td>
                    <td width="25%" className="border">
                      <InputGroup className="mb-1">
                        <InputGroup.Text id="basic-addon1">
                          Booking Status
                        </InputGroup.Text>
                        <Form.Select
                          value={this.state.BookingStatus}
                          name="bookingstatus"
                          onChange={(e) => {
                            this.OnBookingStatusChange(e);
                          }}
                        >
                          <option value=""></option>
                          <option value="1">Booked</option>
                          <option value="2">CheckedIn</option>
                          <option value="3">Cancelled</option>
                        </Form.Select>
                      </InputGroup>
                    </td>
                  </tr>
                </Table>
              </div>
              <div>
                <Table border striped className="px-0 bg-white ">
                  <thead className="">
                    <tr className="text-start text-black bg-white border">
                      <th width="5%"></th>
                      <th width="10%">Created</th>
                      <th width="10%">Booking Date</th>
                      <th width="10%">Customer Name</th>
                      <th width="10%">MobileNo</th>
                      <th width="10%">Email-Id</th>
                      <th width="10%">Service Type</th>
                      <th width="10%">Status</th>
                      <th width="20%" className="text-end">
                        Options
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-primary border">
                    {/* {this.state.productservicebookings */}
                    {this.state.searchData
                      .filter((f) => {
                        if (f.status == Number(this.state.BookingStatus))
                          return true;
                        if (this.state.BookingStatus == "") return true;
                        return false;
                      })
                      .sort((a, b) => (a.created > b.created ? 1 : -1))
                      .map((productservicebooking, i) =>
                        this.state.reload === true ? (
                          <EditProductServiceBookingInline
                            index={i}
                            productservicebooking={productservicebooking}
                            OnDeleted={this.OnDeleted}
                            RefreshBooking={this.OnRefreshButtonClick}
                          ></EditProductServiceBookingInline>
                        ) : null
                      )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export class EditProductServiceBookingInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      productservicebooking: "",
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      editModalShow: false,
    };
  }

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDelete = () => {
    if (this.state.productservicebooking.id == undefined) {
      this.ShowMessagBox(
        "Information",
        "This is invalid row which can't be deleted. Press Refresh!"
      );
      return;
    }

    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "ManufactureAndService/DeleteProductServiceBooking/" +
        this.state.productservicebooking.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert(
            "Not deleted. This ProductServiceBooking may be associated with few Products. First disassociate this productservicebooking from the Products and then try!"
          );
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Status",
          "Deleted Successfully. Click Refresh Button to see the changes!"
        );
        this.props.OnDeleted();
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("OnDelete Failed" + error);
      });
  };

  componentDidMount = () => {
    this.setState({
      productservicebooking: this.props.productservicebooking,
    });
  };
  OnEditButtonClick = () => {
    this.setState({ editModalShow: true }, () => {});
  };

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {
      this.props.RefreshBooking();
    });
  };

  render() {
    return (
      <>
        {this.state.editModalShow === true ? (
          <ProductServiceBookingAddOrEditModal
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            productservicebooking={this.state.productservicebooking}
            Type="Edit"
          />
        ) : (
          ""
        )}
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <tr
          className="text-start align-middle text-secondary bg-white mx-5"
          style={{ fontSize: "12px" }}
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          <td>{this.props.index + 1}</td>
          <td>
            {this.context.GetInDateFormat(
              new Date(this.state.productservicebooking.created)
            )}
          </td>
          <td>
            {this.context.GetInDateFormat(
              new Date(this.state.productservicebooking.bookingFor)
            )}
          </td>
          <td className="text-start ">
            {this.state.productservicebooking.firstName +
              " " +
              this.state.productservicebooking.lastName}
          </td>
          <td className="text-start ">
            {this.state.productservicebooking.mobileNo}
          </td>
          <td className="text-start ">
            {this.state.productservicebooking.emailId}
          </td>
          <td className="text-start ">
            {this.state.productservicebooking == 1
              ? "Free Service"
              : "Paid Service"}
          </td>
          <td className="text-start ">
            {this.state.productservicebooking.status == 1
              ? "Booked"
              : this.state.productservicebooking.status == 2
              ? "CheckedIn"
              : this.state.productservicebooking.status == 3
              ? "Cancelled"
              : ""}
          </td>
          <td className="col-md-2 text-end align-middle ">
            <div class="btn-group">
              <OverlayTrigger overlay={<Tooltip>Edit!</Tooltip>}>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    this.OnEditButtonClick(e);
                  }}
                >
                  <BsFillPencilFill size="16" />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                overlay={
                  <Tooltip>
                    Warning! This will delete this Brand Row and associated
                    Products as well.
                  </Tooltip>
                }
              >
                <Button
                  className="mx-1 mainthemecolor"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        "Warning! This action will delete this Brand Row and associated Products as well. Are you sure you want to Delete?"
                      )
                    ) {
                      this.OnDelete(e);
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

export class ProductServiceBookingAddOrEditModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      productservicebooking: {
        bookingFor: new Date(),
        serviceType: "1",
        status: "1",
      },

      customerDetails: "",
      mobileNo: "",
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      bookingFor: new Date(),
      addModalShow: false,
    };
  }

  addModalClose = (e) => {
    if (e.customerId === undefined) {
      this.setState({ addModalShow: false }, () => {});
      return;
    }

    this.setState({ customerDetails: e }, () => {
      this.state.productservicebooking.customerId = e.customerId;
      this.setState({
        productservicebooking: this.state.productservicebooking,
      });
      this.setState({ addModalShow: false }, () => {});
    });
  };

  GetProductServiceCustomersByMobileNo = (e) => {
    if (
      this.state.mobileNo == "" ||
      (this.state.mobileNo != "" && this.state.mobileNo.length != 10)
    ) {
      alert("Invalid MobileNo. Enter a valid Customer Mobile number and try!");
      return;
    }
    fetch(
      process.env.REACT_APP_API +
        "ManufactureAndService/GetProductServiceCustomersByMobileNo/" +
        this.context.storeId +
        "/" +
        this.state.mobileNo
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
          alert("Mobile Number Not Found!");
        } else {
          this.setState({ customerDetails: data }, () => {
            this.state.productservicebooking.customerId = data.id;
            this.setState({
              productservicebooking: this.state.productservicebooking,
            });
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  AddOrUpdateProductServiceBookingInDB = () => {
    this.ShowLoadSpinner();

    var tmpProductServiceBooking = this.state.productservicebooking;
    if (this.props.Type == "Edit") {
    } else {
      tmpProductServiceBooking.storeId = this.context.storeId;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmpProductServiceBooking),
    };

    fetch(
      process.env.REACT_APP_API +
        "ManufactureAndService/AddOrUpdateProductServiceBooking/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.HideLoadSpinner();
          this.props.onHide();
          return;
        }
        this.HideLoadSpinner();
        alert("This ProductServiceBooking is already exist. try again!");
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("Exception. Received an Exception. Try after sometime !" + error);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.AddOrUpdateProductServiceBookingInDB();
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "mobileNo":
        this.setState({ mobileNo: value });
        break;
      case "bookingFor":
        this.state.productservicebooking.bookingFor = value;
        break;
      case "serviceType":
        this.state.productservicebooking.serviceType = value;
        break;
      case "description":
        this.state.productservicebooking.description = value;
        break;
      case "status":
        this.state.productservicebooking.status = value;
        break;
    }
    this.setState({
      productservicebooking: this.state.productservicebooking,
    });
  };
  onBookingForChange = (date) => {
    this.state.productservicebooking.bookingFor = date;
    this.setState({ productservicebooking: this.state.productservicebooking });
  };

  componentDidMount() {
    if (this.props.Type == "Edit") {
      var tbookingDetails = {
        id: this.props.productservicebooking.id,
        storeId: this.props.productservicebooking.storeId,
        customerId: this.props.productservicebooking.customerId,
        created: this.props.productservicebooking.created,
        bookingFor: this.props.productservicebooking.bookingFor,
        serviceType: this.props.productservicebooking.serviceType,
        status: this.props.productservicebooking.status,
        description: this.props.productservicebooking.description,
      };
      this.setState({
        productservicebooking: tbookingDetails,
      });

      var tcustomerDetails = {
        firstName: this.props.productservicebooking.firstName,
        lastName: this.props.productservicebooking.lastName,
        mobileNo: this.props.productservicebooking.mobileNo,
        emailId: this.props.productservicebooking.emailId,
        Id: this.props.productservicebooking.customerId,
      };
      this.setState({ customerDetails: tcustomerDetails });
      this.setState({ mobileNo: this.props.productservicebooking.mobileNo });
    }
  }

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          dialogClassName="ProductServiceBookingModal"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          {this.state.addModalShow === true ? (
            <ProductServiceCustomerAddOrEditModal
              show={this.state.addModalShow}
              Type="Add"
              onHide={this.addModalClose}
            />
          ) : null}
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit" ? "Edit Booking" : "Create Booking"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              {/* <Row
                className="m-2 p-2 bg-light border"
                style={{ fontSize: "9pt" }}
              >
                <Col md={12}>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Customer MobileNo:
                    </Form.Label>
                    <Col sm="4">
                      <Form.Control
                        type="text"
                        name="mobileNo"
                        value={this.state.mobileNo}
                        required
                        placeholder="Enter 10 digit mobile number"
                        onChange={(e) => this.handleInputChange(e)}
                      ></Form.Control>
                    </Col>
                    <Col sm="5">
                      {this.state.customerDetails == "" ? (
                        <>
                          <Stack direction="horizontal">
                            <Button
                              onClick={(e) =>
                                this.GetProductServiceCustomersByMobileNo(e)
                              }
                            >
                              Get Customer Details
                            </Button>{" "}
                            &nbsp;
                            <Button
                              onClick={() =>
                                this.setState({ addModalShow: true })
                              }
                            >
                              Add If New Customer
                            </Button>
                          </Stack>
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Customer Details:
                    </Form.Label>
                    <Col sm="9">
                      {this.state.customerDetails != ""
                        ? this.state.customerDetails.firstName +
                          " " +
                          this.state.customerDetails.lastName +
                          "," +
                          this.state.customerDetails.address +
                          "," +
                          this.state.customerDetails.city
                        : ""}
                    </Col>
                  </Form.Group>
                </Col>
              </Row> */}
              <Row
                className="m-2 p-2 bg-light border"
                style={{ fontSize: "9pt" }}
              >
                <Col md={6}>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="4">
                      Customer MobileNo:
                    </Form.Label>
                    <Col sm="8">
                      <Form.Control
                        type="text"
                        name="mobileNo"
                        value={this.state.mobileNo}
                        required
                        readOnly={
                          this.state.customerDetails == "" ? false : true
                        }
                        placeholder="Enter 10 digit mobile number"
                        onChange={(e) => this.handleInputChange(e)}
                      ></Form.Control>
                    </Col>
                  </Form.Group>
                  {this.state.customerDetails == "" ? (
                    <>
                      <Stack direction="horizontal">
                        <Button
                          onClick={(e) =>
                            this.GetProductServiceCustomersByMobileNo(e)
                          }
                        >
                          Get Customer Details
                        </Button>{" "}
                        &nbsp;
                        <Button
                          onClick={() => this.setState({ addModalShow: true })}
                        >
                          Add If New Customer
                        </Button>
                      </Stack>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
                {this.state.customerDetails == "" ? (
                  <></>
                ) : (
                  <>
                    <Col md={6}>
                      <Form.Group as={Row} className="mb-3 " controlId="">
                        <Form.Label column sm="4">
                          Customer Name:
                        </Form.Label>
                        <Col sm="8">
                          <b>
                            {this.state.customerDetails != ""
                              ? this.state.customerDetails.firstName +
                                " " +
                                this.state.customerDetails.lastName
                              : ""}
                          </b>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3 " controlId="">
                        <Form.Label column sm="4">
                          Customer Address:
                        </Form.Label>
                        <Col sm="8">
                          {this.state.customerDetails != ""
                            ? this.state.customerDetails.address +
                              "," +
                              this.state.customerDetails.city +
                              "," +
                              this.state.customerDetails.state +
                              "," +
                              this.state.customerDetails.country
                            : ""}
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3 " controlId="">
                        <Form.Label column sm="4">
                          EmailId:
                        </Form.Label>
                        <Col sm="8">
                          {this.state.customerDetails != ""
                            ? this.state.customerDetails.emailId
                            : ""}
                        </Col>
                      </Form.Group>
                    </Col>
                  </>
                )}
              </Row>
              {this.state.customerDetails != "" ? (
                <>
                  <Row
                    className="m-2 p-2 bg-light border"
                    style={{ fontSize: "9pt" }}
                  >
                    <Col md={12}>
                      <Form.Group as={Row} className="mb-3 " controlId="">
                        <Form.Label column sm="3">
                          Booking For
                        </Form.Label>
                        <Col sm="9">
                          <DateTimePicker
                            onChange={this.onBookingForChange}
                            value={this.state.productservicebooking.bookingFor}
                            format="dd-MMM-yy hh:mm a"
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3 " controlId="">
                        <Form.Label column sm="3">
                          Service Type
                        </Form.Label>
                        <Col sm="9">
                          <Form.Select
                            type="text"
                            name="serviceType"
                            value={this.state.productservicebooking.serviceType}
                            required
                            placeholder="select Sub Category"
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="1">Free Service</option>
                            <option value="2">Paid Service</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3 " controlId="">
                        <Form.Label column sm="3">
                          Description
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            type="text"
                            as="textarea"
                            rows={3}
                            name="description"
                            maxlength="499"
                            value={this.state.productservicebooking.description}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-3 " controlId="">
                        <Form.Label column sm="3">
                          Status
                        </Form.Label>
                        <Col sm="9">
                          <Form.Select
                            type="text"
                            name="status"
                            value={this.state.productservicebooking.status}
                            required
                            placeholder="select Sub Category"
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value="1">Book</option>
                            <option value="2">CheckIn For Service</option>
                            <option value="3">Cancel</option>
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </>
              ) : (
                <></>
              )}
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  Save
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
