import React, { Component, useState } from "react";
import { Navigate } from "react-router-dom";
import { SideMenu } from "./SideMenu";
import { TbPackage } from "react-icons/tb";
import { LoadSpinner } from "./LoadSpinner";
import Chart from "chart.js/auto";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Bar, Line, Pie } from "react-chartjs-2";
import { SiHackthebox } from "react-icons/si";
import { RiFeedbackLine, RiCoupon5Line } from "react-icons/ri";
import { TbReceiptTax, TbBuildingStore } from "react-icons/tb";

import { CategoryScale } from "chart.js";

import {
  Alert,
  InputGroup,
  Stack,
  FormControl,
  Button,
  ButtonToolbar,
  Form,
  Modal,
  Row,
  Col,
  Nav,
  Table,
  Navbar,
  Badge,
  ListGroup,
  Accordion,
} from "react-bootstrap";
import {
  BsUiChecksGrid,
  BsCartX,
  BsCardList,
  BsUiRadiosGrid,
  BsPeopleFill,
  BsBoundingBox,
  BsFillCameraFill,
  BsFillFileImageFill,
  BsFillLaptopFill,
  BsDiagram3,
  BsPersonSquare,
  BsAward,
  BsTruck,
  BsMenuDown,
  BsWallet2,
  BsPersonCheckFill,
  BsZoomOut,
} from "react-icons/bs";

import { Link } from "react-router-dom";

import CartContext from "./CartContext";

export default class HomeMobileFront extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      showElasticCart: true,
    };
  }
  enableProductsMenu = () => {
    if (
      this.context.UserRoles.PRODUCTADMIN == this.context.userData.roleId ||
      this.context.UserRoles.STOREADMIN == this.context.userData.roleId ||
      this.context.UserRoles.STOREMANAGER == this.context.userData.roleId
    ) {
      return false;
    } else {
      return true;
    }
  };
  enableCustomersMenu = () => {
    if (
      this.context.UserRoles.PRODUCTADMIN == this.context.userData.roleId ||
      this.context.UserRoles.STOREADMIN == this.context.userData.roleId ||
      this.context.UserRoles.STOREMANAGER == this.context.userData.roleId ||
      this.context.UserRoles.ORDERS == this.context.userData.roleId
    ) {
      return false;
    } else {
      return true;
    }
  };
  fakeRequest1 = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 2000));
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.context.prods.length == 0) {
      this.fakeRequest1().then(() => {
        this.setState({ showElasticCart: false }); // showing the app
      });
    } else {
      this.setState({ showElasticCart: false }); // showing the app
    }

    if (this.state.dashboardSummary == "" && this.context.storeId != "") {
      this.GetDashboardSummary();
      this.GetOrderSummary();
      this.GetRevenueSummary();
      this.GetCustomerSummary();
      this.GetTopSellingProductsSummary();
    }
  }
  render() {
    if (this.state.showElasticCart == true) {
      return (
        <>
          <ElasticCartAd></ElasticCartAd>
        </>
      );
    }
    if (this.context.userData.loginStatus != "active") {
      return <Navigate to="/UserLogin" />;
    }
    return (
      <>
        <div className="py-3" style={{ backgroundColor: "black" }}>
          <div className="p-3 py-2 mx-3 mainthemecolor">
            <Table hover className="p-0 m-0" size="sm">
              <tbody className="text-center  p-0 m-0">
                <Row
                  className="text-center p-0 m-0"
                  hidden={this.context.enableOrderToolbarIcon()}
                >
                  <Col className="text-center p-0 m-0">
                    <Alert className="bg-light p-3">
                      <Link
                        className="text-start  bg-white  w-100 text-dark"
                        to="/HomeMobile"
                        style={{
                          width: "100%",
                          fontSize: "16px",
                          textDecoration: "none",
                        }}
                      >
                        <h3>Dashboard</h3>
                      </Link>
                    </Alert>
                  </Col>
                </Row>
                {this.context.storeSettings.showOrdersManagement == undefined ||
                this.context.storeSettings.showOrdersManagement == true ? (
                  <>
                    <div className="border p-2 text-center text-white">
                      Orders Management
                      <Row
                        className="text-center p-0 m-0"
                        hidden={this.context.enableOrderToolbarIcon()}
                      >
                        <Col className="text-center p-0 m-0">
                          <Alert className="bg-light p-3">
                            <Link
                              className="text-start  bg-white  w-100 text-dark"
                              to="/Orders"
                              style={{
                                width: "100%",
                                fontSize: "16px",
                                textDecoration: "none",
                              }}
                            >
                              <Badge
                                bg="primary"
                                className="notify-badge-HomeFrontMenu"
                                style={{ fontsize: "12pt" }}
                              >
                                {this.context.orderSummary != ""
                                  ? this.context.orderSummary.orderReceived
                                  : ""}
                              </Badge>
                              <h3>1.New Orders</h3>
                            </Link>
                          </Alert>
                        </Col>
                      </Row>
                      {this.context.store.businessTypeId == 3 ? (
                        <>
                          {/* <Row
                      className="text-center p-0 m-0"
                      hidden={this.context.enableOrderToolbarIcon()}
                    >
                      <Col className="text-center p-0 m-0">
                        <Alert className="bg-light p-3">
                          <Link
                            className="text-start  bg-white  w-100 text-dark"
                            to="/TableOrdersQueue"
                            style={{
                              width: "100%",
                              fontSize: "16px",
                              textDecoration: "none",
                            }}
                          >
                            <Badge
                              bg="dark"
                              className="notify-badge-HomeFrontMenu"
                              style={{ fontsize: "12pt" }}
                            >
                              {this.context.orderSummary != ""
                                ? this.context.orderSummary.kitchenOrders
                                : ""}
                            </Badge>
                            <h3>2.Table Orders</h3>
                          </Link>
                        </Alert>
                      </Col>
                    </Row> */}
                          <Row
                            className="text-center p-0 m-0"
                            hidden={this.context.enableOrderToolbarIcon()}
                          >
                            <Col className="text-center p-0 m-0">
                              <Alert className="bg-light p-3">
                                <Link
                                  className="text-start  bg-white  w-100 text-dark"
                                  to="/Kds2"
                                  style={{
                                    width: "100%",
                                    fontSize: "16px",
                                    textDecoration: "none",
                                  }}
                                >
                                  {/* <BsFullscreen size="20"></BsFullscreen> */}
                                  <Badge
                                    bg="dark"
                                    className="notify-badge-HomeFrontMenu"
                                    style={{ fontsize: "12pt" }}
                                  >
                                    {this.context.orderSummary != ""
                                      ? this.context.orderSummary.orderPrepare
                                      : ""}
                                  </Badge>
                                  <h3>2.Kitchen(KDS)</h3>
                                </Link>
                              </Alert>
                            </Col>
                          </Row>
                        </>
                      ) : (
                        <>
                          <Row
                            className="text-center p-0 m-0"
                            hidden={this.context.enableOrderToolbarIcon()}
                          >
                            <Col className="text-center p-0 m-0">
                              <Alert className="bg-light p-3">
                                <Link
                                  className="text-start  bg-white  w-100 text-dark"
                                  to="/Kds2"
                                  style={{
                                    width: "100%",
                                    fontSize: "16px",
                                    textDecoration: "none",
                                  }}
                                >
                                  {/* <BsFullscreen size="20"></BsFullscreen> */}
                                  <Badge
                                    bg="dark"
                                    className="notify-badge-HomeFrontMenu"
                                    style={{ fontsize: "12pt" }}
                                  >
                                    {this.context.orderSummary != ""
                                      ? this.context.orderSummary.orderPrepare
                                      : ""}
                                  </Badge>
                                  <h3>2.Prepare(OMS)</h3>
                                </Link>
                              </Alert>
                            </Col>
                          </Row>
                        </>
                      )}
                      <Row
                        className="text-center p-0 m-0"
                        hidden={this.context.enablePackaingToolbarIcon()}
                      >
                        <Col className="text-center p-0 m-0">
                          <Alert className="bg-light p-3">
                            <Link
                              className="text-start  bg-white  w-100 text-dark"
                              to="/Package"
                              style={{
                                width: "100%",
                                fontSize: "16px",
                                textDecoration: "none",
                              }}
                            >
                              <Badge
                                bg="danger"
                                className="notify-badge-HomeFrontMenu "
                              >
                                {this.context.orderSummary != ""
                                  ? this.context.orderSummary.orderPackage
                                  : ""}
                              </Badge>
                              <h3>3.Package(PMS)</h3>
                            </Link>
                          </Alert>
                        </Col>
                      </Row>
                      <Row
                        className="text-center p-0 m-0"
                        hidden={this.context.enableShippingToolbarIcon()}
                      >
                        <Col className="text-center p-0 m-0">
                          <Alert className="bg-light p-3">
                            <Link
                              className="text-start  bg-white  w-100 text-dark"
                              to="/Dispatch"
                              style={{
                                width: "100%",
                                fontSize: "16px",
                                textDecoration: "none",
                              }}
                            >
                              <Badge
                                bg="danger"
                                className="notify-badge-HomeFrontMenu text-white"
                              >
                                {this.context.orderSummary != ""
                                  ? this.context.orderSummary.orderShip
                                  : ""}
                              </Badge>
                              <h3>4.Shipping(SMS)</h3>
                            </Link>
                          </Alert>
                        </Col>
                      </Row>
                      <Row
                        className="text-center p-0 m-0"
                        hidden={this.context.enableDeliveryToolbarIcon()}
                      >
                        <Col className="text-center p-0 m-0">
                          <Alert className="bg-light p-3">
                            <Link
                              className="text-start  bg-white  w-100 text-dark"
                              to="/Delivery"
                              style={{
                                width: "100%",
                                fontSize: "16px",
                                textDecoration: "none",
                              }}
                            >
                              <Badge
                                bg="danger"
                                className="notify-badge-HomeFrontMenu text-white"
                              >
                                {this.context.orderSummary != ""
                                  ? this.context.orderSummary.orderDelivery
                                  : ""}
                              </Badge>
                              <h3>5.Delivery(DMS)</h3>
                            </Link>
                          </Alert>
                        </Col>
                      </Row>
                      <Row
                        className="text-center p-0 m-0"
                        hidden={this.context.enablePaymentToolbarIcon()}
                      >
                        <Col className="text-center p-0 m-0">
                          <Alert className="bg-light p-3">
                            <Link
                              className="text-start  bg-white  w-100 text-dark"
                              to="/Payment"
                              style={{
                                width: "100%",
                                fontSize: "16px",
                                textDecoration: "none",
                              }}
                            >
                              <Badge
                                bg="danger"
                                className="notify-badge-HomeFrontMenu text-white"
                              >
                                {this.context.orderSummary != ""
                                  ? this.context.orderSummary.orderPayment
                                  : ""}
                              </Badge>
                              <h3>6.Payment Review(PRS)</h3>
                            </Link>
                          </Alert>
                        </Col>
                      </Row>
                      <Row
                        className="text-center p-0 m-0"
                        hidden={this.context.enableOrderToolbarIcon()}
                      >
                        <Col className="text-center p-0 m-0">
                          <Alert className="bg-light p-3">
                            <Link
                              className="text-start  bg-white  w-100 text-dark"
                              to="/CloseOrder"
                              style={{
                                width: "100%",
                                fontSize: "16px",
                                textDecoration: "none",
                              }}
                            >
                              <Badge
                                bg="warning"
                                className="notify-badge-HomeFrontMenu text-white"
                              >
                                {this.context.orderSummary != ""
                                  ? this.context.orderSummary.orderClose
                                  : ""}
                              </Badge>
                              <h3>7.Close Orders</h3>
                            </Link>
                          </Alert>
                        </Col>
                      </Row>
                      <Row
                        className="text-center p-0 m-0"
                        hidden={this.context.enableOrderToolbarIcon()}
                      >
                        <Col className="text-center p-0 m-0">
                          <Alert className="bg-light p-3">
                            <Link
                              className="text-start  bg-white  w-100 text-dark"
                              to="/OrderHistory"
                              style={{
                                width: "100%",
                                fontSize: "16px",
                                textDecoration: "none",
                              }}
                            >
                              <Badge
                                bg="secondary"
                                className="notify-badge-HomeFrontMenu text-white"
                              >
                                {this.context.orderSummary != ""
                                  ? this.context.orderSummary.orderHistory
                                  : ""}
                              </Badge>
                              <h3>8.History</h3>
                            </Link>
                          </Alert>
                        </Col>
                      </Row>
                      <Row
                        className="text-center p-0 m-0"
                        hidden={this.context.enableOrderToolbarIcon()}
                      >
                        <Col className="text-center p-0 m-0">
                          <Alert className="bg-light p-3">
                            <Link
                              className="text-start  bg-white  w-100 text-dark"
                              to="/Returns"
                              style={{
                                width: "100%",
                                fontSize: "16px",
                                textDecoration: "none",
                              }}
                            >
                              <Badge
                                bg="danger"
                                className="notify-badge-HomeFrontMenu text-white"
                              >
                                {this.context.orderSummary != ""
                                  ? this.context.orderSummary.orderReturned
                                  : ""}
                              </Badge>
                              <h3>9.Returns</h3>
                            </Link>
                          </Alert>
                        </Col>
                      </Row>
                    </div>
                    <br />
                    <Row
                      className="text-center p-0 m-0"
                      hidden={this.context.enableOrderToolbarIcon()}
                    >
                      <Col className="text-center p-0 m-0">
                        <Alert className="bg-light p-3">
                          <Link
                            className="text-start  bg-white  w-100 text-dark"
                            to="/Customers"
                            style={{
                              width: "100%",
                              fontSize: "16px",
                              textDecoration: "none",
                            }}
                          >
                            <Badge
                              bg="success"
                              className="notify-badge-HomeFrontMenu text-white"
                            >
                              {this.context.orderSummary != ""
                                ? this.context.orderSummary.customersCount
                                : ""}
                            </Badge>
                            <h3>Customers DB</h3>
                          </Link>
                        </Alert>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}

                {/* Product Inventory */}
                {this.context.storeSettings.showProductInventoryModule ==
                  undefined ||
                this.context.storeSettings.showProductInventoryModule ==
                  true ? (
                  <>
                    <Row
                      className="text-center p-0 m-0"
                      hidden={this.context.enableOrderToolbarIcon()}
                    >
                      <ListGroup.Item
                        className="px-0 py-0 mx-0 my-0"
                        disabled={this.enableProductsMenu()}
                      >
                        <Accordion
                          defaultActiveKey="1"
                          className="mx-0 px-0 py-0 my-0 mb-0 border-0"
                        >
                          <Accordion.Item
                            eventKey={
                              this.context.selectedMenuItem === "product"
                                ? "1"
                                : "0"
                            }
                            className="border-0 mx-0 px-0 py-0 my-0 mb-0"
                            onClick={(e) => {
                              e.eventKey = "1";
                              this.context.selectedMenuItem = "product";
                              //this.context.UpdateSelectedMenuItem("product");
                            }}
                          >
                            <Accordion.Header className="mx-0 p-lg-0  text-start">
                              <Nav.Link
                                as={Link}
                                className="p-lg-0 bg-white border-0 text-dark text-start"
                                style={{
                                  fontSize: "20px",
                                  fontFamily: "inherit",
                                  // fontWeight: "bold",
                                }}
                                to=""
                                onClick={(e) =>
                                  this.setState({
                                    productDefaultActiveKey: 0,
                                  })
                                }
                              >
                                <BsCardList size="20" />
                                &nbsp;&nbsp;Product Inventory
                              </Nav.Link>
                            </Accordion.Header>
                            <Accordion.Body>
                              <ListGroup variant="flush" className="bg-black">
                                <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                                  <Nav.Link
                                    as={Link}
                                    style={{
                                      fontSize: "20px",
                                      fontFamily: "inherit",
                                    }}
                                    className="w-100 py-2 my-1 mb-0 bg-white border-0 text-secondary text-start"
                                    to="/ProductsListView"
                                  >
                                    <BsUiChecksGrid size="20" />
                                    &nbsp;&nbsp;Products
                                  </Nav.Link>
                                </ListGroup.Item>
                                <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                                  <Nav.Link
                                    as={Link}
                                    style={{
                                      fontSize: "20px",
                                      fontFamily: "inherit",
                                    }}
                                    className="w-100 py-2 my-1 mb-0 bg-white border-0 text-secondary text-start"
                                    to="/OutOfStock"
                                    onClick={(e) =>
                                      this.setState({
                                        productDefaultActiveKey: 1,
                                      })
                                    }
                                  >
                                    <BsZoomOut size="20" />
                                    &nbsp;&nbsp;Out-Of-Stock
                                  </Nav.Link>
                                </ListGroup.Item>
                              </ListGroup>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </ListGroup.Item>
                    </Row>
                  </>
                ) : (
                  <></>
                )}

                {/* Product purchase */}
                {this.context.storeSettings.showPurchaseModule == true ||
                this.context.storeSettings.showPurchaseModule == undefined ? (
                  <>
                    <Row
                      className="text-center py-3 m-0"
                      hidden={this.context.enableOrderToolbarIcon()}
                    >
                      <ListGroup.Item
                        className="px-0 py-0 mx-0 my-0"
                        disabled={this.enableCustomersMenu()}
                      >
                        <Accordion
                          defaultActiveKey="1"
                          className="m-0 p-0 text-dark"
                        >
                          <Accordion.Item
                            eventKey={
                              this.context.selectedMenuItem === "purchase"
                                ? "1"
                                : "0"
                            }
                            className="border-0 m-0 p-0"
                            onClick={(e) => {
                              this.context.selectedMenuItem = "purchase";
                            }}
                          >
                            <Accordion.Header className="mx-0 p-0 text-start ">
                              <Nav.Link
                                className="p-lg-0 bg-white text-dark text-start"
                                style={{
                                  fontSize: "20px",
                                  fontFamily: "tahoma",
                                }}
                                to=""
                              >
                                {/* <BsFillCartCheckFill size="20" /> */}
                                <TbPackage size="20"></TbPackage>
                                &nbsp;Product Purchase
                              </Nav.Link>
                            </Accordion.Header>
                            <Accordion.Body>
                              <Nav.Link
                                as={Link}
                                style={{
                                  fontSize: "20px",
                                  fontFamily: "tahoma",
                                }}
                                className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                                to="/Suppliers"
                              >
                                <BsPeopleFill size="20" />
                                &nbsp;Supplier
                              </Nav.Link>
                              <Nav.Link
                                as={Link}
                                style={{
                                  fontSize: "20px",
                                  fontFamily: "tahoma",
                                }}
                                className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                                to="/PurchaseRequests"
                              >
                                <RiCoupon5Line size="20" />
                                &nbsp;Purchase Requests (PR)
                              </Nav.Link>
                              <Nav.Link
                                as={Link}
                                style={{
                                  fontSize: "20px",
                                  fontFamily: "tahoma",
                                }}
                                className="w-100 py-2 my-2 mb-0 bg-white border-0 text-secondary text-start"
                                to="/PurchaseOrders"
                              >
                                <RiCoupon5Line size="20" />
                                &nbsp;Purchase Orders (PO)
                              </Nav.Link>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </ListGroup.Item>
                    </Row>
                  </>
                ) : (
                  <></>
                )}

                <Row
                  className="text-center p-0 m-0"
                  hidden={this.context.enableOrderToolbarIcon()}
                >
                  <Col className="text-center p-0 m-0">
                    <Alert className="bg-light p-3">
                      <Link
                        className="text-start  bg-white  w-100 text-dark"
                        to="/Reports"
                        style={{
                          width: "100%",
                          fontSize: "16px",
                          textDecoration: "none",
                        }}
                      >
                        <Badge
                          bg="success"
                          className="notify-badge-HomeFrontMenu text-white"
                        ></Badge>
                        <h3>Reports</h3>
                      </Link>
                    </Alert>
                  </Col>
                </Row>

                {window.location.hostname ==
                "lifestyleaqua-admin.elasticcart.com" ? (
                  <>
                    <Row className="text-center py-0 m-0"></Row>
                    <ListGroup.Item className="px-0 py-2 mx-0 my-0">
                      <Nav.Link
                        as={Link}
                        style={{
                          width: "100%",
                          fontSize: "16px",
                          textDecoration: "none",
                        }}
                        className="text-start  bg-white  w-100 text-dark"
                        to="/LifeStyleAquaAuthenticator"
                      >
                        {/* <BsFillFileImageFill size="20" /> */}
                        <h3>&nbsp;Authenticator</h3>
                      </Nav.Link>
                    </ListGroup.Item>
                  </>
                ) : (
                  <></>
                )}
              </tbody>
            </Table>
          </div>
        </div>
      </>
    );
  }
}

class ElasticCartAd extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Modal
          {...this.props}
          size="lg"
          // aria-labelledby="contained-modal-title-vcenter"
          centered
          // backdrop="static"
          // keyboard={false}
          show={true}
          className="spinnermodal-backdrop m-0 p-0 bg-white  border-0"
          // dialogClassName="payByCash"
        >
          <Modal.Body
            className="text-center m-0 p-0 bg-white border-0"
            style={{ backgroundColor: "transparant" }}
          >
            <div className="text-center m-0 p-0 border-0">
              {/* <h1>Welcome to ElasticCART!</h1> */}
              <img
                src="./elasticcartad.png"
                className="w-100 CornersRounded border-0"
                alt="..."
                width="100%"
                // height="500"
                style={{ objectFit: "contain" }}
              />{" "}
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
