import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";

import {
  OverlayTrigger,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export class HomeSettingsCarousel extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      carousels: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
    };
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDeleted = () => {
    // this.setState({ carousels: [] }, () => {
    //   this.setState({ reload: false }, () => {
    //     this.setState({ carousels: this.context.carousels }, () => {
    //       this.setState({ reload: true });
    //     });
    //   });
    // });

    this.OnRefreshButtonClick();
  };
  OnSaved = () => {
    // this.setState({ carousels: this.context.carousels });
    // this.setState({ reload: false }, () => this.setState({ reload: true }));
    // this.ShowMessagBox("Status", "Saved Successfully!");
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    // this.context.GetMainCarousels();
    this.setState({ carousels: [] }, () => {
      this.setState({ reload: false }, () => {
        this.setState({ carousels: this.context.carousels }, () => {
          this.setState({ reload: true });
        });
      });
    });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  handleAddCarousel = (e) => {
    this.setState({
      carousels: [
        ...this.state.carousels,
        {
          storeId: this.context.storeId,
          imgFileName: "",
          TargetDevice: 1,
          imgHeight: "",
          imgWidth: "",
          seqNo: this.state.carousels.length + 1,
        },
      ],
    });
    alert("An empty row added at the end of list below!");
  };

  OnSave = (e) => {
    this.ShowLoadSpinner();

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.carousels),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/AddOrUpdateMainCarousel/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.context.GetMainCarousels();
          this.HideLoadSpinner();
          this.ShowMessagBox(
            "Status",
            "Saved Successfully. Click Refresh Button to see the changes!"
          );
          return;
        }
        this.HideLoadSpinner();
        alert("Not Saved. try later again!");
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("Exception. Received an Exception. Try after sometime !" + error);
      });
  };
  OnDelete = (i) => {
    if (this.state.carousels[i].id == undefined) {
      this.ShowMessagBox(
        "Information",
        "This is invalid row which can't be deleted. Press Refresh!"
      );
      return;
    }

    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteCarousel/" +
        this.state.carousels[i].id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert("not deleted");
          return;
        }
        this.context.GetMainCarousels();
        this.HideLoadSpinner();
        this.OnDeleted();
        this.ShowMessagBox(
          "Status",
          "Deleted Successfully. Click Refresh Button to see the changes!"
        );
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("OnDelete Failed" + error);
      });
  };
  handleInputChange = (e, i) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "imgFileName":
        this.state.carousels[i].imgFileName = value;
        break;
      case "targetDevice":
        this.state.carousels[i].targetDevice = value;
        break;
      case "seqNo":
        this.state.carousels[i].seqNo = value;
        break;
      case "carouselType":
        this.state.carousels[i].carouselType = value;
        break;
      case "mainCategoryId":
        this.state.carousels[i].mainCategoryId = value;
        break;
      case "subCategoryId":
        this.state.carousels[i].subCategoryId = value;
        break;
      case "productGroupId":
        this.state.carousels[i].productGroupId = value;
        break;
    }
    this.setState({ carousels: this.state.carousels });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.setState({ carousels: this.context.carousels });
    this.setState({ reload: true });
  };

  render() {
    return (
      <>
        <div className="Container-fluid p-1 ">
          <Row>
            <Col className="" xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="pagebackground p-0">
              {this.state.msgboxOpen === true ? (
                <MessageBox
                  open={this.state.msgboxOpen}
                  title={this.state.msgboxTitle}
                  message={this.state.msgboxMessage}
                  callback={this.CloseMessagBoxCallback}
                />
              ) : (
                ""
              )}
              {this.state.loadSpinnerOpen === true ? (
                <LoadSpinner open="true"></LoadSpinner>
              ) : (
                ""
              )}
              <Navbar
                expand="lg"
                className="secondarythemecolor position-sticky fixed-top  px-3"
              >
                <h4> &nbsp; &nbsp;Carousels &nbsp;</h4>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemecolor"
                        onClick={() => this.handleAddCarousel()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add
                      </Button>
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={(e) => {
                          this.OnSave(e);
                        }}
                      >
                        <BiSave size="26" />
                        Save
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Refresh
                      </Button>
                      &nbsp;
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              Note: Recommended Image Sizes &nbsp;&nbsp;&nbsp;<b>
                for PC:
              </b>{" "}
              2000 Width X 500 Height in pixels; <b>For Mobile:</b> 400 width X
              170 height in pixels
              <div></div>
              <div></div>
              <div>
                {this.state.reload == true ? (
                  <>
                    <Table border striped className="px-0 bg-white ">
                      <thead className="">
                        <tr className="text-center text-black bg-white border">
                          <th width="30%">Img FileName</th>
                          <th width="10%">Image</th>
                          <th width="10%">TargetDevice</th>
                          <th width="10%">Sequence No</th>
                          <th width="10%">LinkType</th>
                          <th width="20%">Reference</th>
                          <th width="10%" className="text-center">
                            Options
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-primary border">
                        {this.state.carousels
                          // .sort((a, b) => (a.seqNo < b.seqNo ? 1 : -1))
                          // .sort((a, b) =>
                          //   a.targetDevice > b.targetDevice ? 1 : -1
                          // )
                          .map((carousel, i) => (
                            <>
                              <tr
                                className="text-center align-middle text-secondary bg-white mx-5"
                                style={{ fontSize: "12px" }}
                              >
                                <td>
                                  <Form.Select
                                    type="text"
                                    name="imgFileName"
                                    value={this.state.carousels[i].imgFileName}
                                    required
                                    placeholder=""
                                    autoComplete="off"
                                    className="text-start"
                                    onChange={(e) =>
                                      this.handleInputChange(e, i)
                                    }
                                  >
                                    {this.context.images.map((r) => (
                                      <option value={r.name}>{r.name}</option>
                                    ))}
                                  </Form.Select>
                                </td>
                                <td className=" text-center ">
                                  <img
                                    src={
                                      this.context.store.storageBlobUrl +
                                      this.context.store
                                        .storageBlobContainerName +
                                      "/images/" +
                                      this.state.carousels[i].imgFileName +
                                      this.context.store.storageSasToken
                                    }
                                    className="productimg-on-productlist mx-auto"
                                    alt="..."
                                  />
                                </td>
                                <td className=" text-center ">
                                  <Form.Select
                                    type="text"
                                    name="targetDevice"
                                    value={this.state.carousels[i].targetDevice}
                                    required
                                    placeholder=""
                                    autoComplete="off"
                                    className="text-start"
                                    onChange={(e) =>
                                      this.handleInputChange(e, i)
                                    }
                                  >
                                    <option value={1}>PC</option>
                                    <option value={2}>Mobile</option>
                                  </Form.Select>
                                </td>
                                <td>
                                  <Form.Control
                                    type="number"
                                    name="seqNo"
                                    min={0}
                                    value={this.state.carousels[i].seqNo}
                                    required
                                    placeholder=""
                                    align="center"
                                    className="text-start"
                                    autoComplete="off"
                                    onChange={(e) =>
                                      this.handleInputChange(e, i)
                                    }
                                  />
                                </td>
                                <td>
                                  <Form.Select
                                    size=""
                                    name="carouselType"
                                    value={this.state.carousels[i].carouselType}
                                    onChange={(e) =>
                                      this.handleInputChange(e, i)
                                    }
                                  >
                                    <option value={0}></option>
                                    <option value={1}>MainCategory</option>
                                    <option value={2}>SubCategory</option>
                                    <option value={3}>ProductGroup</option>
                                  </Form.Select>
                                </td>
                                <td>
                                  {this.state.carousels[i].carouselType == 1 ? (
                                    <Form.Select
                                      size=""
                                      name="mainCategoryId"
                                      value={
                                        this.state.carousels[i].mainCategoryId
                                      }
                                      onChange={(e) =>
                                        this.handleInputChange(e, i)
                                      }
                                    >
                                      <option value=""></option>
                                      {this.context.mainCategories.map((m) => (
                                        <>
                                          <option value={m.id}>{m.name}</option>
                                        </>
                                      ))}
                                    </Form.Select>
                                  ) : this.state.carousels[i].carouselType ==
                                    2 ? (
                                    <Form.Select
                                      size=""
                                      name="subCategoryId"
                                      value={
                                        this.state.carousels[i].subCategoryId
                                      }
                                      onChange={(e) =>
                                        this.handleInputChange(e, i)
                                      }
                                    >
                                      <option value=""></option>
                                      {this.context.subCategories.map((m) => (
                                        <>
                                          <option value={m.id}>{m.name}</option>
                                        </>
                                      ))}
                                    </Form.Select>
                                  ) : this.state.carousels[i].carouselType ==
                                    3 ? (
                                    <Form.Select
                                      size=""
                                      name="productGroupId"
                                      value={
                                        this.state.carousels[i].productGroupId
                                      }
                                      onChange={(e) =>
                                        this.handleInputChange(e, i)
                                      }
                                    >
                                      <option value=""></option>
                                      {this.context.productGroups.map((m) => (
                                        <>
                                          <option value={m.id}>{m.name}</option>
                                        </>
                                      ))}
                                    </Form.Select>
                                  ) : (
                                    ""
                                  )}
                                </td>
                                <td className="col-md-2 text-center align-middle ">
                                  <div class="btn-group">
                                    <OverlayTrigger
                                      overlay={
                                        <Tooltip>
                                          Warning! This will delete this
                                          Carousel Row and associated Products
                                          as well.
                                        </Tooltip>
                                      }
                                    >
                                      <Button
                                        className="mx-1 mainthemecolor"
                                        onClick={(e) => {
                                          if (
                                            window.confirm(
                                              "Warning! This action will delete this Carousel Row and associated Products as well. Are you sure you want to Delete?"
                                            )
                                          ) {
                                            this.OnDelete(i);
                                          }
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="25"
                                          height="25"
                                          fill="currentColor"
                                          class="bi bi-trash"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                          <path
                                            fill-rule="evenodd"
                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                          />
                                        </svg>
                                      </Button>
                                    </OverlayTrigger>
                                  </div>
                                </td>
                              </tr>
                            </>
                          ))}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
