import React, { Component, useState } from "react";
import { Navigate } from "react-router-dom";
import { SideMenu } from "./SideMenu";
import { LoadSpinner } from "./LoadSpinner";
import Chart from "chart.js/auto";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Bar, Doughnut, Line, Pie } from "react-chartjs-2";
import DatePicker from "react-date-picker";
import DateTimePicker from "react-datetime-picker";
import { CategoryScale } from "chart.js";

import {
  InputGroup,
  Stack,
  FormControl,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Nav,
  Table,
  Navbar,
  Modal,
} from "react-bootstrap";

import { Link } from "react-router-dom";

import CartContext from "./CartContext";

//https://www.c-sharpcorner.com/article/create-different-charts-in-react-using-chart-js-library/
export class Home extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      dashboardSummary: "",
      orderSummary: "",
      revenueSummary: "",
      customerSummary: "",
      totalCustomerAccessSummary: "",
      topProductsSummary: "",
      purchaseOrderSummary: "",
      purchaseOrderExpensesSummary: "",
      rawMaterialPurchaseOrderSummary: "",
      rawMaterialPurchaseOrderExpensesSummary: "",
      customDate: new Date(),
      dateSelection: "Today",
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      firstLoad: false,
      showElasticCart: true,
      data: {
        datasets: [
          {
            data: [10, 20, 30],
          },
        ],
        labels: ["Red", "Yellow", "Blue"],
      },
    };
    ChartJS.register(CategoryScale, ArcElement, Tooltip, Legend);
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDateSelectionChange = (e) => {
    const { name, value } = e.target;

    this.setState({ dateSelection: value }, () => {
      this.LoadDashboard();
    });
  };

  LoadDashboard = () => {
    this.GetDashboardSummary();
    this.GetOrderSummary();
    this.GetRevenueSummary();
    this.GetCustomerSummary();
    this.GetTopSellingProductsSummary();
    this.GetPurchaseOrderSummary();
    this.GetPurchasesOrderExpensesSummary();
    this.GetRawMaterialPurchaseOrderSummary();
    this.GetRawMaterialPurchasesOrderExpensesSummary();
    this.GetTotalCustomerAccessSummary();
  };

  GetDashboardSummary = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetDashboardSummary/" +
        this.context.storeId +
        "/" +
        this.state.dateSelection +
        "/" +
        this.context.GetTicks(this.state.customDate)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ dashboardSummary: data });
        }
      })
      .catch((error) => {});
  };

  GetOrderSummary = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetOrderSummary/" +
        this.context.storeId +
        "/" +
        this.state.dateSelection +
        "/" +
        this.context.GetTicks(this.state.customDate)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          data.datasets[0].label = "Orders";
          data.datasets[0].borderColor = "rgb(0, 192, 0)";
          data.datasets[0].backgroundColor = "rgb(0, 0, 192)";
          data.datasets[0].fill = false;
          data.datasets[0].lineTension = 0.1;
          this.setState({ orderSummary: data });
        }
      })
      .catch((error) => {
        // alert("GetOrderSummary Error");
      });
  };

  GetRevenueSummary = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetRevenueSummary/" +
        this.context.storeId +
        "/" +
        this.state.dateSelection +
        "/" +
        this.context.GetTicks(this.state.customDate)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          data.datasets[0].label = "Revenues";
          data.datasets[0].borderColor = "rgb(0, 255, 0)";
          data.datasets[0].backgroundColor = "rgb(0, 255, 0)";
          data.datasets[0].fill = false;
          data.datasets[0].lineTension = 0.1;
          this.setState({ revenueSummary: data });
        }
      })
      .catch((error) => {
        // alert("GetRevenueSummary Error");
      });
  };

  GetPurchaseOrderSummary = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetPurchaseOrderSummary/" +
        this.context.storeId +
        "/" +
        this.state.dateSelection +
        "/" +
        this.context.GetTicks(this.state.customDate)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          data.datasets[0].label = "Orders";
          data.datasets[0].borderColor = "rgb(255, 0, 0)";
          data.datasets[0].backgroundColor = "rgb(100, 100, 100)";
          data.datasets[0].fill = false;
          data.datasets[0].lineTension = 0.01;
          this.setState({ purchaseOrderSummary: data });
        }
      })
      .catch((error) => {
        // alert("GetOrderSummary Error");
      });
  };
  GetPurchasesOrderExpensesSummary = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetPurchasesOrderExpensesSummary/" +
        this.context.storeId +
        "/" +
        this.state.dateSelection +
        "/" +
        this.context.GetTicks(this.state.customDate)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          data.datasets[0].label = "Revenues";
          data.datasets[0].borderColor = "rgb(0, 255, 0)";
          data.datasets[0].backgroundColor = "rgb(255, 0, 0)";
          data.datasets[0].fill = false;
          data.datasets[0].lineTension = 0.1;
          this.setState({ purchaseOrderExpensesSummary: data });
        }
      })
      .catch((error) => {
        // alert("GetRevenueSummary Error");
      });
  };

  GetRawMaterialPurchaseOrderSummary = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetRawMaterialPurchaseOrderSummary/" +
        this.context.storeId +
        "/" +
        this.state.dateSelection +
        "/" +
        this.context.GetTicks(this.state.customDate)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          data.datasets[0].label = "Orders";
          data.datasets[0].borderColor = "rgb(255, 0, 0)";
          data.datasets[0].backgroundColor = "rgb(100, 100, 100)";
          data.datasets[0].fill = false;
          data.datasets[0].lineTension = 0.01;
          this.setState({ rawMaterialPurchaseOrderSummary: data });
        }
      })
      .catch((error) => {
        // alert("GetOrderSummary Error");
      });
  };
  GetRawMaterialPurchasesOrderExpensesSummary = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetRawMaterialPurchasesOrderExpensesSummary/" +
        this.context.storeId +
        "/" +
        this.state.dateSelection +
        "/" +
        this.context.GetTicks(this.state.customDate)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          data.datasets[0].label = "Revenues";
          data.datasets[0].borderColor = "rgb(0, 255, 0)";
          data.datasets[0].backgroundColor = "rgb(255, 0, 0)";
          data.datasets[0].fill = false;
          data.datasets[0].lineTension = 0.1;
          this.setState({ rawMaterialPurchaseOrderExpensesSummary: data });
        }
      })
      .catch((error) => {
        // alert("GetRevenueSummary Error");
      });
  };

  GetCustomerSummary = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetCustomerSummary/" +
        this.context.storeId +
        "/" +
        this.state.dateSelection +
        "/" +
        this.context.GetTicks(this.state.customDate)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          data.datasets[0].label = "Customers";
          data.datasets[0].borderColor = "rgb(255, 0, 0)";
          data.datasets[0].backgroundColor = "rgb(0, 192, 0)";
          data.datasets[0].fill = false;
          data.datasets[0].lineTension = 0.1;
          this.setState({ customerSummary: data });
        }
      })
      .catch((error) => {
        // alert("GetCustomersSummary Error");
      });
  };
  GetTotalCustomerAccessSummary = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetTotalCustomerAccessSummary/" +
        this.context.storeId +
        "/" +
        this.state.dateSelection +
        "/" +
        this.context.GetTicks(this.state.customDate)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          data.datasets[0].label = "Customers";
          data.datasets[0].borderColor = "rgb(255, 0, 0)";
          data.datasets[0].backgroundColor = "rgb(0, 192, 0)";
          data.datasets[0].fill = false;
          data.datasets[0].lineTension = 0.1;
          this.setState({ totalCustomerAccessSummary: data });
        }
      })
      .catch((error) => {});
  };
  GetTopSellingProductsSummary = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetTopSellingProductsSummary/" +
        this.context.storeId +
        "/" +
        this.state.dateSelection +
        "/" +
        this.context.GetTicks(this.state.customDate)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          // data.datasets[0].backgroundColor = ["#2FDE00", "#00A6B4", "#ff6600"];
          // data.datasets[0].hoverBackgroundColor = [
          //   "#175000",
          //   "#003350",
          //   "#993d00",
          // ];
          data.datasets[0].label = "products";
          data.datasets[0].borderColor = "rgb(100, 100, 100)";
          data.datasets[0].backgroundColor = "rgb(0, 0, 100)";
          this.setState({ topProductsSummary: data });
        }
      })
      .catch((error) => {
        // alert("GetCustomersSummary Error");
      });
  };

  fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 2000));
  };
  fakeRequest1 = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 2000));
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.context.prods.length == 0) {
      this.fakeRequest1().then(() => {
        this.setState({ showElasticCart: false }); // showing the app
      });
      this.fakeRequest().then(() => {
        this.setState({ loadSpinnerOpen: false }); // showing the app
      });
    } else {
      this.setState({ showElasticCart: false }); // showing the app
      this.setState({ loadSpinnerOpen: false }); // showing the app
    }

    if (this.state.dashboardSummary == "" && this.context.storeId != "") {
      if (this.state.firstLoad == false) {
        this.state.firstLoad = true;
        this.GetDashboardSummary();
        this.GetOrderSummary();
        this.GetRevenueSummary();
        this.GetCustomerSummary();
        this.GetTopSellingProductsSummary();
        this.GetPurchaseOrderSummary();
        this.GetPurchasesOrderExpensesSummary();
        this.GetRawMaterialPurchaseOrderSummary();
        this.GetRawMaterialPurchasesOrderExpensesSummary();
        this.GetTotalCustomerAccessSummary();
      }
    }
  }

  divLoaded = (event) => {
    // alert(event.target.previousSibling.previousSibling.id);
  };

  render() {
    if (this.state.showElasticCart == true) {
      return (
        <>
          <ElasticCartAd></ElasticCartAd>
        </>
      );
    }
    if (this.context.userData.loginStatus != "active") {
      return <Navigate to="/UserLogin" />;
    }

    return (
      <>
        <div
          id="element_to_watch"
          className="Container-fluid mx-0 px-0"
          style={{
            backgroundImage: `background-image: url("data:image/svg+xml,<svg id='patternId' width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><defs><pattern id='a' patternUnits='userSpaceOnUse' width='40' height='40' patternTransform='scale(2) rotate(0)'><rect x='0' y='0' width='100%' height='100%' fill='hsla(0,0%,100%,1)'/><path d='M40 45a5 5 0 110-10 5 5 0 010 10zM0 45a5 5 0 110-10 5 5 0 010 10zM0 5A5 5 0 110-5 5 5 0 010 5zm40 0a5 5 0 110-10 5 5 0 010 10z'  stroke-width='1' stroke='hsla(153, 98%, 48%, 1)' fill='none'/><path d='M20 25a5 5 0 110-10 5 5 0 010 10z'  stroke-width='1' stroke='hsla(175, 98%, 48%, 1)' fill='none'/></pattern></defs><rect width='800%' height='800%' transform='translate(0,0)' fill='url(%23a)'/></svg>")`,
          }}
        >
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          {this.context.userData.loginStatus == "active" ? (
            <>
              <Row>
                <Col className="bg-light " style={{ width: "10px" }}>
                  <SideMenu></SideMenu>
                </Col>
                <Col xs={10} className=" my-2 ">
                  <div style={{ textAlign: "start", fontSize: 18 }}>
                    <b>STORE DETAILS</b>
                  </div>
                  <Table className="border bg-primary text-warning m-0 p-0">
                    <tr className="m-0 p-0">
                      <td width="20%" className="border">
                        Store Name:{" "}
                        <span className=" text-white">
                          {this.context.store.name}
                        </span>
                      </td>
                      <td width="20%" className="border">
                        <div>
                          Store Expires on:{" "}
                          <span className="blink_me text-white">
                            {
                              this.context.GetInDateFormat(
                                new Date(this.context.store.storeEnd)
                              )

                              // new Date(this.context.store.storeEnd).getDate() +
                              //   "-" +
                              //   (new Date(
                              //     this.context.store.storeEnd
                              //   ).getMonth() +
                              //     1) +
                              //   "-" +
                              //   new Date(
                              //     this.context.store.storeEnd
                              //   ).getFullYear()
                            }
                          </span>
                        </div>
                      </td>
                      <td width="20%" className="border">
                        <div>
                          License Type:{" "}
                          <span className="text-white">
                            {this.context.store.isTrail == true
                              ? "Trial"
                              : "Licensed"}
                          </span>
                        </div>
                      </td>
                      <td width="40%" className="border">
                        <div>
                          Store Domain Link:{"  "}
                          <a
                            href={
                              "https://" + this.context.store.storeDomainName
                            }
                            target="_blank"
                          >
                            <u className="text-white">
                              {this.context.store.storeDomainName}
                            </u>
                          </a>
                        </div>
                      </td>
                    </tr>
                  </Table>

                  <Table className="m-0 p-0 ">
                    <tr className="border-0 text-start w-100 m-0 p-0">
                      <td
                        className="text-start  m-0 p-0"
                        style={{ textAlign: "start", fontSize: 18 }}
                        width="50%"
                      >
                        <b>SALES SUMMARY </b>
                      </td>
                      <td
                        width="30%"
                        className="text-center "
                        style={{ textAlign: "center" }}
                      >
                        <div className="text-center border-dark bg-white ">
                          <InputGroup
                            className="mb-1 "
                            style={{ justifyContent: "end", textAlign: "end" }}
                          >
                            <InputGroup.Text id="basic-addon1">
                              <small>by Date Range</small>
                            </InputGroup.Text>
                            <small>
                              <Form.Select
                                value={this.state.dateSelection}
                                onChange={(e) => {
                                  this.OnDateSelectionChange(e);
                                }}
                              >
                                <option value="Custom">Custom</option>
                                <option value="Today">Today</option>
                                <option value="Yesterday">Yesterday</option>
                                {/* <option value="Last 3 days">Last 3 days</option> */}
                                {/* <option value="This Week">This Week</option> */}
                                <option value="Month">This Month</option>
                                <option value="This Year">This Year</option>
                                <option value="Last Year">Last Year</option>
                                {/* <option value="LastYear">Last Year</option> */}
                                {/* <option value="ALL">ALL</option> */}
                              </Form.Select>
                            </small>
                          </InputGroup>
                        </div>
                      </td>
                      <td width="20%">
                        {this.state.dateSelection == "Custom" ? (
                          <>
                            <div className="mx-3">
                              <DatePicker
                                format="dd-MMM-yy"
                                onChange={(e) => {
                                  this.setState({ customDate: e }, () => {
                                    this.LoadDashboard();
                                  });
                                }}
                                value={this.state.customDate}
                              />
                            </div>
                          </>
                        ) : (
                          <></>
                        )}
                      </td>
                    </tr>
                  </Table>
                  <Table className="border bg-secondary text-warning">
                    <tr className="text-start">
                      <td width="20%" className=" ">
                        Total Orders :{" "}
                        <span className="text-white">
                          {this.state.dashboardSummary.totalOrders}
                        </span>
                      </td>
                      <td width="20%" className="border ">
                        Total Revenue :{" "}
                        <span className="text-white">
                          {Math.round(
                            this.state.dashboardSummary.totalRevenue,
                            0
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </span>
                      </td>
                      <td width="20%" className="border ">
                        Total Products :{" "}
                        <span className="text-white">
                          {this.state.dashboardSummary.totalProducts}
                        </span>
                      </td>
                      <td width="20%" className="border ">
                        Total Customers :{" "}
                        <span className="text-white">
                          {this.state.dashboardSummary.totalCustomers}
                        </span>
                      </td>
                      {/* <td width="20%" className="border ">
                      Total Registered Customers :{" "}
                      <span className="text-white">
                        {this.state.dashboardSummary.totalRegisteredCustomers}
                      </span>
                    </td>
                    <td width="20%" className="border ">
                      Total Guest Customers :
                      <span className="text-white">
                        {this.state.dashboardSummary.totalGuestCustomers}
                      </span>
                    </td> */}
                    </tr>
                  </Table>
                  <Table className="border-0 m-0 p-0 bg-white text-dark">
                    <tr>
                      <td width="50% " className="border">
                        <div className="text-center">
                          {" "}
                          <h5>
                            Total Orders Summery
                            {/* <br />- by{" "}
                            {this.state.dateSelection} */}
                          </h5>
                        </div>
                        {this.state.orderSummary == "" ? (
                          ""
                        ) : (
                          <>
                            <Bar
                              // type="bar"
                              width={160}
                              height={60}
                              options={{
                                title: {
                                  display: true,
                                  text: "",
                                  fontSize: 20,
                                },
                                legend: {
                                  display: true, //Is the legend shown?
                                  position: "top", //Position of the legend.
                                },
                              }}
                              data={this.state.orderSummary}
                            />
                          </>
                        )}
                      </td>
                      <td width="50%" className="border">
                        <div className="text-center">
                          {" "}
                          <h5>
                            Total Revenue Summery
                            {/* <br />- by{" "}
                            {this.state.dateSelection} */}
                          </h5>
                        </div>
                        {this.state.revenueSummary == "" ? (
                          ""
                        ) : (
                          <>
                            <Bar
                              type="bar"
                              width={160}
                              height={60}
                              options={{
                                title: {
                                  display: true,
                                  text: "COVID-19 Cases of Last 6 Months",
                                  fontSize: 20,
                                },
                                legend: {
                                  display: true, //Is the legend shown?
                                  position: "top", //Position of the legend.
                                },
                              }}
                              data={this.state.revenueSummary}
                            />
                          </>
                        )}
                      </td>
                    </tr>

                    <tr className="border">
                      <td width="50%" className="border">
                        <div className="text-center">
                          {" "}
                          <h5>
                            Total Product Purchase Orders(PO) Summary
                            {/* <br />- by{" "}
                            {this.state.dateSelection} */}
                          </h5>
                        </div>
                        {this.state.purchaseOrderSummary == "" ? (
                          ""
                        ) : (
                          <>
                            <Bar
                              // type="bar"
                              width={160}
                              height={60}
                              options={{
                                responsive: true,
                                maintainAspectRatio: true,
                                title: {
                                  display: true,
                                  text: "COVID-19 Cases of Last 6 Months",
                                  fontSize: 20,
                                },
                                legend: {
                                  display: true, //Is the legend shown?
                                  position: "top", //Position of the legend.
                                },
                              }}
                              data={this.state.purchaseOrderSummary}
                            />
                          </>
                        )}
                      </td>
                      <td
                        width="50%"
                        className="border"
                        style={{ height: "5cm" }}
                      >
                        <div className="text-center">
                          {" "}
                          <h5>
                            Total Product PurchaseOrders(PO) Expenses Summary{" "}
                            {/* <br />- by {this.state.dateSelection} */}
                          </h5>
                        </div>
                        {this.state.purchaseOrderExpensesSummary == "" ? (
                          ""
                        ) : (
                          <>
                            <Bar
                              // type="pie"
                              width={100}
                              height={40}
                              options={{
                                responsive: true,
                                maintainAspectRatio: true,
                                title: {
                                  display: true,
                                  text: "COVID-19 Cases of Last 3 Months",
                                  fontSize: 15,
                                },
                                legend: {
                                  display: true, //Is the legend shown?
                                  position: "top", //Position of the legend.
                                },
                              }}
                              data={this.state.purchaseOrderExpensesSummary}
                            />
                          </>
                        )}
                      </td>
                    </tr>

                    <tr className="border">
                      <td width="50%" className="border">
                        <div className="text-center">
                          {" "}
                          <h5>
                            Total RawMaterials Purchase Order(PO) Summary <br />
                            - by {this.state.dateSelection}
                          </h5>
                        </div>
                        {this.state.rawMaterialPurchaseOrderSummary == "" ? (
                          ""
                        ) : (
                          <>
                            <Bar
                              // type="bar"
                              width={160}
                              height={60}
                              options={{
                                responsive: true,
                                maintainAspectRatio: true,
                                title: {
                                  display: true,
                                  text: "COVID-19 Cases of Last 6 Months",
                                  fontSize: 20,
                                },
                                legend: {
                                  display: true, //Is the legend shown?
                                  position: "top", //Position of the legend.
                                },
                              }}
                              data={this.state.rawMaterialPurchaseOrderSummary}
                            />
                          </>
                        )}
                      </td>
                      <td
                        width="50%"
                        className="border"
                        style={{ height: "5cm" }}
                      >
                        <div className="text-center">
                          {" "}
                          <h5>
                            Total RawMaterials PurchaseOrders(PO) Expenses
                            Summary
                            {/* <br />- by {this.state.dateSelection} */}
                          </h5>
                        </div>
                        {this.state.rawMaterialPurchaseOrderExpensesSummary ==
                        "" ? (
                          ""
                        ) : (
                          <>
                            <Bar
                              // type="pie"
                              width={100}
                              height={40}
                              options={{
                                responsive: true,
                                maintainAspectRatio: true,
                                title: {
                                  display: true,
                                  text: "COVID-19 Cases of Last 3 Months",
                                  fontSize: 15,
                                },
                                legend: {
                                  display: true, //Is the legend shown?
                                  position: "top", //Position of the legend.
                                },
                              }}
                              data={
                                this.state
                                  .rawMaterialPurchaseOrderExpensesSummary
                              }
                            />
                          </>
                        )}
                      </td>
                    </tr>

                    <tr className="border">
                      <td width="50%" className="border">
                        <div className="text-center">
                          {" "}
                          <h5>
                            Newly Registered Customers
                            {/* <br />- by
                            {this.state.dateSelection} */}
                          </h5>
                        </div>
                        {this.state.customerSummary == "" ? (
                          ""
                        ) : (
                          <>
                            <Bar
                              type="bar"
                              width={160}
                              height={60}
                              options={{
                                title: {
                                  display: true,
                                  text: "COVID-19 Cases of Last 6 Months",
                                  fontSize: 20,
                                },
                                legend: {
                                  display: true, //Is the legend shown?
                                  position: "top", //Position of the legend.
                                },
                              }}
                              data={this.state.customerSummary}
                            />
                          </>
                        )}
                      </td>
                      <td
                        width="50%"
                        className="border"
                        style={{ height: "5cm" }}
                      >
                        <div className="text-center">
                          {" "}
                          <h5>
                            TOP 15 Selling Products
                            {/* <br />- by{" "}
                            {this.state.dateSelection} */}
                          </h5>
                        </div>
                        {this.state.topProductsSummary == "" ? (
                          ""
                        ) : (
                          <>
                            <Line
                              // type="pie"
                              width={100}
                              height={40}
                              options={{
                                responsive: true,
                                maintainAspectRatio: true,
                                title: {
                                  display: true,
                                  text: "COVID-19 Cases of Last 3 Months",
                                  fontSize: 15,
                                },
                                legend: {
                                  display: true, //Is the legend shown?
                                  position: "top", //Position of the legend.
                                },
                              }}
                              data={this.state.topProductsSummary}
                            />
                          </>
                        )}
                      </td>
                    </tr>

                    <tr className="border">
                      <td colSpan={2} className="border">
                        <div className="text-center">
                          <h5>
                            Total Customers Accessed Online Store/Shop
                            {/* <br />-
                            by &nbsp;{this.state.dateSelection} */}
                          </h5>
                        </div>
                        {this.state.totalCustomerAccessSummary == "" ? (
                          ""
                        ) : (
                          <>
                            <Bar
                              type="bar"
                              height={60}
                              options={{
                                title: {
                                  display: true,
                                  text: "COVID-19 Cases of Last 6 Months",
                                  fontSize: 20,
                                },
                                legend: {
                                  display: true, //Is the legend shown?
                                  position: "top", //Position of the legend.
                                },
                              }}
                              data={this.state.totalCustomerAccessSummary}
                            />
                          </>
                        )}
                      </td>
                    </tr>
                  </Table>
                </Col>
              </Row>
            </>
          ) : (
            ""
          )}
        </div>
        <iframe
          style={{ display: "none", width: "0", height: "0" }}
          onLoad={(e) => this.divLoaded(e)}
        ></iframe>
      </>
    );
  }
}

class ElasticCartAd extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <Modal
          {...this.props}
          size="lg"
          // aria-labelledby="contained-modal-title-vcenter"
          centered
          // backdrop="static"
          // keyboard={false}
          show={true}
          className="spinnermodal-backdrop m-0 p-0 bg-white  border-0"
          // dialogClassName="payByCash"
        >
          <Modal.Body
            className="text-center m-0 p-0 bg-white border-0"
            style={{ backgroundColor: "transparant" }}
          >
            <div className="text-center m-0 p-0 border-0">
              {/* <h1>Welcome to ElasticCART!</h1> */}
              <img
                src="./elasticcartad.png"
                className="w-100 CornersRounded border-0"
                alt="..."
                width="100%"
                // height="500"
                style={{ objectFit: "contain" }}
              />{" "}
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
