import React, { Component } from "react";
import CartContext from "./CartContext";
import { BsFillPencilFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import Select from "react-select";
import { ProductServiceCustomerAddOrEditModal } from "./ProductServiceCustomers";
import DateTimePicker from "react-datetime-picker";
import "../CSS/DateTimePicker.css";
import "../CSS/Calendar.css";
import "../CSS/Clock.css";
import { ProductServiceTasks } from "./ProductServiceTasks";
import { ProductServiceRawMaterials } from "./ProductServiceRawmaterials";
import axios from "axios";

import {
  Tabs,
  Stack,
  OverlayTrigger,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Modal,
  Nav,
  Table,
  Navbar,
  Tab,
} from "react-bootstrap";

export class ProductServicingAddOrEditModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      productservicingRequest: {
        customerId: "",
        PsrNo: "",
        productName: "",
        productModel: "",
        serviceStart: new Date(),
        plannedDeliveryDate: new Date(),
        productNo: "",
        status: "1",
        statusWofklowDescription: "",
        createdBy: "",
        assignedTo: "",
        insuranceClaimService: false,
        insuranceCompanyName: "",
        insuranceNumber: "",
        insuranceExpiryDate: new Date(),
        insurancePaidAmount: "0",
        serviceCost1: "0",
        serviceCost1Description: "",
        serviceCost2: "0",
        serviceCost2Description: "",
        serviceCost3: "0",
        serviceCost3Description: "",
        otherCost: "0",
        otherCost1Description: "0",
        discountPercentage: "0",
        sgstPercentage: "0",
        cgstPercentage: "0",
        customerPaidAmount: "0",
      },
      previousStatus:
        this.props.Type == "Edit"
          ? this.props.productservicingRequest.status
          : "",
      firstload: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      productNames: [],
      customerDetails: "",
      mobileNo: "",
      addModalShow: false,

      productservicingTasks: [],
      showProductServicingTaskModal: false,
      editProductServicingTaskRow: 0,
      editProductServicingTaskModalShow: false,

      productservicingRawMaterials: [],
      showProductServicingRawMaterialModal: false,
      editProductServicingRawMaterialRow: 0,
      editProductServicingRawMaterialModalShow: false,
    };
  }

  GetServiceInvoiceHtml = (e) => {
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "ManufactureAndService/GetServiceInvoiceHtml/" +
          this.context.storeId +
          "/" +
          this.state.productservicingRequest.id
      )
      .then((response) => {
        if (response.status == 404) {
        } else if (response.status == 200) {
          // this.setState({ invoiceHtml: response.data });
          this.PrintTable(response.data);
        } else {
        }
      })
      .catch((error) => {
        console.error("GetInvoiceHtml - There was an error! ", error);
      });
  };

  PrintTable = (invoiceHtml) => {
    var printWindow = window.open("", "_blank");
    printWindow.document.write(invoiceHtml);
    printWindow.document.close();
    this.onPrintFinished(printWindow.print(), printWindow);
  };

  getOS = () => {
    var uA = navigator.userAgent || navigator.vendor || window.opera;
    if (
      (/iPad|iPhone|iPod/.test(uA) && !window.MSStream) ||
      (uA.includes("Mac") && "ontouchend" in document)
    )
      return "iOS";

    var i,
      os = ["Windows", "Android", "Unix", "Mac", "Linux", "BlackBerry"];
    for (i = 0; i < os.length; i++)
      if (new RegExp(os[i], "i").test(uA)) return os[i];
  };

  onPrintFinished = (status, printWindow) => {
    var osvar = this.getOS();
    if (osvar == "Windows") printWindow.close();
  };

  GenerateServiceInvoice = (e) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      //body: JSON.stringify(tmp),
    };
    fetch(
      process.env.REACT_APP_API +
        "ManufactureAndService/GenerateServiceInvoice/" +
        this.context.storeId +
        "/" +
        this.state.productservicingRequest.id,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
          alert("Mobile Number Not Found!");
        } else {
          this.setState({ productservicingRequest: data }, () => {});
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  addModalClose = (e) => {
    if (e.id === undefined) {
      this.setState({ addModalShow: false }, () => {});
      return;
    }

    this.setState({ customerDetails: e }, () => {
      this.state.productservicingRequest.customerId = e.customerId;
      this.setState({ mobileNo: e.mobileNo });
      this.setState({
        productservicingRequest: this.state.productservicingRequest,
      });
      this.setState({ addModalShow: false }, () => {});
    });
  };
  LoadProductName = () => {
    {
      var pd = [];
      this.context.prods.map((r) =>
        pd.push({
          value: r.id,
          label: r.name,
        })
      );
      this.setState({ productNames: pd });
    }
  };
  GetProductName = (id) => {
    var list = this.state.productNames.filter((f) => f.value == id);
    if (list.length > 0) return list[0].label;
    return "";
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  GetProductServiceCustomersByMobileNo = (e) => {
    if (
      this.state.mobileNo == "" ||
      (this.state.mobileNo != "" && this.state.mobileNo.length != 10)
    ) {
      alert("Invalid MobileNo. Enter a valid Customer Mobile number and try!");
      return;
    }
    fetch(
      process.env.REACT_APP_API +
        "ManufactureAndService/GetProductServiceCustomersByMobileNo/" +
        this.context.storeId +
        "/" +
        this.state.mobileNo
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 404) {
          alert("This Number Not Found in DB!");
        } else {
          this.setState({ customerDetails: data }, () => {
            this.state.productservicingRequest.customerId = data.id;
            this.setState({
              productservicingRequest: this.state.productservicingRequest,
            });
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  GetProductServiceCustomer = (customerId) => {
    fetch(
      process.env.REACT_APP_API +
        "ManufactureAndService/GetProductServiceCustomer/" +
        customerId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
          alert("Phone number does not exist in DB");
        } else {
          this.setState({ customerDetails: data }, () => {
            this.setState({ mobileNo: data.mobileNo });
            this.state.productservicingRequest.customerId = data.id;
            this.setState({
              productservicingRequest: this.state.productservicingRequest,
            });
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  AddOrUpdateProductServiceRequest = () => {
    this.ShowLoadSpinner();

    var tmp = this.state.productservicingRequest;
    tmp.productServiceTasks = this.state.productservicingTasks;
    tmp.productServiceRawMaterials = this.state.productservicingRawMaterials;

    if (this.props.Type == "Add") {
      tmp.storeId = this.context.storeId;
      tmp.statusWofklowDescription =
        "<p>" +
        this.context.GetInDateFormat(new Date()) +
        "=> " +
        "NewRequest" +
        "</p>";
    } else {
      if (
        this.state.previousStatus != this.state.productservicingRequest.status
      ) {
        tmp.statusWofklowDescription +=
          "<p>" +
          this.context.GetInDateFormat(new Date()) +
          "=> " +
          this.context.productServiceStatusTypes.filter(
            (d) => d.id == this.state.productservicingRequest.status
          )[0].name +
          "</p>";
      }
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmp),
    };

    fetch(
      process.env.REACT_APP_API +
        "ManufactureAndService/AddOrUpdateProductServiceRequest/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.HideLoadSpinner();
          this.props.onHide();
          return;
        } else {
          alert("Error Code: " + res.status);
          this.HideLoadSpinner();
          return;
        }
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("Exception. Received an Exception. Try after sometime !" + error);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.AddOrUpdateProductServiceRequest();
  };

  onServiceStartChange = (date) => {
    this.state.productservicingRequest.serviceStart = date;
    this.setState({
      productservicingRequest: this.state.productservicingRequest,
    });
  };

  onPlannedDeliveryChange = (date) => {
    this.state.productservicingRequest.plannedDeliveryDate = date;
    this.setState({
      productservicingRequest: this.state.productservicingRequest,
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "mobileNo":
        this.setState({ mobileNo: value });
        return;
      case "productName":
        this.state.productservicingRequest.productName = value;
        break;
      case "productModel":
        this.state.productservicingRequest.productModel = value;
        break;
      case "productNo":
        this.state.productservicingRequest.productNo = value;
        break;
      case "productDescription":
        this.state.productservicingRequest.productDescription = value;
        break;
      case "status":
        this.state.productservicingRequest.status = value;
        break;
      case "createdBy":
        this.state.productservicingRequest.createdBy = value;
        break;
      case "assignedTo":
        this.state.productservicingRequest.assignedTo = value;
        break;
      case "insuranceClaimService":
        this.state.productservicingRequest.insuranceClaimService = value;
        break;
      case "insuranceCompanyName":
        this.state.productservicingRequest.insuranceCompanyName = value;
        break;
      case "insuranceNumber":
        this.state.productservicingRequest.insuranceNumber = value;
        break;
      case "insuranceExpiryDate":
        this.state.productservicingRequest.insuranceExpiryDate = value;
        break;
      case "insurancePaidAmount":
        this.state.productservicingRequest.insurancePaidAmount = value;
        break;
      case "serviceCost1":
        this.state.productservicingRequest.serviceCost1 = value;
        break;
      case "serviceCost1Description":
        this.state.productservicingRequest.serviceCost1Description = value;
        break;
      case "serviceCost2":
        this.state.productservicingRequest.serviceCost2 = value;
        break;
      case "serviceCost2Description":
        this.state.productservicingRequest.serviceCost2Description = value;
        break;
      case "serviceCost3":
        this.state.productservicingRequest.serviceCost3 = value;
        break;
      case "serviceCost3Description":
        this.state.productservicingRequest.serviceCost3Description = value;
        break;
      case "otherCost":
        this.state.productservicingRequest.otherCost = value;
        break;
      case "otherCost1Description":
        this.state.productservicingRequest.otherCost1Description = value;
        break;
      case "discountPercentage":
        this.state.productservicingRequest.discountPercentage = value;
        break;
      case "sgstPercentage":
        this.state.productservicingRequest.sgstPercentage = value;
        break;
      case "cgstPercentage":
        this.state.productservicingRequest.cgstPercentage = value;
        break;
      case "customerFeedback":
        this.state.productservicingRequest.customerFeedback = value;
        break;
      case "customerRatings":
        this.state.productservicingRequest.customerRatings = value;
        break;
      // case "customerPaidAmount":
      //   this.state.productservicingRequest.customerPaidAmount = value;
      //   break;
    }
    this.state.productservicingRequest.customerPaidAmount =
      this.GetTotalAmountToPayByCustomer();

    this.setState({
      productservicingRequest: this.state.productservicingRequest,
    });
  };

  GetTotalAfterDiscount = () => {
    var TotalRawMaterialCost = this.GetRawMaterialsTotalCost();

    var TotalSrviceCost =
      Number(this.state.productservicingRequest.serviceCost1) +
      Number(this.state.productservicingRequest.serviceCost2) +
      Number(this.state.productservicingRequest.serviceCost3) +
      Number(this.state.productservicingRequest.otherCost);
    var Total = TotalRawMaterialCost + TotalSrviceCost;
    var TotalAfterDiscount =
      Total -
      (Total * Number(this.state.productservicingRequest.discountPercentage)) /
        100;
    return TotalAfterDiscount;
  };

  GetTotalAmountToPayByCustomer = () => {
    var TotalAfterDiscount = this.GetTotalAfterDiscount();
    var SgstPercentage = this.context.taxDetails.productServiceSgstPercentage;
    var CgstPercentage = this.context.taxDetails.productServiceCgstPercentage;

    var SgstCharges = (TotalAfterDiscount * SgstPercentage) / 100;
    var CgstCharges = (TotalAfterDiscount * CgstPercentage) / 100;

    var TotalAfterGST =
      TotalAfterDiscount +
      SgstCharges +
      CgstCharges -
      this.state.productservicingRequest.insurancePaidAmount;

    return TotalAfterGST;
  };

  OnProductNameChange = (e) => {
    this.state.productservicingRequest.productId = e.value;
    this.setState({
      productservicingRequest: this.state.productservicingRequest,
    });
  };

  UpdateProductServicingTask = (item, index) => {
    this.state.productservicingTasks[index].taskId = item.taskId;
    this.state.productservicingTasks[index].taskDescription =
      item.taskDescription;
    this.state.productservicingTasks[index].assignedTo = item.assignedTo;
    this.state.productservicingTasks[index].statusId = item.statusId;
    this.state.productservicingTasks[index].seqNo = item.seqNo;

    this.setState(
      {
        productservicingTasks: this.state.productservicingTasks,
      },
      () => {
        this.setState({ editProductServicingTaskModalShow: false }, () => {});
      }
    );
  };

  AddProductServicingTask = (item) => {
    this.setState(
      {
        productservicingTasks: [
          ...this.state.productservicingTasks,
          {
            taskId: item.taskId,
            taskDescription: item.taskDescription,
            assignedTo: item.assignedTo,
            statusId: item.statusId,
            seqNo: this.state.productservicingTasks.length + 1,
          },
        ],
      },
      () => {
        this.onHideProductServicingTaskModal();
      }
    );
  };
  onHideProductServicingTaskModal = () => {
    this.setState({ showProductServicingTaskModal: false });
  };

  handleProductServicingTaskAddItems = (e) => {
    this.setState({ showProductServicingTaskModal: true });
  };

  onHideProductServicingTaskEditModal = () => {
    this.setState({ editProductServicingTaskModalShow: false });
  };
  OnProductServicingTaskEditButtonClick = (e, i) => {
    this.setState({ editProductServicingTaskRow: i }, () => {
      this.setState({ editProductServicingTaskModalShow: true }, () => {});
    });
  };
  handleProductServicingTaskRemoveClick = (indexno) => {
    const list = [...this.state.productservicingTasks];
    list.splice(indexno, 1);
    this.setState({ productservicingTasks: list }, () => {});
  };

  UpdateProductServicingRawMaterial = (item, index) => {
    this.state.productservicingRawMaterials[index].rawMaterialId =
      item.rawMaterialId;
    this.state.productservicingRawMaterials[index].price = item.price;
    this.state.productservicingRawMaterials[index].qty = item.qty;
    this.state.productservicingRawMaterials[index].details = item.details;
    this.state.productservicingRawMaterials[index].statusId = item.statusId;

    this.setState(
      {
        productservicingRawMaterials: this.state.productservicingRawMaterials,
      },
      () => {
        this.setState(
          { editProductServicingRawMaterialModalShow: false },
          () => {}
        );
      }
    );
  };

  AddProductServicingRawMaterial = (item) => {
    this.setState(
      {
        productservicingRawMaterials: [
          ...this.state.productservicingRawMaterials,
          {
            rawMaterialId: item.rawMaterialId,
            price: item.price,
            qty: item.qty,
            details: item.details,
            statusId: item.statusId,
          },
        ],
      },
      () => {
        this.onHideProductServicingRawMaterialModal();
      }
    );
  };
  onHideProductServicingRawMaterialModal = () => {
    this.setState({ showProductServicingRawMaterialModal: false });
  };

  handleProductServicingRawMaterialAddItems = (e) => {
    this.setState({ showProductServicingRawMaterialModal: true });
  };

  onHideProductServicingRawMaterialEditModal = () => {
    this.setState({ editProductServicingRawMaterialModalShow: false });
  };
  OnProductServicingRawMaterialEditButtonClick = (e, i) => {
    this.setState({ editProductServicingRawMaterialRow: i }, () => {
      this.setState(
        { editProductServicingRawMaterialModalShow: true },
        () => {}
      );
    });
  };
  handleProductServicingRawMaterialRemoveClick = (indexno) => {
    const list = [...this.state.productservicingRawMaterials];
    list.splice(indexno, 1);
    this.setState({ productservicingRawMaterials: list }, () => {});
  };
  GetRawMaterialName = (id) => {
    var list = this.props.rawMaterialsInventory.filter((f) => f.id == id);
    if (list.length > 0) return list[0].name;
    return "";
  };

  onInsuranceExpiryDateChange = (date) => {
    this.state.productservicingRequest.insuranceExpiryDate = date;
    this.setState({
      productservicingRequest: this.state.productservicingRequest,
    });
  };

  GetServiceTaskName = (id) => {
    var list = this.props.serviceTasksInventory.filter((f) => f.id == id);
    if (list.length > 0) return list[0].taskName;
    return "";
  };
  handleOnInsuranceClaimService = (e) => {
    this.state.productservicingRequest.insuranceClaimService = e.target.checked;
    this.setState({
      productservicingRequest: this.state.productservicingRequest,
    });
  };
  GetUserNameById = (id) => {
    var result = this.props.users.filter((f) => f.id == id);
    if (result.length > 0) return result[0].emailId;
    else return "";
  };
  GetRawMaterialsTotalCost = () => {
    let sum = 0;
    this.state.productservicingRawMaterials.forEach((v) => {
      sum += v.price * v.qty;
    });
    return sum;
  };
  OnChangeSeqNoOfTasks = (e, i) => {
    const { name, value } = e.target;
    this.state.productservicingTasks[i].seqNo = value;
    this.setState({ productservicingTasks: this.state.productservicingTasks });
  };
  componentDidMount() {
    if (this.state.firstload == false) {
      this.state.firstload = true;

      if (this.props.Type == "Edit") {
        this.GetProductServiceCustomer(
          this.props.productservicingRequest.customerId
        );
        this.setState({
          //productservicingRequest: this.props.productservicingRequest,
          productservicingRequest: JSON.parse(
            JSON.stringify(this.props.productservicingRequest)
          ),
        });
        this.setState(
          {
            productservicingTasks:
              this.props.productservicingRequest.productServiceTasks,
            // .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1)),
          },
          () => {}
        );
        this.setState({
          productservicingRawMaterials:
            this.props.productservicingRequest.productServiceRawMaterials,
        });
      } else {
        this.state.productservicingRequest.createdBy = this.context.userData.id;
        this.setState({
          productservicingRequest: this.state.productservicingRequest,
        });
      }
    }
  }
  componentDidUpdate = () => {
    var d1 = document.getElementById("one");
    if (d1) {
      d1.innerHTML =
        this.props.productservicingRequest != undefined
          ? this.props.productservicingRequest.statusWofklowDescription
          : "";
    }
  };

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          //fullscreen={"xxl-down"}
          //size="xxl"
          backdrop="static"
          keyboard={false}
          dialogClassName="productservice-modal"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-primary text-light">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit"
                  ? "Edit Product Service Request(PSR)"
                  : "Add Product Service Request(PSR)"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              {this.state.showProductServicingTaskModal == true ? (
                <>
                  <ProductServiceTasks
                    onHide={this.onHideProductServicingTaskModal}
                    AddProductServicingTask={this.AddProductServicingTask}
                    users={this.props.users}
                    Type="Add"
                    serviceTasksInventory={this.props.serviceTasksInventory}
                  ></ProductServiceTasks>
                </>
              ) : (
                <></>
              )}
              {this.state.editProductServicingTaskModalShow == true ? (
                <>
                  <ProductServiceTasks
                    onHide={this.onHideProductServicingTaskEditModal}
                    UpdateProductServicingTask={this.UpdateProductServicingTask}
                    Type="Edit"
                    serviceTasksInventory={this.props.serviceTasksInventory}
                    users={this.props.users}
                    productservicingTask={
                      this.state.productservicingTasks[
                        this.state.editProductServicingTaskRow
                      ]
                    }
                    Index={this.state.editProductServicingTaskRow}
                  ></ProductServiceTasks>
                </>
              ) : (
                <></>
              )}
              {this.state.showProductServicingRawMaterialModal == true ? (
                <>
                  <ProductServiceRawMaterials
                    onHide={this.onHideProductServicingRawMaterialModal}
                    AddProductServicingRawMaterial={
                      this.AddProductServicingRawMaterial
                    }
                    Type="Add"
                    rawMaterialsInventory={this.props.rawMaterialsInventory}
                  ></ProductServiceRawMaterials>
                </>
              ) : (
                <></>
              )}
              {this.state.editProductServicingRawMaterialModalShow == true ? (
                <>
                  <ProductServiceRawMaterials
                    onHide={this.onHideProductServicingRawMaterialEditModal}
                    UpdateProductServicingRawMaterial={
                      this.UpdateProductServicingRawMaterial
                    }
                    Type="Edit"
                    productservicingRawMaterial={
                      this.state.productservicingRawMaterials[
                        this.state.editProductServicingRawMaterialRow
                      ]
                    }
                    Index={this.state.editProductServicingRawMaterialRow}
                    rawMaterialsInventory={this.props.rawMaterialsInventory}
                  ></ProductServiceRawMaterials>
                </>
              ) : (
                <></>
              )}
              {this.state.addModalShow === true ? (
                <ProductServiceCustomerAddOrEditModal
                  show={this.state.addModalShow}
                  Type="Add"
                  onHide={this.addModalClose}
                />
              ) : null}
              <h4>
                <b>Customer Details</b>
              </h4>
              <Row
                className="m-2 p-2 bg-light border"
                style={{ fontSize: "9pt" }}
              >
                <Col md={6}>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Customer MobileNo:
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="mobileNo"
                        value={this.state.mobileNo}
                        required
                        readOnly={
                          this.state.customerDetails == "" ? false : true
                        }
                        placeholder="Enter 10 digit mobile number"
                        onChange={(e) => this.handleInputChange(e)}
                      ></Form.Control>
                      {this.state.customerDetails == "" ? (
                        <>
                          <Stack direction="horizontal">
                            <Button
                              onClick={(e) =>
                                this.GetProductServiceCustomersByMobileNo(e)
                              }
                            >
                              Get Customer Details
                            </Button>{" "}
                            &nbsp;
                            <Button
                              onClick={() =>
                                this.setState({ addModalShow: true })
                              }
                            >
                              Add If New Customer
                            </Button>
                          </Stack>
                        </>
                      ) : (
                        <></>
                      )}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Customer Name:
                    </Form.Label>
                    <Col sm="9">
                      <b>
                        {this.state.customerDetails != ""
                          ? this.state.customerDetails.firstName +
                            " " +
                            this.state.customerDetails.lastName
                          : ""}
                      </b>
                    </Col>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Customer Address:
                    </Form.Label>
                    <Col sm="9">
                      {this.state.customerDetails != ""
                        ? this.state.customerDetails.address +
                          "," +
                          this.state.customerDetails.city +
                          "," +
                          this.state.customerDetails.state +
                          "," +
                          this.state.customerDetails.country
                        : ""}
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      EmailId:
                    </Form.Label>
                    <Col sm="9">
                      {this.state.customerDetails != ""
                        ? this.state.customerDetails.emailId
                        : ""}
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
              {this.state.customerDetails == "" ? (
                <></>
              ) : (
                <>
                  <h4>
                    <b>Service Details</b>
                  </h4>

                  <Row
                    className="m-2 p-2 bg-light border"
                    style={{ fontSize: "9pt" }}
                  >
                    <Col md={5}>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="3">
                          PSR No:
                        </Form.Label>
                        <Col sm="9">
                          <b>
                            <Form.Control
                              type="text"
                              size="lg"
                              className="pd-2 w-100"
                              name="psrNo"
                              required
                              readOnly
                              value={this.state.productservicingRequest.psrNo}
                              placeholder=""
                              autoComplete="off"
                            />
                          </b>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="3">
                          Product Name
                        </Form.Label>
                        <Col sm="9">
                          <span id="productname">
                            <Form.Control
                              type="text"
                              size="lg"
                              name="productName"
                              required
                              className="pd-2 w-100"
                              value={
                                this.state.productservicingRequest.productName
                              }
                              onChange={(e) => this.handleInputChange(e)}
                            ></Form.Control>
                          </span>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="3">
                          Product Model
                        </Form.Label>
                        <Col sm="9">
                          <span id="productname">
                            <Form.Control
                              type="text"
                              size="lg"
                              name="productModel"
                              required
                              className="pd-2 w-100"
                              value={
                                this.state.productservicingRequest.productModel
                              }
                              onChange={(e) => this.handleInputChange(e)}
                            ></Form.Control>
                          </span>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="3">
                          Product Number
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            type="text"
                            size="lg"
                            name="productNo"
                            required
                            className="pd-2 w-100"
                            value={this.state.productservicingRequest.productNo}
                            onChange={(e) => this.handleInputChange(e)}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col md={4}>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="3">
                          Service Start
                        </Form.Label>
                        <Col sm="9">
                          <DateTimePicker
                            onChange={this.onServiceStartChange}
                            value={
                              this.state.productservicingRequest.serviceStart
                            }
                            format="dd-MMM-yy hh:mm a"
                            amPmAriaLabel
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="3">
                          Planned Delivery
                        </Form.Label>
                        <Col sm="9">
                          <DateTimePicker
                            onChange={this.onPlannedDeliveryChange}
                            value={
                              this.state.productservicingRequest
                                .plannedDeliveryDate
                            }
                            format="dd-MMM-yy hh:mm a"
                          />
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="3">
                          Created By (*)
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            type="text"
                            size="lg"
                            className="pd-2 w-100"
                            name="createdBy"
                            disabled
                            value={this.context.userData.emailId}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          ></Form.Control>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="3">
                          Assigned To (*)
                        </Form.Label>
                        <Col sm="9">
                          <Form.Select
                            type="text"
                            size="lg"
                            className="pd-2 w-100"
                            name="assignedTo"
                            required
                            value={
                              this.state.productservicingRequest.assignedTo
                            }
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            {this.props.users.map((d) => (
                              <>
                                <option value={d.id}>{d.emailId}</option>
                              </>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>

                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="3">
                          Status (*)
                        </Form.Label>
                        <Col sm="9">
                          <Form.Select
                            type="text"
                            size="lg"
                            className="pd-2 w-100"
                            name="status"
                            required
                            value={this.state.productservicingRequest.status}
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            {this.context.productServiceStatusTypes.map((d) => (
                              <option value={d.id}>
                                {d.id + "." + d.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col md={3} className="bg-dark text-white">
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Col sm="12">
                          <h5>
                            <b>
                              <u>Status Workflow</u>
                            </b>
                          </h5>
                          <div id="one" className=""></div>
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  {/* Insurance Details */}
                  <br />
                  <h4>
                    {" "}
                    <b>Insurance Details:</b>{" "}
                  </h4>
                  <Row className="m-0 bg-light p-2 border">
                    <Col md={6} className="text-start text-dark my-1">
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="4">
                          Insurance Cliam Applicable?
                        </Form.Label>
                        <Col sm="8">
                          <b>
                            <Form.Check
                              // type="radio"
                              checked={
                                this.state.productservicingRequest
                                  .insuranceClaimService
                              }
                              name="insuranceClaimService"
                              label=""
                              size="lg"
                              id="selfDelivery"
                              onChange={this.handleOnInsuranceClaimService}
                            ></Form.Check>{" "}
                          </b>
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="4">
                          Insurance Company Name
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            type="text"
                            size="lg"
                            className="pd-2 w-100"
                            name="insuranceCompanyName"
                            required
                            readOnly={
                              !this.state.productservicingRequest
                                .insuranceClaimService
                            }
                            value={
                              this.state.productservicingRequest
                                .insuranceCompanyName
                            }
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                    <Col md={6} className="text-start text-dark my-1">
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="4">
                          Insurance Number
                        </Form.Label>
                        <Col sm="6">
                          <Form.Control
                            type="text"
                            size="lg"
                            required
                            className="pd-2 w-100"
                            name="insuranceNumber"
                            readOnly={
                              !this.state.productservicingRequest
                                .insuranceClaimService
                            }
                            value={
                              this.state.productservicingRequest.insuranceNumber
                            }
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="4">
                          Insurance Expiry Date
                        </Form.Label>
                        <Col sm="8">
                          <DateTimePicker
                            onChange={this.onInsuranceExpiryDateChange}
                            value={
                              this.state.productservicingRequest
                                .insuranceExpiryDate
                            }
                            format="dd-MMM-yy"
                            disabled={
                              !this.state.productservicingRequest
                                .insuranceClaimService
                            }
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <br />
                  {/* Tasks */}
                  <Row className="m-0">
                    <Col md={8} className="text-start text-dark my-1">
                      <span>
                        <h4>
                          {" "}
                          <b>Tasks:</b>{" "}
                        </h4>
                      </span>
                    </Col>
                    <Col md={4} className="text-end align-bottom">
                      <Button
                        variant="primary"
                        classname="px-2"
                        id="addbutton"
                        onClick={(e) =>
                          this.handleProductServicingTaskAddItems(e)
                        }
                      >
                        {" "}
                        + Add
                      </Button>
                      &nbsp;&nbsp;
                    </Col>
                  </Row>
                  <Row className="m-0 p-0 bg-light border">
                    <Col>
                      <Table bordered size="sm" className=" text-start">
                        <thead className="bg-secondary text-white">
                          <tr size="lg" className="text-center">
                            <td></td>
                            <td width="20%">TaskName</td>
                            <td width="20%">TaskDescription</td>
                            <td width="20%">Assigned To</td>
                            <td width="10%">SeqNo</td>
                            <td width="20%">Status</td>
                            <td width="10%" className="text-center">
                              Options
                            </td>
                          </tr>
                        </thead>
                        <tbody size="lg">
                          {this.state.productservicingTasks != undefined
                            ? this.state.productservicingTasks
                                //.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                                .map((row, i) => (
                                  <>
                                    <tr
                                      height="30pt"
                                      className="align-middle text-center"
                                    >
                                      <td className="text-center">{i + 1}</td>
                                      {/* <td>{row.sku}</td> */}
                                      <td className="text-start">
                                        {this.GetServiceTaskName(row.taskId)}
                                      </td>
                                      <td>{row.taskDescription}</td>
                                      <td>
                                        {this.GetUserNameById(row.assignedTo)}
                                      </td>
                                      <td>
                                        {/* {row.seqNo} */}
                                        <Form.Control
                                          type="text"
                                          size="lg"
                                          className="pd-2 w-100 text-center"
                                          name="seqNo"
                                          required
                                          value={row.seqNo}
                                          placeholder=""
                                          autoComplete="off"
                                          onChange={(e) =>
                                            this.OnChangeSeqNoOfTasks(e, i)
                                          }
                                        />
                                      </td>
                                      <td>
                                        {row.statusId == 1
                                          ? "Open"
                                          : row.statusId == 2
                                          ? "InProgress"
                                          : row.statusId == 3
                                          ? "Complete"
                                          : ""}
                                      </td>
                                      <td className="text-center">
                                        <OverlayTrigger
                                          overlay={<Tooltip>Edit!</Tooltip>}
                                        >
                                          <Button
                                            className="mainthemecolor"
                                            onClick={(e) => {
                                              this.OnProductServicingTaskEditButtonClick(
                                                e,
                                                i
                                              );
                                            }}
                                          >
                                            <BsFillPencilFill size="16" />
                                          </Button>
                                        </OverlayTrigger>
                                        &nbsp;&nbsp;
                                        <OverlayTrigger
                                          overlay={<Tooltip>Delete!</Tooltip>}
                                        >
                                          <Button
                                            variant="outline-danger"
                                            onClick={(e) => {
                                              this.handleProductServicingTaskRemoveClick(
                                                i
                                              );
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="currentColor"
                                              class="bi bi-trash"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                              <path
                                                fill-rule="evenodd"
                                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                              />
                                            </svg>
                                          </Button>
                                        </OverlayTrigger>
                                      </td>
                                    </tr>
                                  </>
                                ))
                            : ""}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>

                  {/* RawMaterials Required */}
                  <br />
                  <br />
                  <Row className="m-0">
                    <Col md={8} className="text-start text-dark my-1">
                      <span>
                        <h4>
                          {" "}
                          <b>Raw Materials Required:</b>{" "}
                        </h4>
                      </span>
                    </Col>
                    <Col md={4} className="text-end align-bottom">
                      <Button
                        variant="primary"
                        classname="px-2"
                        id="addbutton"
                        onClick={(e) =>
                          this.handleProductServicingRawMaterialAddItems(e)
                        }
                      >
                        + Add
                      </Button>
                      &nbsp;&nbsp;
                    </Col>
                  </Row>
                  <Row className="m-0 p-0 bg-light border">
                    <Col>
                      <Table bordered size="sm" className=" text-start">
                        <thead className="bg-secondary text-white">
                          <tr size="lg" className="text-center">
                            <td></td>
                            <td width="30%">RawMaterial Name</td>
                            <td width="10%">Price</td>
                            <td width="10%">Qty</td>
                            <td width="10%">
                              SubTotal <br />
                              [Rs.{this.GetRawMaterialsTotalCost()}]
                            </td>
                            <td width="20%">Details</td>
                            <td width="10%">Status</td>
                            <td width="10%" className="text-center">
                              Options
                            </td>
                          </tr>
                        </thead>
                        <tbody size="lg">
                          {this.state.productservicingRawMaterials != undefined
                            ? this.state.productservicingRawMaterials
                                //.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                                .map((row, i) => (
                                  <>
                                    <tr
                                      height="30pt"
                                      className="align-middle text-center"
                                    >
                                      <td className="text-center">{i + 1}</td>

                                      <td className="text-start">
                                        {this.GetRawMaterialName(
                                          row.rawMaterialId
                                        )}
                                      </td>
                                      <td>{row.price}</td>
                                      <td>{row.qty}</td>
                                      <td>
                                        <b>{row.price * row.qty}</b>
                                      </td>
                                      <td>{row.details}</td>
                                      <td>
                                        {row.statusId == 1
                                          ? "Requested"
                                          : row.statusId == 2
                                          ? "Delivered"
                                          : row.statusId == 3
                                          ? "OutOfStock"
                                          : ""}
                                      </td>

                                      <td className="text-center">
                                        <OverlayTrigger
                                          overlay={<Tooltip>Edit!</Tooltip>}
                                        >
                                          <Button
                                            className="mainthemecolor"
                                            onClick={(e) => {
                                              this.OnProductServicingRawMaterialEditButtonClick(
                                                e,
                                                i
                                              );
                                            }}
                                          >
                                            <BsFillPencilFill size="16" />
                                          </Button>
                                        </OverlayTrigger>
                                        &nbsp;&nbsp;
                                        <OverlayTrigger
                                          overlay={<Tooltip>Delete!</Tooltip>}
                                        >
                                          <Button
                                            variant="outline-danger"
                                            onClick={(e) => {
                                              this.handleProductServicingRawMaterialRemoveClick(
                                                i
                                              );
                                            }}
                                          >
                                            <svg
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="16"
                                              height="16"
                                              fill="currentColor"
                                              class="bi bi-trash"
                                              viewBox="0 0 16 16"
                                            >
                                              <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                              <path
                                                fill-rule="evenodd"
                                                d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                              />
                                            </svg>
                                          </Button>
                                        </OverlayTrigger>
                                      </td>
                                    </tr>
                                  </>
                                ))
                            : ""}
                        </tbody>
                      </Table>
                    </Col>
                  </Row>
                  <br />

                  {/* Service Summary */}
                  <br />
                  {this.props.serviceStatus == 1 ||
                  this.props.serviceStatus == undefined ? (
                    <></>
                  ) : (
                    <>
                      <h4>
                        {" "}
                        <b>Service Summary:</b>{" "}
                      </h4>
                      <Row className="m-0 p-2 border bg-light">
                        <Form.Group as={Row} className="mb-1 " controlId="">
                          <Form.Label column sm="3">
                            Total Raw Material Charges
                          </Form.Label>
                          <Col sm="3" className="text-end">
                            <b>
                              {this.GetRawMaterialsTotalCost().toLocaleString(
                                this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings.defaultCurrency,
                                }
                              )}
                            </b>
                          </Col>
                          <Col sm="6"></Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1 " controlId="">
                          <Form.Label column sm="3">
                            Service Cost1 & Description
                          </Form.Label>
                          <Col sm="3">
                            <b>
                              <Form.Control
                                type="number"
                                size="lg"
                                className="pd-2 w-100 text-end"
                                name="serviceCost1"
                                value={
                                  this.state.productservicingRequest
                                    .serviceCost1
                                }
                                placeholder=""
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e)}
                              />
                            </b>
                          </Col>
                          <Col sm="6">
                            <Form.Control
                              as="textarea"
                              type="textarea"
                              rows={1}
                              size="lg"
                              className="pd-2 w-100 "
                              name="serviceCost1Description"
                              value={
                                this.state.productservicingRequest
                                  .serviceCost1Description
                              }
                              placeholder=""
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e)}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1 " controlId="">
                          <Form.Label column sm="3">
                            Service Cost2 & Description
                          </Form.Label>
                          <Col sm="3">
                            <b>
                              <Form.Control
                                type="number"
                                size="lg"
                                className="pd-2 w-100 text-end"
                                name="serviceCost2"
                                value={
                                  this.state.productservicingRequest
                                    .serviceCost2
                                }
                                placeholder=""
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e)}
                              />
                            </b>
                          </Col>
                          <Col sm="6">
                            <Form.Control
                              as="textarea"
                              type="textarea"
                              rows={1}
                              size="lg"
                              className="pd-2 w-100"
                              name="serviceCost2Description"
                              value={
                                this.state.productservicingRequest
                                  .serviceCost2Description
                              }
                              placeholder=""
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e)}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1 " controlId="">
                          <Form.Label column sm="3">
                            Service Cost3 & Description
                          </Form.Label>
                          <Col sm="3">
                            <b>
                              <Form.Control
                                type="number"
                                size="lg"
                                className="pd-2 w-100 text-end"
                                name="serviceCost3"
                                value={
                                  this.state.productservicingRequest
                                    .serviceCost3
                                }
                                placeholder=""
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e)}
                              />
                            </b>
                          </Col>
                          <Col sm="6">
                            <Form.Control
                              as="textarea"
                              type="textarea"
                              rows={1}
                              size="lg"
                              className="pd-2 w-100"
                              name="serviceCost3Description"
                              value={
                                this.state.productservicingRequest
                                  .serviceCost3Description
                              }
                              placeholder=""
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e)}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1 " controlId="">
                          <Form.Label column sm="3">
                            Other Charges
                          </Form.Label>
                          <Col sm="3">
                            <b>
                              <Form.Control
                                type="number"
                                size="lg"
                                className="pd-2 w-100 text-end"
                                name="otherCost"
                                value={
                                  this.state.productservicingRequest.otherCost
                                }
                                placeholder=""
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e)}
                              />
                            </b>
                          </Col>
                          <Col sm="6">
                            <Form.Control
                              as="textarea"
                              type="textarea"
                              rows={1}
                              size="lg"
                              className="pd-2 w-100"
                              name="otherCost1Description"
                              value={
                                this.state.productservicingRequest
                                  .otherCost1Description
                              }
                              placeholder=""
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e)}
                            />
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1 " controlId="">
                          <Form.Label column sm="3">
                            Discount (%)
                          </Form.Label>
                          <Col sm="3">
                            <b>
                              <Form.Control
                                type="number"
                                size="lg"
                                className="pd-2 w-100 text-end"
                                name="discountPercentage"
                                value={
                                  this.state.productservicingRequest
                                    .discountPercentage
                                }
                                placeholder=""
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e)}
                              />
                            </b>
                          </Col>
                          <Col sm="6"></Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1 " controlId="">
                          <Form.Label column sm="3">
                            SGST (
                            {
                              this.context.taxDetails
                                .productServiceSgstPercentage
                            }
                            %)
                          </Form.Label>
                          <Col sm="3" className="text-end">
                            {Number(
                              (this.GetTotalAfterDiscount() *
                                this.context.taxDetails
                                  .productServiceSgstPercentage) /
                                100
                            ).toLocaleString(
                              this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                style: "currency",
                                currency:
                                  this.context.storeSettings.defaultCurrency,
                              }
                            )}
                          </Col>
                          <Col sm="6"></Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1 " controlId="">
                          <Form.Label column sm="3">
                            CGST (
                            {
                              this.context.taxDetails
                                .productServiceCgstPercentage
                            }
                            %)
                          </Form.Label>
                          <Col sm="3" className="text-end">
                            <b>
                              {(
                                Number(
                                  this.GetTotalAfterDiscount() *
                                    this.context.taxDetails
                                      .productServiceCgstPercentage
                                ) / 100
                              ).toLocaleString(
                                this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings.defaultCurrency,
                                }
                              )}
                            </b>
                          </Col>
                          <Col sm="6"></Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1 " controlId="">
                          <Form.Label column sm="3">
                            Insurance Amount Received:
                          </Form.Label>
                          <Col sm="3">
                            <Form.Control
                              type="number"
                              size="lg"
                              className="pd-2 w-100 text-end"
                              name="insurancePaidAmount"
                              value={
                                this.state.productservicingRequest
                                  .insurancePaidAmount
                              }
                              placeholder=""
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e)}
                            />
                          </Col>
                          <Col sm="6"></Col>
                        </Form.Group>
                        <Form.Group
                          as={Row}
                          className="mb-1 text-primary"
                          controlId=""
                        >
                          <Form.Label column sm="3">
                            <h4>Total Amount to Pay</h4>
                          </Form.Label>
                          <Col sm="3" className="text-end">
                            <h2>
                              {this.state.productservicingRequest.customerPaidAmount.toLocaleString(
                                this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 1,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings.defaultCurrency,
                                }
                              )}
                            </h2>
                          </Col>
                          <Col sm="6"></Col>
                        </Form.Group>
                      </Row>

                      {/* Invoice */}
                      <br />
                      {Number(this.props.serviceStatus) < 4 ||
                      this.props.serviceStatus == undefined ? (
                        <></>
                      ) : (
                        <>
                          <h4>
                            {" "}
                            <b>Invoice:</b>{" "}
                          </h4>
                          <Row className="m-0 p-2 border bg-light">
                            <Col sm="3">
                              <Button
                                size="lg"
                                // variant="warning"
                                className="CornersRounded fw-bold"
                                onClick={(e) => this.GenerateServiceInvoice(e)}
                              >
                                &nbsp;&nbsp;
                                {this.state.productservicingRequest
                                  .invoiceFileName == null
                                  ? "Generate Invoice"
                                  : "Re-generate Invoice"}
                                &nbsp;&nbsp;
                              </Button>
                            </Col>
                            <Col sm="6">
                              <Stack direction="horizontal">
                                {this.state.productservicingRequest
                                  .invoiceFileName != null
                                  ? "Invoice Generated: " +
                                    this.state.productservicingRequest
                                      .invoiceFileName +
                                    ".html"
                                  : ""}
                                &nbsp;&nbsp;
                                <a
                                  href={
                                    this.context.store.storageBlobUrl +
                                    this.context.store
                                      .storageBlobContainerName +
                                    "/invoice/" +
                                    this.state.productservicingRequest
                                      .invoiceFileName +
                                    ".html" +
                                    this.context.store.storageSasToken +
                                    "&xyz=" +
                                    new Date().toLocaleString()
                                  }
                                  //target="_blank"
                                >
                                  Download Invoice
                                </a>
                              </Stack>
                            </Col>
                            {this.state.productservicingRequest
                              .invoiceFileName != null ? (
                              <>
                                <Col sm="3" className="text-center">
                                  <Stack direction="horizontal">
                                    <Button
                                      size="lg"
                                      variant="primary"
                                      onClick={(e) =>
                                        this.GetServiceInvoiceHtml(e)
                                      }
                                    >
                                      Print Invoice
                                    </Button>
                                  </Stack>
                                </Col>
                              </>
                            ) : (
                              <></>
                            )}
                          </Row>
                        </>
                      )}

                      {/* Customer Feedback */}
                      {Number(this.props.serviceStatus) < 6 ||
                      this.props.serviceStatus == undefined ? (
                        <></>
                      ) : (
                        <>
                          <br />
                          <h4>
                            {" "}
                            <b>Customer Feedback:</b>{" "}
                          </h4>
                          <Row className="m-0 p-2 border bg-light">
                            <Form.Group as={Row} className="mb-1 " controlId="">
                              <Form.Label column sm="3">
                                Feedback
                              </Form.Label>
                              <Col sm="9">
                                <Form.Control
                                  as="textarea"
                                  type="textarea"
                                  rows={3}
                                  size="lg"
                                  className="pd-2 w-100 "
                                  name="customerFeedback"
                                  value={
                                    this.state.productservicingRequest
                                      .customerFeedback
                                  }
                                  placeholder=""
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </Col>
                            </Form.Group>
                            <Form.Group as={Row} className="mb-1 " controlId="">
                              <Form.Label column sm="3">
                                Ratings(1..5)
                              </Form.Label>
                              <Col sm="9">
                                <Form.Control
                                  type="number"
                                  size="lg"
                                  className="pd-2 w-100 "
                                  name="customerRatings"
                                  value={
                                    this.state.productservicingRequest
                                      .customerRatings
                                  }
                                  placeholder=""
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </Col>
                            </Form.Group>
                          </Row>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button
                  variant="primary"
                  size="lg"
                  type="submit"
                  disabled={this.state.customerDetails == ""}
                >
                  Save
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
