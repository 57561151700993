import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import { BsFillPencilFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import { ThemeAddOrEditModal } from "./ThemeAddOrEditModal";

import {
  OverlayTrigger,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export class ProductThemes extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      themes: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      reload: true,
    };
  }

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  // OnDeleted = () => {
  //   this.OnRefreshButtonClick();
  // };
  OnSaved = () => {
    // this.setState({ themes: this.context.themes });
    // this.setState({ reload: false }, () => this.setState({ reload: true }));
    // this.ShowMessagBox("Status", "Saved Successfully!");
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.setState({ theme: [] }, () => {});
    this.setState({ reload: false }, () => {
      this.setState({ themes: this.context.productThemes }, () => {
        this.setState({ reload: true });
      });
    });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  OnDeleted = (indexno) => {
    this.setState({ reload: false });
    const list = [...this.state.themes];
    list.splice(indexno, 1);
    this.setState({ themes: list }, () => {
      this.setState({ reload: true }, () => {
        this.ShowMessagBox("Information", "Deleted Successfully!");
      });
    });
  };

  OnUpdated = (indexno) => {
    this.OnRefreshButtonClick();
  };

  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
  };
  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.setState({ themes: this.context.productThemes });
    this.setState({ reload: true });
  };

  render() {
    return (
      <>
        <div className="Container-fluid p-1 ">
          <Row>
            <Col className="" xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="pagebackground p-0">
              {this.state.msgboxOpen === true ? (
                <MessageBox
                  open={this.state.msgboxOpen}
                  title={this.state.msgboxTitle}
                  message={this.state.msgboxMessage}
                  callback={this.CloseMessagBoxCallback}
                />
              ) : (
                ""
              )}
              <Navbar
                expand="lg"
                className="secondarythemecolor position-sticky fixed-top  px-3"
              >
                <h4> &nbsp; &nbsp;Product Themes &nbsp;</h4>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemecolor"
                        // onClick={() => this.handleAddTheme()}
                        onClick={() => this.setState({ addModalShow: true })}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Refresh
                      </Button>
                      &nbsp;
                      {this.state.addModalShow === true ? (
                        <ThemeAddOrEditModal
                          show={this.state.addModalShow}
                          Type="Add"
                          onHide={this.addModalClose}
                          ThemeType="ProductTheme"
                        />
                      ) : null}
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div>
                <Table border striped className="px-0 bg-white ">
                  <thead className="">
                    <tr className="text-center text-black bg-white border">
                      <th></th>
                      <th width="30%">Theme Name</th>

                      <th width="50%">Description</th>

                      <th width="20%" className="text-end">
                        Options
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-primary border">
                    {this.state.themes.map((theme, i) =>
                      this.state.reload === true ? (
                        <EditProductThemeInline
                          index={i}
                          theme={theme}
                          themes={this.state.themes}
                          OnDeleted={this.OnDeleted}
                          OnSaved={this.OnSaved}
                        ></EditProductThemeInline>
                      ) : null
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export class EditProductThemeInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      theme: "",
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      editModalShow: false,
    };
  }

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDelete = () => {
    if (this.state.theme.id == undefined) {
      this.ShowMessagBox(
        "Information",
        "This is invalid row which can't be deleted. Press Refresh!"
      );
      return;
    }

    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteProductTheme/" +
        this.state.theme.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert(
            "Not deleted. This Theme may be associated with few Products. First disassociate this theme from the Products and then try!"
          );
          return;
        }
        this.context.getProductThemes();
        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Status",
          "Deleted Successfully. Click Refresh Button to see the changes!"
        );
        // this.props.OnDeleted();
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("OnDelete Failed" + error);
      });
  };

  ValidateDuplicate = () => {
    var b = this.props.themes.filter(
      (p) =>
        p.themeName.toLowerCase().trim() ===
        this.state.theme.themeName.toLowerCase().trim()
    );

    //Add
    if (this.state.theme.id == undefined) {
      if (b.length == 2) {
        this.ShowMessagBox("Information", "This themename already exist!");
        return false;
      }
    } else {
      //Update
      if (b.length == 2) {
        this.ShowMessagBox("Information", "This themename already exist!");
        return false;
      }
    }

    return true;
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "themeName":
        this.state.theme.themeName = value;
        break;
    }
    this.setState({ theme: this.state.theme });
  };
  componentDidMount = () => {
    this.setState({ theme: this.props.theme });
  };
  OnEditButtonClick = () => {
    this.setState({ editModalShow: true }, () => {});
  };

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {
      // this.props.OnUpdated();
    });
  };

  render() {
    return (
      <>
        {this.state.editModalShow === true ? (
          <ThemeAddOrEditModal
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            theme={this.state.theme}
            Type="Edit"
            ThemeType="ProductTheme"
          />
        ) : (
          ""
        )}

        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}

        <tr
          className="text-center align-middle text-secondary bg-white mx-5"
          style={{ fontSize: "12px" }}
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          <td>{this.props.index + 1}</td>
          <td className="text-center ">{this.state.theme.themeName}</td>
          <td className="text-center ">{this.state.theme.themeDescription}</td>
          <td className="col-md-2 text-end align-middle ">
            <div class="btn-group">
              <OverlayTrigger overlay={<Tooltip>Edit!</Tooltip>}>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    this.OnEditButtonClick(e);
                  }}
                >
                  <BsFillPencilFill size="20" />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                overlay={
                  <Tooltip>
                    Warning! This will delete this Theme Row and associated
                    Products as well.
                  </Tooltip>
                }
              >
                <Button
                  className="mx-1 mainthemecolor"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        "Warning! This action will delete this Theme Row and associated Products as well. Are you sure you want to Delete?"
                      )
                    ) {
                      this.OnDelete(e);
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>
            </div>
          </td>
        </tr>
      </>
    );
  }
}
