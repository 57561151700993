import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import { Country, State, City } from "country-state-city";

import {
  OverlayTrigger,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export class MultiCurrencys extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      multiCurrencys: [],
      menuReferences: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
    };
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDeleted = () => {
    this.OnRefreshButtonClick();
  };
  OnSaved = () => {
    // this.setState({ multiCurrencys: this.context.multiCurrencys });
    // this.setState({ reload: false }, () => this.setState({ reload: true }));
    // this.ShowMessagBox("Status", "Saved Successfully!");
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.setState({ multiCurrencys: [] }, () => {
      this.GetMultiCurrencys();
    });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  handleAddMultiCurrency = (e) => {
    this.setState({
      multiCurrencys: [
        ...this.state.multiCurrencys,
        {
          storeId: this.context.storeId,
          countryName: "",
          currencyCode: "",
          defaultState: "",
          defaultLocale: "",
          defaultCountryCode: "0",
          exchangeRate: "0",
          phoneNumberLength: "10",
          pincodeLength: "6",
          storePhoneNumber: "",
          storeAddress: "",
          seqNo: this.state.multiCurrencys.length + 1,
        },
      ],
    });
    alert("An empty row added at the end of list below!");
  };

  handleInputChange = (e, i) => {
    const { name, value } = e.target;

    //validate for minus values not to allow
    switch (name.trim()) {
      case "countryName":
        this.state.multiCurrencys[i].countryName = value;
        var country = Country.getCountryByCode(value);
        if (country) {
          this.state.multiCurrencys[i].currencyCode = country.currency;
          this.state.multiCurrencys[i].defaultCountryCode = country.phonecode;
        }
        break;
      case "currencyCode":
        this.state.multiCurrencys[i].currencyCode = value;
        break;
      case "defaultState":
        this.state.multiCurrencys[i].defaultState = value;
        break;
      case "defaultLocale":
        this.state.multiCurrencys[i].defaultLocale = value;
        break;
      case "defaultCountryCode":
        this.state.multiCurrencys[i].defaultCountryCode = value;
        break;
      case "exchangeRate":
        this.state.multiCurrencys[i].exchangeRate = value;
        break;
      case "phoneNumberLength":
        this.state.multiCurrencys[i].phoneNumberLength = value;
        break;
      case "pincodeLength":
        this.state.multiCurrencys[i].pincodeLength = value;
        break;
      case "storePhoneNumber":
        this.state.multiCurrencys[i].storePhoneNumber = value;
        break;
      case "storeAddress":
        this.state.multiCurrencys[i].storeAddress = value;
        break;
      case "seqNo":
        this.state.multiCurrencys[i].seqNo = value;
        break;
    }
    this.setState({ multiCurrencys: this.state.multiCurrencys });
  };

  OnSave = (e) => {
    this.ShowLoadSpinner();

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.multiCurrencys),
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/AddOrUpdateMultiCurrencys/" +
        this.context.storeId,
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.GetMultiCurrencys();
          this.HideLoadSpinner();
          this.ShowMessagBox("Information", "Saved Successfully!");
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed!");
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed! " + error);
      });
  };

  OnDelete = (i) => {
    if (this.state.multiCurrencys[i].id == undefined) {
      this.ShowMessagBox(
        "Information",
        "This is invalid row which can't be deleted. Press Refresh!"
      );
      return;
    }

    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteMultiCurrency/" +
        this.state.multiCurrencys[i].id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert("not deleted");
          return;
        }
        // this.GetMultiCurrencys();
        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Status",
          "Deleted Successfully. Click Refresh Button to see the changes!"
        );
        this.OnDeleted();
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("OnDelete Failed" + error);
      });
  };

  GetMultiCurrencys = () => {
    this.setState({ multiCurrencys: [] }, () => {
      fetch(
        process.env.REACT_APP_API +
          "StoreManager/GetMultiCurrencys/" +
          this.context.storeId
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.status == 400) {
          } else {
            this.setState({
              multiCurrencys: data.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1)),
            });
          }
        })
        .catch((error) => {
          alert("GetMultiCurrencys:: " + error);
        });
    });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.GetMultiCurrencys();
  };

  render() {
    return (
      <>
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <div className="Container-fluid p-1 ">
          <Row>
            <Col className="" xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="pagebackground p-0">
              {this.state.msgboxOpen === true ? (
                <MessageBox
                  open={this.state.msgboxOpen}
                  title={this.state.msgboxTitle}
                  message={this.state.msgboxMessage}
                  callback={this.CloseMessagBoxCallback}
                />
              ) : (
                ""
              )}
              <Navbar
                expand="lg"
                className="secondarythemecolor position-sticky fixed-top  px-3"
              >
                <h4> &nbsp; &nbsp;Multi Country & Currency Settings &nbsp;</h4>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemecolor"
                        onClick={() => this.handleAddMultiCurrency()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={(e) => {
                          this.OnSave(e);
                        }}
                      >
                        <BiSave size="26" />
                        Save
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Refresh
                      </Button>
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <br />
              <Row className="border">
                <Col className="text-center">
                  <h4>
                    Default Country Code :{" "}
                    <b>{this.context.storeSettings.defaultCountry}</b>
                  </h4>
                </Col>
                <Col className="text-center">
                  <h4>
                    Default Currency :{" "}
                    <b>{this.context.storeSettings.defaultCurrency}</b>
                  </h4>
                </Col>
              </Row>
              <br />
              <br />
              <div>
                <h5 className="text-danger blink_me">
                  <b>
                    Note: The first Row of the Table should be the Default
                    country(which is configured in StoreSettings) and
                    ExchangeRate must be 1. And SeqNo must be 1.
                  </b>
                </h5>
                <Table border striped className="px-0 bg-white ">
                  <thead className="">
                    <tr className="text-center text-black bg-white border">
                      {/* <th width="20%">multiCurrency Name</th> */}
                      <th></th>
                      <th width="10%">Country Name</th>
                      <th width="10%">Currency Code</th>
                      <th width="10%">
                        Exchange Rate <br />
                        [from: {this.context.storeSettings.defaultCurrency}]
                      </th>
                      <th width="10%">Default State</th>
                      <th width="5%">Locale</th>
                      <th width="5%">Phone Code</th>
                      <th width="5%">PhoneNumber Length</th>
                      <th width="5%">Pincode Length</th>
                      <th width="10%">Store PhoneNumber</th>
                      <th width="20%">Store Address</th>
                      <th width="5%" className="text-center">
                        SeqNo
                      </th>
                      <th width="5%" className="text-center">
                        Options
                      </th>
                    </tr>
                  </thead>
                  <tbody className="text-primary border">
                    {this.state.multiCurrencys.map((mainMenu, i) => (
                      <>
                        <tr className={i == 0 ? "bg-warning" : ""}>
                          <td>{i + 1}</td>
                          <td>
                            <Form.Select
                              type="text"
                              name="countryName"
                              value={this.state.multiCurrencys[i].countryName}
                              required
                              className="text-start  "
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e, i)}
                            >
                              <option value=""></option>
                              {Country.getAllCountries().map((country) => (
                                <>
                                  <option value={country.isoCode}>
                                    {country.name}
                                  </option>
                                </>
                              ))}
                            </Form.Select>
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              name="currencyCode"
                              value={this.state.multiCurrencys[i].currencyCode}
                              required
                              readOnly
                              className="text-start  "
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e, i)}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              name="exchangeRate"
                              value={this.state.multiCurrencys[i].exchangeRate}
                              required
                              className="text-start  "
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e, i)}
                            />
                          </td>
                          <td>
                            <Form.Select
                              type="text"
                              name="defaultState"
                              value={this.state.multiCurrencys[i].defaultState}
                              required
                              className="text-start  "
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e, i)}
                            >
                              <option value=""></option>
                              {State.getStatesOfCountry(
                                this.state.multiCurrencys[i].countryName
                              ).map((s1) => (
                                <>
                                  <option>{s1.name}</option>
                                </>
                              ))}
                            </Form.Select>
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              name="defaultLocale"
                              value={this.state.multiCurrencys[i].defaultLocale}
                              required
                              className="text-start  "
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e, i)}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              name="defaultCountryCode"
                              value={
                                this.state.multiCurrencys[i].defaultCountryCode
                              }
                              required
                              readOnly
                              className="text-start  "
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e, i)}
                            />
                          </td>

                          <td>
                            <Form.Control
                              type="text"
                              name="phoneNumberLength"
                              value={
                                this.state.multiCurrencys[i].phoneNumberLength
                              }
                              required
                              className="text-start  "
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e, i)}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              name="pincodeLength"
                              value={this.state.multiCurrencys[i].pincodeLength}
                              required
                              className="text-start  "
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e, i)}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              name="storePhoneNumber"
                              value={
                                this.state.multiCurrencys[i].storePhoneNumber
                              }
                              required
                              className="text-start  "
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e, i)}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="text"
                              name="storeAddress"
                              value={this.state.multiCurrencys[i].storeAddress}
                              required
                              className="text-start  "
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e, i)}
                            />
                          </td>
                          <td>
                            <Form.Control
                              type="number"
                              name="seqNo"
                              value={this.state.multiCurrencys[i].seqNo}
                              required
                              className="text-start  "
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e, i)}
                            />
                          </td>

                          <td className="text-center align-middle">
                            <Button
                              className="mx-1 mainthemecolor"
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Warning! This action will delete this Carousel Row and associated Products as well. Are you sure you want to Delete?"
                                  )
                                ) {
                                  this.OnDelete(i);
                                }
                              }}
                            >
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="25"
                                height="25"
                                fill="currentColor"
                                class="bi bi-trash"
                                viewBox="0 0 16 16"
                              >
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path
                                  fill-rule="evenodd"
                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                />
                              </svg>
                            </Button>
                          </td>
                        </tr>
                      </>
                    ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
