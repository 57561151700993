import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { IngredientsCategory } from "./IngredientsCategory";
import { BiSave, BiRefresh } from "react-icons/bi";
import { BsFillPencilFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import Fuse from "fuse.js";

import {
  OverlayTrigger,
  Tooltip,
  Stack,
  FormControl,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Modal,
  InputGroup,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export class IngredientsInventory extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      ingredientsinventory: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      reload: true,
      ingredientscategory: [],
      ingredientsinventoryFiltered: [],
      categoryFilterName: "",
    };
  }
  GetIngredientsCategories = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetIngredientsCategories/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ ingredientscategory: data }, () => {
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  GetIngredientsInventories = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetIngredientsInventories/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({
            ingredientsinventoryFiltered: data,
          });
          this.setState({ ingredientsinventory: data }, () => {
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnSaved = () => {
    this.OnRefreshButtonClick();
  };
  OnDeleted = () => {
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.setState({ ingredientsinventory: [] }, () => {
      this.GetIngredientsInventories();
    });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  OnUpdated = (indexno) => {
    this.OnRefreshButtonClick();
  };

  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
    this.OnRefreshButtonClick();
  };

  onSearchChange = (query) => {
    this.setState({ reload: false });
    this.setState({ searchquery: query });
    if (!query) {
      this.setState(
        { ingredientsinventoryFiltered: this.state.ingredientsinventory },
        () => {
          this.setState({ reload: true });
        }
      );
      return;
    }
    const fuse = new Fuse(this.state.ingredientsinventory, {
      keys: ["name"],
      threshold: 0.0,
      ignoreLocation: true,
      findAllMatches: true,
      includeMatches: true,
      distance: 120,
    });
    const result = fuse.search(query);
    const finalResult = [];
    if (result.length) {
      result.forEach((item) => {
        finalResult.push(item.item);
      });
      this.setState(
        { ingredientsinventoryFiltered: finalResult.slice(0, 15) },
        () => {
          this.setState({ reload: true });
        }
      );
    } else {
      this.setState({ ingredientsinventoryFiltered: [] });
    }
  };

  OnCategoryChanged = (e) => {
    this.setState({ reload: false });
    this.setState({ categoryFilterName: e.target.value }, () => {
      this.setState({ reload: true });
    });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    if (this.state.ingredientsinventory.length == 0)
      this.GetIngredientsInventories();
    this.GetIngredientsCategories();
  };

  render() {
    return (
      <>
        <div className="Container-fluid p-1 ">
          <Row>
            <Col className="" xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="pagebackground p-0">
              <Row>
                <Col xs={3}>
                  <IngredientsCategory
                    update={this.GetIngredientsCategories}
                  ></IngredientsCategory>
                </Col>
                <Col xs={9}>
                  {this.state.msgboxOpen === true ? (
                    <MessageBox
                      open={this.state.msgboxOpen}
                      title={this.state.msgboxTitle}
                      message={this.state.msgboxMessage}
                      callback={this.CloseMessagBoxCallback}
                    />
                  ) : (
                    ""
                  )}
                  <Navbar
                    expand="lg"
                    className="secondarythemecolor position-sticky fixed-top  px-3"
                  >
                    <h4> &nbsp; &nbsp;Ingredients Inventory &nbsp;</h4>
                    <Navbar.Collapse className="justify-content-end mx-0 ">
                      <Nav>
                        <ButtonToolbar className="text-end align-middle">
                          <Button
                            className="mainthemecolor"
                            // onClick={() => this.handleAddIngredientsInventory()}
                            onClick={() =>
                              this.setState({ addModalShow: true })
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="26"
                              fill="currentColor"
                              class="bi bi-plus"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                            Add
                          </Button>
                          &nbsp;
                          <Button
                            className="mainthemecolor"
                            size=""
                            onClick={() => this.OnRefreshButtonClick()}
                          >
                            <BiRefresh size="26"></BiRefresh>
                            Refresh
                          </Button>
                          &nbsp;
                          {this.state.addModalShow === true ? (
                            <IngredientsInventoryAddOrEditModal
                              show={this.state.addModalShow}
                              Type="Add"
                              onHide={this.addModalClose}
                              ingredientscategory={
                                this.state.ingredientscategory
                              }
                            />
                          ) : null}
                        </ButtonToolbar>
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>
                  <div className="p-3">
                    <Row>
                      <Col>
                        <Stack direction="horizontal" gap={3} className="">
                          <b>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              fill="currentColor"
                              class="bi bi-search"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                          </b>
                          <FormControl
                            type="search"
                            placeholder="Search  "
                            className="text-primary fw-bold bg-white "
                            aria-label="Search"
                            onChange={(e) =>
                              this.onSearchChange(e.target.value)
                            }
                          />
                        </Stack>
                      </Col>
                      <Col>
                        <InputGroup className="">
                          <InputGroup.Text id="basic-addon1">
                            Category
                          </InputGroup.Text>
                          <Form.Select
                            value={this.state.categoryFilterName}
                            onChange={(e) => {
                              this.OnCategoryChanged(e);
                            }}
                          >
                            <option value=""></option>
                            {this.state.ingredientscategory.map((m) => (
                              <>
                                <option value={m.id}>{m.name}</option>
                              </>
                            ))}
                          </Form.Select>
                        </InputGroup>
                      </Col>
                    </Row>
                  </div>
                  <div>
                    <Table border striped className="px-0 bg-white ">
                      <thead className="">
                        <tr className="text-center text-black bg-white border">
                          <th width="5%"></th>
                          <th width="40%" className="text-start">
                            Name
                          </th>
                          <th width="20%" className="text-center">
                            Category
                          </th>
                          <th width="10%" className="text-center">
                            Price
                          </th>
                          <th width="10%" className="text-center">
                            InStock Qty
                          </th>
                          <th width="20%" className="text-end">
                            Options
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-primary border">
                        {this.state.ingredientsinventoryFiltered
                          .filter((f) => {
                            if (
                              this.state.categoryFilterName != "" &&
                              this.state.categoryFilterName == f.categoryId
                            ) {
                              return true;
                            } else if (this.state.categoryFilterName == "") {
                              return true;
                            } else {
                              return false;
                            }
                          })
                          // .filter((f) => {
                          //   if (this.state.categoryFilterName == "") return true;
                          // })
                          //.sort((a, b) => (a.created > b.created ? 1 : -1))
                          .map((ingredientsinventory, i) =>
                            this.state.reload === true ? (
                              <EditIngredientsInventoryInline
                                index={i}
                                ingredientsinventory={ingredientsinventory}
                                OnDeleted={this.OnDeleted}
                                ingredientscategory={
                                  this.state.ingredientscategory
                                }
                              ></EditIngredientsInventoryInline>
                            ) : null
                          )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export class EditIngredientsInventoryInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      ingredientsinventory: "",
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      editModalShow: false,
    };
  }

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDelete = () => {
    if (this.state.ingredientsinventory.id == undefined) {
      this.ShowMessagBox(
        "Information",
        "This is invalid row which can't be deleted. Press Refresh!"
      );
      return;
    }

    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteIngredientsInventory/" +
        this.state.ingredientsinventory.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert(
            "Not deleted. This IngredientsInventory may be associated with few Products. First disassociate this ingredientsinventory from the Products and then try!"
          );
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Status",
          "Deleted Successfully. Click Refresh Button to see the changes!"
        );
        this.props.OnDeleted();
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("OnDelete Failed" + error);
      });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name.trim()) {
      case "instock":
        this.state.ingredientsinventory.instock = value;
        break;
    }
    this.setState({ ingredientsinventory: this.state.ingredientsinventory });
  };

  componentDidMount = () => {
    this.setState({ ingredientsinventory: this.props.ingredientsinventory });
  };
  OnEditButtonClick = () => {
    this.setState({ editModalShow: true }, () => {});
  };

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {});
  };

  GetCategoryName = (categoryId) => {
    var result = this.props.ingredientscategory.filter(
      (f) => f.id == categoryId
    );
    if (result.length > 0) {
      return result[0].name;
    }
    return "";
  };
  OnSave = () => {
    this.ShowLoadSpinner();

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.ingredientsinventory),
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/AddOrUpdateIngredientsInventory/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.HideLoadSpinner();
          return;
        }
        this.HideLoadSpinner();
        alert("This IngredientsInventory is already exist. try again!");
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("Exception. Received an Exception. Try after sometime !" + error);
      });
  };

  render() {
    return (
      <>
        {this.state.editModalShow === true ? (
          <IngredientsInventoryAddOrEditModal
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            ingredientsinventory={this.state.ingredientsinventory}
            Type="Edit"
            ingredientscategory={this.props.ingredientscategory}
          />
        ) : (
          ""
        )}
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <tr
          className="text-center align-middle text-secondary bg-white mx-5"
          style={{ fontSize: "12px" }}
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          <td>{this.props.index + 1}</td>
          <td className="text-start ">
            {this.state.ingredientsinventory.name}
          </td>
          <td className="text-center ">
            {this.GetCategoryName(this.state.ingredientsinventory.categoryId)}
          </td>
          <td className="text-center ">
            {this.state.ingredientsinventory.price}
          </td>
          <td className="text-start">
            <Form.Control
              type="number"
              name="instock"
              value={this.state.ingredientsinventory.instock}
              required
              placeholder=""
              min={0}
              align="center"
              className="text-center  "
              autoComplete="off"
              onChange={(e) => this.handleInputChange(e)}
            />
          </td>
          <td className="col-md-2 text-end align-middle ">
            <div class="btn-group">
              <OverlayTrigger overlay={<Tooltip>Save</Tooltip>}>
                <Button
                  className="mainthemecolor rounded"
                  onClick={(e) => {
                    this.OnSave(e);
                  }}
                >
                  <BiSave size="20" />
                </Button>
              </OverlayTrigger>
              &nbsp;
              <OverlayTrigger overlay={<Tooltip>Edit!</Tooltip>}>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    this.OnEditButtonClick(e);
                  }}
                >
                  <BsFillPencilFill size="20" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                overlay={
                  <Tooltip>Warning! This will delete all dependents.</Tooltip>
                }
              >
                <Button
                  className="mx-1 mainthemecolor"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        "Warning! This action will delete all it dependents. Are you sure you want to Delete?"
                      )
                    ) {
                      this.OnDelete(e);
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

export class IngredientsInventoryAddOrEditModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      ingredientsinventory: {
        name: "",
      },
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
    };
  }

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  AddOrUpdateIngredientsInventoryInDB = () => {
    this.ShowLoadSpinner();

    var tmpIngredientsInventory = this.state.ingredientsinventory;
    if (this.props.Type == "Edit") {
      tmpIngredientsInventory = this.props.ingredientsinventory;
    } else {
      tmpIngredientsInventory.storeId = this.context.storeId;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmpIngredientsInventory),
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/AddOrUpdateIngredientsInventory/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.HideLoadSpinner();
          this.props.onHide();
          return;
        }
        this.HideLoadSpinner();
        alert("This IngredientsInventory is already exist. try again!");
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("Exception. Received an Exception. Try after sometime !" + error);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.AddOrUpdateIngredientsInventoryInDB();
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "name":
        this.state.ingredientsinventory.name = value;
        break;
      case "categoryId":
        this.state.ingredientsinventory.categoryId = value;
        break;
      case "price":
        this.state.ingredientsinventory.price = value;
        break;
      case "instock":
        this.state.ingredientsinventory.instock = value;
        break;
    }
    this.setState({ ingredientsinventory: this.state.ingredientsinventory });
  };

  componentDidMount() {
    if (this.props.Type == "Edit") {
      this.setState({
        ingredientsinventory: this.props.ingredientsinventory,
      });
    }
    if (document.getElementById("name") != null)
      document.getElementById("name").focus();
  }

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          dialogClassName="IngredientsInventoryModal"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit"
                  ? "Edit Ingredients"
                  : "Add Ingredients"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Row
                className="m-2 p-2 bg-light border"
                style={{ fontSize: "9pt" }}
              >
                <Col md={12}>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Name
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        id="name"
                        name="name"
                        maxlength="99"
                        value={this.state.ingredientsinventory.name}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Category Name
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        name="categoryId"
                        maxlength="99"
                        value={this.state.ingredientsinventory.categoryId}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value=""></option>
                        {this.props.ingredientscategory &&
                          this.props.ingredientscategory.map((r) => (
                            <option value={r.id}>{r.name}</option>
                          ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Price
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        name="price"
                        min={0}
                        value={this.state.ingredientsinventory.price}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      InStock Qty
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        name="instock"
                        min={0}
                        value={this.state.ingredientsinventory.instock}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  Save
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
