import { Component } from "react";
import React, { useState, useEffect, useContext } from "react";
import CartContext from "./CartContext";
//import ringsound from "../../Audio/shortsound.mp3";

//refresh bearer token every minute if user is logged on. RefreshBearerToken embedded on Navigation
const RefreshOrderSummary = () => {
  const { GetOrdersSummary, storeSettings } = useContext(CartContext);
  const { orderSummary } = useContext(CartContext);
  const [second, setSecond] = useState("00");
  const [minute, setMinute] = useState("00");
  const [isActive, setIsActive] = useState(true);
  const [counter, setCounter] = useState(0);
  const [ring, setRing] = useState(true);

  useEffect(() => {
    let intervalId;

    if (isActive) {
      intervalId = setInterval(() => {
        const secondCounter = counter % 60;
        const minuteCounter = Math.floor(counter / 60);

        const computedSecond =
          String(secondCounter).length === 1
            ? `0${secondCounter}`
            : secondCounter;
        const computedMinute =
          String(minuteCounter).length === 1
            ? `0${minuteCounter}`
            : minuteCounter;

        setSecond(computedSecond);
        setMinute(computedMinute);
        console.log("timer called to refresh order summary");
        GetOrdersSummary();

        setCounter((counter) => counter + 1);
        setRing(false);
      }, 20000); //refresh once in 20seconds
      setRing(true);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isActive, counter]);

  return (
    <div className="container">
      {ring == true ? (
        <>
          {orderSummary.orderReceived > 0 ? (
            <>
              {storeSettings.disableNewOrderBeepSound == false ? (
                <>
                  <BoopButton></BoopButton>
                </>
              ) : (
                <></>
              )}
            </>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

class BoopButton extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount = () => {
    try {
      const audioEl = document.getElementsByClassName("audio-element")[0];
      audioEl.play();
    } catch (err) {}
  };
  render() {
    return (
      <>
        <audio className="audio-element">
          <source src="/telephone-ring-129620.mp3"></source>
          {/* <source src="https://assets.coderrocketfuel.com/pomodoro-times-up.mp3"></source> */}
        </audio>
      </>
    );
  }
}

export default RefreshOrderSummary;
