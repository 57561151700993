import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiRefresh } from "react-icons/bi";
import { BsFillPencilFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import DatePicker from "react-date-picker";

import {
  OverlayTrigger,
  InputGroup,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Modal,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export class AccountLedger extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      dateRange: "This Month",
      ledgerSummary: "",
    };
  }

  GetLedgerSummary = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetLedgerSummary/" +
        this.context.storeId +
        "/" +
        this.state.dateRange
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ ledgerSummary: data }, () => {});
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.GetLedgerSummary();
  };

  render() {
    return (
      <>
        <div className="Container-fluid p-1 ">
          <Row>
            {window.innerWidth > 1200 && this.props.isReport != true ? (
              <>
                <Col className="" xs={2}>
                  <SideMenu></SideMenu>
                </Col>
              </>
            ) : (
              <></>
            )}

            <Col
              xs={window.innerWidth > 1200 ? 10 : 12}
              className="pagebackground p-0"
            >
              {this.props.isReport != true ? (
                <>
                  <Navbar className="secondarythemecolor position-sticky fixed-top px-1 m-0 p-1 text-center border-3 border-dark">
                    <h4> &nbsp; &nbsp;Account Ledger &nbsp;</h4>
                  </Navbar>
                </>
              ) : (
                <></>
              )}

              <div className="bg-light ">
                <Table className="bg-light m-0 p-0">
                  <tr className="border-0">
                    <td width="20%">
                      <InputGroup className="mb-1">
                        <InputGroup.Text id="basic-addon1">
                          Date Range
                        </InputGroup.Text>
                        <Form.Select
                          value={this.state.dateRange}
                          onChange={(e) => {
                            this.setState({ dateRange: e.target.value }, () => {
                              this.GetLedgerSummary();
                            });
                          }}
                        >
                          <option value="This Month">This Month</option>
                          <option value="Last Month">Last Month</option>
                          <option value="This Year">This Year</option>
                          <option value="Last Year">Last Year</option>
                        </Form.Select>
                      </InputGroup>
                    </td>
                    {/* <td width="20%">
                      <InputGroup className="mb-1">
                        <InputGroup.Text id="basic-addon1">
                          Category
                        </InputGroup.Text>
                        <Form.Select
                          value={this.state.filterUserTypeName}
                          onChange={(e) => {
                            this.OnCategoryChanged(e);
                          }}
                        >
                          <option value=""></option>
                          {this.state.categories.map((m) => (
                            <>
                              <option value={m.id}>{m.name}</option>
                            </>
                          ))}
                        </Form.Select>
                      </InputGroup>
                    </td> */}
                  </tr>
                </Table>
              </div>
              <div className="bg-light p-5">
                <Row>
                  <Col xs={6} className="border">
                    <h2 className="text-center">CREDITS</h2>
                    <Row>
                      <Col>
                        <h4>Sales Revenue</h4>
                      </Col>
                      <Col>
                        <h4 className="text-end">
                          {Number(
                            this.state.ledgerSummary.totalSalesRevenue
                          ).toFixed(2)}
                        </h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4>Other Credits</h4>
                      </Col>
                      <Col>
                        <h4 className="text-end">
                          {Number(
                            this.state.ledgerSummary.totalOtherRevenue
                          ).toFixed(2)}
                        </h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col></Col>
                      <Col></Col>
                    </Row>
                    <Row>
                      <Col></Col>
                      <Col></Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <h4>
                          <b>Sub.Total (A):</b>
                        </h4>
                      </Col>
                      <Col>
                        <h4 className="text-end">
                          <b>
                            {Number(
                              Number(
                                this.state.ledgerSummary.totalSalesRevenue
                              ) +
                                Number(
                                  this.state.ledgerSummary.totalOtherRevenue
                                )
                            ).toFixed(2)}
                          </b>
                        </h4>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={6} className="border">
                    <h2 className="text-center">DEBITS</h2>
                    <Row>
                      <Col>
                        <h4>Product Purchases</h4>
                      </Col>
                      <Col>
                        <h4 className="text-end">
                          {Number(
                            this.state.ledgerSummary.totalProductPurchases
                          ).toFixed(2)}
                        </h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4>Rawmaterial Purchases</h4>
                      </Col>
                      <Col>
                        <h4 className="text-end">
                          {Number(
                            this.state.ledgerSummary.totalRawmaterialPurchases
                          ).toFixed(2)}
                        </h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4>Other Expenses</h4>
                      </Col>
                      <Col>
                        <h4 className="text-end">
                          {Number(
                            this.state.ledgerSummary.totalOtherExpenses
                          ).toFixed(2)}
                        </h4>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <h4>Employee Salaries</h4>
                      </Col>
                      <Col>
                        <h4 className="text-end">
                          {Number(
                            this.state.ledgerSummary.totalSalaries
                          ).toFixed(2)}
                        </h4>
                      </Col>
                    </Row>
                    <hr />
                    <Row>
                      <Col>
                        <h4>
                          <b>Sub.Total (B):</b>
                        </h4>
                      </Col>
                      <Col>
                        <h4 className="text-end">
                          <b>
                            {Number(
                              Number(
                                this.state.ledgerSummary.totalProductPurchases
                              ) +
                                Number(
                                  this.state.ledgerSummary
                                    .totalRawmaterialPurchases
                                ) +
                                Number(
                                  this.state.ledgerSummary.totalOtherExpenses
                                ) +
                                Number(this.state.ledgerSummary.totalSalaries)
                            ).toFixed(2)}
                          </b>
                        </h4>
                      </Col>
                    </Row>
                  </Col>
                </Row>
                <Row className="py-3  border">
                  <Col className="text-center">
                    <h1>
                      <b>
                        Balance (A-B) :{" "}
                        {Number(
                          Number(this.state.ledgerSummary.totalSalesRevenue) +
                            Number(this.state.ledgerSummary.totalOtherRevenue) -
                            (Number(
                              this.state.ledgerSummary.totalProductPurchases
                            ) +
                              Number(
                                this.state.ledgerSummary
                                  .totalRawmaterialPurchases
                              ) +
                              Number(
                                this.state.ledgerSummary.totalOtherExpenses
                              ) +
                              Number(this.state.ledgerSummary.totalSalaries))
                        ).toFixed(2)}{" "}
                      </b>
                    </h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <h4>Total Account Receivables:</h4>
                  </Col>
                  <Col>
                    <h4 className="text-end">
                      {Number(
                        this.state.ledgerSummary.accountReceiveable
                      ).toFixed(2)}
                    </h4>
                  </Col>
                  <Col>
                    <h4>Total Account Payables:</h4>
                  </Col>
                  <Col>
                    <h4 className="text-end">
                      {Number(this.state.ledgerSummary.accountPayable).toFixed(
                        2
                      )}
                    </h4>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
