import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiRefresh } from "react-icons/bi";
import { BsFillPencilFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import DatePicker from "react-date-picker";
import DateTimePicker from "react-datetime-picker";

import {
  OverlayTrigger,
  InputGroup,
  Tooltip,
  Button,
  Stack,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Modal,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export class AccountExpenses extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      accountexpenses: [],
      filteredaccountexpenses: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      reload: true,
      categories: [],
      dateRange: "Today",
      selectedCategoryId: "",
      startDate: new Date(),
      endDate: new Date(),
    };
  }
  OnCategoryChanged = (e) => {
    this.setState({ selectedCategoryId: e.target.value });
  };
  OnDateRangeChanged = (e) => {
    const { name, value } = e.target;
    this.setState({ dateRange: value }, () => {
      this.GetAccountExpenses();
    });
  };

  GetAccountExpensesCategorys = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetAccountExpensesCategorys/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ categories: data }, () => {});
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  GetAccountExpenses = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetAccountExpenses/" +
        this.context.storeId +
        "/" +
        this.state.dateRange +
        "/" +
        this.context.GetTicks(this.state.startDate) +
        "/" +
        this.context.GetTicks(this.state.endDate)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ accountexpenses: data }, () => {
            this.setState({ filteredaccountexpenses: data });
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnSaved = () => {
    this.OnRefreshButtonClick();
  };
  OnDeleted = () => {
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.setState({ accountexpenses: [] }, () => {
      this.GetAccountExpenses();
    });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  OnUpdated = (indexno) => {
    this.OnRefreshButtonClick();
  };

  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
    this.OnRefreshButtonClick();
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    if (this.state.accountexpenses.length == 0) this.GetAccountExpenses();
    this.GetAccountExpensesCategorys();
  };

  render() {
    return (
      <>
        <div className="Container-fluid p-1 ">
          <Row>
            {window.innerWidth > 1200 ? (
              <>
                <Col className="" xs={2}>
                  <SideMenu></SideMenu>
                </Col>
              </>
            ) : (
              <></>
            )}

            <Col
              xs={window.innerWidth > 1200 ? 10 : 12}
              className="pagebackground p-0"
            >
              {this.state.msgboxOpen === true ? (
                <MessageBox
                  open={this.state.msgboxOpen}
                  title={this.state.msgboxTitle}
                  message={this.state.msgboxMessage}
                  callback={this.CloseMessagBoxCallback}
                />
              ) : (
                ""
              )}
              <Navbar className="secondarythemecolor position-sticky fixed-top px-1 m-0 p-1 text-center border-3 border-dark">
                <h4> &nbsp; &nbsp;Account Expenses &nbsp;</h4>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemecolor"
                        // onClick={() => this.handleAddAccountExpenses()}
                        onClick={() => this.setState({ addModalShow: true })}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Refresh
                      </Button>
                      &nbsp;
                      {this.state.addModalShow === true ? (
                        <AccountExpensesAddOrEditModal
                          show={this.state.addModalShow}
                          Type="Add"
                          onHide={this.addModalClose}
                          categories={this.state.categories}
                        />
                      ) : null}
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div className="bg-light ">
                <Table className="bg-light m-0 p-0">
                  <tr className="border-0">
                    <td width="20%">
                      <InputGroup className="mb-1">
                        <InputGroup.Text id="basic-addon1">
                          Date Range
                        </InputGroup.Text>
                        <Form.Select
                          value={this.state.dateRange}
                          onChange={(e) => {
                            this.OnDateRangeChanged(e);
                          }}
                        >
                          <option value="Today">Today</option>
                          <option value="Yesterday">Yesterday</option>
                          <option value="Last 3 Days">Last 3 Days</option>
                          <option value="Last 7 Days">Last 7 Days</option>
                          <option value="This Month">This Month</option>
                          <option value="Last Month">Last Month</option>
                          <option value="Last 3 Months">Last 3 Months</option>
                          <option value="All">All</option>
                          <option value="Custom">Custom</option>
                        </Form.Select>
                      </InputGroup>
                    </td>
                    {this.state.dateRange == "Custom" ? (
                      <>
                        <td>
                          StartDate:{" "}
                          <DateTimePicker
                            onChange={(date) =>
                              this.setState({ startDate: date }, () => {
                                this.GetAccountExpenses();
                              })
                            }
                            value={this.state.startDate}
                            format="dd-MMM-yy HH:mm a"
                          />
                        </td>
                        <td>
                          <Stack direction="horizontal">
                            EndDate:
                            <DateTimePicker
                              onChange={(date) =>
                                this.setState({ endDate: date }, () => {
                                  this.GetAccountExpenses();
                                })
                              }
                              value={this.state.endDate}
                              format="dd-MMM-yy hh:mm a"
                            />
                          </Stack>
                        </td>
                      </>
                    ) : (
                      <></>
                    )}
                    <td width="20%">
                      <InputGroup className="mb-1">
                        <InputGroup.Text id="basic-addon1">
                          Category
                        </InputGroup.Text>
                        <Form.Select
                          value={this.state.filterUserTypeName}
                          onChange={(e) => {
                            this.OnCategoryChanged(e);
                          }}
                        >
                          <option value=""></option>
                          {this.state.categories.map((m) => (
                            <>
                              <option value={m.id}>{m.name}</option>
                            </>
                          ))}
                        </Form.Select>
                      </InputGroup>
                    </td>
                  </tr>
                </Table>
              </div>
              <div>
                <Table border striped className="px-0 bg-white ">
                  <thead className="">
                    <tr className="text-start text-black bg-white border">
                      <th width="5%">#</th>
                      <>
                        <th width="10%" className="text-center">
                          Date{" "}
                        </th>
                        <th width="35%" className="text-start">
                          Expense Details
                        </th>
                        <th width="10%" className="text-center">
                          Category
                        </th>
                        <th width="10%" className="text-center">
                          PaidTo
                        </th>
                        <th width="10%" className="text-center">
                          Amount
                          <br />
                          Total:
                          {Math.round(
                            this.state.filteredaccountexpenses.reduce(
                              (a, v) => Number((a = a + Number(v.amount))),
                              0
                            ),
                            0
                          )}
                        </th>
                        <th width="10%" className="text-center">
                          CreatedBy
                        </th>
                        <th width="10%" className="text-center">
                          Attachment
                        </th>
                        <th width="10%" className="text-end">
                          Options
                        </th>
                      </>
                    </tr>
                  </thead>
                  <tbody className="text-primary border">
                    {this.state.filteredaccountexpenses
                      .filter((f) => {
                        if (
                          this.state.selectedCategoryId != "" &&
                          f.expenseCategoryId == this.state.selectedCategoryId
                        )
                          return true;
                        if (this.state.selectedCategoryId == "") return true;
                      })
                      .sort((a, b) => (a.created > b.created ? 1 : -1))
                      .map((accountexpenses, i) =>
                        this.state.reload === true ? (
                          <EditAccountExpensesInline
                            index={i}
                            accountexpenses={accountexpenses}
                            categories={this.state.categories}
                            OnDeleted={this.OnDeleted}
                          ></EditAccountExpensesInline>
                        ) : null
                      )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export class EditAccountExpensesInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      accountexpenses: "",
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      editModalShow: false,
    };
  }

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDelete = () => {
    if (this.state.accountexpenses.id == undefined) {
      this.ShowMessagBox(
        "Information",
        "This is invalid row which can't be deleted. Press Refresh!"
      );
      return;
    }

    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteAccountExpense/" +
        this.state.accountexpenses.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert(
            "Not deleted. This AccountExpenses may be associated with few Products. First disassociate this accountexpenses from the Products and then try!"
          );
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Status",
          "Deleted Successfully. Click Refresh Button to see the changes!"
        );
        this.props.OnDeleted();
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("OnDelete Failed" + error);
      });
  };

  componentDidMount = () => {
    this.setState({
      accountexpenses: this.props.accountexpenses,
    });
  };
  OnEditButtonClick = () => {
    this.setState({ editModalShow: true }, () => {});
  };

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {});
  };

  GetCategoryName = (id) => {
    var result = this.props.categories.filter(
      (f) => f.id == this.state.accountexpenses.expenseCategoryId
    );
    if (result.length > 0) return result[0].name;
    return "";
  };

  render() {
    return (
      <>
        {this.state.editModalShow === true ? (
          <AccountExpensesAddOrEditModal
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            accountexpenses={this.state.accountexpenses}
            categories={this.props.categories}
            Type="Edit"
          />
        ) : (
          ""
        )}
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <tr
          className="text-center align-middle text-secondary bg-white mx-5"
          style={{ fontSize: "12px" }}
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          <td className="text-center ">{this.props.index + 1}</td>
          <td className="text-center ">
            {this.context.GetInDateFormat(
              new Date(this.state.accountexpenses.created)
            )}
          </td>
          <td className="text-start ">
            {this.state.accountexpenses.expenseDescription}
          </td>
          <td className="text-center ">
            {this.GetCategoryName(this.state.accountexpenses.expenseCategoryId)}
          </td>
          <td className="text-center ">{this.state.accountexpenses.paidTo}</td>
          <td className="text-end ">{this.state.accountexpenses.amount}</td>
          <td className="text-center ">
            {this.state.accountexpenses.createdBy}
          </td>
          <td className="text-center ">
            {this.state.accountexpenses.attachmentFileName}
          </td>

          <td className="col-md-2 text-end align-middle ">
            <div class="btn-group">
              <OverlayTrigger overlay={<Tooltip>Edit!</Tooltip>}>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    this.OnEditButtonClick(e);
                  }}
                >
                  <BsFillPencilFill size="20" />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                overlay={
                  <Tooltip>
                    Warning! This will delete this Brand Row and associated
                    Products as well.
                  </Tooltip>
                }
              >
                <Button
                  className="mx-1 mainthemecolor"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        "Warning! This action will delete this Brand Row and associated Products as well. Are you sure you want to Delete?"
                      )
                    ) {
                      this.OnDelete(e);
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

export class AccountExpensesAddOrEditModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      accountexpenses: {
        accountexpensesName: "",
        manufacturerName: "",
        imgFileName: "",
        hideMe: false,
      },
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
    };
  }

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  AddOrUpdateAccountExpensesInDB = () => {
    this.ShowLoadSpinner();

    var tmpAccountExpenses = this.state.accountexpenses;
    if (this.props.Type == "Edit") {
      tmpAccountExpenses = this.props.accountexpenses;
    } else {
      tmpAccountExpenses.storeId = this.context.storeId;
    }
    tmpAccountExpenses.created =
      new Date(tmpAccountExpenses.created).getMonth() +
      1 +
      "/" +
      new Date(tmpAccountExpenses.created).getDate() +
      "/" +
      new Date(tmpAccountExpenses.created).getFullYear();

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmpAccountExpenses),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/AddOrUpdateAccountExpense/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.HideLoadSpinner();
          this.props.onHide();
          return;
        }
        this.HideLoadSpinner();
        alert("This AccountExpenses is already exist. try again!");
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("Exception. Received an Exception. Try after sometime !" + error);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.AddOrUpdateAccountExpensesInDB();
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "expenseDescription":
        this.state.accountexpenses.expenseDescription = value;
        break;
      case "expenseCategoryId":
        this.state.accountexpenses.expenseCategoryId = value;
        break;
      case "paidTo":
        this.state.accountexpenses.paidTo = value;
        break;
      case "amount":
        this.state.accountexpenses.amount = value;
        break;
      case "createdBy":
        this.state.accountexpenses.createdBy = value;
        break;
      case "attachmentFileName":
        this.state.accountexpenses.attachmentFileName = value;
        break;
    }
    this.setState({
      accountexpenses: this.state.accountexpenses,
    });
  };

  componentDidMount() {
    if (this.props.Type == "Edit") {
      this.setState({
        accountexpenses: this.props.accountexpenses,
      });
    }
  }

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          dialogClassName="AccountExpensesModal"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit"
                  ? "Edit accountexpenses"
                  : "Add accountexpenses"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Row
                className="m-2 p-2 bg-light border"
                style={{ fontSize: "9pt" }}
              >
                <Col md={12}>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Date
                    </Form.Label>
                    <Col sm="9">
                      <DatePicker
                        format="dd-MMM-yy"
                        onChange={(e) => {
                          var t = this.state.accountexpenses;
                          t.created = e;
                          this.setState({ accountexpenses: t }, () => {});
                        }}
                        value={this.state.accountexpenses.created}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Expense Details
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="expenseDescription"
                        maxlength="99"
                        value={this.state.accountexpenses.expenseDescription}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Category
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        name="expenseCategoryId"
                        value={this.state.accountexpenses.expenseCategoryId}
                        placeholder=""
                        required
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value=""></option>
                        {this.props.categories.map((m) => (
                          <option value={m.id}>{m.name}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Paid To
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="paidTo"
                        maxlength="99"
                        value={this.state.accountexpenses.paidTo}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Amount
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        name="amount"
                        value={this.state.accountexpenses.amount}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Created By
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="createdBy"
                        value={this.state.accountexpenses.createdBy}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Attachment
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="attachmentFileName"
                        value={this.state.accountexpenses.attachmentFileName}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  Save
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
