import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";

import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import {
  Row,
  Col,
  Form,
  Nav,
  Table,
  Navbar,
  Tooltip,
  Button,
  InputGroup,
  ButtonToolbar,
} from "react-bootstrap";

export class ClientDisplayCounter extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      displayCounter: {
        name1: "",
        maxValue1: "",
        endingLetter1: "+",
        name2: "",
        maxValue2: "",
        endingLetter2: "+",
        name3: "",
        maxValue3: "",
        endingLetter3: "+",
        name4: "",
        maxValue4: "",
        endingLetter4: "+",
        storeId: "",
      },
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      firstLoad: false,
    };
  }
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };
  fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 2000));
  };
  componentDidMount() {
    if (this.state.firstLoad == false) {
      this.state.firstLoad = true;
      this.GetDisplayCounter();
    }
    window.scrollTo(0, 0);
  }

  OnSaved = () => {
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.GetDisplayCounter();
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name.trim()) {
      case "name1":
        this.state.displayCounter.name1 = value;
        break;
      case "maxValue1":
        this.state.displayCounter.maxValue1 = value;
        break;
      case "endingLetter1":
        this.state.displayCounter.endingLetter1 = value;
        break;
      case "name2":
        this.state.displayCounter.name2 = value;
        break;
      case "maxValue2":
        this.state.displayCounter.maxValue2 = value;
        break;
      case "endingLetter2":
        this.state.displayCounter.endingLetter2 = value;
        break;
      case "name3":
        this.state.displayCounter.name3 = value;
        break;
      case "maxValue3":
        this.state.displayCounter.maxValue3 = value;
        break;
      case "endingLetter3":
        this.state.displayCounter.endingLetter3 = value;
        break;
      case "name4":
        this.state.displayCounter.name4 = value;
        break;
      case "maxValue4":
        this.state.displayCounter.maxValue4 = value;
        break;
      case "endingLetter4":
        this.state.displayCounter.endingLetter4 = value;
        break;
    }
    this.setState({ displayCounter: this.state.displayCounter });
  };

  GetDisplayCounter = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetClientDisplayCounters/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ displayCounter: data });
        }
      })
      .catch((error) => {});
  };
  OnSave = (e) => {
    this.ShowLoadSpinner();

    this.state.displayCounter.storeId = this.context.storeId;
    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state.displayCounter),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/UpdateClientDisplayCounters/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.GetDisplayCounter();
          this.HideLoadSpinner();
          this.ShowMessagBox("Information", "Saved Successfully!");
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed!");
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed! " + error);
      });
  };

  componentDidUpdate() {}
  render() {
    return (
      <>
        {this.state.msgboxOpen === true ? (
          <MessageBox
            open={this.state.msgboxOpen}
            title={this.state.msgboxTitle}
            message={this.state.msgboxMessage}
            callback={this.CloseMessagBoxCallback}
          />
        ) : (
          ""
        )}
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}

        <div className="Container-fluid p-1 pagebackground">
          <Row>
            <Col className="pagebackground " xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="p-2 bg-white">
              <Navbar className="secondarythemecolor position-sticky fixed-top  my-0">
                <h4> &nbsp; &nbsp;Manage Display Counters &nbsp;</h4>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemeColorCode"
                        size=""
                        onClick={(e) => {
                          this.OnSave(e);
                        }}
                      >
                        <BiSave size="26" />
                        Save
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemeColorCode"
                        size=""
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Undo
                      </Button>
                      &nbsp;
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <br></br>
              {this.state.displayCounter == "" ? (
                ""
              ) : (
                <>
                  <div className="border ">
                    <Row className="p-2 m-2 bg-light">
                      <Col width="100%">
                        <div className="px-3 py-2 border border-3 w-50 bg-light w-100">
                          <Row className="p-1 w-100">
                            <Col sm={2}>
                              <h2>Counter1</h2>
                            </Col>
                            <Col sm={5}>
                              <InputGroup className="mb-1 " size="lg">
                                <InputGroup.Text id="basic-addon1">
                                  Name
                                </InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  name="name1"
                                  size="lg"
                                  value={this.state.displayCounter.name1}
                                  required
                                  placeholder=""
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </InputGroup>
                            </Col>
                            <Col sm={3}>
                              <InputGroup className="mb-1 " size="lg">
                                >
                                <InputGroup.Text id="basic-addon1">
                                  MaxValue
                                </InputGroup.Text>
                                <Form.Control
                                  type="number"
                                  min={1}
                                  size="lg"
                                  name="maxValue1"
                                  value={this.state.displayCounter.maxValue1}
                                  required
                                  placeholder=""
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </InputGroup>
                            </Col>
                            <Col sm={2}>
                              <InputGroup className="mb-1 " size="lg">
                                >
                                <InputGroup.Text id="basic-addon1">
                                  Ending Letter
                                </InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  size="lg"
                                  name="endingLetter1"
                                  value={
                                    this.state.displayCounter.endingLetter1
                                  }
                                  required
                                  placeholder=""
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row className="p-2 m-2 bg-light">
                      <Col width="100%">
                        <div className="px-3 py-2 border border-3 w-50 bg-light w-100">
                          <Row className="p-1 w-100">
                            <Col sm={2}>
                              <h2>Counter2</h2>
                            </Col>
                            <Col sm={5}>
                              <InputGroup className="mb-1 " size="lg">
                                <InputGroup.Text id="basic-addon1">
                                  Name
                                </InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  size="lg"
                                  name="name2"
                                  value={this.state.displayCounter.name2}
                                  required
                                  placeholder=""
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </InputGroup>
                            </Col>
                            <Col sm={3}>
                              <InputGroup className="mb-1" size="lg">
                                <InputGroup.Text id="basic-addon1">
                                  MaxValue
                                </InputGroup.Text>
                                <Form.Control
                                  type="number"
                                  size="lg"
                                  min={1}
                                  name="maxValue2"
                                  value={this.state.displayCounter.maxValue2}
                                  required
                                  placeholder=""
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </InputGroup>
                            </Col>
                            <Col sm={2}>
                              <InputGroup className="mb-1 " size="lg">
                                <InputGroup.Text id="basic-addon1">
                                  Ending Letter
                                </InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  name="endingLetter2"
                                  value={
                                    this.state.displayCounter.endingLetter2
                                  }
                                  required
                                  placeholder=""
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                    <Row className="p-2 m-2 bg-light">
                      <Col width="100%">
                        <div className="px-3 py-2 border border-3 w-50 bg-light w-100">
                          <Row className="p-1 w-100">
                            <Col sm={2}>
                              <h2>Counter3</h2>
                            </Col>
                            <Col sm={5}>
                              <InputGroup className="mb-1 " size="lg">
                                <InputGroup.Text id="basic-addon1">
                                  Name
                                </InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  name="name3"
                                  value={this.state.displayCounter.name3}
                                  required
                                  placeholder=""
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </InputGroup>
                            </Col>
                            <Col sm={3}>
                              <InputGroup className="mb-1 " size="lg">
                                <InputGroup.Text id="basic-addon1">
                                  MaxValue
                                </InputGroup.Text>
                                <Form.Control
                                  type="number"
                                  min={1}
                                  name="maxValue3"
                                  value={this.state.displayCounter.maxValue3}
                                  required
                                  placeholder=""
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </InputGroup>
                            </Col>
                            <Col sm={2}>
                              <InputGroup className="mb-1 " size="lg">
                                <InputGroup.Text id="basic-addon1">
                                  Ending Letter
                                </InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  name="endingLetter3"
                                  value={
                                    this.state.displayCounter.endingLetter3
                                  }
                                  required
                                  placeholder=""
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>

                    <Row className="p-2 m-2 bg-light">
                      <Col width="100%">
                        <div className="px-3 py-2 border border-3 w-50 bg-light w-100">
                          <Row className="p-1 w-100">
                            <Col sm={2}>
                              <h2>Counter4</h2>
                            </Col>
                            <Col sm={5}>
                              <InputGroup className="mb-1 " size="lg">
                                <InputGroup.Text id="basic-addon1">
                                  Name
                                </InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  name="name4"
                                  value={this.state.displayCounter.name4}
                                  required
                                  placeholder=""
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </InputGroup>
                            </Col>
                            <Col sm={3}>
                              <InputGroup className="mb-1 " size="lg">
                                <InputGroup.Text id="basic-addon1">
                                  MaxValue
                                </InputGroup.Text>
                                <Form.Control
                                  type="number"
                                  min={1}
                                  name="maxValue4"
                                  value={this.state.displayCounter.maxValue4}
                                  required
                                  placeholder=""
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </InputGroup>
                            </Col>
                            <Col sm={2}>
                              <InputGroup className="mb-1 " size="lg">
                                <InputGroup.Text id="basic-addon1">
                                  Ending Letter
                                </InputGroup.Text>
                                <Form.Control
                                  type="text"
                                  name="endingLetter4"
                                  value={
                                    this.state.displayCounter.endingLetter4
                                  }
                                  required
                                  placeholder=""
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </InputGroup>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
