import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import axios from "axios";

import {
  OverlayTrigger,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export class HomeSettingsBlog extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      blogs: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      reload: true,
    };
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDeleted = () => {
    this.OnRefreshButtonClick();
  };
  OnSaved = () => {
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.setState({ blogs: [] }, () => {
      this.setState({ reload: false }, () => {
        this.GetBlogs();
        this.setState({ reload: true });
      });
    });
  };

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  handleAddBlog = (e) => {
    this.setState({
      blogs: [
        ...this.state.blogs,
        {
          storeId: this.context.storeId,
          imgFileName: "",
          shortDescription: "",
          htmlFileName: "",
          Created: "",
          seqNo: this.state.blogs.length + 1,
        },
      ],
    });
    alert("An empty row added at the end of list below!");
  };

  OnSave = (e) => {
    this.ShowLoadSpinner();

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.blogs),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/AddOrUpdateBlogs/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.HideLoadSpinner();
          this.OnRefreshButtonClick();
          this.ShowMessagBox("Status", "Saved Successfully. ");
          return;
        }
        this.HideLoadSpinner();
        alert("Not Saved. try later again!");
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("Exception. Received an Exception. Try after sometime !" + error);
      });
  };
  OnDelete = (i) => {
    if (this.state.blogs[i].id == undefined) {
      this.ShowMessagBox(
        "Information",
        "This is invalid row which can't be deleted. Press Refresh!"
      );
      return;
    }

    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteBlog/" +
        this.state.blogs[i].id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert("not deleted");
          return;
        }
        this.HideLoadSpinner();
        this.OnDeleted();
        this.ShowMessagBox(
          "Status",
          "Deleted Successfully. Click Refresh Button to see the changes!"
        );
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("OnDelete Failed" + error);
      });
  };
  handleInputChange = (e, i) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "shortDescription":
        this.state.blogs[i].shortDescription = value;
        break;
      case "imgFileName":
        this.state.blogs[i].imgFileName = value;
        break;
      case "htmlFileName":
        this.state.blogs[i].htmlFileName = value;
        break;
      case "seqNo":
        this.state.blogs[i].seqNo = value;
        break;
      case "created":
        this.state.blogs[i].created = value;
        break;
    }
    this.setState({ blogs: this.state.blogs });
  };

  GetBlogs = () => {
    var res = axios
      .get(
        process.env.REACT_APP_API + "Consumers/GetBlogs/" + this.context.storeId
      )
      .then((response) => {
        if (response.status == 404) {
        } else if (response.status == 200) {
          this.setState({
            blogs: response.data.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1)),
          });
        }
      })
      .catch((error) => {
        console.error("GetCarousel - There was an error! ", error);
      });
  };
  componentDidMount = () => {
    window.scrollTo(0, 0);
    this.GetBlogs();
  };

  render() {
    return (
      <>
        <div className="Container-fluid p-1 ">
          <Row>
            <Col className="" xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="pagebackground p-0">
              {this.state.msgboxOpen === true ? (
                <MessageBox
                  open={this.state.msgboxOpen}
                  title={this.state.msgboxTitle}
                  message={this.state.msgboxMessage}
                  callback={this.CloseMessagBoxCallback}
                />
              ) : (
                ""
              )}
              {this.state.loadSpinnerOpen === true ? (
                <LoadSpinner open="true"></LoadSpinner>
              ) : (
                ""
              )}
              <Navbar
                expand="lg"
                className="secondarythemecolor position-sticky fixed-top  px-3"
              >
                <h4> &nbsp; &nbsp;Blogs &nbsp;</h4>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemecolor"
                        onClick={() => this.handleAddBlog()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add
                      </Button>
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={(e) => {
                          this.OnSave(e);
                        }}
                      >
                        <BiSave size="26" />
                        Save
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Refresh
                      </Button>
                      &nbsp;
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>

              <div></div>
              <div>
                {this.state.reload == true ? (
                  <>
                    <Table border striped className="px-0 bg-white ">
                      <thead className="">
                        <tr className="text-center text-black bg-white border">
                          <th width="20%">Poster Image</th>
                          <th width="30%">Description</th>
                          <th width="20%">Html File</th>
                          <th width="10%">SeqNo</th>
                          <th width="10%" className="text-center">
                            Options
                          </th>
                        </tr>
                      </thead>
                      <tbody className="text-primary border">
                        {this.state.blogs.map((blog, i) => (
                          <>
                            <tr
                              className="text-center align-middle text-secondary bg-white mx-5"
                              style={{ fontSize: "12px" }}
                            >
                              <td>
                                <Form.Select
                                  type="text"
                                  name="imgFileName"
                                  value={this.state.blogs[i].imgFileName}
                                  required
                                  placeholder=""
                                  autoComplete="off"
                                  className="text-start"
                                  onChange={(e) => this.handleInputChange(e, i)}
                                >
                                  <option value=""></option>
                                  {this.context.images.map((r) => (
                                    <option value={r.name}>{r.name}</option>
                                  ))}
                                </Form.Select>
                              </td>
                              <td>
                                <Form.Control
                                  as="textarea"
                                  type="textarea"
                                  rows={2}
                                  name="shortDescription"
                                  value={this.state.blogs[i].shortDescription}
                                  maxLength={249}
                                  required
                                  placeholder=""
                                  align="center"
                                  className="text-start"
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e, i)}
                                />
                              </td>
                              <td>
                                <Form.Select
                                  type="text"
                                  name="htmlFileName"
                                  value={this.state.blogs[i].htmlFileName}
                                  required
                                  placeholder=""
                                  autoComplete="off"
                                  className="text-start"
                                  onChange={(e) => this.handleInputChange(e, i)}
                                >
                                  <option value=""></option>
                                  {this.context.images.map((r) => (
                                    <option value={r.name}>{r.name}</option>
                                  ))}
                                </Form.Select>
                              </td>
                              {/* <td className=" text-center ">
                                <img
                                  src={
                                    this.context.store.storageBlobUrl +
                                    this.context.store
                                      .storageBlobContainerName +
                                    "/images/" +
                                    this.state.blogs[i].fileName +
                                    this.context.store.storageSasToken
                                  }
                                  className="productimg-on-productlist mx-auto"
                                  alt="..."
                                />
                              </td> */}
                              <td>
                                <Form.Control
                                  type="number"
                                  name="seqNo"
                                  min={0}
                                  value={this.state.blogs[i].seqNo}
                                  required
                                  placeholder=""
                                  align="center"
                                  className="text-start"
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e, i)}
                                />
                              </td>
                              <td className="col-md-2 text-center align-middle ">
                                <div class="btn-group">
                                  <OverlayTrigger
                                    overlay={
                                      <Tooltip>
                                        Warning! This will delete this Blog Row
                                        and associated Products as well.
                                      </Tooltip>
                                    }
                                  >
                                    <Button
                                      className="mx-1 mainthemecolor"
                                      onClick={(e) => {
                                        if (
                                          window.confirm(
                                            "Warning! This action will delete this Blog Row and associated Products as well. Are you sure you want to Delete?"
                                          )
                                        ) {
                                          this.OnDelete(i);
                                        }
                                      }}
                                    >
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="25"
                                        height="25"
                                        fill="currentColor"
                                        class="bi bi-trash"
                                        viewBox="0 0 16 16"
                                      >
                                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                        <path
                                          fill-rule="evenodd"
                                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                        />
                                      </svg>
                                    </Button>
                                  </OverlayTrigger>
                                </div>
                              </td>
                            </tr>
                          </>
                        ))}
                      </tbody>
                    </Table>
                  </>
                ) : (
                  ""
                )}
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
