import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import { BsFillPencilFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import axios from "axios";
import Swal from "sweetalert2";

import {
  Modal,
  OverlayTrigger,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export class DataTypes extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      ProductDataType: [],
      ProductDataTypeValues: [],
      addDatatypeModalShow: false,
      editDatatypeModal: false,
      selectedDatatype: "",
      reloadDataTypeValues: false,
    };
  }

  addDatatypeModalClose = () => {
    this.setState({ addDatatypeModalShow: false }, () => {});
    this.RefreshDataType();
  };
  OnEditDataTypeButtonClick = (datatype) => {
    this.setState({ selectedDatatype: datatype });
    this.setState({ editDatatypeModal: true }, () => {
      this.setState({ addDatatypeModalShow: true }, () => {});
    });
  };

  RefreshDataType = () => {
    this.setState({ ProductDataType: [] }, () => this.GetProductDataType());
  };
  GetProductDataType = () => {
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "StoreManager/GetProductDataType/" +
          this.context.storeId
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ ProductDataType: response.data });
          if (response.data.length > 0) {
            // this.GetProductDataTypeValues(response.data[0]);
            // this.setState({ selectedDatatype: response.data[0] });
            this.onSelectDataTypeRow(response.data[0]);
          }
        }
      })
      .catch((error) => {
        console.error("GetProductDataType - There was an error! ", error);
      });
  };
  OnRefreshButtonClick = () => {
    this.setState({ ProductDataType: [] }, () => {
      this.GetProductDataType();
    });
  };
  DeleteDataType = (datatype) => {
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.context.userData.bearertoken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(datatype),
    };
    fetch(
      process.env.REACT_APP_API + "StoreManager/DeleteDataType/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.RefreshDataType();
          return;
        }
      })
      .catch((error) => {
        alert("Delete Failed! " + +error);
      });
  };

  onSelectDataTypeRow = (datatype) => {
    this.setState({ selectedDatatype: datatype }, () => {
      //this.GetProductDataTypeValues(datatype);
      this.setState({ reloadDataTypeValues: true }, () => {
        this.setState({ reloadDataTypeValues: false });
      });
    });
  };

  componentDidMount = () => {
    if (this.state.ProductDataType.length == 0) this.GetProductDataType();
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <>
        <div className="Container-fluid p-1 ">
          <Row>
            <Col className="" xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="pagebackground p-0">
              <Row>
                <Col xs={6} className="pagebackground p-0 border">
                  {this.state.addDatatypeModalShow === true ? (
                    <AddOrEditDataType
                      show={this.state.addDatatypeModalShow}
                      Type={
                        this.state.editDatatypeModal == true ? "Edit" : "Add"
                      }
                      ProductDataTypes={this.state.ProductDataType}
                      selectedDatatype={this.state.selectedDatatype}
                      onHide={this.addDatatypeModalClose}
                    />
                  ) : null}
                  <Navbar
                    expand="lg"
                    className="secondarythemecolor position-sticky fixed-top  px-3"
                  >
                    <h5>
                      {" "}
                      &nbsp; &nbsp;<b>DataTypes</b> &nbsp;
                    </h5>
                    <Navbar.Collapse className="justify-content-end mx-0 ">
                      <Nav>
                        <ButtonToolbar className="text-end align-middle">
                          <Button
                            className="mainthemecolor"
                            onClick={() =>
                              this.setState({ addDatatypeModalShow: true })
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="26"
                              height="26"
                              fill="currentColor"
                              class="bi bi-plus"
                              viewBox="0 0 16 16"
                            >
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                            </svg>
                            Add
                          </Button>
                          &nbsp;
                          <Button
                            className="mainthemecolor"
                            size=""
                            onClick={() => this.OnRefreshButtonClick()}
                          >
                            <BiRefresh size="26"></BiRefresh>
                            Refresh
                          </Button>
                          &nbsp;
                          {/* {this.state.addModalShow === true ? (
                            <BrandAddOrEditModal
                              show={this.state.addModalShow}
                              Type="Add"
                              onHide={this.addModalClose}
                            />
                          ) : null} */}
                        </ButtonToolbar>
                      </Nav>
                    </Navbar.Collapse>
                  </Navbar>
                  <Table
                    hover
                    border
                    // striped
                    className="px-0 bg-white imagetable"
                  >
                    <thead className="">
                      <tr className="text-center text-black bg-white border">
                        <th width="5%"></th>
                        <th width="40%">Data Type Name</th>
                        <th width="35%">Display As</th>
                        <th width="20%">Options</th>
                      </tr>
                    </thead>
                    <tbody className="text-primary border">
                      {this.state.ProductDataType.map((datatype, i) => (
                        <tr
                          className="text-center"
                          onClick={(e) => this.onSelectDataTypeRow(datatype)}
                        >
                          <>
                            <td>{i + 1}</td>
                            <td>{datatype.name}</td>
                            <td>{datatype.displayAs}</td>
                            <td>
                              <OverlayTrigger
                                overlay={<Tooltip>Edit!</Tooltip>}
                              >
                                <Button
                                  className="mainthemecolor"
                                  onClick={(e) => {
                                    this.OnEditDataTypeButtonClick(datatype);
                                  }}
                                >
                                  <BsFillPencilFill size="20" />
                                </Button>
                              </OverlayTrigger>
                              &nbsp;
                              <OverlayTrigger
                                overlay={<Tooltip>Delete!</Tooltip>}
                              >
                                <Button
                                  variant="outline-danger"
                                  onClick={(e) => {
                                    if (
                                      window.confirm(
                                        "Are you sure you want to Delete ?"
                                      )
                                    ) {
                                      this.DeleteDataType(datatype);
                                    }
                                  }}
                                >
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-trash"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                    <path
                                      fill-rule="evenodd"
                                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                    />
                                  </svg>
                                </Button>
                              </OverlayTrigger>
                            </td>
                          </>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </Col>
                <Col xs={6} className="pagebackground p-0 border">
                  {this.state.reloadDataTypeValues == false ? (
                    <>
                      <DataTypeValues
                        selectedDatatype={this.state.selectedDatatype}
                      ></DataTypeValues>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export class AddOrEditDataType extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      datatype: {
        name: "",
        displayAs: "DropDown",
      },
    };
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;
    //validate for minus values not to allow
    switch (name.trim()) {
      case "name":
        this.state.datatype.name = value;
        break;
      case "displayAs":
        this.state.datatype.displayAs = value;
        break;
    }
    this.setState({ datatype: this.state.datatype });
  };

  IsDuplicateDatatype = () => {
    if (this.props.Type != "Edit") {
      var result = this.props.ProductDataTypes.filter(
        (f) => f.name.toUpperCase() == this.state.datatype.name.toUpperCase()
      );
      if (result.length > 0) {
        return true;
      }
    }
    return false;
  };
  AddOrUpdateDataType = () => {
    if (this.IsDuplicateDatatype() == true) {
      alert("Same datatype name is already exist!");
      return;
    }
    this.state.datatype.storeId = this.context.storeId;
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.context.userData.bearertoken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state.datatype),
    };
    console.log(process.env.REACT_APP_API);
    fetch(
      process.env.REACT_APP_API + "StoreManager/AddOrEditDataType/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          Swal.fire({
            title: "Saved Successfully!",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              this.props.onHide();
              window.scrollTo(0, 0);
            }
          });
          return;
        } else if (res.status == 409) {
          alert("error 409");
        } else {
          alert("Error Saved Failed!");
        }
      })
      .catch((error) => {
        alert("Error Saved Failed! ");
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();

    this.AddOrUpdateDataType();
  };

  componentDidMount() {
    if (this.props.Type == "Edit") {
      this.setState({ datatype: this.props.selectedDatatype });
    }
  }

  render() {
    return (
      <>
        {this.state.msgboxOpen === true ? (
          <MessageBox
            open={this.state.msgboxOpen}
            title={this.state.msgboxTitle}
            message={this.state.msgboxMessage}
            callback={this.CloseMessagBoxCallback}
          />
        ) : (
          ""
        )}
        <Modal
          {...this.props}
          // size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          show={true}
          dialogClassName="payByCash"
        >
          <Form onSubmit={this.handleSubmit}>
            {/* <Form> */}
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                Add/Edit Datatype
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Form.Group as={Row} className="mb-1 " controlId="">
                <Form.Label column sm="5">
                  Datatype Name:
                </Form.Label>
                <Col sm="7">
                  <Form.Control
                    type="text"
                    size="lg"
                    name="name"
                    value={this.state.datatype.name}
                    required
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => this.handleInputChange(e)}
                  />
                </Col>
              </Form.Group>
              <Form.Group as={Row} className="mb-1 " controlId="">
                <Form.Label column sm="5">
                  Display As:
                </Form.Label>
                <Col sm="7">
                  {/* <Form.Control
                    type="text"
                    size="lg"
                    name="displayAs"
                    value={this.state.datatype.displayAs}
                    required
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => this.handleInputChange(e)}
                  /> */}
                  <Form.Select
                    type="text"
                    name="displayAs"
                    value={this.state.datatype.displayAs}
                    required
                    placeholder=""
                    autoComplete="off"
                    className="text-start"
                    onChange={(e) => this.handleInputChange(e)}
                  >
                    <option value="DropDown">DropDown</option>
                    {/* <option value="RadioButton">RadioButton</option> */}
                    <option value="ListItems">ListItems</option>
                  </Form.Select>
                </Col>
              </Form.Group>
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  {/* <Button variant="primary" size="lg" onClick={this.handleSubmit}> */}
                  Save
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </>
    );
  }
}

export class DataTypeValues extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      datatypeValues: [],
    };
  }
  GetProductDataTypeValues = (datatype) => {
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "StoreManager/GetProductDataTypeValues/" +
          this.context.storeId +
          "/" +
          datatype.name
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({
            datatypeValues: response.data.sort((a, b) =>
              a.seqNo > b.seqNo ? 1 : -1
            ),
          });
        }
      })
      .catch((error) => {
        console.error("GetProductDataTypeValues - There was an error! ", error);
      });
  };
  OnDeleted = () => {
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.setState({ datatypeValues: [] }, () => {
      this.GetProductDataTypeValues(this.props.selectedDatatype);
    });
  };

  handleAddDatatypeValue = () => {
    this.setState({
      datatypeValues: [
        ...this.state.datatypeValues,
        {
          storeId: this.context.storeId,
          dataTypeName: this.props.selectedDatatype.name,
          value: "",
          seqNo: this.state.datatypeValues.length + 1,
        },
      ],
    });
    alert("An empty row added at the end of list below!");
  };
  handleRemoveClick = (i) => {
    const list = [...this.state.datatypeValues];
    list.splice(i, 1);
    this.setState({ datatypeValues: list });
  };

  handleInputChange = (e, i) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "value":
        this.state.datatypeValues[i].value = value;
        break;
      case "seqNo":
        this.state.datatypeValues[i].seqNo = value;
        break;
    }
    this.setState({ datatypeValues: this.state.datatypeValues });
  };

  OnSave = (e) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.datatypeValues),
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/SaveDataTypeValues/" +
        this.context.storeId +
        "/" +
        this.props.selectedDatatype.name,
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.GetProductDataTypeValues(this.props.selectedDatatype);
          alert("Saved Successfully");
          return;
        }
      })
      .catch((error) => {
        alert("Unable to Save");
      });
  };

  componentDidMount = () => {
    this.GetProductDataTypeValues(this.props.selectedDatatype);
  };

  render() {
    return (
      <>
        <div className="Container-fluid p-1 ">
          <Navbar
            expand="lg"
            className="bg-warning position-sticky fixed-top  px-3"
          >
            <h4>
              {" "}
              &nbsp; &nbsp;{this.props.selectedDatatype.name}'s&nbsp;values
            </h4>
            <Navbar.Collapse className="justify-content-end mx-0 ">
              <Nav>
                <ButtonToolbar className="text-end align-middle">
                  <Button
                    className="mainthemecolor"
                    onClick={() => this.handleAddDatatypeValue()}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="26"
                      height="26"
                      fill="currentColor"
                      class="bi bi-plus"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                    </svg>
                    Add
                  </Button>
                  &nbsp;
                  <Button
                    className="mainthemecolor"
                    size=""
                    onClick={(e) => {
                      this.OnSave(e);
                    }}
                  >
                    <BiSave size="26" />
                    Save
                  </Button>
                  &nbsp;
                  <Button
                    className="mainthemecolor"
                    size=""
                    onClick={() => this.OnRefreshButtonClick()}
                  >
                    <BiRefresh size="26"></BiRefresh>
                    Refresh
                  </Button>
                </ButtonToolbar>
              </Nav>
            </Navbar.Collapse>
          </Navbar>
          <div>
            <Table border striped className="px-0 bg-white ">
              <thead className="">
                <tr className="text-center text-black bg-white border">
                  <th></th>
                  <th width="50%">Value</th>
                  <th width="20%">Seq No</th>
                  <th width="20%" className="text-center">
                    Options
                  </th>
                </tr>
              </thead>
              <tbody className="text-primary border">
                {this.state.datatypeValues.map((datatypeValue, i) => (
                  <>
                    <tr>
                      <td>{i + 1}</td>
                      <td>
                        <Form.Control
                          type="text"
                          name="value"
                          value={datatypeValue.value}
                          onChange={(e) => this.handleInputChange(e, i)}
                        ></Form.Control>
                      </td>
                      <td>
                        {" "}
                        <Form.Control
                          type="number"
                          name="seqNo"
                          min={0}
                          value={datatypeValue.seqNo}
                          required
                          placeholder=""
                          align="center"
                          className="text-center  "
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e, i)}
                        />
                      </td>
                      <td className="text-center align-middle">
                        <Button
                          className="mx-1 mainthemecolor"
                          onClick={(e) => {
                            if (
                              window.confirm(
                                "Warning! This action will delete this Carousel Row and associated Products as well. Are you sure you want to Delete?"
                              )
                            ) {
                              this.handleRemoveClick(i);
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="25"
                            height="25"
                            fill="currentColor"
                            class="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                            <path
                              fill-rule="evenodd"
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                            />
                          </svg>
                        </Button>
                      </td>
                    </tr>
                  </>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </>
    );
  }
}
