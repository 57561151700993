import React, { Component } from "react";
import CartContext from "./CartContext";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";

import {
  FloatingLabel,
  OverlayTrigger,
  Tooltip,
  Table,
  InputGroup,
  Modal,
  Button,
  Row,
  Col,
  Form,
  Image,
  Alert,
} from "react-bootstrap";

// https://www.geeksforgeeks.org/how-to-create-an-editable-table-with-add-delete-and-search-filter-using-reactjs/
export class ProductGroupAddOrEditModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      productGroup: { seqNo: 1, hideMe: "false", isCarouselType: "false" },
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
    };
  }

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  AddProductGroupInDB = () => {
    this.ShowLoadSpinner();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        storeId: this.context.storeId,
        name: this.state.productGroup.name,
        imgFileName: this.state.productGroup.imgFileName,
        seqNo: this.state.productGroup.seqNo,
        adsImgFileName: this.state.productGroup.adsImgFileName,
        hideMe: this.state.productGroup.hideMe,
        isCarouselType: this.state.productGroup.isCarouselType,
      }),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/AddProductGroup/",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Information", "Added Successfully");
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Failed", "Unable to Add! try later");
        alert("Failed" + error);
      });
  };
  UpdateProductGroupInDB = () => {
    this.ShowLoadSpinner();
    var tmpProd = this.props.productGroup;
    tmpProd.name = this.state.productGroup.name;
    tmpProd.imgFileName = this.state.productGroup.imgFileName;
    tmpProd.seqNo = this.state.productGroup.seqNo;
    tmpProd.adsImgFileName = this.state.productGroup.adsImgFileName;
    tmpProd.hideMe = this.state.productGroup.hideMe;
    tmpProd.isCarouselType = this.state.productGroup.isCarouselType;

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmpProd),
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/UpdateProductGroup/" +
        this.props.productGroup.id,
      requestOptions
    )
      .then((res) => {
        if (res.status === 200) {
          this.HideLoadSpinner();
          this.ShowMessagBox("Information", "Updated Successfully");
        } else {
          this.HideLoadSpinner();
          this.ShowMessagBox("Failed", "Not Updated");
        }
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Failed", "Not Updated");
      });
  };
  handleSubmit = (event) => {
    event.preventDefault();

    if (this.props.Type == "Edit") {
      this.UpdateProductGroupInDB();
    }
    if (this.props.Type == "Add") this.AddProductGroupInDB();
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    //validate for minus values not to allow
    switch (name.trim()) {
      case "name":
        this.state.productGroup.name = value;
        break;
      case "imgFileName":
        this.state.productGroup.imgFileName = value;
        break;
      case "seqNo":
        this.state.productGroup.seqNo = value;
        break;
      case "hideMe":
        this.state.productGroup.hideMe = value;
        break;
      case "isCarouselType":
        this.state.productGroup.isCarouselType = value;
        break;
      case "adsImgFileName":
        this.state.productGroup.adsImgFileName = value;
        break;
    }
    this.setState({ productGroup: this.state.productGroup });
  };

  componentDidMount() {
    if (this.props.Type == "Edit") {
      this.setState({ productGroup: this.props.productGroup });
    }
  }

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          dialogClassName="ProductGroupModal"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit"
                  ? "Edit ProductGroup"
                  : "Add ProductGroup"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              {/* <Form.Label column>
                <h4 className="text-dark">ProductGroup Details</h4>
              </Form.Label> */}
              <Row
                className="m-2 p-2 bg-light border"
                style={{ fontSize: "9pt" }}
              >
                <Col md={6}>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Group Name
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="name"
                        value={this.state.productGroup.name}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Image FileName
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        styles={this.styles}
                        name="imgFileName"
                        value={this.state.productGroup.imgFileName}
                        // onChange={(e) => this.OnMainCategoryImgNameChanges(e)}
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value=""></option>
                        {this.context.images.map((r) => (
                          <option value={r.name}>{r.name}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Sequence No
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        name="seqNo"
                        min={0}
                        value={this.state.productGroup.seqNo}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Show Me
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        name="hideMe"
                        value={!this.state.productGroup.hideMe}
                        required
                        placeholder="select Sub Category"
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value="true">true</option>
                        <option value="false">false</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Carosel Type
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        name="isCarouselType"
                        value={this.state.productGroup.isCarouselType}
                        required
                        placeholder="select Sub Category"
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value="true">true</option>
                        <option value="false">false</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Ads Image FileName
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        styles={this.styles}
                        name="adsImgFileName"
                        value={this.state.productGroup.adsImgFileName}
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value=""></option>
                        {this.context.images.map((r) => (
                          <option value={r.name}>{r.name}</option>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                </Col>
                <Col xs={6} className="my-3">
                  <Form.Group controlId="Image" className="text-center">
                    <img
                      width="400px"
                      height="200px"
                      src={
                        this.context.store.storageBlobUrl +
                        this.context.store.storageBlobContainerName +
                        "/images/" +
                        this.state.productGroup.imgFileName +
                        this.context.store.storageSasToken
                      }
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="m-0">
                <Col md={12} className="text-start text-dark my-3">
                  <h4>Ads Image </h4>
                </Col>
              </Row>
              <Row className="m-0">
                <Col md={12} className="text-start text-dark my-3">
                  <img
                    width="100%"
                    height="150px"
                    src={
                      this.context.store.storageBlobUrl +
                      this.context.store.storageBlobContainerName +
                      "/images/" +
                      this.state.productGroup.adsImgFileName +
                      this.context.store.storageSasToken
                    }
                  />
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  Save
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
