import React, { Component, useState } from "react";
import { SideMenu } from "./SideMenu";
import CartContext from "./CartContext";
import { CategoryAddOrEditModal } from "./CategoryAddOrEditModal";
import { BsFillPencilFill } from "react-icons/bs";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import { InputGroup, Modal } from "react-bootstrap";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import Switch from "react-switch";
import DateTimePicker from "react-datetime-picker";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";

// import "../CSS/TimePicker.css";
// import "../CSS/DateTimePicker.css";
// import "../CSS/Calendar.css";
// import "../CSS/Clock.css";

import {
  Nav,
  Table,
  OverlayTrigger,
  Tooltip,
  Navbar,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
} from "react-bootstrap";

export class Category extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      addModalShow: false,
      reload: true,
      mainCategories: "",
      subCategories: "",
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
    };
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
  };

  OnRefreshButtonClick = () => {
    this.setState({ mainCategories: [] }, () => {
      this.setState({ subCategories: [] }, () => {
        this.setState({ mainCategories: this.context.mainCategories });
        this.setState({ subCategories: this.context.subCategories });
        this.setState({ reload: false }, () => {
          this.setState({ reload: true });
        });
      });
    });
  };

  OnDeleted = () => {
    // this.setState({ mainCategories: [] }, () => {
    //   this.setState({ mainCategories: this.context.mainCategories });
    // });
    // this.setState({ subCategories: this.context.subCategories }, () => {
    //   this.setState({ mainCategories: this.context.mainCategories });
    // });
  };

  OnUpdated = () => {
    // this.setState({ mainCategories: [] }, () => {
    //   this.setState({ mainCategories: this.context.mainCategories });
    // });
    // this.setState({ subCategories: this.context.subCategories }, () => {
    //   this.setState({ mainCategories: this.context.mainCategories });
    // });
  };

  componentDidMount() {
    this.setState({ mainCategories: this.context.mainCategories });
    this.setState({ subCategories: this.context.subCategories });
    window.scrollTo(0, 0);
  }
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  render() {
    return (
      <div className="Container-fluid p-1 ">
        <Row>
          <Col className="" xs={2}>
            <SideMenu></SideMenu>
          </Col>
          <Col xs={10} className="pagebackground p-0">
            {this.state.msgboxOpen === true ? (
              <MessageBox
                open={this.state.msgboxOpen}
                title={this.state.msgboxTitle}
                message={this.state.msgboxMessage}
                callback={this.CloseMessagBoxCallback}
              />
            ) : (
              ""
            )}

            <Navbar
              expand="lg"
              className="secondarythemecolor position-sticky fixed-top  px-3 py-1"
            >
              <h5>
                {" "}
                &nbsp; &nbsp;<b>CATEGORY MANAGEMENT</b> &nbsp;
              </h5>
              <Navbar.Collapse className="justify-content-end mx-0 ">
                <Nav>
                  <ButtonToolbar className="text-end align-middle">
                    <Button
                      className="mainthemecolor"
                      onClick={() => this.setState({ addModalShow: true })}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="26"
                        height="26"
                        fill="currentColor"
                        class="bi bi-plus"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                      </svg>
                      Add Category
                    </Button>
                    &nbsp;
                    <Button
                      className="mainthemecolor"
                      size=""
                      onClick={() => this.OnRefreshButtonClick()}
                    >
                      <BiRefresh size="26"></BiRefresh>
                      Refresh
                    </Button>
                    &nbsp;
                    {/* this logical check is required to deal with object destraction after close or submit the model */}
                    {this.state.addModalShow === true ? (
                      <CategoryAddOrEditModal
                        show={this.state.addModalShow}
                        onHide={this.addModalClose}
                        storeId={this.context.storeId}
                        ModalType="Add"
                      />
                    ) : null}
                  </ButtonToolbar>
                </Nav>
              </Navbar.Collapse>
            </Navbar>
            <div className="Container-fluid mx-0 py-0 ">
              <Table className="">
                <thead className="">
                  <tr className="text-center text-dark bg-light">
                    <th className="border py-0 ">
                      <h5>MAIN CATEGORY</h5>
                    </th>

                    <th className="border">
                      <h5>SUB CATEGORY</h5>
                    </th>
                    <th className="border">
                      <h5>OPTIONS</h5>
                    </th>
                  </tr>
                </thead>
                <tbody className="text-primary "></tbody>
                {this.state.mainCategories !== ""
                  ? this.state.mainCategories.map((maincategory, i) =>
                      this.state.reload === true ? (
                        <EditCategoryInline
                          index={i}
                          maincategory={maincategory}
                          storeId={this.context.storeId}
                          subCategories={this.state.subCategories.filter(
                            (e) => e.mainCategoryId == maincategory.id
                          )}
                          OnDeleted={this.OnDeleted}
                          OnUpdated={this.OnUpdated}
                        ></EditCategoryInline>
                      ) : (
                        ""
                      )
                    )
                  : ""}
              </Table>
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

export class EditCategoryInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      maincategory: this.props.maincategory,
      // name: this.props.maincategory.name,
      // description: this.props.maincategory.description,
      // hideMe: this.props.maincategory.hideMe,
      // seqNo: this.props.maincategory.seqNo,
      // imgFileName: this.props.maincategory.imgFileName,
      subCategories: this.props.subCategories,
      addModalShow: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      startTime: "10:00",
      endTime: "10:00",
    };
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "name":
        this.state.maincategory.name = value;
        break;
      case "description":
        this.state.maincategory.description = value;
        break;
      case "hideMe":
        this.state.maincategory.hideMe = value;
        break;
      case "seqNo":
        this.state.maincategory.seqNo = value;
        break;
    }
    this.setState({ maincategory: this.state.maincategory });
  };

  // OnNameChange = (event) => {
  //   this.setState({ name: event.target.value });
  // };
  // OnDescriptionChange = (event) => {
  //   this.setState({ description: event.target.value });
  // };
  // OnHideMeOnPortalChange = (event) => {
  //   this.setState({ hideMe: event.target.value });
  // };
  // OnSeqNoChange = (event) => {
  //   this.setState({ seqNo: event.target.value });
  // };

  // OnMainCategoryImgNameChanges = (e) => {
  //   this.setState({ imgFileName: e.value });
  // };

  handleSubCategoryControlChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...this.state.subCategories];

    list[index][name] = value;

    this.setState({ subCategories: list });
  };
  OnEditButtonClick = (e) => {
    this.setState({ addModalShow: true }, () => {});
  };
  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };
  handleRemoveSubCategoryClick = (i) => {
    const list = [...this.state.subCategories];
    list.splice(i, 1);
    this.setState({ subCategories: list });
  };
  handleSubCategoryDeleteClick = (e, i) => {
    const list = [...this.state.subCategories];
    list.splice(i, 1);
    this.setState({ subCategories: list });

    this.ShowMessagBox(
      "Status",
      "This action was a Soft Delete! Next you have to Click `Save` to delete it from System."
    );
  };

  OnDeleteMainCategory = () => {
    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteMainCategory/" +
        this.props.maincategory.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert("not deleted");
          return;
        }
        this.context.getMainCategories();
        this.context.getSubCategories();
        this.context.GetAllProducts();
        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Status",
          "Deleted Successfully. Click Refresh Button to see the changes!"
        );
        this.props.OnDeleted();
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("OnDelete Failed" + error);
      });
  };

  OnSave = () => {
    this.ShowLoadSpinner();
    // var tmpMainCategory = this.props.maincategory;
    // tmpMainCategory.name = this.state.name;
    // tmpMainCategory.hideMe = this.state.hideMe;
    // tmpMainCategory.seqNo = this.state.seqNo;
    this.state.maincategory.subCategories = this.state.subCategories;

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.maincategory),
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/UpdateMainCategory/" +
        this.state.maincategory.id,
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.context.getMainCategories();
          this.context.getSubCategories();
          this.context.GetAllProducts();
          this.HideLoadSpinner();
          console.log("Updated Successfully");
          this.ShowMessagBox(
            "Status",
            "Updated Successfully. Click Refresh Button to see the changes!"
          );
          this.props.OnUpdated();
          return;
        }
        this.HideLoadSpinner();
        alert("not updated");
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("Failed" + error);
      });
  };

  handleMainCategoryHidemeChange = (checked) => {
    this.state.maincategory.hideMe = checked == false ? true : false;
    this.setState({ maincategory: this.state.maincategory });
    console.log("hi");
  };
  handleSubCategoryHidemeChange = (e, index) => {
    const list = [...this.state.subCategories];

    list[index]["hideMe"] = !e;

    this.setState({ subCategories: list });
  };

  render() {
    return (
      <>
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <tr className="align-top text-secondary text-center bg-white">
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          <td className="col-md-5 border-0 p-3">
            <Table className=" my-3 text-start bg-light ">
              <thead className="bg-light text-secondary text-center   ">
                <tr>
                  <td width="5%">#</td>
                  <td width="40%">Name</td>
                  <td width="10%">Image</td>
                  <td width="20%">Show Me</td>
                  <td width="15%">Seq.No</td>
                </tr>
              </thead>
              <tbody size="" className="border">
                <tr style={{ fontSize: "12px" }}>
                  <td className="align-middle">{this.props.index + 1}</td>
                  <td className="align-middle">
                    <Form.Control
                      type="text"
                      name="name"
                      value={this.state.maincategory.name}
                      required
                      className="text-start align-middle bg-white"
                      placeholder=""
                      autoComplete="off"
                      onChange={(e) => this.handleInputChange(e)}
                    />
                  </td>
                  <td>
                    <img
                      width="70px"
                      height="60px"
                      src={
                        this.context.store.storageBlobUrl +
                        this.context.store.storageBlobContainerName +
                        "/images/" +
                        this.state.maincategory.imgFileName +
                        this.context.store.storageSasToken
                      }
                      // className="prd-img mx-auto"
                      alt="..."
                    />
                  </td>
                  <td className="align-middle ">
                    {" "}
                    {/* <Form.Select
                      type="text"
                      name="hideMe"
                      
                      value={this.state.maincategory.hideMe}
                      required
                      placeholder="select Sub Category"
                      onChange={(e) => this.handleInputChange(e)}
                      className="align-middle "
                    >
                      <option value="true">true</option>
                      <option value="false">false</option>
                    </Form.Select> */}
                    <div className="text-center">
                      <Switch
                        height={20}
                        onChange={this.handleMainCategoryHidemeChange}
                        checked={!this.state.maincategory.hideMe}
                      />
                    </div>
                    Open:
                    <TimePicker
                      amPmAriaLabel="Select AM/PM"
                      format="h:mm a"
                      onChange={(t) => {
                        this.state.maincategory.visibleStartTime = t;
                        this.setState({
                          maincategory: this.state.maincategory,
                        });
                      }}
                      value={
                        this.state.maincategory.visibleStartTime == undefined
                          ? "0:0"
                          : this.state.maincategory.visibleStartTime
                      }
                    />
                    <br />
                    Close:
                    <TimePicker
                      amPmAriaLabel="Select AM/PM"
                      format="h:mm a"
                      onChange={(t) => {
                        this.state.maincategory.visibleEndTime = t;
                        this.setState({
                          maincategory: this.state.maincategory,
                        });
                      }}
                      value={
                        this.state.maincategory.visibleEndTime == undefined
                          ? "23:59"
                          : this.state.maincategory.visibleEndTime
                      }
                    />
                  </td>
                  <td className="align-middle">
                    <Form.Control
                      type="number"
                      min={0}
                      name="seqNo"
                      value={this.state.maincategory.seqNo}
                      required
                      className="text-center align-middle bg-white"
                      placeholder=""
                      autoComplete="off"
                      onChange={(e) => this.handleInputChange(e)}
                    />
                  </td>
                </tr>
              </tbody>
            </Table>
          </td>

          <td className="col-md-6 p-3">
            <Table className="my-3 text-start  bg-light ">
              <thead className="bg-light text-secondary text-start ">
                <tr style={{ fontSize: "12px" }}>
                  <th>#</th>
                  <th>Name</th>
                  <th>Image</th>
                  <th>Show Me</th>
                  <th>SeqNo</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="border">
                {this.state.subCategories.map((subcategory, i) => (
                  <tr className="text-start" style={{ fontSize: "12px" }}>
                    <td width="5%" className="text-start align-middle">
                      {i + 1}
                    </td>
                    <td width="35%" className="align-middle ">
                      <Form.Control
                        type="text"
                        name="name"
                        value={subcategory.name}
                        required
                        className="text-start"
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) =>
                          this.handleSubCategoryControlChange(e, i)
                        }
                      />
                    </td>
                    <td width="15%" className="align-middle ">
                      <img
                        width="80px"
                        height="50px"
                        className="text-start"
                        src={
                          this.context.store.storageBlobUrl +
                          this.context.store.storageBlobContainerName +
                          "/images/" +
                          subcategory.imgFileName +
                          this.context.store.storageSasToken
                        }
                        // className="prd-img mx-auto"
                        alt="..."
                      />
                    </td>
                    <td width="20%" className="align-middle ">
                      {/* <Form.Select
                        type="text"
                        name="hideMe"
                        value={subcategory.hideMe}
                        required
                        className="text-start"
                        placeholder=""
                        onChange={(e) =>
                          this.handleSubCategoryControlChange(e, i)
                        }
                      >
                        <option value="true">true</option>
                        <option value="false">false</option>
                      </Form.Select> */}
                      <Switch
                        height={20}
                        onChange={(e) =>
                          this.handleSubCategoryHidemeChange(e, i)
                        }
                        checked={!subcategory.hideMe}
                      />
                    </td>
                    <td width="15%" className="align-middle">
                      <Form.Control
                        type="number"
                        min={0}
                        name="seqNo"
                        value={subcategory.seqNo}
                        required
                        size=""
                        className="text-start "
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) =>
                          this.handleSubCategoryControlChange(e, i)
                        }
                      ></Form.Control>
                    </td>
                    <td width="10%" className="align-middle ">
                      <OverlayTrigger
                        overlay={
                          <Tooltip>
                            Warning! This will Delete all products associated to
                            this sub-category as well
                          </Tooltip>
                        }
                      >
                        <Button
                          variant="outline-danger"
                          onClick={(e) => {
                            if (
                              window.confirm(
                                "Warning! This action will delete this Sub-Category and associated products. Are you sure you want to Delete? "
                              )
                            ) {
                              this.handleSubCategoryDeleteClick(e, i);
                            }
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="16"
                            fill="currentColor"
                            class="bi bi-trash"
                            viewBox="0 0 16 16"
                          >
                            <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                            <path
                              fill-rule="evenodd"
                              d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                            />
                          </svg>
                        </Button>
                      </OverlayTrigger>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </td>
          <td className="col-md-1 text-end align-middle ">
            <div class="btn-group">
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    Save! Click this button after editing any fields on this
                    Row.{" "}
                  </Tooltip>
                }
              >
                <Button
                  className="mx-1 mainthemecolor bg-success"
                  size="lg"
                  onClick={(e) => {
                    this.OnSave(e);
                  }}
                >
                  <BiSave size="25" />
                </Button>
              </OverlayTrigger>
              {this.state.addModalShow === true ? (
                <CategoryAddOrEditModal
                  show={this.state.addModalShow}
                  onHide={this.addModalClose}
                  storeId={this.context.storeId}
                  ModalType="Edit"
                  maincategory={this.props.maincategory}
                  subCategories={this.props.subCategories}
                />
              ) : null}
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    Edit! Click this button if you wanted advanced edit
                    operation on this row in a Modal dialog window.{" "}
                  </Tooltip>
                }
              >
                <Button
                  className="mx-1 bg-warning"
                  size="lg"
                  onClick={(e) => {
                    this.OnEditButtonClick(e);
                  }}
                >
                  <BsFillPencilFill size="25" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                overlay={
                  <Tooltip>
                    Warning! This will delete this Main-Category, associated
                    Sub-categories and products.
                  </Tooltip>
                }
              >
                <Button
                  className="mx-1  bg-danger"
                  size="sm"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        "Warning! This action will delete this Main-category, subcategories and associated products. Are you sure you want to Delete?"
                      )
                    ) {
                      this.OnDeleteMainCategory(e);
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="22"
                    height="22"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>
            </div>
          </td>
        </tr>
      </>
    );
  }
}
