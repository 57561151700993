import React, { Component } from "react";
import { useState } from "react";
import Swal from "sweetalert2";
import { SideMenu } from "./SideMenu";
import CartContext from "./CartContext";
import { BsFillPencilFill } from "react-icons/bs";
import { FaTrashAlt } from "react-icons/fa";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import Fuse from "fuse.js";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import Switch from "react-switch";
import axios from "axios";
import { useZxing } from "react-zxing";
// import "../CSS/3d.css";
import { IoMdQrScanner } from "react-icons/io";

import {
  Nav,
  Stack,
  InputGroup,
  FormControl,
  Table,
  OverlayTrigger,
  Tooltip,
  Navbar,
  Modal,
  ListGroup,
} from "react-bootstrap";
import { Button, ButtonToolbar, Form, Row, Col } from "react-bootstrap";

import { ProductAddOrEditModal } from "./ProductAddOrEditModal";

const loader = document.querySelector(".loader");
const WAIT_INTERVAL = 1000;
export class ProductsListView extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      prods: [],
      addModalShow: false,
      editModalShow: false,
      selectedMainCategoryId: "0",
      selectedMainCategoryName: "All",
      selectedSubCategoryId: "0",
      selectedSubCategoryName: "All",
      selectedBrandId: "0",
      selectedHideMe: "All",
      filteredProducts: [],
      filteredProductsCopy: [],
      filteredOnHideProductsCopy: [],
      searchData: [],
      searchquery: "",
      reload: true,
      loading: true,
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      firstLoad: false,
      showResultsLimit: "25",
    };
    this.inputFile = React.createRef();
  }

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  onFilteredProductsChanged = () => {
    this.setState(
      {
        filteredProductsCopy: this.state.filteredProducts,
      },
      () => {
        this.setState({ reload: false }, () => {
          this.setState({ reload: true }, () => {
            this.HideLoadSpinner();
          });
        });
        console.log("products reloaded");
      }
    );
  };

  OnShowResultsLimitChanges = (e) => {
    if (e.target.value == "All")
      this.setState({ showResultsLimit: this.state.filteredProducts.length });
    else this.setState({ showResultsLimit: Number(e.target.value) });
  };
  onMainCategorySelectChangeEvent = (e) => {
    this.startLoading();
    this.resetOnHideFilter();

    this.setState({ reload: false });
    this.setState({ selectedMainCategoryId: e.target.value }, () => {
      this.setState({ selectedSubCategoryId: "0" }, () =>
        this.OnSubCategoryIDChanges()
      );
    });
  };
  OnSubCategoryIDChanges = () => {
    this.onCategoryChanges();
  };
  onSubCategorySelectChangeEvent = (e) => {
    this.startLoading();
    this.resetOnHideFilter();

    this.setState({ reload: false });
    this.setState({ selectedSubCategoryId: e.target.value }, () =>
      this.onCategoryChanges()
    );
  };

  resetOnHideFilter = () => {
    this.setState({ selectedHideMe: "All" });
    this.setState({ filteredOnHideProductsCopy: [] });
  };

  onBrandSelectChangeEvent = (e) => {
    this.startLoading();
    this.resetOnHideFilter();

    this.setState({ selectedBrandId: e.target.value }, () => {
      this.setState({ selectedMainCategoryId: "0" }, () => {
        this.setState({ selectedSubCategoryId: "0" }, () => {
          if (this.state.selectedBrandId == 0) {
            this.setState(
              {
                selectedMainCategoryId:
                  this.context.mainCategories.length > 0
                    ? this.context.mainCategories[0].id
                    : "0",
              },
              () => {
                this.onCategoryChanges();
              }
            );
          } else {
            this.setState(
              {
                filteredProducts: [
                  ...this.context.prods.filter(
                    (f) => f.brandId == this.state.selectedBrandId
                  ),
                ], //deep clone
              },
              () => this.onFilteredProductsChanged()
            );
          }
        });
      });
    });
  };
  onHideMeSelectChangeEvent = (e) => {
    // this.ShowLoadSpinner();
    this.startLoading();

    this.setState({ selectedHideMe: e.target.value }, () => {
      if (this.state.selectedHideMe == "All") {
        this.setState(
          {
            selectedMainCategoryId:
              this.context.mainCategories.length > 0
                ? this.context.mainCategories[0].id
                : "0",
          },
          () => {
            this.onCategoryChanges();
          }
        );
      } else {
        if (this.state.filteredOnHideProductsCopy.length == 0) {
          this.setState(
            { filteredOnHideProductsCopy: this.state.filteredProducts },
            () => {
              this.setState(
                {
                  filteredProducts: [
                    ...this.state.filteredOnHideProductsCopy.filter(
                      (f) => String(f.hideMe) == this.state.selectedHideMe
                    ),
                  ], //deep clone
                },
                () => this.onFilteredProductsChanged()
              );
            }
          );
        } else {
          this.setState(
            {
              filteredProducts: [
                ...this.state.filteredOnHideProductsCopy.filter(
                  (f) => String(f.hideMe) == this.state.selectedHideMe
                ),
              ], //deep clone
            },
            () => this.onFilteredProductsChanged()
          );
        }
      }
    });
  };

  OnDeleted = (indexno) => {
    this.setState({ reload: false });
    const list = [...this.state.filteredProducts];
    list.splice(indexno, 1);
    this.setState({ filteredProducts: list }, () => {
      this.setState({ reload: true }, () => {
        this.ShowMessagBox("Information", "Deleted Successfully!");
      });
    });
  };

  OnUpdated = (indexno) => {
    this.setState({ reload: true }, () => this.setState({ reload: true }));
  };

  fakeRequestRefresh = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 10000));
  };

  OnRefreshButtonClick = () => {
    this.ShowLoadSpinner();
    this.context.GetAllProducts();
    this.fakeRequestRefresh().then(() => {
      this.onCategoryChanges();
      this.HideLoadSpinner();
    });
  };
  useForceUpdate = () => {
    const [value, setValue] = useState(0); // integer state
    return () =>
      setValue(
        (value) => value + 1,
        () => {}
      ); // update the state to force render
  };
  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
  };

  onSearchChange = (query) => {
    this.setState({ searchquery: query }, (r) => {
      if (this.state.searchquery.length > 0) {
        clearTimeout(this.timer);
        this.timer = setTimeout(this.triggerChange, WAIT_INTERVAL);
      }
    });
    if (query.length <= 0) {
      this.setState({ searchData: [] });
      this.setState(
        {
          filteredProducts: JSON.parse(
            JSON.stringify([...this.state.filteredProductsCopy])
          ),
        },
        () => {
          this.setState({ reload: true });
        }
      );
      return;
    }
  };

  triggerChange = () => {
    if (false == this.ShowSearchResults()) {
    }
  };

  ShowSearchResults = () => {
    this.setState({ reload: false });
    if (!this.state.searchquery) {
      this.setState(
        {
          filteredProducts: JSON.parse(
            JSON.stringify([...this.state.filteredProductsCopy])
          ),
        },
        () => {
          this.setState({ reload: true });
        }
      );
      return;
    }
    // const fuse = new Fuse(this.state.filteredProducts, {
    const fuse = new Fuse(this.state.filteredProductsCopy, {
      keys: ["name", "sku"],
      threshold: 0.2,
      ignoreLocation: true,
      findAllMatches: false,
      distance: 1,
    });
    const result = fuse.search(this.state.searchquery);
    const finalResult = [];
    if (result.length) {
      result.forEach((item) => {
        finalResult.push(item.item);
      });
      this.setState({ filteredProducts: finalResult.slice(0, 25) }, () => {
        this.setState({ reload: true });
      });
    } else {
      this.setState({ filteredProducts: [] });
    }
  };

  onCategoryChanges = () => {
    //when main and sub are "All"
    if (
      this.state.selectedMainCategoryId === "0" &&
      this.state.selectedSubCategoryId === "0"
    ) {
      this.setState(
        {
          filteredProducts: JSON.parse(JSON.stringify([...this.context.prods])), //deep clone
        },
        () => this.onFilteredProductsChanged()
      );
      // this.setState({ filteredProducts: [] }, () => {

      // });
    }
    //when Main is not "ALL" but sub is "All"
    else if (
      this.state.selectedMainCategoryId !== "0" &&
      this.state.selectedSubCategoryId === "0"
    ) {
      this.setState(
        {
          filteredProducts: JSON.parse(
            JSON.stringify(
              this.context.prods
                .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                .filter((prod) => {
                  return (
                    prod.subCategory.mainCategoryId ===
                    this.state.selectedMainCategoryId
                  );
                })
            )
          ),
        },
        () => this.onFilteredProductsChanged()
      );
    }
    //when both Main and Sub are not "All"
    else {
      this.setState(
        {
          filteredProducts: JSON.parse(
            JSON.stringify(
              this.context.prods
                .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                .filter((prod) => {
                  return (
                    prod.subCategory.mainCategoryId ===
                      this.state.selectedMainCategoryId &&
                    prod.subCategory.id === this.state.selectedSubCategoryId
                  );
                })
            )
          ),
        },
        () => this.onFilteredProductsChanged()
      );
    }
  };

  fakeRequest = () => {
    // var waittime = (this.context.prods.length / 100) * 1000;
    return new Promise((resolve) => setTimeout(() => resolve(), 1000));
  };

  componentDidMount() {
    if (this.state.firstLoad == false) {
      this.state.firstLoad = true;
      this.setState(
        {
          selectedMainCategoryId:
            this.context.mainCategories.length > 0
              ? this.context.mainCategories[0].id
              : "0",
        },
        () => {
          this.fakeRequest().then(() => {
            this.setState({ loading: false }); // showing the app
          });
          this.onCategoryChanges();
        }
      );
    }
  }

  fakeRequestForLoading = () => {
    // var waittime = (this.context.prods.length / 100) * 1000;
    return new Promise((resolve) => setTimeout(() => resolve(), 3000));
  };
  startLoading = () => {
    this.setState({ loading: true }, () => {
      this.fakeRequestForLoading().then(() => {
        this.setState({ loading: false }); // showing the app
      });
    });
  };

  DownloadProductsToCSV = () => {
    this.ShowLoadSpinner();
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "StoreManager/DownloadProductsToCSV/" +
          this.context.storeId
      )
      .then((response) => {
        if (response.status === 200) {
          this.HideLoadSpinner();
          const element = document.createElement("a");
          const file = new Blob([response.data], {
            type: "text/plain",
          });
          element.href = URL.createObjectURL(file);
          element.download =
            "products-" +
            new Date().getDate() +
            (new Date().getMonth() + 1) +
            new Date().getFullYear() +
            "-" +
            new Date().getMinutes() +
            new Date().getSeconds() +
            ".csv";
          document.body.appendChild(element); // Required for this to work in FireFox
          element.click();
        } else {
          this.HideLoadSpinner();
          this.ShowMessagBox("Error", "Download Failed. Please try later!");
        }
      })
      .catch((error) => {
        this.HideLoadSpinner();
        console.error("DownloadProductsToCSV - There was an error! ", error);
      });
  };

  onUploadButtonClick = () => {
    this.inputFile.current.click();
  };

  handleFileUpload = (e) => {
    this.ShowLoadSpinner();
    const { files } = e.target;

    const reader = new FileReader();
    reader.onload = (e) => {
      const text = e.target.result;
      this.UploadProductsFromCSV(text);
    };
    reader.readAsText(files[0]);
  };

  UploadProductsFromCSV = (csvstr) => {
    this.ShowLoadSpinner();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(csvstr),
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/UploadProductsFromCsv/" +
        this.context.storeId,
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 404) {
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Information",
          "Uploaded Successfully. Please Refresh(f5) the Portal! \r\n" +
            "\r\n" +
            data
        );
      })
      .catch((error) => {
        this.HideLoadSpinner();
        // this.ShowMessagBox("Failed", "Unable to Add! try later");
        alert("Failed" + error);
      });
  };

  componentDidUpdate() {}

  render() {
    if (this.state.loading === true) {
      return (
        <>
          <LoadSpinner open="true"></LoadSpinner>
        </>
      );
    }

    const {} = this.state;
    return (
      <div className="Container-fluid mx-3 p-0 pagebackground">
        {this.state.msgboxOpen === true ? (
          <MessageBox
            open={this.state.msgboxOpen}
            title={this.state.msgboxTitle}
            message={this.state.msgboxMessage}
            callback={this.CloseMessagBoxCallback}
          />
        ) : (
          ""
        )}

        <input
          style={{ display: "none" }}
          accept=".csv"
          ref={this.inputFile}
          onChange={this.handleFileUpload}
          type="file"
          multiple="single"
        />

        <Row>
          {window.innerWidth >= 1200 ? (
            <>
              <Col className="" xs={2}>
                <SideMenu></SideMenu>
              </Col>
            </>
          ) : (
            ""
          )}
          <Col
            xs={window.innerWidth >= 1200 ? 10 : 12}
            className="pagebackground my-1  m-0 p-0"
          >
            <Navbar className="secondarythemecolor position-sticky fixed-top   ">
              <h5>
                {" "}
                &nbsp;{" "}
                <b>
                  {window.innerWidth >= 1200
                    ? "PRODUCT MANAGEMENT"
                    : "PRD Management"}
                </b>
              </h5>
              <Navbar.Collapse className="justify-content-end mx-1 ">
                {window.innerWidth >= 1200 ? (
                  <>
                    <Nav>
                      <ButtonToolbar className="text-end align-middle">
                        <Button
                          className="mainthemecolor buttonRounded"
                          onClick={() => this.setState({ addModalShow: true })}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-plus"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                          </svg>
                          Add
                        </Button>
                        &nbsp;
                        <Button
                          className="mainthemecolor buttonRounded"
                          onClick={() => this.OnRefreshButtonClick()}
                        >
                          <BiRefresh
                            size="20"
                            className="mainthemecolor"
                          ></BiRefresh>
                          Refresh
                        </Button>
                        {this.context.UserRoles.PRODUCTADMIN ===
                        this.context.userData.roleId ? (
                          <>
                            &nbsp;
                            <Button
                              className="mainthemecolor buttonRounded"
                              // onClick={() => this.setState({ addModalShow: true })}
                              onClick={(e) => this.onUploadButtonClick()}
                            >
                              <BiUpload
                                size="20"
                                className="mainthemecolor"
                              ></BiUpload>
                              Upload from CSV
                            </Button>
                          </>
                        ) : (
                          ""
                        )}
                        &nbsp;
                        <Button
                          className="mainthemecolor buttonRounded"
                          size=""
                          onClick={(e) => this.DownloadProductsToCSV()}
                        >
                          <BiDownload size="20"></BiDownload>
                          Download as CSV
                        </Button>
                        &nbsp;
                        {/* this logical check is required to deal with object destraction after close or submit the model */}
                        {this.state.addModalShow === true ? (
                          <ProductAddOrEditModal
                            show={this.state.addModalShow}
                            Type="Add"
                            onHide={this.addModalClose}
                          />
                        ) : null}
                      </ButtonToolbar>
                    </Nav>
                  </>
                ) : (
                  <>
                    {this.state.addModalShow === true ? (
                      <ProductAddOrEditModal
                        show={this.state.addModalShow}
                        Type="Add"
                        onHide={this.addModalClose}
                      />
                    ) : null}
                    <Nav>
                      <ButtonToolbar className="text-end align-middle">
                        <Button
                          className="mainthemecolor buttonRounded"
                          onClick={() => this.setState({ addModalShow: true })}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            fill="currentColor"
                            class="bi bi-plus"
                            viewBox="0 0 16 16"
                          >
                            <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                          </svg>
                          Add
                        </Button>
                        <Button
                          className="mainthemecolor buttonRounded"
                          onClick={() => this.OnRefreshButtonClick()}
                        >
                          <BiRefresh
                            size="20"
                            className="mainthemecolor"
                          ></BiRefresh>
                          Refresh
                        </Button>
                      </ButtonToolbar>
                    </Nav>
                  </>
                )}
              </Navbar.Collapse>
            </Navbar>
            <Row>
              {window.innerWidth >= 1200 ? (
                <>
                  <Table>
                    <tr>
                      <td
                        className=" py-1 border"
                        width="10%"
                        style={{ verticalAlign: "top" }}
                      >
                        <div className=" ">
                          <div className="my-2 p-1 ">
                            <Table className=" my-0 py-0 ">
                              <Row>
                                <Col className="text-secondary bg-white">
                                  <div className="py-2  text-center text-dark">
                                    <h4>Filters</h4>
                                  </div>
                                  <br></br>

                                  {/* <div className="px-0 py-4 mx-0 my-0 border"> */}
                                  <h6 className="py-2">
                                    <b>MainCategory</b>
                                  </h6>
                                  <Form.Select
                                    size=""
                                    value={this.state.selectedMainCategoryId}
                                    disabled={
                                      this.state.selectedBrandId == 0
                                        ? false
                                        : true
                                    }
                                    onChange={(e) => {
                                      this.onMainCategorySelectChangeEvent(e);
                                    }}
                                  >
                                    <option value={0}>ALL</option>
                                    {this.context.mainCategories.map(
                                      (maincategory) => (
                                        <option value={maincategory.id}>
                                          {maincategory.name}
                                        </option>
                                      )
                                    )}
                                  </Form.Select>
                                  <br></br>
                                  <h6 className="py-2">
                                    <b>SubCategory</b>
                                  </h6>
                                  <Form.Select
                                    value={this.state.selectedSubCategoryId}
                                    disabled={
                                      this.state.selectedBrandId == 0
                                        ? false
                                        : true
                                    }
                                    onChange={(e) => {
                                      this.onSubCategorySelectChangeEvent(e);
                                    }}
                                  >
                                    <option value={0}>All</option>
                                    {this.context.subCategories
                                      .filter(
                                        (subcategory) =>
                                          subcategory.mainCategoryId ===
                                          this.state.selectedMainCategoryId
                                      )
                                      .map((subcategory) => (
                                        <option value={subcategory.id}>
                                          {subcategory.name}
                                        </option>
                                      ))}
                                  </Form.Select>
                                  <br></br>
                                  <h6 className="py-2">
                                    <b>Brand</b>
                                  </h6>
                                  <Form.Select
                                    size=""
                                    value={this.state.selectedBrandId}
                                    onChange={(e) => {
                                      this.onBrandSelectChangeEvent(e);
                                    }}
                                  >
                                    <option value={0}>All</option>
                                    {this.context.brands
                                      .sort((a, b) =>
                                        a.brandName > b.brandName ? 1 : -1
                                      )
                                      .map((brand) => (
                                        <option value={brand.id}>
                                          {brand.brandName}
                                        </option>
                                      ))}
                                  </Form.Select>
                                  <br></br>
                                  <h6 className="py-2">
                                    <b>Show-Me</b>
                                  </h6>
                                  <Form.Select
                                    size=""
                                    value={this.state.selectedHideMe}
                                    onChange={(e) => {
                                      this.onHideMeSelectChangeEvent(e);
                                    }}
                                  >
                                    <option value="All">All</option>
                                    <option value={false}>True</option>
                                    <option value={true}>False</option>
                                  </Form.Select>
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                  <br />
                                </Col>
                              </Row>
                            </Table>
                          </div>
                        </div>
                      </td>
                      <td
                        className=" py-1 border"
                        // width="10%"
                        style={{ verticalAlign: "top" }}
                      >
                        <div className=" ">
                          <Row className="w-100 border-0 py-2 ">
                            <Col md={9} className="border-0">
                              <Stack
                                direction="horizontal"
                                gap={3}
                                className=""
                              >
                                <b>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    class="bi bi-search"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                  </svg>
                                </b>
                                <FormControl
                                  type="search"
                                  placeholder="Search... "
                                  className="text-dark fw-bold bg-white "
                                  size="lg"
                                  aria-label="Search"
                                  onChange={(e) =>
                                    this.onSearchChange(e.target.value)
                                  }
                                />
                              </Stack>
                            </Col>
                            <Col md={3} className="border-0 p-0 m-0">
                              <Stack direction="horizontal">
                                <Form.Label>
                                  &nbsp;&nbsp;&nbsp;Show Results:
                                </Form.Label>

                                <Form.Select
                                  className="text-center"
                                  onChange={(e) => {
                                    this.OnShowResultsLimitChanges(e);
                                  }}
                                >
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                  <option value="500">500</option>
                                  <option value="All">All</option>
                                </Form.Select>
                              </Stack>
                            </Col>
                          </Row>

                          {/* <h4>shows top 100 results</h4> */}
                          <Table border striped className="px-0 bg-white ">
                            <thead className="">
                              <tr
                                className="text-center text-black bg-white border"
                                style={{ fontSize: "12px" }}
                              >
                                <th width="1%" className="border"></th>
                                <th width="5%" className="border"></th>
                                <th className="text-start border" width="31%">
                                  Name & SKU
                                </th>
                                <th width="8%" className="border">
                                  Seq.No
                                </th>
                                <th width="10%" className="border">
                                  Show-Me
                                </th>
                                <th width="13%" className="border">
                                  MRP (
                                  {this.context.storeSettings.defaultCurrency}),
                                  & Sell.Price
                                </th>

                                {/* <th width="5%" className="border">
                                  Take Away Charge(%)
                                </th>
                                <th width="5%" className="border">
                                  Home Delivery Charge(%)
                                </th> */}
                                {/* <th width="5%" className="border">
                                  Disc (%)
                                </th> */}
                                <th width="7%" className="border">
                                  In-Stock Qty
                                </th>
                                <th width="5%" className="border">
                                  Options
                                </th>
                                {/* <th width="5%" className="text-center border">
                                  Options
                                </th> */}
                              </tr>
                            </thead>
                            <tbody className="text-primary border">
                              {this.state.filteredProducts
                                .slice(0, this.state.showResultsLimit)
                                // .slice(0, 100)
                                //.sort((a, b) => (a.name > b.name ? 1 : -1))
                                .map((prod, i) =>
                                  this.state.reload === true ? (
                                    <EditProductInline
                                      index={i}
                                      prod={prod}
                                      OnDeleted={this.OnDeleted}
                                      OnUpdated={this.OnUpdated}
                                    ></EditProductInline>
                                  ) : null
                                )}
                            </tbody>
                          </Table>
                          {this.state.loadSpinnerOpen === true ? (
                            <LoadSpinner open="true"></LoadSpinner>
                          ) : (
                            ""
                          )}
                        </div>
                      </td>
                    </tr>
                  </Table>
                  {/* <Col className=" py-1 border" xs={2}>                    
                  </Col>
                  <Col xs={10} className=" py-1">                    
                  </Col> */}
                </>
              ) : (
                <>
                  <Col xs={12} className=" py-1">
                    <div className=" ">
                      <div className=" ">
                        <div className="my-2 p-1 ">
                          <div className="text-center ">
                            <h3>Filters</h3>{" "}
                          </div>
                          <Table
                            className=" my-0 py-0  w-100 bg-light"
                            size="sm"
                            bordered
                          >
                            <tbody>
                              <tr width="100% border-0">
                                <td width="40%">
                                  <h6 className="py-2">
                                    <b>MainCategory</b>
                                  </h6>
                                </td>
                                <td width="60%">
                                  <Form.Select
                                    size=""
                                    value={this.state.selectedMainCategoryId}
                                    disabled={
                                      this.state.selectedBrandId == 0
                                        ? false
                                        : true
                                    }
                                    onChange={(e) => {
                                      this.onMainCategorySelectChangeEvent(e);
                                    }}
                                  >
                                    <option value={0}>ALL</option>
                                    {this.context.mainCategories.map(
                                      (maincategory) => (
                                        <option value={maincategory.id}>
                                          {maincategory.name}
                                        </option>
                                      )
                                    )}
                                  </Form.Select>
                                </td>
                              </tr>

                              <tr width="100% border-0">
                                <td width="40%">
                                  <h6 className="py-2">
                                    <b>SubCategory</b>
                                  </h6>
                                </td>
                                <td width="60%">
                                  <Form.Select
                                    value={this.state.selectedSubCategoryId}
                                    disabled={
                                      this.state.selectedBrandId == 0
                                        ? false
                                        : true
                                    }
                                    onChange={(e) => {
                                      this.onSubCategorySelectChangeEvent(e);
                                    }}
                                  >
                                    <option value={0}>All</option>
                                    {this.context.subCategories
                                      .filter(
                                        (subcategory) =>
                                          subcategory.mainCategoryId ===
                                          this.state.selectedMainCategoryId
                                      )
                                      .map((subcategory) => (
                                        <option value={subcategory.id}>
                                          {subcategory.name}
                                        </option>
                                      ))}
                                  </Form.Select>
                                </td>
                              </tr>
                              {this.context.brands.length > 0 ? (
                                <>
                                  <tr width="100%">
                                    <td width="40%">
                                      <h6 className="py-2">
                                        <b>Brand</b>
                                      </h6>
                                    </td>
                                    <td width="60%">
                                      <Form.Select
                                        size=""
                                        value={this.state.selectedBrandId}
                                        onChange={(e) => {
                                          this.onBrandSelectChangeEvent(e);
                                        }}
                                      >
                                        <option value={0}>All</option>
                                        {this.context.brands
                                          .sort((a, b) =>
                                            a.brandName > b.brandName ? 1 : -1
                                          )
                                          .map((brand) => (
                                            <option value={brand.id}>
                                              {brand.brandName}
                                            </option>
                                          ))}
                                      </Form.Select>
                                    </td>
                                  </tr>
                                </>
                              ) : (
                                <></>
                              )}

                              <tr width="100%">
                                <td width="40%">
                                  <h6 className="py-2">
                                    <b>HideMe</b>
                                  </h6>
                                </td>
                                <td width="60%">
                                  <Form.Select
                                    size=""
                                    value={this.state.selectedHideMe}
                                    onChange={(e) => {
                                      this.onHideMeSelectChangeEvent(e);
                                    }}
                                  >
                                    <option value="All">All</option>
                                    <option value={false}>False</option>
                                    <option value={true}>True</option>
                                  </Form.Select>
                                </td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div className=" my-2 p-1">
                        <Table className="my-0 py-0">
                          <Row className="">
                            <Col>
                              <Stack
                                direction="horizontal"
                                gap={3}
                                className=""
                              >
                                <b>
                                  <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="30"
                                    height="30"
                                    fill="currentColor"
                                    class="bi bi-search"
                                    viewBox="0 0 16 16"
                                  >
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                  </svg>
                                </b>
                                <FormControl
                                  type="search"
                                  placeholder="Search... "
                                  className="text-dark fw-bold bg-white "
                                  size="lg"
                                  aria-label="Search"
                                  onChange={(e) =>
                                    this.onSearchChange(e.target.value)
                                  }
                                />
                                <Form.Select
                                  className="text-center w-25"
                                  onChange={(e) => {
                                    this.OnShowResultsLimitChanges(e);
                                  }}
                                >
                                  <option value="25">25</option>
                                  <option value="50">50</option>
                                  <option value="100">100</option>
                                  <option value="500">500</option>
                                  <option value="All">All</option>
                                </Form.Select>
                              </Stack>
                            </Col>
                          </Row>
                        </Table>
                      </div>
                      {/* <h4>shows top 100 results</h4> */}
                      <Table
                        border
                        striped
                        className="px-0 bg-white"
                        style={{
                          tableLayout: "fixed",
                        }}
                      >
                        {/* <thead className="">
                          <tr
                            className="text-center text-black bg-white border"
                            style={{ fontSize: "12px" }}
                          >
                            <th className="text-center border" width="50%"></th>
                            <th width="50%">Price </th>
                          </tr>
                        </thead> */}
                        <tbody className="text-primary border">
                          {this.state.filteredProducts
                            .slice(0, this.state.showResultsLimit)
                            // .sort((a, b) => (a.name > b.name ? 1 : -1))
                            .map((prod, i) =>
                              this.state.reload === true ? (
                                <EditProductInline
                                  index={i}
                                  prod={prod}
                                  OnDeleted={this.OnDeleted}
                                  OnUpdated={this.OnUpdated}
                                ></EditProductInline>
                              ) : null
                            )}
                        </tbody>
                      </Table>
                      {this.state.loadSpinnerOpen === true ? (
                        <LoadSpinner open="true"></LoadSpinner>
                      ) : (
                        ""
                      )}
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </Col>
        </Row>
      </div>
    );
  }
}

export class EditProductInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      prod: this.props.prod,
      editModalShow: false,
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      onscan: false,
    };
  }
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  handleHidemeChange = (checked) => {
    this.state.prod.hideMe = checked == false ? true : false;
    this.setState({ prod: this.state.prod });
    console.log("hi");
  };
  handleShowInPosChange = (checked) => {
    this.state.prod.showePos = checked == true ? true : false;
    this.setState({ prod: this.state.prod });
    console.log("hi");
  };
  handleShowInKioskChange = (checked) => {
    this.state.prod.showKiosk = checked == true ? true : false;
    this.setState({ prod: this.state.prod });
    console.log("hi");
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    //validate for minus values not to allow
    switch (name.trim()) {
      case "brandId":
        this.state.prod.brandId = value;
        break;
      case "name":
        this.state.prod.name = value;
        break;
      case "name2":
        this.state.prod.name2 = value;
        break;
      case "sku":
        this.state.prod.sku = value;
        break;
      case "hideMe":
        this.state.prod.hideMe = value;
        break;
      case "mrp":
        this.state.prod.mrp = value;
        break;
      case "takeAwayChargeInPercentage":
        this.state.prod.takeAwayChargeInPercentage = value;
        break;
      case "homeDeliveryChargeInPercentage":
        this.state.prod.homeDeliveryChargeInPercentage = value;
        break;
      case "discount":
        this.state.prod.discount = value;
        break;
      case "inStockQty":
        this.state.prod.inStockQty = value;
        break;
      case "seqNo":
        this.state.prod.seqNo = value;
        break;
      case "shortAd":
        this.state.prod.shortAd = value;
        break;
    }
    this.setState({ prod: this.state.prod });
  };

  OnDelete = () => {
    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteProduct/" +
        this.state.prod.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          this.ShowMessagBox("Error", "Delete Failed! try after sometime");
          return;
        }

        this.context.DeleteProductFromMemory(this.state.prod.id);
        this.HideLoadSpinner();
        this.props.OnDeleted(this.props.index);
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Delete Failed! try after sometime");
      });
  };

  OnSave = () => {
    // this.ShowLoadSpinner();
    var tmpProd = this.props.prod;
    if (this.state.prod.brandId == "0") tmpProd.brandId = "";
    tmpProd.hideMe = this.state.prod.hideMe;
    tmpProd.mrp = this.state.prod.mrp;
    tmpProd.discount =
      this.state.prod.discount == "" || this.state.prod.discount == undefined
        ? 0
        : this.state.prod.discount;
    tmpProd.inStockQty =
      this.state.prod.inStockQty == "" ||
      this.state.prod.inStockQty == undefined
        ? 0
        : this.state.prod.inStockQty;
    tmpProd.seqNo =
      this.state.prod.seqNo == "" || this.state.prod.seqNo == undefined
        ? 0
        : this.state.prod.seqNo;

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      // body: JSON.stringify({ tmpProd.JSON }),
      body: JSON.stringify(tmpProd),
    };

    // fetch(
    //   process.env.REACT_APP_API +
    //     "StoreManager/UpdateProduct/" +
    //     this.state.prod.id,
    //   requestOptions
    // )
    axios
      .put(
        process.env.REACT_APP_API +
          "StoreManager/UpdateProduct/" +
          this.state.prod.id,
        tmpProd
      )
      .then((res) => {
        if (res.status == 200) {
          this.context.UpdateProductInContext(tmpProd);

          Swal.fire({
            title: "Saved Successfully!",
            timer: 500,
          }).then((result) => {
            this.setState({ searchquery: "" });
          });
          return;
        }
        // this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed!");
      })
      .catch((error) => {
        // this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed! " + error);
      });
  };

  OnEditButtonClick = () => {
    this.setState({ editModalShow: true }, () => {});
  };

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {
      this.props.OnUpdated();
    });
  };
  componentDidMount() {}

  getMainCategoryId = (subCategoryId) => {
    var mainCategoryId = "";
    var f = this.context.subCategories.filter((e) => e.id === subCategoryId);
    if (f.length == 1) return f[0].mainCategoryId;
    else return 0;
  };
  GetBarCode = (value) => {
    this.state.prod.sku = value;
    this.setState({ prod: this.state.prod });
    if (value != "") this.setState({ onscan: false });
  };
  OnScanner = () => {
    this.setState({ onscan: true });
  };
  OnScannerClose = () => {
    this.setState({ onscan: false });
  };

  render() {
    return (
      <>
        {this.state.editModalShow === true ? (
          <ProductAddOrEditModal
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            prod={this.state.prod}
            Type="Edit"
            mainCategoryId={this.getMainCategoryId(
              this.state.prod.subCategoryId
            )}
          />
        ) : (
          ""
        )}
        {this.state.msgboxOpen === true ? (
          <MessageBox
            open={this.state.msgboxOpen}
            title={this.state.msgboxTitle}
            message={this.state.msgboxMessage}
            callback={this.CloseMessagBoxCallback}
          />
        ) : (
          ""
        )}
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}

        {window.innerWidth >= 1200 ? (
          <>
            <tr
              className="text-center align-middle text-secondary bg-white mx-5"
              style={{ fontSize: "11px" }}
            >
              <td>{this.props.index + 1}</td>
              <td className="text-center ">
                {this.state.prod.productImages.length > 0 ? (
                  <img
                    src={
                      this.context.store.storageBlobUrl +
                      this.context.store.storageBlobContainerName +
                      "/images/" +
                      this.state.prod.productImages[0].fileName +
                      this.context.store.storageSasToken
                    }
                    className="productimg-on-productlist mx-auto btn-default CornersRounded"
                    alt="..."
                    height="100px"
                  />
                ) : (
                  ""
                )}
              </td>
              <td className="text-start">
                {/* <b>{this.state.prod.name}</b> */}
                <Row>
                  <Form.Control
                    type="text"
                    name="name"
                    size="lg"
                    value={this.state.prod.name}
                    required
                    placeholder="enter Product Name"
                    min={0}
                    maxLength={119}
                    align="center"
                    className="text-start  w-100 fw-bold"
                    autoComplete="off"
                    onChange={(e) => this.handleInputChange(e)}
                  />
                </Row>
                <Row>
                  <Form.Control
                    type="text"
                    name="name2"
                    // size="lg"
                    value={this.state.prod.name2}
                    required
                    placeholder="Product Name (2nd languge)"
                    min={0}
                    maxLength={119}
                    align="center"
                    className="text-start  w-100 fw-bold"
                    autoComplete="off"
                    onChange={(e) => this.handleInputChange(e)}
                  />
                </Row>

                <Row className="w-100 my-1">
                  <Col xs={6}>
                    <b>Sub Category Name :</b>{" "}
                  </Col>
                  <Col xs={6} className="text-secondary text-start ">
                    <div className="text-secondary text-start">
                      {this.state.prod.subCategory.name}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col xs={6}>
                    <Stack direction="horizontal">
                      <b>Brand:</b>
                      <Form.Select
                        type="text"
                        name="brandId"
                        size="sm"
                        value={this.state.prod.brandId}
                        placeholder="select Brand"
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value={0}></option>
                        {this.context.brands
                          .sort((a, b) => (a.brandName > b.brandName ? 1 : -1))
                          .map((brand) => (
                            <option value={brand.id}>{brand.brandName}</option>
                          ))}
                      </Form.Select>
                    </Stack>
                  </Col>

                  <Col xs={6}>
                    <Stack direction="horizontal">
                      <b>SKU:</b>
                      <Form.Control
                        type="text"
                        name="sku"
                        value={this.state.prod.sku}
                        required
                        placeholder="enter SKU"
                        min={0}
                        maxLength={20}
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Stack>
                  </Col>
                </Row>
              </td>
              <td>
                <tr>
                  <td>
                    Seq.No:
                    <Form.Control
                      // type="number"
                      name="seqNo"
                      value={this.state.prod.seqNo}
                      required
                      min={0}
                      className="text-center text-danger fw-bold"
                      placeholder=""
                      autoComplete="off"
                      onChange={(e) => this.handleInputChange(e)}
                    />
                    <br />
                    Short-Ad:{" "}
                    <Form.Control
                      type="text"
                      name="shortAd"
                      value={this.state.prod.shortAd}
                      required
                      maxLength={21}
                      className="text-center text-danger fw-bold"
                      placeholder=""
                      autoComplete="off"
                      onChange={(e) => this.handleInputChange(e)}
                    />
                  </td>
                </tr>
              </td>
              <td className="text-center ">
                <tr className="text-center aligncenerintd1">
                  <td>C.POR</td>
                  <td>
                    <Switch
                      height={20}
                      onChange={this.handleHidemeChange}
                      checked={!this.state.prod.hideMe}
                    />
                  </td>
                </tr>
                <tr className="text-center aligncenerintd1">
                  <td>POS</td>
                  <td>
                    <Switch
                      height={20}
                      onChange={this.handleShowInPosChange}
                      checked={this.state.prod.showePos}
                    />
                  </td>
                </tr>
                <tr className="text-center aligncenerintd1">
                  <td>Kiosk</td>
                  <td>
                    <Switch
                      height={20}
                      onChange={this.handleShowInKioskChange}
                      checked={this.state.prod.showKiosk}
                    />
                  </td>
                </tr>
              </td>

              <td className="text-center align-middle">
                {this.state.prod.productVariantType == 1 ? (
                  <>
                    <Row className="verticalaligncentertd">
                      <Col xs={3}>MRP:</Col>
                      <Col xs={9}>
                        <Form.Control
                          type="number"
                          name="mrp"
                          value={this.state.prod.mrp}
                          required
                          style={{ fontSize: "18px" }}
                          placeholder=""
                          className="text-center w-100"
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Row>
                    {/* <Row>
                      <Col xs={5}>Take Away(%)</Col>
                      <Col xs={7}>
                        <Stack direction="horizontal">
                          <Form.Control
                            type="number"
                            name="takeAwayChargeInPercentage"
                            value={this.state.prod.takeAwayChargeInPercentage}
                            required
                            placeholder=""
                            min={0}
                            align="center"
                            className="text-center CornersRounded "
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />

                          {(
                            Number(this.state.prod.mrp) +
                            (Number(this.state.prod.mrp) *
                              Number(
                                this.state.prod.takeAwayChargeInPercentage
                              )) /
                              100
                          ).toLocaleString(
                            this.context.storeSettings == ""
                              ? "en-IN"
                              : this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings == ""
                                  ? "INR"
                                  : this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </Stack>
                      </Col>
                    </Row> */}
                    {/* <Row>
                      <Col xs={5}>Home Del(%):</Col>
                      <Col xs={7}>
                        <Stack direction="horizontal">
                          <Form.Control
                            type="number"
                            name="homeDeliveryChargeInPercentage"
                            value={
                              this.state.prod.homeDeliveryChargeInPercentage
                            }
                            required
                            placeholder=""
                            min={0}
                            align="center"
                            className="text-center CornersRounded "
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />

                          {(
                            Number(this.state.prod.mrp) +
                            (Number(this.state.prod.mrp) *
                              Number(
                                this.state.prod.homeDeliveryChargeInPercentage
                              )) /
                              100
                          ).toLocaleString(
                            this.context.storeSettings == ""
                              ? "en-IN"
                              : this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings == ""
                                  ? "INR"
                                  : this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </Stack>
                      </Col>
                    </Row> */}
                  </>
                ) : (
                  <></>
                )}
                {this.state.prod.productVariantType == 1 ? (
                  <>
                    <Row>
                      <Col xs={3} className="">
                        Disc %:
                      </Col>
                      <Col xs={9}>
                        <Form.Control
                          type="number"
                          name="discount"
                          value={this.state.prod.discount}
                          required
                          style={{ fontSize: "18px" }}
                          placeholder=""
                          min={0}
                          className="text-center w-100"
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
                {this.state.prod.productVariantType == 1 ? (
                  <>
                    <Row>
                      <Col xs={3} className="">
                        S.Price :
                      </Col>
                      <Col xs={9} className=" text-center  ">
                        <span
                          className="text-dark text-center"
                          style={{ fontSize: "18px", fontWeight: "bold" }}
                        >
                          {this.context
                            .GetBasePrice(this.state.prod)
                            .toLocaleString(
                              this.context.storeSettings == ""
                                ? "en-IN"
                                : this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                                style: "currency",
                                currency:
                                  this.context.storeSettings == ""
                                    ? "INR"
                                    : this.context.storeSettings
                                        .defaultCurrency,
                              }
                            )}

                          {/* <b>TA:</b> &nbsp;&nbsp;
                      {this.context
                        .GetTakeAwayPrice(this.state.prod)
                        .toLocaleString(
                          this.context.storeSettings == ""
                            ? "en-IN"
                            : this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings == ""
                                ? "INR"
                                : this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      <br />
                      <b>HD:</b> &nbsp;&nbsp;
                      {this.context
                        .GetHomeDeliveryPrice(this.state.prod)
                        .toLocaleString(
                          this.context.storeSettings == ""
                            ? "en-IN"
                            : this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings == ""
                                ? "INR"
                                : this.context.storeSettings.defaultCurrency,
                          }
                        )} */}
                        </span>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
              </td>

              {/* <td></td> */}
              <td>
                {this.state.prod.productVariantType == 1 ? (
                  <>
                    In-Stock
                    <Form.Control
                      type="number"
                      name="inStockQty"
                      size="lg"
                      value={this.state.prod.inStockQty}
                      required
                      min={0}
                      className="text-center text-danger fw-bold"
                      placeholder=""
                      autoComplete="off"
                      onChange={(e) => this.handleInputChange(e)}
                    />
                  </>
                ) : (
                  <></>
                )}
              </td>

              {/* <td></td> */}

              <td className="text-cener">
                <div class="">
                  <OverlayTrigger overlay={<Tooltip>Save</Tooltip>}>
                    <Button
                      className="rounded-circle   bg-success "
                      onClick={(e) => {
                        this.OnSave(e);
                      }}
                    >
                      <BiSave size="24"> Save</BiSave>
                    </Button>
                  </OverlayTrigger>

                  <OverlayTrigger overlay={<Tooltip>Edit!</Tooltip>}>
                    <Button
                      className="rounded-circle   bg-primary my-1"
                      onClick={(e) => {
                        this.OnEditButtonClick(e);
                      }}
                    >
                      <BsFillPencilFill size="20" />
                    </Button>
                  </OverlayTrigger>
                  <br />

                  <OverlayTrigger overlay={<Tooltip>Delete!</Tooltip>}>
                    <Button
                      className="mx-1 text-danger border-0"
                      style={{ backgroundColor: "transparent" }}
                      onClick={(e) => {
                        if (
                          window.confirm("Are you sure you want to Delete ?")
                        ) {
                          this.OnDelete(e);
                        }
                      }}
                    >
                      <FaTrashAlt size={24} />
                    </Button>
                  </OverlayTrigger>
                </div>
              </td>
            </tr>
          </>
        ) : (
          <>
            <div
              className="border border-dark"
              // style={{ border: "outline: thin solid" }}
            >
              <Table striped size="sm" className="">
                <tbody>
                  <tr
                    className="text-start text-dark bg-white mx-1"
                    style={{ fontSize: "11px" }}
                  >
                    <td
                      className="text-start"
                      colSpan={3}
                      // style={{ verticalAlign: "middle" }}
                    >
                      <Stack direction="horizontal" className="text-start">
                        <h3>{this.props.index + 1}. </h3>
                        {this.state.prod.productImages.length > 0 ? (
                          <img
                            src={
                              this.context.store.storageBlobUrl +
                              this.context.store.storageBlobContainerName +
                              "/images/" +
                              this.state.prod.productImages[0].fileName +
                              this.context.store.storageSasToken
                            }
                            className=" text-start"
                            alt="..."
                            style={{
                              height: "20px",
                              // objectFit: "contain",
                            }}
                          />
                        ) : (
                          ""
                        )}
                        &nbsp;&nbsp;<h3>{this.state.prod.name}</h3>
                      </Stack>
                    </td>
                    {/* <td
                colSpan={2}
                className="text-start"
                style={{ verticalAlign: "middle" }}
              >
                
              </td> */}
                  </tr>
                  <tr
                    className="text-start align-top text-secondary bg-white mx-5"
                    style={{ fontSize: "11px" }}
                  >
                    <td colSpan={2} width={"70%"}>
                      <Stack direction="horizontal" gap={3}>
                        SKU
                        <Form.Control
                          type="text"
                          name="sku"
                          value={this.state.prod.sku}
                          required
                          placeholder="enter SKU"
                          min={0}
                          maxLength={20}
                          align="center"
                          className="text-center  "
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                        <Button
                          className="rounded-circle text-danger"
                          style={{ backgroundColor: "transparent" }}
                          onClick={(e) => this.OnScanner()}
                        >
                          <IoMdQrScanner />
                        </Button>
                      </Stack>
                      {this.state.onscan == true ? (
                        <>
                          <BarCodeScannerModal
                            barcode={this.GetBarCode}
                            Close={this.OnScannerClose}
                          ></BarCodeScannerModal>
                        </>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td
                      width={"30%"}
                      className="text-start"
                      style={{ verticalAlign: "top" }}
                    >
                      <tr width="100% m-0 p-0">
                        <td
                          width="40% text-start"
                          style={{ verticalAlign: "middle" }}
                        >
                          ShowMe:
                        </td>
                        <td width="60%">
                          <Switch
                            height={20}
                            onChange={this.handleHidemeChange}
                            checked={!this.state.prod.hideMe}
                          />
                        </td>
                      </tr>
                      {/* <tr>
                  
                </tr> */}
                    </td>
                    {/* <td width="50%">
                <tr>
                  
                </tr>
                <tr>
                  
                </tr>
              </td> */}
                  </tr>
                  <tr>
                    <td width={"40%"}>
                      {this.state.prod.productVariantType == 1 ? (
                        <>
                          <td style={{ verticalAlign: "middle" }}>MRP</td>
                          <td>
                            <Form.Control
                              type="number"
                              name="mrp"
                              value={this.state.prod.mrp}
                              required
                              placeholder=""
                              min={0}
                              align="center"
                              className="text-center  "
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e)}
                            />
                          </td>
                        </>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td width={"40%"}>
                      {this.state.prod.productVariantType == 1 ? (
                        <>
                          <td style={{ verticalAlign: "middle" }}>Disc(%)</td>
                          <td>
                            <Form.Control
                              type="number"
                              name="discount"
                              value={this.state.prod.discount}
                              required
                              min={0}
                              className="text-center"
                              placeholder=""
                              autoComplete="off"
                              onChange={(e) => this.handleInputChange(e)}
                            />
                          </td>
                        </>
                      ) : (
                        <></>
                      )}
                    </td>
                    <td width={"20%"}>
                      <Row>
                        <Col className="text-primary text-center align-middle">
                          {/* &nbsp;&nbsp; S.Price: <br /> */}
                          <h1>
                            {this.context
                              .GetBasePrice(this.state.prod)
                              .toLocaleString(
                                this.context.storeSettings == ""
                                  ? "en-IN"
                                  : this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 0,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings == ""
                                      ? "INR"
                                      : this.context.storeSettings
                                          .defaultCurrency,
                                }
                              )}
                          </h1>
                        </Col>
                      </Row>
                    </td>
                  </tr>
                  <tr className="">
                    {this.state.prod.productVariantType == 1 ? (
                      <>
                        <td colSpan={1} className="text-center" width={"60%"}>
                          {this.state.prod.productVariantType == 1 ? (
                            <>
                              <td style={{ verticalAlign: "middle" }}>
                                InStk:
                              </td>
                              <td>
                                <Form.Control
                                  type="number"
                                  name="inStockQty"
                                  value={this.state.prod.inStockQty}
                                  required
                                  min={0}
                                  className="text-center text-danger fw-bold"
                                  placeholder=""
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </td>
                            </>
                          ) : (
                            <></>
                          )}
                        </td>
                        <td className="text-center border" width={"20%"}>
                          <div class="">
                            <OverlayTrigger overlay={<Tooltip>Edit!</Tooltip>}>
                              <Button
                                className="rounded-circle text-danger "
                                style={{ backgroundColor: "transparent" }}
                                onClick={(e) => {
                                  this.OnEditButtonClick(e);
                                }}
                              >
                                <BsFillPencilFill size="16" />
                              </Button>
                            </OverlayTrigger>
                            &nbsp;
                            <OverlayTrigger
                              overlay={<Tooltip>Delete!</Tooltip>}
                            >
                              <Button
                                className="rounded-circle text-danger "
                                style={{ backgroundColor: "transparent" }}
                                onClick={(e) => {
                                  if (
                                    window.confirm(
                                      "Are you sure you want to Delete ?"
                                    )
                                  ) {
                                    this.OnDelete(e);
                                  }
                                }}
                              >
                                <FaTrashAlt size={16} />
                              </Button>
                            </OverlayTrigger>
                          </div>
                        </td>
                        <td className="text-center border" width={"20%"}>
                          <Col>
                            <OverlayTrigger overlay={<Tooltip>Save</Tooltip>}>
                              <Button
                                className="mainthemecolor w-100 CornersRounded"
                                onClick={(e) => {
                                  this.OnSave(e);
                                }}
                              >
                                <BiSave size="20" />
                                Save
                              </Button>
                            </OverlayTrigger>
                          </Col>
                        </td>
                      </>
                    ) : (
                      <></>
                    )}
                  </tr>
                </tbody>
              </Table>
            </div>
          </>
        )}
      </>
    );
  }
}

class BarCodeScannerModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          show={true}
          backdrop="static"
          keyboard={false}
          dialogClassName="BrandModal"
          onHide={this.props.Close}
        >
          <Modal.Header closeButton className="bg-warning">
            <Modal.Title id="contained-modal-title-vcenter"></Modal.Title>
          </Modal.Header>
          <Modal.Body className="show-grid ">
            <BarcodeScanner barcode={this.props.barcode}></BarcodeScanner>
          </Modal.Body>
        </Modal>
      </div>
    );
  }
}

export const BarcodeScanner = (props) => {
  const [result, setResult] = useState("");
  const { ref } = useZxing({
    onDecodeResult(result) {
      setResult(result.getText());
      props.barcode(result.getText());
    },
  });

  return (
    <>
      <video ref={ref} />
      <p>
        <span>Last result:</span>
        <span>{result}</span>
      </p>
    </>
  );
};
