import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./App.css";

import { Container } from "react-bootstrap";
import React, { createContext, useEffect, useState } from "react";
import Navigation from "./Components/StoreManager/Navigation";
import NavigationMobile from "./Components/StoreManager/NavigationMobile";
import { Layout } from "./Components/Layout";
import { Home } from "./Components/StoreManager/Home";
import HomeMobile from "./Components/StoreManager/HomeMobile";
import HomeMobileFront from "./Components/StoreManager/HomeMobileFront";
import { CartProvider } from "./Components/StoreManager/CartContext";
import { ProductsListView } from "./Components/StoreManager/ProductsListView";
import { Category } from "./Components/StoreManager/Category";
import { OutOfStock } from "./Components/StoreManager/OutOfStock";
import { OrderHistory } from "./Components/StoreManager/OrderHistory";
import { Customers } from "./Components/StoreManager/Customers";
import { Brands } from "./Components/StoreManager/Brands";
import { ProductGroups } from "./Components/StoreManager/ProductGroups";
import { Images } from "./Components/StoreManager/Images";
import { HomeSettingsCarousel } from "./Components/StoreManager/HomeSettingsCarousel";
import { HomeSettingsMainMenu } from "./Components/StoreManager/HomeSettingsMainMenu";
import { HomeSettingsTop5Ads } from "./Components/StoreManager/HomeSettingsTop5Ads";
import UserLogin from "./Components/StoreManager/UserLogin";
import UserChangePassword from "./Components/StoreManager/UserChangePassword";
import Users from "./Components/StoreManager/Users";
import StoreSettings from "./Components/StoreManager/StoreSettings";
import { Coupon } from "./Components/StoreManager/Coupon";
import { ShippingConfig } from "./Components/StoreManager/ShippingConfig";
import { TaxesConfig } from "./Components/StoreManager/TaxesConfig";
import { Themes } from "./Components/StoreManager/Themes";
import { Reports } from "./Components/StoreManager/Reports";
import { ProductThemes } from "./Components/StoreManager/ProductThemes";
import PosUsers from "./Components/StoreManager/PosUsers";
import { RestaurantTables } from "./Components/StoreManager/RestaurantTables";
import TableOrdersQueue from "./Components/StoreManager/TableOrdersQueue";
import Kds from "./Components/StoreManager/Kds";
import Kds2 from "./Components/StoreManager/Kds2";
import { BarCodeCustom } from "./Components/StoreManager/BarCodeCustom";
import { DataTypes } from "./Components/StoreManager/DataTypes";
import { Suppliers } from "./Components/StoreManager/Suppliers";
import { PurchaseRequests } from "./Components/StoreManager/PurchaseRequests";
import { PurchaseOrders } from "./Components/StoreManager/PurchaseOrders";
import { RawMaterialCategory } from "./Components/StoreManager/RawMaterialCategory";
import { RawMaterialsInventory } from "./Components/StoreManager/RawMaterialInventory";
import { RawMaterialSuppliers } from "./Components/StoreManager/RawMaterialSuppliers";
import { RawMaterialPurchaseOrders } from "./Components/StoreManager/RawMaterialPurchaseOrders";
import { RawMaterialPurchaseRequests } from "./Components/StoreManager/RawMaterialPurchaseRequests";
import { IngredientsInventory } from "./Components/StoreManager/IngredientsInventory";
import { Recipes } from "./Components/StoreManager/Recipes";
import { ClientCertificate } from "./Components/StoreManager/ClientCertificate";
import { ClientLogo } from "./Components/StoreManager/ClientLogo";
import { ClientTestimonial } from "./Components/StoreManager/ClientTestimonial";
import { ClientDisplayCounter } from "./Components/StoreManager/ClientDisplayCounter";
import { RawMaterialManufacturingRequest } from "./Components/StoreManager/RawMaterialManufacturingRequest";
import { RawMaterialServiceRequest } from "./Components/StoreManager/RawMaterialServiceRequest";

import {
  Orders,
  Package,
  Dispatch,
  Delivery,
  Payment,
  CloseOrder,
  Returns,
} from "./Components/StoreManager/Orders";

import RefreshBearerToken from "./Components/StoreManager/RefreshBearerToken";
import { ManufacturingRequestOrders } from "./Components/StoreManager/ManufacturingRequestOrder";
import { ProductServiceCustomers } from "./Components/StoreManager/ProductServiceCustomers";
import { ProductServiceBookings } from "./Components/StoreManager/ProductServiceBooking";
import { ProductServiceTaskInventory } from "./Components/StoreManager/ProductServiceTaskInventory";
import { ProductServiceRequests } from "./Components/StoreManager/ProductServiceRequests";
import { SalesLeads } from "./Components/StoreManager/SalesLeads";
import { HomeSettingsVlog } from "./Components/StoreManager/HomeSettingsVlog";
import { HomeSettingsBlog } from "./Components/StoreManager/HomeSettingsBlog";
import { LifeStyleAquaAuthenticator } from "./Components/StoreManager/LifeStyleAquaAuthenticator";
import { MultiCurrencys } from "./Components/StoreManager/MultiCurrencyPage";
import { Departments } from "./Components/StoreManager/Departments";
import { Employees } from "./Components/StoreManager/Employees";
import { AccountExpensesCategorys } from "./Components/StoreManager/AccountExpensesCategory";
import { AccountExpenses } from "./Components/StoreManager/AccountExpenses";
import { AccountRevenues } from "./Components/StoreManager/AccountRevenues";
import { AccountReceiveables } from "./Components/StoreManager/AccountReceiveable";
import { AccountPayables } from "./Components/StoreManager/AccountPayable";
import { AccountPayroll } from "./Components/StoreManager/AccountPayroll";
import { AccountLedger } from "./Components/StoreManager/AccountLedger";
import { QuotationRequest } from "./Components/StoreManager/QuotationRequest";

function App() {
  return (
    <Container fluid className="m-0 p-0 w-100">
      {window.innerWidth <= 1100 ? (
        <CartProvider>
          <BrowserRouter>
            <NavigationMobile />
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<HomeMobileFront />} />
                <Route path="UserLogin" element={<UserLogin />} />
                <Route path="HomeMobile" element={<HomeMobile />} />

                <Route path="Customers" element={<Customers />} />
                <Route
                  path="Orders"
                  element={<Orders OrderState="Received" />}
                />
                <Route
                  path="Package"
                  element={<Package OrderState="Package" />}
                />
                <Route path="Kds2" element={<Kds2 />} />
                <Route
                  path="Dispatch"
                  element={<Dispatch OrderState="Dispatch" />}
                />
                <Route
                  path="Delivery"
                  element={<Delivery OrderState="Delivery" />}
                />
                <Route
                  path="Payment"
                  element={<Payment OrderState="Payment" />}
                />
                <Route
                  path="CloseOrder"
                  element={<CloseOrder OrderState="Close" />}
                />
                <Route
                  path="OrderHistory"
                  element={<OrderHistory OrderState="History" />}
                />
                <Route
                  path="Returns"
                  element={<Returns OrderState="Returns" />}
                />
                <Route path="TableOrdersQueue" element={<TableOrdersQueue />} />
                <Route path="ProductsListView" element={<ProductsListView />} />
                <Route path="OutOfStock" element={<OutOfStock />} />
                <Route
                  path="UserChangePassword"
                  element={<UserChangePassword />}
                />

                <Route path="Suppliers" element={<Suppliers />} />
                <Route path="PurchaseRequests" element={<PurchaseRequests />} />
                <Route path="PurchaseOrders" element={<PurchaseOrders />} />
                <Route
                  path="LifeStyleAquaAuthenticator"
                  element={<LifeStyleAquaAuthenticator />}
                />
                <Route path="Reports" element={<Reports />} />
                <Route path="*" element={<HomeMobileFront />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </CartProvider>
      ) : (
        <CartProvider>
          <BrowserRouter>
            <Navigation />
            <Routes>
              <Route path="/" element={<Layout />}>
                <Route index element={<Home />} />
                <Route path="Category" element={<Category />} />
                <Route path="ProductsListView" element={<ProductsListView />} />
                <Route path="OutOfStock" element={<OutOfStock />} />
                <Route path="ProductGroups" element={<ProductGroups />} />
                <Route path="Brands" element={<Brands />} />
                <Route path="DataTypes" element={<DataTypes />} />

                <Route path="BarCodeCustom" element={<BarCodeCustom />} />
                <Route path="Images" element={<Images />} />
                <Route
                  path="Orders"
                  element={<Orders OrderState="Received" />}
                />
                <Route
                  path="Package"
                  element={<Package OrderState="Package" />}
                />
                <Route
                  path="Dispatch"
                  element={<Dispatch OrderState="Dispatch" />}
                />
                <Route
                  path="Delivery"
                  element={<Delivery OrderState="Delivery" />}
                />
                <Route
                  path="Payment"
                  element={<Payment OrderState="Payment" />}
                />
                <Route
                  path="CloseOrder"
                  element={<CloseOrder OrderState="Close" />}
                />
                <Route
                  path="OrderHistory"
                  element={<OrderHistory OrderState="History" />}
                />
                <Route
                  path="Returns"
                  element={<Returns OrderState="Returns" />}
                />

                <Route
                  path="HomeSettingsCarousel"
                  element={<HomeSettingsCarousel />}
                />
                <Route path="HomeSettingsVlog" element={<HomeSettingsVlog />} />
                <Route path="HomeSettingsBlog" element={<HomeSettingsBlog />} />

                <Route
                  path="HomeSettingsMainMenu"
                  element={<HomeSettingsMainMenu />}
                />
                <Route
                  path="HomeSettingsTop5Ads"
                  element={<HomeSettingsTop5Ads />}
                />
                <Route
                  path="ClientCertificate"
                  element={<ClientCertificate />}
                />
                <Route path="ClientLogo" element={<ClientLogo />} />
                <Route
                  path="ClientTestimonial"
                  element={<ClientTestimonial />}
                />
                <Route
                  path="ClientDisplayCounter"
                  element={<ClientDisplayCounter />}
                />
                <Route path="Themes" element={<Themes />} />
                <Route path="ProductThemes" element={<ProductThemes />} />
                <Route path="TableOrdersQueue" element={<TableOrdersQueue />} />
                <Route path="Kds" element={<Kds />} />
                <Route path="Kds2" element={<Kds2 />} />

                <Route path="Suppliers" element={<Suppliers />} />
                <Route path="PurchaseRequests" element={<PurchaseRequests />} />
                <Route path="PurchaseOrders" element={<PurchaseOrders />} />

                <Route path="Customers" element={<Customers />} />
                <Route path="Coupon" element={<Coupon />} />

                {/* Admin */}

                <Route path="Users" element={<Users />} />
                <Route path="UserLogin" element={<UserLogin />} />
                <Route path="ShippingConfig" element={<ShippingConfig />} />
                <Route path="TaxesConfig" element={<TaxesConfig />} />
                <Route path="RestaurantTables" element={<RestaurantTables />} />

                <Route path="StoreSettings" element={<StoreSettings />} />
                <Route
                  path="UserChangePassword"
                  element={<UserChangePassword />}
                />
                <Route path="PosUsers" element={<PosUsers />} />
                <Route path="Reports" element={<Reports />} />

                <Route
                  path="ManufacturingRequestOrders"
                  element={<ManufacturingRequestOrders />}
                />
                {/* RawMaterial */}
                <Route
                  path="RawMaterialCategory"
                  element={<RawMaterialCategory />}
                />
                <Route
                  path="RawMaterialsInventory"
                  element={<RawMaterialsInventory />}
                />
                <Route
                  path="RawMaterialSuppliers"
                  element={<RawMaterialSuppliers />}
                />
                <Route
                  path="RawMaterialPurchaseOrders"
                  element={<RawMaterialPurchaseOrders />}
                />
                <Route
                  path="RawMaterialPurchaseRequests"
                  element={<RawMaterialPurchaseRequests />}
                />
                <Route
                  path="RawMaterialManufacturingRequest"
                  element={<RawMaterialManufacturingRequest />}
                />
                <Route
                  path="RawMaterialServiceRequest"
                  element={<RawMaterialServiceRequest />}
                />

                <Route
                  path="IngredientsInventory"
                  element={<IngredientsInventory />}
                />
                <Route path="Recipes" element={<Recipes />} />
                <Route
                  path="ProductServiceCustomers"
                  element={<ProductServiceCustomers />}
                />
                <Route
                  path="ProductServiceBookings"
                  element={<ProductServiceBookings />}
                />
                <Route
                  path="ProductServiceTaskInventory"
                  element={<ProductServiceTaskInventory />}
                />
                <Route
                  path="ProductServiceRequests"
                  element={<ProductServiceRequests />}
                />
                <Route path="SalesLeads" element={<SalesLeads />} />

                <Route
                  path="LifeStyleAquaAuthenticator"
                  element={<LifeStyleAquaAuthenticator />}
                />
                <Route path="MultiCurrencys" element={<MultiCurrencys />} />
                <Route path="Departments" element={<Departments />} />
                <Route path="Employees" element={<Employees />} />

                <Route
                  path="AccountExpensesCategorys"
                  element={<AccountExpensesCategorys />}
                />

                <Route path="AccountExpenses" element={<AccountExpenses />} />
                <Route path="AccountRevenues" element={<AccountRevenues />} />
                <Route
                  path="AccountReceiveables"
                  element={<AccountReceiveables />}
                />
                <Route path="AccountPayables" element={<AccountPayables />} />
                <Route path="AccountPayroll" element={<AccountPayroll />} />
                <Route path="AccountLedger" element={<AccountLedger />} />

                <Route path="QuotationRequest" element={<QuotationRequest />} />

                <Route path="*" element={<Home />} />
              </Route>
            </Routes>
          </BrowserRouter>
        </CartProvider>
      )}
    </Container>
  );
}

export default App;
