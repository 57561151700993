import React, { Component } from "react";
import CartContext from "./CartContext";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import Select from "react-select";
import reactSelect from "react-select";
import DatePicker from "react-date-picker";

import "react-date-picker/dist/DatePicker.css";
import "react-calendar/dist/Calendar.css";

import {
  FloatingLabel,
  OverlayTrigger,
  Tooltip,
  Table,
  InputGroup,
  Modal,
  Button,
  Row,
  Col,
  Form,
  Image,
  Alert,
} from "react-bootstrap";

// https://www.geeksforgeeks.org/how-to-create-an-editable-table-with-add-delete-and-search-filter-using-reactjs/
export class CouponAddOrEditModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      coupon: {
        couponStatus: true,
        totalTimesUsed: 0,
        // createdOn: new Date().toLocaleString(),
      },
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
    };
  }

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  handleChange = (selectedOption) => {
    this.setState({ selectedOption }, () =>
      console.log(`Option selected:`, this.state.selectedOption)
    );
  };

  AddOrUpdateCouponInDB = () => {
    this.ShowLoadSpinner();
    var tCoupon = JSON.parse(JSON.stringify(this.state.coupon));
    tCoupon.storeId = this.context.storeId;
    tCoupon.expiryDate =
      this.state.coupon.expiryDate.getMonth() +
      1 +
      "/" +
      this.state.coupon.expiryDate.getDate() +
      "/" +
      this.state.coupon.expiryDate.getFullYear();
    // this.state.coupon.expiryDate = this.state.coupon.expiryDate.stringify();
    const requestOptions = {
      method: "POST",
      headers: {
        Authorization: `Bearer ${this.context.userData.bearertoken}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(tCoupon),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/AddOrUpdateCoupon/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.HideLoadSpinner();
          this.ShowMessagBox(
            "Information",
            "Saved Successfully. Click Refresh to see the changes!"
          );
          return;
        } else if (res.status == 409) {
          this.HideLoadSpinner();
          this.ShowMessagBox("Information", "Coupon Code already Exist!");
        } else {
          this.HideLoadSpinner();
          this.ShowMessagBox("Error", "Saved Failed!");
        }
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed! " + error);
      });
  };

  getDateWithoutTime(date) {
    var date1 = new Date(date);
    date1.setHours(0, 0, 0, 0);
    return date1;
  }

  ValidateExpiryDate = () => {
    if (
      this.state.coupon.expiryDate == "" ||
      this.state.coupon.expiryDate == undefined
    ) {
      alert("invalid Expiry Date");
      return false;
    } else if (
      this.state.coupon.expiryDate != "" &&
      this.state.coupon.expiryDate != undefined &&
      this.getDateWithoutTime(new Date()) >
        this.getDateWithoutTime(this.state.coupon.expiryDate)
    ) {
      alert("invalid Expiry Date");
      return false;
    }
    return true;
  };

  handleSubmit = (event) => {
    event.preventDefault();

    if (this.ValidateExpiryDate() == false) {
      return;
    } else {
      this.AddOrUpdateCouponInDB();
    }
  };
  GetCouponStatus(value) {
    var today = new Date();
    today.setHours(0, 0, 0, 0);
    var tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
    var expiryDate = new Date(this.state.coupon.expiryDate);
    expiryDate.setHours(0, 0, 0, 0);

    if (expiryDate >= today) return true;
    else return false;
  }

  handleInputChange = (e) => {
    const { name, value } = e.target;

    //validate for minus values not to allow
    switch (name.trim()) {
      case "mainCategoryId":
        this.state.coupon.mainCategoryId = value;
        break;
      case "couponCode":
        this.state.coupon.couponCode = value.toUpperCase();
        break;
      case "couponPurpose":
        this.state.coupon.couponPurpose = value;
        break;
      case "couponValueType":
        this.state.coupon.couponValueType = value;
        if (value == "1") {
          this.state.coupon.discount = "";
        } else if (value == "2") {
          this.state.coupon.amount = "";
        }
        break;
      case "amount":
        if (Number(value) > 0) {
          this.state.coupon.amount = value;
        } else this.state.coupon.amount = 0;
        break;
      case "discount":
        if (Number(value) > 0) {
          this.state.coupon.discount = value;
        }
        break;
      case "noOfTimesCanBeUsed":
        this.state.coupon.noOfTimesCanBeUsed = value;
        break;
      case "reason":
        this.state.coupon.reason = value;
        break;
      case "createdBy":
        this.state.coupon.createdBy = value;
        break;
      case "couponStatus":
        this.state.coupon.couponStatus = value;
        break;
      case "totalTimesUsed":
        this.state.coupon.totalTimesUsed = value;
        break;
    }
    this.setState({ coupon: this.state.coupon }, () => {});
  };

  ExpiryDateChange = (date) => {
    var tcoupon = this.state.coupon;
    tcoupon.expiryDate = date;
    this.setState({ coupon: tcoupon }, () => {
      this.setState({ couponStatus: this.GetCouponStatus() });
    });
  };

  componentDidMount() {
    if (this.props.Type == "Edit") {
      var tcoupon = this.props.coupon;
      tcoupon.expiryDate = new Date(tcoupon.expiryDate);
      this.setState({ coupon: tcoupon });
    }
  }

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          dialogClassName="CouponModal"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit" ? "Edit Coupon" : "Add Coupon"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              {/* <Form.Label column>
                <h4 className="text-dark">Coupon Details</h4>
              </Form.Label> */}
              <Row
                className="m-2 p-2 bg-light border"
                style={{ fontSize: "9pt" }}
              >
                <Col md={12}>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Coupon Code
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="couponCode"
                        maxlength="11"
                        value={this.state.coupon.couponCode}
                        required
                        disabled={this.props.Type == "Edit" ? true : false}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1" controlId="">
                    <Form.Label column sm="3">
                      Coupon Purpose
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        name="couponPurpose"
                        value={this.state.coupon.couponPurpose}
                        required
                        placeholder=""
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value="0"></option>
                        <option value="1">Refund</option>
                        <option value="2">Reward</option>
                        <option value="3">Promotion</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1" controlId="">
                    <Form.Label column sm="3">
                      Coupon Type
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        name="couponValueType"
                        value={this.state.coupon.couponValueType}
                        required
                        placeholder=""
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value="0"></option>
                        <option value="1">Cash Coupon</option>
                        <option value="2">Discount Coupon</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  {this.state.coupon.couponValueType == "1" ? (
                    <>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="3">
                          Coupon Value (in Cash)
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            type="number"
                            min={0}
                            name="amount"
                            value={this.state.coupon.amount}
                            required
                            disabled={
                              this.state.coupon.couponValueType == "1"
                                ? false
                                : true
                            }
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </>
                  ) : (
                    <></>
                  )}

                  {this.state.coupon.couponValueType == "2" ? (
                    <>
                      <Form.Group as={Row} className="mb-1 " controlId="">
                        <Form.Label column sm="3">
                          Coupon Discount (in %)
                        </Form.Label>
                        <Col sm="9">
                          <Form.Control
                            type="number"
                            min={0}
                            name="discount"
                            value={this.state.coupon.discount}
                            disabled={
                              this.state.coupon.couponValueType == "2"
                                ? false
                                : true
                            }
                            placeholder=""
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Form.Group>
                    </>
                  ) : (
                    <></>
                  )}

                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Only to this MainCategory Product
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        size=""
                        name="mainCategoryId"
                        value={this.state.coupon.mainCategoryId}
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value="">All-Category</option>
                        {this.context.mainCategories.map((m) => (
                          <>
                            <option value={m.id}>{m.name}</option>
                          </>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>

                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Number of times to Use
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        min={0}
                        name="noOfTimesCanBeUsed"
                        value={this.state.coupon.noOfTimesCanBeUsed}
                        required
                        disabled={
                          this.state.coupon.couponPurpose == "3" ? false : true
                        }
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Coupon Reason
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        as="textarea"
                        type="textarea"
                        rows={2}
                        name="reason"
                        value={this.state.coupon.reason}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Created By
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        rows={2}
                        name="createdBy"
                        value={this.state.coupon.createdBy}
                        required
                        placeholder="Enter your Name/email-id"
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Expiry Date
                    </Form.Label>
                    <Col sm="9">
                      <DatePicker
                        format="dd-MMM-yy"
                        onChange={(e) => this.ExpiryDateChange(e)}
                        value={this.state.coupon.expiryDate}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1" controlId="">
                    <Form.Label column sm="3">
                      Coupon Status
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        name="couponStatus"
                        value={this.GetCouponStatus()}
                        disabled
                        required
                        placeholder=""
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value="true">Valid</option>
                        <option value="false">Expired Coupon</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Total Times Used
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        min={0}
                        name="totalTimesUsed"
                        value={this.state.coupon.totalTimesUsed}
                        required
                        disabled
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  Save
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
