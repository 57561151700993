import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import { CouponAddOrEditModal } from "./CouponAddOrEditModal";
import { BsFillPencilFill } from "react-icons/bs";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import {
  Nav,
  Stack,
  InputGroup,
  FormControl,
  Table,
  OverlayTrigger,
  Tooltip,
  Navbar,
  ListGroup,
} from "react-bootstrap";
import { Button, ButtonToolbar, Form, Row, Col } from "react-bootstrap";

export class Coupon extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      coupons: [],
      loadSpinnerOpen: true,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      reload: true,
    };
  }
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDeleted = (indexno) => {
    this.setState({ reload: false });
    const list = [...this.state.coupons];
    list.splice(indexno, 1);
    this.setState({ coupons: list }, () => {
      this.setState({ reload: true }, () => {
        //this.ShowMessagBox("Information", "Deleted Successfully!");
      });
    });
  };

  OnUpdated = (indexno) => {
    this.OnRefreshButtonClick();
  };

  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
  };

  OnRefreshButtonClick = () => {
    this.setState({ coupon: [] }, () => {
      this.GetCoupons();
      this.setState({ reload: false }, () => {
        this.setState({ reload: true });
      });
    });
  };

  GetCoupons = () => {
    const config = {
      headers: { Authorization: `Bearer ${this.context.userData.bearertoken}` },
    };
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetCoupons/" +
        this.context.storeId,
      config
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ coupons: data });
        }
      })
      .catch((error) => {
        this.ShowMessagBox("Warning", "Session Timeout!");
        this.context.clearUserData();
        this.setState({ sessionTimedout: true });
      });
  };

  fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 500));
  };
  componentDidMount() {
    if (this.state.coupons.length == 0) {
      this.GetCoupons();
    }
    this.fakeRequest().then(() => {
      this.setState({ loadSpinnerOpen: false }); // showing the app
    });
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {}
  render() {
    return (
      <>
        <div className="Container-fluid mx-0 px-0">
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}

          <Row>
            <Col className="" xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="pagebackground">
              <Navbar className="secondarythemecolor position-sticky fixed-top  my-0">
                <h5> &nbsp; &nbsp;COUPONS MANAGEMENT &nbsp;</h5>
                <Navbar.Collapse className="justify-content-end mx-1 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemecolor"
                        onClick={() => this.setState({ addModalShow: true })}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          height="30"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh
                          size="26"
                          className="mainthemecolor"
                        ></BiRefresh>
                        Refresh
                      </Button>
                      &nbsp;
                      {/* this logical check is required to deal with object destraction after close or submit the model */}
                      {this.state.addModalShow === true ? (
                        <CouponAddOrEditModal
                          show={this.state.addModalShow}
                          Type="Add"
                          onHide={this.addModalClose}
                        />
                      ) : null}
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <Row>
                <div className="text-black ">
                  <Table border striped className="px-0 bg-white ">
                    <thead className="">
                      <tr
                        className="text-center text-black bg-white border"
                        style={{ fontSize: "12px" }}
                      >
                        <th width="5" className="border"></th>
                        <th width="10%" className="border">
                          CreatedOn
                        </th>
                        <th className="text-start border" width="10%">
                          Coupon Code
                        </th>
                        <th width="10%" className="border">
                          Coupon Purpose
                        </th>
                        <th width="10%" className="border">
                          Coupon Value Type
                        </th>
                        <th width="10%" className="border">
                          Amount
                        </th>
                        <th width="10%" className="border">
                          Discount(%())
                        </th>
                        <th width="10%" className="border">
                          Total Times Can Be Used
                        </th>
                        <th width="10%" className="border">
                          Expiry Date
                        </th>
                        <th width="10%" className="border">
                          Coupon Status
                        </th>
                        <th width="10%" className="border">
                          Total Times Used
                        </th>
                        <th width="15%" className="text-center border">
                          Options
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-primary border">
                      {this.state.coupons
                        .sort((a, b) => (a.createdOn > b.createdOn ? 1 : -1))
                        .map((coupon, i) =>
                          this.state.reload === true ? (
                            <EditCouponInline
                              index={i}
                              coupon={coupon}
                              OnDeleted={this.OnDeleted}
                              OnUpdated={this.OnUpdated}
                            ></EditCouponInline>
                          ) : null
                        )}
                    </tbody>
                  </Table>
                </div>
              </Row>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export class EditCouponInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      coupon: this.props.coupon,
      editModalShow: false,
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
    };
  }
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDelete = () => {
    this.ShowLoadSpinner();

    const requestOptions = {
      method: "DELETE",
      headers: { Authorization: `Bearer ${this.context.userData.bearertoken}` },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteCoupon/" +
        this.state.coupon.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          this.ShowMessagBox("Error", "Delete Failed! try after sometime");
          return;
        }
        this.HideLoadSpinner();
        this.props.OnDeleted(this.props.index);
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Delete Failed! try after sometime");
      });
  };

  OnEditButtonClick = () => {
    this.setState({ editModalShow: true }, () => {});
  };

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {
      this.props.OnUpdated();
    });
  };

  GetCouponPurpose(value) {
    switch (value) {
      case 1:
        return "Refund";
      case 2:
        return "Reward";
      case 3:
        return "Promotional";
    }
  }
  GetCouponType(value) {
    switch (value) {
      case 1:
        return "Cash";
      case 2:
        return "Discount";
    }
  }
  GetCouponStatus(value) {
    switch (value) {
      case true: {
        var today = new Date();
        today.setHours(0, 0, 0, 0);
        var tomorrow = new Date(today.getTime() + 24 * 60 * 60 * 1000);
        var expiryDate = new Date(this.state.coupon.expiryDate);
        expiryDate.setHours(0, 0, 0, 0);

        if (expiryDate >= today) return "Valid";
        //if (new Date(this.state.coupon.expiryDate) >= today) return "Valid";
        else return "Expired";
      }
      case false:
        return "Expired";
    }
  }

  componentDidMount() {}

  render() {
    return (
      <>
        {this.state.editModalShow === true ? (
          <CouponAddOrEditModal
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            coupon={this.state.coupon}
            Type="Edit"
          />
        ) : (
          ""
        )}
        {this.state.msgboxOpen === true ? (
          <MessageBox
            open={this.state.msgboxOpen}
            title={this.state.msgboxTitle}
            message={this.state.msgboxMessage}
            callback={this.CloseMessagBoxCallback}
          />
        ) : (
          ""
        )}
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <tr
          className="text-center align-middle text-secondary bg-white mx-5"
          style={{ fontSize: "11px" }}
        >
          <td>{this.props.index + 1}</td>
          <td>
            {
              this.context.GetInDateFormat(
                new Date(this.state.coupon.createdOn)
              )
              // new Date(this.state.coupon.createdOn).getDate() +
              //   "-" +
              //   (new Date(this.state.coupon.createdOn).getMonth() + 1) +
              //   "-" +
              //   new Date(this.state.coupon.createdOn).getFullYear()
            }
          </td>
          <td> {this.state.coupon.couponCode}</td>
          <td> {this.GetCouponPurpose(this.state.coupon.couponPurpose)}</td>
          <td> {this.GetCouponType(this.state.coupon.couponValueType)}</td>
          <td> {this.state.coupon.amount}</td>
          <td> {this.state.coupon.discount}</td>
          <td> {this.state.coupon.noOfTimesCanBeUsed}</td>
          <td>
            {" "}
            {
              this.context.GetInDateFormat(
                new Date(this.state.coupon.expiryDate)
              )
              // new Date(this.state.coupon.expiryDate).getDate() +
              //   "-" +
              //   (new Date(this.state.coupon.expiryDate).getMonth() + 1) +
              //   "-" +
              //   new Date(this.state.coupon.expiryDate).getFullYear()
            }
          </td>
          <td> {this.GetCouponStatus(this.state.coupon.couponStatus)}</td>
          <td> {this.state.coupon.totalTimesUsed} </td>
          <td className="text-cener">
            <div class="btn-group">
              &nbsp;&nbsp;
              <OverlayTrigger overlay={<Tooltip>Edit!</Tooltip>}>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    this.OnEditButtonClick(e);
                  }}
                >
                  <BsFillPencilFill size="20" />
                </Button>
              </OverlayTrigger>
              &nbsp;&nbsp;&nbsp;
              <OverlayTrigger overlay={<Tooltip>Delete!</Tooltip>}>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    if (window.confirm("Are you sure you want to Delete ?")) {
                      this.OnDelete(e);
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>
            </div>
          </td>
        </tr>
      </>
    );
  }
}
