import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Offcanvas, Badge, Nav, Navbar, Button } from "react-bootstrap";

export default function MainMenu() {
  const [show, setShowMenuOffCanvas] = useState(false);
  const handleMenuOffCanvasClose = () => setShowMenuOffCanvas(false);
  const handleMenuOffCanvasShow = () => setShowMenuOffCanvas(true);

  const showDropdown = (e) => {
    e.show(true);
  };
  const hideDropdown = (e) => {
    e.show(false);
  };

  return (
    <>
      <Nav.Link className="navBarLink " onClick={handleMenuOffCanvasShow}>
        &nbsp;&nbsp;&nbsp;&nbsp;
        <img src="./images/menu3.png" width="28" height="28"></img>
        <span className="text-warning ">
          &nbsp;Menu&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </span>
      </Nav.Link>

      <Offcanvas
        show={show}
        onHide={handleMenuOffCanvasClose}
        className="offcanvasmenucustom"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="bg-primary text-dark">
          <Button className="w-100  text-white text-start my-1">Survey</Button>
          <br /> <br />
          <Button className="w-100  text-white text-start my-1">Users</Button>
          <Button className="w-100  text-white text-start my-1">Groups</Button>
          <br /> <br />
          <Button className="w-100  text-white text-start my-1">Images</Button>
          <Button className="w-100  text-white text-start my-1">Videos</Button>
          <Button className="w-100  text-white text-start my-1">
            Settings
          </Button>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}
