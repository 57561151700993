import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiRefresh } from "react-icons/bi";
import { BsFillPencilFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";

import {
  OverlayTrigger,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Modal,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export class Suppliers extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      suppliers: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      reload: true,
    };
  }
  GetSuppliers = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetSuppliers/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ suppliers: data }, () => {
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnSaved = () => {
    this.OnRefreshButtonClick();
  };
  OnDeleted = () => {
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.setState({ suppliers: [] }, () => {
      this.GetSuppliers();
    });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  OnUpdated = (indexno) => {
    this.OnRefreshButtonClick();
  };

  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
    this.OnRefreshButtonClick();
  };

  componentDidMount = () => {
    window.scrollTo(0, 0);
    if (this.state.suppliers.length == 0) this.GetSuppliers();
  };

  render() {
    return (
      <>
        <div className="Container-fluid p-1 ">
          <Row>
            {window.innerWidth > 1200 ? (
              <>
                <Col className="" xs={2}>
                  <SideMenu></SideMenu>
                </Col>
              </>
            ) : (
              <></>
            )}

            <Col
              xs={window.innerWidth > 1200 ? 10 : 12}
              className="pagebackground p-0"
            >
              {this.state.msgboxOpen === true ? (
                <MessageBox
                  open={this.state.msgboxOpen}
                  title={this.state.msgboxTitle}
                  message={this.state.msgboxMessage}
                  callback={this.CloseMessagBoxCallback}
                />
              ) : (
                ""
              )}
              <Navbar className="secondarythemecolor position-sticky fixed-top px-1 m-0 p-1 text-center border-3 border-dark">
                <h4> &nbsp; &nbsp;Suppliers &nbsp;</h4>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemecolor"
                        // onClick={() => this.handleAddSupplier()}
                        onClick={() => this.setState({ addModalShow: true })}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Refresh
                      </Button>
                      &nbsp;
                      {this.state.addModalShow === true ? (
                        <SupplierAddOrEditModal
                          show={this.state.addModalShow}
                          Type="Add"
                          onHide={this.addModalClose}
                        />
                      ) : null}
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div>
                <Table border striped className="px-0 bg-white ">
                  <thead className="">
                    <tr className="text-start text-black bg-white border">
                      <th>#</th>
                      {window.innerWidth > 1200 ? (
                        <>
                          <th width="20%">Supplier Name</th>
                          <th width="30%">Address</th>
                          <th width="10%">MobileNo</th>
                          <th width="10%">Email-Id</th>
                          <th width="20%" className="text-end">
                            Options
                          </th>
                        </>
                      ) : (
                        <>
                          <th width="50%">Supplier Name</th>
                          <th width="20%">MobileNo</th>
                          <th width="20%" className="text-end">
                            Options
                          </th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody className="text-primary border">
                    {this.state.suppliers
                      .sort((a, b) => (a.created > b.created ? 1 : -1))
                      .map((supplier, i) =>
                        this.state.reload === true ? (
                          <EditSupplierInline
                            index={i}
                            supplier={supplier}
                            OnDeleted={this.OnDeleted}
                          ></EditSupplierInline>
                        ) : null
                      )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export class EditSupplierInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      supplier: "",
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      editModalShow: false,
    };
  }

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDelete = () => {
    if (this.state.supplier.id == undefined) {
      this.ShowMessagBox(
        "Information",
        "This is invalid row which can't be deleted. Press Refresh!"
      );
      return;
    }

    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteSupplier/" +
        this.state.supplier.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert(
            "Not deleted. This Supplier may be associated with few Products. First disassociate this supplier from the Products and then try!"
          );
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Status",
          "Deleted Successfully. Click Refresh Button to see the changes!"
        );
        this.props.OnDeleted();
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("OnDelete Failed" + error);
      });
  };

  componentDidMount = () => {
    this.setState({ supplier: this.props.supplier });
  };
  OnEditButtonClick = () => {
    this.setState({ editModalShow: true }, () => {});
  };

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {});
  };

  render() {
    return (
      <>
        {this.state.editModalShow === true ? (
          <SupplierAddOrEditModal
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            supplier={this.state.supplier}
            Type="Edit"
          />
        ) : (
          ""
        )}
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <tr
          className="text-center align-middle text-secondary bg-white mx-5"
          style={{ fontSize: "12px" }}
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          <td className="text-start ">{this.props.index + 1}</td>
          <td className="text-start ">{this.state.supplier.name}</td>
          {window.innerWidth > 1200 ? (
            <>
              <td className="text-start ">
                {this.state.supplier.address +
                  ", " +
                  this.state.supplier.city +
                  ", " +
                  this.state.supplier.state +
                  ", " +
                  this.state.supplier.country +
                  ", " +
                  this.state.supplier.pinCode}
              </td>
            </>
          ) : (
            <></>
          )}

          <td className="text-start ">{this.state.supplier.mobileNo}</td>
          {window.innerWidth > 1200 ? (
            <>
              <td className="text-start ">{this.state.supplier.emailId}</td>
            </>
          ) : (
            <></>
          )}

          <td className="col-md-2 text-end align-middle ">
            <div class="btn-group">
              <OverlayTrigger overlay={<Tooltip>Edit!</Tooltip>}>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    this.OnEditButtonClick(e);
                  }}
                >
                  <BsFillPencilFill size="20" />
                </Button>
              </OverlayTrigger>

              <OverlayTrigger
                overlay={
                  <Tooltip>
                    Warning! This will delete this Brand Row and associated
                    Products as well.
                  </Tooltip>
                }
              >
                <Button
                  className="mx-1 mainthemecolor"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        "Warning! This action will delete this Brand Row and associated Products as well. Are you sure you want to Delete?"
                      )
                    ) {
                      this.OnDelete(e);
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </Button>
              </OverlayTrigger>
            </div>
          </td>
        </tr>
      </>
    );
  }
}

export class SupplierAddOrEditModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      supplier: {
        supplierName: "",
        manufacturerName: "",
        imgFileName: "",
        hideMe: false,
      },
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
    };
  }

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  AddOrUpdateSupplierInDB = () => {
    this.ShowLoadSpinner();

    var tmpSupplier = this.state.supplier;
    if (this.props.Type == "Edit") {
      tmpSupplier = this.props.supplier;
    } else {
      tmpSupplier.storeId = this.context.storeId;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmpSupplier),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/AddOrUpdateSupplier/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.HideLoadSpinner();
          this.props.onHide();
          return;
        }
        this.HideLoadSpinner();
        alert("This Supplier is already exist. try again!");
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("Exception. Received an Exception. Try after sometime !" + error);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.AddOrUpdateSupplierInDB();
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "name":
        this.state.supplier.name = value;
        break;
      case "address":
        this.state.supplier.address = value;
        break;
      case "city":
        this.state.supplier.city = value;
        break;
      case "state":
        this.state.supplier.state = value;
        break;
      case "country":
        this.state.supplier.country = value;
        break;
      case "pinCode":
        this.state.supplier.pinCode = value;
        break;
      case "mobileNo":
        this.state.supplier.mobileNo = value;
        break;
      case "emailId":
        this.state.supplier.emailId = value;
        break;
    }
    this.setState({ supplier: this.state.supplier });
  };

  componentDidMount() {
    if (this.props.Type == "Edit") {
      this.setState({ supplier: this.props.supplier });
    }
  }

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          dialogClassName="SupplierModal"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit" ? "Edit supplier" : "Add supplier"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Row
                className="m-2 p-2 bg-light border"
                style={{ fontSize: "9pt" }}
              >
                <Col md={12}>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Supplier Name
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="name"
                        maxlength="99"
                        value={this.state.supplier.name}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Address
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="address"
                        maxlength="99"
                        value={this.state.supplier.address}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      City
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="city"
                        maxlength="99"
                        value={this.state.supplier.city}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      State
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="state"
                        maxlength="99"
                        value={this.state.supplier.state}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Country
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="country"
                        maxlength="99"
                        value={this.state.supplier.country}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      PinCode
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="pinCode"
                        maxlength="99"
                        value={this.state.supplier.pinCode}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Mobile No
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="mobileNo"
                        maxlength="99"
                        value={this.state.supplier.mobileNo}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      EmailId
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="emailId"
                        maxlength="99"
                        value={this.state.supplier.emailId}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  Save
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
