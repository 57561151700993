import React, { Component } from "react";
import { useState } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import Fuse from "fuse.js";
import { BsFillPencilFill } from "react-icons/bs";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import {
  Nav,
  Stack,
  FormControl,
  InputGroup,
  Table,
  OverlayTrigger,
  Tooltip,
  Navbar,
} from "react-bootstrap";
import { Button, ButtonToolbar, Form, Row, Col } from "react-bootstrap";

export class OutOfStock extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      prods: [],
      selectedMainCategoryId: "1",
      selectedMainCategoryName: "All",
      selectedSubCategoryId: "0",
      selectedSubCategoryName: "All",
      filteredProducts: [],
      filteredProductsCopy: [],
      searchData: [],
      searchquery: "",
      reload: true,
      instockRequired: 10,
    };
  }
  onMainCategorySelectChangeEvent = (e) => {
    // this.setState({ selectedMainCategoryId: e.target.value }, () => {
    //   this.setState({ selectedSubCategoryId: "0" }, () =>
    //     this.OnSubCategoryIDChanges()
    //   );
    // });
    this.setState({ reload: false });
    this.setState({ selectedMainCategoryId: e.target.value }, () => {
      this.setState({ selectedSubCategoryId: "0" }, () =>
        this.OnSubCategoryIDChanges()
      );
    });
  };
  OnSubCategoryIDChanges = () => {
    this.onCategoryChanges();
  };

  onSubCategorySelectChangeEvent = (e) => {
    this.setState({ reload: false });
    this.setState({ selectedSubCategoryId: e.target.value }, () =>
      this.onCategoryChanges()
    );
  };
  onFilteredProductsChanged = () => {
    this.setState({ filteredProductsCopy: this.state.filteredProducts }, () => {
      this.setState({ reload: true });
      console.log("products reloaded");
    });
  };
  onCategoryChanges = () => {
    console.log("Category changes; prod length=" + this.context.prods.length);

    //when main and sub are "All"
    if (
      this.state.selectedMainCategoryId === "0" &&
      this.state.selectedSubCategoryId === "0"
    ) {
      this.setState(
        {
          filteredProducts: JSON.parse(JSON.stringify([...this.context.prods])), //deep clone
        },
        () => this.onFilteredProductsChanged()
      );
    }
    //when Main is not "ALL" but sub is "All"
    else if (
      this.state.selectedMainCategoryId !== "0" &&
      this.state.selectedSubCategoryId === "0"
    ) {
      this.setState(
        {
          filteredProducts: JSON.parse(
            JSON.stringify(
              this.context.prods.filter((prod) => {
                return (
                  prod.subCategory.mainCategoryId ===
                  this.state.selectedMainCategoryId
                );
              })
            )
          ),
        },
        () => this.onFilteredProductsChanged()
      );
    }
    //when both Main and Sub are not "All"
    else {
      this.setState(
        {
          filteredProducts: JSON.parse(
            JSON.stringify(
              this.context.prods.filter((prod) => {
                return (
                  prod.subCategory.mainCategoryId ===
                    this.state.selectedMainCategoryId &&
                  prod.subCategory.id === this.state.selectedSubCategoryId
                );
              })
            )
          ),
        },
        () => this.onFilteredProductsChanged()
      );
    }
  };

  onSearchChange = (query) => {
    this.setState({ reload: false });
    this.setState({ searchquery: query });
    if (!query) {
      //this.setState({ searchData: [] });
      this.setState(
        { filteredProducts: this.state.filteredProductsCopy },
        () => {
          this.setState({ reload: true });
        }
      );
      return;
    }
    const fuse = new Fuse(this.state.filteredProducts, {
      keys: ["name"],
      threshold: 0.6,
    });
    const result = fuse.search(query);
    const finalResult = [];
    if (result.length) {
      result.forEach((item) => {
        finalResult.push(item.item);
      });
      this.setState({ filteredProducts: finalResult.slice(0, 15) }, () => {
        this.setState({ reload: true });
      });
    } else {
      this.setState({ filteredProducts: [] });
    }
  };
  OnRefreshButtonClick = () => {
    this.setState({ reload: false }, () => {
      this.onCategoryChanges();
    });
  };
  useForceUpdate = () => {
    const [value, setValue] = useState(0); // integer state
    return () =>
      setValue(
        (value) => value + 1,
        () => {}
      ); // update the state to force render
  };

  IsMultiVariantProductOutOfStock = (prod) => {
    var result = prod.productCustomFields.filter(
      (f) => f.inStockQty <= this.state.instockRequired
    );
    if (result.length > 0) return true;
    else return false;
  };

  OnInstockRequiredChanged = (e) => {
    if (e.target.value < 0) return;
    this.setState({ instockRequired: e.target.value });
  };
  componentDidMount() {
    this.setState(
      {
        selectedMainCategoryId:
          this.context.mainCategories.length > 0
            ? this.context.mainCategories[0].id
            : "0",
      },
      () => {
        this.onCategoryChanges();
      }
    );
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {}

  render() {
    return (
      <>
        <div className="Container-fluid p-1 pagebackground">
          <Row>
            {window.innerWidth >= 1200 ? (
              <>
                <Col className=" " xs={2}>
                  <SideMenu></SideMenu>
                </Col>
              </>
            ) : (
              ""
            )}
            <Col xs={window.innerWidth >= 1200 ? 10 : 12} className="p-0">
              <div className="Container-fluid mx-0 px-0 text-white ">
                <Navbar className="secondarythemecolor position-sticky fixed-top px-3">
                  <h5>
                    <b>OUT-OF-STOCKs</b> &nbsp;
                  </h5>
                  {/* <Navbar.Collapse className="justify-content-end mx-1 w-25">
                    <Nav></Nav>
                  </Navbar.Collapse> */}
                  {/* <Navbar.Collapse className="justify-content-end mx-1 ">
                    <Nav>
                      <ButtonToolbar className="text-end align-middle">
                        &nbsp;
                        <Button
                          className="mainthemecolor"
                          onClick={() => this.OnRefreshButtonClick()}
                        >
                          <BiRefresh size="26"></BiRefresh>
                          Refresh
                        </Button>
                        &nbsp;
                      </ButtonToolbar>
                    </Nav>
                  </Navbar.Collapse> */}
                </Navbar>
                <div className=" py-0 my-3">
                  {window.innerWidth >= 1200 ? (
                    <>
                      <Table className="w-100" size="sm">
                        <Row className="">
                          <Col className="">
                            <InputGroup>
                              <InputGroup.Text
                                id="basic-addon1"
                                className="border-0 text-secondary"
                              >
                                Minimum Required In-Stock quantity:
                              </InputGroup.Text>
                              <Form.Control
                                type="number"
                                min={0}
                                name="instockqty"
                                value={this.state.instockRequired}
                                required
                                className="text-center px-0"
                                placeholder=""
                                autoComplete="off"
                                onChange={(e) =>
                                  this.OnInstockRequiredChanged(e)
                                }
                              />
                            </InputGroup>
                          </Col>
                          <Col>
                            <InputGroup>
                              <InputGroup.Text id="basic-addon1">
                                Main Category
                              </InputGroup.Text>
                              <Form.Select
                                value={this.state.selectedMainCategoryId}
                                onChange={(e) => {
                                  this.onMainCategorySelectChangeEvent(e);
                                }}
                              >
                                <option value={0}>ALL</option>
                                {this.context.mainCategories.map(
                                  (maincategory) => (
                                    <option value={maincategory.id}>
                                      {maincategory.name}
                                    </option>
                                  )
                                )}
                              </Form.Select>
                            </InputGroup>
                            <Form.Group controlId="mainCategory"></Form.Group>
                          </Col>
                          <Col>
                            <InputGroup>
                              <InputGroup.Text id="basic-addon1">
                                Sub Category
                              </InputGroup.Text>
                              <Form.Select
                                value={this.state.selectedSubCategoryId}
                                onChange={(e) => {
                                  this.onSubCategorySelectChangeEvent(e);
                                }}
                              >
                                <option value={0}>All</option>
                                {this.context.subCategories
                                  .filter(
                                    (subcategory) =>
                                      subcategory.mainCategoryId ===
                                      this.state.selectedMainCategoryId
                                  )
                                  .map((subcategory) => (
                                    <option value={subcategory.id}>
                                      {subcategory.name}
                                    </option>
                                  ))}
                              </Form.Select>
                            </InputGroup>
                          </Col>
                        </Row>
                      </Table>
                    </>
                  ) : (
                    <>
                      <div className="border">
                        <Table className="" size="sm" borderless>
                          <div className="text-center">
                            <h3>Filters</h3>
                          </div>
                          <Row className="">
                            <InputGroup className="mb-1">
                              <InputGroup.Text
                                id="basic-addon1"
                                className="border-0 text-secondary"
                              >
                                Minimum Required In-Stock quantity:
                              </InputGroup.Text>
                              <Form.Control
                                type="number"
                                min={0}
                                name="instockqty"
                                value={this.state.instockRequired}
                                required
                                className="text-center instockqtyrequiredwidth px-0"
                                placeholder=""
                                autoComplete="off"
                                onChange={(e) =>
                                  this.OnInstockRequiredChanged(e)
                                }
                              />
                            </InputGroup>
                          </Row>
                          <Row>
                            <Col>
                              <InputGroup className="mb-1">
                                <InputGroup.Text id="basic-addon1">
                                  Main Category
                                </InputGroup.Text>
                                <Form.Select
                                  value={this.state.selectedMainCategoryId}
                                  onChange={(e) => {
                                    this.onMainCategorySelectChangeEvent(e);
                                  }}
                                >
                                  <option value={0}>ALL</option>
                                  {this.context.mainCategories.map(
                                    (maincategory) => (
                                      <option value={maincategory.id}>
                                        {maincategory.name}
                                      </option>
                                    )
                                  )}
                                </Form.Select>
                              </InputGroup>
                              <Form.Group controlId="mainCategory"></Form.Group>
                            </Col>
                          </Row>
                          <Row>
                            <Col>
                              <InputGroup className="mb-1">
                                <InputGroup.Text id="basic-addon1">
                                  Sub Category
                                </InputGroup.Text>
                                <Form.Select
                                  value={this.state.selectedSubCategoryId}
                                  onChange={(e) => {
                                    this.onSubCategorySelectChangeEvent(e);
                                  }}
                                >
                                  <option value={0}>All</option>
                                  {this.context.subCategories
                                    .filter(
                                      (subcategory) =>
                                        subcategory.mainCategoryId ===
                                        this.state.selectedMainCategoryId
                                    )
                                    .map((subcategory) => (
                                      <option value={subcategory.id}>
                                        {subcategory.name}
                                      </option>
                                    ))}
                                </Form.Select>
                              </InputGroup>
                            </Col>
                          </Row>
                        </Table>
                      </div>
                    </>
                  )}

                  <Table className="p-0 m-0" size="sm">
                    <Row className="">
                      <Col>
                        <Stack direction="horizontal" gap={3} className="">
                          <b>
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              fill="currentColor"
                              class="bi bi-search"
                              viewBox="0 0 16 16"
                            >
                              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                            </svg>
                          </b>
                          <FormControl
                            type="search"
                            placeholder="Search... "
                            className="text-primary fw-bold bg-white "
                            size="lg"
                            aria-label="Search"
                            onChange={(e) =>
                              this.onSearchChange(e.target.value)
                            }
                          />
                        </Stack>
                      </Col>
                    </Row>
                  </Table>
                </div>
                <br></br>
                <div className="text-center w-100 text-black">
                  <h3>Results</h3>
                </div>
                <Table border striped className=" px-0">
                  {window.innerWidth >= 1200 ? (
                    <>
                      <thead className="text-center">
                        <tr className="text-center text-black bg-white border">
                          <th className="text-center" width="20%">
                            Name
                          </th>
                          <th className="text-center">ProductVariant</th>
                          <th width="15%">Category</th>
                          <th width="15%">Price</th>
                          <th width="10%">Discount(%)</th>
                          <th width="10%">S.Price</th>
                          <th width="10%">In-Stock</th>
                        </tr>
                      </thead>
                    </>
                  ) : (
                    <>
                      <thead className="text-center">
                        <tr className="text-center text-black bg-white border">
                          <th></th>
                          <th className="text-center">Name</th>
                          <th className="text-center">ProductVariant</th>
                          <th>Price</th>
                          <th>Disc %</th>
                          <th>S.Price</th>
                          <th>In-Stock</th>
                        </tr>
                      </thead>
                    </>
                  )}

                  <tbody className="text-primary border">
                    {this.state.filteredProducts.map((prod, i) =>
                      this.state.reload === true &&
                      ((prod.productVariantType == 1 &&
                        prod.inStockQty <=
                          Number(this.state.instockRequired)) ||
                        (prod.productVariantType == 2 &&
                          this.IsMultiVariantProductOutOfStock(prod))) ? (
                        <ShowOutOfStockProductsInline
                          index={i}
                          prod={prod}
                        ></ShowOutOfStockProductsInline>
                      ) : null
                    )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export class ShowOutOfStockProductsInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      prod: this.props.prod,
    };
  }

  componentDidMount() {}

  getMainCategoryId = (subCategoryId) => {
    var mainCategoryId = "";
    return this.context.subCategories
      .filter((e) => e.id === subCategoryId)
      .map((t) => (mainCategoryId = t.mainCategoryId));
    return mainCategoryId;
  };

  render() {
    return (
      <>
        {window.innerWidth >= 1200 ? (
          <>
            <tr
              className="text-center align-middle text-secondary bg-white mx-5"
              style={{ fontSize: "12px" }}
            >
              <td className="col-md-3 text-center ">
                {this.state.prod.productImages.length > 0 ? (
                  <>
                    <img
                      src={
                        this.context.store.storageBlobUrl +
                        this.context.store.storageBlobContainerName +
                        "/images/" +
                        this.state.prod.productImages[0].fileName +
                        this.context.store.storageSasToken
                      }
                      className="productimg-on-productlist mx-auto"
                      alt="..."
                    />
                    <br></br>
                  </>
                ) : (
                  <></>
                )}
                &nbsp;{this.state.prod.name}
              </td>
              <td>
                {this.state.prod.productVariantType == 1
                  ? "Single"
                  : "Multiple"}
              </td>
              <td>
                {this.state.prod.subCategory.name}
                <br></br>[
                {this.context.getMainCategoryName(
                  this.state.prod.subCategory.mainCategoryId
                )}
                ]
              </td>
              {this.state.prod.productVariantType == 2 ? (
                <>
                  <td colSpan={4}></td>
                </>
              ) : (
                <>
                  <td className="text-center align-middle">
                    {Number(this.state.prod.mrp).toLocaleString(
                      this.context.storeSettings.defaultLocale,
                      {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 1,
                        style: "currency",
                        currency: this.context.storeSettings.defaultCurrency,
                      }
                    )}
                  </td>
                  <td>{this.state.prod.discount}</td>
                  <td>
                    <h6 className="fw-bold text-dark">
                      {Math.round(
                        this.state.prod.mrp -
                          (this.state.prod.mrp * this.state.prod.discount) /
                            100,
                        0
                      ).toLocaleString(
                        this.context.storeSettings.defaultLocale,
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1,
                          style: "currency",
                          currency: this.context.storeSettings.defaultCurrency,
                        }
                      )}
                    </h6>
                  </td>
                  <td>{this.state.prod.inStockQty}</td>
                </>
              )}
            </tr>
          </>
        ) : (
          <>
            <tr
              className="text-center align-middle text-secondary bg-white mx-5"
              style={{ fontSize: "12px" }}
            >
              <td>{this.props.index + 1}</td>
              <td className="col-md-3 text-center ">
                <img
                  src={
                    this.context.store.storageBlobUrl +
                    this.context.store.storageBlobContainerName +
                    "/images/" +
                    this.state.prod.productImages[0].fileName +
                    this.context.store.storageSasToken
                  }
                  className="productimg-on-productlist mx-auto"
                  alt="..."
                />
                <br></br>
                &nbsp;{this.state.prod.name}
              </td>
              <td>
                {this.state.prod.productVariantType == 1
                  ? "Single"
                  : "Multiple"}
              </td>
              <td className="text-center align-middle">
                {this.state.prod.mrp}
              </td>
              <td>{this.state.prod.discount}</td>
              <td>
                <h6 className="fw-bold text-dark">
                  {Math.round(
                    this.state.prod.mrp -
                      (this.state.prod.mrp * this.state.prod.discount) / 100,
                    0
                  )}
                </h6>
              </td>
              <td>{this.state.prod.inStockQty}</td>
            </tr>
          </>
        )}
      </>
    );
  }
}
