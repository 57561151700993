import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiRefresh } from "react-icons/bi";
import { BsFillPencilFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import Select from "react-select";
import { ProductServiceCustomerAddOrEditModal } from "./ProductServiceCustomers";
import DateTimePicker from "react-datetime-picker";
import "../CSS/DateTimePicker.css";
import "../CSS/Calendar.css";
import "../CSS/Clock.css";
import { ProductServiceTasks } from "./ProductServiceTasks";
import { ProductServiceRawMaterials } from "./ProductServiceRawmaterials";
import { ProductServicingAddOrEditModal } from "./ProductServicingAddOrEditModal";

import {
  Tabs,
  Stack,
  OverlayTrigger,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Modal,
  Nav,
  Table,
  Navbar,
  Tab,
} from "react-bootstrap";

export class ProductServiceRequests extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      productservicerequests: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      reload: true,
      serviceTasksInventory: [],
      rawMaterialsInventory: [],
      firstload: false,
      users: [],
      key: "NewRequest",
      addModalShow: false,
    };
  }
  GetUsers = () => {
    const config = {
      headers: { Authorization: `Bearer ${this.context.userData.bearertoken}` },
    };
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetStoreUserAccounts/" +
        this.context.storeId,
      config
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({
            //users: data.filter((f) => f.storeRoleId != 1),
            users: data,
          });
        }
      })
      .catch((error) => {
        alert("Session Timeout! Signout and Signin again!");
      });
  };
  GetRawMaterialsInventories = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetRawMaterialsInventories/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ rawMaterialsInventory: data }, () => {
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  GetProductServiceTasksInventories = () => {
    fetch(
      process.env.REACT_APP_API +
        "ManufactureAndService/GetProductServiceTasksInventories/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({
            productservicetasksinventoryFiltered: data,
          });
          this.setState({ serviceTasksInventory: data }, () => {
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  GetProductServiceRequests = () => {
    fetch(
      process.env.REACT_APP_API +
        "ManufactureAndService/GetProductServiceRequests/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ productservicerequests: data }, () => {
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnSaved = () => {
    this.OnRefreshButtonClick();
  };
  OnDeleted = () => {
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.setState({ productservicerequests: [] }, () => {
      this.GetProductServiceRequests();
    });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  OnUpdated = (indexno) => {
    this.OnRefreshButtonClick();
  };

  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
    this.OnRefreshButtonClick();
  };

  componentDidMount = () => {
    if (this.state.firstload == false) {
      this.state.firstload = true;
      this.GetProductServiceRequests();
      this.GetRawMaterialsInventories();
      this.GetProductServiceTasksInventories();
      this.GetUsers();
    }
    window.scrollTo(0, 0);
    console.log("ProductServiceRequests mounted");
  };
  componentWillUnmount = () => {
    console.log("ProductServiceRequests unmounted");
  };

  render() {
    return (
      <>
        <div className="Container-fluid p-1 ">
          <Row>
            <Col className="" xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="pagebackground p-0">
              {this.state.msgboxOpen === true ? (
                <MessageBox
                  open={this.state.msgboxOpen}
                  title={this.state.msgboxTitle}
                  message={this.state.msgboxMessage}
                  callback={this.CloseMessagBoxCallback}
                />
              ) : (
                ""
              )}
              <Navbar
                expand="lg"
                className="secondarythemecolor position-sticky fixed-top  px-3"
              >
                <h5>
                  {" "}
                  &nbsp; &nbsp;<b>Product Servicing Request (PSR)</b>&nbsp;
                </h5>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemecolor"
                        // onClick={() => this.handleAddSupplier()}
                        onClick={() => this.setState({ addModalShow: true })}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Refresh
                      </Button>
                      &nbsp;
                      {this.state.addModalShow === true ? (
                        <ProductServicingAddOrEditModal
                          show={this.state.addModalShow}
                          Type="Add"
                          onHide={this.addModalClose}
                          rawMaterialsInventory={
                            this.state.rawMaterialsInventory
                          }
                          serviceTasksInventory={
                            this.state.serviceTasksInventory
                          }
                          users={this.state.users}
                          serviceStatus={this.props.serviceStatus}
                        />
                      ) : null}
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <div className="p-3 m-3 border-0 border-secondary bg-light">
                <Tabs
                  id="controlled-tab-example"
                  activeKey={this.state.key}
                  onSelect={(k) => this.setState({ key: k })}
                  className="bg-light"
                  variant="tabs"
                  fill
                  // Transition={true}
                  style={{ fontSize: "18px" }}
                >
                  <Tab eventKey="NewRequest" title="1.New Request" className="">
                    <div className="text-center">
                      <h4>
                        <b>1. New Requests</b>
                      </h4>
                    </div>
                    <ProductServiceList
                      productservicerequests={this.state.productservicerequests}
                      reload={this.state.reload}
                      OnDeleted={this.OnDeleted}
                      users={this.state.users}
                      rawMaterialsInventory={this.state.rawMaterialsInventory}
                      serviceTasksInventory={this.state.serviceTasksInventory}
                      serviceStatus="1"
                      Refresh={this.OnRefreshButtonClick}
                    ></ProductServiceList>
                  </Tab>
                  <Tab eventKey="Servicing" title="2.Servicing">
                    <div className="text-center">
                      <h4>
                        <b>2. Service-In-Progress</b>
                      </h4>
                    </div>
                    <ProductServiceList
                      productservicerequests={this.state.productservicerequests}
                      reload={this.state.reload}
                      OnDeleted={this.OnDeleted}
                      users={this.state.users}
                      rawMaterialsInventory={this.state.rawMaterialsInventory}
                      serviceTasksInventory={this.state.serviceTasksInventory}
                      serviceStatus="2"
                      Refresh={this.OnRefreshButtonClick}
                    ></ProductServiceList>
                  </Tab>
                  <Tab eventKey="QualityCheck" title="3.QualityCheck">
                    <div className="text-center">
                      <h4>
                        <b>3. Quality Check</b>
                      </h4>
                    </div>
                    <ProductServiceList
                      productservicerequests={this.state.productservicerequests}
                      reload={this.state.reload}
                      OnDeleted={this.OnDeleted}
                      users={this.state.users}
                      rawMaterialsInventory={this.state.rawMaterialsInventory}
                      serviceTasksInventory={this.state.serviceTasksInventory}
                      serviceStatus="3"
                      Refresh={this.OnRefreshButtonClick}
                    ></ProductServiceList>
                  </Tab>
                  <Tab eventKey="Invoice" title="4.Invoice/Payment">
                    <div className="text-center">
                      <h4>
                        <b>4. Invoice & Payment</b>
                      </h4>
                    </div>
                    <ProductServiceList
                      productservicerequests={this.state.productservicerequests}
                      reload={this.state.reload}
                      OnDeleted={this.OnDeleted}
                      users={this.state.users}
                      rawMaterialsInventory={this.state.rawMaterialsInventory}
                      serviceTasksInventory={this.state.serviceTasksInventory}
                      serviceStatus="4"
                      Refresh={this.OnRefreshButtonClick}
                    ></ProductServiceList>
                  </Tab>
                  <Tab eventKey="Delivery" title="5.Delivery">
                    <div className="text-center">
                      <h4>
                        <b>5. Delivery</b>
                      </h4>
                    </div>
                    <ProductServiceList
                      productservicerequests={this.state.productservicerequests}
                      reload={this.state.reload}
                      OnDeleted={this.OnDeleted}
                      users={this.state.users}
                      rawMaterialsInventory={this.state.rawMaterialsInventory}
                      serviceTasksInventory={this.state.serviceTasksInventory}
                      serviceStatus="5"
                      Refresh={this.OnRefreshButtonClick}
                    ></ProductServiceList>
                  </Tab>
                  <Tab eventKey="Feedback" title="6.Feedback">
                    <div className="text-center">
                      <h4>
                        <b>6. Customer Feedback</b>
                      </h4>
                    </div>
                    <ProductServiceList
                      productservicerequests={this.state.productservicerequests}
                      reload={this.state.reload}
                      OnDeleted={this.OnDeleted}
                      users={this.state.users}
                      rawMaterialsInventory={this.state.rawMaterialsInventory}
                      serviceTasksInventory={this.state.serviceTasksInventory}
                      serviceStatus="6"
                      Refresh={this.OnRefreshButtonClick}
                    ></ProductServiceList>
                  </Tab>
                  <Tab eventKey="History" title="7.History">
                    <div className="text-center">
                      <h4>
                        <b>7. History</b>
                      </h4>
                    </div>
                    <ProductServiceList
                      productservicerequests={this.state.productservicerequests}
                      reload={this.state.reload}
                      OnDeleted={this.OnDeleted}
                      users={this.state.users}
                      rawMaterialsInventory={this.state.rawMaterialsInventory}
                      serviceTasksInventory={this.state.serviceTasksInventory}
                      serviceStatus="History"
                      Refresh={this.OnRefreshButtonClick}
                    ></ProductServiceList>
                  </Tab>
                </Tabs>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
class ProductServiceList extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    console.log("ProductServiceList mounted");
  };
  componentWillUnmount = () => {
    console.log("ProductServiceList unmounted");
  };
  render() {
    return (
      <>
        <Table border striped className="px-0 bg-white ">
          <thead className="">
            <tr className="text-start text-black bg-white border">
              <th></th>
              <th width="10%">Created</th>
              <th width="10%">PSR No</th>
              <th width="20%">ProductName</th>
              <th width="10%">ServiceStart</th>
              <th width="10%">DeliveryDate</th>
              <th width="10%">Status</th>
              <th width="10%">Created By</th>
              <th width="10%">Assigned To</th>
              <th width="10%" className="text-end">
                Options
              </th>
            </tr>
          </thead>
          <tbody className="text-primary border">
            {this.props.productservicerequests
              .filter((f) => {
                if (f.status == this.props.serviceStatus) return true;
                else if (
                  (f.status == 7 || f.status == 8) &&
                  this.props.serviceStatus == "History"
                )
                  return true;
              })
              .sort((a, b) => (a.created > b.created ? 1 : -1))
              .map((request, i) =>
                this.props.reload === true ? (
                  <EditProductServicingInline
                    index={i}
                    productservicingRequest={request}
                    OnDeleted={this.props.OnDeleted}
                    users={this.props.users}
                    rawMaterialsInventory={this.props.rawMaterialsInventory}
                    serviceTasksInventory={this.props.serviceTasksInventory}
                    serviceStatus={this.props.serviceStatus}
                    Refresh={this.props.Refresh}
                  ></EditProductServicingInline>
                ) : null
              )}
          </tbody>
        </Table>
      </>
    );
  }
}
export class EditProductServicingInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      editModalShow: false,
    };
  }

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnEditButtonClick = () => {
    this.setState({ editModalShow: true }, () => {});
  };

  OnDelete = () => {};

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {});
    this.props.Refresh();
  };

  GetStatusNameById = (id) => {
    var result = this.context.productServiceStatusTypes.filter(
      (f) => f.id == id
    );
    if (result.length > 0) return result[0].name;
    else return "";
  };
  GetUserNameById = (id) => {
    var result = this.props.users.filter((f) => f.id == id);
    if (result.length > 0) return result[0].emailId;
    else return "";
  };
  componentDidMount = () => {
    console.log("EditProductServicingInline mounted");
  };
  componentWillUnmount = () => {
    console.log("EditProductServicingInline unmounted");
  };

  render() {
    return (
      <>
        {this.state.editModalShow === true ? (
          <ProductServicingAddOrEditModal
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            productservicingRequest={this.props.productservicingRequest}
            Type="Edit"
            users={this.props.users}
            rawMaterialsInventory={this.props.rawMaterialsInventory}
            serviceTasksInventory={this.props.serviceTasksInventory}
            serviceStatus={this.props.serviceStatus}
          />
        ) : (
          ""
        )}
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <tr
          className="text-start align-middle text-secondary bg-white mx-5"
          style={{ fontSize: "12px" }}
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          <td>{this.props.index + 1}</td>
          <td className="text-start ">
            {this.context.GetInDateFormat(
              new Date(this.props.productservicingRequest.created)
            )}
          </td>
          <td className="text-start ">
            <b>{this.props.productservicingRequest.psrNo}</b>
          </td>
          <td className="text-start ">
            {/* {this.GetProductNameById(
              this.props.productservicingRequest.productId
            )} */}
            {this.props.productservicingRequest.productName}
          </td>
          <td className="text-start ">
            {this.context.GetInDateFormat(
              new Date(this.props.productservicingRequest.serviceStart)
            )}
          </td>
          <td>
            {this.context.GetInDateFormat(
              new Date(this.props.productservicingRequest.plannedDeliveryDate)
            )}
          </td>
          <td className="text-start ">
            {this.GetStatusNameById(this.props.productservicingRequest.status)}
          </td>
          <td className="text-start ">
            {this.GetUserNameById(this.props.productservicingRequest.createdBy)}
          </td>
          <td className="text-start ">
            {this.GetUserNameById(
              this.props.productservicingRequest.assignedTo
            )}
          </td>
          <td className="col-md-2 text-end align-middle ">
            <div class="btn-group">
              <OverlayTrigger overlay={<Tooltip>Edit!</Tooltip>}>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    this.OnEditButtonClick(e);
                  }}
                >
                  <BsFillPencilFill size="16" />
                </Button>
              </OverlayTrigger>
              {/* <OverlayTrigger
                overlay={
                  <Tooltip>
                    Warning! This will delete this Brand Row and associated
                    Products as well.
                  </Tooltip>
                }
              >
                <Button
                  className="mx-1 mainthemecolor"
                  onClick={(e) => {
                    if (
                      window.confirm(
                        "Warning! This action will delete this Brand Row and associated Products as well. Are you sure you want to Delete?"
                      )
                    ) {
                      this.OnDelete(e);
                    }
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="25"
                    height="25"
                    fill="currentColor"
                    class="bi bi-trash"
                    viewBox="0 0 16 16"
                  >
                    <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                    <path
                      fill-rule="evenodd"
                      d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                    />
                  </svg>
                </Button>
              </OverlayTrigger> */}
            </div>
          </td>
        </tr>
      </>
    );
  }
}
