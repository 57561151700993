import React, { Component, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Audio, Watch, ThreeDots } from "react-loader-spinner";
import CartContext from "./CartContext";

export class LoadSpinner extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      open: this.props.open,
      callback: this.props.callback,
    };
  }
  customstyle = {
    content: {
      ...Modal,
    },
    overlay: { zIndex: 1000 },
  };

  handleClose = () => {
    this.setState({ open: false });
    this.props.callback();
  };
  render() {
    return (
      <>
        <Modal
          size="sm"
          width="10pt"
          className="spinnermodal-backdrop spinnermodalcenter "
          show={this.state.open}
          keyboard={false}
          // backdropClassName="opacity-100"
          style={{ opacity: ".9" }}
        >
          <Modal.Body
            className="loadspinnermodalalign "

            // style={{
            //   backgroundColor:
            //     this.context.theme == ""
            //       ? "bg-white"
            //       : this.context.theme.mainNavBarBackgroundColor,

            //   color: this.context.theme.mainNavBarTextColor,
            // }}
          >
            <div className="text-center w-100 ">
              {this.context.store == "" ? (
                ""
              ) : (
                <>
                  <img
                    src={
                      this.context.store.storageBlobUrl +
                      this.context.store.storageBlobContainerName +
                      "/images/" +
                      this.context.storeSettings.storeLogoFileName +
                      this.context.store.storageSasToken
                    }
                    className={
                      "logohover m-0 p-0 rounded-pill loadspinnermodalalign "
                    }
                    style={{
                      cursor: "pointer",
                      color: this.context.theme.mainNavBarTextColor,
                      width:
                        this.context.theme.storeLogoImageWidth == undefined
                          ? "2cm"
                          : this.context.theme.storeLogoImageWidth + "cm",
                      backgroundColor: "transparant",
                      filter: "invert(" + this.context.theme.invertLogo + "%)",
                    }}
                  />
                  <br />
                </>
              )}

              <div className="loadspinnermodalalign">
                <Watch
                  height="80"
                  width="350"
                  radius="48"
                  color="#4fa94d"
                  ariaLabel="watch-loading"
                  wrapperStyle={{}}
                  wrapperClassName=""
                  visible={true}
                />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}
