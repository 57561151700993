import React, { Component } from "react";
import { SideMenu } from "./SideMenu";
import { useState } from "react";
import CartContext from "./CartContext";
import { OrderDetails } from "./OrderDetails";
import { OrderDetailsMobile } from "./OrderDetailsMobile";
import Fuse from "fuse.js";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import axios from "axios";
import Swal from "sweetalert2";
import { Navigate } from "react-router-dom";

import {
  InputGroup,
  Stack,
  FormControl,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export class OrderHistory extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      ordersDetail: [],
      reload: true,
      filteredOrdersDetail: [],
      showOrderDetailsModal: false,
      dateRange: "Today",
      searchquery: "",
      filterUserTypeName: "",
      filterUserTypeNames: [],
      filterPaymentModeName: "",
      filterPaymentModeNames: [],
      firstLoad: false,
    };
  }
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  setOrders = (data) => {
    this.setState({ ordersDetail: [] }, () => {
      this.setState({ filteredOrdersDetail: [] }, () => {
        this.setState({ ordersDetail: data });
        this.setState({ filteredOrdersDetail: data }, () => {
          this.GetUserTypes();
          this.GetPaymentModes();
        });
      });
    });
  };

  OnOrderRowClick = (e, order) => {
    this.setState({ selectedOrder: order });
    this.setState({ showOrderDetailsModal: true });
  };
  ModalClose = () => {
    this.setState({ showOrderDetailsModal: false }, () => {});
    this.Reload();
  };

  GetOrders = () => {
    this.ShowLoadSpinner();
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetOrdersHistory/" +
        this.context.storeId +
        "/" +
        this.state.dateRange
    )
      .then((response) => response.json())
      .then((data) => {
        this.setOrders(data);
        this.HideLoadSpinner();
      })
      .catch((error) => {
        console.error("There was an error!", error);
        this.HideLoadSpinner();
      });
  };
  OnDateRangeChanged = (e) => {
    const { name, value } = e.target;
    this.setState({ dateRange: value }, () => {
      this.GetOrders();
    });
  };

  onSearchChange = (query) => {
    this.setState({ reload: false });
    this.setState({ searchquery: query });
    if (!query) {
      this.setState({ filteredOrdersDetail: this.state.ordersDetail }, () => {
        this.setState({ reload: true });
      });
      return;
    }
    const fuse = new Fuse(this.state.ordersDetail, {
      keys: [
        "orderId",
        "customerName",
        "mobileNo",
        "location",
        "deliveryAddress",
      ],
      threshold: 0.0,
      ignoreLocation: true,
      findAllMatches: true,
      includeMatches: true,
      distance: 120,
    });
    const result = fuse.search(query);
    const finalResult = [];
    if (result.length) {
      result.forEach((item) => {
        finalResult.push(item.item);
      });
      this.setState({ filteredOrdersDetail: finalResult.slice(0, 15) }, () => {
        this.setState({ reload: true });
      });
    } else {
      this.setState({ filteredOrdersDetail: [] });
    }
  };

  OnUserTypeNameChanged = (e) => {
    this.setState({ filterUserTypeName: e.target.value });
  };
  OnPaymentModeNameChanged = (e) => {
    this.setState({ filterPaymentModeName: e.target.value });
  };

  GetUserTypes = () => {
    var userTypeNames = [
      ...new Set(
        this.state.filteredOrdersDetail.map(
          (q) => q.customerUserType.userTypeName
        )
      ),
    ];
    this.setState({ filterUserTypeNames: userTypeNames });
  };
  GetPaymentModes = () => {
    var names = [
      ...new Set(this.state.filteredOrdersDetail.map((q) => q.paymentmodeName)),
    ];
    this.setState({ filterPaymentModeNames: names });
  };
  OnDeleteOrder = (e, order) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteAnCustomerOrder/" +
        order.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.Reload();
          alert("not deleted");
          return;
        }
        this.Reload();
        Swal.fire({
          title: "Status",
          text: "Deleted Successfully",
          // confirmButtonText: "yes",

          timer: 1000,
        }).then((result) => {});
      })
      .catch((error) => {
        alert("OnDelete Failed" + error);
      });
  };

  DownloadToCSV = () => {
    var headings =
      "Created,OrderId,UserType, CustomerName,MobileNo,Location,TotalQty,TotalAmt,TaxAmount,PaymentMode,PaymentStatus,OrderStatus";
    var rows = "";
    this.state.filteredOrdersDetail
      .filter((f) => {
        if (
          this.state.filterUserTypeName != "" &&
          f.customerUserType.userTypeName == this.state.filterUserTypeName
        )
          return true;
        if (this.state.filterUserTypeName == "") return true;
      })
      .filter((f) => {
        if (
          this.state.filterPaymentModeName != "" &&
          f.paymentmodeName == this.state.filterPaymentModeName
        )
          return true;
        if (this.state.filterPaymentModeName == "") return true;
      })
      .map((m) => {
        rows +=
          this.context.GetInDateFormat(new Date(m.orderCreated)) +
          // new Date(m.orderCreated).getDate() +
          // "/" +
          // (new Date(m.orderCreated).getMonth() + 1) +
          // "/" +
          // new Date(m.orderCreated).getFullYear() +
          " " +
          new Date(m.orderCreated).toLocaleString("en-US", {
            hour: "numeric",
            minute: "numeric",
            hour12: true,
          }) +
          "," +
          m.orderId +
          "," +
          m.customerUserType.userTypeName +
          "," +
          m.customerName +
          "," +
          m.mobileNo +
          "," +
          m.location +
          "," +
          m.totalQty +
          "," +
          m.totalAmt +
          "," +
          m.taxAmount +
          "," +
          m.paymentmodeName +
          "," +
          m.paymentStatusName +
          "," +
          m.orderStatusName;
        rows += "\n";
      });
    rows = headings + "\n" + rows;
    const element = document.createElement("a");
    const file = new Blob([rows], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download =
      "products-" +
      new Date().getDate() +
      (new Date().getMonth() + 1) +
      new Date().getFullYear() +
      "-" +
      new Date().getMinutes() +
      new Date().getSeconds() +
      ".csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  Reload = () => {
    this.GetOrders();
    // this.setState({ filterUserTypeName: "" });
    // this.setState({ filterPaymentModeName: "" });
    // this.onSearchChange("");
    // this.setState({ dateRange: "Today" }, () => {
    //   this.GetOrders();
    // });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.state.firstLoad == false) {
      this.state.firstLoad = true;
      this.GetOrders();
    }
  }

  render() {
    if (this.context.storeId === "") {
      // return <Navigate to="/UserLogin" />;
      return <Navigate to="/Home" />;
    }
    return (
      <>
        <div className="Container-fluid mx-3 p-0 pagebackground">
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <Row>
            {window.innerWidth >= 1100 ? (
              <>
                {/* <Col className="pagebackground " xs={2}>
                  <SideMenu></SideMenu>
                </Col> */}
              </>
            ) : (
              ""
            )}

            <Col
              xs={window.innerWidth >= 1100 ? 12 : 12}
              className="pagebackground p-0 my-1"
            >
              {window.innerWidth >= 1200 ? (
                <>
                  <div>
                    <Navbar
                      // expand="lg"
                      className={
                        this.props.report == true ? (
                          <></>
                        ) : (
                          <>
                            "secondarythemecolor position-sticky fixed-top px-3
                            text-white m-0 p-1"
                          </>
                        )
                      }
                    >
                      {this.props.report == true ? (
                        <></>
                      ) : (
                        <>
                          <Navbar.Collapse className="justify-content-end m-0 p-0 ">
                            <Nav>
                              <div className="text-end border-3 border-dark">
                                <h3>7. History & Closed Orders</h3>
                              </div>
                            </Nav>
                          </Navbar.Collapse>
                        </>
                      )}

                      <Navbar.Collapse className="justify-content-end mx-1 m-0 p-0">
                        <Nav>
                          <ButtonToolbar className="text-end align-middle">
                            &nbsp;
                            <Button
                              className="mainthemecolor"
                              onClick={() => this.Reload()}
                            >
                              <BiRefresh size="20"></BiRefresh>
                              Refresh
                            </Button>
                            &nbsp;
                          </ButtonToolbar>
                          <ButtonToolbar className="text-end align-middle">
                            &nbsp;
                            <Button
                              className="mainthemecolor"
                              onClick={() => this.DownloadToCSV()}
                            >
                              <BiDownload size="20"></BiDownload>
                              Download
                            </Button>
                            &nbsp;
                          </ButtonToolbar>
                        </Nav>
                      </Navbar.Collapse>
                    </Navbar>
                    <div className="bg-light ">
                      <Table className="bg-light m-0 p-0">
                        <tr className="border-0">
                          <td width="30%">
                            <Stack direction="horizontal" gap={3} className="">
                              <b>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="30"
                                  fill="currentColor"
                                  class="bi bi-search"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                              </b>
                              <FormControl
                                type="search"
                                placeholder="Search by OrderNo / Customer details ... "
                                className="text-primary bg-white "
                                aria-label="Search"
                                value={this.state.searchquery}
                                onChange={(e) =>
                                  this.onSearchChange(e.target.value)
                                }
                              />
                            </Stack>
                          </td>
                          <td width="20%">
                            <InputGroup className="mb-1">
                              <InputGroup.Text id="basic-addon1">
                                Date Range
                              </InputGroup.Text>
                              <Form.Select
                                value={this.state.dateRange}
                                onChange={(e) => {
                                  this.OnDateRangeChanged(e);
                                }}
                              >
                                <option value="Today">Today</option>
                                <option value="Yesterday">Yesterday</option>
                                <option value="Last 3 Days">Last 3 Days</option>
                                <option value="Last 7 Days">Last 7 Days</option>
                                <option value="This Month">This Month</option>
                                <option value="Last Month">Last Month</option>
                                <option value="Last 3 Months">
                                  Last 3 Months
                                </option>
                                <option value="All">All</option>
                              </Form.Select>
                            </InputGroup>
                          </td>
                          <td width="20%">
                            <InputGroup className="mb-1">
                              <InputGroup.Text id="basic-addon1">
                                User Type
                              </InputGroup.Text>
                              <Form.Select
                                value={this.state.filterUserTypeName}
                                onChange={(e) => {
                                  this.OnUserTypeNameChanged(e);
                                }}
                              >
                                <option value=""></option>
                                {this.state.filterUserTypeNames.map((m) => (
                                  <>
                                    <option value={m}>{m}</option>
                                  </>
                                ))}
                              </Form.Select>
                            </InputGroup>
                          </td>
                          <td width="20%">
                            <InputGroup className="mb-1">
                              <InputGroup.Text id="basic-addon1">
                                Payment Mode
                              </InputGroup.Text>
                              <Form.Select
                                value={this.state.filterPaymentModeName}
                                onChange={(e) => {
                                  this.OnPaymentModeNameChanged(e);
                                }}
                              >
                                <option value=""></option>
                                {this.state.filterPaymentModeNames.map((m) => (
                                  <>
                                    <option value={m}>{m}</option>
                                  </>
                                ))}
                              </Form.Select>
                            </InputGroup>
                          </td>
                        </tr>
                      </Table>
                    </div>
                    <h4>
                      Result shows top 1000 records! Contact support if require
                      bulk download.
                    </h4>
                    <Table
                      bordered
                      hover
                      className="bg-white ordertablecustom"
                      style={{
                        tableLayout: "revert",
                        verticalAlign: "middle",
                      }}
                    >
                      <thead>
                        <tr
                          className="text-center text-dark ordertablecustom ordertable"
                          style={{ whiteSpace: "nowrap" }}
                        >
                          <th></th>

                          <th width="20%">
                            Order No <br />[
                            {
                              this.state.filteredOrdersDetail
                                .filter((f) => {
                                  if (
                                    this.state.filterUserTypeName != "" &&
                                    f.customerUserType.userTypeName ==
                                      this.state.filterUserTypeName
                                  )
                                    return true;
                                  if (this.state.filterUserTypeName == "")
                                    return true;
                                })
                                .filter((f) => {
                                  if (
                                    this.state.filterPaymentModeName != "" &&
                                    f.paymentmodeName ==
                                      this.state.filterPaymentModeName
                                  )
                                    return true;
                                  if (this.state.filterPaymentModeName == "")
                                    return true;
                                }).length
                            }
                            ]
                          </th>
                          <th width="10%">User Type</th>
                          <th width="10%">Deliver Address</th>
                          <th width="10%">
                            Total Items
                            <br />[
                            {this.state.filteredOrdersDetail
                              .filter((f) => {
                                if (
                                  this.state.filterUserTypeName != "" &&
                                  f.customerUserType.userTypeName ==
                                    this.state.filterUserTypeName
                                )
                                  return true;
                                if (this.state.filterUserTypeName == "")
                                  return true;
                              })
                              .filter((f) => {
                                if (
                                  this.state.filterPaymentModeName != "" &&
                                  f.paymentmodeName ==
                                    this.state.filterPaymentModeName
                                )
                                  return true;
                                if (this.state.filterPaymentModeName == "")
                                  return true;
                              })
                              .reduce(
                                (a, v) => (a = a + Number(v.totalQty)),
                                0
                              )}
                            ]
                          </th>
                          <th width="10%">
                            Total Amt <br />[
                            <strong
                              style={{
                                fontFamily: "monospace",
                                fontSize: "12px",
                              }}
                            >
                              {Math.round(
                                this.state.filteredOrdersDetail
                                  .filter((f) => {
                                    if (
                                      this.state.filterUserTypeName != "" &&
                                      f.customerUserType.userTypeName ==
                                        this.state.filterUserTypeName
                                    )
                                      return true;
                                    if (this.state.filterUserTypeName == "")
                                      return true;
                                  })
                                  .filter((f) => {
                                    if (
                                      this.state.filterPaymentModeName != "" &&
                                      f.paymentmodeName ==
                                        this.state.filterPaymentModeName
                                    )
                                      return true;
                                    if (this.state.filterPaymentModeName == "")
                                      return true;
                                  })
                                  .reduce(
                                    (a, v) =>
                                      Number((a = a + Number(v.totalAmt))),
                                    0
                                  ),
                                0
                              ).toLocaleString(
                                this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 1,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings.defaultCurrency,
                                }
                              )}
                            </strong>
                            ]
                          </th>
                          <th width="10%">Payment Mode</th>
                          <th width="10%">Payment Status</th>
                          <th width="20%">Order Status</th>
                          <th width="10%"></th>
                        </tr>
                      </thead>
                      <tbody className="cartviewtable text-center">
                        {this.state.filteredOrdersDetail
                          .sort((a, b) =>
                            a.orderCreated < b.orderCreated ? 1 : -1
                          )
                          .slice(0, 1000)
                          .filter((f) => {
                            if (
                              this.state.filterUserTypeName != "" &&
                              f.customerUserType.userTypeName ==
                                this.state.filterUserTypeName
                            )
                              return true;
                            if (this.state.filterUserTypeName == "")
                              return true;
                          })
                          .filter((f) => {
                            if (
                              this.state.filterPaymentModeName != "" &&
                              f.paymentmodeName ==
                                this.state.filterPaymentModeName
                            )
                              return true;
                            if (this.state.filterPaymentModeName == "")
                              return true;
                          })
                          .map((order, i) => (
                            <tr
                              className="ordertablecustom text-center "
                              onDoubleClick={(e) =>
                                this.OnOrderRowClick(e, order)
                              }
                              // style={{ whiteSpace: "wrap" }}
                            >
                              <td>{i + 1}</td>
                              <td>
                                <h5>{order.orderId}</h5>
                                {this.context.GetInDateFormat(
                                  new Date(order.orderCreated)
                                ) +
                                  // new Date(order.orderCreated).getDate() +
                                  //   "/" +
                                  //   (new Date(order.orderCreated).getMonth() +
                                  //     1) +
                                  //   "/" +
                                  //   new Date(order.orderCreated).getFullYear() +
                                  " " +
                                  new Date(order.orderCreated).toLocaleString(
                                    "en-US",
                                    {
                                      hour: "numeric",
                                      minute: "numeric",
                                      hour12: true,
                                    }
                                  )}
                              </td>
                              <td>
                                {order.customerUserType.userTypeName}
                                {order.customerUserType.userTypeName ==
                                "POS" ? (
                                  <>
                                    <br />
                                    Cashier:{order.customerName}
                                    <br /> {order.tableName}
                                  </>
                                ) : (
                                  <></>
                                )}
                              </td>
                              <td>
                                {order.customerUserType.userTypeName ==
                                "POS" ? (
                                  <>
                                    {order.address},{order.location}-
                                    {order.pinCode}
                                  </>
                                ) : (
                                  <>
                                    {order.customerName}[{order.mobileNo}]
                                    <br />
                                    {order.location}-{order.pinCode}
                                  </>
                                )}
                              </td>
                              <td>{order.totalQty}</td>
                              <td className="">
                                <strong
                                  style={{
                                    fontFamily: "monospace",
                                    fontSize: "12px",
                                  }}
                                >
                                  {Math.round(order.totalAmt, 0).toLocaleString(
                                    this.context.storeSettings.defaultLocale,
                                    {
                                      minimumFractionDigits: 0,
                                      maximumFractionDigits: 1,
                                      style: "currency",
                                      currency:
                                        this.context.storeSettings
                                          .defaultCurrency,
                                    }
                                  )}
                                </strong>
                              </td>

                              <td>{order.paymentmodeName}</td>
                              <td>
                                <strong>{order.paymentStatusName}</strong>
                              </td>
                              <td>{order.orderStatusName}</td>
                              <td>
                                <Button
                                  className="mainthemecolor"
                                  onClick={(e) =>
                                    this.OnOrderRowClick(e, order)
                                  }
                                >
                                  Open
                                </Button>
                                <Button
                                  className="w-100 my-2"
                                  hidden={this.context.enableDeleteButtonMenu()}
                                  variant="danger"
                                  onClick={(e) => {
                                    if (
                                      window.confirm(
                                        " Are you sure that you want to Delete?"
                                      )
                                    ) {
                                      this.OnDeleteOrder(e, order);
                                    }
                                  }}
                                >
                                  Delete
                                </Button>
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </Table>
                  </div>
                </>
              ) : (
                <>
                  <>
                    <div>
                      <Navbar className="secondarythemecolor position-sticky fixed-top px-0 text-white">
                        <h5>Orders History</h5>
                        <Navbar.Collapse className="justify-content-end mx-1 ">
                          <Nav>
                            <ButtonToolbar className="text-end align-middle">
                              &nbsp;
                              <Button
                                className="mainthemecolor"
                                onClick={() => this.Reload()}
                              >
                                <BiRefresh size="18"></BiRefresh>
                                Refresh
                              </Button>
                              &nbsp;
                            </ButtonToolbar>
                            <ButtonToolbar className="text-end align-middle">
                              &nbsp;
                              <Button
                                className="mainthemecolor"
                                onClick={() => this.DownloadToCSV()}
                              >
                                <BiDownload size="18"></BiDownload>
                                Download
                              </Button>
                              &nbsp;
                            </ButtonToolbar>
                          </Nav>
                        </Navbar.Collapse>
                      </Navbar>
                      <div className="bg-light ">
                        <Table className="bg-light m-0 p-0 border-0">
                          <tbody>
                            <tr className="border-0">
                              <td colSpan={3}>
                                <Stack
                                  direction="horizontal"
                                  gap={3}
                                  className=""
                                >
                                  <b>
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="30"
                                      height="30"
                                      fill="currentColor"
                                      class="bi bi-search"
                                      viewBox="0 0 16 16"
                                    >
                                      <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                    </svg>
                                  </b>
                                  <FormControl
                                    type="search"
                                    placeholder="Search by OrderNo,Phoneno,Name,City ... "
                                    className="text-primary bg-white "
                                    aria-label="Search"
                                    onChange={(e) =>
                                      this.onSearchChange(e.target.value)
                                    }
                                  />
                                </Stack>
                              </td>
                            </tr>
                            <tr>
                              <td width="30%">
                                <small>Date Range</small>
                                <Form.Select
                                  value={this.state.dateRange}
                                  onChange={(e) => {
                                    this.OnDateRangeChanged(e);
                                  }}
                                >
                                  <option value="Today">Today</option>
                                  <option value="Yesterday">Yesterday</option>
                                  <option value="Last 3 Days">
                                    Last 3 Days
                                  </option>
                                  <option value="Last 7 Days">
                                    Last 7 Days
                                  </option>
                                  <option value="This Month">This Month</option>
                                  <option value="Last Month">Last Month</option>
                                  <option value="Last 3 Months">
                                    Last 3 Months
                                  </option>
                                  <option value="All">All</option>
                                </Form.Select>
                              </td>
                              <td width="30%">
                                User Type
                                <br />
                                <Form.Select
                                  value={this.state.filterUserTypeName}
                                  onChange={(e) => {
                                    this.OnUserTypeNameChanged(e);
                                  }}
                                >
                                  <option value=""></option>
                                  {this.state.filterUserTypeNames.map((m) => (
                                    <>
                                      <option value={m}>{m}</option>
                                    </>
                                  ))}
                                </Form.Select>
                              </td>
                              <td width="30%">
                                Payment Mode
                                <Form.Select
                                  value={this.state.filterPaymentModeName}
                                  onChange={(e) => {
                                    this.OnPaymentModeNameChanged(e);
                                  }}
                                >
                                  <option value=""></option>
                                  {this.state.filterPaymentModeNames.map(
                                    (m) => (
                                      <>
                                        <option value={m}>{m}</option>
                                      </>
                                    )
                                  )}
                                </Form.Select>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                      <div
                        className="fixedTableHightOrder"
                        style={{ height: window.innerHeight - 100 + "px" }}
                      >
                        <h4>Result shows top 500 records!</h4>
                        <Table
                          bordered
                          hover
                          className="bg-white ordertablecustom"
                          size="sm"
                        >
                          <thead>
                            <tr className="text-center bg-primary text-white ordertablecustom ordertable m-0 p-0 border-2 border-primary">
                              <th></th>
                              <th>
                                <span
                                  style={{
                                    fontFamily: "monospace",
                                    fontSize: "16px",
                                    color: "yellow",
                                  }}
                                >
                                  Order
                                </span>
                                <br />
                                <div
                                  className="bg-secondary text-white"
                                  style={{
                                    fontFamily: "monospace",
                                    fontSize: "12px",
                                  }}
                                >
                                  [
                                  {
                                    this.state.filteredOrdersDetail
                                      .filter((f) => {
                                        if (
                                          this.state.filterUserTypeName != "" &&
                                          f.customerUserType.userTypeName ==
                                            this.state.filterUserTypeName
                                        )
                                          return true;
                                        if (this.state.filterUserTypeName == "")
                                          return true;
                                      })
                                      .filter((f) => {
                                        if (
                                          this.state.filterPaymentModeName !=
                                            "" &&
                                          f.paymentmodeName ==
                                            this.state.filterPaymentModeName
                                        )
                                          return true;
                                        if (
                                          this.state.filterPaymentModeName == ""
                                        )
                                          return true;
                                      }).length
                                  }
                                  ]
                                </div>
                              </th>
                              <th>
                                <span
                                  style={{
                                    fontFamily: "monospace",
                                    fontSize: "16px",
                                    color: "yellow",
                                  }}
                                >
                                  Status
                                </span>
                                <br />
                                <div
                                  className="bg-secondary text-white"
                                  style={{
                                    fontFamily: "monospace",
                                    fontSize: "12px",
                                  }}
                                >
                                  [Items=
                                  {this.state.filteredOrdersDetail
                                    .filter((f) => {
                                      if (
                                        this.state.filterUserTypeName != "" &&
                                        f.customerUserType.userTypeName ==
                                          this.state.filterUserTypeName
                                      )
                                        return true;
                                      if (this.state.filterUserTypeName == "")
                                        return true;
                                    })
                                    .filter((f) => {
                                      if (
                                        this.state.filterPaymentModeName !=
                                          "" &&
                                        f.paymentmodeName ==
                                          this.state.filterPaymentModeName
                                      )
                                        return true;
                                      if (
                                        this.state.filterPaymentModeName == ""
                                      )
                                        return true;
                                    })
                                    .reduce(
                                      (a, v) => (a = a + Number(v.totalQty)),
                                      0
                                    )}
                                  ] [Sale:
                                  <strong
                                    style={{
                                      fontFamily: "monospace",
                                      fontSize: "12px",
                                    }}
                                  >
                                    {Math.round(
                                      this.state.filteredOrdersDetail
                                        .filter((f) => {
                                          if (
                                            this.state.filterUserTypeName !=
                                              "" &&
                                            f.customerUserType.userTypeName ==
                                              this.state.filterUserTypeName
                                          )
                                            return true;
                                          if (
                                            this.state.filterUserTypeName == ""
                                          )
                                            return true;
                                        })
                                        .filter((f) => {
                                          if (
                                            this.state.filterPaymentModeName !=
                                              "" &&
                                            f.paymentmodeName ==
                                              this.state.filterPaymentModeName
                                          )
                                            return true;
                                          if (
                                            this.state.filterPaymentModeName ==
                                            ""
                                          )
                                            return true;
                                        })
                                        .reduce(
                                          (a, v) =>
                                            (a = a + Number(v.totalAmt)),
                                          0
                                        ),
                                      0
                                    ).toLocaleString(
                                      this.context.storeSettings.defaultLocale,
                                      {
                                        minimumFractionDigits: 0,
                                        maximumFractionDigits: 1,
                                        style: "currency",
                                        currency:
                                          this.context.storeSettings
                                            .defaultCurrency,
                                      }
                                    )}
                                  </strong>
                                  ]
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.state.filteredOrdersDetail
                              .sort((a, b) =>
                                a.orderCreated < b.orderCreated ? 1 : -1
                              )
                              .slice(0, 500)
                              .filter((f) => {
                                if (
                                  this.state.filterUserTypeName != "" &&
                                  f.customerUserType.userTypeName ==
                                    this.state.filterUserTypeName
                                )
                                  return true;
                                if (this.state.filterUserTypeName == "")
                                  return true;
                              })
                              .filter((f) => {
                                if (
                                  this.state.filterPaymentModeName != "" &&
                                  f.paymentmodeName ==
                                    this.state.filterPaymentModeName
                                )
                                  return true;
                                if (this.state.filterPaymentModeName == "")
                                  return true;
                              })
                              .sort((a, b) =>
                                a.orderCreated > b.orderCreated ? 1 : -1
                              )
                              .map((order, i) => (
                                <tr
                                  className="trheight ordertablecustom text-center border-2 border-primary"
                                  onDoubleClick={(e) =>
                                    this.OnOrderRowClick(e, order)
                                  }
                                >
                                  <td>{i + 1}</td>
                                  <td
                                    className="text-start m-0 p-0"
                                    style={{
                                      lineHeight: "200%",
                                      fontSize: "11px",
                                      width: "45%",
                                    }}
                                  >
                                    <div className="border w-100 m-0 p-0">
                                      <b>OrderNo:</b>{" "}
                                      <span className="bg-primary text-white">
                                        &nbsp;
                                        {order.orderId}
                                        &nbsp;
                                      </span>
                                    </div>
                                    <div className="border w-100 m-0 p-0">
                                      <b>Date:</b>{" "}
                                      {this.context.GetInDateFormat(
                                        new Date(order.orderCreated)
                                      ) +
                                        // new Date(order.orderCreated).getDate() +
                                        //   "/" +
                                        //   (new Date(
                                        //     order.orderCreated
                                        //   ).getMonth() +
                                        //     1) +
                                        //   "/" +
                                        //   new Date(
                                        //     order.orderCreated
                                        //   ).getFullYear() +
                                        " " +
                                        new Date(
                                          order.orderCreated
                                        ).getHours() +
                                        ":" +
                                        new Date(
                                          order.orderCreated
                                        ).getMinutes()}
                                    </div>
                                    <div className="border w-100 m-0 p-0">
                                      <b>Delivery Address:</b>
                                      <br />
                                      <span className="bg-secondary text-white">
                                        &nbsp; {order.customerName}&nbsp;
                                      </span>
                                      , {order.mobileNo}, {order.location},{" "}
                                      {order.pinCode}
                                    </div>
                                  </td>

                                  <td
                                    className="text-start m-0 p-0"
                                    style={{
                                      lineHeight: "200%",
                                      fontSize: "11px",
                                      width: "55%",
                                    }}
                                  >
                                    <div className="border w-100 m-0 p-0">
                                      <b>Tot.Qty:</b>{" "}
                                      <span className="bg-secondary text-white">
                                        &nbsp;&nbsp;{order.totalQty}&nbsp;&nbsp;
                                      </span>
                                      &nbsp;&nbsp;&nbsp;
                                      <strong>Total Amt: </strong>{" "}
                                      <span className="bg-success text-white">
                                        &nbsp;
                                        <strong
                                          style={{
                                            fontFamily: "monospace",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {Math.round(
                                            Number(order.totalAmt, 0)
                                          ).toLocaleString(
                                            this.context.storeSettings
                                              .defaultLocale,
                                            {
                                              minimumFractionDigits: 0,
                                              maximumFractionDigits: 1,
                                              style: "currency",
                                              currency:
                                                this.context.storeSettings
                                                  .defaultCurrency,
                                            }
                                          )}
                                        </strong>
                                        &nbsp;
                                      </span>
                                    </div>
                                    <div className="border w-100 m-0 p-0">
                                      <b>User Type:</b>{" "}
                                      <span className="bg-primary text-white">
                                        &nbsp;
                                        <strong>
                                          {order.customerUserType.userTypeName}
                                        </strong>
                                        &nbsp;
                                      </span>
                                    </div>
                                    <b>PaymentMode:</b>
                                    <span className="bg-primary text-white">
                                      &nbsp;{order.paymentmodeName}&nbsp;
                                    </span>
                                    <br />
                                    <div className="border w-100 m-0 p-0">
                                      <b>PaymentStatus:</b>{" "}
                                      <span className="bg-danger text-white">
                                        &nbsp;{order.paymentStatusName}&nbsp;
                                      </span>
                                    </div>
                                    <div className="border w-100 m-0 p-0">
                                      <b>OrderStatus:</b>{" "}
                                      <span className="bg-warning text-dark">
                                        &nbsp;{order.orderStatusName}&nbsp;
                                      </span>
                                    </div>
                                    <div className="text-end m-0 p-0">
                                      <Button
                                        className="mainthemecolor justify-content w-100"
                                        onClick={(e) =>
                                          this.OnOrderRowClick(e, order)
                                        }
                                      >
                                        Open
                                      </Button>
                                      <Button
                                        className="w-100 my-2"
                                        hidden={this.context.enableDeleteButtonMenu()}
                                        variant="danger"
                                        onClick={(e) => {
                                          if (
                                            window.confirm(
                                              " Are you sure that you want to Delete?"
                                            )
                                          ) {
                                            this.OnDeleteOrder(e, order);
                                          }
                                        }}
                                      >
                                        Delete
                                      </Button>
                                    </div>
                                  </td>
                                </tr>
                              ))}
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </>
                </>
              )}

              {this.state.showOrderDetailsModal === true ? (
                <>
                  {window.innerWidth >= 1200 ? (
                    <>
                      <OrderDetails
                        show={this.state.showOrderDetailsModal}
                        onHide={this.ModalClose}
                        OrderState={this.props.OrderState}
                        storeId={this.context.storeId}
                        orderDetail={this.state.selectedOrder}
                      />
                    </>
                  ) : (
                    <>
                      <OrderDetailsMobile
                        show={this.state.showOrderDetailsModal}
                        onHide={this.ModalClose}
                        OrderState={this.props.OrderState}
                        storeId={this.context.storeId}
                        orderDetail={this.state.selectedOrder}
                      />
                    </>
                  )}
                </>
              ) : null}
              {/* <orders orderstate="history"></orders> */}
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
