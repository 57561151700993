import React, { Component } from "react";
import { useState, useRef } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import Barcode from "react-barcode";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { MessageBox } from "./MessageBox";
import Fuse from "fuse.js";
import { LoadSpinner } from "./LoadSpinner";
import { BsFillPencilFill } from "react-icons/bs";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import "../CSS/pagination.css";
import ReactPaginate from "react-paginate";

import {
  Table,
  Stack,
  FormControl,
  Form,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  ButtonToolbar,
} from "react-bootstrap";

export class Images extends Component {
  static contextType = CartContext;

  constructor(props) {
    super(props);
    this.state = {
      reload: true,
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      images: [],
      filteredImages: [],
      selectAllImages: false,
      selectUnusedImages: false,

      pageCount: 20,
      totalProductsInPage: 200,
      pageItemEnd: 200,
      pageItemStart: 0,
      reloadPaginate: false,
    };
    this.inputFile = React.createRef();
  }

  ResetPaginate = () => {
    this.setState({ pageItemEnd: this.state.totalProductsInPage });
    this.setState({ pageItemStart: 0 });
    this.setState({ reloadPaginate: true }, () => {
      this.setState({ reloadPaginate: false });
    });
  };
  handlePageClick = (event) => {
    //uncheck all
    this.setState({ selectAllImages: false });
    this.setState({ selectUnusedImages: false });
    this.state.images.map((i) => {
      i.checked = false;
    });
    this.setState({ images: this.state.images });
    this.setState({ filteredImages: this.state.images }, () => {
      var startIndex =
        (event.selected + 1) * this.state.totalProductsInPage -
        this.state.totalProductsInPage;

      //change end value first to avoid hanging
      this.setState({
        pageItemEnd: startIndex + this.state.totalProductsInPage,
      });

      this.setState({
        pageItemStart: startIndex,
      });
      window.scrollTo(0, 0);
    });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };
  fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 2000));
  };

  handleFileUpload = async (e) => {
    this.ShowLoadSpinner();
    const { files } = e.target;

    for (let i = 0; i < files.length; i++) {
      if (files[i].size < 10 * 1024 * 1024)
        await this.uploadFileToBlob(files[i]);
      else alert(files[i].name + "is bigger than 10MB");
    }
    console.log("All uploaded ");
    this.context.GetAllImageNames();
    this.HideLoadSpinner();
    this.ShowMessagBox(
      "Status",
      "Upload Success. Press Refresh Button to see the changes!"
    );
  };

  uploadFileToBlob = async (file) => {
    if (!file) return [];
    const blobService = new BlobServiceClient(
      this.context.store.storageBlobUrl + this.context.store.storageSasToken
    );
    const containerClient = blobService.getContainerClient(
      this.context.store.storageBlobContainerName
    );

    console.log("uploading:" + file.name);
    //upload file.
    await this.createBlobInContainer(containerClient, file);

    // get list of blobs in container
    //return getBlobsInContainer(containerClient);
  };

  createBlobInContainer = async (containerClient, file) => {
    // create blobClient for container
    const blobClient = containerClient.getBlockBlobClient(
      "images/" + file.name
    );

    // set mimetype as determined from browser with file upload control
    const options = { blobHTTPHeaders: { blobContentType: file.type } };

    // upload file
    await blobClient.uploadData(file, options);

    console.log("uploaded:" + file.name);
  };

  onUploadButtonClick = () => {
    this.inputFile.current.click();
  };

  onSearchChange = (query) => {
    this.setState({ reload: false });
    this.setState({ searchquery: query });
    if (!query) {
      //this.setState({ searchData: [] });
      this.setState({ filteredImages: this.state.images }, () => {
        this.setState({ reload: true });
      });
      return;
    }
    const fuse = new Fuse(this.state.images, {
      keys: ["name"],
      threshold: 0.2,
      ignoreLocation: true,
      findAllMatches: true,
      includeMatches: true,
      distance: 120,
    });
    const result = fuse.search(query);
    const finalResult = [];
    if (result.length) {
      result.forEach((item) => {
        finalResult.push(item.item);
      });
      this.setState({ filteredImages: finalResult.slice(0, 15) }, () => {
        this.setState({ reload: true });
      });
    } else {
      this.setState({ filteredImages: [] });
    }
  };

  DeleteImages = (selectedImages) => {
    this.ShowLoadSpinner();

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(selectedImages),
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteImages/" +
        this.context.storeId,
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.context.GetAllImageNames();
          this.HideLoadSpinner();
          this.ShowMessagBox(
            "Information",
            "Deleted Successfully. Click Refresh to see the changes!"
          );
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Delete Failed!");
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Delete Failed! " + error);
      });
  };

  OnDelete = () => {
    var selectedImages = this.state.images.filter((i) => i.checked == true);
    this.DeleteImages(selectedImages);
  };

  onSelectAllCheckChanged = (e) => {
    this.setState({ selectAllImages: e.target.checked }, () => {
      this.state.filteredImages
        .slice(this.state.pageItemStart, this.state.pageItemEnd)
        .map((i) => (i.checked = e.target.checked));
      //this.setState({ images: this.state.images });
      this.setState({ filteredImages: this.state.images });
      this.setState({ searchquery: "" });
    });
  };

  GetAllCheckedImageNames = () => {
    var str = "";
    var index = 1;
    this.state.images.map((i) => {
      if (i.checked == true) {
        str += index + ". " + i.name + "\n";
        index++;
      }
    });
    return str;
  };

  onSelectAllUnusedCheckChanged = (e) => {
    this.setState({ selectUnusedImages: e.target.checked }, () => {
      this.state.filteredImages
        .slice(this.state.pageItemStart, this.state.pageItemEnd)
        .map((i) => {
          if (this.IsImageUsed(i) == "UNUSED/???") {
            i.checked = e.target.checked;
          }
        });
      //this.setState({ images: this.state.images });
      this.setState({ filteredImages: this.state.images });
      this.setState({ searchquery: "" });
    });
  };

  onImageCheckChanged = (e) => {
    console.log(e.target.name + "" + e.target.checked);
    this.state.images.map((i) =>
      i.name == e.target.name ? (i.checked = e.target.checked) : ""
    );
    this.setState({ images: this.state.images });

    this.state.filteredImages.map((i) =>
      i.name == e.target.name ? (i.checked = e.target.checked) : ""
    );
    this.setState({ filteredImages: this.state.filteredImages });
  };

  onLoad = () => {
    this.state.images = [];
    this.context.images.map((m) => {
      var image = { name: m.name, size: m.size, checked: false };
      this.state.images.push(image);
    });
    this.setState({ images: this.state.images });
    this.setState({ filteredImages: this.state.images });
    this.setState({ searchquery: "" });
    this.setState({ reload: true });
  };
  OnRefreshButtonClick = () => {
    this.setState({ reload: false }, () => {
      this.onLoad();
    });
  };

  IsImageUsed = (image) => {
    // is used as product image
    for (let i = 0; i < this.context.prods.length; i++) {
      for (
        let j = 0;
        this.context.prods[i].productImages &&
        j < this.context.prods[i].productImages.length;
        j++
      ) {
        if (
          this.context.prods[i].productImages[j].fileName == image.name ||
          this.context.prods[i].productImages[j].posterName == image.name
        )
          return "Used in Product";
      }
      if (this.context.prods[i].description2FileName == image.name)
        return "Used in Product";
    }

    //Carousels
    for (let i = 0; i < this.context.carousels.length; i++) {
      if (this.context.carousels[i].imgFileName == image.name)
        return "Used in Carousel";
    }

    //top5 ad
    for (let i = 0; i < this.context.top5Ads.length; i++) {
      if (this.context.top5Ads[i].imgFileName == image.name)
        return "Used in Top5Ads";
    }

    //certificates
    for (let i = 0; i < this.context.clientCertificates.length; i++) {
      if (this.context.clientCertificates[i].imgFileName == image.name)
        return "Used in Certificates";
    }

    //client logos
    for (let i = 0; i < this.context.clientLogos.length; i++) {
      if (this.context.clientLogos[i].imgFileName == image.name)
        return "Used in Client Logos";
    }

    //testimonials
    for (let i = 0; i < this.context.clientTestimonials.length; i++) {
      if (this.context.clientTestimonials[i].imgFileName == image.name)
        return "Used in Client Logos";
    }

    //vlogs
    for (let i = 0; i < this.context.vlogs.length; i++) {
      if (
        this.context.vlogs[i].fileName == image.name ||
        this.context.vlogs[i].posterName == image.name
      )
        return "Used in Client vlogs";
    }

    //blogs
    for (let i = 0; i < this.context.blogs.length; i++) {
      if (
        this.context.blogs[i].fileName == image.name ||
        this.context.blogs[i].htmlFileName == image.name
      )
        return "Used in Client blogs";
    }

    // is used as product group image
    for (let i = 0; i < this.context.mainCategories.length; i++) {
      if (this.context.mainCategories[i].imgFileName == image.name)
        return "Used in MainCategory";
    }

    for (let i = 0; i < this.context.brands.length; i++) {
      if (this.context.brands[i].imgFileName == image.name)
        return "Used in Brand";
    }

    for (let i = 0; i < this.context.subCategories.length; i++) {
      if (this.context.subCategories[i].imgFileName == image.name)
        return "Used in Subcategory";
      if (this.context.subCategories[i].adImgFileName == image.name)
        return "Used in Subcategory";
    }

    for (let i = 0; i < this.context.productGroups.length; i++) {
      if (this.context.productGroups[i].imgFileName == image.name)
        return "Used in ProductGroup";
      if (this.context.productGroups[i].adImgFileName == image.name)
        return "Used ProductGroup";
    }

    if (this.context.storeSettings.storeLogoFileName == image.name)
      return "Used in StoreSettings";
    if (this.context.storeSettings.whatsAppStoreImageFile == image.name)
      return "Used in StoreSettings";
    if (this.context.storeSettings.razorpayCompanylogoFileName == image.name)
      return "Used in StoreSettings";
    if (this.context.storeSettings.bankReceiptDescriptionHtml == image.name)
      return "Used in StoreSettings";
    if (
      "termsandconditions.html" == image.name ||
      "privacypolicy.html" == image.name ||
      "contactus.html" == image.name ||
      "qrcode.png" == image.name
    )
      return "Used";

    return "UNUSED/???";
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fakeRequest().then(() => {
      this.setState({ loadSpinnerOpen: false }); // showing the app
    });

    if (this.state.images.length == 0) {
      this.onLoad();
    }
  }

  componentDidUpdate() {}
  render() {
    return (
      <>
        <div className="Container-fluid mx-0 px-0">
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <input
            style={{ display: "none" }}
            accept=".png,.jpg,.jpeg"
            ref={this.inputFile}
            onChange={this.handleFileUpload}
            type="file"
            multiple="multiple"
          />
          <Row>
            <Col className="pagebackground " xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="pagebackground p-0">
              <Navbar
                expand="lg"
                className="secondarythemecolor position-sticky fixed-top  px-3"
              >
                <h4> &nbsp; &nbsp;IMAGES &nbsp;</h4>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemecolor"
                        // onClick={() => this.setState({ addModalShow: true })}
                        onClick={(e) => this.onUploadButtonClick()}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Upload
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        disabled={
                          this.state.images.filter((f) => f.checked == true)
                            .length > 0
                            ? ""
                            : "true"
                        }
                        onClick={() => {
                          if (
                            window.confirm(
                              "WARNING: Below Images will be Deleted! \n" +
                                this.GetAllCheckedImageNames()
                            )
                          ) {
                            this.OnDelete();
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="16"
                          height="16"
                          fill="currentColor"
                          class="bi bi-trash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                          <path
                            fill-rule="evenodd"
                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                          />
                        </svg>
                        &nbsp; Delete
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Refresh
                      </Button>
                      &nbsp;
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              {/* <b>Note:</b>
              <br></br> 1. Recommended Product Image Size: 500 Width X 500
              Height in pixels <br></br>2. Recommended Carousel Image Size:
              &nbsp;&nbsp;&nbsp;
              <b>for PC:</b> 2000 Width X 500 Height in pixels;{" "}
              <b>&nbsp;&nbsp;&nbsp;For Mobile:</b> 400 width X 170 height in
              pixels
              <br /> */}
              {this.state.images.length / this.state.totalProductsInPage > 1 ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      padding: 2,
                      boxSizing: "border-box",
                      width: "100%",
                    }}
                  >
                    {this.state.reloadPaginate == false && this.state.images ? (
                      <>
                        <div
                          className=" w-100 justify-content-center"
                          style={{ display: "flex", fontSize: "22px" }}
                        >
                          <ReactPaginate
                            nextLabel="next >"
                            onPageChange={this.handlePageClick}
                            // pageRangeDisplayed={3}
                            pageRangeDisplayed={
                              this.state.images.length /
                                this.state.totalProductsInPage >
                              10
                                ? "10"
                                : "5"
                            }
                            marginPagesDisplayed={2}
                            pageCount={
                              this.state.images.length /
                              this.state.totalProductsInPage
                            }
                            previousLabel="< previous"
                            pageClassName="page-item"
                            pageLinkClassName="page-link"
                            previousClassName="page-item"
                            previousLinkClassName="page-link"
                            nextClassName="page-item"
                            nextLinkClassName="page-link"
                            breakLabel="..."
                            breakClassName="page-item"
                            breakLinkClassName="page-link"
                            containerClassName="pagination"
                            activeClassName="active"
                            renderOnZeroPageCount={null}
                          />
                        </div>
                      </>
                    ) : (
                      <></>
                    )}
                  </div>
                </>
              ) : (
                <></>
              )}
              <div className=" mx-5 p-1">
                <Table className="mx-0 py-0" striped>
                  <Row className="">
                    <Col>
                      <Stack direction="horizontal" gap={3} className="">
                        <b>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            fill="currentColor"
                            class="bi bi-search"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                          </svg>
                        </b>
                        <FormControl
                          type="search"
                          placeholder="Search... "
                          className="text-dark fw-bold bg-white "
                          size="lg"
                          aria-label="Search"
                          onChange={(e) => this.onSearchChange(e.target.value)}
                        />
                      </Stack>
                    </Col>
                  </Row>
                </Table>
              </div>

              <div>
                <Row className="bg-light">
                  <Col className="align-content-center">
                    <h5>
                      <b>
                        <Form.Check
                          type="checkbox"
                          // name={this.state.selectAllImages}
                          label="Select All"
                          checked={this.state.selectAllImages}
                          onChange={(e) => this.onSelectAllCheckChanged(e)}
                          className="align-content-center"
                        ></Form.Check>
                      </b>
                    </h5>
                  </Col>
                  <Col>
                    <h5>
                      <b>
                        <Form.Check
                          type="checkbox"
                          label="Select All Unused files"
                          checked={this.state.selectUnusedImages}
                          onChange={(e) =>
                            this.onSelectAllUnusedCheckChanged(e)
                          }
                        ></Form.Check>
                      </b>
                    </h5>
                  </Col>
                  <Col></Col>
                  <Col></Col>
                </Row>
              </div>
              <div className="border categorydiv">
                <Table striped size="sm">
                  <thead className="bg-dark text-white">
                    <th></th>
                    <th width="20%">File Name</th>
                    <th width="20%">Size</th>
                    <th width="20%">Status</th>
                    <th width="20%">Image</th>
                    <th width="20%"></th>
                  </thead>
                  <tbody>
                    {this.state.reload == true
                      ? this.state.filteredImages
                          .slice(
                            this.state.pageItemStart,
                            this.state.pageItemEnd
                          )
                          .map((image, i) => (
                            <tr
                              className="text-start align-middle text-secondary bg-white mx-5"
                              style={{ fontSize: "12px" }}
                            >
                              <td className="text-start">{i + 1}</td>
                              <td className="text-start">
                                <h5>
                                  <Form.Check
                                    type="checkbox"
                                    name={image.name}
                                    label={image.name}
                                    checked={image.checked}
                                    onChange={(e) =>
                                      this.onImageCheckChanged(e)
                                    }
                                  ></Form.Check>
                                </h5>
                              </td>
                              <td className="text-start">
                                <h5>{image.size.toLocaleString()} KB</h5>
                              </td>
                              <td className="text-start">
                                <h5>{this.IsImageUsed(image)}</h5>
                              </td>
                              <td className="col-md-3 text-start ">
                                <img
                                  src={
                                    this.context.store.storageBlobUrl +
                                    this.context.store
                                      .storageBlobContainerName +
                                    "/images/" +
                                    image.name +
                                    this.context.store.storageSasToken
                                  }
                                  className=""
                                  width="70px"
                                  height="50px"
                                  style={{ objectFit: "contain" }}
                                />
                              </td>
                              <td>
                                <a
                                  contextType="application/binary"
                                  href={
                                    this.context.store.storageBlobUrl +
                                    this.context.store
                                      .storageBlobContainerName +
                                    "/images/" +
                                    image.name +
                                    this.context.store.storageSasToken +
                                    "&xyz=" +
                                    new Date().toLocaleString()
                                  }
                                  target="_blank"
                                >
                                  View / Download
                                </a>
                              </td>
                            </tr>
                          ))
                      : ""}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
