import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiRefresh } from "react-icons/bi";
import { BsFillPencilFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import Select from "react-select";

import {
  OverlayTrigger,
  Tabs,
  Tab,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Modal,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export class ManufacturingRawMaterials extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      rawMaterialId: "",
      price: "",
      qty: "1",
      details: "",
      statusId: 1,
      rawmaterials: [],
      firstload: false,
    };
  }

  LoadRawMaterials = () => {
    {
      var pd = [];
      this.props.rawMaterialsInventory.map((r) =>
        pd.push({
          value: r.id,
          label: r.name,
        })
      );
      this.setState({ rawmaterials: pd });
    }
  };

  GetPriceOfRawMaterial = (id) => {
    var result = this.props.rawMaterialsInventory.filter((f) => f.id == id);
    if (result.length > 0) return result[0].price;
    return 0;
  };

  handleItemsInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "qty":
        this.setState({ qty: value });
        break;
      case "details":
        this.setState({ details: value });
        break;
    }
  };
  handleSubmit = (event) => {
    if (
      this.state.rawMaterialId == "" ||
      this.state.price == "" ||
      this.state.qty == ""
    ) {
      alert("enter all manadatory fields and try!");
      return;
    }
    if (this.props.Type == "Add") {
      this.props.AddManufacturingRawMaterial({
        rawMaterialId: this.state.rawMaterialId,
        price: this.state.price,
        qty: this.state.qty,
        details: this.state.details,
        statusId: this.state.statusId,
      });
    } else {
      this.props.UpdateManufacturingRawMaterial(
        {
          rawMaterialId: this.state.rawMaterialId,
          price: this.state.price,
          qty: this.state.qty,
          details: this.state.details,
          statusId: this.state.statusId,
        },
        this.props.Index
      );
    }
  };

  OnRawMaterialNameChange = (e) => {
    this.setState({ rawMaterialId: e.value });
    this.setState({ price: this.GetPriceOfRawMaterial(e.value) });
  };
  GetRawMaterialName = (id) => {
    var list = this.state.rawmaterials.filter((f) => f.value == id);
    if (list.length > 0) return list[0].label;
    return "";
  };
  componentDidMount = () => {
    if (this.state.firstload == false) {
      this.state.firstload = true;
      if (this.props.Type == "Edit") {
        this.setState({
          rawMaterialId: this.props.manufacturingRawMaterial.rawMaterialId,
        });
        this.setState({
          price: this.props.manufacturingRawMaterial.price,
        });
        this.setState({ qty: this.props.manufacturingRawMaterial.qty });
        this.setState({ details: this.props.manufacturingRawMaterial.details });
      }
      this.LoadRawMaterials();
    }
    if (document.getElementById("productName") != null)
      document.getElementById("productName").focus();
  };

  render() {
    return (
      <>
        <div className="container ">
          <Modal
            {...this.props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={true}
            backdrop="static"
            keyboard={false}
            dialogClassName="CouponModal"
          >
            {/* <Form onSubmit={this.handleSubmit}> */}
            {/* <Form> */}
            <Modal.Header closeButton className="bg-primary text-light">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit"
                  ? "Edit Purchase Item"
                  : "Add Purchase Item"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Row className="m-2 p-2 bg-light " style={{ fontSize: "12pt" }}>
                <Col md={12}>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      RawMaterial Name
                    </Form.Label>
                    <Col sm="9">
                      <Select
                        name="productName"
                        id="productName"
                        autoFocus
                        value={{
                          value: this.state.rawMaterialId,
                          label: this.GetRawMaterialName(
                            this.state.rawMaterialId
                          ),
                        }}
                        onChange={(e) => this.OnRawMaterialNameChange(e)}
                        options={this.state.rawmaterials}
                      ></Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Price
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        size="lg"
                        className="pd-2 w-100"
                        name="price"
                        readOnly
                        value={this.state.price}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleItemsInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Qty
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        size="lg"
                        className="pd-2 w-100"
                        name="qty"
                        required
                        value={this.state.qty}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleItemsInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      SubTotal
                    </Form.Label>
                    <Col sm="9">{this.state.price * this.state.qty}</Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Details
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        as="textarea"
                        type="textarea"
                        rows={3}
                        maxLength={499}
                        size="lg"
                        className="pd-2 w-100"
                        name="details"
                        required
                        value={this.state.details}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleItemsInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Status
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        size="lg"
                        className="pd-2 w-100"
                        name="statusId"
                        required
                        disabled
                        value={this.state.statusId}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleItemsInputChange(e)}
                      >
                        <option value="1">Request</option>
                        <option value="2">Delivered</option>
                        <option value="3">OutOfStock</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                {/* <Button variant="primary" size="lg" type="submit"> */}
                <Button variant="primary" size="lg" onClick={this.handleSubmit}>
                  {this.props.Type == "Add" ? "Add" : "Update"}
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
            {/* </Form> */}
          </Modal>
        </div>
      </>
    );
  }
}
