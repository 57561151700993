import React, { Component, useContext, useState, useEffect } from "react";
import { Navigate } from "react-router-dom";
import { unmountComponentAtNode, render } from "react-dom";
import { SiHackthebox } from "react-icons/si";
import { RiFeedbackLine } from "react-icons/ri";
import RefreshOrderSummary from "./RefreshOrderSummary";

import {
  BsCartX,
  BsArrowRight,
  BsTruck,
  BsCashCoin,
  BsDoorClosed,
  BsClockHistory,
  BsArrowReturnLeft,
  BsPeopleFill,
} from "react-icons/bs";

import {
  Navbar,
  Nav,
  Badge,
  OverlayTrigger,
  Popover,
  ListGroup,
  Dropdown,
  Container,
  Row,
  Col,
  Button,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import MainMenu from "./MainMenu";

import CartContext from "./CartContext";

import "../CSS/textanimation.css";

export default class NavigationMobile extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      storeId: "",
      showOverlay: false,
    };
  }

  handleOnMouseEnter = () => {
    this.setState({ showOverlay: true });
  };
  handleOnMouseLeave = () => {
    this.setState({ showOverlay: false });
  };

  GetMyStoreDetails = () => {
    var v1 = process.env.REACT_APP_API + "Consumers/GetMyStoreDetails/";
    fetch(process.env.REACT_APP_API + "Consumers/GetMyStoreDetails/")
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
          alert(
            "This Application is not active yet. Please contact your Vendor!"
          );
        } else {
          let expirydate = new Date(data.storeEnd);
          const diffTime = Math.abs(new Date() - expirydate);
          const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

          if (new Date(data.storeEnd) > new Date() == false) {
            alert(
              "Information: Store License has expired. Kindly contact our Support Team for Renewal!"
            );
            return;
          } else if (diffDays < 30) {
            alert(
              "Renewal Remainder! Store License will expire on " +
                this.context.GetInDateFormat(new Date(data.storeEnd)) +
                ". Kindly contact our Support Team for Renewal!"
            );
          }
          this.context.setStoreId(data.id);
          if (this.state.storeId != "") return;
          this.setState({ storeId: data.id }, () => {
            this.context.GetStore();
            this.context.GetStoreSettings();
            this.context.GetMainCarousels(); //1-desktop 2-Mobile
            this.context.GetOrderStatusTypes();
            this.context.GetProductGroups();
            this.context.getMainCategories();
            this.context.getSubCategories();
            this.context.getBrands();
            this.context.GetAllProducts();
            this.context.GetMainMenus();
            this.context.GetAllImageNames();
            this.context.GetStoreRoles();
            this.context.GetOrdersSummary();
            this.context.GetCustomerOrderPaymentStatusTypes();
            this.context.getTaxDetails();
            this.context.getThemes();
            this.context.getProductThemes();
            this.context.GetRestaturantTables();
            this.context.GetRestaurantTableOrdereStatus();
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
        alert("GetMyStoreDetails fetch err:" + error);
      });
  };

  componentDidMount() {
    if (this.state.storeId == "") {
      this.GetMyStoreDetails();
    }
    // if (this.context.store.Id != undefined) {
    //   this.context.GetAllImageNames();
    // }
  }
  componentDidUpdate() {}

  render() {
    if (this.context.storeId === "") {
      return <Navigate to="/HomeMobileFront" />;
    }
    return (
      <>
        {this.context.userData.loginStatus == "active" ? (
          <>
            <RefreshOrderSummary></RefreshOrderSummary>
          </>
        ) : (
          <></>
        )}
        <Navbar className="mainnavbar  position-sticky fixed-top m-0 py-1 w-100 mainthemecolor">
          {/* <Navbar className="position-sticky fixed-top align-middle mainthemecolor py-4"> */}
          <Navbar.Collapse className="justify-content-start m-0 p-0">
            <Nav>
              <Nav.Link
                as={Link}
                to="/home"
                className="border-0 mx-2 p-1 text-center"
              >
                <span>
                  <img
                    src={
                      this.context.store.storageBlobUrl +
                      this.context.store.storageBlobContainerName +
                      "/images/" +
                      this.context.storeSettings.storeLogoFileName +
                      this.context.store.storageSasToken
                    }
                    width="100"
                    // height="100%"
                    // className="border-0 rounded-pill bg-white invertimage"
                    className="border-0  bg-white rounded-pill"
                  />
                </span>
                <br />
                <span
                  className="text-center"
                  style={{ color: "white", fontSize: "10px" }}
                >
                  ver:&nbsp;
                  {this.context.REACT_APP_VERSION}
                </span>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>

          <Navbar.Collapse className="justify-content-end mx-2 p-0 text-end border-0">
            <Nav className="navBarLink m-0 p-0 text-end  justify-content-end">
              <div className="">
                <Nav.Link
                  as={Link}
                  className="navBarLink border-0 m-0 p-0 text-end  justify-content-end"
                  to="/UserLogin"
                  hidden={this.context.userData.hideSignupLink}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-person-circle"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                    <path
                      fill-rule="evenodd"
                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                    />
                  </svg>
                </Nav.Link>
              </div>

              <OverlayTrigger
                show={this.state.showOverlay} // Control trigger behavior with show instead of trigger.
                key="bottom"
                placement="bottom"
                rootClose="false"
                overlay={
                  <Popover
                    id={`popover-positioned-bottom`}
                    onMouseEnter={this.handleOnMouseEnter}
                    onMouseLeave={this.handleOnMouseLeave}
                    className="bg-dark"
                  >
                    <Popover.Header as="h3"></Popover.Header>
                    <Popover.Body>
                      <UserMenu></UserMenu>
                    </Popover.Body>
                  </Popover>
                }
              >
                <Button
                  style={{ height: "30px", width: "160px" }}
                  // variant="dark"
                  className="mainthemecolor border-0 text-center  justify-content-center"
                  hidden={!this.context.userData.hideSignupLink}
                  onMouseEnter={this.handleOnMouseEnter}
                  onMouseLeave={this.handleOnMouseLeave}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-person-circle"
                      viewBox="0 0 16 16"
                    >
                      <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                      <path
                        fill-rule="evenodd"
                        d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z"
                      />
                    </svg>
                    <small>
                      &nbsp;Hi&nbsp;
                      {this.context.userData.emailId}
                    </small>
                  </span>
                </Button>
              </OverlayTrigger>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </>
    );
  }
}

class UserMenu extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <ListGroup variant="flush" className="fw-bold bg-dark">
          <ListGroup.Item
            as={Link}
            action
            variant="dark"
            to="/UserChangePassword"
          >
            Change Password
          </ListGroup.Item>

          <ListGroup.Item
            as={Link}
            action
            variant="dark"
            to="/home"
            onClick={(e) => {
              this.context.clearUserData();
            }}
          >
            Sign Out
          </ListGroup.Item>
        </ListGroup>
      </>
    );
  }
}
