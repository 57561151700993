import React, { Component } from "react";
import CartContext from "./CartContext";
import DateTimePicker from "react-datetime-picker";
import "react-datetime-picker/dist/DateTimePicker.css";
import "react-calendar/dist/Calendar.css";
import "react-clock/dist/Clock.css";
import { OrderDetails } from "./OrderDetails";
import { SideMenu } from "./SideMenu";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import { OrderHistory } from "./OrderHistory";
import { Navigate } from "react-router-dom";

import {
  InputGroup,
  Stack,
  FormControl,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

import Select from "react-select";
import {} from "react-bootstrap";

export class PurchaseByRawMaterialReport extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      dateRange: "Today",
      result: [],
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  OnDateRangeChanged = (e) => {
    const { name, value } = e.target;
    this.setState({ dateRange: value }, () => {
      this.setState({ result: [] }, () => {});
    });
  };

  GetPurchasesByRawMaterial = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetPurchasesByRawMaterial/" +
        this.context.storeId +
        "/" +
        this.state.dateRange +
        "/" +
        this.context.GetTicks(this.state.startDate) +
        "/" +
        this.context.GetTicks(this.state.endDate)
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ result: data });
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // alert("GetOrders fetch err:" + error);
      });
  };
  DownloadToCSV = () => {
    var headings =
      "#,Product Name,Tot.Qty,Purchases in Amount,Tax Amount,Total ";
    var reportDetails = "RawMaterials Purchase by Material Summary  - Report\n";
    reportDetails += this.context.GetDateResult(
      this.state.dateRange,
      this.state.startDate,
      this.state.endDate
    );
    var rows = "";
    this.state.result
      .sort((a, b) => (a.totqty < b.totqty ? 1 : -1))
      .map((m, i) => {
        rows +=
          i +
          1 +
          "," +
          m.name +
          "," +
          m.totqty +
          "," +
          Number(m.price).toFixed(2) +
          "," +
          Number((m.price * m.tax) / 100).toFixed(2) +
          "," +
          Number(m.price + (m.price * m.tax) / 100).toFixed(2);

        rows += "\n";
      });

    var result = reportDetails + "\n\n" + headings + "\n" + rows;
    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download =
      "products-" +
      new Date().getDate() +
      (new Date().getMonth() + 1) +
      new Date().getFullYear() +
      "-" +
      new Date().getMinutes() +
      new Date().getSeconds() +
      ".csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  onStartDateChange = (date) => {
    this.setState({ startDate: date });
  };
  onEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  GetLabelOfProduct = (id) => {
    var prods = this.props.rawMaterialsInventory.filter((f) => f.id == id);
    if (prods.length > 0)
      return prods[0].name + " " + prods[0].weight + prods[0].unit;
    return "";
  };

  componentDidMount = () => {
    //this.GetPurchasesByProduct();
  };

  render() {
    return (
      <>
        <div>
          {window.innerWidth > 1200 ? (
            <>
              <tr>
                <td>
                  <InputGroup className="mb-1">
                    <InputGroup.Text id="basic-addon1">
                      Date Range
                    </InputGroup.Text>
                    <Form.Select
                      value={this.state.dateRange}
                      onChange={(e) => {
                        this.OnDateRangeChanged(e);
                      }}
                    >
                      <option value="Custom">Custom</option>
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Last 3 Days">Last 3 Days</option>
                      <option value="Last 7 Days">Last 7 Days</option>
                      <option value="This Month">This Month</option>
                      <option value="Last Month">Last Month</option>
                      <option value="Last 3 Months">Last 3 Months</option>
                      <option value="All">All</option>
                    </Form.Select>
                  </InputGroup>
                </td>
                {this.state.dateRange == "Custom" ? (
                  <>
                    <td>
                      StartDate:{" "}
                      <DateTimePicker
                        onChange={this.onStartDateChange}
                        value={this.state.startDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                    </td>
                    <td>
                      <Stack direction="horizontal">
                        EndDate:
                        <DateTimePicker
                          onChange={this.onEndDateChange}
                          value={this.state.endDate}
                          format="dd-MMM-yy hh:mm a"
                        />
                      </Stack>
                    </td>
                  </>
                ) : (
                  <></>
                )}
                <td>
                  <Button onClick={(e) => this.GetPurchasesByRawMaterial()}>
                    Show Report
                  </Button>
                </td>
                <td className="">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {this.state.result.length > 0 ? (
                    <>
                      <Button
                        // className="mainthemecolor"
                        variant="danger"
                        onClick={() => this.DownloadToCSV()}
                      >
                        <BiDownload size="20"></BiDownload>
                        Download
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td>
                  <InputGroup className="mb-1">
                    <InputGroup.Text id="basic-addon1">
                      Date Range
                    </InputGroup.Text>
                    <Form.Select
                      value={this.state.dateRange}
                      onChange={(e) => {
                        this.OnDateRangeChanged(e);
                      }}
                    >
                      <option value="Custom">Custom</option>
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Last 3 Days">Last 3 Days</option>
                      <option value="Last 7 Days">Last 7 Days</option>
                      <option value="This Month">This Month</option>
                      <option value="Last Month">Last Month</option>
                      <option value="Last 3 Months">Last 3 Months</option>
                      <option value="All">All</option>
                    </Form.Select>
                  </InputGroup>
                  {this.state.dateRange == "Custom" ? (
                    <>
                      StartDate:{" "}
                      <DateTimePicker
                        onChange={this.onStartDateChange}
                        value={this.state.startDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                      EndDate:
                      <DateTimePicker
                        onChange={this.onEndDateChange}
                        value={this.state.endDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </td>

                <td>
                  <Button onClick={(e) => this.GetPurchasesByRawMaterial()}>
                    Show Report
                  </Button>
                </td>
                <td className="">
                  {this.state.result.length > 0 ? (
                    <>
                      <Button
                        // className="mainthemecolor"
                        variant="danger"
                        onClick={() => this.DownloadToCSV()}
                      >
                        <BiDownload size="20"></BiDownload>
                        Download
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </>
          )}
          <Table
            bordered
            hover
            className="bg-white text-end"
            style={{
              tableLayout: "revert",
              verticalAlign: "middle",
              fontFamily: "monospace",
              fontSize: "14px",
            }}
          >
            <thead>
              <th className="text-center">No.</th>
              <th className="text-start">Product Name</th>
              <th>Tot.Qty</th>
              <th>
                Purchases in Amount
                <br />[
                {this.state.result
                  .reduce((a, v) => (a = a + Number(v.price)), 0)
                  .toLocaleString(this.context.storeSettings.defaultLocale, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1,
                    style: "currency",
                    currency: this.context.storeSettings.defaultCurrency,
                  })}
                ]
              </th>
              <th>
                Tax Amount
                <br />[
                {this.state.result
                  .reduce((a, v) => (a = a + Number(v.price * v.tax) / 100), 0)
                  .toLocaleString(this.context.storeSettings.defaultLocale, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1,
                    style: "currency",
                    currency: this.context.storeSettings.defaultCurrency,
                  })}
                ]
              </th>
              <th>
                Total
                <br />[
                {this.state.result
                  .reduce(
                    (a, v) => (a = a + v.price + Number(v.price * v.tax) / 100),
                    0
                  )
                  .toLocaleString(this.context.storeSettings.defaultLocale, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1,
                    style: "currency",
                    currency: this.context.storeSettings.defaultCurrency,
                  })}
                ]
              </th>
            </thead>
            <tbody>
              {this.state.result
                .sort((a, b) => (a.totqty < b.totqty ? 1 : -1))
                .map((m, i) => (
                  <>
                    <tr>
                      <td className="text-center">{i + 1}</td>
                      <td className="text-start">
                        {this.GetLabelOfProduct(m.name)}
                      </td>
                      <td>{Number(m.totqty).toFixed(1)}</td>
                      <td>
                        {m.price.toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </td>
                      <td>
                        {Number((m.price * m.tax) / 100).toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </td>
                      <td>
                        {(m.price + (m.price * m.tax) / 100).toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </td>
                    </tr>
                  </>
                ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}
export class RawMaterialsPurchaseOrderSummaryReport extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      dateRange: "Today",
      result: [],
      startDate: new Date(),
      endDate: new Date(),
    };
  }

  OnDateRangeChanged = (e) => {
    const { name, value } = e.target;
    this.setState({ dateRange: value }, () => {
      this.setState({ result: [] }, () => {});
    });
  };

  GetRawMaterialPurchaseSummaryReport = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetRawMaterialPurchaseSummaryReport/" +
        this.context.storeId +
        "/" +
        this.state.dateRange +
        "/" +
        this.context.GetTicks(this.state.startDate) +
        "/" +
        this.context.GetTicks(this.state.endDate)
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ result: data });
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // alert("GetOrders fetch err:" + error);
      });
  };

  DownloadToCSV = () => {
    var headings =
      "#,CreatedOn,InvoiceNo, Tot.Purchase Amount,Tot.Purchsed Items ";
    var reportDetails = "RawMaterials Purchase Order(PO) Summary  - Report\n";
    reportDetails += this.context.GetDateResult(
      this.state.dateRange,
      this.state.startDate,
      this.state.endDate
    );
    var rows = "";
    this.state.result
      .sort((a, b) => (a.created < b.created ? 1 : -1))
      .map((m, i) => {
        rows +=
          i +
          1 +
          "," +
          this.context.GetInDateFormat(new Date(m.created)) +
          "," +
          m.invoiceNo +
          "," +
          Number(m.totAmount).toFixed(2) +
          "," +
          Number(m.totQty).toFixed(2);

        rows += "\n";
      });

    var result = reportDetails + "\n\n" + headings + "\n" + rows;
    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download =
      "products-" +
      new Date().getDate() +
      (new Date().getMonth() + 1) +
      new Date().getFullYear() +
      "-" +
      new Date().getMinutes() +
      new Date().getSeconds() +
      ".csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };
  onStartDateChange = (date) => {
    this.setState({ startDate: date });
  };
  onEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  componentDidMount = () => {
    //this.GetPurchasesByProduct();
  };

  render() {
    return (
      <>
        <div>
          {window.innerWidth > 1200 ? (
            <>
              <tr>
                <td>
                  <InputGroup className="mb-1">
                    <InputGroup.Text id="basic-addon1">
                      Date Range
                    </InputGroup.Text>
                    <Form.Select
                      value={this.state.dateRange}
                      onChange={(e) => {
                        this.OnDateRangeChanged(e);
                      }}
                    >
                      <option value="Custom">Custom</option>
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Last 3 Days">Last 3 Days</option>
                      <option value="Last 7 Days">Last 7 Days</option>
                      <option value="This Month">This Month</option>
                      <option value="Last Month">Last Month</option>
                      <option value="Last 3 Months">Last 3 Months</option>
                      <option value="All">All</option>
                    </Form.Select>
                  </InputGroup>
                </td>
                {this.state.dateRange == "Custom" ? (
                  <>
                    <td>
                      StartDate:{" "}
                      <DateTimePicker
                        onChange={this.onStartDateChange}
                        value={this.state.startDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                    </td>
                    <td>
                      <Stack direction="horizontal">
                        EndDate:
                        <DateTimePicker
                          onChange={this.onEndDateChange}
                          value={this.state.endDate}
                          format="dd-MMM-yy hh:mm a"
                        />
                      </Stack>
                    </td>
                  </>
                ) : (
                  <></>
                )}
                <td>
                  <Button
                    onClick={(e) => this.GetRawMaterialPurchaseSummaryReport()}
                  >
                    Show Report
                  </Button>
                </td>
                <td className="">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {this.state.result.length > 0 ? (
                    <>
                      <Button
                        // className="mainthemecolor"
                        variant="danger"
                        onClick={() => this.DownloadToCSV()}
                      >
                        <BiDownload size="20"></BiDownload>
                        Download
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td>
                  <InputGroup className="mb-1">
                    <InputGroup.Text id="basic-addon1">
                      Date Range
                    </InputGroup.Text>
                    <Form.Select
                      value={this.state.dateRange}
                      onChange={(e) => {
                        this.OnDateRangeChanged(e);
                      }}
                    >
                      <option value="Custom">Custom</option>
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Last 3 Days">Last 3 Days</option>
                      <option value="Last 7 Days">Last 7 Days</option>
                      <option value="This Month">This Month</option>
                      <option value="Last Month">Last Month</option>
                      <option value="Last 3 Months">Last 3 Months</option>
                      <option value="All">All</option>
                    </Form.Select>
                  </InputGroup>
                  {this.state.dateRange == "Custom" ? (
                    <>
                      StartDate:{" "}
                      <DateTimePicker
                        onChange={this.onStartDateChange}
                        value={this.state.startDate}
                      />
                      EndDate:
                      <DateTimePicker
                        onChange={this.onEndDateChange}
                        value={this.state.endDate}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </td>

                <td>
                  <Button
                    onClick={(e) => this.GetRawMaterialPurchaseSummaryReport()}
                  >
                    Show Report
                  </Button>
                </td>
                <td className="">
                  {this.state.result.length > 0 ? (
                    <>
                      <Button
                        // className="mainthemecolor"
                        variant="danger"
                        onClick={() => this.DownloadToCSV()}
                      >
                        <BiDownload size="20"></BiDownload>
                        Download
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </>
          )}
          <Table
            bordered
            hover
            className="bg-white text-end"
            style={{
              tableLayout: "revert",
              verticalAlign: "middle",
              fontFamily: "monospace",
              fontSize: "14px",
            }}
          >
            <thead>
              <th className="text-center">No.</th>
              <th className="text-start">CreatedOn</th>
              <th className="text-start">InvoiceNo</th>
              <th>
                Tot.Purchase Amount
                <br />[
                {this.state.result
                  .reduce((a, v) => (a = a + Number(v.totAmount)), 0)
                  .toLocaleString(this.context.storeSettings.defaultLocale, {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 1,
                    style: "currency",
                    currency: this.context.storeSettings.defaultCurrency,
                  })}
                ]
              </th>
              <th>
                Tot.Purchsed Items
                <br />[
                {this.state.result.reduce(
                  (a, v) => (a = a + Number(v.totQty)),
                  0
                )}
                ]
              </th>
            </thead>
            <tbody>
              {this.state.result
                .sort((a, b) => (a.created < b.created ? 1 : -1))
                .map((m, i) => (
                  <>
                    <tr>
                      <td className="text-center">{i + 1}</td>
                      <td className="text-start">
                        {this.context.GetInDateFormat(new Date(m.created))}
                      </td>
                      <td className="text-start">{m.invoiceNo}</td>
                      <td>
                        {m.totAmount.toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </td>
                      <td>{Number(m.totQty).toFixed(1)}</td>
                    </tr>
                  </>
                ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}

export class SalesReport extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      dateRange: "Today",
      result: { totSales: 0, totTax: 0, totOrders: 0 },
      productPurchaseResult: { totSales: 0, totTax: 0, totOrders: 0 },
      rawmaterialPurchaseResult: { totSales: 0, totTax: 0, totOrders: 0 },
      accountrevenues: "",
      accountexpenses: "",
      startDate: new Date(),
      endDate: new Date(),
      reportGenerated: false,
    };
  }

  OnDateRangeChanged = (e) => {
    const { name, value } = e.target;
    this.setState({ dateRange: value }, () => {
      this.setState({ result: [] }, () => {});
    });
    this.setState({ reportGenerated: false });
  };

  GetReports = () => {
    this.GetSalesReport();
    this.GetProductPurchaseReport();
    this.GetRawMaterialPurchaseReport();
    this.GetAccountRevenues();
    this.GetAccountExpenses();
    this.setState({ reportGenerated: true });
  };

  GetSalesReport = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetSalesReport/" +
        this.context.storeId +
        "/" +
        this.state.dateRange +
        "/" +
        this.context.GetTicks(this.state.startDate) +
        "/" +
        this.context.GetTicks(this.state.endDate)
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ result: data });
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  GetProductPurchaseReport = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetProductPurchaseReport/" +
        this.context.storeId +
        "/" +
        this.state.dateRange +
        "/" +
        this.context.GetTicks(this.state.startDate) +
        "/" +
        this.context.GetTicks(this.state.endDate)
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ productPurchaseResult: data });
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // alert("GetOrders fetch err:" + error);
      });
  };
  GetRawMaterialPurchaseReport = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetRawMaterialPurchaseReport/" +
        this.context.storeId +
        "/" +
        this.state.dateRange +
        "/" +
        this.context.GetTicks(this.state.startDate) +
        "/" +
        this.context.GetTicks(this.state.endDate)
    )
      .then((response) => response.json())
      .then((data) => {
        this.setState({ rawmaterialPurchaseResult: data });
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // alert("GetOrders fetch err:" + error);
      });
  };
  GetAccountRevenues = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetAccountRevenuesReport/" +
        this.context.storeId +
        "/" +
        this.state.dateRange +
        "/" +
        this.context.GetTicks(this.state.startDate) +
        "/" +
        this.context.GetTicks(this.state.endDate)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ accountrevenues: data }, () => {});
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };
  GetAccountExpenses = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetAccountExpenses/" +
        this.context.storeId +
        "/" +
        this.state.dateRange +
        "/" +
        this.context.GetTicks(this.state.startDate) +
        "/" +
        this.context.GetTicks(this.state.endDate)
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ accountexpenses: data }, () => {});
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  DownloadToCSV = () => {
    var headings = "";
    var reportDetails = "Sales & Expenses Report\n";
    reportDetails += this.context.GetDateResult(
      this.state.dateRange,
      this.state.startDate,
      this.state.endDate
    );
    var rows = "";

    rows =
      "Type,Total Orders,Net Amount, Taxable Value, Tax Amount, CGST, SGST\n";

    //1. Product Sales Report
    if (this.state.result) {
      rows +=
        "Product Sales," +
        this.state.result.totOrders +
        "," +
        Number(this.state.result.totSales + this.state.result.totTax) +
        "," +
        Number(this.state.result.totSales) +
        "," +
        Number(this.state.result.totTax) +
        "," +
        Number(this.state.result.totTax / 2) +
        "," +
        Number(this.state.result.totTax / 2) +
        "\n";
    }

    //2. Product Purchases Report
    if (this.state.productPurchaseResult != "") {
      rows +=
        "Product Purchases" +
        "," +
        this.state.productPurchaseResult.totOrders +
        "," +
        Number(
          this.state.productPurchaseResult.totSales +
            this.state.productPurchaseResult.totTax
        ) +
        "," +
        Number(this.state.productPurchaseResult.totSales) +
        "," +
        Number(this.state.productPurchaseResult.totTax) +
        "," +
        Number(this.state.productPurchaseResult.totTax / 2) +
        "," +
        Number(this.state.productPurchaseResult.totTax / 2) +
        "\n";
    }
    //3. product Purchase Report
    if (this.state.rawmaterialPurchaseResult != "") {
      rows +=
        "RawMaterial Purchases," +
        this.state.rawmaterialPurchaseResult.totOrders +
        "," +
        Number(
          this.state.rawmaterialPurchaseResult.totSales +
            this.state.rawmaterialPurchaseResult.totTax
        ) +
        "," +
        Number(this.state.rawmaterialPurchaseResult.totSales) +
        "," +
        Number(this.state.rawmaterialPurchaseResult.totTax) +
        "," +
        Number(this.state.rawmaterialPurchaseResult.totTax / 2) +
        "," +
        Number(this.state.rawmaterialPurchaseResult.totTax / 2) +
        "\n";
    }

    rows += "\n\n\n";
    rows += "SUMMARY\n";
    rows += "Details,Value\n";
    rows +=
      "Tot.Revenue with Taxes(A)," +
      Number(this.state.result.totSales + this.state.result.totTax) +
      "\n";
    rows +=
      "Tot.Expenses with Taxes(B)," +
      Number(
        this.state.productPurchaseResult.totSales +
          this.state.productPurchaseResult.totTax +
          this.state.rawmaterialPurchaseResult.totSales +
          this.state.rawmaterialPurchaseResult.totTax
      ) +
      "\n";
    rows +=
      "A-B," +
      Number(
        this.state.result.totSales +
          this.state.result.totTax -
          (this.state.productPurchaseResult.totSales +
            this.state.productPurchaseResult.totTax +
            this.state.rawmaterialPurchaseResult.totSales +
            this.state.rawmaterialPurchaseResult.totTax)
      ) +
      "\n";
    rows +=
      "Tot.Tax Paid towards Purchase(C)," +
      Number(
        this.state.productPurchaseResult.totTax +
          this.state.rawmaterialPurchaseResult.totTax
      ) +
      "\n";
    rows +=
      "Tot.Tax Received from Sales(D)," + Number(this.state.result.totTax);

    var result = reportDetails + "\n\n" + headings + "\n" + rows;
    const element = document.createElement("a");
    const file = new Blob([result], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download =
      "salesandexpensesreport-" +
      new Date().getDate() +
      (new Date().getMonth() + 1) +
      new Date().getFullYear() +
      "-" +
      new Date().getMinutes() +
      new Date().getSeconds() +
      ".csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  componentDidMount = () => {
    //this.GetSalesByProduct();
  };

  onStartDateChange = (date) => {
    this.setState({ startDate: date });
  };
  onEndDateChange = (date) => {
    this.setState({ endDate: date });
  };

  render() {
    return (
      <>
        <div>
          {window.innerWidth > 1200 ? (
            <>
              <tr>
                <td>
                  <InputGroup className="mb-1">
                    <InputGroup.Text id="basic-addon1">
                      Date Range
                    </InputGroup.Text>
                    <Form.Select
                      value={this.state.dateRange}
                      onChange={(e) => {
                        this.OnDateRangeChanged(e);
                      }}
                    >
                      <option value="Custom">Custom</option>
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Last 3 Days">Last 3 Days</option>
                      <option value="Last 7 Days">Last 7 Days</option>
                      <option value="This Month">This Month</option>
                      <option value="Last Month">Last Month</option>
                      <option value="Last 3 Months">Last 3 Months</option>
                      <option value="All">All</option>
                    </Form.Select>
                  </InputGroup>
                </td>
                {this.state.dateRange == "Custom" ? (
                  <>
                    <td>
                      StartDate:{" "}
                      <DateTimePicker
                        onChange={this.onStartDateChange}
                        value={this.state.startDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                    </td>
                    <td>
                      <Stack direction="horizontal">
                        EndDate:
                        <DateTimePicker
                          onChange={this.onEndDateChange}
                          value={this.state.endDate}
                          format="dd-MMM-yy hh:mm a"
                        />
                      </Stack>
                    </td>
                  </>
                ) : (
                  <></>
                )}
                <td>
                  <Button onClick={(e) => this.GetReports()}>
                    Show Report
                  </Button>
                </td>
                <td className="">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {this.state.reportGenerated == true ? (
                    <>
                      <Button
                        // className="mainthemecolor"
                        variant="danger"
                        onClick={() => this.DownloadToCSV()}
                      >
                        <BiDownload size="20"></BiDownload>
                        Download
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </>
          ) : (
            <>
              <tr>
                <td>
                  <InputGroup className="mb-1">
                    <InputGroup.Text id="basic-addon1">
                      Date Range
                    </InputGroup.Text>
                    <Form.Select
                      value={this.state.dateRange}
                      onChange={(e) => {
                        this.OnDateRangeChanged(e);
                      }}
                    >
                      <option value="Custom">Custom</option>
                      <option value="Today">Today</option>
                      <option value="Yesterday">Yesterday</option>
                      <option value="Last 3 Days">Last 3 Days</option>
                      <option value="Last 7 Days">Last 7 Days</option>
                      <option value="This Month">This Month</option>
                      <option value="Last Month">Last Month</option>
                      <option value="Last 3 Months">Last 3 Months</option>
                      <option value="All">All</option>
                    </Form.Select>
                  </InputGroup>
                  {this.state.dateRange == "Custom" ? (
                    <>
                      StartDate:{" "}
                      <DateTimePicker
                        onChange={this.onStartDateChange}
                        value={this.state.startDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                      EndDate:
                      <DateTimePicker
                        onChange={this.onEndDateChange}
                        value={this.state.endDate}
                        format="dd-MMM-yy hh:mm a"
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </td>

                <td>
                  <Button onClick={(e) => this.GetReports()}>
                    Show Report
                  </Button>
                </td>
                <td className="">
                  {this.state.reportGenerated == true ? (
                    <>
                      <Button
                        // className="mainthemecolor"
                        variant="danger"
                        onClick={() => this.DownloadToCSV()}
                      >
                        <BiDownload size="20"></BiDownload>
                        Download
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </td>
              </tr>
            </>
          )}

          <Table
            bordered
            hover
            className="bg-white text-end"
            style={{
              tableLayout: "revert",
              verticalAlign: "middle",
              fontFamily: "monospace",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            <thead>
              <th className="text-center">Type</th>
              <th className="text-center">Total Orders</th>
              <th>Net Amount</th>
              <th>Taxable Value</th>
              <th>Tax Amount</th>
              <th>CGST</th>
              <th>SGST</th>
            </thead>
            <tbody>
              {this.state.reportGenerated == true ? (
                <>
                  {/* Sales Report */}
                  {this.state.result != "" ? (
                    <>
                      <tr>
                        <td className="text-center">Product Sales</td>
                        <td className="text-center">
                          {this.state.result.totOrders}
                        </td>
                        <td>
                          {Number(
                            this.state.result.totSales +
                              this.state.result.totTax
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>
                          {Number(this.state.result.totSales).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>
                          {Number(this.state.result.totTax).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>
                          {Number(this.state.result.totTax / 2).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>
                          {Number(this.state.result.totTax / 2).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* product Purchase Report */}
                  {this.state.productPurchaseResult != "" ? (
                    <>
                      <tr>
                        <td className="text-center">Product Purchases</td>
                        <td className="text-center">
                          {this.state.productPurchaseResult.totOrders}
                        </td>
                        <td>
                          {Number(
                            this.state.productPurchaseResult.totSales +
                              this.state.productPurchaseResult.totTax
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>
                          {Number(
                            this.state.productPurchaseResult.totSales
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>
                          {Number(
                            this.state.productPurchaseResult.totTax
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>
                          {Number(
                            this.state.productPurchaseResult.totTax / 2
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>
                          {Number(
                            this.state.productPurchaseResult.totTax / 2
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* product Purchase Report */}
                  {this.state.rawmaterialPurchaseResult != "" ? (
                    <>
                      <tr>
                        <td className="text-center">RawMaterial Purchases</td>
                        <td className="text-center">
                          {this.state.rawmaterialPurchaseResult.totOrders}
                        </td>
                        <td>
                          {Number(
                            this.state.rawmaterialPurchaseResult.totSales +
                              this.state.rawmaterialPurchaseResult.totTax
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>
                          {Number(
                            this.state.rawmaterialPurchaseResult.totSales
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>
                          {Number(
                            this.state.rawmaterialPurchaseResult.totTax
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>
                          {Number(
                            this.state.rawmaterialPurchaseResult.totTax / 2
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>
                          {Number(
                            this.state.rawmaterialPurchaseResult.totTax / 2
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                      </tr>
                    </>
                  ) : (
                    <></>
                  )}
                  {/* Other Revenues Report */}
                  {this.state.accountrevenues != "" ? (
                    <>
                      <tr>
                        <td className="text-center">Other Revenues</td>
                        <td className="text-center">
                          {/* {this.state.accountrevenues.totOrders} */}0
                        </td>
                        <td>
                          {Number(
                            this.state.accountrevenues.total
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>
                          {Number(
                            this.state.accountrevenues.total
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                        <td>0</td>
                        <td>0</td>
                        <td>0</td>
                      </tr>
                    </>
                  ) : (
                    <></>
                  )}
                </>
              ) : (
                <></>
              )}
            </tbody>
          </Table>
          {this.state.reportGenerated == true ? (
            <>
              <div className="bg-white text-center p-5 mx-5">
                <div className="bg-white text-center p-5 mx-5">
                  <h2>Summary</h2>
                  <Table
                    bordered
                    striped
                    hover
                    className="bg-white text-start p-5 "
                    style={{
                      tableLayout: "revert",
                      verticalAlign: "middle",
                      fontFamily: "monospace",
                      fontSize: "16px",
                      fontWeight: "bold",
                    }}
                  >
                    <thead
                      style={{
                        tableLayout: "revert",
                        verticalAlign: "middle",
                        fontFamily: "monospace",
                        fontSize: "20px",
                        fontWeight: "bold",
                      }}
                    >
                      <th className="text-start" width="50%">
                        Details
                      </th>
                      <th className="text-end" width="50%">
                        Value
                      </th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Tot.Revenue with Taxes(A) </td>
                        <td className="text-end">
                          {Number(
                            this.state.result.totSales +
                              this.state.result.totTax
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Tot.Expenses with Taxes(B)</td>
                        <td className="text-end">
                          {Number(
                            this.state.productPurchaseResult.totSales +
                              this.state.productPurchaseResult.totTax +
                              this.state.rawmaterialPurchaseResult.totSales +
                              this.state.rawmaterialPurchaseResult.totTax
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>A-B</td>
                        <td className="text-end">
                          {Number(
                            this.state.result.totSales +
                              this.state.result.totTax -
                              (this.state.productPurchaseResult.totSales +
                                this.state.productPurchaseResult.totTax +
                                this.state.rawmaterialPurchaseResult.totSales +
                                this.state.rawmaterialPurchaseResult.totTax)
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Tot.Tax Paid towards Purchase(C)</td>
                        <td className="text-end">
                          {Number(
                            this.state.productPurchaseResult.totTax +
                              this.state.rawmaterialPurchaseResult.totTax
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                      </tr>
                      <tr>
                        <td>Tot.Tax Received from Sales(D)</td>
                        <td className="text-end">
                          {Number(this.state.result.totTax).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </td>
                      </tr>
                    </tbody>
                  </Table>
                </div>
              </div>
            </>
          ) : (
            <></>
          )}
        </div>
      </>
    );
  }
}
