import React, { Component } from "react";
import { Navigate } from "react-router-dom";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import { Country, State, City } from "country-state-city";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import {
  Row,
  Col,
  Form,
  Nav,
  Table,
  Navbar,
  Tooltip,
  Button,
  ButtonToolbar,
} from "react-bootstrap";

export class ShippingConfig extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      shippingDetails: "",
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
    };
  }
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnSaved = () => {
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.GetShippingDetails();
    this.setState({ reload: false }, () => {
      this.setState({ reload: true });
    });
  };

  handleDeliveryCharges = (event) => {
    const { name, value } = event.target;
    switch (event.target.id) {
      case "FreeDelivery":
        if (value == "on") {
          this.state.shippingDetails.freeDelivery = true;
          this.state.shippingDetails.deliveryCharges = false;
        }
        break;
      case "DeliveryCharge":
        if (value == "on") {
          this.state.shippingDetails.deliveryCharges = true;
          this.state.shippingDetails.freeDelivery = false;
        }
        break;
    }
    this.setState({ shippingDetails: this.state.shippingDetails });
  };

  handleDeliveryRangeChange = (event) => {
    const { name, value } = event.target;
    switch (event.target.id) {
      case "5km":
        this.state.shippingDetails.deliveryRange = 1;
        break;
      case "city":
        this.state.shippingDetails.deliveryRange = 2;
        break;
      case "state":
        this.state.shippingDetails.deliveryRange = 3;
        break;
      case "country":
        this.state.shippingDetails.deliveryRange = 4;
        break;
      case "international":
        this.state.shippingDetails.deliveryRange = 5;
        break;
    }
    this.setState({ shippingDetails: this.state.shippingDetails });
  };
  handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name.trim()) {
      //Store
      case "address":
        this.state.shippingDetails.address = value;
        break;
      case "landMark":
        this.state.shippingDetails.landMark = value;
        break;
      case "city":
        this.state.shippingDetails.city = value;
        break;
      case "pincode":
        this.state.shippingDetails.pincode = value;
        break;
      case "deliveryDuration":
        this.state.shippingDetails.deliveryDuration = value;
        break;
      case "minimumOrderLimitForDelivery":
        this.state.shippingDetails.minimumOrderLimitForDelivery = value;
        break;
      case "deliveryPincodes":
        this.state.shippingDetails.deliveryPincodes = value;
        break;
      case "deliveryRange":
        this.state.shippingDetails.deliveryRange = value;
        break;
      case "freeDelivery":
        this.state.shippingDetails.freeDelivery = value;
        break;
      case "deliveryCharges":
        this.state.shippingDetails.deliveryCharges = value;
        break;
      case "freeDeliveryAbove":
        this.state.shippingDetails.freeDeliveryAbove = value;
        break;
      case "deliveryChargePerOrder":
        this.state.shippingDetails.deliveryChargePerOrder = value;
        break;
      case "freeDeliveryPincodes":
        this.state.shippingDetails.freeDeliveryPincodes = value;
        break;
    }
    this.setState({ shippingDetails: this.state.shippingDetails });
  };

  handleChangeCountry = (event) => {
    this.state.shippingDetails.country = event.target.value;
    this.state.shippingDetails.countryLong =
      event.target[event.target.selectedIndex].text;
    this.setState({ shippingDetails: this.state.shippingDetails });
  };

  handleChangeState = (event) => {
    this.state.shippingDetails.state = event.target.value;
    this.setState({ shippingDetails: this.state.shippingDetails });
  };

  GetShippingDetails = () => {
    const config = {
      headers: { Authorization: `Bearer ${this.context.userData.bearertoken}` },
    };
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetShippingDetails/" +
        this.context.storeId,
      config
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ shippingDetails: data });
        }
      })
      .catch((error) => {
        this.ShowMessagBox("Warning", "Session Timeout!");
        this.context.clearUserData();
        this.setState({ sessionTimedout: true });
      });
  };

  OnSave = (e) => {
    e.preventDefault();
    this.ShowLoadSpinner();

    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.context.userData.bearertoken}`,
      },
      body: JSON.stringify(this.state.shippingDetails),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/UpdateShippingDetails/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.GetShippingDetails();
          this.HideLoadSpinner();
          this.ShowMessagBox("Information", "Saved Successfully!");
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed!");
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed! " + error);
      });
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.state.shippingDetails == "") this.GetShippingDetails();
  }

  render() {
    if (this.state.sessionTimedout == true) {
      return <Navigate to="/UserLogin" />;
    }
    return (
      <>
        {this.state.msgboxOpen === true ? (
          <MessageBox
            open={this.state.msgboxOpen}
            title={this.state.msgboxTitle}
            message={this.state.msgboxMessage}
            callback={this.CloseMessagBoxCallback}
          />
        ) : (
          ""
        )}
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <div className="Container-fluid p-1 pagebackground">
          <Row>
            <Col className="pagebackground " xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="p-2 bg-white">
              <Form onSubmit={this.OnSave}>
                <Navbar className="secondarythemecolor position-sticky fixed-top  my-0">
                  <h4>
                    {" "}
                    &nbsp; &nbsp;Shipping / Delivery Configuration &nbsp;
                  </h4>
                  <Navbar.Collapse className="justify-content-end mx-0 ">
                    <Nav>
                      <ButtonToolbar className="text-end align-middle">
                        <Button
                          className="mainthemeColorCode"
                          size=""
                          // onClick={(e) => {
                          //   this.OnSave(e);
                          // }}
                          type="submit"
                        >
                          <BiSave size="26" />
                          Save
                        </Button>
                        &nbsp;
                        <Button
                          className="mainthemeColorCode"
                          size=""
                          onClick={() => this.OnRefreshButtonClick()}
                        >
                          <BiRefresh size="26"></BiRefresh>
                          Undo
                        </Button>
                        &nbsp;
                      </ButtonToolbar>
                    </Nav>
                  </Navbar.Collapse>
                </Navbar>
                <br></br>
                {this.state.shippingDetails == "" ? (
                  ""
                ) : (
                  <>
                    <div>
                      <h5>
                        <b>Store Shipping Location</b>
                      </h5>
                    </div>
                    <div className=" border">
                      <Row className="p-2 m-2 bg-light">
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom07"
                        >
                          <Form.Label>Address (*)</Form.Label>
                          <Form.Control
                            as="textarea"
                            rows={1}
                            required
                            name="address"
                            placeholder=""
                            //autoComplete="new-off"
                            value={this.state.shippingDetails.address}
                            onChange={(e) => this.handleInputChange(e)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter address
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="6"
                          controlId="validationCustom08"
                        >
                          <Form.Label>Landmark (if any) </Form.Label>
                          <Form.Control
                            type="text"
                            placeholder=""
                            //autoComplete="new-off"
                            className="bg-white"
                            name="landMark"
                            value={this.state.shippingDetails.landMark}
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Form.Group>
                      </Row>
                      <Row className="p-2 m-2 bg-white">
                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationCustom09"
                        >
                          <Form.Label>Country (*)</Form.Label>
                          <Form.Select
                            name="country"
                            value={this.state.shippingDetails.country}
                            required
                            // disabled={this.context.storeSettings.disableCountrySelection}
                            // disabled
                            onChange={this.handleChangeCountry}
                          >
                            {Country.getAllCountries().map((country) => (
                              <>
                                <option value={country.isoCode}>
                                  {/* <option value={country.name}> */}
                                  {country.name}
                                </option>
                              </>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please select country
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationCustom10"
                        >
                          <Form.Label>State (*)</Form.Label>
                          <Form.Select
                            value={this.state.shippingDetails.state}
                            required
                            disabled={
                              this.context.storeSettings.disableStateSelection
                            }
                            onChange={this.handleChangeState}
                          >
                            {State.getStatesOfCountry(
                              this.state.shippingDetails.country
                            ).map((s1) => (
                              <>
                                <option>{s1.name}</option>
                              </>
                            ))}
                          </Form.Select>
                          <Form.Control.Feedback type="invalid">
                            Please select State
                          </Form.Control.Feedback>
                        </Form.Group>

                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationCustom11"
                        >
                          <Form.Label>City (*)</Form.Label>
                          <Form.Control
                            type="text"
                            name="city"
                            placeholder=""
                            //autoComplete="new-off"

                            required
                            className="bg-white"
                            value={this.state.shippingDetails.city}
                            onChange={(e) => this.handleInputChange(e)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please Enter City
                          </Form.Control.Feedback>
                        </Form.Group>
                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="validationCustom12"
                        >
                          <Form.Label>Pincode (*)</Form.Label>
                          <Form.Control
                            type="text"
                            name="pincode"
                            minLength={this.context.storeSettings.pincodeLength}
                            maxLength={this.context.storeSettings.pincodeLength}
                            placeholder=""
                            //autoComplete="new-off"
                            className="bg-white"
                            required
                            value={this.state.shippingDetails.pincode}
                            onChange={(e) => this.handleInputChange(e)}
                          />
                          <Form.Control.Feedback type="invalid">
                            Please enter valid Pincode.
                          </Form.Control.Feedback>
                        </Form.Group>
                      </Row>
                    </div>
                    <br></br>
                    <div>
                      <h5>
                        <b>Delivery Limits</b>
                      </h5>
                    </div>
                    <div className="border  ">
                      <Row className="p-2 m-2 bg-light">
                        <Col width="25%">
                          <Form.Label>Delivery Note (*)</Form.Label>
                        </Col>
                        <Col width="75%">
                          <Form.Control
                            as="textarea"
                            rows={2}
                            name="deliveryDuration"
                            value={this.state.shippingDetails.deliveryDuration}
                            className="text-dark"
                            required
                            maxLength={499}
                            placeholder="3-5 days"
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Row>
                      <Row className="p-2 m-2 bg-light">
                        <Col width="25%">
                          Minimum Order value for Delivery (Leave it blank if no
                          limit)
                        </Col>
                        <Col width="75%">
                          <Form.Control
                            type="number"
                            name="minimumOrderLimitForDelivery"
                            value={
                              this.state.shippingDetails
                                .minimumOrderLimitForDelivery
                            }
                            className="text-dark"
                            placeholder=""
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Row>
                      <Row className="p-2 m-2 bg-light">
                        <Col width="25%">
                          <b>Delivery Pincodes: </b> <br /> 1) Leave it blank if
                          no restriction <br />
                          2) Use comma(,) as delimiter between pincodes. <br />
                          3)Use hyphen(-) for range. <br />
                          Ex: 560100,560101,560500-560600,560800
                        </Col>
                        <Col width="75%">
                          <Form.Control
                            as="textarea"
                            rows={5}
                            name="deliveryPincodes"
                            value={this.state.shippingDetails.deliveryPincodes}
                            className="text-dark"
                            placeholder="enter delivery pincodes"
                            maxLength={2499}
                            onChange={(e) => this.handleInputChange(e)}
                          />
                        </Col>
                      </Row>
                    </div>
                    <br></br>
                    <div>
                      <h5>
                        <b>Delivery Charges</b>
                      </h5>
                    </div>
                    <div className="border ">
                      <Row className="p-2 m-2 bg-light">
                        <Col width="100% border border-dark">
                          <Form.Check
                            className=""
                            type="radio"
                            checked={
                              this.state.shippingDetails.freeDelivery == true
                                ? "true"
                                : ""
                            }
                            name="deliverycharges"
                            label="Free Delivery"
                            id="FreeDelivery"
                            onChange={this.handleDeliveryCharges}
                          ></Form.Check>
                          <Form.Check
                            className="my-4"
                            type="radio"
                            checked={
                              this.state.shippingDetails.deliveryCharges == true
                                ? "true"
                                : ""
                            }
                            name="deliverycharges"
                            label="Delivery Charge Calculation"
                            id="DeliveryCharge"
                            onChange={this.handleDeliveryCharges}
                          ></Form.Check>
                          <div className="px-3 py-2 border border-3 w-100 bg-light">
                            <Row className="p-1">
                              <Col>Free delivery above</Col>
                              <Col>
                                <div>
                                  <Form.Control
                                    type="text"
                                    name="freeDeliveryAbove"
                                    value={
                                      this.state.shippingDetails
                                        .deliveryCharges == true
                                        ? this.state.shippingDetails
                                            .freeDeliveryAbove
                                        : ""
                                    }
                                    required
                                    disabled={
                                      this.state.shippingDetails
                                        .deliveryCharges == true
                                        ? false
                                        : true
                                    }
                                    placeholder="Enter order amount"
                                    autoComplete="off"
                                    onChange={(e) => this.handleInputChange(e)}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="p-1">
                              <Col>Delivery Charges per Order</Col>
                              <Col>
                                <div>
                                  <Form.Control
                                    type="text"
                                    name="deliveryChargePerOrder"
                                    value={
                                      this.state.shippingDetails
                                        .deliveryCharges == true
                                        ? this.state.shippingDetails
                                            .deliveryChargePerOrder
                                        : ""
                                    }
                                    required
                                    disabled={
                                      this.state.shippingDetails
                                        .deliveryCharges == true
                                        ? false
                                        : true
                                    }
                                    placeholder="Enter delivery charges per order"
                                    autoComplete="off"
                                    onChange={(e) => this.handleInputChange(e)}
                                  />
                                </div>
                              </Col>
                            </Row>
                            <Row className="p-1">
                              <Col>Free Delivery Pincodes</Col>
                              <Col>
                                <div>
                                  <Form.Control
                                    as="textarea"
                                    rows={3}
                                    name="freeDeliveryPincodes"
                                    value={
                                      this.state.shippingDetails
                                        .freeDeliveryPincodes
                                    }
                                    disabled={
                                      this.state.shippingDetails
                                        .deliveryCharges == true
                                        ? false
                                        : true
                                    }
                                    placeholder="Enter free deliery Pincode"
                                    autoComplete="off"
                                    onChange={(e) => this.handleInputChange(e)}
                                  />
                                </div>
                              </Col>
                            </Row>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </>
                )}
              </Form>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
