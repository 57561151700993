import React, { Component } from "react";
import CartContext from "./CartContext";
import { BlobServiceClient } from "@azure/storage-blob";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import Swal from "sweetalert2";
import { Navigate } from "react-router-dom";
import axios from "axios";

import {
  Button,
  Form,
  Row,
  Col,
  Container,
  Modal,
  Table,
  Stack,
} from "react-bootstrap";

import {} from "react-bootstrap";

export class OrderDetails extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      orderDetail: JSON.parse(JSON.stringify(this.props.orderDetail)),
      updatedOrderedItems: this.props.orderDetail.customerOrderedItems,
      changedOrderedState: 0,
      RefreshPage: false,
      reloadOrderedItems: true,
    };
  }
  RefreshOrderDetail = () => {
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "StoreManager/GetOrdereById/" +
          this.props.orderDetail.id
      )
      .then((response) => {
        if (response.status === 200) {
          this.setState({ orderDetail: response.data }, () => {
            this.Reload();
          });
        }
      })
      .catch((error) => {
        alert("RefreshOrderDetail - Failed! ", error);
      });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  StoreChangedOrderState = (newstate) => {
    this.setState({ changedOrderedState: newstate });
    //this.state.orderDetail = JSON.parse(JSON.stringify(this.props.orderDetail));
    if (
      (newstate == this.context.OrderStates.CANCELLED ||
        newstate == this.context.OrderStates.CANCELPACKAGE ||
        newstate == this.context.OrderStates.CANCELDELIVERY) &&
      this.state.orderDetail.totalAmtPaid > 0
    ) {
      this.state.orderDetail.amtToRefund = this.state.orderDetail.totalAmtPaid;
      this.state.orderDetail.couponValue = 0;
      this.state.orderDetail.isCancelled = true;
      this.setState({ orderDetail: this.state.orderDetail }, () => {
        this.setState({ reloadOrderedItems: false }, () => {
          this.setState({ updatedOrderedItems: [] });
          this.setState({ reloadOrderedItems: true });
        });
      });
    } else if (
      (newstate == this.context.OrderStates.REFUNDED ||
        newstate == this.context.OrderStates.REFUNDEDANDCLOSED ||
        newstate == this.context.OrderStates.ADDTOINVENTORY ||
        newstate == this.context.OrderStates.ADDEDTOINVENTORY) &&
      this.state.orderDetail.totalAmtPaid > 0 &&
      this.state.orderDetail.isCancelled == true
    ) {
      this.state.orderDetail.amtToRefund = this.state.orderDetail.totalAmtPaid;
      this.setState({ orderDetail: this.state.orderDetail }, () => {
        this.setState({ reloadOrderedItems: false }, () => {
          this.setState({ updatedOrderedItems: [] });
          this.setState({ reloadOrderedItems: true });
        });
      });
    } else {
      if (this.state.orderDetail.totalAmtPaid > 0) {
        this.state.orderDetail.amtToRefund = this.GetRefundAmount();
        this.setState({ orderDetail: this.state.orderDetail });
      }
    }
  };

  GetRefundAmount = () => {
    var subTotalAmt = 0;
    var taxValue = 0;
    var deliveryAmt = 0;
    var totalAmt = 0;
    var amtToRefund = 0;

    if (
      this.state.updatedOrderedItems == undefined ||
      this.state.updatedOrderedItems.length == 0
    ) {
      return this.props.orderDetail.amtToRefund;
    }
    this.state.updatedOrderedItems.map((item) => {
      if (item.availability == true) {
        //1. update subTotalAmt
        subTotalAmt +=
          item.mrp * item.qty - (item.mrp * item.qty * item.discount) / 100;
      }
    });

    //2. Update deliveryAmt. TODO will deliveramt changes here
    deliveryAmt = this.state.orderDetail.deliveryAmt;
    if (subTotalAmt == 0) deliveryAmt = 0;

    taxValue =
      ((subTotalAmt + deliveryAmt - this.state.orderDetail.couponValue) *
        this.state.orderDetail.taxPercentage) /
      100;

    //3. update totalAmt
    totalAmt = Math.round(
      subTotalAmt + deliveryAmt - this.state.orderDetail.couponValue + taxValue,
      0
    );

    //4. update amount to refund if customer already paid
    amtToRefund =
      this.state.orderDetail.totalAmtPaid > 0
        ? this.state.orderDetail.totalAmtPaid - totalAmt
        : 0;

    return amtToRefund < 1 ? 0 : Math.round(amtToRefund, 0);
  };
  StorePaymentStatus = (value) => {
    if (value == 1) {
      //paid
      this.state.orderDetail.totalAmtPaid = this.props.orderDetail.totalAmt;
    }
    if (value == 2) {
      //unpaid
      this.state.orderDetail.totalAmtPaid = 0;
      this.state.orderDetail.amtToRefund = 0;
    }
    this.state.orderDetail.paymentStatusId = Number(value);
    this.state.orderDetail.paymentStatusName =
      this.context.customerOrderPaymentStatusTypes.filter(
        (f) => f.id == Number(value)
      )[0].statusName;
    this.setState({ changedOrderedState: 0 });
    this.setState({ orderDetail: this.state.orderDetail }, () => {
      if (value == 1 || value == 2) {
        this.UpdateOrderDetail();
      }
    });
  };

  UpdateOrderDetails = (orderDetail) => {
    this.setState({ orderDetail: orderDetail });
    //this.Reload();
  };

  StoreUpdatedOrderedItems = (order) => {
    this.setState({ changedOrderedState: 0 }, () => {
      this.setState({ updatedOrderedItems: order }, () =>
        this.UpdateOrderDetail()
      );
    });
  };
  UpdateOrderDetail = () => {
    //0.  update totalQty. TODO:

    var subTotalAmt = 0;
    var taxValue = 0;
    var deliveryAmt = 0;
    var totalAmt = 0;
    var amtToRefund = 0;
    var couponValue = 0;
    var amountToRefundOnReturns = 0;

    if (this.state.updatedOrderedItems.length == 0) return;
    this.state.updatedOrderedItems.map((item) => {
      if (item.availability == true) {
        //1. update subTotalAmt
        subTotalAmt +=
          item.mrp * item.qty - (item.mrp * item.qty * item.discount) / 100;
      }
      amountToRefundOnReturns += Number(item.amountToRefundOnReturns);
    });

    //2. Update deliveryAmt. TODO will deliveramt changes here
    deliveryAmt = this.props.orderDetail.deliveryAmt;
    couponValue = this.props.orderDetail.couponValue;
    if (subTotalAmt == 0) {
      Swal.fire({
        title: "Information",
        text: "Since all Items are Unavailable, do Cancel the order!",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
        }
      });
      deliveryAmt = 0;
      couponValue = 0;
    }

    taxValue =
      ((subTotalAmt + deliveryAmt - couponValue) *
        this.state.orderDetail.taxPercentage) /
      100;

    //3. update totalAmt
    totalAmt = subTotalAmt + deliveryAmt - couponValue + taxValue;

    //4. update amount to refund if customer already paid
    amtToRefund =
      this.state.orderDetail.totalAmtPaid > 0
        ? this.state.orderDetail.totalAmtPaid - totalAmt
        : 0;

    //update in orderDetails State
    //var orderDetailTemp = JSON.parse(JSON.stringify(this.props.orderDetail));
    var orderDetailTemp = JSON.parse(JSON.stringify(this.state.orderDetail));
    orderDetailTemp.subTotalAmt = subTotalAmt;
    orderDetailTemp.deliveryAmt = deliveryAmt;
    orderDetailTemp.taxAmount = taxValue;
    orderDetailTemp.totalAmt = totalAmt;
    orderDetailTemp.totalAmtPaid = this.state.orderDetail.totalAmtPaid;
    orderDetailTemp.amtToRefund = amtToRefund;
    orderDetailTemp.amountToRefundOnReturns = Number(amountToRefundOnReturns);
    this.setState({ orderDetail: orderDetailTemp });
    this.Reload();
  };

  Reload = () => {
    this.setState({ RefreshPage: true }, () =>
      this.setState({ RefreshPage: false })
    );
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };

  SaveOrder = () => {
    var tmporder = this.state.orderDetail;

    tmporder.orderStatus = this.state.changedOrderedState;

    var orderStatus = this.context.orderStatusTypes.filter(
      (f) => f.id == Number(this.state.changedOrderedState)
    );
    tmporder.orderStatusName = orderStatus[0].statusName;
    tmporder.orderWorkflowStatus +=
      "<p>" +
      this.context.GetInDateFormat(new Date()) +
      "[" +
      this.context.userData.emailId +
      "]" +
      "=> <b>" +
      orderStatus[0].statusName +
      "</b></p>";

    tmporder.customerOrderedItems = this.state.updatedOrderedItems;
    // tmporder.orderModified = new Date().toLocaleString();

    const requestOptions = {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmporder),
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/UpdateOrder/" +
        this.state.orderDetail.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          //this.HideLoadSpinner();
          alert("not updated");
          return;
        }
        console.log("Updated Successfully");
        this.context.GetOrdersSummary();
        //this.HideLoadSpinner();
        this.ShowMessagBox("Status", "Updated Successfully!");
        // Swal.fire({
        //   title: "Information",
        //   text: "Updated Successfully!",

        //   timer: 2000,
        // }).then((result) => {});
      })
      .catch((error) => {
        //this.HideLoadSpinner();
        alert("Failed" + error);
      });
  };
  handleSubmit = (event) => {
    event.preventDefault();
    if (this.state.changedOrderedState == 0) {
      //alert("Please change the `Order Staus` before Save!");
      Swal.fire({
        title: "Information",
        text: "Please change the `Order Staus` before Save!",
        // confirmButtonText: "yes",

        timer: 2000,
      }).then((result) => {});
      return;
    }

    this.SaveOrder();
  };

  componentDidMount = () => {
    var d1 = document.getElementById("one");
    d1.innerHTML =
      this.state.orderDetail != undefined
        ? this.state.orderDetail.orderWorkflowStatus
        : "";
  };
  render() {
    return (
      <div className="container ">
        {this.state.msgboxOpen === true ? (
          <MessageBox
            open={this.state.msgboxOpen}
            title={this.state.msgboxTitle}
            message={this.state.msgboxMessage}
            callback={this.CloseMessagBoxCallback}
          />
        ) : (
          ""
        )}
        <Modal
          {...this.props}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          size="xl"
          // className="modal-fullscreen"

          backdrop="static"
          keyboard={false}
          dialogClassName="order-modal"
        >
          <Form
            onSubmit={this.handleSubmit}
            style={{ fontFamily: "Verdana", fontSize: "14px" }}
          >
            {/* <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                Order Details
              </Modal.Title>
            </Modal.Header> */}
            <Modal.Body className="show-grid ">
              <div
                className="fixedTableHightOrder"
                style={{
                  height: window.innerHeight - 150 + "px",
                }}
              >
                <tr width="100%">
                  <td width="78%" className="p-4">
                    {this.state.RefreshPage === false ? (
                      <>
                        <OrderSummary
                          orderDetail={this.state.orderDetail}
                          StoreChangedOrderState={this.StoreChangedOrderState}
                          StorePaymentStatus={this.StorePaymentStatus}
                          OrderState={this.props.OrderState}
                          UpdateOrderDetails={this.UpdateOrderDetails}
                          RefreshOrderDetail={this.RefreshOrderDetail}
                        ></OrderSummary>
                      </>
                    ) : (
                      ""
                    )}

                    {this.state.reloadOrderedItems == true ? (
                      <>
                        <OrderedProductItems
                          orderDetail={this.state.orderDetail}
                          StoreUpdatedOrderedItems={
                            this.StoreUpdatedOrderedItems
                          }
                          OrderState={this.props.OrderState}
                        ></OrderedProductItems>
                      </>
                    ) : (
                      ""
                    )}
                  </td>
                  <td
                    width="23%"
                    className="border bg-dark text-white align-top"
                  >
                    <p
                      className="text-center  bg-secondary"
                      style={{ color: "yellow" }}
                    >
                      Order workflow Status
                    </p>
                    <tr className="bg-dark text-white">
                      <td width="100%" className="p-2">
                        <small>
                          <div id="one" className=""></div>
                        </small>
                      </td>
                    </tr>
                  </td>
                </tr>
              </div>
            </Modal.Body>

            <Modal.Footer className="bg-light">
              <Form.Group>
                {this.state.orderDetail.orderStatus == 20 ? (
                  ""
                ) : (
                  <>
                    <Button variant="primary" type="submit" size="lg">
                      Save
                    </Button>
                  </>
                )}
              </Form.Group>
              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}

export class CustomerDetails extends Component {
  static contextType = CartContext;

  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    return (
      <>
        <div>
          <h4>Customer Details</h4>
          <Row>
            <Col
              xs={6}
              className="py-2 border px-3 bg-light"
              style={{ fontSize: "12px" }}
            >
              <Row className="py-0">
                <Col className=" text-start">
                  <Form.Label size="" className="">
                    {this.props.orderDetail.customerUserType.userTypeName ==
                    "POS" ? (
                      <>Billing User Name:</>
                    ) : (
                      <>Customer Name :</>
                    )}
                  </Form.Label>
                </Col>
                <Col className="text-end">
                  <Form.Label size="" className="py-0">
                    {this.props.orderDetail.customerName == "" ||
                    this.props.orderDetail.customerName == undefined
                      ? "<guest user>"
                      : this.props.orderDetail.customerName}
                  </Form.Label>
                </Col>
              </Row>
              {this.props.orderDetail.deliveryMethodId != 4 &&
              this.props.orderDetail.deliveryMethodId != 5 ? (
                <>
                  <Row className="py-0">
                    <Col className=" text-start">
                      <Form.Label size="" className="">
                        MobileNo :
                      </Form.Label>
                    </Col>
                    <Col className="text-end">
                      <Form.Label size="" className="py-0">
                        {this.props.orderDetail.mobileNo == "" ||
                        this.props.orderDetail.mobileNo == undefined
                          ? "<guest user>"
                          : this.props.orderDetail.mobileNo}
                      </Form.Label>
                    </Col>
                  </Row>
                  <Row className="py-0">
                    <Col className=" text-start">
                      <Form.Label size="" className="">
                        Email-Id :
                      </Form.Label>
                    </Col>
                    <Col className="text-end">
                      <Form.Label size="" className="py-0">
                        {this.props.orderDetail.emailId == "" ||
                        this.props.orderDetail.emailId == undefined
                          ? "<guest user>"
                          : this.props.orderDetail.emailId}
                      </Form.Label>
                    </Col>
                  </Row>
                </>
              ) : (
                <></>
              )}
            </Col>
            <Col
              xs={6}
              className="py-2 border px-3 bg-light"
              style={{ fontSize: "12px" }}
            >
              <Row className="py-0">
                <Col className=" text-start">
                  <Form.Label size="" className="">
                    Delivery Address :
                  </Form.Label>
                </Col>
                <Col className="text-start border">
                  <Form.Label size="" className="py-0">
                    {this.props.orderDetail.deliveryAddress}
                  </Form.Label>
                </Col>
              </Row>
              <Row className="py-0">
                <Col className=" text-start">
                  <Form.Label size="" className="">
                    GOOLE MAP :
                  </Form.Label>
                </Col>
                <Col className="text-start border">
                  <a
                    target="_"
                    href={
                      "https://www.google.com/maps/search/" +
                      this.props.orderDetail.googleAddress
                    }
                  >
                    <h5>Map</h5>
                  </a>
                </Col>
              </Row>

              <Row className="py-1">
                <Col className=" text-start">
                  <Form.Label size="" className="">
                    Delivery OTP :
                  </Form.Label>
                </Col>
                <Col className="text-start border">
                  <Form.Label size="" className="py-0">
                    <h5>{this.props.orderDetail.deliveryOtp}</h5>
                  </Form.Label>
                </Col>
              </Row>

              {this.props.orderDetail.deliveryMethodId != 4 &&
              this.props.orderDetail.deliveryMethodId != 5 ? (
                <>
                  <Row className="py-1">
                    <Col className=" text-start">
                      <Form.Label size="" className="">
                        Customer's Invoice :
                      </Form.Label>
                    </Col>
                    <Col className="text-start">
                      <a
                        href={
                          this.context.store.storageBlobUrl +
                          this.context.store.storageBlobContainerName +
                          "/invoice/" +
                          this.props.orderDetail.orderId +
                          ".html" +
                          this.context.store.storageSasToken +
                          "&xyz=" +
                          new Date().toLocaleString()

                          // this.context.store.invoiceFileShareAccountUrl +
                          // this.context.store.invoiceFileShareName +
                          // "/" +
                          // this.props.orderDetail.orderId +
                          // ".html" +
                          // this.context.store.storageSasToken +
                          // "&xyz=" +
                          // new Date().toLocaleString()
                        }
                        // target="_blank"
                      >
                        Download Invoice
                      </a>
                      {/* <br />
                  <Button onClick={this.Print}>Print</Button> */}
                    </Col>
                  </Row>
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
export class DeliveryDetails extends Component {
  static contextType = CartContext;

  constructor(props) {
    super(props);
    this.state = {
      orderDetail: this.props.orderDetail,
      deliveryusers: [],
    };
  }
  GetUsers = () => {
    const config = {
      headers: { Authorization: `Bearer ${this.context.userData.bearertoken}` },
    };
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetStoreUserAccounts/" +
        this.context.storeId
      // config
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({
            deliveryusers: data.filter((f) => f.storeRoleId != 1),
          });
        }
      })
      .catch((error) => {
        alert("Session Timeout! Signout and Signin again!");
      });
  };
  handleDeliveryBy = (event) => {
    // this.setState({ paymentmode: event.target.id });
    if (event.target.id == "selfDelivery")
      this.state.orderDetail.deliveryBy = 1;
    if (event.target.id == "3rdParty") this.state.orderDetail.deliveryBy = 2;
    this.setState({ orderDetail: this.state.orderDetail });
  };
  handleInputChange = (e, index) => {
    const { name, value } = e.target;

    if (name == "deliveryPersonId")
      this.state.orderDetail.deliveryPersonId = value;
    else if (name == "deliveryTrackingId")
      this.state.orderDetail.deliveryTrackingId = value;
    else if (name == "deliveryCarrierName")
      this.state.orderDetail.deliveryCarrierName = value;
    else if (name == "deliveryDetails")
      this.state.orderDetail.deliveryDetails = value;
    this.setState({ orderDetail: this.state.orderDetail }, () => {
      this.props.UpdateOrderDetails(this.state.orderDetail);
    });
  };
  OnDeliveryPersonChange = (e) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/UpdateDeliveryPersonId/" +
        this.state.orderDetail.id +
        "/" +
        this.state.orderDetail.deliveryPersonId,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          //this.HideLoadSpinner();
          alert("not updated");
          return;
        }
        alert("Updated Successfully!");
      })
      .catch((error) => {
        alert("Failed" + error);
      });
  };
  componentDidMount = () => {
    if (this.state.deliveryusers.length == 0) this.GetUsers();
  };
  render() {
    return (
      <>
        {this.props.orderDetail.deliveryMethodId != 4 &&
        this.props.orderDetail.deliveryMethodId != 5 ? (
          <>
            <div>
              <h4>Delivery Details</h4>
              <div className="py-2 border px-3 bg-light">
                <Row>
                  <Col>
                    <Form.Label size="" className="">
                      Delivery Method :
                    </Form.Label>
                  </Col>
                  <Col>
                    {/* Delivery */}
                    {this.props.orderDetail.deliveryMethodId ==
                    this.context.DeliveryMethods.HOMEDELIVERY ? (
                      <>
                        <Stack direction="horizontal">
                          <Form.Check
                            className=""
                            type="radio"
                            checked={
                              this.state.orderDetail.deliveryBy == 1
                                ? true
                                : false
                            }
                            name="DeliveryBy"
                            label="Self Delivery"
                            id="selfDelivery"
                            disabled={
                              this.props.changeOrderStatusTo ==
                              this.context.OrderStates.CANCELDELIVERY
                                ? true
                                : this.props.OrderState == "Payment" ||
                                  this.props.OrderState === "Close" ||
                                  this.props.OrderState == "History" ||
                                  this.props.OrderState == "Returns"
                                ? true
                                : false
                            }
                            onChange={this.handleDeliveryBy}
                          ></Form.Check>{" "}
                          &nbsp;&nbsp;&nbsp;
                          <Form.Check
                            className=""
                            type="radio"
                            checked={
                              this.state.orderDetail.deliveryBy == 2
                                ? true
                                : false
                            }
                            name="DeliveryBy"
                            label="3rd Party"
                            id="3rdParty"
                            disabled={
                              this.props.changeOrderStatusTo ==
                              this.context.OrderStates.CANCELDELIVERY
                                ? true
                                : this.props.OrderState == "Payment" ||
                                  this.props.OrderState === "Close" ||
                                  this.props.OrderState == "History" ||
                                  this.props.OrderState == "Returns"
                                ? true
                                : false
                            }
                            onChange={this.handleDeliveryBy}
                          ></Form.Check>
                        </Stack>
                      </>
                    ) : (
                      <>
                        <h3>Store-Pickup</h3>
                      </>
                    )}
                  </Col>
                </Row>
                {this.state.orderDetail.deliveryBy == 1 ? (
                  <>
                    <Row className="py-0">
                      <Col className=" text-start">
                        <Form.Label size="" className="">
                          Delivery Person :
                        </Form.Label>
                      </Col>
                      <Col className="text-end">
                        <Stack direction="horizontal">
                          <Form.Select
                            type="text"
                            className="pd-2"
                            name="deliveryPersonId"
                            value={this.state.orderDetail.deliveryPersonId}
                            placeholder="Enter TrackingID"
                            required
                            disabled={
                              this.props.changeOrderStatusTo ==
                              this.context.OrderStates.CANCELDELIVERY
                                ? true
                                : this.props.OrderState == "Payment" ||
                                  this.props.OrderState === "Close" ||
                                  this.props.OrderState == "History" ||
                                  this.props.OrderState == "Returns"
                                ? true
                                : false
                            }
                            max={50}
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e)}
                          >
                            <option value=""></option>
                            {this.state.deliveryusers.map((d) => (
                              <>
                                <option value={d.emailId}>{d.emailId}</option>
                              </>
                            ))}
                          </Form.Select>
                          <Button
                            onClick={(e) => this.OnDeliveryPersonChange(e)}
                          >
                            Update
                          </Button>
                        </Stack>
                      </Col>
                    </Row>
                  </>
                ) : this.state.orderDetail.deliveryBy == 2 ? (
                  <>
                    <Row className="py-0">
                      <Col className=" text-start">
                        <Form.Label size="" className="">
                          Delivery Tracking-Id :
                        </Form.Label>
                      </Col>
                      <Col className="text-end">
                        <Form.Control
                          type="text"
                          className="pd-2"
                          name="deliveryTrackingId"
                          maxLength={49}
                          value={this.state.orderDetail.deliveryTrackingId}
                          placeholder="Enter TrackingID"
                          required
                          disabled={
                            this.props.changeOrderStatusTo ==
                            this.context.OrderStates.CANCELDELIVERY
                              ? true
                              : this.props.OrderState == "Payment" ||
                                this.props.OrderState === "Close" ||
                                this.props.OrderState == "History" ||
                                this.props.OrderState == "Returns"
                              ? true
                              : false
                          }
                          max={50}
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Row>
                    <Row className="py-0">
                      <Col className=" text-start">
                        <Form.Label size="" className="">
                          Delivery Carrier Name :
                        </Form.Label>
                      </Col>
                      <Col className="text-end">
                        <Form.Control
                          type="text"
                          className="pd-2"
                          name="deliveryCarrierName"
                          maxLength={49}
                          value={this.state.orderDetail.deliveryCarrierName}
                          placeholder="Enter Carrier Name"
                          required
                          disabled={
                            this.props.changeOrderStatusTo ==
                            this.context.OrderStates.CANCELDELIVERY
                              ? true
                              : this.props.OrderState == "Payment" ||
                                this.props.OrderState === "Close" ||
                                this.props.OrderState == "History" ||
                                this.props.OrderState == "Returns"
                              ? true
                              : false
                          }
                          max={50}
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Row>
                    <Row className="py-0">
                      <Col className=" text-start">
                        <Form.Label size="" className="">
                          Delivery Details(if any) :
                        </Form.Label>
                      </Col>
                      <Col className="text-end">
                        <Form.Control
                          as="textarea"
                          type="textarea"
                          rows={3}
                          className="pd-2"
                          name="deliveryDetails"
                          maxLength={1999}
                          value={this.state.orderDetail.deliveryDetails}
                          disabled={
                            this.props.changeOrderStatusTo ==
                            this.context.OrderStates.CANCELDELIVERY
                              ? true
                              : this.props.OrderState == "Payment" ||
                                this.props.OrderState === "Close" ||
                                this.props.OrderState == "History" ||
                                this.props.OrderState == "Returns"
                              ? true
                              : false
                          }
                          max={50}
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </>
        ) : (
          <></>
        )}
      </>
    );
  }
}

export class OrderSummary extends Component {
  static contextType = CartContext;

  constructor(props) {
    super(props);
    this.state = {
      loadSpinnerOpen: false,
      orderDetail: this.props.orderDetail,
      id: this.props.orderDetail.id,
      totalQty: this.props.orderDetail.totalQty,
      subTotalAmt: this.props.orderDetail.subTotalAmt,
      deliveryAmt: this.props.orderDetail.deliveryAmt,
      couponCode: this.props.orderDetail.couponCode,
      couponValue: this.props.orderDetail.couponValue,
      taxPercentage: this.props.orderDetail.taxPercentage,
      taxAmount: this.props.orderDetail.taxAmount,
      totalAmt: this.props.orderDetail.totalAmt,

      customerName: this.props.orderDetail.customerName,
      mobileNo: this.props.orderDetail.mobileNo,
      address: this.props.orderDetail.address,
      location: this.props.orderDetail.location,
      state: this.props.orderDetail.state,
      country: this.props.orderDetail.country,
      pinCode: this.props.orderDetail.pinCode,
      paymentmodeName: this.props.orderDetail.paymentmodeName,
      orderStatusName: this.props.orderDetail.orderStatusName,
      paymentStatusId: this.props.orderDetail.paymentStatusId,
      paymentStatusName: this.props.orderDetail.paymentStatusName,

      deliveryTrackingId: this.props.orderDetail.deliveryTrackingId,
      deliveryCarrierName: this.props.orderDetail.deliveryCarrierName,
      deliveryDetails: this.props.orderDetail.deliveryDetails,

      description: this.props.orderDetail.description,
      bankReceiptRefNumber: this.props.orderDetail.bankReceiptRefNumber,
      bankReceiptDetails: this.props.orderDetail.bankReceiptDetails,
      bankReceiptFileName: this.props.orderDetail.bankReceiptFileName,

      orderWorkflowStatus: this.props.orderDetail.orderWorkflowStatus,
      orderCreated: this.props.orderDetail.orderCreated,
      orderModified: this.props.orderDetail.orderModified,

      changeOrderStatusTo: 0,
      orderStatusList: [],
      paymentStatusList: [],
      RECEIVED: 1,
      STARTPACKAGING: 2,
      CANCELLED: 3,
      PACKAGED: 4,
      SHORTAGEITEMS: 5,
      SENTDELIVERY: 6,
      DELIVEREDANDPAID: 7,
      DELIVEREDBUTUNPAID: 8,
      PAYMENTRECEIVED: 9,
      REFUNDED: 10,
      CLOSED: 11,
      RETURNED: 12,
      ADDTOINVENTORY: 13,
      ADDEDTOINVENTORY: 14,
      CANCELPACKAGE: 15,
      CANCELDELIVERY: 16,
      CANCELLEDREFUNDNOTREQUIRED: 17,
      CANCELANDCLOSE: 18,
      REFUNDEDANDCLOSED: 19,
      RETURNEHANDEDDANDREFUNDED: 20,
    };
    this.inputFile = React.createRef();
  }

  IsOrderReady = (order) => {
    var items = this.props.orderDetail.customerOrderedItems.filter(
      (f) =>
        (f.orderStatusId == 1 || f.orderStatusId == 2) &
        (f.availability == true)
    );
    if (items.length > 0) return false;
    return true;
  };

  onHandleChangeOrderStatusTo = (e) => {
    if (
      e.target.value == this.state.STARTPACKAGING &&
      this.props.orderDetail.paymentmodeName == "BankReceipt" &&
      Number(this.state.paymentStatusId) != 1
    ) {
      //alert("`Payment Status` to be changed to `Paid`");
      Swal.fire({
        title: "Information",
        text: "`Payment Status` to be changed to `Paid`",
        // confirmButtonText: "yes",

        timer: 2000,
      }).then((result) => {});
      return;
    }

    if (
      (e.target.value == this.state.PAYMENTRECEIVED ||
        e.target.value == this.state.DELIVEREDANDPAID ||
        e.target.value == this.state.CLOSED) &&
      Number(this.state.paymentStatusId) != 1
    ) {
      Swal.fire({
        title: "Information",
        text: "`Payment Status` to be changed to `Paid`",
        // confirmButtonText: "yes",

        timer: 2000,
      }).then((result) => {});
      return;
    }

    if (e.target.value == this.state.PACKAGED && this.IsOrderReady() == false) {
      //alert("Items are still Preparing!");
      Swal.fire({
        title: "Information",
        text: "Items are still Preparing!",
        // confirmButtonText: "yes",

        timer: 2000,
      }).then((result) => {});
      return;
    }

    if (
      e.target.value == this.state.SENTDELIVERY &&
      this.props.orderDetail.deliveryMethodId ==
        this.context.DeliveryMethods.HOMEDELIVERY &&
      (this.state.orderDetail.deliveryBy == undefined ||
        this.state.orderDetail.deliveryBy == null)
    ) {
      Swal.fire({
        title: "Information",
        text: "select `Delivery Method` and try!",
        timer: 2000,
      }).then((result) => {});
      return;
    }
    if (
      e.target.value == this.state.DELIVEREDBUTUNPAID &&
      this.state.paymentStatusId == 1
    ) {
      //alert("Change `Payment Status` to be `Unpaid` ");
      Swal.fire({
        title: "Information",
        text: "Change `Payment Status` to be `Unpaid` ",
        timer: 2000,
      }).then((result) => {});
      return;
    }
    if (
      e.target.value == this.state.CANCELDELIVERY &&
      this.props.orderDetail.paymentmodeName == "CashOnDelivery" &&
      this.state.paymentStatusId == 1
    ) {
      //alert("Change `Payment Status` to be `Unpaid` ");
      Swal.fire({
        title: "Information",
        text: "Change `Payment Status` to be `Unpaid` ",
        timer: 2000,
      }).then((result) => {});
      return;
    }
    if (
      e.target.value == this.state.CANCELANDCLOSE &&
      Number(this.state.paymentStatusId) != 2 &&
      Number(this.state.paymentStatusId) != 5
    ) {
      //alert("Change `Payment Status` to be `Unpaid` ");
      Swal.fire({
        title: "Information",
        text: "Change `Payment Status` to be `Unpaid` ",
        timer: 2000,
      }).then((result) => {});
      return;
    }
    if (
      e.target.value == this.state.REFUNDED &&
      Number(this.state.paymentStatusId) != 3
    ) {
      //alert("Change `Payment Status` to 'Refunded'");
      Swal.fire({
        title: "Information",
        text: "Change `Payment Status` to 'Refunded'",
        timer: 2000,
      }).then((result) => {});
      return;
    }
    if (
      e.target.value == this.state.CANCELLEDREFUNDNOTREQUIRED &&
      Number(this.state.paymentStatusId) != 2 &&
      Number(this.state.paymentStatusId) != 5
    ) {
      //alert("Change `Payment Status` to 'Unpaid'");
      Swal.fire({
        title: "Information",
        text: "Change `Payment Status` to 'Unpaid'",
        timer: 2000,
      }).then((result) => {});
      return;
    }

    this.setState({ changeOrderStatusTo: e.target.value });
    this.props.StoreChangedOrderState(e.target.value);
  };

  onPaymentStatusChange = (e) => {
    this.props.StorePaymentStatus(e.target.value);
    this.setState({ changeOrderStatusTo: 0 });
    this.setState({ paymentStatusId: e.target.value });

    this.setState({
      paymentStatusName: this.context.customerOrderPaymentStatusTypes.filter(
        (f) => f.id == Number(e.target.value)
      )[0].statusName,
    });
  };

  handleInputChange = (e, index) => {
    const { name, value } = e.target;

    if (name == "deliveryPersonId")
      this.state.orderDetail.deliveryPersonId = value;
    else if (name == "deliveryTrackingId")
      this.state.orderDetail.deliveryTrackingId = value;
    else if (name == "deliveryCarrierName")
      this.state.orderDetail.deliveryCarrierName = value;
    else if (name == "deliveryDetails")
      this.state.orderDetail.deliveryDetails = value;
    else if (name == "description") this.state.orderDetail.description = value;
    else if (name == "bankReceiptRefNumber")
      this.state.orderDetail.bankReceiptRefNumber = value;
    else if (name == "bankReceiptDetails")
      this.state.orderDetail.bankReceiptDetails = value;
    else if (name == "bankReceiptFileName")
      this.state.orderDetail.bankReceiptFileName = value;

    this.setState({ orderDetail: this.state.orderDetail }, () => {
      this.props.UpdateOrderDetails(this.state.orderDetail);
    });
  };

  StoreOrderState = () => {
    if (this.props.OrderState == "Received") {
      if (this.props.orderDetail.orderStatus === this.state.RECEIVED) {
        this.setState({
          orderStatusList: [
            { state: "", value: "0" },
            { state: "StartPackaging", value: "2" },
            { state: "CancelOrder", value: "3" },
          ],
        });
        if (this.props.orderDetail.paymentmodeName == "BankReceipt") {
          this.setState({
            paymentStatusList: [
              // { state: "", value: "" },
              { state: "Paid", value: "1" },
              { state: "Unpaid", value: "2" },
              // { state: "Refunded", value: "3" },
            ],
          });
        }
      } else if (
        this.props.orderDetail.orderStatus === this.state.SHORTAGEITEMS
      ) {
        this.setState({
          orderStatusList: [
            { state: "", value: "0" },
            { state: "StartPackaging", value: "2" },
            { state: "CancelOrder", value: "3" },
          ],
        });
      } else if (
        this.props.orderDetail.orderStatus === this.state.CANCELPACKAGE
      ) {
        this.setState({
          orderStatusList: [
            { state: "", value: "0" },
            { state: "AddToInventory", value: "13" },
          ],
        });
      } else if (
        this.props.orderDetail.orderStatus === this.state.CANCELDELIVERY
      ) {
        this.setState({
          orderStatusList: [
            { state: "", value: "0" },
            { state: "AddToInventory", value: "13" },
          ],
        });
      } else if (this.props.orderDetail.orderStatus === this.state.RETURNED) {
        this.setState({
          orderStatusList: [
            { state: "", value: "0" },
            { state: "AddToInventory", value: "13" },
          ],
        });
      }
    } else if (this.props.OrderState == "Package") {
      if (this.props.orderDetail.orderStatus === this.state.STARTPACKAGING) {
        this.setState({
          orderStatusList: [
            { state: "", value: "0" },
            { state: "Packaged", value: "4" },
            { state: "Shortage", value: "5" },
            { state: "CancelPackage", value: "15" },
          ],
        });
      } else if (
        this.props.orderDetail.orderStatus === this.state.ADDTOINVENTORY
      ) {
        this.setState({
          orderStatusList: [
            { state: "", value: "0" },
            { state: "AddedToInventory", value: "14" },
          ],
        });
      }
    } else if (this.props.OrderState == "Dispatch") {
      if (this.props.orderDetail.orderStatus === this.state.PACKAGED) {
        this.setState({
          orderStatusList: [
            { state: "", value: "0" },
            { state: "SentDelivery", value: "6" },
            { state: "CancelDelivery", value: "16" },
          ],
        });
        if (this.props.orderDetail.paymentStatusId == 1) {
          this.setState({
            paymentStatusList: [
              { state: "Paid", value: "1" },
              // { state: "Unpaid", value: "2" },
              // { state: "Refunded", value: "3" },
            ],
          });
        } else if (this.props.orderDetail.paymentStatusId == 2) {
          this.setState({
            paymentStatusList: [
              //{ state: "Paid", value: "1" },
              { state: "Unpaid", value: "2" },
              // { state: "Refunded", value: "3" },
            ],
          });
        } else if (this.props.orderDetail.paymentStatusId == 5) {
          this.setState({
            paymentStatusList: [
              { state: "Paid", value: "1" },
              { state: "Failed", value: "5" },
            ],
          });
        }
      }
    } else if (this.props.OrderState == "Delivery") {
      if (
        this.props.orderDetail.orderStatus === this.state.SENTDELIVERY ||
        this.props.orderDetail.orderStatus === this.state.PACKAGED
      ) {
        if (this.props.orderDetail.paymentStatusId == 1) {
          this.setState({
            orderStatusList: [
              { state: "", value: "0" },
              { state: "DeliveredAndPaid", value: "7" },
              { state: "CancelDelivery", value: "16" },
            ],
          });
          this.setState({
            paymentStatusList: [{ state: "Paid", value: "1" }],
          });
        } else if (this.props.orderDetail.paymentStatusId == 5) {
          this.setState({
            paymentStatusList: [
              { state: "Paid", value: "1" },
              { state: "Failed", value: "5" },
            ],
          });
        } else {
          this.setState({
            orderStatusList: [
              { state: "", value: "0" },
              { state: "DeliveredAndPaid", value: "7" },
              { state: "DeliveredButUnpaid", value: "8" },
              { state: "CancelDelivery", value: "16" },
            ],
          });
          this.setState({
            paymentStatusList: [
              { state: "Paid", value: "1" },
              { state: "Unpaid", value: "2" },
              // { state: "Refunded", value: "3" },
            ],
          });
        }
      }
    } else if (this.props.OrderState == "Payment") {
      if (
        this.props.orderDetail.orderStatus === this.state.DELIVEREDANDPAID ||
        this.props.orderDetail.orderStatus === this.state.DELIVEREDBUTUNPAID
      ) {
        if (
          this.props.orderDetail.paymentStatusId == 1 &&
          Math.round(this.props.orderDetail.amtToRefund, 0) > 0
        ) {
          this.setState({
            orderStatusList: [
              { state: "", value: "0" },
              { state: "Refunded", value: "10" },
            ],
          });
          this.setState({
            paymentStatusList: [
              { state: "Paid", value: "1" },
              //{ state: "Unpaid", value: "2" },
              { state: "Refunded", value: "3" },
            ],
          });
        } else if (
          this.props.orderDetail.paymentStatusId == 3 &&
          Math.round(this.props.orderDetail.amtToRefund, 0) > 0
        ) {
          this.setState({
            orderStatusList: [
              { state: "", value: "0" },
              { state: "Refunded", value: "10" },
            ],
          });
          this.setState({
            paymentStatusList: [
              { state: "Paid", value: "1" },
              { state: "Refunded", value: "3" },
            ],
          });
        } else {
          this.setState({
            orderStatusList: [
              { state: "", value: "0" },
              { state: "PaymentReceived", value: "9" },
            ],
          });
          this.setState({
            paymentStatusList: [
              { state: "Paid", value: "1" },
              { state: "Unpaid", value: "2" },
              // { state: "Refunded", value: "3" },
            ],
          });
        }
      } else if (
        this.props.orderDetail.orderStatus === this.state.CANCELLED ||
        this.props.orderDetail.orderStatus === this.state.ADDEDTOINVENTORY
      ) {
        if (this.props.orderDetail.paymentStatusId == 1) {
          // if already paid
          this.setState({
            orderStatusList: [
              { state: "", value: "0" },
              { state: "Refunded", value: "10" },
            ],
          });
          this.setState({
            paymentStatusList: [
              { state: "Paid", value: "1" },
              //{ state: "Unpaid", value: "2" },
              { state: "Refunded", value: "3" },
            ],
          });
        } else if (this.props.orderDetail.paymentStatusId == 3) {
          // if already paid
          this.setState({
            orderStatusList: [
              { state: "", value: "0" },
              { state: "Refunded", value: "10" },
            ],
          });
          this.setState({
            paymentStatusList: [
              { state: "Paid", value: "1" },
              //{ state: "Unpaid", value: "2" },
              { state: "Refunded", value: "3" },
            ],
          });
        } else if (this.props.orderDetail.paymentStatusId == 5) {
          // FAILED
          this.setState({
            orderStatusList: [
              { state: "", value: "0" },
              { state: "CancelledAndRefundNotRequired", value: "17" },
            ],
          });
          this.setState({
            paymentStatusList: [
              { state: "Failed", value: "5" },
              { state: "Unpaid", value: "2" },
            ],
          });
        } else {
          this.setState({
            orderStatusList: [
              { state: "", value: "0" },
              // { state: "Refunded", value: "10" },
              { state: "CancelledAndRefundNotRequired", value: "17" },
            ],
          });
          this.setState({
            paymentStatusList: [
              //{ state: "Paid", value: "1" },
              { state: "Unpaid", value: "2" },
              // { state: "Refunded", value: "3" },
            ],
          });
        }
      }
    } else if (this.props.OrderState == "Close") {
      if (this.props.orderDetail.orderStatus === this.state.PAYMENTRECEIVED) {
        this.setState({
          orderStatusList: [
            { state: "", value: "0" },
            { state: "CloseOrder", value: "11" },
          ],
        });
        this.setState({
          paymentStatusList: [
            { state: "Paid", value: "1" },
            //{ state: "Unpaid", value: "2" },
            // { state: "Refunded", value: "3" },
          ],
        });
      } else if (this.props.orderDetail.orderStatus === this.state.REFUNDED) {
        this.setState({
          orderStatusList: [
            { state: "", value: "0" },
            { state: "RefundedAndClosed", value: "19" },
          ],
        });
        this.setState({
          paymentStatusList: [
            //{ state: "Paid", value: "1" },
            // { state: "Unpaid", value: "2" },
            { state: "Refunded", value: "3" },
          ],
        });
      } else if (
        this.props.orderDetail.orderStatus ===
        this.state.CANCELLEDREFUNDNOTREQUIRED
      ) {
        this.setState({
          orderStatusList: [
            { state: "", value: "0" },

            { state: "CancelledAndCloseOrder", value: "18" },
          ],
        });
        this.setState({
          paymentStatusList: [
            //{ state: "Paid", value: "1" },
            { state: "Unpaid", value: "2" },
            { state: "Failed", value: "5" },
            // { state: "Refunded", value: "3" },
          ],
        });
      }
    } else if (this.props.OrderState == "History") {
      if (
        this.props.orderDetail.orderStatus === this.state.CLOSED ||
        this.props.orderDetail.orderStatus === this.state.REFUNDEDANDCLOSED
      ) {
        this.setState({
          orderStatusList: [
            { state: "", value: "0" },
            { state: "Returned", value: "12" },
          ],
        });
        this.setState({
          paymentStatusList: [
            { state: "Paid", value: "1" },
            //{ state: "Unpaid", value: "2" },
            { state: "Refunded", value: "3" },
          ],
        });
      }
    } else if (this.props.OrderState == "Returns") {
      if (this.props.orderDetail.orderStatus === this.state.RETURNED) {
        this.setState({
          orderStatusList: [
            { state: "", value: "0" },
            { state: "ReturnHandledAndClosed", value: "20" },
          ],
        });
        this.setState({
          paymentStatusList: [
            { state: "Paid", value: "1" },
            //{ state: "Unpaid", value: "2" },
            { state: "Refunded", value: "3" },
          ],
        });
      }
    }
  };

  Print = () => {
    const url =
      this.context.store.storageBlobUrl +
      this.context.store.storageBlobContainerName +
      "/invoice/" +
      this.props.orderDetail.orderId +
      ".html" +
      this.context.store.storageSasToken;

    // this.context.store.invoiceFileShareAccountUrl +
    // this.context.store.invoiceFileShareName +
    // "/" +
    // this.props.orderDetail.orderId +
    // ".html" +
    // this.context.store.storageSasToken;
    fetch(url).then((r) => {
      alert("hi");
      alert(r.text());
    });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  handleFileUpload = async (e) => {
    this.ShowLoadSpinner();
    const { files } = e.target;

    for (let i = 0; i < files.length; i++) {
      await this.uploadFileToBlob(files[i]);
    }
    console.log("All uploaded ");
    this.HideLoadSpinner();
  };

  uploadFileToBlob = async (file) => {
    if (!file) return [];
    const blobService = new BlobServiceClient(
      this.context.store.storageBlobUrl + this.context.store.storageSasToken
    );
    const containerClient = blobService.getContainerClient(
      this.context.store.storageBlobContainerName
    );

    console.log("uploading:" + file.name);
    //upload file.
    await this.createBlobInContainer(containerClient, file);

    // get list of blobs in container
    //return getBlobsInContainer(containerClient);
  };

  createBlobInContainer = async (containerClient, file) => {
    // create blobClient for container
    var targetFileName =
      this.props.orderDetail.orderId + "." + file.name.split(".").pop();
    const blobClient = containerClient.getBlockBlobClient(
      "bankreceipt/" + targetFileName
    );

    // set mimetype as determined from browser with file upload control
    const options = { blobHTTPHeaders: { blobContentType: file.type } };

    // upload file
    await blobClient.uploadData(file, options);

    this.setState({ bankReceiptFileName: targetFileName });
    this.state.orderDetail.bankReceiptFileName = targetFileName;
    this.setState({ orderDetail: this.state.orderDetail }, () => {
      this.props.UpdateOrderDetails(this.state.orderDetail);
    });

    console.log("uploaded:" + file.name);
  };

  onUploadButtonClick = () => {
    this.inputFile.current.click();
  };

  DeleteBankReceipt = () => {
    this.setState({ bankReceiptFileName: "" });
  };
  CheckPhonePePaymentStatus = () => {
    var res = axios
      .get(
        process.env.REACT_APP_API +
          "StoreManager/CheckPhonePePaymentStatus/" +
          this.context.storeId +
          "/" +
          this.context.storeSettings.merchentId +
          "/" +
          this.props.orderDetail.paymentTransactionId
      )
      .then((response) => {
        if (response.status === 200) {
          this.props.RefreshOrderDetail();
        }
      })
      .catch((error) => {
        alert("CheckPhonePePaymentStatus - Failed! ", error);
      });
  };

  componentDidMount() {
    this.StoreOrderState();
  }

  render() {
    return (
      <div className="mx-1 px-1  ">
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        <input
          style={{ display: "none" }}
          accept=".png,.jpg,.jpeg"
          ref={this.inputFile}
          onChange={this.handleFileUpload}
          type="file"
          multiple="multiple"
        />
        <div id="demo"></div>
        <Container className="p-0 bg-white">
          <Row className="py-0">
            <Row>
              <h2 className=" text-center text-dark">
                Order Number - {this.props.orderDetail.orderId}
              </h2>
            </Row>
            <Col
              xs={6}
              className="py-2 border px-3 bg-light"
              style={{ fontSize: "12px" }}
            >
              {this.props.orderDetail.deliveryMethodId != 4 &&
              this.props.orderDetail.deliveryMethodId != 5 ? (
                <>
                  <Row className="py-0 ">
                    <Col className=" text-start">
                      <Form.Label size="" className="">
                        Subtotal [
                        {Math.round(
                          Number(this.state.totalQty),
                          0
                        ).toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}{" "}
                        Items]:
                      </Form.Label>
                    </Col>
                    <Col className="text-end">
                      <Form.Label size="" className="">
                        {Number(this.state.subTotalAmt).toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </Form.Label>
                    </Col>
                  </Row>
                  <Row className="py-0">
                    <Col className=" text-start">
                      <Form.Label size="" className="">
                        Delivery/Shipping charges :
                      </Form.Label>
                    </Col>
                    <Col className="text-end">
                      <Form.Label size="" className="">
                        {Number(this.state.deliveryAmt).toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </Form.Label>
                    </Col>
                  </Row>
                  <>
                    <Row className="py-0">
                      <Col className=" text-start">
                        <Form.Label size="" className="">
                          Tax({this.state.taxPercentage}%) :
                        </Form.Label>
                      </Col>
                      <Col className="text-end">
                        <Form.Label size="" className="">
                          {Number(this.state.taxAmount).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </Form.Label>
                      </Col>
                    </Row>
                  </>
                  {this.state.couponValue > 0 ? (
                    <>
                      <Row className="py-0 ">
                        <Col className=" text-start">
                          <Form.Label size="" className="">
                            Coupon Code:
                          </Form.Label>
                        </Col>
                        <Col className="text-end">
                          <Form.Label size="lg" className="text-end">
                            {this.state.couponCode}
                          </Form.Label>
                        </Col>
                      </Row>
                      <Row className="py-0 ">
                        <Col className=" text-start">
                          <Form.Label size="" className="">
                            Coupon Value:
                          </Form.Label>
                        </Col>
                        <Col className="text-end">
                          <Form.Label size="lg" className="text-end">
                            {Number(this.state.couponValue).toLocaleString(
                              this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 1,
                                style: "currency",
                                currency:
                                  this.context.storeSettings.defaultCurrency,
                              }
                            )}
                          </Form.Label>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                <></>
              )}
              <Row className="py-0">
                <Col className=" text-start">
                  <Form.Label size="" className="">
                    Total :
                  </Form.Label>
                </Col>
                <Col className="text-end ">
                  <Form.Label size="" className="py-0">
                    <b>
                      {Number(this.state.totalAmt).toLocaleString(
                        this.context.storeSettings.defaultLocale,
                        {
                          minimumFractionDigits: 0,
                          maximumFractionDigits: 1,
                          style: "currency",
                          currency: this.context.storeSettings.defaultCurrency,
                        }
                      )}
                    </b>
                  </Form.Label>
                </Col>
              </Row>
            </Col>
            <Col
              xs={6}
              className="py-2 border px-3 bg-light"
              style={{ fontSize: "12px" }}
            >
              <Row className="py-0">
                <Col className=" text-start">
                  <Form.Label size="" className="">
                    Order Created :
                  </Form.Label>
                </Col>
                <Col className="text-end">
                  <Form.Label size="" className="py-0">
                    {
                      this.context.GetInDateFormat(
                        new Date(this.state.orderCreated)
                      )
                      // new Date(this.state.orderCreated).toLocaleString()
                    }
                  </Form.Label>
                </Col>
              </Row>
              <Row className="py-0">
                <Col className=" text-start">
                  <Form.Label size="" className="">
                    Order Modified :
                  </Form.Label>
                </Col>
                <Col className="text-end">
                  <Form.Label size="" className="py-0">
                    {
                      this.context.GetInDateFormat(
                        new Date(this.state.orderModified)
                      )
                      //new Date(this.state.orderModified).toLocaleString()
                    }
                  </Form.Label>
                </Col>
              </Row>
              <Row>
                <Col className=" text-start">
                  <Form.Label>
                    Order details (placeholder to enter any information about
                    the order. upto 500 chars allowed)
                  </Form.Label>
                </Col>
                <Col className=" text-start">
                  <Form.Control
                    as="textarea"
                    type="text"
                    className="pd-2 w-100"
                    rows={3}
                    name="description"
                    value={this.state.orderDetail.description}
                    placeholder=""
                    autoComplete="off"
                    onChange={(e) => this.handleInputChange(e)}
                  />
                </Col>
              </Row>
              {this.props.OrderState == "Payment" ||
              this.props.OrderState === "History" ||
              this.props.OrderState === "Returns" ||
              this.props.OrderState === "Close" ||
              this.props.OrderState == "Dispatch" ||
              this.props.OrderState == "Delivery" ? (
                <>
                  {/* <DeliveryDetails
                    orderDetail={this.state.orderDetail}
                    OrderState={this.props.OrderState}
                    changeOrderStatusTo={this.state.changeOrderStatusTo}
                    UpdateOrderDetails={this.props.UpdateOrderDetails}
                  ></DeliveryDetails> */}
                  {this.state.orderDetail.orderStatus ==
                    this.state.RETURNEHANDEDDANDREFUNDED ||
                  this.state.orderDetail.orderStatus == this.state.RETURNED ? (
                    <>
                      <Row className="py-0">
                        <Col className=" text-start">
                          <Form.Label size="" className="">
                            Tot.Amount To Refund OnRetuns :
                          </Form.Label>
                        </Col>
                        <Col className="text-start">
                          <span className="costfont">
                            <h3 className="bg-warning">
                              {this.state.orderDetail.amountToRefundOnReturns.toLocaleString(
                                this.context.storeSettings.defaultLocale,
                                {
                                  minimumFractionDigits: 0,
                                  maximumFractionDigits: 1,
                                  style: "currency",
                                  currency:
                                    this.context.storeSettings.defaultCurrency,
                                }
                              )}
                            </h3>
                          </span>
                        </Col>
                      </Row>
                    </>
                  ) : (
                    ""
                  )}
                </>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <br />
          <CustomerDetails
            orderDetail={this.state.orderDetail}
          ></CustomerDetails>
          {this.props.OrderState == "Payment" ||
          this.props.OrderState === "History" ||
          this.props.OrderState === "Returns" ||
          this.props.OrderState === "Close" ||
          this.props.OrderState == "Dispatch" ||
          this.props.OrderState == "Delivery" ? (
            <>
              <br />
              <DeliveryDetails
                orderDetail={this.state.orderDetail}
                OrderState={this.props.OrderState}
                changeOrderStatusTo={this.state.changeOrderStatusTo}
                UpdateOrderDetails={this.props.UpdateOrderDetails}
              ></DeliveryDetails>
            </>
          ) : (
            <></>
          )}
          <br />
          <Row className="">
            <h4>Payment Details</h4>
            <Col
              className="py-2 border px-3 bg-light"
              style={{ fontSize: "12px" }}
            >
              {this.props.orderDetail.deliveryMethodId != 4 &&
              this.props.orderDetail.deliveryMethodId != 5 ? (
                <>
                  <Row className="py-0">
                    <Col className=" text-start">
                      {/* TODO: */}
                      <Form.Label size="" className="">
                        Amount Received :
                      </Form.Label>
                    </Col>
                    <Col className="text-end text-success">
                      <Form.Label size="" className="py-0">
                        <b>
                          {Number(
                            this.props.orderDetail.totalAmtPaid
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </b>
                      </Form.Label>
                    </Col>
                  </Row>
                  <Row className="py-0">
                    <Col className=" text-start">
                      <Form.Label size="" className="">
                        Amount to Refund :
                      </Form.Label>
                    </Col>
                    <Col className="text-end">
                      <Form.Label size="" className="py-0 text-danger">
                        <b>
                          {Number(
                            this.props.orderDetail.amtToRefund < 1
                              ? 0
                              : this.props.orderDetail.amtToRefund
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 0,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </b>
                      </Form.Label>
                    </Col>
                  </Row>
                </>
              ) : (
                <></>
              )}
            </Col>
            <Col
              className="py-2 border px-3 bg-light"
              style={{ fontSize: "12px" }}
            >
              <Row className="py-0">
                <Col className=" text-start">
                  <Form.Label size="" className="">
                    Payment Mode :
                  </Form.Label>
                </Col>
                <Col className="text-center">
                  <Form.Label size="" className="py-0 border">
                    <b>{this.state.paymentmodeName} </b>
                    {this.props.orderDetail.paymentTransactionId != ""
                      ? "[PaymentId= " +
                        this.props.orderDetail.paymentTransactionId +
                        "]"
                      : ""}
                  </Form.Label>
                </Col>
              </Row>
              <Row className="py-0">
                <Col className=" text-start">
                  <Form.Label size="" className="">
                    Payment Status :
                  </Form.Label>
                </Col>
                <Col className="text-end">
                  {this.props.OrderState == "Payment" ||
                  this.props.OrderState == "Dispatch" ||
                  this.props.OrderState == "Delivery" ||
                  this.props.OrderState == "Close" ||
                  (this.props.orderDetail.paymentmodeName == "BankReceipt" &&
                    this.props.OrderState == "Received" &&
                    this.props.orderDetail.orderStatus ==
                      this.context.OrderStates.RECEIVED) ? (
                    <Form.Select
                      type="text"
                      name="paymentstatus"
                      size="lg"
                      value={this.state.paymentStatusId}
                      required
                      placeholder=""
                      className="text-success text-center fw-bold"
                      onChange={(e) => this.onPaymentStatusChange(e)}
                    >
                      {/* <option value="1">Paid</option>
                      <option value="2">Unpaid</option>
                      <option value="3">Refunded</option> */}
                      {this.state.paymentStatusList.map((status, i) => (
                        <>
                          <option value={status.value}>{status.state}</option>
                        </>
                      ))}
                    </Form.Select>
                  ) : (
                    <Form.Label
                      size=""
                      className="px-2 py-1 text-danger border "
                    >
                      <h3>{this.state.paymentStatusName}</h3>
                    </Form.Label>
                  )}
                  {this.state.paymentStatusName != "Paid" &&
                  this.state.paymentmodeName == "PhonePe" ? (
                    <>
                      <Button onClick={(e) => this.CheckPhonePePaymentStatus()}>
                        Check Status!
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </Col>
              </Row>
            </Col>
            {this.props.orderDetail.paymentModeId ==
            this.context.PaymentMethods.CashOnDelivery ? (
              <>
                <Col className="text-center">
                  QRCODE
                  <br />
                  <img
                    src={
                      this.context.store.storageBlobUrl +
                      this.context.store.storageBlobContainerName +
                      "/images/qrcode.png" +
                      this.context.store.storageSasToken
                    }
                    className="text-center"
                    alt="..."
                    width="150px"
                  />
                </Col>
              </>
            ) : (
              <></>
            )}
          </Row>
          <br />
          <Row>
            <h4>Order Status</h4>
            <Col
              xs={6}
              className="py-2 border px-3 bg-light"
              style={{ fontSize: "12px" }}
            >
              <Row
                className="py-1 border bg-secondary text-white"
                style={{ fontSize: "14px" }}
              >
                <Col className=" text-start ">
                  <Form.Label
                    size=""
                    className="w-100 text-start align-middle "
                  >
                    Current "Order Status" :
                  </Form.Label>
                </Col>
                <Col className="text-end ">
                  <Form.Label
                    // size="lg"
                    className="px-2 py-1 w-100 "
                  >
                    {this.state.orderStatusName}
                  </Form.Label>
                </Col>
              </Row>
            </Col>
            <Col
              xs={6}
              className="py-2 border px-3 bg-light"
              style={{ fontSize: "12px" }}
            >
              {this.state.orderDetail.orderStatus ==
              this.state.RETURNEHANDEDDANDREFUNDED ? (
                ""
              ) : (
                <>
                  <Row
                    className="py-0 border bg-warning py-1"
                    style={{ fontSize: "14px" }}
                  >
                    <Col className=" text-start">
                      <Form.Label size="" className="">
                        Change "Order Status" to:
                      </Form.Label>
                    </Col>
                    <Col className="text-end">
                      <Form.Select
                        type="text"
                        name="changeOrderStatus"
                        value={this.state.changeOrderStatusTo}
                        placeholder=""
                        className="text-dark text-center fw-bold"
                        onChange={(e) => this.onHandleChangeOrderStatusTo(e)}
                      >
                        {this.state.orderStatusList.map((status, i) => (
                          <>
                            <option value={status.value}>{status.state}</option>
                          </>
                        ))}
                      </Form.Select>
                    </Col>
                  </Row>
                </>
              )}
            </Col>
          </Row>
          {this.state.paymentmodeName == "BankReceipt" ? (
            <Row>
              <h5>Bank Receipt Details</h5>
              <Col
                xs={6}
                className="py-2 border px-3 bg-light"
                style={{ fontSize: "12px" }}
              >
                <>
                  <div className="border">
                    <Row className="py-0">
                      <Col className=" text-start">
                        <Form.Label size="" className="">
                          Reference Number(*) :
                        </Form.Label>
                      </Col>
                      <Col className="text-end">
                        <Form.Control
                          type="text"
                          name="bankReceiptRefNumber"
                          value={this.state.orderDetail.bankReceiptRefNumber}
                          required
                          placeholder=""
                          className=" text-center"
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </Col>
                    </Row>
                    <Row className="py-2">
                      <br></br>
                      {this.state.bankReceiptFileName == "" ||
                      this.state.bankReceiptFileName == undefined ? (
                        <>
                          <Col>
                            <Form.Label
                              size=""
                              className=""
                              style={{ verticalAlign: "middle" }}
                            >
                              Attachment (if any) :
                            </Form.Label>
                          </Col>
                          <Col>
                            <Button
                              // className="w-100"
                              onClick={(e) => this.onUploadButtonClick()}
                            >
                              Upload BankReceipt{" "}
                            </Button>
                          </Col>
                        </>
                      ) : (
                        <>
                          <Col>Attachment :</Col>
                          <Col>
                            <b>{this.state.bankReceiptFileName}</b>{" "}
                            &nbsp;&nbsp;&nbsp;
                            <a
                              href={
                                this.context.store.storageBlobUrl +
                                this.context.store.storageBlobContainerName +
                                "/bankreceipt/" +
                                this.state.bankReceiptFileName +
                                this.context.store.storageSasToken
                              }
                              target="_blank"
                            >
                              View
                            </a>
                            &nbsp;&nbsp;&nbsp;
                            <Button
                              onClick={(e) => {
                                if (
                                  window.confirm(
                                    "Are you sure you want to Delete ?"
                                  )
                                ) {
                                  this.DeleteBankReceipt();
                                }
                              }}
                            >
                              X
                            </Button>
                          </Col>
                        </>
                      )}
                    </Row>
                  </div>
                </>
              </Col>
              <Col
                xs={6}
                className="py-2 border px-3 bg-light"
                style={{ fontSize: "12px" }}
              >
                <Row className="py-0">
                  <Col className="text-start">
                    Bank & Other Details(if any) :
                    <Form.Control
                      as="textarea"
                      type="text"
                      rows={3}
                      name="bankReceiptDetails"
                      value={this.props.orderDetail.bankReceiptDetails}
                      placeholder=""
                      className=" text-center"
                      autoComplete="off"
                      onChange={(e) => this.handleInputChange(e)}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </Container>
      </div>
    );
  }
}

export class OrderedProductItems extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      orderedItems: [],
    };
  }

  handleInputChange = (e, index) => {
    const { name, value } = e.target;

    if (name === "qty") {
      if (Number(value) < 0) return;
      if (value == "") return;

      if (Number(value) > this.state.orderedItems[index].qty) return;
    }

    const list = [...this.state.orderedItems];
    if (name == "availability") {
      list[index][name] = value == "true" ? true : false;
    } else if (name == "amountToRefundOnReturns") {
      list[index][name] = Number(value);
    } else {
      list[index][name] = value;
    }
    this.setState({ order: list }, () => {
      this.props.StoreUpdatedOrderedItems(this.state.orderedItems);
    });
  };

  setOrders = (data) => {
    this.setState({ orderedItems: data });
  };

  GetOrder = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetOrderedItemByOrderNo/" +
        this.context.storeId +
        "/" +
        this.props.orderDetail.id
    )
      .then((response) => response.json())
      .then((data) => {
        this.setOrders(data);
        //this.props.StoreUpdatedOrderedItems(data);
      })
      .catch((error) => {
        console.error("There was an error!", error);
        // alert("GetOrder fetch err:" + error);
      });
  };

  componentDidMount() {
    this.GetOrder();
  }
  PrepareStatus = (order) => {
    if (order.availability != true) return "";
    if (order.orderStatusId != 3) return "Preparing";
    else return "Ready";
  };
  render() {
    return (
      <>
        <Row className="py-3">
          <h4 className=" text-start text-dark">Ordered Products</h4>
        </Row>
        <div className="p-0">
          <Table striped className="cartviewtable p-5 ">
            <thead className="bg-light">
              <tr className=" text-black ">
                <th className="text-center" width="2%">
                  #
                </th>
                <th className="text-center" width="5%">
                  Image
                </th>
                <th className="text-center" width="15%">
                  Product Name
                </th>
                <th className="text-center" width="8%">
                  Price
                </th>
                <th className="text-center" width="5%">
                  Disc
                </th>
                <th className="text-center" width="5%">
                  {" "}
                  Qty
                </th>
                {this.props.orderDetail.deliveryMethodId != 4 &&
                this.props.orderDetail.deliveryMethodId != 5 ? (
                  <>
                    <th className="text-center" width="15%">
                      {" "}
                      Availability
                    </th>
                  </>
                ) : (
                  <></>
                )}

                {this.props.OrderState == "Package" ? (
                  <>
                    <th className="text-center" width="10%">
                      Prepare Status
                    </th>
                  </>
                ) : (
                  ""
                )}

                <th className="text-center" width="10%">
                  Sub. Total
                </th>
                {(this.props.OrderState === "Returns" ||
                  this.props.OrderState === "History") &&
                (this.props.orderDetail.orderStatus == 20 ||
                  this.props.orderDetail.orderStatus == 12) ? (
                  <>
                    <th className="text-center" width="10%">
                      Amt. to refund On Return
                    </th>
                  </>
                ) : (
                  ""
                )}

                <th className="text-center" width="20%">
                  Comments
                </th>
              </tr>
            </thead>

            <tbody>
              {this.state.orderedItems.length > 0
                ? this.state.orderedItems.map((orderdata, i) => (
                    <tr className=" align-middle text-black">
                      <td className=" text-center">{i + 1}</td>
                      <td className=" text-center">
                        <img
                          src={
                            this.context.store.storageBlobUrl +
                            this.context.store.storageBlobContainerName +
                            "/images/" +
                            orderdata.imgFileName +
                            this.context.store.storageSasToken
                          }
                          className="cardprodimg-on-cartview mx-auto"
                          alt="..."
                        />
                      </td>
                      <td className="text-center">{orderdata.productName}</td>
                      <td className=" text-black text-center">
                        {Number(orderdata.mrp).toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                      </td>
                      <td className=" text-black text-center">
                        {Number(orderdata.discount).toLocaleString(
                          this.context.storeSettings.defaultLocale,
                          {
                            minimumFractionDigits: 0,
                            maximumFractionDigits: 1,
                            style: "currency",
                            currency:
                              this.context.storeSettings.defaultCurrency,
                          }
                        )}
                        %
                      </td>
                      <td className=" text-black text-right  " align="center">
                        {orderdata.availability == true ? (
                          orderdata.qty
                        ) : (
                          <>
                            <b className="bg-danger text-warning">
                              Unavailable
                            </b>
                          </>
                        )}
                      </td>

                      {this.props.orderDetail.deliveryMethodId != 4 &&
                      this.props.orderDetail.deliveryMethodId != 5 ? (
                        <>
                          <td
                            className=" text-black text-right  "
                            align="center"
                          >
                            <Form.Select
                              type="text"
                              name="availability"
                              disabled={
                                this.props.OrderState === "Received"
                                  ? this.props.orderDetail.orderStatus == 3 ||
                                    this.props.orderDetail.orderStatus == 15 ||
                                    this.props.orderDetail.orderStatus == 16
                                    ? true
                                    : false
                                  : true
                              }
                              value={orderdata.availability}
                              className="text-dark text-center cartviewtable"
                              onChange={(e) => this.handleInputChange(e, i)}
                            >
                              <option value={true}>Available</option>
                              <option value={false}>Unavailable</option>
                            </Form.Select>
                          </td>
                        </>
                      ) : (
                        <></>
                      )}

                      {this.props.OrderState == "Package" ? (
                        <>
                          <td className="text-center">
                            <h5>
                              {this.PrepareStatus(orderdata) == "Ready" ? (
                                <span
                                  style={{
                                    backgroundColor: "#1B5448",
                                    color: "white",
                                  }}
                                >
                                  &nbsp;Ready&nbsp;
                                </span>
                              ) : this.PrepareStatus(orderdata) ==
                                "Preparing" ? (
                                <span
                                  className="blink_me "
                                  style={{
                                    backgroundColor: "yellow",
                                  }}
                                >
                                  &nbsp;Preparing&nbsp;
                                </span>
                              ) : (
                                ""
                              )}
                            </h5>
                          </td>
                        </>
                      ) : (
                        <></>
                      )}

                      <td className=" text-black text-center">
                        <strong>
                          {Number(
                            orderdata.mrp * orderdata.qty -
                              (orderdata.qty *
                                orderdata.mrp *
                                orderdata.discount) /
                                100
                          ).toLocaleString(
                            this.context.storeSettings.defaultLocale,
                            {
                              minimumFractionDigits: 0,
                              maximumFractionDigits: 1,
                              style: "currency",
                              currency:
                                this.context.storeSettings.defaultCurrency,
                            }
                          )}
                        </strong>
                      </td>

                      {(this.props.OrderState === "Returns" ||
                        this.props.OrderState === "History") &&
                      (this.props.orderDetail.orderStatus == 20 ||
                        this.props.orderDetail.orderStatus == 12) ? (
                        <td className=" text-black text-right  " align="center">
                          <Form.Control
                            type="number"
                            name="amountToRefundOnReturns"
                            value={orderdata.amountToRefundOnReturns}
                            required
                            placeholder=""
                            className=" text-center"
                            disabled={
                              this.props.OrderState === "History" ? true : false
                            }
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e, i)}
                          />
                        </td>
                      ) : (
                        ""
                      )}

                      <td>
                        {this.props.OrderState === "History" ? (
                          <Form.Control
                            as="textarea"
                            type="text"
                            name="description"
                            value={orderdata.description}
                            placeholder=""
                            readOnly
                            className="text-start"
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e, i)}
                          />
                        ) : (
                          <Form.Control
                            as="textarea"
                            type="text"
                            name="description"
                            value={orderdata.description}
                            placeholder=""
                            disabled={
                              this.props.orderDetail.orderStatus == 3 ||
                              this.props.orderDetail.orderStatus == 15 ||
                              this.props.orderDetail.orderStatus == 16
                                ? true
                                : false
                            }
                            className="text-start"
                            autoComplete="off"
                            onChange={(e) => this.handleInputChange(e, i)}
                          />
                        )}
                      </td>
                    </tr>
                  ))
                : ""}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}
