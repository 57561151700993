import React, { useState, useContext, Component, createRef } from "react";
import CartContext from "./CartContext";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  Modal,
  Row,
  Col,
  Nav,
  Form,
  Button,
  Alert,
  Container,
} from "react-bootstrap";

export default class UserChangePassword extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      password1: "",
      password2: "",
      status: "",
    };
  }

  handleChangePassword1 = (event) => {
    this.setState({ password1: event.target.value });
    this.setState({ status: "" });
  };
  handleChangePassword2 = (event) => {
    this.setState({ password2: event.target.value });
    this.setState({ status: "" });
  };

  SaveNewPassword = () => {
    const config = {
      headers: { Authorization: `Bearer ${this.context.userData.bearertoken}` },
    };
    const bodyParameters = {
      id: this.context.userData.id,
      password: this.state.password1,
      // modified: new Date().toLocaleString(),
    };

    var res = axios
      .post(
        process.env.REACT_APP_API + "StoreManager/ChangeUserPassword/",
        bodyParameters,
        config
      )
      .then((response) => {
        if (response.status === 200) {
          alert("Password changed successfully!");
        } else {
          alert("unable to change password!");
        }
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    if (
      this.state.password1 != "" &&
      this.state.password2 != "" &&
      this.state.password1 == this.state.password2
    ) {
      this.SaveNewPassword();
    } else {
      this.setState({ status: "Password did not match!" });
    }
  };

  render() {
    return (
      <>
        <div className="Container-fluid mx-0 px-1 ">
          <div className="row mt-2 mx-1 px-0">
            <div className="col-md-4 "></div>
            <div className="col-md-4 ">
              <br></br>
              <br></br>
              <h1 className="text-center">
                <b>Change your Password</b>
              </h1>
              <div className="mx-0 px-3 p-3 bg-secondary border">
                <Form onSubmit={this.handleSubmit}>
                  {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label className="text-white">
                      Email address
                    </Form.Label>
                    <Form.Control
                      size="lg"
                      type="email"
                      disabled="true"
                      placeholder="Enter your emailId"
                      value={this.state.value}
                      onChange={(e) => this.handleChange(e)}
                    />
                  </Form.Group> */}
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    {/* <Form.Label className="text-white">
                      <h5>Enter New Password</h5>
                    </Form.Label> */}
                    <Form.Control
                      size="lg"
                      type="password"
                      placeholder="Enter New Password"
                      value={this.state.password1}
                      onChange={this.handleChangePassword1}
                    />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    {/* <Form.Label className="text-white">
                      <h5>Reenter New Password</h5>
                    </Form.Label> */}
                    <Form.Control
                      size="lg"
                      type="password"
                      placeholder="Re-enter Password"
                      value={this.state.password2}
                      onChange={this.handleChangePassword2}
                    />
                  </Form.Group>
                  <div className="text-center text-white">
                    <h5 className="blink_me">{this.state.status}</h5>
                  </div>
                  <br></br>
                  <div className="text-center">
                    <Button
                      variant="primary"
                      type="submit"
                      className="w-25"
                      size="lg"
                    >
                      <b>Submit</b>
                    </Button>
                  </div>
                  <br></br>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
