import React, { Component } from "react";
import CartContext from "./CartContext";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import Fuse from "fuse.js";

import {
  FloatingLabel,
  OverlayTrigger,
  Tooltip,
  Stack,
  FormControl,
  Table,
  InputGroup,
  Modal,
  Button,
  Row,
  Col,
  Form,
  Image,
  Alert,
} from "react-bootstrap";

// https://www.geeksforgeeks.org/how-to-create-an-editable-table-with-add-delete-and-search-filter-using-reactjs/
export class ProductGroupAndProductsMapping extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      productGroup: { hideMe: false, hideWhenOutOfStock: true },
      productsInProductGroup: [],
      selectedMainCategoryId: "0",
      selectedMainCategoryName: "All",
      selectedSubCategoryId: "0",
      selectedSubCategoryName: "All",
      filteredProducts: [],
      filteredProductsCopy: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      searchquery: "",
      reload: true,
    };
  }

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  onMainCategorySelectChangeEvent = (e) => {
    this.setState({ reload: false });
    this.setState({ selectedMainCategoryId: e.target.value }, () => {
      this.setState({ selectedSubCategoryId: "0" }, () =>
        this.OnSubCategoryIDChanges()
      );
    });
  };
  OnSubCategoryIDChanges = () => {
    this.onCategoryChanges();
  };
  onSubCategorySelectChangeEvent = (e) => {
    this.setState({ reload: false });
    this.setState({ selectedSubCategoryId: e.target.value }, () =>
      this.onCategoryChanges()
    );
  };
  onFilteredProductsChanged = () => {
    this.setState({ filteredProductsCopy: this.state.filteredProducts }, () => {
      this.setState({ reload: true }, () => {
        this.HideLoadSpinner();
      });
      console.log("products reloaded");
    });
  };
  onCategoryChanges = () => {
    //when main and sub are "All"
    if (
      this.state.selectedMainCategoryId === "0" &&
      this.state.selectedSubCategoryId === "0"
    ) {
      this.setState(
        {
          filteredProducts: [...this.context.prods], //deep clone
        },
        () => this.onFilteredProductsChanged()
      );
    }
    //when Main is not "ALL" but sub is "All"
    else if (
      this.state.selectedMainCategoryId !== "0" &&
      this.state.selectedSubCategoryId === "0"
    ) {
      this.setState(
        {
          filteredProducts: this.context.prods.filter((prod) => {
            return (
              prod.subCategory.mainCategoryId ===
              this.state.selectedMainCategoryId
            );
          }),
        },
        () => this.onFilteredProductsChanged()
      );
    }
    //when both Main and Sub are not "All"
    else {
      this.setState(
        {
          filteredProducts: this.context.prods.filter((prod) => {
            return (
              prod.subCategory.mainCategoryId ===
                this.state.selectedMainCategoryId &&
              prod.subCategory.id === this.state.selectedSubCategoryId
            );
          }),
        },
        () => this.onFilteredProductsChanged()
      );
    }
  };

  GetProductsOnProductGroup = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetProductsOnProductGroup/" +
        this.props.productGroup.id
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({
            productsInProductGroup: data
              ? data.sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
              : "",
          });
        }
      })
      .catch((error) => {
        alert("GetproductGroups:: " + error);
      });
  };
  SaveProductsOnProductGroup = () => {
    this.ShowLoadSpinner();
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(this.state.productsInProductGroup),
      // this.state.productsInProductGroup),
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/SaveProductsOnProductGroup/" +
        this.props.productGroup.id,
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.context.GetProductGroups();
          this.HideLoadSpinner();
          this.ShowMessagBox("Information", "Saved Successfully!");
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed!");
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed! " + error);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.SaveProductsOnProductGroup();
  };

  fakeRequest = () => {
    // var waittime = (this.context.prods.length / 100) * 1000;
    return new Promise((resolve) => setTimeout(() => resolve(), 1000));
  };

  OnAddProductToGroup = (prod) => {
    this.setState({
      productsInProductGroup: [
        ...this.state.productsInProductGroup,
        {
          productId: prod.id,
          productGroupId: this.props.productGroup.id,
          seqNo: this.state.productsInProductGroup.length + 1,
          // name: prod.name,
          // mainCategoryName: this.context.getMainCategoryName(
          //   prod.subCategory.mainCategoryId
          // ),
          // subCategoryName: prod.subCategory.name,
          // subCategoryName: this.context.prods.filter((p) => p.id == prod.id)[0]
          //   .subCategory.name,
        },
      ],
    });
  };

  handleRemoveClickOnGroup = (i) => {
    const list = [...this.state.productsInProductGroup];
    list.splice(i, 1);
    this.setState({ productsInProductGroup: list });
  };
  handleInputChange = (e, i) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "seqNo":
        if (value < 1) return;
        this.state.productsInProductGroup[i].seqNo = value;
        break;
    }
    this.setState({
      productsInProductGroup: this.state.productsInProductGroup,
    });
  };

  componentDidMount() {
    this.setState(
      {
        selectedMainCategoryId:
          this.context.mainCategories.length > 0
            ? this.context.mainCategories[0].id
            : "0",
      },
      () => {
        this.fakeRequest().then(() => {
          this.setState({ loading: false }); // showing the app
        });
        this.onCategoryChanges();
      }
    );
    this.GetProductsOnProductGroup();
  }

  onSearchChange = (query) => {
    this.setState({ reload: false });
    this.setState({ searchquery: query });
    if (!query) {
      this.setState(
        { filteredProducts: this.state.filteredProductsCopy },
        () => {
          this.setState({ reload: true });
        }
      );
      return;
    }
    const fuse = new Fuse(this.state.filteredProducts, {
      keys: ["name"],
      threshold: 0.6,
    });
    const result = fuse.search(query);
    const finalResult = [];
    if (result.length) {
      result.forEach((item) => {
        finalResult.push(item.item);
      });
      this.setState({ filteredProducts: finalResult.slice(0, 15) }, () => {
        this.setState({ reload: true });
      });
    } else {
      this.setState({ filteredProducts: [] });
    }
  };

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          size="xl"
          // fullscreen={true}
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          dialogClassName="ProductModal"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit} className="bg-white">
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                Select "{this.props.productGroup.name}" Products
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="bg-white">
              <Row className="m-0 p-0 bg-white " style={{ fontSize: "9pt" }}>
                <Col md={4} className="bg-seondary border border-dark">
                  <Row className="text-center text-secondary">
                    <h4>
                      <b>PRODUCTS</b>
                    </h4>
                  </Row>
                  <Row className="text-center py-3">
                    <Col className="text-secondary ">
                      <Row>
                        <Col md={4}>MainCategory</Col>
                        <Col md={8}>
                          <Form.Select
                            size=""
                            value={this.state.selectedMainCategoryId}
                            onChange={(e) => {
                              this.onMainCategorySelectChangeEvent(e);
                            }}
                          >
                            <option value={0}>ALL</option>
                            {this.context.mainCategories.map((maincategory) => (
                              <option value={maincategory.id}>
                                {maincategory.name}
                              </option>
                            ))}
                          </Form.Select>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4}>SubCategory</Col>
                        <Col md={8}>
                          <Form.Select
                            value={this.state.selectedSubCategoryId}
                            onChange={(e) => {
                              this.onSubCategorySelectChangeEvent(e);
                            }}
                          >
                            <option value={0}>All</option>
                            {this.context.subCategories
                              .filter(
                                (subcategory) =>
                                  subcategory.mainCategoryId ===
                                  this.state.selectedMainCategoryId
                              )
                              .map((subcategory) => (
                                <option value={subcategory.id}>
                                  {subcategory.name}
                                </option>
                              ))}
                          </Form.Select>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Table className="my-0 py-0">
                        <Row className="">
                          <Col>
                            <Stack direction="horizontal" gap={3} className="">
                              <b>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="30"
                                  height="30"
                                  fill="currentColor"
                                  class="bi bi-search"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg>
                              </b>
                              <FormControl
                                type="search"
                                placeholder="Search... "
                                className="text-dark fw-bold bg-white "
                                aria-label="Search"
                                onChange={(e) =>
                                  this.onSearchChange(e.target.value)
                                }
                              />
                            </Stack>
                          </Col>
                        </Row>
                      </Table>

                      <div className="fixedTableHight">
                        <Table
                          border-1
                          className="bg-white  w-100 "
                          width="50%"
                        >
                          <thead className="border-5 ">
                            <tr className="text-center text-dark  border ">
                              <th className="text-start " width="50%">
                                Product Name
                              </th>
                              <th className="text-start " width="20%">
                                InStock
                              </th>
                              <th className="text-end " width="30%">
                                Add Product
                              </th>
                            </tr>
                          </thead>
                          <tbody className="text-primary border-5  ">
                            {this.state.filteredProducts.map((prod, i) =>
                              this.state.reload === true ? (
                                <>
                                  <tr
                                    className="text-start align-middle text-dark bg-white mx-5 border-5 "
                                    style={{ fontSize: "12px" }}
                                  >
                                    <td>{prod.name}</td>
                                    <td className="text-center">
                                      {prod.inStockQty}
                                    </td>
                                    <td className="text-end ">
                                      <small>
                                        {this.state.productsInProductGroup.filter(
                                          (p) => p.productId == prod.id
                                        ).length > 0 ? (
                                          <Button variant="secondary" disabled>
                                            ADDED
                                          </Button>
                                        ) : (
                                          <Button
                                            onClick={(e) => {
                                              this.OnAddProductToGroup(prod);
                                            }}
                                          >
                                            {" "}
                                            Add &nbsp;-->
                                          </Button>
                                        )}
                                      </small>
                                    </td>
                                  </tr>
                                </>
                              ) : null
                            )}
                          </tbody>
                        </Table>
                      </div>
                    </Col>
                  </Row>
                </Col>
                {/* <Col md={0} className=""></Col> */}
                <Col md={8} className="border border-dark px-2">
                  <Row>
                    <Col className="text-center text-secondary">
                      {" "}
                      <h4>
                        <b>
                          Selected Products in "{this.props.productGroup.name}"
                          Group
                        </b>
                      </h4>
                    </Col>
                  </Row>
                  <div className="fixedTableHightForProductGroup py-3">
                    <Table border-1 className="bg-white w-100 " width="50%">
                      <thead className="border-5 ">
                        <tr className="text-center text-dark  border ">
                          <th className="text-start " width="30%">
                            Product Name
                          </th>
                          <th className="text-start " width="20%">
                            {" "}
                            Category
                          </th>
                          <th className="text-center" width="10%">
                            Price
                          </th>
                          <th className="text-center " width="10%">
                            Disc
                          </th>
                          <th className="text-center " width="10%">
                            Instock
                          </th>
                          <th className="text-center " width="15%">
                            SeqNo
                          </th>
                          <th className="text-start " width="5%"></th>
                        </tr>
                      </thead>
                      <tbody className="text-primary border-5  ">
                        {this.state.productsInProductGroup
                          // .sort((a, b) => (a.seqNo > b.seqNo ? 1 : -1))
                          .map((prodGroup, i) =>
                            this.state.reload === true ? (
                              <>
                                <tr
                                  className="text-start align-middle text-dark bg-white mx-5 border-5 "
                                  style={{ fontSize: "12px" }}
                                >
                                  <td>
                                    {
                                      this.context.prods.filter(
                                        (p) => p.id == prodGroup.productId
                                      )[0].name
                                    }
                                  </td>
                                  <td className="">
                                    {
                                      this.context.prods.filter(
                                        (p) => p.id == prodGroup.productId
                                      )[0].subCategory.name
                                    }{" "}
                                    /
                                    {this.context.getMainCategoryName(
                                      this.context.prods.filter(
                                        (p) => p.id == prodGroup.productId
                                      )[0].subCategory.mainCategoryId
                                    )}
                                  </td>

                                  <td className="text-center">
                                    {
                                      this.context.prods.filter(
                                        (p) => p.id == prodGroup.productId
                                      )[0].mrp
                                    }
                                  </td>
                                  <td className="text-center">
                                    {this.context.prods.filter(
                                      (p) => p.id == prodGroup.productId
                                    )[0].discount + "%"}
                                  </td>
                                  <td className="text-center">
                                    {
                                      this.context.prods.filter(
                                        (p) => p.id == prodGroup.productId
                                      )[0].inStockQty
                                    }
                                  </td>
                                  <td>
                                    <Form.Control
                                      type="number"
                                      name="seqNo"
                                      min={0}
                                      value={prodGroup.seqNo}
                                      required
                                      placeholder=""
                                      align="center"
                                      className="text-center  "
                                      autoComplete="off"
                                      onChange={(e) =>
                                        this.handleInputChange(e, i)
                                      }
                                    />
                                    {/* {prod.seqNo} */}
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      overlay={<Tooltip>Delete!</Tooltip>}
                                    >
                                      <Button
                                        className="mainthemecolor"
                                        onClick={(e) => {
                                          if (
                                            window.confirm(
                                              "Are you sure you want to Delete ?"
                                            )
                                          ) {
                                            this.handleRemoveClickOnGroup(i);
                                          }
                                        }}
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="20"
                                          height="20"
                                          fill="currentColor"
                                          class="bi bi-trash"
                                          viewBox="0 0 16 16"
                                        >
                                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                          <path
                                            fill-rule="evenodd"
                                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                          />
                                        </svg>
                                      </Button>
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                              </>
                            ) : null
                          )}
                      </tbody>
                    </Table>
                  </div>
                </Col>
              </Row>
            </Modal.Body>

            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  Save
                </Button>
              </Form.Group>
              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
