import React, { Component } from "react";
import { useState, useRef } from "react";
import { BlobServiceClient } from "@azure/storage-blob";
import { Navigate } from "react-router-dom";
import Swal from "sweetalert2";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { MessageBox } from "./MessageBox";
import Fuse from "fuse.js";
import { LoadSpinner } from "./LoadSpinner";
import { BsFillPencilFill } from "react-icons/bs";
import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import {
  OverlayTrigger,
  Tooltip,
  Table,
  Stack,
  Modal,
  FormControl,
  Form,
  Row,
  Col,
  Navbar,
  Nav,
  Button,
  ButtonToolbar,
} from "react-bootstrap";

export class Customers extends Component {
  static contextType = CartContext;

  constructor(props) {
    super(props);
    this.state = {
      reload: true,
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      customers: [],
      filteredCustomers: [],
      selectAllCustomers: false,
    };
    this.inputFile = React.createRef();
  }
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };
  fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 2000));
  };
  onSearchChange = (query) => {
    this.setState({ reload: false });
    this.setState({ filteredCustomers: [] }, () => {
      this.setState({ searchquery: query });
      if (!query) {
        //this.setState({ searchData: [] });
        this.setState({ filteredCustomers: this.state.customers }, () => {
          this.setState({ reload: true });
        });
        return;
      }
      const fuse = new Fuse(this.state.customers, {
        keys: ["mobileNo", "firstName", "lastName"],
        // keys: ["mobileNo"],
        // threshold: 0.0,
        // ignoreLocation: true,
        // findAllMatches: true,
        // includeMatches: true,
        // distance: 120,
        threshold: 0.2,
        ignoreLocation: true,
        findAllMatches: false,
        distance: 100,
      });
      const result = fuse.search(query);
      const finalResult = [];
      if (result.length) {
        result.forEach((item) => {
          finalResult.push(item.item);
        });
        this.setState({ filteredCustomers: finalResult.slice(0, 15) }, () => {
          this.setState({ reload: true });
        });
      } else {
        this.setState({ filteredCustomers: [] });
      }
    });
  };

  GetCustomers = () => {};

  // DeleteCustomers = (selectedCustomers) => {
  //   this.ShowLoadSpinner();

  //   const requestOptions = {
  //     method: "POST",
  //     headers: { "Content-Type": "application/json" },
  //     body: JSON.stringify(selectedCustomers),
  //   };

  //   fetch(
  //     process.env.REACT_APP_API +
  //       "StoreManager/DeleteCustomers/" +
  //       this.context.storeId,
  //     requestOptions
  //   )
  //     .then((res) => {
  //       if (res.status == 200) {
  //         this.context.GetAllImageNames();
  //         this.HideLoadSpinner();
  //         this.ShowMessagBox(
  //           "Information",
  //           "Deleted Successfully. Click Refresh to see the changes!"
  //         );
  //         return;
  //       }
  //       this.HideLoadSpinner();
  //       this.ShowMessagBox("Error", "Delete Failed!");
  //     })
  //     .catch((error) => {
  //       this.HideLoadSpinner();
  //       this.ShowMessagBox("Error", "Delete Failed! " + error);
  //     });
  // };

  // OnDelete = () => {
  //   var selectedCustomers = this.state.customers.filter(
  //     (i) => i.checked == true
  //   );
  //   this.DeleteCustomers(selectedCustomers);
  // };

  GetCustomerAccounts = () => {
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetCustomerAccounts/" +
        this.context.storeId
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ customers: data }, () => {
            this.setState({ filteredCustomers: this.state.customers });
            this.setState({ searchquery: "" });
            this.setState({ reload: true });
          });
        }
      })
      .catch((error) => {
        alert("GetCustomerAccounts:: " + error);
      });
  };

  OnRefreshButtonClick = () => {
    this.setState({ customers: [] });
    this.setState({ filteredCustomers: [] });

    this.GetCustomerAccounts();
    this.setState({ reload: false }, () => {
      this.setState({ reload: true });
    });
  };
  DownloadToCSV = () => {
    var headings =
      "Created,FirstName,LastName,CustomerType,MobileNo,EmailId,Address,City,State,Country";
    var rows = "";
    this.state.filteredCustomers.map((m) => {
      rows +=
        this.context.GetInDateFormat(new Date(m.createdDateTime)) +
        "," +
        m.firstName +
        "," +
        m.lastName +
        "," +
        (m.customerUserTypeId == 1 ? "Registered" : "Guest") +
        "," +
        m.mobileNo +
        "," +
        m.emailId +
        "," +
        m.address +
        "," +
        m.city +
        "," +
        m.state +
        "," +
        m.country;

      rows += "\n";
    });
    rows = headings + "\n" + rows;
    const element = document.createElement("a");
    const file = new Blob([rows], {
      type: "text/plain",
    });
    element.href = URL.createObjectURL(file);
    element.download =
      "products-" +
      new Date().getDate() +
      (new Date().getMonth() + 1) +
      new Date().getFullYear() +
      "-" +
      new Date().getMinutes() +
      new Date().getSeconds() +
      ".csv";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  componentDidMount() {
    this.fakeRequest().then(() => {
      this.setState({ loadSpinnerOpen: false }); // showing the app
    });
    if (this.state.customers.length == 0) this.GetCustomerAccounts();
    window.scrollTo(0, 0);
  }

  componentDidUpdate() {}
  render() {
    return (
      <>
        <div className="Container-fluid mx-0 px-0">
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}

          <Row>
            {window.innerWidth >= 1100 ? (
              <>
                <Col className="pagebackground " xs={2}>
                  <SideMenu></SideMenu>
                </Col>
              </>
            ) : (
              ""
            )}
            <Col
              xs={window.innerWidth >= 1100 ? 10 : 12}
              className="pagebackground p-1 "
            >
              <Navbar className="secondarythemecolor position-sticky fixed-top px-1 m-0 p-1 text-center border-3 border-dark">
                <h4> &nbsp; &nbsp;Customers &nbsp;</h4>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Refresh
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        onClick={() => this.DownloadToCSV()}
                      >
                        <BiDownload size="20"></BiDownload>
                        Download
                      </Button>
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <br />
              <div className=" my-2 p-1">
                <Table className="my-0 py-0" size="small">
                  <Row className="">
                    <Col>
                      <Stack direction="horizontal" gap={3} className="">
                        <b>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            fill="currentColor"
                            class="bi bi-search"
                            viewBox="0 0 16 16"
                          >
                            <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                          </svg>
                        </b>
                        <FormControl
                          type="search"
                          placeholder="Search by Contact number or Name... "
                          className="text-dark fw-bold bg-white "
                          aria-label="Search"
                          onChange={(e) => this.onSearchChange(e.target.value)}
                        />
                      </Stack>
                    </Col>
                  </Row>
                </Table>
              </div>
              <br></br>
              <div className="categorydiv">
                <Table border striped className="px-0 bg-white ">
                  <thead className="">
                    {window.innerWidth >= 1200 ? (
                      <>
                        <tr className="text-center text-black bg-white border">
                          <th></th>
                          <th width="20%">First Name</th>
                          <th width="10%">Customer Type</th>
                          <th width="10%">Contact No</th>
                          <th width="10%">EmailId</th>
                          <th width="10%">City</th>
                          <th width="10%">State</th>
                          <th width="10%">Created</th>
                          <th width="0%" className="text-center"></th>
                        </tr>
                      </>
                    ) : (
                      <>
                        <tr className="text-center text-black bg-white border">
                          <th></th>
                          <th className="text-start">Customer Address</th>
                          <th>Type</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </>
                    )}
                  </thead>
                  <tbody className="text-primary border">
                    {this.state.filteredCustomers
                      .sort((a, b) =>
                        a.createdDateTime > b.createdDateTime ? 1 : -1
                      )
                      .map((customer, i) =>
                        this.state.reload === true ? (
                          <EditCustomerInline
                            index={i}
                            customer={customer}
                            customers={this.state.customers}
                            OnDeleted={this.OnDeleted}
                            OnSaved={this.OnSaved}
                          ></EditCustomerInline>
                        ) : null
                      )}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export class EditCustomerInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      customer: "",
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      showOrdersModal: false,
    };
  }

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDelete = () => {
    if (this.state.customer.id == undefined) {
      this.ShowMessagBox(
        "Information",
        "This is invalid row which can't be deleted. Press Refresh!"
      );
      return;
    }

    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "StoreManager/DeleteCustomerAccount/" +
        this.state.customer.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert("not deleted");
          return;
        }

        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Status",
          "Deleted Successfully. Click Refresh Button to see the changes!"
        );
        // this.props.OnDeleted();
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("OnDelete Failed" + error);
      });
  };

  ValidateDuplicate = () => {
    return true;
  };

  componentDidMount = () => {
    this.setState({ customer: this.props.customer });
  };

  enableDeleteButtonMenu = () => {
    if (this.context.UserRoles.PRODUCTADMIN == this.context.userData.roleId) {
      return false;
    } else {
      return true;
    }
  };

  OnClickViewOrders = () => {
    this.setState({ showOrdersModal: true });
  };
  OnCloseOrdersModal = () => {
    this.setState({ showOrdersModal: false });
  };

  render() {
    return (
      <>
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}
        {this.state.msgboxOpen === true ? (
          <MessageBox
            open={this.state.msgboxOpen}
            title={this.state.msgboxTitle}
            message={this.state.msgboxMessage}
            callback={this.CloseMessagBoxCallback}
          />
        ) : (
          ""
        )}

        {this.state.showOrdersModal == true ? (
          <>
            <ViewCustomerOrders
              customer={this.props.customer}
              Close={this.OnCloseOrdersModal}
            ></ViewCustomerOrders>
          </>
        ) : (
          <></>
        )}

        {window.innerWidth >= 1200 ? (
          <>
            <tr
              className="text-center align-middle text-secondary bg-white mx-5"
              style={{ whiteSpace: "nowrap", fontSize: "12px" }}
            >
              <td>{this.props.index + 1}</td>
              {/* <td className="text-start">{this.state.customer.id}</td> */}
              <td>
                {this.state.customer.firstName}
                &nbsp;{this.state.customer.lastName}
              </td>
              <td>
                {this.state.customer.customerUserTypeId == 1
                  ? "Registered"
                  : "Guest"}
              </td>
              <td>{this.state.customer.mobileNo}</td>
              <td>{this.state.customer.emailId}</td>
              <td>{this.state.customer.city}</td>
              <td>{this.state.customer.state}</td>
              <td>
                {
                  this.context.GetInDateFormat(
                    new Date(this.state.customer.createdDateTime)
                  )
                  // new Date(this.state.customer.createdDateTime).getDate()}/
                  // {new Date(this.state.customer.createdDateTime).getMonth() + 1}/
                  // {new Date(this.state.customer.createdDateTime).getFullYear()
                }
              </td>
              <td>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    this.OnClickViewOrders();
                  }}
                >
                  Orders
                </Button>{" "}
                &nbsp;&nbsp;
                {this.enableDeleteButtonMenu() == false ? (
                  <OverlayTrigger overlay={<Tooltip>Delete!</Tooltip>}>
                    <Button
                      className="mainthemecolor"
                      disabled={this.enableDeleteButtonMenu()}
                      onClick={(e) => {
                        if (
                          window.confirm(
                            "Warning! This operation will delete this Customer's Account and all his Orders from database. Are you sure you want to Delete?"
                          )
                        ) {
                          this.OnDelete(e);
                        }
                      }}
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        fill="currentColor"
                        class="bi bi-trash"
                        viewBox="0 0 16 16"
                      >
                        <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                        <path
                          fill-rule="evenodd"
                          d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                        />
                      </svg>
                    </Button>
                  </OverlayTrigger>
                ) : (
                  <></>
                )}
              </td>
            </tr>
          </>
        ) : (
          <>
            <tr
              className="text-center align-middle text-secondary bg-white mx-5"
              style={{ fontSize: "12px" }}
            >
              <td>{this.props.index + 1}</td>

              <td className="text-start">
                {this.state.customer.firstName}
                {this.state.customer.lastName}, {this.state.customer.mobileNo},
                <br />
                {this.state.customer.emailId}, {this.state.customer.city},
                {this.state.customer.state}
              </td>

              <td>
                {this.state.customer.customerUserTypeId == 1
                  ? "Registered"
                  : "Guest"}
                <br />
                {this.context.GetInDateFormat(
                  new Date(this.state.customer.createdDateTime)
                )}
              </td>
              <td>
                <Button
                  className="mainthemecolor"
                  onClick={(e) => {
                    this.OnClickViewOrders();
                  }}
                >
                  Orders
                </Button>{" "}
              </td>
              <td>
                {this.enableDeleteButtonMenu() == false ? (
                  <>
                    <OverlayTrigger overlay={<Tooltip>Delete!</Tooltip>}>
                      <Button
                        className="mainthemecolor"
                        // disabled={this.enableDeleteButtonMenu()}
                        onClick={(e) => {
                          if (
                            window.confirm(
                              "Warning! This operation will delete this Customer's Account and all his Orders from database. Are you sure you want to Delete?"
                            )
                          ) {
                            this.OnDelete(e);
                          }
                        }}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="20"
                          height="20"
                          fill="currentColor"
                          class="bi bi-trash"
                          viewBox="0 0 16 16"
                        >
                          <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                          <path
                            fill-rule="evenodd"
                            d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                          />
                        </svg>
                      </Button>
                    </OverlayTrigger>
                  </>
                ) : (
                  <></>
                )}
              </td>
            </tr>
          </>
        )}
      </>
    );
  }
}

export class ViewCustomerOrders extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = { userOrders: [], sessionTimedout: false };
  }
  GetUserOrders = () => {
    console.log(`Bearer ${this.context.userData.bearertoken}`);
    fetch(
      process.env.REACT_APP_API +
        "Consumers/GetUserOrders/" +
        this.context.storeId +
        "," +
        this.props.customer.id,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${this.context.userData.bearertoken}`,
        },
      }
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 404) return;
        this.setState({ userOrders: data });
      })
      .catch((error) => {
        Swal.fire({
          title: "Timeout",
          text: "Session Timeout",
          timer: 1000,
        }).then((result) => {
          this.context.clearUserData();
          this.setState({ sessionTimedout: true });
        });
      });
  };

  componentDidMount() {
    this.GetUserOrders();
  }

  GetOrderStatus = (orderStatusId) => {
    var list = this.context.orderStatusTypes.filter(
      (e) => e.id == orderStatusId
    );
    if (list.length > 0) return list[0].statusName;
    return "";
  };
  render() {
    if (this.state.sessionTimedout == true) {
      return <Navigate to="/UserLogin" />;
    }
    return (
      <div className="container ">
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          show={true}
          dialogClassName="CouponModal"
          onHide={this.props.Close}
        >
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                Orders
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Table
                border
                className="cartviewtable px-5 table-striped"
                responsive="md"
              >
                <thead>
                  <tr className="bg-light text-center text-secondary">
                    <th width="10%">Order Placed</th>
                    <th width="10%">Order No</th>
                    <th width="10%">Total Items</th>
                    <th width="10%">Total Amount</th>
                    <th width="20%">Order Status</th>
                    <th width="20%">Delivery Address</th>
                    <th width="20%">Invoice</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.userOrders
                    .sort((a, b) => (a.orderId < b.orderId ? 1 : -1))
                    .map((p) => (
                      <>
                        <tr className="text-center text-secondary ">
                          <td>
                            {
                              this.context.GetInDateFormat(
                                new Date(p.orderCreated)
                              )
                              // new Date(p.orderCreated).getDate() +
                              //   "/" +
                              //   (new Date(p.orderCreated).getMonth() + 1) +
                              //   "/" +
                              //   new Date(p.orderCreated).getFullYear()
                            }
                          </td>
                          <td>
                            <b>
                              {/* {p.orderId.replace(this.context.store.id, "")} */}
                              {p.orderId}
                            </b>
                          </td>
                          <td>{p.customerOrderedItems.length}</td>
                          <td>
                            {Math.round(Number(p.totalAmt), 0).toLocaleString(
                              this.context.storeSettings.defaultLocale,
                              {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 1,
                                style: "currency",
                                currency:
                                  this.context.storeSettings.defaultCurrency,
                              }
                            )}
                          </td>
                          <td>
                            <b>{this.GetOrderStatus(p.orderStatus)}</b>
                          </td>
                          <td>
                            {p.customerOrderDeliveryAddresses[0].firstName}
                            &nbsp;{" "}
                            {p.customerOrderDeliveryAddresses[0].lastName},
                            PhoneNo :{" "}
                            {p.customerOrderDeliveryAddresses[0].mobileNo},{" "}
                            {p.customerOrderDeliveryAddresses[0].address},{" "}
                            {p.customerOrderDeliveryAddresses[0].city},{" "}
                            {p.customerOrderDeliveryAddresses[0].state},{" "}
                            {p.customerOrderDeliveryAddresses[0].country}
                            {" - "}
                            {p.customerOrderDeliveryAddresses[0].pinCode}
                          </td>
                          <td>
                            <a
                              href={
                                this.context.store.storageBlobUrl +
                                this.context.store.storageBlobContainerName +
                                "/invoice/" +
                                p.orderId +
                                ".html" +
                                this.context.store.storageSasToken +
                                "&xyz=" +
                                new Date().toLocaleString()
                                // this.context.store.invoiceFileShareAccountUrl +
                                // this.context.store.invoiceFileShareName +
                                // "/" +
                                // p.orderId +
                                // ".html" +
                                // this.context.store.storageSasToken
                              }
                              target="_blank"
                            >
                              download
                            </a>
                          </td>
                        </tr>
                      </>
                    ))}
                </tbody>
              </Table>
            </Modal.Body>

            <Modal.Footer className="bg-light">
              <Button variant="danger" size="lg" onClick={this.props.Close}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
