import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiRefresh } from "react-icons/bi";
import { BsFillPencilFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import Select from "react-select";
import { ProductServiceCustomerAddOrEditModal } from "./ProductServiceCustomers";
import DateTimePicker from "react-datetime-picker";
import "../CSS/DateTimePicker.css";
import "../CSS/Calendar.css";
import "../CSS/Clock.css";

import {
  Tabs,
  Stack,
  OverlayTrigger,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Row,
  Col,
  Modal,
  Nav,
  Table,
  Navbar,
  Tab,
} from "react-bootstrap";

export class ProductServiceTasks extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      serviceTasks: [],
      taskId: "",
      taskDescription: "",
      estimationCost: "",
      assignedTo: "",
      statusId: "1",
      seqNo: "",
    };
  }
  LoadServiceTasks = () => {
    {
      var pd = [];
      this.props.serviceTasksInventory.map((r) =>
        pd.push({
          value: r.id,
          label: r.taskName,
        })
      );
      this.setState({ serviceTasks: pd });
    }
  };
  componentDidMount = () => {
    if (this.props.Type == "Edit") {
      this.setState({ taskId: this.props.productservicingTask.taskId });
      this.setState({
        taskDescription: this.props.productservicingTask.taskDescription,
      });
      this.setState({ assignedTo: this.props.productservicingTask.assignedTo });
      this.setState({ statusId: this.props.productservicingTask.statusId });
      this.setState({ seqNo: this.props.productservicingTask.seqNo });
      this.setState({
        estimationCost: this.GetEstimationOfTaskName(
          this.props.productservicingTask.taskId
        ),
      });
    }
    if (document.getElementById("taskName") != null)
      document.getElementById("taskName").focus();

    this.LoadServiceTasks();
  };

  handleItemsInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "taskId":
        this.setState({ taskId: value });
        break;
      case "taskDescription":
        this.setState({ taskDescription: value });
        break;
      case "estimationCost":
        this.setState({ estimationCost: value });
        break;
      case "assignedTo":
        this.setState({ assignedTo: value });
        break;
      case "statusId":
        this.setState({ statusId: value });
        break;
      case "seqNo":
        this.setState({ seqNo: value });
        break;
    }
  };
  handleSubmit = (event) => {
    if (
      this.state.taskId == "" ||
      this.state.assignedTo == "" ||
      this.state.statusId == ""
    ) {
      alert("enter all manadatory fields and try!");
      return;
    }
    if (this.props.Type == "Add") {
      this.props.AddProductServicingTask({
        taskId: this.state.taskId,
        taskDescription: this.state.taskDescription,
        seqNo: this.state.seqNo,
        assignedTo: this.state.assignedTo,
        statusId: this.state.statusId,
      });
    } else {
      this.props.UpdateProductServicingTask(
        {
          taskId: this.state.taskId,
          taskDescription: this.state.taskDescription,
          seqNo: this.state.seqNo,
          assignedTo: this.state.assignedTo,
          statusId: this.state.statusId,
        },
        this.props.Index
      );
    }
  };
  GetEstimationOfTaskName = (id) => {
    var result = this.props.serviceTasksInventory.filter((f) => f.id == id);
    if (result.length > 0) return result[0].costEstimation;
    return 0;
  };
  OnTaskNameChange = (e) => {
    this.setState({ taskId: e.value });
    this.setState({ estimationCost: this.GetEstimationOfTaskName(e.value) });
  };
  GetServiceTaskName = (id) => {
    var list = this.props.serviceTasksInventory.filter((f) => f.id == id);
    if (list.length > 0) return list[0].taskName;
    return "";
  };

  render() {
    return (
      <>
        <div className="container ">
          <Modal
            {...this.props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={true}
            backdrop="static"
            keyboard={false}
            dialogClassName="CouponModal"
          >
            {/* <Form onSubmit={this.handleSubmit}> */}
            {/* <Form> */}
            <Modal.Header closeButton className="bg-primary text-light">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit"
                  ? "Edit ProductServicing Task"
                  : "Add ProductServicing Task "}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Row className="m-2 p-2 bg-light " style={{ fontSize: "12pt" }}>
                <Col md={12}>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Task Name (*)
                    </Form.Label>
                    <Col sm="9">
                      <Select
                        name="taskId"
                        id="taskId"
                        autoFocus
                        value={{
                          value: this.state.taskId,
                          label: this.GetServiceTaskName(this.state.taskId),
                        }}
                        onChange={(e) => this.OnTaskNameChange(e)}
                        options={this.state.serviceTasks}
                      ></Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Estimation Cost
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        size="lg"
                        className="pd-2 w-100"
                        name="estimationCost"
                        id="estimationCost"
                        required
                        value={this.state.estimationCost}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleItemsInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Task Description / Customer requirements
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        as="textarea"
                        type="textarea"
                        rows={3}
                        maxLength={1999}
                        size="lg"
                        className="pd-2 w-100"
                        name="taskDescription"
                        required
                        value={this.state.taskDescription}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleItemsInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Assigned To (*)
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        size="lg"
                        className="pd-2 w-100"
                        name="assignedTo"
                        required
                        value={this.state.assignedTo}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleItemsInputChange(e)}
                      >
                        <option value=""></option>
                        {this.props.users.map((d) => (
                          <>
                            <option value={d.id}>{d.emailId}</option>
                          </>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Status (*)
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        size="lg"
                        className="pd-2 w-100"
                        name="statusId"
                        required
                        value={this.state.statusId}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleItemsInputChange(e)}
                      >
                        <option value="1">Open</option>
                        <option value="2">InProgress</option>
                        <option value="3">Complete</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                {/* <Button variant="primary" size="lg" type="submit"> */}
                <Button variant="primary" size="lg" onClick={this.handleSubmit}>
                  {this.props.Type == "Add" ? "Add" : "Update"}
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
            {/* </Form> */}
          </Modal>
        </div>
      </>
    );
  }
}
