import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";

import { BiSave, BiRefresh, BiUpload, BiDownload } from "react-icons/bi";
import {
  Row,
  Col,
  Form,
  Nav,
  Table,
  Navbar,
  Tooltip,
  Button,
  ButtonToolbar,
} from "react-bootstrap";

export class TaxesConfig extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      taxDetails: "",
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
    };
  }
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };
  fakeRequest = () => {
    return new Promise((resolve) => setTimeout(() => resolve(), 2000));
  };
  componentDidMount() {
    window.scrollTo(0, 0);
    // this.fakeRequest().then(() => {
    //   this.setState({ loadSpinnerOpen: false }); // showing the app
    // });
    if (this.state.taxDetails == "") this.GetTaxDetails();
  }

  OnSaved = () => {
    this.OnRefreshButtonClick();
  };

  OnRefreshButtonClick = () => {
    this.GetTaxDetails();
    this.setState({ reload: false }, () => {
      this.setState({ reload: true });
    });
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;
    switch (name.trim()) {
      //Store
      case "taxNumber":
        this.state.taxDetails.taxNumber = value;
        break;
      case "taxPercentage":
        this.state.taxDetails.taxPercentage = value;
        break;
      case "rawMaterialsSgstPercentage":
        this.state.taxDetails.rawMaterialsSgstPercentage = value;
        break;
      case "rawMaterialsCgstPercentage":
        this.state.taxDetails.rawMaterialsCgstPercentage = value;
        break;
      case "productServiceSgstPercentage":
        this.state.taxDetails.productServiceSgstPercentage = value;
        break;
      case "productServiceCgstPercentage":
        this.state.taxDetails.productServiceCgstPercentage = value;
        break;
    }
    this.setState({ taxDetails: this.state.taxDetails });
  };

  handleIncusiveOrExclusiveTax = (event) => {
    const { name, value } = event.target;
    switch (event.target.id) {
      case "inclusiveOfTax":
        if (value == "on") {
          this.state.taxDetails.inclusiveOfTax = true;
        }
        break;
      case "exclusiveOfTax":
        if (value == "on") {
          this.state.taxDetails.inclusiveOfTax = false;
        }
        break;
    }
    this.setState({ taxDetails: this.state.taxDetails });
  };
  GetTaxDetails = () => {
    const config = {
      headers: { Authorization: `Bearer ${this.context.userData.bearertoken}` },
    };
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetTaxDetails/" +
        this.context.storeId,
      config
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ taxDetails: data });
        }
      })
      .catch((error) => {
        this.ShowMessagBox("Warning", "Session Timeout!");
        this.context.clearUserData();
        this.setState({ sessionTimedout: true });
      });
  };
  OnSave = (e) => {
    this.ShowLoadSpinner();

    const requestOptions = {
      method: "PUT",

      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${this.context.userData.bearertoken}`,
      },
      body: JSON.stringify(this.state.taxDetails),
    };

    fetch(
      process.env.REACT_APP_API + "StoreManager/UpdateTaxDetails/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.GetTaxDetails();
          this.HideLoadSpinner();
          this.ShowMessagBox("Information", "Saved Successfully!");
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed!");
      })
      .catch((error) => {
        this.HideLoadSpinner();
        this.ShowMessagBox("Error", "Saved Failed! " + error);
      });
  };

  componentDidUpdate() {}
  render() {
    return (
      <>
        {this.state.msgboxOpen === true ? (
          <MessageBox
            open={this.state.msgboxOpen}
            title={this.state.msgboxTitle}
            message={this.state.msgboxMessage}
            callback={this.CloseMessagBoxCallback}
          />
        ) : (
          ""
        )}
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}

        <div className="Container-fluid p-1 pagebackground">
          <Row>
            <Col className="pagebackground " xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="p-2 bg-white">
              <Navbar className="secondarythemecolor position-sticky fixed-top  my-0">
                <h4> &nbsp; &nbsp;Taxes Configuration &nbsp;</h4>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemeColorCode"
                        size=""
                        onClick={(e) => {
                          this.OnSave(e);
                        }}
                      >
                        <BiSave size="26" />
                        Save
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemeColorCode"
                        size=""
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Undo
                      </Button>
                      &nbsp;
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              <br></br>
              {this.state.taxDetails == "" ? (
                ""
              ) : (
                <>
                  <h4>
                    <b>eCommerce Settings</b>
                  </h4>
                  <div className="border ">
                    <Row className="p-2 m-2 bg-light">
                      <Col width="100%">
                        <Form.Check
                          className=""
                          type="radio"
                          checked={
                            this.state.taxDetails.inclusiveOfTax == true
                              ? "true"
                              : ""
                          }
                          name="inclusiveOfTax"
                          label="Inclusive Of Tax"
                          id="inclusiveOfTax"
                          onChange={this.handleIncusiveOrExclusiveTax}
                        ></Form.Check>
                        <Form.Check
                          className="my-4"
                          type="radio"
                          checked={
                            this.state.taxDetails.inclusiveOfTax == false
                              ? "true"
                              : ""
                          }
                          name="exclusiveOfTax"
                          label="Exclusive Of Tax"
                          id="exclusiveOfTax"
                          onChange={this.handleIncusiveOrExclusiveTax}
                        ></Form.Check>
                        <div className="px-3 py-2 border border-3 w-50 bg-light">
                          <Row className="p-1">
                            <Col>GST Number</Col>
                            <Col>
                              <div>
                                <Form.Control
                                  type="text"
                                  name="taxNumber"
                                  value={
                                    this.state.taxDetails.inclusiveOfTax ==
                                    false
                                      ? this.state.taxDetails.taxNumber
                                      : ""
                                  }
                                  required
                                  disabled={
                                    this.state.taxDetails.inclusiveOfTax == true
                                      ? true
                                      : false
                                  }
                                  placeholder="Enter valid GST Number"
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </div>
                            </Col>
                          </Row>
                          <Row className="p-1">
                            <Col>GST/Tax in Percentage(%)</Col>
                            <Col>
                              <div>
                                <Form.Control
                                  type="number"
                                  min={0}
                                  name="taxPercentage"
                                  value={
                                    this.state.taxDetails.inclusiveOfTax ==
                                    false
                                      ? this.state.taxDetails.taxPercentage
                                      : ""
                                  }
                                  required
                                  disabled={
                                    this.state.taxDetails.inclusiveOfTax == true
                                      ? true
                                      : false
                                  }
                                  placeholder="Enter GST/Tax in %"
                                  autoComplete="off"
                                  onChange={(e) => this.handleInputChange(e)}
                                />
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <h4>
                    <b>RawMaterials Settings</b>
                  </h4>
                  <div className="border ">
                    <Row className="p-2 m-2 bg-light">
                      <Col width="100%">
                        <Form.Group as={Row} className="mb-1 " controlId="">
                          <Form.Label column sm="3">
                            SGST Percentage(%):
                          </Form.Label>
                          <Col sm="9">
                            <b>
                              <Form.Control
                                type="text"
                                size="lg"
                                className="pd-2 w-100"
                                name="rawMaterialsSgstPercentage"
                                required
                                value={
                                  this.state.taxDetails
                                    .rawMaterialsSgstPercentage
                                }
                                placeholder=""
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e)}
                              />
                            </b>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1 " controlId="">
                          <Form.Label column sm="3">
                            CGST Percentage(%):
                          </Form.Label>
                          <Col sm="9">
                            <b>
                              <Form.Control
                                type="text"
                                size="lg"
                                className="pd-2 w-100"
                                name="rawMaterialsCgstPercentage"
                                required
                                value={
                                  this.state.taxDetails
                                    .rawMaterialsCgstPercentage
                                }
                                placeholder=""
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e)}
                              />
                            </b>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                  <br />
                  <h4>
                    <b>Product Service Settings</b>
                  </h4>
                  <div className="border ">
                    <Row className="p-2 m-2 bg-light">
                      <Col width="100%">
                        <Form.Group as={Row} className="mb-1 " controlId="">
                          <Form.Label column sm="3">
                            SGST Percentage(%):
                          </Form.Label>
                          <Col sm="9">
                            <b>
                              <Form.Control
                                type="text"
                                size="lg"
                                className="pd-2 w-100"
                                name="productServiceSgstPercentage"
                                required
                                value={
                                  this.state.taxDetails
                                    .productServiceSgstPercentage
                                }
                                placeholder=""
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e)}
                              />
                            </b>
                          </Col>
                        </Form.Group>
                        <Form.Group as={Row} className="mb-1 " controlId="">
                          <Form.Label column sm="3">
                            CGST Percentage(%):
                          </Form.Label>
                          <Col sm="9">
                            <b>
                              <Form.Control
                                type="text"
                                size="lg"
                                className="pd-2 w-100"
                                name="productServiceCgstPercentage"
                                required
                                value={
                                  this.state.taxDetails
                                    .productServiceCgstPercentage
                                }
                                placeholder=""
                                autoComplete="off"
                                onChange={(e) => this.handleInputChange(e)}
                              />
                            </b>
                          </Col>
                        </Form.Group>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }
}
