import React, { Component } from "react";
import CartContext from "./CartContext";
import { SideMenu } from "./SideMenu";
import { BiRefresh } from "react-icons/bi";
import { BsFillPencilFill } from "react-icons/bs";
import { MessageBox } from "./MessageBox";
import { LoadSpinner } from "./LoadSpinner";
import DateTimePicker from "react-datetime-picker";
import { Country, State, City } from "country-state-city";
import "../CSS/DateTimePicker.css";
import "../CSS/Calendar.css";
import "../CSS/Clock.css";

import {
  OverlayTrigger,
  InputGroup,
  Tooltip,
  Button,
  ButtonToolbar,
  Form,
  Tabs,
  Tab,
  Row,
  Col,
  Modal,
  Nav,
  Table,
  Navbar,
} from "react-bootstrap";

export class SalesLeads extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      ShowNewRequestTabRefresh: true,
      ShowFollowupTabRefresh: false,
      ShowClosedTabRefresh: false,
      refreshTab: false,
      addModalShow: false,
      users: [],
    };
  }
  GetUsers = () => {
    const config = {
      headers: { Authorization: `Bearer ${this.context.userData.bearertoken}` },
    };
    fetch(
      process.env.REACT_APP_API +
        "StoreManager/GetStoreUserAccounts/" +
        this.context.storeId,
      config
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({
            //users: data.filter((f) => f.storeRoleId != 1),
            users: data,
          });
        }
      })
      .catch((error) => {
        alert("Session Timeout! Signout and Signin again!");
      });
  };
  refreshTab = () => {
    this.setState({ refreshTab: true }, () => {
      this.setState({ refreshTab: false });
    });
  };
  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
    this.refreshTab();
  };
  OnRefreshButtonClick = () => {
    this.refreshTab();
  };

  ShowSelectedTab = (k) => {
    this.setState({ ShowNewRequestTabRefresh: false });
    this.setState({ ShowFollowupTabRefresh: false });
    this.setState({ ShowClosedTabRefresh: false });

    if (k == "NewRequest") this.setState({ ShowNewRequestTabRefresh: true });
    if (k == "FollowUp") this.setState({ ShowFollowupTabRefresh: true });
    if (k == "Closed") this.setState({ ShowClosedTabRefresh: true });
  };
  componentDidMount = () => {
    this.GetUsers();
  };
  render() {
    return (
      <>
        <div className="Container-fluid p-1 ">
          <Row>
            <Col className="" xs={2}>
              <SideMenu></SideMenu>
            </Col>
            <Col xs={10} className="pagebackground p-0">
              {this.state.msgboxOpen === true ? (
                <MessageBox
                  open={this.state.msgboxOpen}
                  title={this.state.msgboxTitle}
                  message={this.state.msgboxMessage}
                  callback={this.CloseMessagBoxCallback}
                />
              ) : (
                ""
              )}
              <Navbar
                expand="lg"
                className="secondarythemecolor position-sticky fixed-top  px-3"
              >
                <h4> &nbsp; &nbsp;Sale leads &nbsp;</h4>
                <Navbar.Collapse className="justify-content-end mx-0 ">
                  <Nav>
                    <ButtonToolbar className="text-end align-middle">
                      <Button
                        className="mainthemecolor"
                        // onClick={() => this.handleAddLead()}
                        onClick={() => this.setState({ addModalShow: true })}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="26"
                          height="26"
                          fill="currentColor"
                          class="bi bi-plus"
                          viewBox="0 0 16 16"
                        >
                          <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z" />
                        </svg>
                        Add
                      </Button>
                      &nbsp;
                      <Button
                        className="mainthemecolor"
                        size=""
                        onClick={() => this.OnRefreshButtonClick()}
                      >
                        <BiRefresh size="26"></BiRefresh>
                        Refresh
                      </Button>
                      &nbsp;
                      {this.state.addModalShow === true ? (
                        <LeadAddOrEditModal
                          show={this.state.addModalShow}
                          Type="Add"
                          onHide={this.addModalClose}
                          users={this.state.users}
                        />
                      ) : null}
                    </ButtonToolbar>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              {this.state.refreshTab == false ? (
                <>
                  <Tabs
                    id="controlled-tab-example"
                    activeKey={this.state.key}
                    onSelect={(k) => {
                      this.setState({ key: k });
                      this.ShowSelectedTab(k);
                    }}
                    className="mb-3 bg-light fw-bold"
                    fill
                    style={{ fontSize: "22px", color: "yellow" }}
                  >
                    <Tab
                      eventKey="NewRequest"
                      title={"1.New Leads"}
                      style={{ label: "abc" }}
                    >
                      {this.state.ShowNewRequestTabRefresh == true ? (
                        <>
                          <SalesLeadTable
                            CurrentState="NewRequest"
                            users={this.state.users}
                          ></SalesLeadTable>
                        </>
                      ) : (
                        <></>
                      )}
                    </Tab>
                    <Tab eventKey="FollowUp" title={"2.Follow up"}>
                      {this.state.ShowFollowupTabRefresh == true ? (
                        <>
                          <SalesLeadTable
                            CurrentState="FollowUp"
                            users={this.state.users}
                          ></SalesLeadTable>
                        </>
                      ) : (
                        <></>
                      )}
                    </Tab>
                    <Tab eventKey="Closed" title={"3.Closed "}>
                      {this.state.ShowClosedTabRefresh == true ? (
                        <>
                          <SalesLeadTable
                            CurrentState="Closed"
                            users={this.state.users}
                          ></SalesLeadTable>
                        </>
                      ) : (
                        <></>
                      )}
                    </Tab>
                  </Tabs>
                </>
              ) : (
                <></>
              )}
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

class SalesLeadTable extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      salesleads: [],
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      reload: true,
      customerTypeFilter: "",
      statusFilter: "",
      selectedRow: -1,
      selectedLeadRow: "",

      firstLoad: false,
    };
  }
  OnRowSelect = (e, lead) => {
    this.setState({ selectedRow: "" }, () => {
      this.setState({ selectedLeadRow: lead });
    });
    // this.setState({ showOrderDetailsModal: true });
  };

  OnCustomerFilterChanges = (e) => {
    const { name, value } = e.target;
    this.setState({ customerTypeFilter: value });
    this.setState({ reload: false }, () => {
      this.setState({ reload: true });
    });
  };

  OnStatusFilterChanges = (e) => {
    const { name, value } = e.target;
    this.setState({ statusFilter: value });
    this.setState({ reload: false }, () => {
      this.setState({ reload: true });
    });
  };

  GetSalesLeads = () => {
    fetch(
      process.env.REACT_APP_API +
        "ManufactureAndService/GetSalesLeads/" +
        this.context.storeId +
        "/" +
        this.props.CurrentState
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.status == 400) {
        } else {
          this.setState({ salesleads: data }, () => {
            this.setState({ reload: false }, () => {
              this.setState({ reload: true });
            });
          });
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnSaved = () => {
    this.OnRefreshButtonClick();
  };
  OnDeleted = () => {
    this.OnRefreshButtonClick();
  };

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };

  OnUpdated = (indexno) => {
    this.OnRefreshButtonClick();
  };

  addModalClose = () => {
    this.setState({ addModalShow: false }, () => {});
    this.OnRefreshButtonClick();
  };

  componentDidMount = () => {
    if (this.state.firstLoad == false) {
      this.state.firstLoad = true;
      this.GetSalesLeads();
    }
    window.scrollTo(0, 0);
  };

  render() {
    return (
      <>
        <div className="Container-fluid p-1 ">
          <Row>
            <Col xs={12} className="pagebackground p-0">
              <div>
                <Row className="w-100">
                  <Col sm={12} className="border">
                    <div>
                      <Row className="bg-light">
                        <Col sm={4}></Col>
                        <Col sm={4}></Col>
                        <Col sm={4}>
                          <InputGroup className="">
                            <InputGroup.Text id="basic-addon1">
                              Customer Type
                            </InputGroup.Text>
                            <Form.Select
                              value={this.state.customerTypeFilter}
                              onChange={(e) => {
                                this.OnCustomerFilterChanges(e);
                              }}
                            >
                              <option value=""></option>
                              <option value="1">Cold</option>
                              <option value="2">Warm</option>
                              <option value="3">HOT</option>
                            </Form.Select>
                          </InputGroup>
                        </Col>
                        {/* <Col sm={4}>
                          <InputGroup className="">
                            <InputGroup.Text id="basic-addon1">
                              Status
                            </InputGroup.Text>
                            <Form.Select
                              value={this.state.statusFilter}
                              onChange={(e) => {
                                this.OnStatusFilterChanges(e);
                              }}
                            >
                              <option value=""></option>
                              <option value="1">Open</option>
                              <option value="2">Following-Up</option>
                              <option value="3">Closed</option>
                              <option value="4">Cancelled</option>
                            </Form.Select>
                          </InputGroup>
                        </Col> */}
                      </Row>
                    </div>
                    <div>
                      <Table hover border striped className="px-0 bg-white ">
                        <thead className="">
                          <tr className="text-start text-black bg-white border">
                            <th width="5%"></th>
                            <th width="10%">Created</th>
                            <th width="10%">Customer</th>
                            <th width="20%">Address</th>
                            <th width="10%">MobileNo</th>
                            <th width="10%">CustomerType</th>
                            <th width="10%">Status</th>
                            <th width="10%">NextFollowup</th>
                            <th width="10%">AssignedTo</th>
                            <th width="10%" className="text-end">
                              Options
                            </th>
                          </tr>
                        </thead>
                        <tbody className="text-primary border">
                          {this.state.salesleads
                            .filter((f) => {
                              if (
                                f.customerType == this.state.customerTypeFilter
                              )
                                return true;
                              if (this.state.customerTypeFilter == "")
                                return true;
                            })
                            .filter((f) => {
                              if (f.status == this.state.statusFilter)
                                return true;
                              if (this.state.statusFilter == "") return true;
                            })
                            .sort((a, b) => (a.created > b.created ? 1 : -1))
                            .map((lead, i) =>
                              this.state.reload === true ? (
                                <>
                                  <tr
                                    className="text-start align-middle text-secondary bg-white py-5"
                                    style={{ fontSize: "14px" }}
                                    onClick={(e) => this.OnRowSelect(e, lead)}
                                    onDoubleClick={(e) =>
                                      this.OnRowSelect(e, lead)
                                    }
                                  >
                                    <EditLeadInline
                                      index={i}
                                      lead={lead}
                                      OnDeleted={this.OnDeleted}
                                      users={this.props.users}
                                    ></EditLeadInline>
                                  </tr>
                                </>
                              ) : null
                            )}
                        </tbody>
                      </Table>
                    </div>
                  </Col>
                  {/* <Col sm={3} className="border">
                    {this.state.selectedLeadRow == "" ? (
                      <></>
                    ) : (
                      <>
                        <b>Customer Name:</b>
                        {this.state.selectedLeadRow.customerName}
                        <br />
                        <b>Contact No:</b>
                        {this.state.selectedLeadRow.mobileNo}
                        <br />
                        <b>Address :</b>{" "}
                        {this.state.selectedLeadRow.address +
                          ", " +
                          this.state.selectedLeadRow.city +
                          ", " +
                          this.state.selectedLeadRow.state +
                          ", " +
                          this.state.selectedLeadRow.country}
                        <br />
                        <div className="text-center bg-light">
                          <u>
                            <b>Requirement & FollowUp Details:</b>
                          </u>
                        </div>
                        <Form.Control
                          as="textarea"
                          type="textarea"
                          rows={30}
                          name="description"
                          maxlength="4999"
                          readOnly
                          value={
                            this.state.selectedLeadRow != ""
                              ? this.state.selectedLeadRow.description
                              : ""
                          }
                          placeholder=""
                          autoComplete="off"
                          onChange={(e) => this.handleInputChange(e)}
                        />
                      </>
                    )}
                  </Col> */}
                </Row>
              </div>
            </Col>
          </Row>
        </div>
      </>
    );
  }
}

export class EditLeadInline extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);
    this.state = {
      lead: "",
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      editModalShow: false,
    };
  }

  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };
  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
  };

  OnDelete = () => {
    if (this.state.lead.id == undefined) {
      this.ShowMessagBox(
        "Information",
        "This is invalid row which can't be deleted. Press Refresh!"
      );
      return;
    }

    this.ShowLoadSpinner();
    const requestOptions = {
      method: "DELETE",
      headers: { "Content-Type": "application/json" },
    };

    fetch(
      process.env.REACT_APP_API +
        "ManufactureAndService/DeleteSalesLead/" +
        this.state.lead.id,
      requestOptions
    )
      .then((res) => {
        if (res.status != 200) {
          this.HideLoadSpinner();
          alert(
            "Not deleted. This Lead may be associated with few Products. First disassociate this lead from the Products and then try!"
          );
          return;
        }
        this.HideLoadSpinner();
        this.ShowMessagBox(
          "Status",
          "Deleted Successfully. Click Refresh Button to see the changes!"
        );
        this.props.OnDeleted();
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("OnDelete Failed" + error);
      });
  };
  GetUserNameById = (id) => {
    var result = this.props.users.filter((f) => f.id == id);
    if (result.length > 0) return result[0].emailId;
    else return "";
  };

  componentDidMount = () => {
    this.setState({ lead: this.props.lead });
  };
  OnEditButtonClick = () => {
    this.setState({ editModalShow: true }, () => {});
  };

  editModalClose = () => {
    this.setState({ editModalShow: false }, () => {});
  };

  render() {
    return (
      <>
        {this.state.editModalShow === true ? (
          <LeadAddOrEditModal
            show={this.state.editModalShow}
            onHide={this.editModalClose}
            lead={this.state.lead}
            Type="Edit"
            users={this.props.users}
          />
        ) : (
          ""
        )}
        {this.state.loadSpinnerOpen === true ? (
          <LoadSpinner open="true"></LoadSpinner>
        ) : (
          ""
        )}

        {this.state.msgboxOpen === true ? (
          <MessageBox
            open={this.state.msgboxOpen}
            title={this.state.msgboxTitle}
            message={this.state.msgboxMessage}
            callback={this.CloseMessagBoxCallback}
          />
        ) : (
          ""
        )}
        <td>{this.props.index + 1}</td>
        <td className="text-start ">
          {this.context.GetInDateFormat(new Date(this.state.lead.created))}
        </td>
        <td className="text-start ">{this.state.lead.customerName}</td>
        <td>
          {this.state.lead.address},&nbsp;{this.state.lead.state},&nbsp;
          {this.state.lead.country}
        </td>
        <td className="text-start ">{this.state.lead.mobileNo}</td>
        {/* <td className="text-start ">
            {
              this.state.lead.address +
                ", " +
                this.state.lead.city +
                ", " +
                this.state.lead.state +
                ", " +
                this.state.lead.country
            
            }
          </td> */}
        <td>
          {this.state.lead.customerType == 1
            ? "COLD"
            : this.state.lead.customerType == 2
            ? "WARM"
            : this.state.lead.customerType == 3
            ? "HOT"
            : ""}
        </td>
        <td>
          {this.state.lead.status == 1
            ? "Open"
            : this.state.lead.status == 2
            ? "FollowingUp"
            : this.state.lead.status == 3
            ? "Closed"
            : this.state.lead.status == 4
            ? "Cancelled"
            : ""}
        </td>
        <td>
          {this.context.GetInDateFormat(
            new Date(this.state.lead.nextFollowUpDate)
          )}
        </td>
        <td>{this.GetUserNameById(this.state.lead.assignedTo)}</td>
        <td className="col-md-2 text-end align-middle ">
          <div class="btn-group my-2">
            <OverlayTrigger overlay={<Tooltip>Edit!</Tooltip>}>
              <Button
                className="mainthemecolor "
                size="sm"
                onClick={(e) => {
                  this.OnEditButtonClick(e);
                }}
              >
                <BsFillPencilFill size="22" />
              </Button>
            </OverlayTrigger>

            <OverlayTrigger
              overlay={
                <Tooltip>
                  Warning! This will delete this Brand Row and associated
                  Products as well.
                </Tooltip>
              }
            >
              <Button
                className="mx-1 mainthemecolor"
                size="sm"
                onClick={(e) => {
                  if (
                    window.confirm(
                      "Warning! This action will delete this Brand Row and associated Products as well. Are you sure you want to Delete?"
                    )
                  ) {
                    this.OnDelete(e);
                  }
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="22"
                  fill="currentColor"
                  class="bi bi-trash"
                  viewBox="0 0 16 16"
                >
                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                  <path
                    fill-rule="evenodd"
                    d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                  />
                </svg>
              </Button>
            </OverlayTrigger>
          </div>
        </td>
      </>
    );
  }
}

export class LeadAddOrEditModal extends Component {
  static contextType = CartContext;
  constructor(props) {
    super(props);

    this.state = {
      lead: {
        country: "",
        state: "",
        status: 1,
        customerType: 1,
        nextFollowUpDate: new Date(),
      },
      msgboxOpen: false,
      msgboxTitle: "",
      msgboxMessage: "",
      loadSpinnerOpen: false,
    };
  }

  CloseMessagBoxCallback = () => {
    this.setState({ msgboxOpen: false });
    this.props.onHide();
  };
  ShowMessagBox = (title, message) => {
    this.setState({ msgboxOpen: true });
    this.setState({ msgboxTitle: title });
    this.setState({ msgboxMessage: message });
  };
  ShowLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: true });
  };
  HideLoadSpinner = () => {
    this.setState({ loadSpinnerOpen: false });
  };

  AddOrUpdateLeadInDB = () => {
    if (
      this.state.lead.mobileNo == "" ||
      (this.state.lead.mobileNo != "" && this.state.lead.mobileNo.length != 10)
    ) {
      alert("Invalid MobileNo. Enter a valid Customer Mobile number and try!");
      return;
    }

    this.ShowLoadSpinner();

    var tmpLead = this.state.lead;
    if (this.props.Type == "Edit") {
      tmpLead = this.props.lead;
    } else {
      tmpLead.storeId = this.context.storeId;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(tmpLead),
    };

    fetch(
      process.env.REACT_APP_API + "ManufactureAndService/AddOrUpdateSalesLead/",
      requestOptions
    )
      .then((res) => {
        if (res.status == 200) {
          this.HideLoadSpinner();
          this.props.onHide();
          return;
        }
        this.HideLoadSpinner();
        alert("This Lead is already exist. try again!");
        return;
      })
      .catch((error) => {
        this.HideLoadSpinner();
        alert("Exception. Received an Exception. Try after sometime !" + error);
      });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.AddOrUpdateLeadInDB();
  };

  handleInputChange = (e) => {
    const { name, value } = e.target;

    switch (name.trim()) {
      case "customerName":
        this.state.lead.customerName = value;
        break;
      case "mobileNo":
        this.state.lead.mobileNo = value;
        break;
      case "address":
        this.state.lead.address = value;
        break;
      case "emailId":
        this.state.lead.emailId = value;
        break;
      case "landMark":
        this.state.lead.landMark = value;
        break;
      case "city":
        this.state.lead.city = value;
        break;
      case "state":
        this.state.lead.state = value;
        break;
      case "country":
        this.state.lead.country = value;
        break;
      case "status":
        this.state.lead.status = value;
        break;
      case "customerType":
        this.state.lead.customerType = value;
        break;
      case "description":
        this.state.lead.description = value;
        break;
      case "assignedTo":
        this.state.lead.assignedTo = value;
        break;
    }
    this.setState({ lead: this.state.lead });
  };

  onNextFollowupChange = (date) => {
    this.state.lead.nextFollowUpDate = date;
    this.setState({
      lead: this.state.lead,
    });
  };

  componentDidMount() {
    if (this.props.Type == "Edit") {
      this.setState({ lead: this.props.lead });
    }
  }

  render() {
    return (
      <div className="container ">
        <Modal
          {...this.props}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
          centered
          backdrop="static"
          keyboard={false}
          dialogClassName="LeadModal"
        >
          {this.state.msgboxOpen === true ? (
            <MessageBox
              open={this.state.msgboxOpen}
              title={this.state.msgboxTitle}
              message={this.state.msgboxMessage}
              callback={this.CloseMessagBoxCallback}
            />
          ) : (
            ""
          )}
          {this.state.loadSpinnerOpen === true ? (
            <LoadSpinner open="true"></LoadSpinner>
          ) : (
            ""
          )}
          <Form onSubmit={this.handleSubmit}>
            <Modal.Header closeButton className="bg-warning">
              <Modal.Title id="contained-modal-title-vcenter">
                {this.props.Type == "Edit" ? "Edit lead" : "Add lead"}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="show-grid ">
              <Row
                className="m-2 p-2 bg-light border"
                style={{ fontSize: "9pt" }}
              >
                <Col md={6}>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Customer Name
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="customerName"
                        maxlength="99"
                        value={this.state.lead.customerName}
                        required
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Mobile No
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="number"
                        name="mobileNo"
                        required
                        value={this.state.lead.mobileNo}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      EmailId
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="email"
                        name="emailId"
                        value={this.state.lead.emailId}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Address
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="address"
                        maxlength="200"
                        value={this.state.lead.address}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      LandMark
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="landMark"
                        maxlength="99"
                        value={this.state.lead.landMark}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      City
                    </Form.Label>
                    <Col sm="9">
                      <Form.Control
                        type="text"
                        name="city"
                        required
                        maxlength="99"
                        value={this.state.lead.city}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Country
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        value={this.state.lead.country}
                        name="country"
                        size=""
                        required
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value=""></option>
                        {Country.getAllCountries().map((country) => (
                          <>
                            <option value={country.isoCode}>
                              {country.name}
                            </option>
                          </>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      State
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        value={this.state.lead.state}
                        name="state"
                        size=""
                        required
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option></option>
                        {this.state.lead.country &&
                          State.getStatesOfCountry(this.state.lead.country).map(
                            (state) => (
                              <>
                                <option>{state.name}</option>
                              </>
                            )
                          )}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Importance
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        size="lg"
                        className="pd-2 w-100"
                        name="customerType"
                        required
                        value={this.state.lead.customerType}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value="1">Cold</option>
                        <option value="2">Warm</option>
                        <option value="3">HOT</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Form.Label column sm="3">
                      Status
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        size="lg"
                        className="pd-2 w-100"
                        name="status"
                        required
                        value={this.state.lead.status}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value="1">Open</option>
                        <option value="2">Following-Up</option>
                        <option value="3">Closed</option>
                        <option value="4">Cancelled</option>
                      </Form.Select>
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      Next Followup
                    </Form.Label>
                    <Col sm="9">
                      <DateTimePicker
                        onChange={this.onNextFollowupChange}
                        value={this.state.lead.nextFollowUpDate}
                        format="dd-MMM-yy"
                      />
                    </Col>
                  </Form.Group>
                  <Form.Group as={Row} className="mb-1 " controlId="">
                    <Form.Label column sm="3">
                      AssignedTo
                    </Form.Label>
                    <Col sm="9">
                      <Form.Select
                        type="text"
                        size="lg"
                        className="pd-2 w-100"
                        name="assignedTo"
                        required
                        value={this.state.lead.assignedTo}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      >
                        <option value=""></option>
                        {this.props.users.map((d) => (
                          <>
                            {/* <option value={d.id}>{d.emailId}</option> */}
                            {d.emailId == "productsupport" ? (
                              <></>
                            ) : (
                              <>
                                <option value={d.id}>{d.emailId}</option>
                              </>
                            )}
                          </>
                        ))}
                      </Form.Select>
                    </Col>
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <Form.Group as={Row} className="mb-3 " controlId="">
                    <Col sm="12">
                      Requirement & FollowUp Details
                      <Form.Control
                        as="textarea"
                        type="textarea"
                        rows={20}
                        name="description"
                        maxlength="4999"
                        value={this.state.lead.description}
                        placeholder=""
                        autoComplete="off"
                        onChange={(e) => this.handleInputChange(e)}
                      />
                    </Col>
                  </Form.Group>
                </Col>
              </Row>
            </Modal.Body>
            <Modal.Footer className="bg-light">
              <Form.Group>
                <Button variant="primary" size="lg" type="submit">
                  Save
                </Button>
              </Form.Group>

              <Button variant="danger" size="lg" onClick={this.props.onHide}>
                Close
              </Button>
            </Modal.Footer>
          </Form>
        </Modal>
      </div>
    );
  }
}
